import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { kebabCaseToGeneralCase } from "../../util/helpers";

import SidebarLinks from "../../components/ui/SidebarLinks";
import { ContentWrapperWithHeader } from "../../components/ui/Content";

import navLinks from "./nav-links.json";
import customReportsLinks from "./custom-reports-links.json";
import Breadcrumbs from "../../components/ui/Content/Breadcrumbs";
import SummaryPage from "./pages/Summary/components/SummaryPage";
import ReportsSummaryProvider from "./pages/Summary/context/ReportsSummaryProvider";
import MessagesPage from "./pages/Summary/components/MessagingReport";
import React, { useContext, useEffect} from "react";
import { UserContext } from "../../context/user";

function MessageLog() {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (!userContext || !userContext.role.auroraAccessLevel.includes("all")) {
      navigate("/operational-map");
    }
  }, [userContext, navigate]);

  return (
    <ReportsSummaryProvider>
      <div className="pt-36 pb-16 flex h-screen">
        <main className="w-full h-full px-8">
          <MessagesPage />
        </main>
      </div>
    </ReportsSummaryProvider>
  );
}

export default MessageLog;
