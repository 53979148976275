import { useEffect, useCallback, RefObject } from "react";

export default function useOnClickOutside(
  ref: RefObject<HTMLElement>,
  handler: (target: HTMLElement) => void
) {
  useEffect(
    () => {
      const listener = (event: Event) => {
        if (!ref.current) return;
        // Do nothing if clicking ref's element or descendent elements
        const target: any = event.target;
        if (!ref.current || ref.current.contains(target)) {
          return;
        }
        handler(target);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    []
  );
}
