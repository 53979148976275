import React, { ReactNode, ReactElement } from "react";

export const ContentWrapperWithHeader = ({
  title,
  content,
  button,
}: {
  title: ReactNode;
  content?: ReactNode | ReactElement;
  button?: ReactNode;
}) => {
  {
    return (
      <div className="bg-white w-full shadow-lg h-full py-10 pt-7 px-10 overflow-hidden">
        <div className="border-b-1">
          <div className="py-3 pl-10 flex justify-between items-center">
            {title}
            {button}
          </div>
        </div>
        {content}
      </div>
    );
  }
};
