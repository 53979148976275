import React, { useContext, useState } from "react";
import { FilterButton } from "../../../../components/ui/Buttons";
import FormFilterOperationalMap from "../../forms/FilterOperationalMap";
import FormSendNotification from "../../forms/SendNotification";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import UserTrackingForm from "../../forms/user-tracking";

import { UserLocationHistory } from "../Map/services/useLocationHistory";
import { FilterMapContext } from "../../context/filter-map";
function SearchFilterMap({
  userLocations,
}: {
  userLocations: void | UserLocationHistory[] | undefined;
}) {
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [sendNotificationOpen, setSendNotificationOpen] =
    useState<boolean>(false);
  const [userFilter, setUserFilter] = useState<boolean>(false);

  const handleUserFilter = () => {
    setUserFilter(true);
  };

  const {filters} = useContext(FilterMapContext)

  return (
    <header className="absolute z-10 top-10 px-10 flex items-center justify-between w-full pointer-events-none">
      <div className="flex gap-4 pointer-events-auto">
        <FilterButton
          onClick={() => {
            setFilterOpen(true);
          }}
        />
        <button
          className="h-12 w-12 bg-white rounded-md shadow-md pointer-events-auto"
          type="button"
          onClick={() => handleUserFilter()}
        >
          <PersonPinIcon style={{ width: "28px", height: "28px" }} />
        </button>

        {userFilter ? (
          <UserTrackingForm
            userFilter={userFilter}
            setUserFilter={setUserFilter}
            userLocations={userLocations}
          />
        ) : null}
      </div>
      <input
        type="text"
        id="pac-input"
        placeholder="Search Location"
        className="h-12 w-full max-w-[240px] text-base mr-7 px-4 rounded-md shadow-md pointer-events-auto"
      />
      {filterOpen && (
        <FormFilterOperationalMap
          filterOpen={filterOpen}
          setFilterOpen={setFilterOpen}
        />
      )}
      {sendNotificationOpen && (
        <FormSendNotification
          sendNotificationOpen={sendNotificationOpen}
          setSendNotificationOpen={setSendNotificationOpen}
        />
      )}
    </header>
  );
}

export default SearchFilterMap;
