import { Done } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { SiteBasedAlertDataTypes } from "../../../../../types/site-based-alerts-data-type";

function SubTypesCheckbox({
  type,
  mainCheck,
  sbaData,
}: {
  type: any;
  mainCheck: boolean;
  sbaData: SiteBasedAlertDataTypes;
}) {
  const [subTypeCheck, setSubTypeCheck] = useState<boolean>(mainCheck);

  useEffect(() => {
    setSubTypeCheck(mainCheck);
  }, [mainCheck]);

  useEffect(() => {
    if (sbaData.incidentType.includes(type.id)) {
      setSubTypeCheck(true);
    }
  }, []);

  const ref = useRef<HTMLInputElement>(null);

  const handleFakeCheckbox = () => {
    if (!ref.current) return;
    setSubTypeCheck((prev) => !prev);
    ref.current.checked = subTypeCheck;
  };

  return (
    <div className="mx-4 flex items-center gap-4 my-1">
      <input
        type="checkbox"
        name={"sub-type"}
        className="absolute left-0 top-0 opacity-0"
        ref={ref}
        value={type.id}
        checked={subTypeCheck}
      />
      <button
        className="h-4 w-4 flex items-center justify-center border"
        type="button"
        onClick={handleFakeCheckbox}
      >
        {subTypeCheck ? <Done style={{ width: "12px" }} /> : null}
      </button>
      <label htmlFor={type.name}>{type.name}</label>
    </div>
  );
}

export default SubTypesCheckbox;
