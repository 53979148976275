import React, {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Sidebar from "../../../../components/ui/Sidebar";
import useGetRoles from "../../../../api/roles/useGetRoles";
import { UserProfileContext } from "../../pages/user-profile/context/user-profile";
import { GreyButton } from "../../../../components/ui/Buttons";
import { RoleTypes } from "../../../../types/roles-types";
import { UserDataTypes } from "../../../../types/user-data-types";
import useGetUserProfile from "../../../../api/user-profile/useGetUserProfile";
import AuroraBranding from "../../../../assets/aurora_branding_upload_image.png";
import { useLocation, useParams } from "react-router-dom";
import { FormDataContext } from "../../pages/user-profile/context/form";
import api from "../../../../api/axiosConfig";
import { downloadCSV } from "../../../../util/helpers";
import { UserContext } from "../../../../context/user";

function UserProfileSidebar() {
  const formDataContext = useContext(FormDataContext)!;
  const userContext = useContext(UserContext);
  const { profilePictureRef, accessRoleRef } = formDataContext;
  const userProfile = useContext(UserProfileContext)!;
  const userProfileData: UserDataTypes = userProfile.data?.data;
  const roles = useGetRoles();
  const { userID } = useParams();
  const [file, setFile] = useState<any>();

  const userRolesRestrictions = () => {
    const roleName = userContext?.role.name;

    switch (roleName) {
      case "NGS Super Admin":
        return [
          "NGS Super Admin",
          "Client Global Admin",
          "Department Admin",
          "Traveller",
          "Insurance User",
        ];

      case "Client Global Admin":
        return [
          "Client Global Admin",
          "Department Admin",
          "Traveller",
          "Insurance User",
        ];

      case "Department Admin":
        return ["Department Admin", "Traveller", "Insurance User"];

      case "Traveller":
        return ["Traveller"];

      case "Insurance User":
        return ["Insurance User"];
    }
  };

  const [imageBlob, setImageBlob] = useState<Blob | null>(null);
  const [roleID, setRoleID] = useState("");

  const fetchImage = async () => {
    return await fetch(
      `${process.env.REACT_APP_API_URL}files/profile-image/${
        userID ? userID : userContext?.sub
      }`
    )
      .then(async (response) => {
        const blob = await response.blob();
        setImageBlob(blob);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchImage();
  }, []);

  useEffect(() => {
    setRoleID(userProfileData.roleID);
  }, [userProfileData]);

  const filteredUserRolesBasedOnRestrictions = () => {
    return roles?.data?.filter((role: RoleTypes) => {
      const userRoles = userRolesRestrictions();
      if (!userRoles?.includes(role.name)) return;
      return role;
    });
  };

  const handleChooseFile = () => {
    profilePictureRef.current?.click();
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      console.error("No file selected");
      return;
    }

    setFile(event.target.files[0]);
  };

  const saveUploadedImage = () => {
    const formData = new FormData();
    formData.append("file", file);

    api
      .post(
        `/files/profile-image/${userID ? userID : userContext?.sub}`,
        formData,
        {
          headers: { "content-type": "multipart/form-data" },
        }
      )
      .then((res) => {
        console.log("image uploaded");
        setFile(null);
        fetchImage();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const pathname = useLocation().pathname.replace("/", "");

  return (
    <div className="w-full max-w-md">
      <Sidebar
        title={`${userProfileData?.profile?.firstName} ${
          userProfileData?.profile?.middleNames
            ? userProfileData?.profile?.middleNames
            : ""
        } ${userProfileData?.profile?.lastName} `}
      >
        <div className="">
          <span className="font-semibold">Profile Picture</span>
          <div className="mt-8 h-40 w-40 rounded-full overflow-hidden mx-auto">
            {imageBlob && (
              <img src={URL.createObjectURL(imageBlob)} alt="Fetched Image" />
            )}
            {!imageBlob && (
              <img src={AuroraBranding} alt="" className="w-full" />
            )}
          </div>
          {/* <div className="flex items-center justify-between my-10">*/}
          {/*  <input*/}
          {/*    type="file"*/}
          {/*    className="hidden"*/}
          {/*    ref={profilePictureRef}*/}
          {/*    onChange={handleFileChange}*/}
          {/*  />*/}
          {/*  <div className="w-1/2">*/}
          {/*    <GreyButton*/}
          {/*      text="Choose File"*/}
          {/*      customClass="bg-light-light-grey rounded-xl"*/}
          {/*      customClassText="text-light-grey"*/}
          {/*      onClick={handleChooseFile}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <div className="text-light-grey"> No file Chosen</div>*/}
          {/* </div>*/}
          <div className="flex gap-4 flex-col mt-5">
            <input
              type="file"
              id="file-upload"
              name="profile-picture"
              className="hidden"
              onChange={handleFileUpload}
              ref={profilePictureRef}
            />
            <div className="flex">
              <button
                className="h-10 w-1/2 rounded-full bg-white shadow-md text-sm"
                onClick={handleChooseFile}
              >
                Choose File
              </button>
              <button
                className={`h-10 w-1/2 rounded-full shadow-md text-sm text-center ml-2 ${
                  !file ? "bg-light-light-grey" : "bg-blue text-white"
                }`}
                disabled={!file}
                onClick={saveUploadedImage}
              >
                Save
              </button>
            </div>
            <span className="text-sm inline-block my-auto mx-auto w-28 text-ellipsis overflow-hidden">
              {" "}
              {file ? file.name : "No file chosen"}
            </span>
          </div>
        </div>
        <div className="">
          <span>Access</span>
          <select
            name="access"
            id="access"
            className="h-10 w-full shadow-md px-4 rounded-md"
            value={roleID}
            onChange={(e) => setRoleID(e.target.value)}
            ref={accessRoleRef}
            disabled={pathname === "profile" ? true : false}
          >
            <option value="roles" disabled>
              Roles
            </option>
            {filteredUserRolesBasedOnRestrictions()?.map((role: RoleTypes) => {
              return (
                <option value={role.id} id={role.id} key={role.id}>
                  {role.name}
                </option>
              );
            })}
          </select>
        </div>
      </Sidebar>
    </div>
  );
}

export default UserProfileSidebar;
