import { Close } from "@mui/icons-material";
import React, { SetStateAction } from "react";

function RiskRatingsCountries({
  setOpen,
}: {
  setOpen: React.Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <div className="bg-white p-8 max-h-[70vh] max-w-7xl h-fit overflow-y-auto relative rounded-md">
      <button className="absolute right-4 top-4" onClick={() => setOpen(false)}>
        <Close />
      </button>
      <header className="h-10 border-b text-center mb-8">Risk Ratings</header>

      <table className="border-separate border-spacing-y-4 w-full">
        <tr className="">
          <th className="pb-4 text-left text-grey">Risk Level</th>
          <th className="text-left pb-4 pl-8 text-grey">Information</th>
        </tr>

        <tbody className="">
          <tr className="bg-light-light-grey">
            <td className="flex flex-col items-center gap-4 justify-center p-12">
              <div className="flex items-center gap-2 h-full">
                <div className="bg-risk-key-high-negligable-green h-10 w-10 rounded-full flex items-center justify-center text-white">
                  1
                </div>
                <span>&</span>
                <div className="bg-risk-key-med-negligable-green h-10 w-10 rounded-full flex items-center justify-center text-white">
                  2
                </div>
              </div>
              <span>Negligible</span>
            </td>
            <td className="p-8">
              <p>
                Safe operating environment with high degree of political
                stability. No threat of international conflict or domestic
                insurgency and no recent history of terrorist attacks. Very low
                crime levels and instances of civil unrest; any political
                protests are peaceful, short-term and have negligible business
                impact. Infrastructure, emergency preparedness and medical
                facilities are generally of high standard. Business environment
                is conducive to foreign investment.
              </p>
            </td>
          </tr>
          <tr className="bg-light-light-grey">
            <td className="flex flex-col items-center gap-4 p-12">
              <div className="flex items-center gap-2">
                <div className="bg-risk-key-med-low-yellow h-10 w-10 rounded-full flex items-center justify-center text-white">
                  3
                </div>
                <span>&</span>
                <div className="bg-risk-key-high-low-desaturated-green h-10 w-10 rounded-full flex items-center justify-center text-white">
                  4
                </div>
              </div>
              <span>Low</span>
            </td>
            <td className="p-8">
              <p>
                Low threat of international conflict or domestic insurgency.
                Stable political institutions and strong overall societal
                adherence to rule of law. History of infrequent terrorist
                attacks by domestic separatists, jihadist-inspired militants or
                other politically motivated groups, including anarchists. Petty
                crime exists but violent crime is generally concentrated among
                criminals and instances of kidnapping are low. Policing, medical
                and infrastructure standards are generally good. Political
                protests and industrial action occur but violent civil unrest is
                rare. Political institutions and the regulatory environment are
                conducive to foreign investment.
              </p>
            </td>
          </tr>
          <tr className="bg-light-light-grey">
            <td className="flex flex-col items-center gap-4 p-12">
              <div className="flex items-center gap-2">
                <div className="bg-risk-key-high-medium h-10 w-10 rounded-full flex items-center justify-center text-white">
                  5
                </div>
                <span>&</span>
                <div className="bg-risk-key-med-mediun h-10 w-10 rounded-full flex items-center justify-center text-white">
                  6
                </div>
              </div>
              <span>Medium</span>
            </td>
            <td className="p-8">
              <p>
                Tensions exist with other countries over historical relations or
                contested territory but there is a low likelihood of inter-state
                conflict. Insurgent or militant groups exist within parts of the
                country but do not threaten the position of government.
                Terrorist attacks occur intermittently and threaten security
                forces and civilian life. Criminal groups are well established
                and often armed; gang violence and street crime are common in
                urban areas and/or border regions. Financially motivated kidnap
                exists and foreign nationals might be targeted due to their
                perceived wealth. Civil unrest can be violent, exacerbated by
                heavy-handedness or poor training of security forces. Protests
                can be well attended and can result in damage to private
                property. Institutional or regulatory barriers, political
                motives, or geopolitical rivalries can present some challenges
                for foreign businesses; such challenges are however manageable
                and foreign operations exist. Infrastructure, including medical
                facilities, transport and telecommunications are less reliable
                outside of major urban centres and subject to periodic
                disruption.
              </p>
            </td>
          </tr>
          <tr className="bg-light-light-grey">
            <td className="flex flex-col items-center gap-4 p-12">
              <div className="flex items-center gap-2">
                <div className="bg-risk-key-high-high h-10 w-10 rounded-full flex items-center justify-center text-white">
                  7
                </div>
                <span>&</span>
                <div className="bg-risk-key-medium-high h-10 w-10 rounded-full flex items-center justify-center text-white">
                  8
                </div>
              </div>
              <span>High</span>
            </td>
            <td className="p-8">
              <p>
                Country has hostile relations and/or disputed territorial claims
                with neighbours, resulting in militarised borders, travel
                restrictions and periodic episodes of violence. Militant or
                insurgent forces exist in the country with the capability to
                regularly conduct attacks. Institutions of government, including
                security forces, lack overall control in these areas. Senior
                political, business or military figures or institutions subject
                to international sanctions. Terrorist attacks are a common
                occurrence, targeting security forces, major infrastructure or
                civilians. Violent crime is high in large areas of the country
                and criminals are usually armed. Foreign nationals vulnerable to
                crime and kidnap in the country. Police response capability is
                poor and corruption among officers is high. Fatalities and
                rioting are common during political protests; poor police
                protest management and the use of emergency powers can
                exacerbate unrest. Infrastructure is unreliable and road
                transportation hazardous, especially in remote areas. Power
                cuts, telecommunication disruption and/or irregular fuel
                supplies are common, even in major cities. Serious illness or
                injury could necessitate medical evacuation. Weak or erratic
                political institutions, corruption and/or poor contractual
                guarantees present barriers to investment and additional costs
                for doing business in the country.
              </p>
            </td>
          </tr>
          <tr className="bg-light-light-grey">
            <td className="flex flex-col items-center gap-4 p-12">
              <div className="flex items-center gap-2">
                <div className="bg-[#000000] h-10 w-10 rounded-full flex items-center justify-center text-white">
                  9
                </div>
              </div>
              <span>Extreme</span>
            </td>
            <td className="p-8">
              <p>
                State of conflict presents a constant threat to life and
                undermines national stability. Political institutions lack
                control over the entire country and struggle to uphold any rule
                of law. Airstrikes, shelling, mass casualty terrorist attacks,
                IEDs and/or other attack forms, are commonplace, including in
                major cities. International sanctions or embargoes are in place
                on key political, military or business figures and bodies. Weak
                rule of law, absent or depleted police and corruption provide a
                conducive environment for crime. Criminals are typically armed
                and willing to use violence against victims. Foreign nationals
                have been victim of both politically and financially motivated
                kidnappings. Civil unrest is typically violent, involving
                fatalities, rioting and clashes with security forces or between
                rival groups. Insecurity impedes travel and has damaged basic
                infrastructure, including water, power, fuel and medical
                supplies. Security and logistical costs for setting up business
                are inhibitive and insecurity or high degree of political
                instability has forced companies to declare force majeure. State
                of violence has rendered medical facilities and emergency
                services obsolete, necessitating private provisions and
                preparedness.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default RiskRatingsCountries;