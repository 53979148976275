import React, { useContext, useEffect, useState } from "react";
import SummaryReport from "./SummaryReport";
import { ReportsSummaryContext } from "../context/ReportsSummaryProvider";
import { Oval } from "react-loader-spinner";
import { ButtonBorderTransparent } from "../../../../../components/ui/Buttons";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MessageEntry from "./MessageEntry";
import useGetMessages from "../../../../../api/reports/useGetMessages";
import LoadingSpinner from "../../../../../components/ui/LoadingSpinner";
import DownloadIcon from "../../../../../assets/icons/download-white.svg";
import MessageFilter from "./MessageFilter";


export default function MessagesPage() {
  const { firstSearch, csvLoading} = useContext(ReportsSummaryContext);
  const getMessages = null;
  const navigate = useNavigate();

  const handleReturn = () => {
    navigate("/reports/summary");
  };

  const [page, setPage] = useState<number>(1);
  const [downloadingCSV, setDownloadingCSV] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [filters, setFilters] = useState({
    startTime: "",
    endTime: "",
    userID: "",
  });

  const fetchMessages = () => {
    console.log("Fetching messages with filters:", filters);
  };

  useEffect(() => {
    console.log("Filters changed:", filters);

    fetchMessages();
  }, [filters]);

  const { data: messages, isLoading, error } = useGetMessages(filters, page, "sentAt", "desc");
  const itemsPerPage = 10;
  const isLastPage = messages && messages.messages.length < itemsPerPage;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleNextPage = () => {
    if (!isLastPage) {
      setPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prev) => prev - 1);
    }
  };


  const handleDownload = () => {
    if (downloadingCSV) {
      return;
    }
    if (!isDownloadable) {
      console.log("User ID is required to download");
      return;
    }
    setDownloadingCSV(true);
    const params = new URLSearchParams();

    params.set("export", "csv");

    if (filters.startTime) {
      params.set("startTime", filters.startTime);
    }

    if (filters.endTime) {
      params.set("endTime", filters.endTime);
    }

    params.set("userID", filters.userID);


    fetch(`${process.env.REACT_APP_API_URL}reports/messages?${params.toString()}`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const downloadLink = document.createElement("a");
        const objectUrl = URL.createObjectURL(blob);

        downloadLink.href = objectUrl;
        downloadLink.download = "messages.csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();

        URL.revokeObjectURL(objectUrl);
        document.body.removeChild(downloadLink);
        setDownloadingCSV(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setDownloadingCSV(false);
      });
  };


  const handleApplyFilter = (appliedFilters: any) => {
    setFilters(appliedFilters);
    console.log(appliedFilters)
  };
  const isDownloadable = !!filters.userID;

  
  return (
    <div className="shadow-lg bg-white w-full h-full">
      <div className="h-16 bg-blue flex items-center justify-between">
        <div className="ml-4">
          <ArrowBackIcon onClick={handleReturn} className="cursor-pointer text-white" />
        </div>
        <MessageFilter onApplyFilter={handleApplyFilter}  setPage={setPage}/>
        <button
            className="bg-gray-300 p-2 rounded-md"
            onClick={isDownloadable ? handleDownload : undefined}
            disabled={!isDownloadable}
            >
            {downloadingCSV ? (
              <LoadingSpinner width={20} height={20} color="#ffffff" />
            ) : (
              <img src={DownloadIcon} className="pointer-events-none"></img>
            )}
          </button>
          {showTooltip && (
              <div style={{
                color: "red",
                marginTop: "5px"
              }}>
                You must pick a user
              </div>
            )}
        <div className="text-white font-semibold text-center flex-grow">
          Message Log
        </div>
        <div className="flex items-center mr-4">
          <button
            onClick={handlePrevPage}
            className="px-4 py-2 bg-blue text-white rounded-md"
          >
            <span className="text-sm">&#8592; Previous Page</span>
          </button>
          <span className=" text-white font-bold">
            {page}{" "}
            <span className="font-semibold" style={{ fontSize: "0.75em" }}>
              of
            </span>{" "}
            {Math.ceil((messages?.pagination?.total ?? 0)/10)}{" "}
          </span>
          <button
            onClick={handleNextPage}
            className={`px-4 py-2 bg-blue text-white rounded-md ${
              isLastPage ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isLastPage}
          >
            <span className="text-sm">Next Page &#8594;</span>
          </button>
        </div>
      </div>
      <div className="px-5 py-7 pb-20 w-full h-full overflow-hidden relative">
        <div className="w-full h-full overflow-x-scroll scrollbar-hide">
          <table className="w-full">
            <thead>
              <tr className="border-b-2 border-gray-300">
                <th className="text-center min-w-[150px]">Name</th>
                <th className="text-center min-w-[150px]">Source</th>
                <th className="text-center min-w-[150px]">Timestamp</th>
                <th className="text-center min-w-[150px]">CountryISO</th>
                <th className="text-center min-w-[150px]">Coordinates</th>
                <th className="text-center min-w-[150px]">Message</th>
              </tr>
            </thead>
            {messages?.messages?.map((message) => (
                <MessageEntry key={message._id} message={message} />
              ))}
          </table>
        </div>
      </div>
    </div>
  );
};
