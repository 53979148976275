import React, { useContext } from "react";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { FormDataContext } from "../../context/form";
function Gender() {
  const { data } = useContext(UserProfileContext)!;
  const formDataContext = useContext(FormDataContext)!;
  const { genderRef } = formDataContext;
  const userData: UserDataTypes = data?.data;
  return (
    <div className="">
      <label htmlFor="birth_gender" className="opacity-80">
        Gender
      </label>
      <select
        ref={genderRef}
        name="gender"
        id="gender"
        className="h-10 w-full rounded-md shadow-md px-4"
        defaultValue={
          userData.profile?.gender ? userData.profile?.gender : "select-gender"
        }
        required
      >
        <option value="select-gender" disabled>
          Select Gender
        </option>
        <option value="male">Male</option>
        <option value="female">Female</option>
        <option value="others">Others</option>
      </select>
    </div>
  );
}

export default Gender;
