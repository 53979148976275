import React from "react";

function Information() {
    return(
        <div className="flex flex-col gap-4 mt-8 px-8">
          <p>
            NGS provides high quality security assessments ensuring people can feel safe wherever they are travelling.
          </p>
          <div className="flex flex-col gap-2">
            There are two reports available:
            <ul className='mx-8'>
              <li>
                Remote Security Assessment: An assessment remotely completed by industry/security experts and based on an independent standard. Additional information will include wider issues relating to the country, specific city, and safety and security.
              </li>
              <li>
                On-site Security Assessment: A security expert will visit the nominated site and conduct a comprehensive assessment. This will include an understanding of the physical, organisational and technical vulnerabilities within existing security control measures.
              </li>
            </ul>
          </div>
          
          <p>
            Please fill out the form below to get in touch with our Security Risk Team if you would like to discuss your requirements further
          </p>
        </div>
    )
}

export default Information