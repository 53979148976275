import React from "react";
import IconMessage from "../../../../../assets/icons/icon-message.svg";
import Envelop from "../../../../../assets/icons/envelop.svg";
import CircleCheck from "../../../../../assets/icons/circle-check.svg";
import Phone from "../../../../../assets/icons/phone.svg";
import MessageChat from "../../../../../assets/icons/message-chat.svg";
import { Link } from "react-router-dom";

export default function MassWarningSingleEntry() {
  return (
    <Link to="1" className="flex w-full shadow-md rounded-xl mt-2">
      <button className="p-4">
        <img src={IconMessage} alt="" className="w-[16px]" />
      </button>
      <div className="grid grid-cols-[1fr_0.8fr_0.8fr_0.8fr_0.6fr] w-full py-1 justify-items-center items-center text-center">
        <div className="font-bold">Client Incident Alert, Seoul</div>
        <div>
          <div>29/10/22</div>
          <div className="text-light-grey">23:20:45</div>
        </div>
        <div>2910-06139449-1</div>
        <div>592</div>
        <div className="flex items-center">
          <button className="mr-1 w-[16px] h-[16px]" onClick={() => {}}>
            <img src={CircleCheck} alt="" />
          </button>
          <button className="mr-1 w-[16px] h-[16px]" onClick={() => {}}>
            <img src={Phone} alt="" />
          </button>
          <button className="mr-1 w-[16px] h-[16px]" onClick={() => {}}>
            <img src={Envelop} alt="" />
          </button>
          <button className="mr-1 w-[16px] h-[16px]" onClick={() => {}}>
            <img src={MessageChat} alt="" />
          </button>
        </div>
      </div>
    </Link>
  );
}
