import React, {useContext, useState} from "react";
import {Link, useParams} from "react-router-dom";

import useGetTripsCheckIns from "../../../../api/trips-check-ins/useGetTripsCheckIns";

import AddIcon from "../../../../assets/icons/add-icon.svg";
import ClockIcon from "../../../../assets/icons/clock-icon-white.svg";
import CrossIcon from "../../../../assets/icons/icon-cross.svg";
import {BlueButton} from "../../../../components/ui/Buttons";
import CheckInEntry from "./components/CheckInEntry";
import DeletePopUp from "./components/DeletePopUp";
import NewCheckInPopUp from "./components/NewCheckInPopUp";
import IndividualTripHeader from "./components/IndividualTripHeader";
import {CheckInType, EditCheckIn} from "../../types/check-ins-data-types";
import EditCheckInPopUp from "./components/EditCheckInPopUp";
import {NewCheckIn, TripsContext} from "../../context/trips";
import {useQuery, useQueryClient} from "react-query";
import moment from "moment";
import {apiDELETE, apiPOST, apiPUT} from "../../../../apiv2/request";
import {CheckInForm} from "./components/CheckInForm";
import {apiV2} from "../../../../api/axiosConfig";
import {isAxiosError} from "axios";
import {UserContext} from "../../../../context/user";
import {Trip} from "../../../../pages/TripsV2/types";

require("moment-timezone");

export default function IndividualTrip() {
  const {id: tripId} = useParams();
  const queryClient = useQueryClient();
  const {
    newCheckIn,
    selectedCheckIn,
    setSelectedCheckIn,
    fetchFilteredTrips,
  } = useContext(TripsContext);
  const {data: checkIns, status} = useGetTripsCheckIns(tripId!);
  const user = useContext(UserContext);


  const [showNewCheckInPopUp, setShowNewCheckInPopUp] =
      useState<boolean>(false);
  const [showEditCheckInPopUp, setShowEditCheckInPopUp] =
      useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [checkInIdToDelete, setCheckInIdToDelete] = useState<string>("");
  const [tripIdToDelete, setTripIdToDelete] = useState<string>("");
  const [tripDestinationName, setTripDestinationName] = useState<string>("");
  const [openCheckInId, setOpenCheckInId] = useState<string>("");
  const [showCreateForm, setShowCreateForm] = useState(false);

  const [errorMessage, setErrorMessage] = useState("")

  function calculateGracePeriod(checkInAt: Date, checkInBy: Date): number {
    // Parse the date strings into Date objects
    const checkInAtDate = new Date(checkInAt);
    const checkInByDate = new Date(checkInBy);

    // Calculate the difference in milliseconds
    const diffMs = checkInByDate.getTime() - checkInAtDate.getTime();

    // Convert milliseconds to minutes
    // 1 second = 1000 ms, 1 minute = 60 seconds
    const diffMinutes = Math.round(diffMs / (1000 * 60));

    return Number(diffMinutes);
  }

  const closeEditCheckInPopUp = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (e.currentTarget === e.target) {
      setShowEditCheckInPopUp(false);
    }
  };

  const closeNewCheckInPopUp = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (e.currentTarget === e.target) {
      setShowNewCheckInPopUp(false);
    }
  };

  const openEditCheckInPopUp = (tripID: string, checkInID: string) => {
    setErrorMessage("")
    if (checkInID) {
      const checkIn = checkIns.find(
          (checkIn: CheckInType) => checkIn.id === checkInID
      );

      setSelectedCheckIn!({
        tripID: tripID,
        checkInAt: checkIn?.checkInAt ? new Date(checkIn.checkInAt) : null,
        gracePeriod: calculateGracePeriod(
            checkIn?.checkInAt ? new Date(checkIn.checkInAt) : new Date(),
            checkIn?.checkInBy ? new Date(checkIn.checkInBy) : new Date()
        ),
        location: {
          countryISO: checkIn?.location.countryISO
              ? checkIn.location.countryISO
              : "",
          timezone: checkIn?.location.timezone ? checkIn.location.timezone : "",
          // city: checkIn?.location.city ? checkIn.location.city : "",
        },
        notes: checkIn?.notes || "",
      });
      setOpenCheckInId(checkInID);
      setShowEditCheckInPopUp(true);
    }
  };

  const openDeleteModal = (tripID: string, checkInID: string) => {
    if (tripID && checkInID) {
      setTripIdToDelete(tripID);
      setCheckInIdToDelete(checkInID);
      setShowDeleteModal(true);
    }
  };

  const deleteCheckIn = () => {

    apiDELETE(`${process.env.REACT_APP_API_V2_URL}/trips/${tripIdToDelete}/scheduled-check-ins/${checkInIdToDelete}`).then((res) => {
      console.log("deleted the check in from the database");
      queryClient.invalidateQueries("trips-check-ins");
      queryClient.invalidateQueries("trips");
      setShowDeleteModal(false);
    })
        .catch((err) => {
          console.log(err);
        });


  };

  const closeDeleteModal = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (e.currentTarget === e.target) {
      setShowDeleteModal(false);
    }
  };

  const trip = useQuery(
    ["trip", tripId],
    async () => {
      const response = await apiV2.get<Trip>(`trips/${tripId}`)
      return response.data
    }
  )

  const saveEditCheckInAndClose = (data: EditCheckIn) => {
    data.gracePeriod = Number(data.gracePeriod);

    const o: any = {...data};
    o.checkInAt = moment(data.checkInAt)
        .tz(data.location.timezone, true)
        .format("YYYY-MM-DDTHH:mm:ssZ");

    apiPUT(`${process.env.REACT_APP_API_V2_URL}/trips/${tripId}/scheduled-check-ins/${openCheckInId}`, {
      time: {
        time: moment(data.checkInAt).format("HH:mm"),
        date: moment(data.checkInAt).format("YYYY-MM-DD"),
      },
      countryISO: data.location.countryISO,
      gracePeriod: Number(data.gracePeriod)
    }).then((res) => {
      console.log("changed the existing check in");
      queryClient.invalidateQueries("trips-check-ins");
      queryClient.invalidateQueries("trips");
      queryClient.invalidateQueries(["trip", tripId])
      setShowEditCheckInPopUp(false);
    }).catch((err) => {
      const e = err as { error: string }
      console.log(e)
      setErrorMessage(e?.error || "An error occurred")
    });
  }

  const saveNewCheckInAndClose = (data: NewCheckIn) => {
    if (!data) {
      return;
    }

    const time = {
      date: moment(data.checkInAt).format("YYYY-MM-DD"),
      time: moment(data.checkInAt).format("HH:mm"),
      timezone: data.location.timezone
    }

    const req = {
      time,
      countryISO: data.location.countryISO,
      notes: data.notes,
      gracePeriod: Number(data.gracePeriod),
    }

    apiPOST(`${process.env.REACT_APP_API_V2_URL}/trips/${tripId}/scheduled-check-ins`, req).then(() => {
      setShowNewCheckInPopUp(false)
      queryClient.invalidateQueries("trips-check-ins");
      queryClient.invalidateQueries("trips");
      queryClient.invalidateQueries(["trip", tripId])
    }).catch((err) => {
      const e = err as { error: string }
      console.log(e)
      setErrorMessage(e?.error || "An error occurred")
    })
  };

  const [regenerateLoading, setRegenerateLoading] = useState(false)
  const [disableLoading, setDisableLoading] = useState(false)
  const [error, setError] = useState<string|null>(null)

  const disableCheckIns = async () => {
    setError(null)
    setDisableLoading(true)

    try {
      await apiV2.post(`trips/${tripId}/disable-check-ins`)
    } catch(e){
      if (isAxiosError(e)){
        if(e.response?.data?.error){
          setError(e.response.data?.error)
        }else {
          setError("An error occurred")
        }
      } else {
        setError("An error occurred")
      }
    }

    await queryClient.invalidateQueries("trips-check-ins");
    await queryClient.invalidateQueries("trips");
    await queryClient.invalidateQueries(["trip", tripId])


    setDisableLoading(false)
  }

  const regenerateCheckIns = async () => {
    setError(null)
    setRegenerateLoading(true)

    try {
      await apiV2.post(`trips/${tripId}/regenerate-check-ins`)
    } catch(e){
      if (isAxiosError(e)){
        if(e.response?.data?.error){
          setError(e.response.data?.error)
        }else {
          setError("An error occurred")
        }
      } else {
        setError("An error occurred")
      }
    }

    await queryClient.invalidateQueries("trips-check-ins");
    await queryClient.invalidateQueries("trips");
    await queryClient.invalidateQueries(["trip", tripId])


    setRegenerateLoading(false)
  }

  return (
      <>
        {showCreateForm && <CheckInForm setShowModal={setShowCreateForm} tripID={tripId}/>}
        <div className="h-screen pt-36 pb-16">
          <div className="flex w-full justify-between items-center px-16 pb-5">
            <div className="flex">
              <Link to="/trips">Trips Table</Link>
              <span className="mx-5">{">"}</span>
              <span>{tripDestinationName} Trip</span>
            </div>
            <div className="flex flex-row gap-4">
              {user?.role?.auroraAccessLevel == "all" && trip.data &&
                  <>

                      <div className={"w-[250px]"}>
                          <BlueButton
                              disabled={trip.data.checkInSettings.disableGeneration && trip.data.checkInSettings.disableGenerationReason == "admin_override" }
                              text="Disable Check Ins"
                              onClick={() => disableCheckIns()}
                              icon={CrossIcon}
                              loading={disableLoading}
                          />
                      </div>

                      <div className={"w-[250px]"}>
                          <BlueButton
                              text="Regenerate Check Ins"
                              onClick={() => regenerateCheckIns()}
                              icon={ClockIcon}
                              loading={regenerateLoading}
                          />
                      </div>
                  </>
              }
              <div className={"w-[250px]"}>
                <BlueButton
                  text="Add New Check Ins"
                  onClick={() => setShowCreateForm(true)}
                  icon={AddIcon}
                />
              </div>
            </div>


          </div>
          <div className="w-full bg-white h-full pb-20 px-20 pt-8">
            {error && <div className={"capitalize text-red mb-4 text-center"}>{error}</div>}
              {trip.data?.checkInSettings.disableGenerationReason == "no_app_install" && <div className={"text-red mb-4 text-center"}>
                Check ins not generated due to app install not being detected
              </div>}

            {trip.data?.checkInSettings.disableGenerationReason == "admin_override" && <div className={"text-red mb-4 text-center"}>
                Check in generation has been disabled by an admin
            </div>}

            {checkIns && checkIns.length !== 0 ? (
              <>
                  <IndividualTripHeader/>
                  <div className="overflow-hidden h-[calc(100vh_-_400px)] pt-3">
                    <div className="overflow-y-scroll h-full">
                      {status === "error" && <div>Error, try again</div>}
                      {status === "loading" && <div>Loading...</div>}
                      {status === "success" &&
                          checkIns.map((checkIn: CheckInType) => {
                            return (
                                <CheckInEntry
                                    key={checkIn.id}
                                    data={checkIn}
                                    openPopUp={(tripID, checkInID) => openEditCheckInPopUp(tripID, checkInID)}
                                    onDelete={(tripID, checkInID) => openDeleteModal(tripID, checkInID)}
                                />
                            );
                          })}
                    </div>
                  </div>
                </>
            ) : (
                <div>No Check Ins</div>
            )}
          </div>
          {showNewCheckInPopUp && (
              <NewCheckInPopUp
                  closeCheckInPopUp={closeNewCheckInPopUp}
                  saveAndClose={saveNewCheckInAndClose}
                  errorMessage={errorMessage}
              />
          )}
          {showEditCheckInPopUp && (
              <EditCheckInPopUp
                  errorMessage={errorMessage}
                  closeCheckInPopUp={closeEditCheckInPopUp}
                  checkIn={selectedCheckIn!}
                  saveAndClose={(data) => saveEditCheckInAndClose(data)}
              />
          )}
          {showDeleteModal && (
              <DeletePopUp
                  popUpText="You are about to delete a Check In."
                  closeDeleteModal={closeDeleteModal}
                  deleteEntry={deleteCheckIn}
              />
          )}
        </div>
      </>
  );
}
