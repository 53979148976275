import { useEffect, useState } from "react";
import api from "../../../../../api/axiosConfig";



const useAirlineRisks = () => {
    const [data, setData] = useState([]); // Assuming 'data' will hold an array
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null); // Error state is now explicitly a string or null
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await api.get("/airline-risk-data");
          setData(response.data.data);
          setError(null);
        } catch (err) {
          setError(err instanceof Error ? err.message : "An unknown error occurred");
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, []);
  
    return { data, loading, error };
  };
  
  export default useAirlineRisks;