import React, { createContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Client, Users } from "../../types/userManagement";
import { getUsers } from "../../api/userManagementAPIs";

interface ReportsContextInterface {
  clients: Client[];
  users: Users[];
}

export const ReportsContext = createContext<Partial<ReportsContextInterface>>(
  {}
);

export default function ReportsDataProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const queryClient = useQueryClient();
  // const clientsData = useQuery("clients", getClients);
  const usersData = useQuery("users", getUsers);

  const [clients, setClients] = useState<Client[]>([]);
  const [users, setUsers] = useState<Users[]>([]);

  // useEffect(() => {
  //   if (clientsData.data && clientsData.isSuccess) {
  //     setClients(clientsData.data);
  //   }
  // }, [clientsData.data]);

  useEffect(() => {
    if (usersData.data && usersData.isSuccess) {
      setUsers(usersData.data);
    }
  }, [usersData.data]);

  return (
    <ReportsContext.Provider value={{ clients }}>
      {children}
    </ReportsContext.Provider>
  );
}
