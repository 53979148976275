import React from "react";

function Overview({
  shortDescription,
  category_name,
  startedAtDate,
  startedAtTime,
  link,
}: {
  shortDescription: string;
  category_name: string;
  startedAtDate: string;
  startedAtTime: string;
  link: string | null;
}) {
  return (
    <div className="px-4">
      <div className="grid grid-cols-2 gap-x-4">
        <label className="font-semibold">Severity</label>
        <span>{shortDescription}</span>
        <label className="font-semibold">Category</label>
        <span>{category_name}</span>
      </div>
      <div className="grid grid-cols-2 gap-x-4">
        <label className="font-semibold">Date</label>
        <span>{startedAtDate}</span>
        <label className="font-semibold">Time</label>
        <span>{startedAtTime}</span>
      </div>

      {link ? (
        <a href={link} target="_blank" className="underline text-blue font-bold" rel="noreferrer">
          Source
        </a>
      ) : (
        <span className="text-blue font-semibold">No source available</span>
      )}
    </div>
  );
}

export default Overview;
