import { FeatureType } from "../types";

type State = FeatureType[];
type Action = {
  type: "ADD_FEATURE" | "EDIT_FEATURE" | "DELETE_FEATURE" | "SET_FEATURES";
  payload: FeatureType | FeatureType[];
};
export default function featuresReducer(state: State, action: Action) {
  const { type } = action;
  switch (type) {
    case "ADD_FEATURE": {
      const payload: FeatureType = action.payload as FeatureType;
      const newState = [...state, payload];
      return newState;
    }
    case "EDIT_FEATURE": {
      const payload: FeatureType = action.payload as FeatureType;
      const filteredState = state.filter(
        (feature) => feature.uuidv4 !== payload.uuidv4
      );
      if (payload.drawingType === "marker") {
        payload.drawing?.setOptions({
          label: {
            text: payload.properties.icon || "",
            fontFamily: "Material Icons",
            color: "#ffffff",
            fontSize: "18px",
          },
        });
      }
      if (payload.drawingType === "text") {
        console.log(payload);
        payload.drawing?.setOptions({
          editable: true,
          label: {
            text: payload.properties.label!,
            fontSize: `${payload.properties.style.fontSize?.toString()}px`,
            color: payload.properties.style.fontColor,
          },
        });
      }
      payload.drawing?.setOptions({
        strokeColor: payload.properties.style.lineColor,
        strokeWeight: payload.properties.style.lineWidth,
        fillColor: payload.properties.style.fillColor,
      });
      if (payload.properties.radius) {
        payload.drawing?.setOptions({ radius: payload.properties.radius });
      }
      const newState = [...filteredState, payload];
      return newState;
    }
    case "DELETE_FEATURE": {
      const payload: FeatureType = action.payload as FeatureType;
      payload.drawing?.setMap(null);
      const filteredState = state.filter(
        (feature) => feature.uuidv4 !== payload.uuidv4
      );
      return filteredState;
    }
    case "SET_FEATURES": {
      const setFeatures: FeatureType[] = action.payload as FeatureType[];
      return setFeatures;
    }
    default:
      return state;
  }
}
