import React from "react";
import { SitesEditZoneMain } from "../../../features/sites/pages/edit-zone";

function EditZone() {
  return (
    <div className="h-full w-full flex flex-1 my-8 relative">
      <SitesEditZoneMain />
    </div>
  );
}

export default EditZone;
