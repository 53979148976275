import React from "react";
import { CustomOverlaysTable } from "../../../features/sites/pages/custom-overlays";

function CustomOverlay() {
  return (
    <div className="w-full h-full py-8">
      <CustomOverlaysTable />
    </div>
  );
}

export default CustomOverlay;
