import React from "react";
import { IncidentDataTypes } from "../../../../../types/incident-data-types";
import moment from "moment";
import { getName } from "country-list";


export default function IncidentEntry({
    data,
  }: {
    data: IncidentDataTypes;
  }) {


    const startedAt = moment(data.startedAt).format("D/MM/Y");
    const countryName = (
      data.countryISO === "GB" 
        ? "United Kingdom" 
        : (data.countryISO 
            ? (getName(data.countryISO) || data.countryISO).split(",")[0].trim()
            : "")
    );
    

  
    return (
      <div className="px-2 py-3 border-b-1 w-full grid grid-cols-6 items-center h-full">
        <div className="text-center">
          {startedAt}
        </div>
        <div className="text-center">
          {data.typeName}
        </div>
        <div className="text-center">
          {data.severity}
        </div>
        <div className="text-center">
          {data.summary}
        </div>
        <div className="text-center">
          {countryName}
        </div>
        <div className="text-center">
          {data.reference && data.reference[0] && data.reference[0].link ? (
            <a href={data.reference[0].link} className="underline text-blue font-bold" target="_blank" rel="noreferrer">
              View
            </a>
          ) : (
            <span className="text-blue ">Source not available</span>
          )}      
        </div>
      </div>
    );
  }
  