/**
 * @description allow access to user management page
 * @returns boolean
 */
import { useContext, useEffect } from "react";
import { UserContext } from "../context/user";
export default function useRestrictedUserManagement() {
  const userContext = useContext(UserContext);
  return userContext?.role.auroraModules.includes("all") ||
    userContext?.role.auroraModules.includes("user_management")
    ? true
    : false;
}
