import React from "react";
import { useQueryClient } from "react-query";
import api from "../../../../../../api/axiosConfig";
import { Bookmark } from "@mui/icons-material";
import { downloadFile } from "../../../../../../util/downloadFile";
import DownloadIcon from "../../../../../../assets/icons/download-grey.svg";
type AdviceGuidesSidebarTypes = {
  icon: string;
  name: string;
  guide: any;
  guideAzureType: string;
};

function AdviceGuidesSidebarCard({
  guide,
  icon,
  name,
  guideAzureType,
}: AdviceGuidesSidebarTypes) {
  const queryClient = useQueryClient();
  const deleteFromSaved = () => {
    api.post(`saved/advice-guides?id=${guide.id}`).then(() => {
      queryClient.invalidateQueries("saved-advice-guides");
    });
  };
  const borderColor =
    guideAzureType === "travel"
      ? `${`border-[${"#68AF6D"}] border-2`}`
      : guideAzureType === "hazard"
      ? `${`border-[${"#E6920A"}] border-2`}`
      : guideAzureType === "medical"
      ? `${`border-[${"#C64805"}] border-2`}`
      : `${`border-[${"#A15FB2"}] border-2`}`;

  const handleDownloadGuide = () =>
    downloadFile(
      `${process.env.REACT_APP_API_URL}files/advice-guide/${guide.id}`,
      guide.name
    );
  return (
    <li
      className={`flex items-center justify-between p-2 rounded-2xl shadow-lg ${borderColor}`}
    >
      <div className="p-1 bg-white w-7 h-7 rounded-full shadow-[0px_4px_10px_-3px_#00000070]">
        <img src={icon} alt="" />
      </div>
      <span className="flex-1 pl-4 pr-12">{name}</span>
      <button
        className="h-8 w-8 flex items-center justify-center"
        onClick={handleDownloadGuide}
      >
        <img src={DownloadIcon} alt="" />
      </button>
      <button onClick={deleteFromSaved}>
        <Bookmark className="text-yellow" />
      </button>
    </li>
  );
}

export default AdviceGuidesSidebarCard;
