import React, { useContext } from "react";
import { DepartmentDataTypes } from "../../../../../../types/department-data-types";

import { FormDataContext } from "../../context/form";
function Department({
  departments,
  defaultDepartmentID,
}: {
  departments: DepartmentDataTypes[];
  defaultDepartmentID: string | null;
}) {
  const formDataContext = useContext(FormDataContext)!;
  const { departmentRef } = formDataContext;
  return (
    <div className="">
      <label htmlFor="department" className="opacity-80">
        Department
      </label>
      <select
        className="h-10 w-full rounded-md shadow-md px-4 flex items-center justify-start"
        ref={departmentRef}
        defaultValue={
          defaultDepartmentID ? defaultDepartmentID : "select-department"
        }
      >
        <option value={"select-department"}>Select Department</option>
        {departments?.map((department) => (
          <option key={department.id} value={department.id}>
            {department.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Department;
