import React, { createContext, ReactNode, SetStateAction, useState } from "react";

type FormsContextType = {
  addIndividualFacility: boolean;
  addMultipleFacilities: boolean;
  addZone: boolean;
  addCustomOverlay: boolean;
  siteBasedAlertModal: boolean;
  setAddIndividualFacility: React.Dispatch<SetStateAction<boolean>>;
  setAddMultipleFacilities: React.Dispatch<SetStateAction<boolean>>;
  setAddZone: React.Dispatch<SetStateAction<boolean>>;
  setAddCustomOverlay: React.Dispatch<SetStateAction<boolean>>;
  setSiteBasedAlertModal: React.Dispatch<SetStateAction<boolean>>;
};

const defaultValues = {
  addIndividualFacility: false,
  addMultipleFacilities: false,
  addZone: false,
  addCustomOverlay: false,
  siteBasedAlertModal: false,
  setAddIndividualFacility: () => {},
  setAddMultipleFacilities: () => {},
  setAddZone: () => {},
  setAddCustomOverlay: () => {},
  setSiteBasedAlertModal: () => {},
};

export const FormsContext = createContext<FormsContextType>(defaultValues);

export default function FormsContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [addIndividualFacility, setAddIndividualFacility] =
    useState<boolean>(false);
  const [addMultipleFacilities, setAddMultipleFacilities] =
    useState<boolean>(false);
  const [addZone, setAddZone] = useState<boolean>(false);
  const [addCustomOverlay, setAddCustomOverlay] = useState<boolean>(false);
  const [siteBasedAlertModal, setSiteBasedAlertModal] = useState(false);
  const value = {
    addIndividualFacility,
    addMultipleFacilities,
    addZone,
    addCustomOverlay,
    siteBasedAlertModal,
    setAddIndividualFacility,
    setAddMultipleFacilities,
    setAddZone,
    setAddCustomOverlay,
    setSiteBasedAlertModal,
  };
  return (
    <FormsContext.Provider value={value}>{children}</FormsContext.Provider>
  );
}
