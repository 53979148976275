import React from "react";
import NavSidebar from "../../../features/risk/layout/nav-sidebar";
import Main from "../../../features/risk/layout/main";

function Home() {
  return (
    <div className="pt-36 pb-10 min-h-screen h-full w-full flex">
      <NavSidebar />
      <Main />
    </div>
  );
}

export default Home;
