import React, { RefObject } from "react";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import FormSection from "../form-section";
import InputText from "../../../../../../components/form/InputText";
import MedicalConditions from "./MedicalConditions";
import Medication from "./Medication";
import Allergies from "./Allergies";
import BloodType from "./BloodType";
import CovidVaccinationStatus from "./CovidVaccinationStatus";
import NumberOfCOVIDVaccination from "./NumberOfCOVIDVaccination";
function MedicalInformation() {
  return (
    <FormSection title={"Medical Information"}>
      <div className="grid grid-cols-4 gap-8">
        <MedicalConditions />
        <Medication />
        <Allergies />
        <BloodType />
        {/* <CovidVaccinationStatus />
        <NumberOfCOVIDVaccination /> */}
      </div>
    </FormSection>
  );
}

export default MedicalInformation;
