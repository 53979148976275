import { useEffect, useState } from "react";

export default function useCountryNameToGeoCode(country_name: string) {
  const [geoCode, setGeoCode] = useState<{ lat: number; lng: number }>();

  useEffect(() => {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode(
      {
        address: country_name,
      },
      (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          setGeoCode({
            lat: results![0].geometry.location.lat(),
            lng: results![0].geometry.location.lng(),
          });
        } else {
          alert(
            "Geocode was not successful for the following reason: " + status
          );
        }
      }
    );
  }, []);

  return geoCode;
}
