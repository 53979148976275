import React, { SetStateAction, useEffect, useRef, useState } from "react";
import LocationSVG from "../../../../assets/icons/location-pin-svg.svg";
import PolygonIcon from "../../../../assets/icons/draw-on-map-lines.svg";
import { FeatureType } from "../../types";
import { useSearchParams } from "react-router-dom";
import EditIcon from "../../../../assets/icons/icon-edit.svg";
import BinIcon from "../../../../assets/icons/icon-bin.svg";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import CropFreeIcon from "@mui/icons-material/CropFree";
function FeatureItem({
  type,
  feature,
  setEditModal,
  handleDelete,
  map,
  setFocusOn,
  focusOn,
}: {
  type: "marker" | "circle" | "polygon" | "polyline" | "text" | undefined;
  feature: FeatureType;
  setEditModal: React.Dispatch<SetStateAction<boolean>>;
  handleDelete: (feature: FeatureType) => void;
  map: google.maps.Map | undefined;
  setFocusOn: React.Dispatch<SetStateAction<string | null>>;
  focusOn: string | null;
}) {
  const [focusFeature, setFocusFeature] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!focusOn) return setFocusFeature(false);

    if (focusOn === feature.uuidv4) {
      setFocusFeature(true);
    } else {
      setFocusFeature(false);
    }
  }, [focusOn, feature.uuidv4]);

  const handleFocus = () => {
    if (!focusFeature) return;
    setFocusOn(feature.uuidv4 as string);
    if (feature.drawingType === "circle") {
      const drawing = feature.drawing as google.maps.Circle;
      map?.setCenter(drawing.getCenter() as google.maps.LatLng);
    } else if (
      feature.drawingType === "marker" ||
      feature.drawingType === "text"
    ) {
      const marker = feature.drawing as google.maps.Marker;
      map?.setCenter(marker.getPosition() as google.maps.LatLng);
    } else if (feature.drawingType === "polygon") {
      const bounds = new google.maps.LatLngBounds();
      let i;
      const polygon = feature.drawing as google.maps.Polygon;
      const polygonCoords = polygon
        .getPath()
        .getArray() as google.maps.LatLng[];

      for (i = 0; i < polygonCoords.length; i++) {
        bounds.extend(polygonCoords[i]);
      }
      map?.setCenter(bounds.getCenter());
    } else {
      const line = feature.drawing as google.maps.Polyline;
      const bounds = new google.maps.LatLngBounds();
      let i;
      const lineCoords = line.getPath().getArray() as google.maps.LatLng[];

      for (i = 0; i < lineCoords.length; i++) {
        bounds.extend(lineCoords[i]);
      }
      map?.setCenter(bounds.getCenter());
    }
  };

  useEffect(() => {
    if (!map) return;
    handleFocus();
  }, [map, focusFeature]);

  if (!type) return null;
  return (
    <div
      className={"border-b border-grey border-opacity-10 px-4 py-2 flex items-center gap-4 mx-4 last:border-none"}
    >
      {type === "circle" ? (
        <div className="h-6 w-6 flex items-center justify-start">
          <div className="h-4 w-4 rounded-full border-2"></div>
        </div>
      ) : type === "polyline" ? (
        <div className="h-6 w-6 flex items-center justify-start">
          <div className="w-3/5 h-[2px] bg-grey rotate-45"></div>
        </div>
      ) : type === "marker" ? (
        <div className="h-6 w-6 flex items-center justify-start">
          <img src={LocationSVG} alt="" />
        </div>
      ) : type === "polygon" ? (
        <div className="h-6 w-6 flex items-center justify-start">
          <img src={PolygonIcon} alt="" />
        </div>
      ) : (
        <div className="h-6 w-6 flex items-center justify-start">
          <span>T</span>
        </div>
      )}

      <span
        className="flex-1 cursor-pointer"
        onMouseEnter={() => {
          feature.drawing?.setOptions({
            strokeWeight:
              feature.properties.style.lineWidth &&
              feature.properties.style.lineWidth + 4,
          });
        }}
        onMouseLeave={() => {
          feature.drawing?.setOptions({
            strokeWeight: feature.properties.style.lineWidth,
          });
        }}
      >
        {feature.properties.name}
      </span>
      <div className="flex items-center justify-center gap-4">
        <button
          className="h-6 w-6 rounded-md shadow-md flex items-center justify-center relative"
          onClick={() => {
            setFocusOn(feature.uuidv4!);
          }}
        >
          {focusFeature ? <CenterFocusStrongIcon /> : <CropFreeIcon />}
        </button>
        <button
          className="h-6 w-6 rounded-md shadow-md flex items-center justify-center"
          onClick={() => {
            setSearchParams({
              type,
              feature: feature.uuidv4!,
            });
            setEditModal(true);
          }}
        >
          <img src={EditIcon} alt="" />
        </button>
        <button
          className="h-6 w-6 rounded-md shadow-md flex items-center justify-center"
          onClick={() => handleDelete(feature)}
        >
          <img src={BinIcon} alt="" className="w-4" />
        </button>
      </div>
    </div>
  );
}

export default FeatureItem;
