import React, { SetStateAction, useEffect, useRef, useState } from "react";
import InputRadio from "../../../../components/form/InputRadio";
import ZonesDataTypes, { GeofencesDataTypes } from "../../types";

const zoneTypeOptions = [
  { label: "Geofences", value: "geofences" },
  { label: "Safety Zone", value: "safetyzones" },
];

function ZoneType({
  zoneType,
  setZoneType,
  data,
  locked,
}: {
  zoneType: { geofences: boolean; safetyZones: boolean };
  setZoneType: React.Dispatch<
    SetStateAction<{ geofences: boolean; safetyZones: boolean }>
  >;
  data: ZonesDataTypes | GeofencesDataTypes;
  locked?: boolean;
}) {
  return (
    <div className="flex flex-col gap-2 py-4">
      <span className="font-bold text-sm text-center">Step 3 : Zone Type</span>
      <div className="flex flex-col gap-4 mx-auto">
        {zoneTypeOptions.map((type) => (
          <InputRadio
            key={type.value}
            name={"zone-type"}
            value={type.value}
            label={type.label}
            disabled={locked}
            onSelect={(value) => {
              if (value === "geofences") {
                setZoneType({ safetyZones: false, geofences: true });
              } else {
                setZoneType({ safetyZones: true, geofences: false });
              }
            }}
            selectedZoneType={
              zoneType.geofences
                ? "geofences"
                : zoneType.safetyZones
                ? "safetyzones"
                : ""
            }
          />
        ))}
      </div>
    </div>
  );
}

export default ZoneType;
