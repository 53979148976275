import React, { useContext } from "react";
import { Main } from "../../../features/user-management/pages/client-users/index";
import { ClientUsersContext } from "../../../features/user-management/context/client-users";

import LoadingPage from "./LoadingPage";

function ClientUsersHome() {
  const clientContext = useContext(ClientUsersContext);

  return clientContext?.allUsers.status !== "success" ||
    clientContext.allUsers.isFetching ? (
    <LoadingPage allUsersStatus={clientContext!.allUsers.status} />
  ) : (
    <div className="flex h-full pt-36 pb-16 px-7">
      <Main />
    </div>
  );
}

export default ClientUsersHome;
