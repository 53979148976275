import { useSearchParams } from "react-router-dom";
import { FacilityDataTypes } from "../../../types/facilitiy-data-types";
import { UserLocationDataTypes } from "../../../types/user-location-data-types";
import { FeatureType } from "../../sites/types";

export const renderInfoWindow = (
  content: string | Element | Text | null | undefined,
  ariaLabel: string | null | undefined
) => {
  return new google.maps.InfoWindow({
    content,
    ariaLabel,
  });
};

export const renderFacilitiyString = (facility: FacilityDataTypes) => {
  return `<div style="font-size: 14px;  padding: 16px">
      <div>
        <p style=""><span style="font-weight: 500;">Facility Name:</span> ${
          facility.name
        }</p>
        <p style=""><span style="font-weight: 500;">Facility Client Name:</span> ${
          facility.clientName
        }</p>
        <p style=""><span style="font-weight: 500;">Type:</span> ${
          facility.type.airport
            ? "Airport"
            : facility.type.hospital
            ? "Hospital"
            : facility.type.hotel
            ? "Hotel"
            : facility.type.office
            ? "Office"
            : facility.type.pointOfInterest
            ? "Point of Interest"
            : facility.type.privateResidence
            ? "Private Residence"
            : "No type"
        }</p>
      </div>
    </div>`;
};

export const renderUserDiv = (user: UserLocationDataTypes) => {
  const { userName, phoneNumbers, email, clientName, source } = user;

  const phoneNumber =
    phoneNumbers?.filter((num) => num.primary)[0]?.number || "No phone number";
  const contentString = `
          <div class="map-incident-dialog">
            <p>
              <span>Name:</span>
               ${userName}
            </p>
            <p><span >Phone Number:</span> ${phoneNumber}</p>
            <p><span >Email:</span> ${email}</p>
            <p><span >Client Name:</span> ${clientName}</p>
            <p>
            <span>Source:</span>
              <span class="info-source">${
                source === "facility" ? "Stationed Facility" : source
              }</span>
            </p>
          </div>`;

  return contentString;
};
