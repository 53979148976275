import React, {ChangeEvent, InputHTMLAttributes} from "react";
import SearchIcon from "../../assets/icons/icon-search.svg";

type SearchTypes = {
  placeholder: string;
  onSearch?: (value: string) => void;
  id?: string;
  onFocus?: () => void;
};

function Search({ placeholder, onSearch, id, onFocus }: SearchTypes) {
  return (
      <div className="relative min-w:w-fit">
          <input
              id={id}
              placeholder={placeholder}
              className="outline-none pl-4 pr-10 h-12 rounded-lg shadow-md w-full"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const value = e.currentTarget.value;
                  if (onSearch) {
                      onSearch(value);
                  }
              }}
              onFocus={onFocus}
          />
          <img
              src={SearchIcon}
              alt=""
              className="absolute top-2/4 -translate-y-2/4 right-4"
          />
      </div>
  );
}

export default Search;


type SearchTextInputProps = InputHTMLAttributes<HTMLInputElement>


export const SearchTextInput: React.FC<SearchTextInputProps> = (props) => {

    return (
        <div className="relative min-w:w-fit">
            <input {...props} className="outline-none pl-4 pr-10 h-12 rounded-lg shadow-md w-full"/>
            <img
                src={SearchIcon}
                alt="search icon"
                className="absolute top-2/4 -translate-y-2/4 right-4"
            />
        </div>
    );
}