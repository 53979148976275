import React, { useEffect, useState } from "react"
import SearchableSelect, { SearchableSelectOption } from "."
import { UserDataTypes } from "../../../types/user-data-types"
import api from "../../../api/axiosConfig"
import { PaginatedResponse } from "../../../types/PaginatedResponse"
import GlobeIcon from "../../../assets/icons/globe.svg";
import {countries as countryList, CountryType} from "../../../constants/countries";

type CountrySelectParams = {
  clientID?: string | null;
  departmentID?: string | null;
  multiple?: boolean;
  onSelectionChange?: (countries: CountryType[]) => void
  countries: CountryType[],
}

function CountrySelect({
  countries,
  clientID,
  departmentID,
  multiple,
  onSelectionChange,
}: CountrySelectParams){

  const [loading, setLoading] = useState(false)
  const [options, setOptions]  = useState<SearchableSelectOption<CountryType>[]>([])
  const [searchStr, setSearchStr] = useState("")

  const fetchCountries = async () => {
    setLoading(true)
    try{

      const opts = countryList.map((c)=> ({
        value: c,
        label: c.name,
        key: c.code,
      }))



      setOptions(opts.filter(c => {
        return c.label.toLowerCase().includes(searchStr.toLowerCase())
      }))

    } catch(e){
      console.error(e)
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchCountries()
  }, [searchStr])

  useEffect(() => {
    onChange([])
  }, [clientID, departmentID])

  const onChange = (selected: SearchableSelectOption<CountryType>[]) => {
    if(onSelectionChange){
      onSelectionChange(selected.map(s => s.value))
    }
  }

  const onSearch = (value: string) => {
    setSearchStr(value)
  }

  return (<SearchableSelect
    icon={GlobeIcon}
    label={multiple ? "Countries" : "Country"}
    selected={countries.map(c => ({
      label: c.name,
      value: c,
      key: c.code,
    }))}
    search={true}
    multiple={!!multiple}
    options={options}
    localSearch={false}
    onChange={onChange}
    onSearch={onSearch}
    loading={loading}
  />)
}

export default CountrySelect