import React, { ChangeEvent, RefObject, useRef, useState } from "react";
import FormSection from "../form-section";
import ExternalIDInputWrapper from "./ExternalIDInputWrapper";
import { GreyButton } from "../../../../../../components/ui/Buttons";
function ExternalIDs() {
  const [externalIDs, setExternalIDs] = useState([{ type: "", value: "" }]);

  const addMore = () => {
    setExternalIDs((prev) => [...prev, { type: "", value: "" }]);
  };

  const handleChange = (
    newData: { type: string; value: string },
    index: number
  ) => {
    const newExternalIDs = [...externalIDs];
    newExternalIDs[index].type = newData.type;
    newExternalIDs[index].value = newData.value;

    setExternalIDs(newExternalIDs);
  };

  const handleRemove = (value: string, index: number) => {
    const newExternalIDs = externalIDs.filter(
      (item) => externalIDs.indexOf(item) !== index
    );
    setExternalIDs(newExternalIDs);
  };

  // console.log(externalIDs);

  return (
    <FormSection title="External IDs">
      <div className="flex flex-col gap-4">
        {externalIDs.map((item, i) => {
          return (
            <ExternalIDInputWrapper
              item={item}
              index={i}
              handleChange={handleChange}
              handleRemove={handleRemove}
            />
          );
        })}
      </div>
      <div className="w-fit my-4">
        <GreyButton text="Add more" onClick={addMore} />
      </div>
    </FormSection>
  );
}

export default ExternalIDs;
