import { Close } from "@mui/icons-material";
import React, { SetStateAction } from "react";

function RiskRatings({
  setOpen,
}: {
  setOpen: React.Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <div className="bg-white p-8 max-h-[70vh] max-w-7xl h-fit overflow-y-auto relative rounded-md">
      <button className="absolute right-4 top-4" onClick={() => setOpen(false)}>
        <Close />
      </button>
      <header className="h-10 border-b text-center mb-8">Risk Ratings</header>

      <table className="border-separate border-spacing-y-4 w-full">
        <tr className="">
          <th className="pb-4 text-left text-grey">Risk Level</th>
          <th className="text-left pb-4 pl-8 text-grey">Information</th>
        </tr>

        <tbody className="">
          <tr className="bg-light-light-grey ">
            <td className="flex flex-col items-center gap-4 text-lg font-semibold justify-center p-12">
              <span>Insignificant:</span>
            </td>
            <td className="p-8">
              <p>
              No injuries or health effects. Minimal financial loss. Negligible disruption to business operations. Impacts can be <span className="font-semibold">resolved.</span>
              </p>
            </td>
          </tr>
          <tr className="bg-light-light-grey">
            <td className="flex flex-col items-center text-lg font-semibold gap-4 p-12">
              <span>Limited:</span>
            </td>
            <td className="p-8">
              <p>
              Minimal injuries or health effects. Minor financial loss. Brief interruption in business operations, quickly <span className="font-semibold">restored.</span> Impacts are <span className="font-semibold"> largely resolved.</span>
              </p>
            </td>
          </tr>
          <tr className="bg-light-light-grey">
            <td className="flex flex-col items-center text-lg font-semibold gap-4 p-12">
              <span>Moderate:</span>
            </td>
            <td className="p-8">
              <p>
              Moderate injuries or health effects. Some financial loss. Business operations face <span className="font-semibold">short-term disruption.</span> Impacts are <span className="font-semibold">somewhat resolved.</span>
              </p>
            </td>
          </tr>
          <tr className="bg-light-light-grey">
            <td className="flex flex-col items-center text-lg font-semibold gap-4 p-12">
              <span>Major:</span>
            </td>
            <td className="p-8">
              <p>
              Severe injuries or health effects. Considerable financial loss. Significant business interruption lasting a couple of <span className="font-semibold">workdays.</span> Some impacts may be <span className="font-semibold">permanent.</span>
              </p>
            </td>
          </tr>
          <tr className="bg-light-light-grey">
            <td className="flex flex-col items-center text-lg font-semibold gap-4 p-12">
              <span>Extreme:</span>
            </td>
            <td className="p-8">
              <p>
              Loss of life or severe injuries. Substantial financial loss. Prolonged business interruption lasting <span className="font-semibold">more than one business week.</span> A portion of the impacts are <span className="font-semibold">lasting.</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default RiskRatings;
