import React, { useState, useEffect } from "react";
// @ts-ignore
import { EditorState, convertToRaw } from "draft-js";
// @ts-ignore
import { Editor } from "react-draft-wysiwyg";
// @ts-ignore
import draftToHtml from "draftjs-to-html";
// @ts-ignore
import { stateFromHTML } from "draft-js-import-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BlueButton } from "../Buttons";

interface EditableContentProps {
  initialContent: string;
  onSave: (html: string) => void;
  height?: string;
}

const TextTemplate: React.FC<EditableContentProps> = ({
  initialContent,
  onSave,
  height,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isEditor, setIsEditor] = useState(false);

  useEffect(() => {
    if (initialContent) {
      const contentState = stateFromHTML(initialContent);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [initialContent]);

  const toggleEdit = () => {
    setIsEditor(!isEditor);
  };

  const saveEdit = () => {
    const updatedHtml = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    onSave(updatedHtml);
    setIsEditor(false);
  };

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  return (
    <div>
      {isEditor ? (
        <div
          className={`${
            height ? `h-[${height}] overflow-y-auto` : "h-auto"
          } min-h-[200px]`}
        >
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
          />
          <div className="w-[100px] mx-auto">
            <BlueButton text="Save" onClick={saveEdit} />
          </div>
        </div>
      ) : (
        <div
          onClick={toggleEdit}
          className={`p-4 bg-desaturated-grey cursor-pointer rounded-lg min-h-[200px] ${
            height ? `h-[${height}] overflow-y-auto` : "h-auto"
          }`}
          dangerouslySetInnerHTML={{
            __html: initialContent ? initialContent : "<p>Click to edit</p>",
          }}
        />
      )}
    </div>
  );
};

export default TextTemplate;
