import React, {createContext, useContext, useEffect, useState} from "react";
import api from "../../api/axiosConfig";

type CountryRiskContextType = {
  items: Record<string, number|undefined>
  getRisk: (countryISO: string) => number | undefined
  loaded: boolean
}

const CountryRiskContext = createContext<
    CountryRiskContextType | undefined
>(undefined);


export const useCountryRisk = () => {
  const context = useContext(CountryRiskContext);
  if (!context) {
    throw new Error(
        "useCountryRisk must be used within CountryRiskProvider",
    );
  }
  return context;
};

const items: Record<string, number|undefined> = {}

export const CountryRiskProvider: React.FC<React.PropsWithChildren> = ({children}) => {

  const [loaded, setLoaded] = useState(false)
  // const [items, setItems] = useState<Record<string, number>>({})
  const getRisk = (countryISO: string) => {
    return items[countryISO]
  }

  const load = async () => {
    try {
      let foundAll = false
      let i = 1
      while (!foundAll){
        const response = await api.get<{items: any[]}>(`/country-profile?page=${i}&rpp=200`)
        i++

        if(response.data.items.length == 0){
          foundAll = true
        }

        response.data.items.forEach((item)=>{
          items[item.countryISO] = item.averageRiskLevel || undefined


        })

      }


    } catch (e){
      console.error(e)
    }

    console.log(items)

    setLoaded(true)

  }

  useEffect(() => {
    load()
  }, []);

  return <CountryRiskContext.Provider value={{items, getRisk, loaded}}>
    {children}
  </CountryRiskContext.Provider>

}