import React, { ReactNode, SetStateAction, createContext, useState } from "react";

type RiskModalContextType = {
  riskModal: boolean;
  setRiskModal: React.Dispatch<SetStateAction<boolean>>;
};

export const RiskModalContext = createContext<RiskModalContextType | null>(
  null
);

export default function RiskModalContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [riskModal, setRiskModal] = useState<boolean>(false);
  return (
    <RiskModalContext.Provider value={{ riskModal, setRiskModal }}>
      {children}
    </RiskModalContext.Provider>
  );
}
