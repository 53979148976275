import React, { ReactNode, SetStateAction, useEffect, useState } from "react";
import ReactDom from "react-dom";
type ToastPropsType = {
  children: ReactNode;
  open: boolean;
  className?: string;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  setSuccess?: React.Dispatch<
    SetStateAction<{ code: number; message: string } | null>
  >;
};

export default function Toast({
  children,
  open,
  className,
  setOpen,
  setSuccess,
}: ToastPropsType) {
  const [percentage, setPercentage] = useState<number>(0);
  if (!open) return null;

  setTimeout(() => {
    if (setSuccess) {
      setSuccess(null);
    }
    setOpen(false);
    // setInterval(() => {
    //   setPercentage((prev) => prev + 1);
    // }, 30);
  }, 3000);

  return ReactDom.createPortal(
    <div className={`absolute top-10 right-10 z-[100] ${className}`}>
      <div className="px-10 py-4 bg-white rounded-md border-blue border-2 relative">
        {/* <div style={{ width: 200, height: 200 }}>
          <CircularProgressbar value={percentage} />
        </div> */}
        {children}
      </div>
    </div>,
    document.getElementById("toast")!
  );
}
