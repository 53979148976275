import {useMemo} from "react";
import {useOperationalMapContext} from "../../providers/OperationalMapProvider";

export const useBoundsCheck = (lng: number, lat: number) => {
  const ctx = useOperationalMapContext()

  return useMemo(() => {
    if (!ctx.bounds) {
      return true;
    }

    const x = lng;
    const y = lat;

    const { west, east, north, south } = ctx.bounds;


    if (y > north || y < south) {
      return true;
    }


    if (west > east) {

      if (x < west && x > east) {
        return true;
      }
    } else {
      if (x < west || x > east) {
        return true;
      }
    }

    return false;
  }, [ctx.bounds, lat, lng]);

}

