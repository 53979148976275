import React, { FC, ReactComponentElement, useContext, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { ReportsContext } from "../../../ReportsDataProvider";

import DropDown from "../../../../../components/form/DropDown";
import InputText from "../../../../../components/form/InputText";
import Checkbox from "../../../../../components/form/Checkbox";

import DateIcon from "../../../../../assets/icons/date.svg";
import Suitcase from "../../../../../assets/icons/suitcase-grey.svg";
import IconSearch from "../../../../../assets/icons/icon-search.svg";
import SingleDateBox from "../../../../../components/form/DateTimeInput/SingleDateBox";
import { BlueButton } from "../../../../../components/ui/Buttons";

type SettingsSearch = {
  clients: "";
  users: "";
};

type Frequency = {
  single?: boolean;
  singleDates?: {
    startDate: Date;
    endDate: Date;
  };
  repeat?: {
    types: string[];
    startDate: Date;
  };
};

export default function SettingsBox() {
  const { clients, users } = useContext(ReportsContext);

  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [settingsSearch, setSettingsSearch] = useState<SettingsSearch>({
    clients: "",
    users: "",
  });
  const [frequency, setFrequency] = useState<Frequency>({});

  const handleSettingsSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSettingsSearch((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClientSelect = (id: string) => {
    if (selectedClients.includes(id)) {
      setSelectedClients(selectedClients.filter((client) => client !== id));
    } else {
      setSelectedClients([...selectedClients, id]);
    }
  };

  const handleUserSelect = (id: string) => {
    if (selectedUsers.includes(id)) {
      setSelectedUsers(selectedUsers.filter((user) => user !== id));
    } else {
      setSelectedUsers([...selectedUsers, id]);
    }
  };

  return (
    <div className="grid grid-cols-3 w-full gap-4">
      <div className="w-full">
        <DropDown title="Clients" dropDownIcon={Suitcase}>
          <div>
            <div className="mb-2">
              <InputText
                placeholder="Search"
                name="clients"
                value={settingsSearch.clients}
                onChange={handleSettingsSearchChange}
              />
              <button className="absolute top-0 right-3 translate-y-1/2">
                <img src={IconSearch} alt="" />
              </button>
            </div>
            <div className="overflow-hidden h-[185px]">
              <div className="h-full overflow-y-scroll scrollbar-hide px-2">
                {clients &&
                  clients.map((client) => {
                    if (
                      client.name
                        .toLowerCase()
                        .includes(settingsSearch.clients.toLowerCase())
                    ) {
                      return (
                        <div className="mt-2">
                          <Checkbox
                            key={client.id}
                            name="client"
                            checked={selectedClients.includes(
                              client.id ? client.id : ""
                            )}
                            value={client.id ? client.id : ""}
                            label={client.name}
                            onClick={() => {
                              handleClientSelect(client.id ? client.id : "");
                            }}
                          />
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        </DropDown>
      </div>
      <DropDown dropDownIcon={DateIcon} title="Frequency">
        <div className="mb-4">
          <Checkbox
            name="single"
            checked={frequency.single ? frequency.single : false}
            value="single"
            label="Single (one-off)"
            onClick={() => {}}
          />
        </div>
        <DropDown title="Dates" dropDownIcon={DateIcon} transparentBox={true}>
          <ReactDatePicker
            inline
            isClearable
            selected={new Date()}
            onChange={() => {}}
            startDate={new Date()}
            endDate={new Date()}
            selectsRange
          />
        </DropDown>
        <div className="mt-5 flex w-full items-center px-10">
          <div className="h-0.1 w-full bg-desaturated-grey" />
          <div className="px-10 text-center">OR</div>
          <div className="h-0.1 w-full bg-desaturated-grey" />
        </div>
        <div>
          <div className="mb-2">
            <Checkbox
              name="annually"
              checked={frequency.repeat?.types.includes("annually")!}
              value="annually"
              label="Annually"
              onClick={() => {}}
            />
          </div>
          <div className="mb-2">
            <Checkbox
              name="monthly"
              checked={frequency.repeat?.types.includes("monthly")!}
              value="monthly"
              label="Monthly"
              onClick={() => {}}
            />
          </div>
          <div className="mb-2">
            <Checkbox
              name="weekly"
              checked={frequency.repeat?.types.includes("weekly")!}
              value="weekly"
              label="Weekly"
              onClick={() => {}}
            />
          </div>
          <div>
            <Checkbox
              name="daily"
              checked={frequency.repeat?.types.includes("daily")!}
              value="daily"
              label="Daily"
              onClick={() => {}}
            />
          </div>
          <div className="pl-6 flex items-center">
            <span>Starting On</span>
            <div className="ml-5">
              <SingleDateBox
                startDate={
                  frequency.repeat?.startDate
                    ? frequency.repeat?.startDate
                    : new Date()
                }
                onStartDatePicked={() => {}}
              />
            </div>
          </div>
        </div>
        <div className="w-[170px] mt-5 m-auto">
          <BlueButton text="Save" onClick={() => {}} />
        </div>
      </DropDown>
      <div className="w-full">
        <DropDown title="Recipient" dropDownIcon={Suitcase}>
          <div>
            <div className="mb-2">
              <InputText
                placeholder="Search"
                name="users"
                value={settingsSearch.users}
                onChange={handleSettingsSearchChange}
              />
              <button className="absolute top-0 right-3 translate-y-1/2">
                <img src={IconSearch} alt="" />
              </button>
            </div>
            <div className="overflow-hidden h-[185px]">
              <div className="h-full overflow-y-scroll scrollbar-hide px-2">
                {users &&
                  users.map((user) => {
                    if (
                      user.Profile.firstName
                        .toLowerCase()
                        .includes(settingsSearch.users.toLowerCase()) ||
                      user.Profile.lastName
                        .toLowerCase()
                        .includes(settingsSearch.users.toLowerCase())
                    ) {
                      return (
                        <div className="mt-2">
                          <Checkbox
                            name="users"
                            checked={selectedUsers.includes(
                              user.id ? user.id : ""
                            )}
                            value={user.id ? user.id : ""}
                            label={
                              user.Profile.firstName +
                              " " +
                              user.Profile.lastName
                            }
                            onClick={() =>
                              handleUserSelect(user.id ? user.id : "")
                            }
                          />
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        </DropDown>
      </div>
    </div>
  );
}
