import React, { ReactNode, createContext } from "react";
import useGetAllCountryProfiles from "../../../../../../api/country-profiles/useGetAllCountryProfiles";
import { CountryProfileDataTypes } from "../../../../../../types/country-profile-data-types";

type CountryProfilesContext = {
  all_country_profiles_data: CountryProfileDataTypes[] | null;
  all_country_profiles_status: "idle" | "loading" | "success" | "error";
  all_country_profiles_error: unknown;
};
export const CountryProfilesContext =
  createContext<CountryProfilesContext | null>(null);

export default function CountryProfilesContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const countryProfiles = useGetAllCountryProfiles();

  return (
    <CountryProfilesContext.Provider value={countryProfiles}>
      {children}
    </CountryProfilesContext.Provider>
  );
}
