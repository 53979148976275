import React, { RefObject, useState } from "react";
import { Done } from "@mui/icons-material";
function CustomCheckbox({
  name,
  ref,
  value,
  checked,
  handleFakeCheckbox,
}: {
  name: string;
  ref?: RefObject<HTMLInputElement>;
  value: string;
  checked: boolean;
  handleFakeCheckbox: () => void;
}) {
  return (
    <div className="overflow-hidden">
      <input
        type="checkbox"
        name={name}
        className="absolute top-0 right-0 opacity-0 display-none"
        ref={ref}
        value={value}
        checked={checked}
        onChange={() => {}}
      />
      <button
        className="h-4 w-4 flex items-center justify-center border"
        type="button"
        onClick={handleFakeCheckbox}
      >
        {checked ? <Done style={{ width: "12px" }} /> : null}
      </button>
    </div>
  );
}

export default CustomCheckbox;
