import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavLinks } from "../../../types/navLinks";

type MainLinkTypes = {
  path: string;
  slug: string;
  title: string;
  innerLinks?: NavLinks[];
};

function MainLink({ path, slug, title, innerLinks }: MainLinkTypes) {
  // location and pathSlug will be used to add active state styles on nav link
  const location = useLocation();

  const pathSlug = location.pathname.split("/")[2];

  const [showSubMenu, setShowSubMenu] = useState<boolean>(false);

  const toggleSubmenu = () => {
    setShowSubMenu((prevState) => !prevState);
  };

  return (
    <>
      {innerLinks!.length === 0 && (
        <Link
          to={path}
          className={`w-full h-10 flex  justify-center items-center rounded-md relative ${
            pathSlug === slug
              ? "font-semibold shadow-md bg-blue text-white"
              : "bg-white shadow-md"
          }`}
        >
          {title}
        </Link>
      )}

      {innerLinks!.length > 0 && (
        <button
          onClick={toggleSubmenu}
          className={`w-full h-10 flex  justify-center items-center rounded-md relative ${
            innerLinks?.some((link) => link.path === location.pathname) ||
            showSubMenu
              ? "font-semibold shadow-md bg-blue text-white"
              : "bg-white shadow-md"
          }`}
        >
          {title}
          {showSubMenu && (
            <div className="absolute z-50 right-0 top-11 shadow-md rounded-md h-fit w-full p-4 flex justify-center items-center flex-col bg-light-light-grey">
              {innerLinks!.map((innerLink) => (
                <Link
                  key={innerLink.slug}
                  to={innerLink.path}
                  className={`w-[95%] h-10 flex mb-1 justify-center items-center rounded-md text-grey relative ${
                    location.pathname === innerLink.path
                      ? "font-semibold shadow-md bg-blue text-white"
                      : "bg-white"
                  }`}
                >
                  {innerLink.label}
                </Link>
              ))}
            </div>
          )}
        </button>
      )}
    </>
  );
}

export default MainLink;
