import React, { SetStateAction, useEffect, useRef, useState } from "react";
import Form from "./Form";
import useGetSiteBasedAlertsPerFacility from "../../../../api/site-based-alerts/useGetSiteBasedAlertsPerFacility";
import LoadingSpinner from "../../../../components/ui/LoadingSpinner";
import AddSiteBasedAlert from "./add-site-based-alerts";
function EditSiteBasedAlerts({
  facilitiyID,
  setEditSiteBasedAlertsModal,
}: {
  facilitiyID: string | null;
  setEditSiteBasedAlertsModal: React.Dispatch<SetStateAction<boolean>>;
}) {
  const [successfullyAddedSBA, setSuccessfullyAddedSBA] =
    useState<boolean>(false);
  const [itemCount, setItemCount] = useState<number>(0);
  const sba = useGetSiteBasedAlertsPerFacility(facilitiyID);
  const items = sba.data?.data.items;

  const render = () => {
    return items.length < 1 ? (
      <AddSiteBasedAlert
        setSiteBasedAlertModal={setEditSiteBasedAlertsModal}
        setSuccessfullyAddedSBA={setSuccessfullyAddedSBA}
        facilityID={facilitiyID!}
      />
    ) : (
      <Form
        setEditSiteBasedAlertsModal={setEditSiteBasedAlertsModal}
        items={items}
        facilitiyID={facilitiyID}
      />
    );
  };
  return sba.status !== "success" ? <LoadingSpinner /> : render();
}

export default EditSiteBasedAlerts;
