import React, { FormEvent, RefObject, useRef, useState } from "react";
import api from "../../../../../../api/axiosConfig";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

type NewClientDataType = {
  name: string;
  department: { name: string; phoneNumber?: string }[] | null;
  type: string;
};

function Main() {
  const location = useLocation();
  const locationPathNameArr = location.pathname.split("/");
  const [formStage, setFormStage] = useState<
    "client" | "departments" | "users"
  >("client");

  const [successReq, setSuccessReq] = useState<boolean>(false);
  const [failedReq, setFailedReq] = useState<boolean>(false);
  const nameRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const usersCSVInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const handleUploadUsersFile = () => {
    const file = usersCSVInputRef.current!.files![0];
    const formData = new FormData();

    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    api.post("/users-bulk-create", formData, config);
  };

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    const formData: NewClientDataType = {
      name: nameRef.current!.value,
      department: null,
      type: "direct",
    };
    handleUploadUsersFile();
    api
      .post("clients", formData)
      .then(() => {
        setSuccessReq(true);
        setTimeout(() => navigate(-1), 3000);
      })
      .catch(() => {
        setFailedReq(true);
        setInterval(() => setFailedReq(false), 3000);
      });
  };

  return (
    <div className=" bg-white shadow-md p-10 h-fit mb-10">
      <div className="flex py-4  text-md w-full pr-10 flex-col gap-8 pb-8">
        <div className="flex gap-12">
          <span
            className={`font-semibold text-lg ${
              !locationPathNameArr.includes("add-departments") &&
              !locationPathNameArr.includes("add-users")
                ? "opacity-100"
                : "opacity-50"
            }`}
          >
            Add Client
          </span>
          <NavigateNextIcon />
          <span
            className={`font-semibold text-lg ${
              locationPathNameArr.includes("add-departments")
                ? "opacity-100"
                : "opacity-50"
            }`}
          >
            Add Departments
          </span>
          <NavigateNextIcon />
          <span
            className={`font-semibold text-lg ${
              locationPathNameArr.includes("add-users")
                ? "opacity-100"
                : "opacity-50"
            }`}
          >
            Add Users
          </span>
        </div>
        <hr className="bg-light-light-grey h-[1px] w-full" />
      </div>

      <div className="">
        <Outlet />
      </div>
    </div>
  );
}

export default Main;
