import React, { useEffect, useState } from "react";
import UserStatusCard from "../../components/UserStatusCard";
import useUserActivity from "../../../../services/useUserActivity";
import { UserActivityDataTypes } from "../../../../types/user-activity-data-types";

function UserStatusCardContainer({
  sidebarType,
  filterValue,
}: {
  sidebarType: "non-emergency" | "emergency";
  filterValue: string;
}) {
  const { nonEmergencyUserActivity, emergencyUserActivity, resolved } =
    useUserActivity();

  const data: UserActivityDataTypes[] =
    sidebarType === "emergency"
      ? emergencyUserActivity
      : nonEmergencyUserActivity;
  const [activityData, setActivityData] = useState<UserActivityDataTypes[]>();
  useEffect(() => {
    setActivityData(data);
  }, [data]);

  useEffect(() => {
    if (filterValue === "") {
      setActivityData(data);
    } else {
      const newData = data.filter((user) =>
        user.metadata.userName.toLowerCase().includes(filterValue.toLowerCase())
      );
      setActivityData(newData);
    }
  }, [filterValue, data]);
  return (
    <div className="overflow-y-auto h-full w-fit px-10 pb-52 pt-10 flex flex-col gap-12 relative">
      {activityData ? (
        activityData
          .filter((a) => {
            if (!resolved) {
              return true;
            }

            return !resolved.find((r) => r.id == a.id);
          })
          .map((user, i) => (
            <UserStatusCard
              userData={user}
              key={user.id + i}
              sidebarType={sidebarType}
            />
          ))
      ) : (
        <p>No user activity found.</p>
      )}
    </div>
  );
}

export default UserStatusCardContainer;
