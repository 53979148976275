import Search from "./components/Search";
import React, { useState } from "react";
import CasesEntry from "./components/CasesEntry";
import Pagination from "../../../../components/ui/Content/Pagination";

export default function Cases() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(12);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const numberOfItems = 30;

  const entryItems: any = [];

  [...Array(numberOfItems)].forEach((_, i) => {
    entryItems.push(<CasesEntry key={i} />);
  });

  return (
    <div className="py-10 relative h-full">
      <Search />
      <div className="mt-10">
        <div className="bg-desaturated-grey rounded-2xl grid grid-cols-[0.3fr_0.4fr_1fr_0.4fr_0.3fr_0.5fr] w-full py-1 justify-items-center items-center text-center">
          <div>Case</div>
          <div>Contact Name</div>
          <div>Subject</div>
          <div>Date Opened</div>
          <div>Status</div>
          <div>Type</div>
        </div>
        <div className="overflow-y-scroll h-full scrollbar-hide">
          {entryItems.slice(indexOfFirstItem, indexOfLastItem)}
        </div>
      </div>
      <div className="absolute bottom-4 right-10">
        <div className="flex text-light-grey">
          {entryItems!.length > 0 && (
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              dataLength={entryItems ? entryItems.length : 0}
              togglePageSelect={(page) => setCurrentPage(page)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
