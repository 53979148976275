import api from "../../api/axiosConfig";
import { useQuery } from "react-query";
import { IncidentDataTypes } from "../../types/incident-data-types";
import moment from "moment";
import convertDateToUTC from "../../util/convertDateToUTC";
import { useCallback } from "react";
export default function useGetIncidentsPerCountry(countryISO: string) {
  const sevenDaysAgoDateFromToday = moment().subtract(7, "days").format();
  const sevenDaysAgoDateFromTodayToUTC = convertDateToUTC(
    sevenDaysAgoDateFromToday
  );
  const today = moment().date();
  const fetchCountryIncidents = useCallback(
    async (page: number) => {
      return await api
        .get(`incidents?page=${page}`, {
          params: {
            countryISO: countryISO,
            rpp: 200,
            startTime: sevenDaysAgoDateFromTodayToUTC,
          },
        })
        .then((res) => res)
        .catch((err) => console.error(err));
    },
    [today, countryISO]
  );

  const countryIncidentsQuery = useQuery("country-incidents", () =>
    fetchCountryIncidents(1)
  );
  const countryIncidentsQueryData = countryIncidentsQuery.data?.data.items;

  const fetchAllIncidents = async () => {
    const totalIncidents = countryIncidentsQuery.data?.data.total;
    const incidentsPerPage = countryIncidentsQuery.data?.data.items.length;
    const totalPagesCalc = Math.ceil(totalIncidents / incidentsPerPage);

    const pageFetches = Array.from({ length: totalPagesCalc }, (v, i) =>
      fetchCountryIncidents(i)
    );
    return await Promise.all(pageFetches);
  };

  const { data, status, error } = useQuery({
    queryKey: "all-country-incidents",
    queryFn: () => fetchAllIncidents(),
    enabled: !!countryIncidentsQueryData,
  });

  const extractIncidentsFromEachPage = () => {
    if (status !== "success") return null;
    const arr: IncidentDataTypes[] = [];
    data.map((page) => {
      const items = page!.data.items;

      items?.forEach((item: IncidentDataTypes) => {
        arr.push(item);
      });
    });

    return arr;
  };
  const extractedData = extractIncidentsFromEachPage();

  return {
    all_country_incidents_data: extractedData,
    all_country_incidents_status: status,
    all_country_incidents_error: error,
  };
}
