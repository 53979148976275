import React from "react";
import { useParams } from "react-router-dom";
import api from "../axiosConfig";
import { useQuery } from "react-query";

export default function useGetCountryProfile() {
  const { country_iso } = useParams();
  const fetchCountryProfile = async () => {
    return await api
      .get(`country-profile/${country_iso}`)
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  const { data, status, error } = useQuery(
    "country-profile",
    fetchCountryProfile
  );

  return {
    data: data ? data : null,
    status: status,
    error: error,
  };
}
