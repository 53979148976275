import React, {
  FormEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import CloseIcon from "@mui/icons-material/Close";
import CheckboxWrapper from "./checkbox-wrapper";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import useGetIncidentTypes from "../../../../../api/incident-types/useGetIncidentTypes";
import useGetFacility from "../../../../../api/facilities/useGetFacility";
import api from "../../../../../api/axiosConfig";
import IncidentTypesDataTypes from "../../../../../types/incident-types-data-types";
import { BlueButton } from "../../../../../components/ui/Buttons";
import SearchableSelect, { SearchableSelectOption } from "../../../../../components/form-v2/searchable-select";

function AddSiteBasedAlert({
  setSiteBasedAlertModal,
  setSuccessfullyAddedSBA,
  facilityID,
}: {
  setSiteBasedAlertModal: React.Dispatch<SetStateAction<boolean>>;
  setSuccessfullyAddedSBA: React.Dispatch<SetStateAction<boolean>>;
  facilityID: string;
}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const incidentTypes = useGetIncidentTypes();
  const formRef = useRef<HTMLFormElement>(null);
  const alertRadiusRef = useRef<HTMLInputElement>(null);
  const facility = useGetFacility(facilityID!);
  const facilityData = facility.data?.data;
  console.log(facilityData);
  const nameRef = useRef<HTMLInputElement>(null);
  const handleSave = (e: FormEvent) => {
    e.preventDefault();
    const formData = new FormData(formRef.current!);
    const mainSubTypesID = formData.getAll("sub-type");
    const severity = formData.getAll("severity");
    const types = mainSubTypesID;
    const data: {
      name: string;
      facilityID: string;
      clientID: string;
      radiusM: number;
      incidentType: FormDataEntryValue[];
      position: {
        type: string;
        coordinates: [number, number];
      };
      severity: {
        unknown: boolean;
        insignificant: boolean;
        limited: boolean;
        moderate: boolean;
        major: boolean;
        extreme: boolean;
      };
    } = {
      name: nameRef.current!.value,
      facilityID: facilityID!,
      clientID: facilityData.clientID,
      radiusM: parseFloat(alertRadiusRef.current!.value),
      incidentType: types,
      position: {
        type: "Point",
        coordinates: [
          facility.data!.data.position.coordinates[0],
          facility.data!.data.position.coordinates[1],
        ],
      },
      severity: {
        unknown: severities.some(item => item.value === "unknown"),
        insignificant: severities.some(item => item.value === "insignificant"),
        limited: severities.some(item => item.value === "limited"),
        moderate: severities.some(item => item.value === "moderate"),
        major: severities.some(item => item.value === "major"),
        extreme: severities.some(item => item.value === "extreme"),
      },
    };

    api
      .post(`sba/${facilityID}`, data)
      .then((res) => {
        queryClient.invalidateQueries(["facility-sba", facilityID]);
        setSiteBasedAlertModal(false);
        setSuccessfullyAddedSBA(true);
        setTimeout(() => {
          setSuccessfullyAddedSBA(false);
          navigate("/sites/facilities");
        }, 2500);
      })
      .catch((err) => console.error(err));
  };

  const handleAddMoreSiteBasedAlerts = () => {
    const formData = new FormData(formRef.current!);
    const mainSubTypesID = formData.getAll("sub-type");
    const types = mainSubTypesID;
    const data: {
      name: string;
      facilityID: string;
      clientID: string;
      radiusM: number;
      incidentType: FormDataEntryValue[];
      position: {
        type: string;
        coordinates: [number, number];
      };
    } = {
      name: nameRef.current!.value,
      facilityID: facilityID!,
      clientID: facilityData.clientID,
      radiusM: parseFloat(alertRadiusRef.current!.value),
      incidentType: types,
      position: {
        type: "Point",
        coordinates: [
          facility.data!.data.position.coordinates[0],
          facility.data!.data.position.coordinates[1],
        ],
      },
    };

    api
      .post(`sba/${facilityID}`, data)
      .then((res) => {
        queryClient.invalidateQueries(["facility-sba", facilityID]);
        setSuccessfullyAddedSBA(true);
        setSiteBasedAlertModal(false);
        formRef.current!.reset();
        setTimeout(() => {
          setSuccessfullyAddedSBA(false);
          setSiteBasedAlertModal(true);
        }, 2500);
      })
      .catch((err) => console.error(err));
  };

  const [severities, setSeverities] = useState<SearchableSelectOption<string>[]>([])

  return incidentTypes.status !== "success" ? null : (
    <form
      className="p-8 bg-white rounded-md shadow-md flex gap-8 flex-col h-[60vh] overflow-y-auto"
      action={"sites"}
      ref={formRef}
    >
      <header className="h-10 w-full border-b flex items-center justify-center">
        <h4 className="font-semibold">Add Site-based Alert</h4>
        <button
          className="absolute top-5 right-5"
          type="button"
          onClick={() => setSiteBasedAlertModal(false)}
        >
          <CloseIcon />
        </button>
      </header>

      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <span className="text-red font-semibold">*</span>
          <input
            name={"name"}
            type="text"
            placeholder="Site Name"
            className="h-10 w-full rounded-md shadow-md px-4"
            required
            ref={nameRef}
          />
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <span className="text-red font-semibold">*</span>
          <input
            name={"alertRadius"}
            type="text"
            placeholder="Alert Radius (m)"
            className="h-10 w-full rounded-md shadow-md px-4"
            required
            ref={alertRadiusRef}
          />
        </div>
      </div>

      <div className="flex flex-col gap-8">
        <div className="flex items-center gap-2">
          <span className="text-red font-semibold">*</span>
          <div className="h-10 w-full px-4 flex items-center opacity-50">
            <p>Incident Types:</p>
          </div>
        </div>
        {incidentTypes.data?.data.map((type: IncidentTypesDataTypes) => (
          <CheckboxWrapper incidentType={type} key={type.id} />
        ))}
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <span className="text-red font-semibold">*</span>
          <div className="h-10 w-full px-4 flex items-center opacity-50">
            <p>Incident Severities:</p>
          </div>
        </div>
        <SearchableSelect
          multiple={true}
          label="Select Severity"
          options={[
            {value: "unknown", label:"Unknown", key:"unknown"},
            {value: "insignificant", label:"Insignificant", key:"insignificant"},
            {value: "limited", label:"Limited", key:"limited"},
            {value: "moderate", label:"Moderate", key:"moderate"},
            {value: "major", label:"Major", key:"major"},
            {value: "extreme", label:"Extreme", key:"extreme"},

          ]}
          search={false}
          localSearch={false}
          selected={severities}
          onChange={(s) => {setSeverities(s)}}
        
        />
      </div>

      <div className="flex mx-auto flex-col gap-4 my-6 w-1/3">
        <BlueButton
          text="No site-based alert"
          onClick={() => setSiteBasedAlertModal(false)}
        />
        <BlueButton text="Save" onClick={handleSave} type="submit" />
        {/* <BlueButton
          text="Add more site-based alerts"
          onClick={handleAddMoreSiteBasedAlerts}
        /> */}
      </div>
    </form>
  );
}

export default AddSiteBasedAlert;
