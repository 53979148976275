import { Status, Wrapper } from "@googlemaps/react-wrapper";
import React, { ReactElement, useContext } from "react";
import Map from "./Map";
import IncidentDetailsModalProvider from "../../../../../../context/incident-details-modal";
import { CountryProfileContext } from "../../context/country-profile";
import useGetIncidentsPerCountry from "../../../../../../api/incidents/useGetIncidentsPerCountry";
import { CountryProfileDataTypes } from "../../../../../../types/country-profile-data-types";
function CountryProfileMapSection() {
  const googleMapsApiKey: string = process.env.REACT_APP_GOOGLE_MAP_API_KEY!;
  const Spinner = () => <h1>Insert spinning wheel for loading</h1>;
  const Error = () => <h1>Insert error</h1>;
  const render = (status: Status): ReactElement => {
    if (status === Status.FAILURE) return <Error />;
    return <Spinner />;
  };
  const countryProfileContext = useContext(CountryProfileContext);
  const countryData: CountryProfileDataTypes =
    countryProfileContext?.data?.data;
  const incidents = useGetIncidentsPerCountry(countryData.countryISO);

  return incidents.all_country_incidents_status !== "success" ||
    countryProfileContext?.status !== "success" ? null : (
    <div className="h-full w-full">
      <Wrapper
        apiKey={googleMapsApiKey}
        render={render}
        libraries={["drawing", "places"]}
      >
        <IncidentDetailsModalProvider>
          <Map
            zoom={6}
            incidents={incidents.all_country_incidents_data!}
            countryProfileData={countryProfileContext?.data?.data}
          />
        </IncidentDetailsModalProvider>
      </Wrapper>
    </div>
  );
}

export default CountryProfileMapSection;
