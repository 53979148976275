import { Coordinates, FeatureType, OverlaysDataTypes } from "../../sites/types";
import { useContext, useEffect, useRef, useState } from "react";
import { FilterMapContext } from "../../operational-map/context/filter-map";
const useCustomOverlayMarker = (
  map: google.maps.Map | undefined,
  overlays: OverlaysDataTypes[],
  siteTypes: string[],
  clientID?: string
) => {
  const { filters } = useContext(FilterMapContext);
  const { searchOverlayID } = filters;
  const [isOverlays, setIsOverlays] = useState<boolean>(
    siteTypes.includes("custom-overlay")
  );

  useEffect(() => {
    setIsOverlays(siteTypes.includes("custom-overlay"));
  }, [siteTypes, searchOverlayID]);

  const markersRef = useRef<google.maps.Marker[]>([]);
  const polylineRef = useRef<google.maps.Polyline[]>([]);
  const polygonRef = useRef<google.maps.Polygon[]>([]);
  const circlesRef = useRef<google.maps.Circle[]>([]);
  const filteredOverlays = overlays?.filter((overlay) =>
    !clientID || clientID === "all" ? overlay : overlay?.clientID === clientID
  );
  /**
   * Render marker, polyline and polygons
   */

  useEffect(() => {
    if (!map) return;
    if (!filteredOverlays || !isOverlays) return;
    const infowindow = new google.maps.InfoWindow();
    const markers: google.maps.Marker[] = [];
    const polylines: google.maps.Polyline[] = [];
    const polygons: google.maps.Polygon[] = [];
    const circles: google.maps.Circle[] = [];
    filteredOverlays
      .filter((overlay) =>
        searchOverlayID ? overlay.id === searchOverlayID : overlay
      )
      .forEach((overlay) => {
        const features = overlay.data.features;
        features?.forEach((feature: FeatureType) => {
          const type: "Point" | "LineString" | "Polygon" =
            feature.geometry.type;
          const featureStringDiv = `<div style="font-size: 14px;  padding: 16px">
            <div>
              <p style=""><span style="font-weight: 500;">${overlay.name}: </span>${feature.properties.name}</p>
            </div>
          </div>`;
          if (
            type === "Point" &&
            !feature.properties.radius &&
            !feature.properties.label
          ) {
            const lng = feature.geometry.coordinates[0] as number;
            const lat = feature.geometry.coordinates[1] as number;
            const position = { lng, lat };
            const marker = new google.maps.Marker({
              position,
              clickable: true,
              label: {
                text: feature.properties.icon || "\ue55e",
                fontFamily: "Material Icons",
                color: "#ffffff",
                fontSize: "18px",
              },
            });
            marker.addListener("click", () => {
              infowindow.setContent(featureStringDiv);
              infowindow.setPosition(position);
              infowindow.open(map);
            });
            return markers.push(marker);
          } else if (
            type === "Point" &&
            feature.properties.radius &&
            !feature.properties.label
          ) {
            const lng = feature.geometry.coordinates[0] as number;
            const lat = feature.geometry.coordinates[1] as number;
            const position = { lng, lat };
            const circle = new google.maps.Circle({
              map,
              center: position,
              radius: feature.properties.radius,
              strokeColor: feature.properties.style.lineColor,
              strokeWeight: feature.properties.style.lineWidth,
              fillColor: feature.properties.style.fillColor,
            });
            circle.addListener("click", (e: google.maps.MapMouseEvent) => {
              infowindow.setContent(featureStringDiv);
              infowindow.setPosition({
                lat: e.latLng!.lat(),
                lng: e.latLng!.lng(),
              });
              infowindow.open(map);
            });
            return circles.push(circle);
          } else if (type === "Point" && feature.properties.label) {
            const lng = feature.geometry.coordinates[0] as number;
            const lat = feature.geometry.coordinates[1] as number;
            const position = { lng, lat };
            const marker = new google.maps.Marker({
              position,
              clickable: true,
              icon: {
                url: "",
                size: new google.maps.Size(1, 1),
              },
              label: {
                text: feature.properties.label,
                color: feature.properties.style.fontColor,
                fontSize: `${feature.properties.style.fontSize?.toString()}px`,
              },
              zIndex: 9999,
            });
            marker.addListener("click", () => {
              infowindow.setContent(featureStringDiv);
              infowindow.setPosition(position);
              infowindow.open(map);
            });
            return markers.push(marker);
          } else if (type === "LineString") {
            const coordinates = feature.geometry.coordinates as Coordinates[];
            const path = coordinates.map((coords) => ({
              lng: coords[0],
              lat: coords[1],
            }));
            const polyline = new google.maps.Polyline({
              path,
              strokeColor: feature.properties.style.lineColor,
              strokeWeight: feature.properties.style.lineWidth,
            });
            polyline.addListener("click", (e: google.maps.PolyMouseEvent) => {
              infowindow.setContent(featureStringDiv);
              infowindow.setPosition({
                lat: e.latLng!.lat(),
                lng: e.latLng!.lng(),
              });
              infowindow.open(map);
            });
            return polylines.push(polyline);
          } else {
            const coordinates = feature.geometry.coordinates as Coordinates[];
            const path = coordinates.map((coords) => ({
              lng: coords[0],
              lat: coords[1],
            }));
            const polygon = new google.maps.Polygon({
              paths: path,
              fillOpacity: 0.35,
              strokeColor: feature.properties.style.lineColor,
              strokeWeight: feature.properties.style.lineWidth || 1,
              fillColor: feature.properties.style.fillColor,
            });

            polygon.addListener("click", (e: google.maps.MapMouseEvent) => {
              infowindow.setContent(featureStringDiv);
              infowindow.setPosition({
                lat: e.latLng!.lat(),
                lng: e.latLng!.lng(),
              });
              infowindow.open(map);
            });
            return polygons.push(polygon);
          }
        });
      });
    // when client id is changed, remove all the existing markers and then render new ones
    markersRef.current.forEach((m) => m.setMap(null));
    polylineRef.current.forEach((m) => m.setMap(null));
    polygonRef.current.forEach((m) => m.setMap(null));
    circlesRef.current.forEach((c) => c.setMap(null));
    markersRef.current = markers;
    polylineRef.current = polylines;
    polygonRef.current = polygons;
    circlesRef.current = circles;
    markersRef.current.forEach((m) => m.setMap(map));
    polylineRef.current.forEach((p) => p.setMap(map));
    polygonRef.current.forEach((p) => p.setMap(map));
    circlesRef.current.forEach((c) => c.setMap(map));
  }, [filteredOverlays, map, isOverlays, clientID, searchOverlayID]);

  useEffect(() => {
    if (!map) return;
    if (isOverlays) {
      markersRef.current.forEach((feature) => {
        feature.setMap(map);
      });
      polylineRef.current.forEach((feature) => {
        feature.setMap(map);
      });
      polygonRef.current.forEach((feature) => {
        feature.setMap(map);
      });
      circlesRef.current.forEach((feature) => {
        feature.setMap(map);
      });
    } else {
      markersRef.current.forEach((feature) => {
        feature.setMap(null);
      });
      polylineRef.current.forEach((feature) => {
        feature.setMap(null);
      });
      polygonRef.current.forEach((feature) => {
        feature.setMap(null);
      });
      circlesRef.current.forEach((feature) => {
        feature.setMap(null);
      });
    }
  }, [isOverlays, map, filters]);
};

export default useCustomOverlayMarker;
