import React from "react";
import { MainEditCustomOverlay } from "../../../features/sites/pages/edit-custom-overlays";

function EditCustomOverlay() {
  return (
    <div className="w-full h-full">
      <MainEditCustomOverlay />
    </div>
  );
}

export default EditCustomOverlay;
