import React, {useContext, useEffect, useRef, useState} from "react";
import {BlueButton, RedButton} from "../../../../../components/ui/Buttons";
import {useOnClickOutside} from "usehooks-ts";
import BasicSelect from "../../../../../components/form-v2/basic-select";
import BasicText, {BasicNumber} from "../../../../../components/form-v2/basic-text";
import {getTimezonesForCountry} from "countries-and-timezones";
import {v4} from "uuid";
import {apiGET, apiPOST} from "../../../../../apiv2/request";
import {Trip} from "../../../../../pages/TripsV2/types";
import LoadingSpinner from "../../../../../components/ui/LoadingSpinner";
import moment from "moment";
import {UserContext} from "../../../../../context/user";
import {useQueryClient} from "react-query";
import {getTimezonesForCountryWithOverride} from "../../../../../util/tz";

const typeOptions = [
  {value: "single", label: "Single"},
  {value: "multiple", label: "Multiple"},
  {value: "interval", label: "Interval"},
]

type CheckInFormProps = {
  setShowModal: (show: boolean) => void,
  tripID: string | undefined
}

type CheckInItem = {
  key: string,
  date: string | undefined,
  time: string | undefined,
  location: string | undefined,
}

type IntervalSettings = {
  startDate: string | undefined,
  startTime: string | undefined,
  endDate: string | undefined,
  endTime: string | undefined,
  location: string | undefined,
  intervalMinutes: number | undefined
}

type CheckInLocation = {
  "countryISO": string,
  "countryName": string,
  "timezone": string,
  "label": string,
  "value": string,
}

// type PostRequest = {
//   items: {
//     time: string,
//     date: string,
//     countryISO: string,
//     timezone: string,
//   }[],
//   gracePeriod: number,
//   force: boolean,
// }

export const CheckInForm: React.FC<CheckInFormProps> = (props) => {

  const [submitting, setSubmitting] = useState(false);
  const [typeSelected, setTypeSelected] = useState<string | undefined>(typeOptions[0].value);
  const [checkInItems, setCheckInItems] = useState<CheckInItem[]>([
    {key: v4()} as CheckInItem
  ])
  const [loadingTrip, setLoadingTrip] = useState(true)
  const [errors, setErrors] = useState<string[]>([])
  const [intervalSettings, setIntervalSettings] = useState({} as IntervalSettings)
  const [locations, setLocations] = useState<CheckInLocation[]>([])
  const [loadingError, setLoadingError] = useState<boolean>(false)
  const [creationError, setCreationError] = useState<string | undefined>()
  const [force, setForce] = useState(false)
  const {role} = useContext(UserContext)!;
  const [gracePeriod, setGracePeriod] = useState(60)
  const queryClient = useQueryClient();


  const onCreate = async () => {
    setSubmitting(true)
    try {
      const url = `${process.env.REACT_APP_API_V2_URL}/trips/${props.tripID}/scheduled-check-ins?multiple`

      const items = typeSelected == "single" ? [checkInItems[0]] : checkInItems

      const req = {
        items: items.map(i => {
          return {
            time: i.time,
            date: i.date,
            countryISO: i.location?.split(":")[0],
            timezone: i.location?.split(":")[1],
          }
        }),
        force,
        gracePeriod
      }


      const result = await apiPOST(url, req)

      props.setShowModal(false);
      queryClient.invalidateQueries("trips-check-ins");

    } catch (e) {
      const err = e as { error: string }
      setCreationError(err.error)

    }
    setSubmitting(false)
  }

  const onChange = (index: number, key: keyof CheckInItem, value: string | undefined) => {
    if (key == "key") {
      return
    }
    setErrors([])
    const newItems = [...checkInItems];
    newItems[index][key] = value
    setCheckInItems(newItems)

  }


  const validateIntervalSettings = (): string[] => {
    const errors: string[] = [];

    // Check each property and add an error if it's undefined or empty
    if (intervalSettings.startDate === undefined || intervalSettings.startDate.trim() === "") {
      errors.push("Start Date is required");
    }
    if (intervalSettings.startTime === undefined || intervalSettings.startTime.trim() === "") {
      errors.push("Start Time is required");
    }
    if (intervalSettings.endDate === undefined || intervalSettings.endDate.trim() === "") {
      errors.push("End Date is required");
    }
    if (intervalSettings.endTime === undefined || intervalSettings.endTime.trim() === "") {
      errors.push("End Time is required");
    }
    if (intervalSettings.location === undefined || intervalSettings.location.trim() === "") {
      errors.push("Location is required");
    }

    if (intervalSettings.intervalMinutes === undefined) {
      errors.push("Interval is required");
    }

    const startDateTime = new Date(`${intervalSettings.startDate}T${intervalSettings.startTime}`);
    const endDateTime = new Date(`${intervalSettings.endDate}T${intervalSettings.endTime}`);

    if (endDateTime < startDateTime) {
      errors.push("End Date/Time must be later than Start Date/time");
    }

    return errors;
  };

  const generateInterval = () => {
    setErrors([])

    const newErrors = validateIntervalSettings()

    if (newErrors.length != 0) {
      setErrors(newErrors)
      return
    }


    const startDateTime = moment(`${intervalSettings.startDate}T${intervalSettings.startTime}`).set({seconds: 0});
    const endDateTime = moment(`${intervalSettings.endDate}T${intervalSettings.endTime}`).set({seconds: 0});

    const current = startDateTime.clone()

    const items: CheckInItem[] = []

    while (current.isSameOrBefore(endDateTime)) {
      const newItem: CheckInItem = {
        key: v4(),
        time: current.format("HH:mm"),
        date: current.format("YYYY-MM-DD"),
        location: intervalSettings.location,
      }

      items.push(newItem)
      current.add(intervalSettings.intervalMinutes, "minutes")
    }

    setCheckInItems(items)


    setTypeSelected("multiple")
  }


  const buttonText = typeSelected == "interval" ? "Generate" : "Create"
  const onButtonPress = () => {
    typeSelected == "interval" ? generateInterval() : onCreate()
  }

  const onIntervalSettingsChange = <K extends keyof IntervalSettings>(key: K, value: IntervalSettings[K]) => {
    const newSettings = {...intervalSettings}
    newSettings[key] = value
    setIntervalSettings(newSettings)
  }

  const addCheckInItem = () => {
    const newItems = [...checkInItems, {key: v4()} as CheckInItem]
    setCheckInItems(newItems)
  }

  const onRemove = (index: number) => {
    if (index == 0) {
      return
    }
    console.log(checkInItems)
    const newItems = [...checkInItems]
    newItems.splice(index, 1)
    setCheckInItems(newItems)
  }

  const loadTrip = async () => {
    try {
      const url = `${process.env.REACT_APP_API_V2_URL}/trips/${props.tripID}`
      const trip = await apiGET<Trip>(url, {}, {})
      const results: Map<string, CheckInLocation> = new Map

      trip.itinerary.forEach((item) => {
        item.locations.forEach((loc) => {
          const key = `${loc.countryISO}:${loc.timezone}`
          results.set(key, {
            countryISO: loc.countryISO,
            timezone: loc.timezone,
            countryName: loc.countryName,
            label: `${loc.countryName} - ${loc.timezone}`,
            value: key,
          })
        })
      })

      const resultsArr = Array.from(results.values()).sort((a, b) => a.label.localeCompare(b.label));
      setLocations(resultsArr)
      console.log(resultsArr)
    } catch (e) {
      setLoadingError(true)
    }
    setLoadingTrip(false)
  }

  useEffect(() => {
    loadTrip()
  }, []);

  const Content = () => {
    if (loadingError) {
      return (
          <p className={"text-sm text-center text-dark-red"}>Error loading trip data</p>
      )
    }

    if (loadingTrip) {
      return (
          <div className={"flex flex-row justify-center"}>
            <LoadingSpinner width={64} height={64}/>
          </div>

      )
    }

    return <>
      <div className={"flex gap-4"}>
        <div className={"w-64"}>
          <BasicSelect label={"Type"} options={typeOptions} value={typeSelected} onChange={setTypeSelected}/>
        </div>

        <div className={"w-64"}>
          <BasicNumber type={"number"} value={gracePeriod} min={15} max={240} label={"Grace Period (Minutes)"}
                       onChange={setGracePeriod}/>
        </div>

      </div>
      {
          typeSelected == "single" &&
          <SingleForm
              index={0}
              date={checkInItems[0].date}
              time={checkInItems[0].time}
              location={checkInItems[0].location}
              locations={locations}
              onChange={onChange}
              onRemove={() => {
              }}

          />
      }

      {
          typeSelected == "multiple" && <>
            {checkInItems.map((_, i) => (
                <SingleForm
                    index={i}
                    key={checkInItems[i].key}
                    date={checkInItems[i].date}
                    time={checkInItems[i].time}
                    location={checkInItems[i].location}
                    locations={locations}
                    onChange={onChange}
                    onRemove={onRemove}

                />
            ))}
              <div className={"flex mt-4 justify-end"}>
                  <div className={"w-64"}>
                      <BlueButton text={"Add"} onClick={addCheckInItem}/>
                  </div>
              </div>
          </>

      }

      {
          typeSelected == "interval" &&
          <IntervalForm onChange={onIntervalSettingsChange} settings={intervalSettings} locations={locations}/>
      }

      {creationError && role.auroraAccessLevel == "all" && <div className={"mt-4"}>
          <p className={"text-sm text-center text-dark-red mb-2"}>{creationError}</p>
          <label className={"block text-center"}>
              <span className={"mr-2"}>Force creation</span>
              <input type={"checkbox"} checked={force} onChange={(e) => setForce(e.target.checked)}/>
          </label>
      </div>}

      {errors.length != 0 && <div className={"mt-4"}>
        {errors.map((error, index) => (
            <p className={"text-sm text-center text-dark-red"} key={error}>{error}</p>
        ))}
      </div>}
    </>
  }

  return (
      <Modal
          title={"Create Scheduled Check Ins"}
          setShowModal={props.setShowModal}
          buttonText={buttonText}
          onButtonPress={onButtonPress}
          submitting={submitting}
      >
        {Content()}


      </Modal>
  );
}

type SingleFormProps = {
  index: number,
  date: string | undefined,
  time: string | undefined,
  location: string | undefined,
  locations: CheckInLocation[]
  onChange: (index: number, key: keyof CheckInItem, value: string | undefined) => void
  onRemove: (index: number) => void
}

const getTimezoneOptions = (countryISO: string) => {
  return getTimezonesForCountryWithOverride(countryISO)?.map((tz) => ({
    label: tz.name,
    value: tz.name
  })) || []
}


const SingleForm: React.FC<SingleFormProps> = (props) => {

  const getOnChange = (key: keyof CheckInItem) => {
    return (value: string | undefined) => props.onChange(props.index, key, value)
  }

  const onRemove = () => {
    props.onRemove(props.index)
  }

  if (!props.location) {
    props.onChange(props.index, "location", props.locations[0].value);
  }

  return (<>

    <div className={"flex gap-4 mt-4"}>
      <div className={"w-64 flex-1"}>
        <BasicText value={props.date || ""} type={"date"} label={"Date"} onChange={getOnChange("date")}/>
      </div>
      <div className={"w-64 flex-1"}>
        <BasicText value={props.time || ""} type={"time"} label={"Time"} onChange={getOnChange("time")}/>
      </div>

      <div className={"w-64 flex-1"}>
        <BasicSelect value={props.location} label={"Location"} options={props.locations}
                     onChange={getOnChange("location")}/>
      </div>


      <div className={"w-64 flex-1"}>
        {props.index != 0 && <>
            <label className={"block mb-1"}>&nbsp;</label>
            <RedButton text={"Remove"} onClick={onRemove}/>
        </>}
      </div>

    </div>
  </>)
}

type IntervalFormProps = {
  settings: IntervalSettings
  onChange: (key: keyof IntervalSettings, value: string | undefined | number) => void
  locations: CheckInLocation[]
}

const IntervalForm: React.FC<IntervalFormProps> = (props) => {

  const getOnChange = (key: keyof IntervalSettings) => {
    return (value: string | undefined | number) => props.onChange(key, value)
  }

  const defaultInterval = 60 * 24

  if (!props.settings.location) {
    props.onChange("location", props.locations[0].value);
  }

  if (!props.settings.intervalMinutes) {
    props.onChange("intervalMinutes", defaultInterval);
  }

  return (<>

    <div className={"flex gap-4 mt-4"}>
      <div className={"w-64 flex-1"}>
        <BasicText value={props.settings.startDate || ""} type={"date"} label={"Start Date"}
                   onChange={getOnChange("startDate")}/>
      </div>
      <div className={"w-64 flex-1"}>
        <BasicText value={props.settings.startTime || ""} type={"time"} label={"Start Time"}
                   onChange={getOnChange("startTime")}/>
      </div>

    </div>
    <div className={"flex gap-4 mt-4"}>
      <div className={"w-64 flex-1"}>
        <BasicText value={props.settings.endDate || ""} type={"date"} label={"End Date"}
                   onChange={getOnChange("endDate")}/>
      </div>
      <div className={"w-64 flex-1"}>
        <BasicText value={props.settings.endTime || ""} type={"time"} label={"End Time"}
                   onChange={getOnChange("endTime")}/>
      </div>
    </div>

    <div className={"flex gap-4 mt-4"}>
      <div className={"w-64 flex-1"}>
        <BasicSelect value={props.settings.location} label={"Location"} options={props.locations}
                     onChange={getOnChange("location")}/>
      </div>

    </div>

    <div className={"flex gap-4 mt-4"}>
      <div className={"w-64 flex-1"}>
        <BasicNumber value={props.settings.intervalMinutes || defaultInterval} type={"number"}
                     label={"Interval (Minutes)"}
                     onChange={getOnChange("intervalMinutes")} min={15} max={1440}/>
      </div>
    </div>
  </>)
}


type ModalProps = {
  title: string;
  setShowModal: (show: boolean) => void;
  buttonText: string;
  onButtonPress: () => void;
  submitting: boolean;
}

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = (props) => {


  const modalRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(modalRef, () => {
    props.setShowModal(false)
  })


  return <>
    <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[120] outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6 mx-auto">
        <div
            ref={modalRef}
            style={{maxHeight: "80vh"}}
            className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none overflow-y-scroll">
          <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 className="text-lg font-semibold">
              {props.title}
            </h3>

          </div>
          <div className="relative p-6 flex-auto">
            {props.children}
          </div>
          <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
            <BlueButton text={props.buttonText} onClick={props.onButtonPress} loading={props.submitting}/>
          </div>
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>


}