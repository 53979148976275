import React, { useRef } from "react";
import DoneIcon from "@mui/icons-material/Done";
type InputRadioPropType = {
  label: string;
  value: string;
  name: string;
  onSelect: (value: string) => void;
  selectedZoneType: string;
  disabled?: boolean;
};

function InputRadio({
  label,
  name,
  value,
  onSelect,
  selectedZoneType,
  disabled,
}: InputRadioPropType) {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className={`flex items-center gap-4 text-sm ${
        disabled ? "opacity-50" : ""
      }`}
    >
      <div className="relative w-4 h-4 flex items-center justify-center">
        <input
          type={"radio"}
          id={value}
          name={name}
          value={value}
          disabled={disabled}
          className="h-full w-full z-10 relative opacity-0 cursor-pointer"
          ref={inputRef}
          onChange={() => onSelect(value)}
        />
        <button
          type="button"
          className="w-full h-full absolute border top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 flex items-center justify-center"
        >
          {selectedZoneType === value ? (
            <DoneIcon style={{ fontSize: "14px" }} />
          ) : null}
        </button>
      </div>
      <label htmlFor="">{label}</label>
    </div>
  );
}

export default InputRadio;
