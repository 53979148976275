import React, { ReactNode, createContext } from "react";
import useGetClient from "../../../../api/client/useGetClient";
import { useParams } from "react-router-dom";
import { ClientDataTypes } from "../../../../types/client-data-types";
import { UseQueryResult } from "react-query";

export const ClientContext = createContext<UseQueryResult<any, unknown> | null>(
  null
);

export default function ClientContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { clientID } = useParams();

  const client = useGetClient(clientID!);

  return (
    <ClientContext.Provider value={client}>{children}</ClientContext.Provider>
  );
}
