import React, {RefObject, useEffect, useState} from "react";
import FormSection from "../form-section";
import {UserDataTypes, UserPassport} from "../../../../../../types/user-data-types";
import InputText from "../../../../../../components/form/InputText";
import PassportNumber from "./PassportNumber";
import PassportExpiry from "./PassportExpiry";
import {BlueButton, OrangeButton} from "../../../../../../components/ui/Buttons";
import PassportCountry from "./PassportCountry";
import {countries} from "../../../../../../constants/countries";

type PassportDetailsProps = {
    passports: UserPassport[];
    addPassport: () => void;
    removePassport: (index: number) => void;
    setPassports: (passports: UserPassport[]) => void
}

const PassportDetails: React.FC<PassportDetailsProps> = ({passports, addPassport, removePassport, setPassports}) => {

    const onChange = (passport: UserPassport, index: number) => {
        const newPassports = [...passports]
        newPassports[index] = passport
        setPassports(newPassports)
    }

    return (
        <FormSection title="Passport Details">
            {passports.map((p, i) => (
                <div key={p.id} className={"mb-8"}>
                    <PassportFields passport={p} index={i} onChange={onChange} removePassport={removePassport}/>
                </div>
            ))}
            <div className="grid grid-cols-4 gap-8">
                <BlueButton text={"Add Passport"} onClick={addPassport}/>
            </div>
        </FormSection>
    )

}
type PassportFieldsProps = {
    passport: UserPassport;
    index: number;
    onChange: (passport: UserPassport, index: number) => void;
    removePassport: (index: number) => void;
}


const PassportFields: React.FC<PassportFieldsProps> = ({passport, index, onChange, removePassport}) => {
    const [number, setNumber] = useState(passport.number)
    const [expiresAt, setExpiresAt] = useState(passport.expiresAt)
    const [countryISO, setCountryISO] = useState(passport.countryISO)

    useEffect(() => {

        const countryObj = countries.find(c => c.code == countryISO)

        onChange({
            country: countryObj ? countryObj.name : "",
            countryISO,
            expiresAt,
            number,
            primary: index == 0,
        }, index)
    }, [number, expiresAt, countryISO])

    return (
        <div className="grid grid-cols-4 gap-8">
            <PassportNumber index={index} value={number} onChange={setNumber}/>
            <PassportExpiry index={index} value={expiresAt} onChange={setExpiresAt}/>
            <PassportCountry index={index} value={countryISO} onChange={setCountryISO}/>

            <div className="">
                <label>&nbsp;</label>
                <OrangeButton text={"Remove Passport"} onClick={() => {
                    removePassport(index)
                }}/>
            </div>
        </div>
    )
}

export default PassportDetails;
