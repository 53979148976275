import React from "react";
import Header from "../../components/header";
import MapApp from "../../components/map";
function Main() {
  return (
    <div className="w-full h-full">
      <Header />
      <MapApp />
    </div>
  );
}

export default Main;
