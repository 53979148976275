import api from "../axiosConfig";
import { useQuery } from "react-query";
import { DataType } from "../../features/trips/types/users-data-types";
import { useParams } from "react-router-dom";

export default function useGetUserProfile() {
  const { userID } = useParams();

  const fetchUserProfile = async () => {
    return await api
      .get(`/users/${userID}`)
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  return useQuery(["user-profile", userID], fetchUserProfile, {
    keepPreviousData: true,
  });
}
