import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import LoadingPage from "./LoadingPage";
import { CountryProfilesContext } from "../../../features/risk/pages/country-profiles/context/country-profiles";
function CountryProfiles() {
  const countryProfilesContext = useContext(CountryProfilesContext)!;

  return countryProfilesContext.all_country_profiles_status !== "success" ? (
    <LoadingPage status={countryProfilesContext.all_country_profiles_status} />
  ) : (
    <div className="flex-1 h-full">
      <Outlet />
    </div>
  );
}

export default CountryProfiles;
