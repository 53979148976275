import React, { RefObject } from "react";
import { countries } from "../../constants/countries";
function CountriesSelect({
  defaultValue,
  countryOfResidenceRef,
}: {
  defaultValue?: string;
  countryOfResidenceRef: RefObject<HTMLSelectElement>;
}) {
  return (
    <select
      name="countries"
      id=""
      defaultValue={defaultValue ? defaultValue : "select_country"}
      className="w-full shadow-md rounded-md px-4 h-10"
      ref={countryOfResidenceRef}
    >
      <option value="select_country" disabled>
        Select Country
      </option>
      {countries.map((country) => (
        <option value={country.code} key={country.code}>
          {country.name}
        </option>
      ))}
    </select>
  );
}

export default CountriesSelect;


type CountrySelectV2Props = {
  value: string
  onChange: (iso: string) => void
}

export const CountrySelectV2: React.FC<CountrySelectV2Props> = ({value, onChange}) => {
  return (
      <select
          name="countries"
          id=""
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="w-full shadow-md rounded-md px-4 h-10 bg-white"
      >
        <option value="select_country" disabled>
          Select Country
        </option>
        {countries.map((country) => (
            <option value={country.code} key={country.code}>
              {country.name}
            </option>
        ))}
      </select>
  );
}

