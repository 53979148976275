import { useEffect, useRef, useState } from "react";
import ZonesDataTypes, {
  PointLocation,
  PolygonLocation,
} from "../../sites/types";
import api from "../../../api/axiosConfig";
import moment from "moment";
const useGeofencesMarker = (
  map: google.maps.Map | undefined,
  geofences: ZonesDataTypes[],
  siteTypes: string[],
  clientID?: string
) => {
  const [isGeofences, setIsGeofence] = useState<boolean>(false);
  const polygonRef = useRef<google.maps.Polygon[]>([]);
  const circlesRef = useRef<google.maps.Circle[]>([]);
  const currentDate = moment();
  const filteredGeofence = geofences
    ?.filter((geofence) =>
      !clientID || clientID === "all"
        ? geofence
        : geofence?.clientID === clientID
    )
    .filter((geofence) => {
      if (geofence.alwaysActive) return geofence;
      if (moment(geofence.expiresAt).isBefore(currentDate)) {
        return null;
      }

      if (moment(geofence.startsAt).isAfter(currentDate)) {
        return null;
      }
      return geofence;
    });
  const fetchClient = async (id: string) => {
    return await api
      .get(`/clients/${id}`)
      .then((res) => res.data)
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    setIsGeofence(siteTypes.includes("geofence"));
  }, [siteTypes]);

  /**
   * Render geofences
   */

  useEffect(() => {
    if (!map || !siteTypes.includes("geofence")) return;
    const infowindow = new google.maps.InfoWindow();
    const polygons: google.maps.Polygon[] = [];
    const circles: google.maps.Circle[] = [];

    filteredGeofence.forEach((geofence) => {
      const geofenceStringDiv = async () => {
        const client = await fetchClient(geofence.clientID);
        const convertedStartTime = moment(geofence.startsAt).format(
          "YYYY-MM-DD HH:mm:ss Z"
        );
        const convertedExpiresTime = moment(geofence.expiresAt).format(
          "YYYY-MM-DD HH:mm:ss Z"
        );
        return `<div style="font-size: 14px;  padding: 16px">
        <div>
          <p>
            <span style="font-weight: 500;">Geofence Name:</span> 
            ${geofence.name}
          </p>
          <p>
            <span style="font-weight: 500;">Client:</span> 
            ${client.name}
          </p>
          ${
            geofence.alwaysActive
              ? "<span style=\"font-weight: 500;\">Permanent Geofence</span>"
              : `          <p>
            <span style="font-weight: 500;">Geofence Starts At:</span> 
            ${convertedStartTime}
          </p>
          <p>
            <span style="font-weight: 500;">Geofence Expires At:</span> 
            ${convertedExpiresTime}
          </p>`
          }

        </div>
      </div>`;
      };

      if (geofence.location.type === "Point") {
        const location = geofence.location as PointLocation;
        const coordinates = location.coordinates;
        const lng = coordinates[0] as number;
        const lat = coordinates[1] as number;
        const position = { lng, lat };
        const circle = new google.maps.Circle({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          map,
          center: position,
          radius: geofence.location.properties.radius,
        });
        circle.setMap(map);
        circle.addListener("click", async (e: google.maps.MapMouseEvent) => {
          infowindow.setContent(await geofenceStringDiv());
          infowindow.setPosition({
            lng: e.latLng!.lng(),
            lat: e.latLng!.lat(),
          });
          infowindow.open(map);
        });
        return circles.push(circle);
      } else {
        const location = geofence.location as PolygonLocation;
        const coordinates = location.coordinates.flatMap((coords) => coords);
        const path = coordinates.map((coords) => ({
          lng: coords[0],
          lat: coords[1],
        }));
        const polygon = new google.maps.Polygon({
          strokeWeight: 2,
          paths: path,
          fillOpacity: 0.35,
          fillColor: "#FF0000",
          strokeColor: "#FF0000",
        });

        polygon.addListener("click", async (e: google.maps.MapMouseEvent) => {
          infowindow.setContent(await geofenceStringDiv());
          infowindow.setPosition({
            lat: e.latLng!.lat(),
            lng: e.latLng!.lng(),
          });
          infowindow.open(map);
        });
        polygon.setMap(map);
        return polygons.push(polygon);
      }
    });
    circlesRef.current.forEach((c) => c.setMap(null));
    polygonRef.current.forEach((p) => p.setMap(null));
    circlesRef.current = circles;
    polygonRef.current = polygons;
    circlesRef.current.forEach((c) => c.setMap(map));
    polygonRef.current.forEach((p) => p.setMap(map));
  }, [filteredGeofence, map, siteTypes]);

  /**
   * Show hide markers and circles
   */

  useEffect(() => {
    if (!map) return;

    if (isGeofences) {
      polygonRef.current.forEach((p) => p.setMap(map));
      circlesRef.current.forEach((c) => c.setMap(map));
      return;
    }
    polygonRef.current.forEach((p) => p.setMap(null));
    circlesRef.current.forEach((c) => c.setMap(null));
  }, [map, isGeofences]);

  useEffect(() => {
    if (!map) return;
    if (siteTypes.includes("geofence")) {
      polygonRef.current.forEach((p) => p.setMap(map));
      circlesRef.current.forEach((c) => c.setMap(map));
    } else {
      polygonRef.current.forEach((p) => p.setMap(null));
      circlesRef.current.forEach((c) => c.setMap(null));
    }
  }, [siteTypes, map]);
};

export default useGeofencesMarker;
