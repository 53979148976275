import { useMutation, useQuery, useQueryClient } from "react-query";
import useInfiniteApi from "../../../hooks/api/useInfiniteApi";
import api from "../../../api/axiosConfig";
import { useNavigate, useParams } from "react-router-dom";
import { OverlaysDataTypes } from "../types";

export function useZones(params: {} = {}) {
  const endpoint = "safety-zones";
  const { zoneID } = useParams();
  const fetchZone = async () => {
    if (!zoneID) return;
    return await api
      .get(`${endpoint}/${zoneID}`)
      .then((res) => res.data)
      .catch((err) => console.error(err));
  };
  const createZone = async (data: {}) => {
    return await api
      .post(endpoint, data)
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  const updateZone = async (data: any) => {
    return await api
      .patch(`${endpoint}/${data.id}`, data)
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  const deleteZone = async (id: string) => {
    return await api
      .delete(`${endpoint}/${id}`)
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  const zonesQuery = useInfiniteApi(endpoint, params, "safety-zones");
  const zoneQuery = useQuery(["zone", zoneID], fetchZone);
  const createMutation = useMutation((data: {}) => createZone(data));
  const updateMutation = useMutation((data: any) => updateZone(data));
  const deleteMutation = useMutation((id: string) => deleteZone(id));
  return {
    zoneQuery,
    createMutation,
    zonesQuery,
    updateMutation,
    deleteMutation,
  };
}

export function useGeofences(params: {} = {}) {
  const endpoint = "geofences";
  const { zoneID } = useParams();

  const fetchGeofence = async () => {
    if (!zoneID) return;
    return await api
      .get(`${endpoint}/${zoneID}`)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => console.error(err));
  };
  const createGeofence = async (data: {}) => {
    return await api
      .post(endpoint, data)
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  const updateGeofence = async (data: any) => {
    return await api
      .patch(`${endpoint}/${data.id}`, data)
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  const deleteGeofence = async (id: string) => {
    return await api
      .delete(`${endpoint}/${id}`)
      .then((res) => res)
      .catch((err) => console.log(err));
  };
  const geofenceQuery = useQuery(["geofence", zoneID], fetchGeofence);
  const geofencesQuery = useInfiniteApi("geofences", params, "geofences");
  const createMutation = useMutation((data: {}) => createGeofence(data));
  const updateMutation = useMutation((data: any) => updateGeofence(data));
  const deleteMutation = useMutation((id: string) => deleteGeofence(id));
  return {
    geofenceQuery,
    geofencesQuery,
    createMutation,
    updateMutation,
    deleteMutation,
  };
}

export function useCustomOverlays(params: {} = {}) {
  const endpoint = "map-overlays";
  const queryClient = useQueryClient();
  const { id } = useParams();
  const navigate = useNavigate();
  const fetchOverlay = async () => {
    if (!id) return;
    return await api.get(`${endpoint}/${id}`).then((res) => {
      const data: OverlaysDataTypes = res.data;
      return data;
    });
  };

  const createOverlay = async (data: {}) => {
    return await api
      .post(endpoint, data)
      .then((res) => res.data)
      .then(() => {
        navigate("/sites");
      })
      .catch((err) => console.error(err));
  };

  const updateOverlay = async (data: {}) => {
    return await api
      .patch(`${endpoint}/${id}`, data)
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  const deleteOverlay = async (id: string) => {
    return await api.delete(`${endpoint}/${id}`);
  };

  const overlaysQuery = useInfiniteApi(endpoint, params, "custom-overlay");
  const overlayQuery = useQuery(["custom-overlays", id], fetchOverlay);
  const createMutation = useMutation((data: {}) => createOverlay(data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries("custom-overlay");
    },
  });

  const updateMutation = useMutation((data: {}) => updateOverlay(data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["custom-overlays", id]);
      await queryClient.invalidateQueries("custom-overlay");
      navigate(-1);
    },
  });

  const deleteMutation = useMutation((id: string) => deleteOverlay(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries("custom-overlay");
    },
  });

  return {
    overlaysQuery,
    overlayQuery,
    createMutation,
    deleteMutation,
    updateMutation,
  };
}
