import React, { useState } from "react";
import PencilIcon from "../../../../../assets/icons/pencil.svg";
import BinIcon from "../../../../../assets/icons/icon-bin.svg";
import Modal from "../../../../../components/portal/Modal";
import DeleteConfirmation from "../../../components/delete-confirmation";
import useClient from "../../../../../hooks/api/useClient";
import { OverlaysDataTypes } from "../../../types";
import moment from "moment";
import { useCustomOverlays } from "../../../services";
import { useNavigate } from "react-router-dom";
function Row({ overlay }: { overlay: OverlaysDataTypes }) {
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const { data: client, isLoading } = useClient(overlay.clientID);
  const { deleteMutation: deleteCustomOverlay } = useCustomOverlays();
  const handleDeleteCustomOverlay = () => {
    deleteCustomOverlay.mutateAsync(overlay.id, {
      onSuccess: () => setDeleteModal(false),
    });
  };

  const handleEdit = () => {
    navigate(`/sites/edit-custom-overlay/${overlay.id}`);
  };

  return isLoading ? null : (
    <>
      <div
        className="grid grid-cols-7 bg-desaturated-grey rounded-lg items-center h-20"
        key={overlay.id}
      >
        <span className="border-r">
          {overlay.name !== "" ? overlay.name : "N/A"}
        </span>
        <span className="border-r">{client ? client.name : "null"}</span>
        <span className="border-r">
          {overlay.description !== "" ? overlay.description : "N/A"}
        </span>
        <span className="border-r capitalize">
          {overlay.audit[0].user.name}
        </span>
        <div className="flex items-center gap-2 justify-center">
          <div className="flex flex-col">
            <span>
              {moment.utc(overlay.createdAt).format("DD/MM/YYYY/ HH:mm:ss")}
            </span>
          </div>
        </div>
        <div className="border-r border-l">
          <button className="mx-auto w-4 h-4" onClick={handleEdit}>
            <img src={PencilIcon} alt="" />
          </button>
        </div>
        <div className="">
          <button
            className="mx-auto w-4 h-4"
            onClick={() => setDeleteModal(true)}
          >
            <img src={BinIcon} alt="" />
          </button>
        </div>
      </div>
      <Modal open={deleteModal} setOpen={setDeleteModal}>
        <DeleteConfirmation
          text="You are about to delete a Custom Overlay. Please press the button below to confirm."
          handleDelete={handleDeleteCustomOverlay}
          cancelDelete={setDeleteModal}
        />
      </Modal>
    </>
  );
}

export default Row;
