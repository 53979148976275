import React from "react";
import { Oval } from "react-loader-spinner";

function LoadingSpinner({
  width,
  height,
  color,
}: {
  width?: number;
  height?: number;
  color?: string;
}) {
  return (
    <Oval
      height={width || 80}
      width={height || 80}
      color={color || "#003B6E"}
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor="#BCE0FD"
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  );
}



export default LoadingSpinner;
