import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Checkbox from "../../../../../../components/form/Checkbox";
import { WhiteButton } from "../../../../../../components/ui/Buttons";
function ZonesSidebarFilter() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterState, setFilterState] = useState({
    zones: true,
    geofence: true,
    safetyZone: true,
  });

  useEffect(() => {
    const siteTypes = searchParams.getAll("siteTypes");
    if (siteTypes.includes("geofence") && siteTypes.includes("safety-zone")) {
      setFilterState({ zones: true, geofence: true, safetyZone: true });
      return;
    }

    if (siteTypes.includes("geofence")) {
      setFilterState((prev) => ({ ...prev, geofence: true }));
    } else {
      setFilterState((prev) => ({ ...prev, geofence: false }));
    }

    if (siteTypes.includes("safety-zone")) {
      setFilterState((prev) => ({ ...prev, safetyZone: true }));
    } else {
      setFilterState((prev) => ({ ...prev, safetyZone: false }));
    }
  }, [searchParams]);

  useEffect(() => {
    if (!filterState.zones) {
      setFilterState((prev) => ({
        ...prev,
        geofence: false,
        safetyZone: false,
      }));
    }
    if (filterState.zones) {
      setFilterState((prev) => ({ ...prev, geofence: true, safetyZone: true }));
    }
  }, [filterState.zones]);

  return (
    <div className="font-semibold">
      <Checkbox
        name="sites-sidebar"
        value="zones"
        label="Zones"
        checked={filterState.zones}
        onClick={() =>
          setFilterState((prev) => ({ ...prev, zones: !prev.zones }))
        }
      />
      <div className="font-normal">
        <div className="px-4 my-2">
          <Checkbox
            value="geofence"
            label="Geofences"
            onClick={() =>
              setFilterState((prev) => ({
                ...prev,
                geofence: !prev.geofence,
                zones: false,
              }))
            }
            name="siteTypes"
            checked={filterState.geofence}
          />
          <Checkbox
            value="safety-zone"
            label="Safety Zone"
            onClick={() =>
              setFilterState((prev) => ({
                ...prev,
                safetyZone: !prev.safetyZone,
                zones: false,
              }))
            }
            name="siteTypes"
            checked={filterState.safetyZone}
          />
        </div>
        <div className="flex flex-col gap-2 my-2">
          <WhiteButton
            text="View / Edit"
            onClick={() => navigate("/sites/zones")}
          />
          <WhiteButton text="Add a Zone" onClick={() => navigate("add-zone")} />
        </div>
      </div>
      <hr className="my-8 opacity-80" />
    </div>
  );
}

export default ZonesSidebarFilter;
