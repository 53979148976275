export const downloadFile = async (url: string, fileName: string) => {
  await fetch(url, {
    headers: {
      "Content-Type": "application/pdf",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      if (blob) {
        const downloadLink = document.createElement("a");
        const objectURL = URL.createObjectURL(blob);
        downloadLink.target = "_blank";
        downloadLink.href = objectURL;
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);

        downloadLink.click();

        document.body.removeChild(downloadLink);

        URL.revokeObjectURL(objectURL);
      }
    })
    .catch((error) => {
      console.error("Failed to download file:", error);
    });
};
