import React, { SetStateAction, useRef, useState } from "react";

import SubTypesCheckbox from "./SubTypesCheckbox";
import { Done } from "@mui/icons-material";
import IncidentTypesDataTypes from "../../../../../../types/incident-types-data-types";

function CheckboxWrapper({
  incidentType,
}: {
  incidentType: IncidentTypesDataTypes;
}) {
  const [mainCheck, setMainCheck] = useState<boolean>(false);

  const checkboxRef = useRef<HTMLInputElement>(null);
  const handleFakeCheckbox = () => {
    if (!checkboxRef.current) return;
    setMainCheck((prev) => !prev);
    checkboxRef.current.checked = mainCheck;
  };

  return (
    <div className="">
      <div className="flex items-center gap-4 my-1">
        <input
          type="checkbox"
          name={"main-type"}
          ref={checkboxRef}
          value={incidentType.id}
          checked={mainCheck}
          className="absolute left-0 top-0 opacity-0"
        />
        <button
          className="h-4 w-4 flex items-center justify-center border"
          type="button"
          onClick={handleFakeCheckbox}
        >
          {mainCheck ? <Done style={{ width: "12px" }} /> : null}
        </button>
        <label htmlFor={"main-type"} className="font-semibold">
          {incidentType.name}
        </label>
      </div>
      <div className="grid grid-cols-3">
        {incidentType.types.map((type: any) => (
          <SubTypesCheckbox type={type} mainCheck={mainCheck} key={type.id} />
        ))}
      </div>
    </div>
  );
}

export default CheckboxWrapper;
