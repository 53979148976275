import React, { useContext, useEffect } from "react";
import WhiteLogoImage from "../../../assets/icons/ngs-white-blue.svg";
import { UserContext } from "../../../context/user";

function WhiteLogo() {
  const userContext = useContext(UserContext)!;
  const [clientLogo, setClientLogo] = React.useState<Blob | null>(null);

  useEffect(() => {
    const fetchImage = async () => {
      return await fetch(
        `${process.env.REACT_APP_API_URL}files/platform-logo/${userContext
          ?.metadata.clientID!}`
      )
        .then(async (response) => {
          if (!response.ok) return;
          const blob = await response.blob();
          setClientLogo(blob);
        })
        .catch((err) => console.error(err));
    };
    fetchImage();
  }, []);

  return (
    <div className="w-full h-full flex justify-center items-center">
      <img
        src={
          clientLogo !== null ? URL.createObjectURL(clientLogo) : WhiteLogoImage
        }
        alt="logo of the company"
        className="h-fill w-auto max-h-30 max-w-[200px] object-contain"
      />
    </div>
  );
}

export default WhiteLogo;
