import React from "react";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import DoneIcon from "@mui/icons-material/Done";
function LoadingPage({
  clientStatus,
}: {
  clientStatus: "idle" | "loading" | "success" | "error";
}) {
  return (
    <div className="h-screen pt-28 w-full flex gap-24 justify-center items-center">
      <div className="flex flex-col gap-4">
        <div
          className={`${
            clientStatus === "success" ? "opacity-100" : "opacity-50"
          } flex gap-12 justify-between`}
        >
          <p>Loading client</p>
          {clientStatus === "success" ? (
            <DoneIcon style={{ opacity: 1 }} />
          ) : (
            <DoneIcon style={{ opacity: 0 }} />
          )}
        </div>
      </div>

      <LoadingSpinner />
    </div>
  );
}

export default LoadingPage;
