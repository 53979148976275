import React, { useEffect, useRef, useState } from "react";
import Row from "./Row";
import LoadingSpinner from "../../../../../components/ui/LoadingSpinner";
import FilterIcon from "../../../../../assets/icons/filter-icon.svg";
import Modal from "../../../../../components/portal/Modal";
import EditSiteBasedAlerts from "../../../components/edit-site-based-alerts";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";
import useFacilities from "../../../../../services/useFacilities";
import { FacilityDataTypes } from "../../../../../types/facilitiy-data-types";

type TablePropsType = {
  data: FacilityDataTypes[],
  isLoading: boolean,
  tableHeader: {
    label: string;
    icon?: string;
    iconConfirmed?: string;
    id: string;
  }[];
};

function Table({ tableHeader, data, isLoading }: TablePropsType) {

  const [facilities, setFacilities] = useState<FacilityDataTypes[]>(data);
  const [filterByName, setFilterByName] = useState<string>("");
  const [filterByClient, setFilterByClient] = useState<
    { clientID: string; clientName: string }[]
  >([]);

  const [editSiteBasedAlertsModal, setEditSiteBasedAlertsModal] =
    useState<boolean>(false);
  const [editSiteBasedAlertsFacilityID, setEditSiteBasedAlertsFacilityID] =
    useState<string | null>(null);
  const [showFilterName, setShowFilterName] = useState<boolean>(false);
  const [showFilterClient, setShowFilterClient] = useState<boolean>(false);
  const filterNameInputRef = useRef<HTMLInputElement>(null);
  const filterClientRef = useRef<HTMLDivElement>(null);

  const clientOptions = facilities
    .map((facility) => {
      return { clientName: facility.clientName, clientID: facility.clientID };
    })
    .filter((v, i, a) => a.findIndex((v2) => v2.clientID === v.clientID) === i)
    .sort((a, b) => {
      if (a.clientName < b.clientName) {
        return -1;
      }
      if (a.clientName > b.clientName) {
        return 1;
      }
      return 0;
    });

  useEffect(() => {
    if (filterByName === "") {
      setFacilities(data);
      return;
    }
  }, [data, filterByName]);
  
  useEffect(() => {
    if (filterByName === "") {
      setFacilities(data);
    }

    const filteredItems = data.filter((item: FacilityDataTypes) =>
      item.name.toLowerCase().includes(filterByName.toLowerCase())
    );
    setFacilities(filteredItems);
  }, [filterByName, data]);

  useEffect(() => {
    if (filterByClient.length < 1) {
      setFacilities(data);
      return;
    }
    const filteredItems = data.filter((item: FacilityDataTypes) => {
      if (filterByClient.find((client) => client.clientID === item.clientID)) {
        return item;
      }
    });

    setFacilities(filteredItems);
  }, [filterByClient, data]);

  useOnClickOutside(filterNameInputRef, () => {
    setShowFilterName(false);
  });
  useOnClickOutside(filterClientRef, () => {
    setShowFilterClient(false);
  });

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="text-center flex flex-col gap-6">
      <div className="grid grid-cols-[12%_12%_10%_25%_10%_10%_10%_12%] bg-desaturated-grey rounded-lg items-center h-20 p-2 font-semibold">
        {tableHeader.map((rowItem, i) => {
          return (
            <div className="h-full relative" key={i}>
              <div
                className={`${
                  i < tableHeader.length - 1 ? "border-r" : null
                } h-full flex items-center justify-center `}
                key={rowItem.id}
              >
                <span>{rowItem.label}</span>
                {rowItem.label === "Name" && (
                  <button
                    className="absolute right-10"
                    onClick={() => setShowFilterName(true)}
                  >
                    <img src={FilterIcon} alt="" />
                  </button>
                )}
                {rowItem.label === "Client" && (
                  <button
                    className="absolute right-10"
                    onClick={() => setShowFilterClient(true)}
                  >
                    <img src={FilterIcon} alt="" />
                  </button>
                )}
              </div>
              {rowItem.label === "Name" && showFilterName && (
                <div className="">
                  <input
                    type="text"
                    placeholder="Search Name"
                    className="h-10 w-[90%] rounded-md shadow-md px-4 absolute -bottom-10 right-2/4 translate-x-2/4"
                    ref={filterNameInputRef}
                    onChange={(e: any) => {
                      setFilterByName(e.target.value);
                    }}
                    defaultValue={filterByName}
                  />
                </div>
              )}
              {rowItem.label === "Client" && showFilterClient && (
                <div className="relative">
                  <div
                    className="max-h-52 overflow-y-auto bg-white absolute text-left p-4 flex flex-col gap-2"
                    ref={filterClientRef}
                  >
                    {clientOptions.map((client) => (
                      <div
                        className="flex gap-2 items-center"
                        key={client.clientID}
                      >
                        <input
                          type="checkbox"
                          value={client.clientID}
                          defaultChecked={
                            filterByClient.find(
                              (item) => item.clientID === client.clientID
                            )
                              ? true
                              : false
                          }
                          onChange={(e: any) => {
                            if (e.target?.checked) {
                              setFilterByClient((prev) => [...prev, client]);
                            } else {
                              setFilterByClient((prev) =>
                                prev.filter(
                                  (item) => item.clientID !== client.clientID
                                )
                              );
                            }
                          }}
                        />
                        <label htmlFor="">{client.clientName}</label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="flex flex-col gap-6 overflow-y-auto max-h-[60vh]">
        {facilities.map((facility, i) => {
          return (
            <Row
              facility={facility}
              key={i}
              setEditSiteBasedAlertsFacilityID={
                setEditSiteBasedAlertsFacilityID
              }
              setEditSiteBasedAlertsModal={setEditSiteBasedAlertsModal}
            />
          );
        })}
      </div>
      <Modal
        open={editSiteBasedAlertsModal}
        setOpen={setEditSiteBasedAlertsModal}
      >
        <EditSiteBasedAlerts
          facilitiyID={editSiteBasedAlertsFacilityID}
          setEditSiteBasedAlertsModal={setEditSiteBasedAlertsModal}
        />
      </Modal>
    </div>
  );
}

export default Table;
