import React, { useCallback, useMemo } from "react";
import { Feature, Overlay as OverlayType } from "../../types/overlay";
import { Polygon } from "./polygon";
import { Circle } from "./circle";
import { getCoordinatesCenter } from "./math";
import { AdvancedMarker, InfoWindow, Marker, useAdvancedMarkerRef } from "@vis.gl/react-google-maps";
import { Polyline } from "./polyline";
import { useOperationalMapContext } from "../../providers/OperationalMapProvider";

type OverlayProps = {
  overlay: OverlayType,
}

export const Overlay: React.FC<OverlayProps> = ({ overlay }) => {
  const ctx = useOperationalMapContext();

  const getCenter = useCallback((feature: Feature) => {
    if (feature.geometry.type === "Point") {
      const [lng, lat] = feature.geometry.coordinates;
      return { lng, lat };
    }

    if (feature.geometry.type === "Polygon") {
      return getCoordinatesCenter(feature.geometry.coordinates);
    }
  }, []);

  return (
    <>
      {overlay.data.features.map((f, i) => (
        <OverlayItem
          key={i}
          feature={f}
          infoKey={`${overlay.id}_${i}`}
          showInfoWindow={ctx.visibleInfoWindow === `${overlay.id}_${i}`}
        />
      ))}
    </>
  );
}

type OverlayItemProps = {
  infoKey: string,
  feature: Feature,
  showInfoWindow: boolean,
}

const OverlayItem: React.FC<OverlayItemProps> = ({ infoKey, feature, showInfoWindow }) => {
  const ctx = useOperationalMapContext();
  const [markerRef, marker] = useAdvancedMarkerRef();

  const onClick = useCallback(() => {
    ctx.setVisibleInfoWindow(infoKey);
  }, [ctx, infoKey]);

  const getCenter = useCallback((feature: Feature) => {
    if (feature.geometry.type === "Point") {
      return getCoordinatesCenter([feature.geometry.coordinates]);
    }
    return getCoordinatesCenter(feature.geometry.coordinates);
  }, []);

  const center = useMemo(() => getCenter(feature), [feature, getCenter]);

  return (
    <>
      <AdvancedMarker ref={markerRef} position={center}>
        <div></div>
      </AdvancedMarker>

      {showInfoWindow && (
        <InfoWindow anchor={marker}>
          <div className="mt-2 grid grid-cols-[1fr,3fr] gap-x-4">
            <div className="text-base font-semibold">Name</div>
            <div className="text-base capitalize">{feature.properties.name}</div>
            <div className="text-base font-semibold">Description</div>
            <div className="text-base capitalize">{feature.properties.description}</div>
          </div>
        </InfoWindow>
      )}
      <Shape feature={feature} onClick={onClick} />
    </>
  );
}

type ShapeProps = {
  feature: Feature,
  onClick: () => void,
}

const Shape: React.FC<ShapeProps> = ({ feature, onClick }) => {
  const shapeProps = useMemo(() => ({
    strokeColor: feature.properties.style.lineColor,
    fillColor: feature.properties.style.fillColor,
    strokeOpacity: feature.properties.style.lineOpacity,
    fillOpacity: feature.properties.style.fillOpacity,
    strokeWeight: feature.properties.style.lineWidth,
  }), [feature]);

  switch (feature.geometry.type) {
    case "Polygon":
      return <Polygon
        zIndex={50}
        onClick={onClick}
        coordinates={feature.geometry.coordinates}
        {...shapeProps}
      />;
    case "Point":
      if (feature.properties.radius) {
        const [lng, lat] = feature.geometry.coordinates;
        return <Circle
          zIndex={50}
          onClick={onClick}
          radius={feature.properties.radius}
          center={{ lng, lat }}
          {...shapeProps}
        />;
      }
      if (!feature.properties.style.fontSize) {
        const [lng, lat] = feature.geometry.coordinates;
        return <Marker
          position={{ lat, lng }}
          onClick={onClick}
          zIndex={100}
          label={{
            text: feature.properties.icon || "\ue55e",
            fontFamily: "Material Icons",
            color: "#ffffff",
            fontSize: "18px",
          }}
        />;
      }
      return <Marker
        position={{ lat: feature.geometry.coordinates[1], lng: feature.geometry.coordinates[0] }}
        onClick={onClick}
        zIndex={100}
        icon={{
          path: google.maps.SymbolPath.CIRCLE,
          scale: 0,
        }}
        label={{
          text: feature.properties.label,
          color: feature.properties.style.fontColor,
          fontSize: `${feature.properties.style.fontSize}px`,
        }}
      />;
    case "LineString":
      return <Polyline
        zIndex={75}
        onClick={onClick}
        coordinates={feature.geometry.coordinates}
        strokeColor={feature.properties.style.lineColor}
        strokeWeight={feature.properties.style.lineWidth}
      />;
    default:
      console.log("FEATURE", feature);
      return null;
  }
}
