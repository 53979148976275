// @ts-nocheck
import React, {
  FormEvent,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { UserContext } from "../../../../context/user";
import { useNavigate, useParams } from "react-router-dom";
import useAccessibleRolesForUser from "../../hooks/useAccessibleRolesForUser";
import { RoleTypes } from "../../../../types/roles-types";
import { BlueButton } from "../../../../components/ui/Buttons";
import useGetAllClientDepartments from "../../../../api/client-departments/useGetAllClientDepartments";
import { DepartmentDataTypes } from "../../../../types/department-data-types";
import moment from "moment";
import convertDateToUTC from "../../../../util/convertDateToUTC";
import api from "../../../../api/axiosConfig";
import { useQueryClient } from "react-query";
import { nationalities } from "../../../../constants/nationalities";
import { countries } from "../../../../constants/countries";
import { languages } from "../../../../constants/languages";
import NationalitySelect from "../../../../components/form/NationalitySelect";
import { KeyboardArrowDown } from "@mui/icons-material";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import Checkbox from "../../../../components/form/Checkbox";
import { emailTemplate } from "../../pages/add-client/constants/email-template";
import TextTemplate from "../../../../components/ui/Content/TextTemplate";

function AddUserForm({ onCreated }: { onCreated: () => void }) {
  const queryClient = useQueryClient();
  const [clientDepartments, setClientDepartments] = useState<
    DepartmentDataTypes[] | null
  >(null);

  const userContext = useContext(UserContext);
  const [invitationEmailSelected, setInvitationEmailSelected] =
    useState<boolean>(false);
  const [emailTemplateText, setEmailTemplateText] =
    useState<string>(emailTemplate);
  const { clientID } = useParams();
  const navigate = useNavigate();
  const [multiSelectLang, setMultiSelectLang] = useState<boolean>(false);
  const nameRef = useRef<HTMLInputElement>(null);
  const middleNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const dateOfBirthRef = useRef<HTMLInputElement>(null);
  const departmentRef = useRef<HTMLSelectElement>(null);
  const roleRef = useRef<HTMLSelectElement>(null);
  const emailAddressRef = useRef<HTMLInputElement>(null);
  const multiLanguageRef = useRef<HTMLSelectElement>(null);
  const genderRef = useRef<HTMLSelectElement>(null);
  const nationalityRef = useRef<HTMLSelectElement>(null);
  const genderAtBirthRef = useRef<HTMLSelectElement>(null);
  const countryOfResidenceRef = useRef<HTMLSelectElement>(null);
  const mainLanguageRef = useRef<HTMLSelectElement>(null);
  const accessibleRolesForUser = useAccessibleRolesForUser(
    userContext?.role.name!
  );
  const departments = useGetAllClientDepartments(clientID!);

  useEffect(() => {
    if (departments.status === "success") {
      setClientDepartments(departments.data);
    }
  }, [clientID, departments.status]);

  useEffect(() => {
    console.log(roleRef.current!.value);
  }, [roleRef]);

  const handleAddUser = (e: FormEvent) => {
    e.preventDefault();

    const firstName = nameRef.current!.value;
    const middleName = middleNameRef.current?.value;
    const lastName = lastNameRef.current!.value;
    const dateOfBirth = dateOfBirthRef.current!.value;
    const departmentID = departmentRef.current!.value;
    const roleID = roleRef.current!.value;
    console.log(roleID);
    const emailAddress = emailAddressRef.current!.value;
    const selectedLanguages: { code: string; primary: boolean }[] = [];
    const nationality = nationalityRef.current!.value;
    const gender = genderRef.current!.value;
    const genderAtBirth = genderAtBirthRef.current!.value;
    const countryOfResidence = countryOfResidenceRef.current!.value;

    for (let i = 0; i < multiLanguageRef.current!.selectedOptions.length; i++) {
      selectedLanguages.push({
        code: multiLanguageRef.current!.selectedOptions[i].value.toUpperCase(),
        primary: false,
      });
    }

    if (roleID === "select-role")
      return console.error("Department or Role not selected.");

    const userMainLanguage = {
      code: mainLanguageRef.current!.value,
      primary: true,
    };
    const userAdditionalLanguages = selectedLanguages;
    const languages = [userMainLanguage, ...userAdditionalLanguages];
    const formData =
      middleName !== ""
        ? {
            clientID: clientID,
            departmentID:
              departmentID === "select-department" ? null : departmentID,
            roleID: roleID,
            email: emailAddress,
            profile: {
              firstName,
              middleNames: middleName,
              lastName,
              dateOfBirth: moment(dateOfBirth).format("YYYY-MM-DD[T00:00:00Z]"),
              nationality: nationality,
              gender: gender,
              genderAssignedAtBirth: genderAtBirth,
              countryOfResidence: countryOfResidence,
            },
            languages: languages,
            needsInviting: invitationEmailSelected,
            emailBody: invitationEmailSelected ? emailTemplateText : "",
          }
        : {
            clientID: clientID,
            departmentID:
              departmentID === "select-department" ? null : departmentID,
            roleID: roleID,
            email: emailAddress,
            profile: {
              firstName,
              lastName,
              dateOfBirth: moment(dateOfBirth).format("YYYY-MM-DD[T00:00:00Z]"),
              nationality: nationality,
              gender: gender,
              genderAssignedAtBirth: genderAtBirth,
              countryOfResidence: countryOfResidence,
            },
            languages: languages,
            needsInviting: invitationEmailSelected,
            emailBody: invitationEmailSelected ? emailTemplateText : "",
          };

    console.log(formData);
    api
      .post("users", formData)
      .then((res) => {
        console.log(res);
        queryClient.invalidateQueries("client-users");
        onCreated();
      })
      .catch((err) => console.error(err));
  };

  useOnClickOutside(multiLanguageRef, () => setMultiSelectLang(false));

  const handleSaveEditor = (text: string) => {
    setEmailTemplateText(text);
  };

  return (
    <form className="bg-white px-8 py-6 rounded-xl shadow-xl mx-32" onSubmit={handleAddUser}>
      <h4 className="font-semibold text-lg">Add User</h4>
      <hr className="my-4 opacity-50" />
      <div className="grid grid-cols-3 gap-4">
        <input
          type="text"
          className={`shadow-md h-10 rounded-md w-full px-4 ${
            nameRef?.current?.value ? "text-grey" : "text-light-grey"
          }`}
          placeholder="First Name"
          required
          ref={nameRef}
        />
        <input
          type="text"
          className={`shadow-md h-10 rounded-md w-full px-4 ${
            middleNameRef?.current?.value ? "text-grey" : "text-light-grey"
          }`}
          placeholder="Middle Name"
          ref={middleNameRef}
        />
        <input
          type="text"
          className={`shadow-md h-10 rounded-md w-full px-4 ${
            lastNameRef?.current?.value ? "text-grey" : "text-light-grey"
          }`}
          placeholder="Last Name"
          required
          ref={lastNameRef}
        />
        <input
          type="email"
          placeholder="Email Address"
          className={`shadow-md h-10 rounded-md w-full px-4 ${
            emailAddressRef?.current?.value ? "text-grey" : "text-light-grey"
          }`}
          required
          ref={emailAddressRef}
        />
        <input
          type="date"
          className={`shadow-md h-10 rounded-md w-full px-4 ${
            dateOfBirthRef?.current?.value ? "text-grey" : "text-light-grey"
          }`}
          placeholder="Date of Birth"
          required
          ref={dateOfBirthRef}
        />
        <select
          name="client-departments"
          id=""
          className={`h-10 w-full shadow-md rounded-md max-h-20 px-4 outline-none ${
            departmentRef?.current?.value ? "text-grey" : "text-light-grey"
          }`}
          defaultValue={"select-department"}
          required
          ref={departmentRef}
        >
          <option
            value="select-department"
            className="text-light-grey"
            disabled
          >
            Select Department
          </option>
          {clientDepartments?.map((department) => {
            return (
              <option
                value={department.id}
                key={department.id}
                id={department.id}
                className="text-grey"
              >
                {department.name}
              </option>
            );
          })}
        </select>
        <select
          name="client-roles"
          id=""
          className={`h-10 w-full shadow-md rounded-md max-h-20 px-4 outline-none ${
            roleRef?.current?.value !== "select-role"
              ? "text-grey"
              : "text-light-grey"
          }`}
          defaultValue={"select-role"}
          ref={roleRef}
          required
        >
          <option value="select-role" disabled>
            Select Role
          </option>
          {accessibleRolesForUser?.map((role: RoleTypes) => (
            <option
              value={role.id}
              id={role.id}
              key={role.id}
              className="text-grey"
            >
              {role.name}
            </option>
          ))}
        </select>

        <select
          name="main-language"
          id="main-language"
          defaultValue={"select-main-language"}
          required
          className={`h-10 w-full shadow-md rounded-md max-h-20 px-4 outline-none ${
            mainLanguageRef?.current?.value !== "select-main-language"
              ? "text-grey"
              : "text-light-grey"
          }`}
          ref={mainLanguageRef}
        >
          <option value="select-main-language">Select Main Language</option>
          {languages.map((language) => (
            <option
              value={language.code}
              key={language.code}
              className="text-grey"
            >
              {language.name}
            </option>
          ))}
        </select>
        <div className="relative h-fit  w-full rounded-md shadow-md ">
          <button
            className=" w-full h-10 flex items-center justify-between px-4  rounded-md shadow-md "
            onClick={() => setMultiSelectLang(true)}
            type="button"
          >
            <span className="flex-1 w-full text-sm text-left text-light-grey">
              Select Multiple Languages
            </span>
            <KeyboardArrowDown
              style={{
                width: "16px",
                position: "absolute",
                right: "0",
                top: "20%",
              }}
            />
          </button>
          <select
            name="multiple-language"
            id="multiple-language"
            multiple
            className={`absolute top-12 left-0 right-0 h-34 bg-white rounded-md shadow-md px-4 py-6 z-10 text-light-grey ${
              multiLanguageRef?.current?.value !== "select-multiple-languages"
                ? "text-grey"
                : "text-light-grey"
            } ${multiSelectLang ? "flex" : "hidden"}`}
            ref={multiLanguageRef}
          >
            {languages.map((lang) => {
              return (
                <option
                  value={lang.code}
                  className="capitalize"
                  key={lang.code}
                  className="text-grey"
                >
                  {lang.name}
                </option>
              );
            })}
          </select>
        </div>

        <select
          name="gender"
          className={`h-10 w-full shadow-md rounded-md max-h-20 px-4 outline-none ${
            genderRef?.current?.value !== "select-gender"
              ? "text-grey"
              : "text-light-grey"
          }`}
          defaultValue={"select-gender"}
          ref={genderRef}
          required
        >
          <option value="select-gender" disabled className="text-grey">
            Select Gender
          </option>
          <option
            value={"male"}
            key={"male"}
            defaultChecked={false}
            className="text-grey"
          >
            Male
          </option>
          <option
            value={"female"}
            key={"female"}
            defaultChecked={false}
            className="text-grey"
          >
            Female
          </option>
          <option
            value={"other"}
            key={"other"}
            defaultChecked={false}
            className="text-grey"
          >
            Other
          </option>
        </select>
        <select
          name="gender-at-birth"
          className={`h-10 w-full shadow-md rounded-md max-h-20 px-4 outline-none ${
            genderAtBirthRef?.current?.value !== "select-gender-at-birth"
              ? "text-grey"
              : "text-light-grey"
          }`}
          defaultValue={"select-gender-at-birth"}
          ref={genderAtBirthRef}
          required
        >
          <option value="select-gender-at-birth" className="text-grey" disabled>
            Select Gender at Birth
          </option>
          <option
            value={"male"}
            key={"male"}
            defaultChecked={false}
            className="text-grey"
          >
            Male
          </option>
          <option
            value={"female"}
            key={"female"}
            defaultChecked={false}
            className="text-grey"
          >
            Female
          </option>
          <option
            value={"other"}
            key={"other"}
            defaultChecked={false}
            className="text-grey"
          >
            Other
          </option>
        </select>

        <NationalitySelect nationalityRef={nationalityRef} />
        <select
          name="country-of-residence"
          className={`h-10 w-full shadow-md rounded-md max-h-20 px-4 outline-none ${
            countryOfResidenceRef?.current?.value !==
            "select-country-of-residence"
              ? "text-grey"
              : "text-light-grey"
          }`}
          defaultValue={"select-country-of-residence"}
          ref={countryOfResidenceRef}
          required
        >
          <option value="select-country-of-residence" className="" disabled>
            Select Country of Residence
          </option>
          {countries.map((country) => (
            <option
              value={country.code}
              key={country.code}
              defaultChecked={false}
              className="text-grey"
            >
              {country.name}
            </option>
          ))}
        </select>
      </div>

      <div className="flex gap-4 my-4">
        <Checkbox
          name={"invitation-email"}
          checked={invitationEmailSelected}
          value={"invitation-email"}
          label={"Add invitation email"}
          onClick={() => setInvitationEmailSelected((prev) => !prev)}
        />
      </div>

      {invitationEmailSelected && (
        <TextTemplate
          initialContent={emailTemplateText}
          onSave={handleSaveEditor}
          height="200px"
        />
      )}

      <div className="mt-4 w-1/3 mx-auto">
        <BlueButton
          type="submit"
          text="Save"
          onClick={(e: FormEvent) => handleAddUser(e)}
        />
      </div>
    </form>
  );
}

export default AddUserForm;
