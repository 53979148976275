import React, { useState, useEffect, useRef } from "react";
import FilterIcon from "../../../../../assets/icons/filter-icon.svg";
import { countries } from "../../../../../constants/countries";
import ReactDatePicker from "react-datepicker";
import useGetIncidentTypes from "../../../../../api/incident-types/useGetIncidentTypes";
import DropDown from "../../../../../components/form/DropDown";
import DateIcon from "../../../../../assets/icons/date.svg"
import UserSelect from "../../../../../components/form-v2/searchable-select/UserSelect";
import { UserDataTypes } from "../../../../../types/user-data-types";

function MessageFilter({ onApplyFilter, setPage }: { onApplyFilter: (filters: any) => void, setPage: (page: number) => void }) {
    const [firstDate, setFirstDate] = useState<Date | null>(null);
    const [finalDate, setFinalDate] = useState<Date | null>(null);
    const [user, setUser] = useState<undefined | UserDataTypes>();
    const [isOpen, setIsOpen] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const filters = {
            startTime: firstDate ? firstDate.toISOString() : "",
            endTime: finalDate ? finalDate.toISOString() : "",
            ...(user ? {userID: user.id} : {})
        };

        onApplyFilter(filters);
        setPage(1); 
        setIsOpen(false); 
    };

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const clearFilters = () => {
        setFirstDate(null);
        setFinalDate(null);
        formRef.current?.reset();

        onApplyFilter({});
    };

    return (
        <div>
            <button onClick={togglePopup} className="bg-gray-300 p-2 rounded-md">
                <img src={FilterIcon} alt="" className="pointer-events-none" />
            </button>

            {isOpen && (
                <div className="absolute top-32 left-16 bg-white p-4 rounded-md shadow-lg w-[25rem] mt-2 z-10 border border-gray-300">
                    <button onClick={togglePopup} className="absolute top-2 right-2 text-gray-500">X</button>
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-semibold">Messages Filter</h2>                        
                    </div>

                <form ref={formRef} onSubmit={handleFormSubmit} style={{height: "250px", overflowY: "visible" }} className='flex flex-col justify-between'>
                    <div className="mb-4">
                        <div className="flex flex-col w-full">
                            <div className="mb-4">
                            <DropDown
                                className="w-full"
                                dropDownIcon={DateIcon}
                                title="Dates"
                                transparentBox={true}
                                selectedValue={
                                    firstDate && finalDate
                                    ? `${firstDate.toLocaleDateString()} - ${finalDate.toLocaleDateString()}`
                                    : ""
                                }
                                selected={firstDate && finalDate ? true : false}
                            >
                                <ReactDatePicker
                                    inline
                                    isClearable
                                    selected={firstDate}
                                    onChange={(dates: [Date | null, Date | null]) => {
                                        const [start, end] = dates;

                                        setFirstDate(start);
                                        setFinalDate(end);
                                    }}
                                    startDate={firstDate}
                                    endDate={finalDate}
                                    selectsRange
                                />
                            </DropDown>
                            </div>
                            <UserSelect users={user ? [user] : []} multiple={false} onSelectionChange={(users) => setUser(users[0])}/>
                        </div>
                    </div> 
                    <div className="flex justify-between m-2 space-x-4">         
                        <button type='submit' className="mt-3 bg-blue text-white p-3 px-9 rounded-md">
                            Filter
                        </button>
                        <button type="button" onClick={clearFilters} className="mt-3 bg-light-grey text-white p-3 px-9 rounded-md ">
                            Clear
                        </button>
                    </div>
                </form>
                </div>
            )}
        </div>
    );
}

export default MessageFilter;
