import { useQuery } from "react-query";
import api from "../axiosConfig";
import moment from "moment/moment";
import {useTimeZoneSelectContext} from "../../providers/TimeZoneSelectProvider";

export default function useGetReportsSummary(
  startTime: string,
  endTime: string,
  clientIDs: string[],
  enabled: boolean
) {

  const tzContext = useTimeZoneSelectContext()
  const fetchReportsSummary = async () => {

    const end = new Date(endTime)
    end.setHours(23,59,59,999)

    const startStr = moment(startTime).format("YYYY-MM-DD HH:mm:ss");
    const startAdjusted = moment.tz(startStr, "YYYY-MM-DD HH:mm:ss", tzContext.timeZone)

    const endStr = moment(end).format("YYYY-MM-DD HH:mm:ss");
    const endAdjusted = moment.tz(endStr, "YYYY-MM-DD HH:mm:ss", tzContext.timeZone)

    return await api
      .get("/reports/summary", {
        params: {
          startTime: startAdjusted.toISOString(),
          endTime: endAdjusted.toISOString(),
          clientIDs: clientIDs.join(),
        },
      })
      .then((res) => res.data)
      .catch((err) => console.error(err));
  };

  const { data, status, error, refetch } = useQuery(
    "reports-summary",
    fetchReportsSummary,
    {
      enabled,
    }
  );

  return {
    data,
    status,
    error,
    refetch,
  };
}
