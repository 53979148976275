import React, { ReactNode, createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useGetUserProfile from "../../../../../../api/user-profile/useGetUserProfile";
import { UseQueryResult } from "react-query";
import { AxiosResponse } from "axios";
import useGetUserPersonalProfile from "../../../../../../api/user-profile/useGetUserPersonalProfile";

type UserProfileContextType = UseQueryResult<
  void | AxiosResponse<any, any>,
  unknown
>;

export const UserProfileContext = createContext<UserProfileContextType | null>(
  null
);

export default function UserProfileContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { userID } = useParams();

  const userProfile = useGetUserProfile();
  const userPersonalProfile = useGetUserPersonalProfile();

  return (
    <UserProfileContext.Provider
      value={userID ? userProfile : userPersonalProfile}
    >
      {children}
    </UserProfileContext.Provider>
  );
}
