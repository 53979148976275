import { SetStateAction, useEffect, useState } from "react";
import { FeatureType } from "../../types";
import {
  handleCircleComplete,
  handleMarkerComplete,
  handlePolygonComplete,
  handlePolylineComplete,
  handleTextComplete,
} from "../../utils";

type DrawingMode = "marker" | "polygon" | "polyline" | "circle" | "text" | null;

export default function useMapListeners(
  drawingMode: DrawingMode,
  disableDrawing: boolean,
  drawingManager: google.maps.drawing.DrawingManager | undefined,
  setDrawingMode: React.Dispatch<SetStateAction<DrawingMode>>,
  setDisableDrawing: React.Dispatch<SetStateAction<boolean>>,
  addFeature: (data: FeatureType) => void,
  setToggleFeatures: React.Dispatch<SetStateAction<boolean>>,
  map: google.maps.Map | undefined,
  setDisableSave: React.Dispatch<SetStateAction<boolean>>
) {
  function cleanUp() {
    google.maps.event.clearListeners(drawingManager!, "markercomplete");
    google.maps.event.clearListeners(drawingManager!, "polygoncomplete");
    google.maps.event.clearListeners(drawingManager!, "polylinecomplete");
    google.maps.event.clearListeners(drawingManager!, "circlecomplete");
  }

  function renderMarker(marker: google.maps.Marker) {
    console.log("render marker");
    setDisableDrawing(true);
    setDrawingMode(null);
    drawingManager!.setDrawingMode(null);
    handleMarkerComplete(marker, (data) => {
      addFeature(data);
      setToggleFeatures(true);
      setDisableSave(false);
    });
  }

  function renderText(marker: google.maps.Marker) {
    setDisableDrawing(true);
    setDrawingMode(null);
    drawingManager!.setDrawingMode(null);
    marker.setOptions({
      label: { text: "text", fontSize: "24px" },
      icon: {
        url: "",
        size: new google.maps.Size(1, 1),
      },
    });
    handleTextComplete(marker, (data) => {
      addFeature(data);
      setToggleFeatures(true);
      setDisableSave(false);
    });
  }

  function markerListener() {
    cleanUp();
    console.log("marker listener");
    return google.maps.event.addListener(
      drawingManager!,
      "markercomplete",
      drawingMode === "marker" ? renderMarker : renderText
    );
  }

  function polylineListener() {
    cleanUp();
    console.log("polyline listener");
    const listener = () =>
      google.maps.event.addListener(
        drawingManager!,
        "polylinecomplete",
        (polyline: google.maps.Polyline) => {
          setDisableDrawing(true);
          setDrawingMode(null);
          drawingManager!.setDrawingMode(null);
          handlePolylineComplete(polyline, (data) => {
            addFeature(data);
            setToggleFeatures(true);
            setDisableSave(false);
          });
        }
      );
    listener();
    google.maps.event.removeListener(listener());
  }

  function polygonListener() {
    cleanUp();
    console.log("polygon listener");
    const listener = () =>
      google.maps.event.addListener(
        drawingManager!,
        "polygoncomplete",
        (polygon: google.maps.Polygon) => {
          setDisableDrawing(true);
          setDrawingMode(null);
          drawingManager!.setDrawingMode(null);
          handlePolygonComplete(polygon, (data) => {
            addFeature(data);
            setToggleFeatures(true);
            setDisableSave(false);
          });
        }
      );
    listener();
    google.maps.event.removeListener(listener());
  }

  function circleListener() {
    cleanUp();
    console.log("circle listener");
    const listener = () =>
      google.maps.event.addListener(
        drawingManager!,
        "circlecomplete",
        (circle: google.maps.Circle) => {
          setDisableDrawing(true);
          setDrawingMode(null);
          drawingManager!.setDrawingMode(null);
          handleCircleComplete(circle, (data) => {
            addFeature(data);
            setToggleFeatures(true);
            setDisableSave(false);
          });
        }
      );
    listener();
    google.maps.event.removeListener(listener());
  }
  return useEffect(() => {
    if (!drawingManager || !drawingMode) return;
    if (drawingMode === "circle") {
      circleListener();
    } else if (drawingMode === "marker" || drawingMode === "text") {
      markerListener();
    } else if (drawingMode === "polygon") {
      polygonListener();
    } else {
      polylineListener();
    }
  }, [drawingManager, drawingMode]);
}
