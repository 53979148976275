import React, { SetStateAction } from "react";
const MATERIAL_ICONS = [
  "local_hospital",
  "local_airport",
  "warning",
  "\uef56",
  "\uef55",
  "home",
];

function MarkerIconsOption({
  icon,
  setIcon,
}: {
  icon: string;
  setIcon: React.Dispatch<SetStateAction<string>>;
}) {
  return (
    <div className="">
      <label htmlFor="">Icon</label>

      <ul className="grid grid-cols-5 my-4">
        {MATERIAL_ICONS.map((mi) => (
          <li
            className={`h-8 w-8 shadow-md rounded-md flex items-center justify-center cursor-pointer ${
              icon === mi ? "border border-blue" : "border-none"
            }`}
            onClick={() => setIcon(mi)}
            key={mi}
          >
            <i className="material-icons">{mi}</i>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default MarkerIconsOption;
