import React, {
  FormEvent,
  ReactNode,
  RefObject,
  createContext,
  useContext,
  useRef,
} from "react";
import { FormDataContextType } from "./form-data-context-types";
import { UserProfileContext } from "../user-profile";
import {
    UserDataTypes,
    UserEmergencyContact,
    UserInsurancePolicy,
    UserPassport
} from "../../../../../../types/user-data-types";
import api from "../../../../../../api/axiosConfig";
import { useQueryClient } from "react-query";
import convertDateToUTC from "../../../../../../util/convertDateToUTC";
import moment from "moment";
import { useParams } from "react-router-dom";
export const FormDataContext = createContext<FormDataContextType | null>(null);

export default function FormDataContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const queryClient = useQueryClient();
  const userProfileData = useContext(UserProfileContext);
  const { userID } = useParams();
  const userData: UserDataTypes = userProfileData?.data?.data;
  const profilePictureRef = useRef<HTMLInputElement>(null);
  const primaryPhoneNumberRef = useRef<HTMLInputElement>(null);
  const secondaryPhoneNumberRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const middleNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const dateOfBirthRef = useRef<HTMLInputElement>(null);
  const genderAssignedAtBirthRef = useRef<HTMLSelectElement>(null);
  const genderRef = useRef<HTMLSelectElement>(null);
  const nationalityRef = useRef<HTMLSelectElement>(null);
  const countryOfResidenceRef = useRef<HTMLSelectElement>(null);
  const mainLanguageRef = useRef<HTMLSelectElement>(null);
  const multiLanguageRef = useRef<HTMLSelectElement>(null);
  const employeeIDRef = useRef<HTMLInputElement>(null);
  const jobTitleRef = useRef<HTMLInputElement>(null);
  const departmentRef = useRef<HTMLSelectElement>(null);
  const stationedFacilityRef = useRef<HTMLInputElement>(null);
  const homeFacilityRef = useRef<HTMLInputElement>(null);
  const emergencyContactFirstNameRef = useRef<HTMLInputElement>(null);
  const emergencyContactLastNameRef = useRef<HTMLInputElement>(null);
  const relationshipRef = useRef<HTMLInputElement>(null);
  const emergencyPhoneNumber = useRef<HTMLInputElement>(null);
  const emergencyEmailAddress = useRef<HTMLInputElement>(null);
  const passportNumberRef = useRef<HTMLInputElement>(null);
  const passportExpiryDateRef = useRef<HTMLInputElement>(null);
  const medicalConditionsRef = useRef<HTMLInputElement>(null);
  const medicationRef = useRef<HTMLInputElement>(null);
  const allergiesRef = useRef<HTMLInputElement>(null);
  const bloodTypeRef = useRef<HTMLInputElement>(null);
  const covidVaccinationStatusRef = useRef<HTMLInputElement>(null);
  const numberOfCovidVaccinationRef = useRef<HTMLInputElement>(null);
  const insurancerRef = useRef<HTMLInputElement>(null);
  const policyNumberRef = useRef<HTMLInputElement>(null);
  const policyExpiry = useRef<HTMLInputElement>(null);
  const accessRoleRef = useRef<HTMLSelectElement>(null);

  const handleEdit = (e: FormEvent, passports: UserPassport[], emergencyContacts: UserEmergencyContact[], insurancePolicies: UserInsurancePolicy[]) => {
    e.preventDefault();

    if (mainLanguageRef.current!.value === "select-main-language") {
      alert("Missing main language");
      return;
    }

    const formData: any = {
      clientID: userData.clientID,
      email: userData.email,
      roleID: accessRoleRef.current!.value,
    };
    const profile: any = {
      firstName: firstNameRef.current!.value,
      lastName: lastNameRef.current!.value,
      middleNames: middleNameRef.current!.value,
      countryOfResidence: countryOfResidenceRef.current!.value,
      gender: genderRef.current!.value,
      genderAssignedAtBirth:
        genderAssignedAtBirthRef.current!.value ===
        "select-gender-assigned-at-birth"
          ? null
          : genderAssignedAtBirthRef.current!.value,
      nationality: nationalityRef.current!.value,
    };

    if (dateOfBirthRef.current?.value !== "") {
      profile.dateOfBirth = convertDateToUTC(
        moment(dateOfBirthRef.current?.value).format()
      );
    }

    const phoneNumbers: {
      label?: string;
      number: string;
      countryISO?: string;
      carrier?: string;
      type?: string;
      primary: boolean;
      country?: string;
    }[] = [
      { number: primaryPhoneNumberRef.current!.value, primary: true },
      { number: secondaryPhoneNumberRef.current!.value, primary: false },
    ];
    const selectedMultipleLanguages = multiLanguageRef.current!.selectedOptions;
    const selectedMultipleLanguagesCodes = [];
    for (let i = 0; i < selectedMultipleLanguages.length; i++) {
      selectedMultipleLanguagesCodes.push({
        code: selectedMultipleLanguages[i].value,
        primary: false,
      });
    }
    const selectedMainLanguage = {
      code: mainLanguageRef.current!.value,
      primary: true,
    };
    const languages: { code: string; primary: boolean }[] = [
      ...selectedMultipleLanguagesCodes,
      selectedMainLanguage,
    ];

    const companyDetails = {
      employeeID: employeeIDRef.current!.value,
      jobTitle: jobTitleRef.current!.value,
      stationedFacility:
        stationedFacilityRef.current?.value === "select-facility"
          ? null
          : stationedFacilityRef.current?.value || null,
      homeFacility:
        homeFacilityRef.current?.value === "select-facility"
          ? null
          : homeFacilityRef.current?.value || null,
    };



    const medicalConditions = {
      conditions: medicalConditionsRef.current!.value,
      allergies: allergiesRef.current!.value,
      medications: medicationRef.current!.value,
      bloodGroup: bloodTypeRef.current!.value,
    };


    const departmentID = departmentRef.current?.value == "select-department" ? null : departmentRef.current?.value;
    formData.profile = profile;
    formData.phoneNumbers = phoneNumbers;
    formData.languages = languages;
    formData.companyDetails = companyDetails;
    formData.emergencyContacts = emergencyContacts;
    formData.medicalInformation = medicalConditions;
    // if (
    //   insurancePolicies.insurer !== "" &&
    //   insurancePolicies.policyNumber !== "" &&
    //   insurancePolicies.expiresAt !== "Invalid date"
    // ) {
    //   formData.insurancePolicies = [insurancePolicies];
    // }

  formData.insurancePolicies = insurancePolicies.map(p => ({
      ...p,
      expiresAt: moment(p.expiresAt, "YYYY-MM-DD").toISOString()
  }))



    formData.passports = passports

    if (departmentID) {
      formData.departmentID = departmentID;
    }

    formData.needsInviting = false;

    api
      .patch(`${userID ? `users/${userData.id}` : "profile"}`, formData)
      .then(() => {
        queryClient.invalidateQueries(
          userID ? "user-profile" : "personal-profile"
        );
      })
      .catch((err) => console.error(err));
  };
  return (
    <FormDataContext.Provider
      value={{
        handleEdit: handleEdit,
        primaryPhoneNumberRef: primaryPhoneNumberRef,
        secondaryPhoneNumberRef: secondaryPhoneNumberRef,
        passwordRef: passwordRef,
        firstNameRef: firstNameRef,
        middleNameRef: middleNameRef,
        lastNameRef: lastNameRef,
        dateOfBirthRef: dateOfBirthRef,
        genderAssignedAtBirthRef: genderAssignedAtBirthRef,
        genderRef: genderRef,
        nationalityRef: nationalityRef,
        countryOfResidenceRef: countryOfResidenceRef,
        mainLanguageRef: mainLanguageRef,
        multiLanguageRef: multiLanguageRef,
        employeeIDRef: employeeIDRef,
        jobTitleRef: jobTitleRef,
        departmentRef: departmentRef,
        stationedFacilityRef: stationedFacilityRef,
        homeFacilityRef: homeFacilityRef,
        emergencyContactFirstNameRef: emergencyContactFirstNameRef,
        emergencyContactLastNameRef: emergencyContactLastNameRef,
        relationshipRef: relationshipRef,
        emergencyPhoneNumber: emergencyPhoneNumber,
        emergencyEmailAddress: emergencyEmailAddress,
        passportNumberRef: passportNumberRef,
        passportExpiryDateRef: passportExpiryDateRef,
        medicalConditionsRef: medicalConditionsRef,
        medicationRef: medicationRef,
        allergiesRef: allergiesRef,
        bloodTypeRef: bloodTypeRef,
        covidVaccinationStatusRef: covidVaccinationStatusRef,
        numberOfCovidVaccinationRef: numberOfCovidVaccinationRef,
        insurancerRef: insurancerRef,
        policyNumberRef: policyNumberRef,
        policyExpiry: policyExpiry,
        profilePictureRef: profilePictureRef,
        accessRoleRef: accessRoleRef,
      }}
    >
      {children}
    </FormDataContext.Provider>
  );
}
