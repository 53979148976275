import React, { FormEvent, useEffect, useRef, useState } from "react";
import Checkbox from "../../../../components/form/Checkbox";
import { BlueButton } from "../../../../components/ui/Buttons";
import api from "../../../../api/axiosConfig";
import { useQueryClient } from "react-query";
import useEmailPreferences from "./hooks/useEmailPreferences";

type EmailPreference = {
  key: string;
  name: string;
  value: boolean;
};

function CustomEmailsRecipients() {
  const queryClient = useQueryClient();
  const { emailPreferences, isError, isLoading, error } = useEmailPreferences();
  const [alert, setAlert] = useState<boolean>(true);
  const [geofence, setGeofence] = useState<boolean>(true);
  const [missedCheckin, setMissedCheckin] = useState<boolean>(true);
  const [safetyzone, setSafetyzone] = useState<boolean>(true);
  const [opsCheckin, setOpsCheckin] = useState<boolean>(false);
  const [violation, setViolation] = useState<boolean>(false);

  const alertRef = useRef<HTMLInputElement>(null);
  const missedCheckinRef = useRef<HTMLInputElement>(null);
  const opsCheckinRef = useRef<HTMLInputElement>(null);
  const violationRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const alertValue =
      emailPreferences?.find(
        (pref: EmailPreference) => pref.key === "admin_alert_received"
      )?.value || false;
    const missedValue =
      emailPreferences?.find(
        (pref: EmailPreference) => pref.key === "admin_missed_checkin"
      )?.value || false;
    const opsCheckinValue =
      emailPreferences?.find(
        (pref: EmailPreference) => pref.key === "ops_checkin"
      )?.value || false;

    const violationValue =
      emailPreferences?.find(
        (pref: EmailPreference) => pref.key === "violation"
      )?.value || false;

    setAlert(alertValue);
    setMissedCheckin(missedValue);
    setOpsCheckin(opsCheckinValue);
    setViolation(violationValue)
  }, [isLoading]);

  const formRef = useRef<HTMLFormElement>(null);
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const newEmailPreferences = {
      emailPreferences: {
        admin_alert_received: alertRef.current!.checked,
        admin_missed_checkin: missedCheckinRef.current!.checked,
        ops_checkin: opsCheckinRef.current!.checked,
        violation: violationRef.current!.checked
      },
    };

    console.log(newEmailPreferences);

    api
      .put("/email-preferences", newEmailPreferences)
      .then(() => {
        queryClient.invalidateQueries("email-preferences");
      })
      .catch((err) => console.error(err));
  };
  return (
    <form
      className=" my-4 flex flex-col gap-4"
      ref={formRef}
      onSubmit={handleSubmit}
    >
      <Checkbox
        label="Emergency & Silent Alarms"
        name="custom-emails"
        value="admin_alert_received"
        checked={alert}
        onClick={() => setAlert((prev) => !prev)}
        inputRef={alertRef}
      />

      <Checkbox
        label="Missed Checkins"
        name="custom-emails"
        value="admin_missed_checkin"
        checked={missedCheckin}
        onClick={() => setMissedCheckin((prev) => !prev)}
        inputRef={missedCheckinRef}
      />

      <Checkbox
        label="Checkins"
        name="custom-emails"
        value="ops_checkin"
        checked={opsCheckin}
        onClick={() => setOpsCheckin((prev) => !prev)}
        inputRef={opsCheckinRef}
      />

    <Checkbox
        label="Zone Violation Notification"
        name="custom-emails"
        value="violation"
        checked={violation}
        onClick={() => setViolation((prev) => !prev)}
        inputRef={violationRef}
      />
      <div className="max-w-xs">
        <BlueButton text="Save" type="submit" />
      </div>
    </form>
  );
}

export default CustomEmailsRecipients;
