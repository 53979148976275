import { useSearchParams } from "react-router-dom";
import CustomOverlayMap from "../../components/Map";
import React, { useEffect } from "react";

function Main() {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultSearchParams = {
    siteTypes: ["facilities", "geofence", "safety-zone", "custom-overlay"],
  };

  useEffect(() => {
    setSearchParams(defaultSearchParams);
  }, []);

  return (
    <div className="h-full w-full">
      <CustomOverlayMap />
    </div>
  );
}

export default Main;
