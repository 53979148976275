import React from "react";
import AwardsGroupOne from "../../../assets/awards-grp-one.png";
import AwardsGroupTwo from "../../../assets/awards-grp-two.png";

export const CopyRights = () => {
  return (
    <div className="text-white w-full px-4">
      <div className="text-left flex-col flex">
        <span className="">&copy; 2024 Copyright</span>
        <span>Company No. 7145685</span>
      </div>
    </div>
  );
};

export const Address = () => {
  return (
    <div className="text-white flex flex-col justify-start pt-2 h-full">
      <span>NORTHCOTT GLOBAL SOLUTIONS LTD</span>
      <span>One Canada Square</span>
      <span>Canary Wharf</span>
      <span>London</span>
      <span>E14 5AA</span>
      <span>United Kingdom</span>
    </div>
  );
};

export const Contact = () => {
  return (
    <div className="text-white flex flex-col justify-start h-full pt-2">
      <div className="flex flex-col">
        <span className="uppercase">EMERGENCIES AND OPERATIONS</span>
        <span>ops@northcottglobalsolutions.com</span>
      </div>
      <span className="mt-2">Tel:+44 207 183 8910</span>
    </div>
  );
};

export const Awards = () => {
  return (
    <div className="flex w-52 justify-start items-center h-full">
      <img src={AwardsGroupTwo} alt="" />
    </div>
  );
};
