import React from "react";
import { Roles } from "../../../../types/userManagement";
import { snakeCaseToGeneralCase } from "../../../../util/helpers";

import Checkbox from "../../../../components/form/Checkbox";

import Save from "../../../../assets/icons/icon-save.svg";
import Bin from "../../../../assets/icons/icon-bin.svg";

export default function RoleEntry({
  data,
  onSave,
  onDelete,
  onChange,
}: {
  data: Roles;
  onSave: (id: string) => void;
  onDelete: (id: string) => void;
  onChange: (e: HTMLInputElement | HTMLTextAreaElement, id: string) => void;
}) {
  return (
    <div className="border-b-1 border-light-grey py-6 px-5 grid grid-cols-[minmax(165px,_1fr)_minmax(415px,_1fr)_minmax(220px,_1fr)_minmax(340px,_1fr)_minmax(175px,_1fr)_minmax(290px,_1fr)_minmax(142px,_1fr)] justify-items-center w-fit">
      <div className="border-r-1 border-r-light-grey w-full flex items-center font-semibold justify-center">
        <input
          type="text"
          name="name"
          value={snakeCaseToGeneralCase(data.name)}
          placeholder="Type role name here"
          onChange={(e) => onChange(e.target, data.id!)}
        />
      </div>
      <div className="border-r-1 border-r-light-grey w-full px-4 grid grid-cols-2 gap-x-6">
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="permissions.all"
            value="all_modules"
            label="All Modules"
            checked={data.permissions.all}
          />
        </div>
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="permissions.trips"
            label="Trips"
            value="trips"
            checked={data.permissions.trips}
          />
        </div>
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="permissions.operational_map"
            value="operational_map"
            label="Operational Map"
            checked={data.permissions.operational_map}
          />
        </div>
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="permissions.sites"
            value="sites"
            label="Sites"
            checked={data.permissions.sites}
          />
        </div>
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="permissions.world_search"
            value="world_search"
            label="World Search"
            checked={data.permissions.world_search}
          />
        </div>
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="permissions.risk"
            value="risk"
            label="Risk"
            checked={data.permissions.risk}
          />
        </div>
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="permissions.travel_tracking"
            value="travel_tracking"
            label="Travel Tracking"
            checked={data.permissions.travel_tracking}
          />
        </div>
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="permissions.reports"
            value="reports"
            label="Reports"
            checked={data.permissions.reports}
          />
        </div>
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="permissions.mass_warning_and_alerting"
            value="mass_warning_and_alerting"
            label="Mass Warning and Alerting"
            checked={data.permissions.mass_warning_and_alerting}
          />
        </div>
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="permissions.e_learning"
            value="e_learning"
            label="E Learning"
            checked={data.permissions.e_learning}
          />
        </div>
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="permissions.settings"
            value="settings"
            label="Settings"
            checked={data.permissions.settings}
          />
        </div>
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="auroraPermissions.user_management"
            value="user_management"
            label="User Management"
            checked={data.permissions.user_management}
          />
        </div>
      </div>
      <div className="border-r-1 border-r-light-grey w-full px-4">
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="auroraPermissions.all_permissions"
            value="all_permissions"
            label="All Permissions"
            checked={data.auroraPermissions.all}
          />
        </div>
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="auroraPermissions.client"
            value="client"
            label="Client"
            checked={data.auroraPermissions.clients}
          />
        </div>
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="auroraPermissions.departments"
            value="departments"
            label="Departments"
            checked={data.auroraPermissions.departments}
          />
        </div>
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="auroraPermissions.user"
            value="user"
            label="User"
            checked={data.auroraPermissions.users}
          />
        </div>
      </div>
      <div className="border-r-1 border-r-light-grey w-full px-4 grid grid-cols-2 gap-x-6 content-start">
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="appPermissions.all_permissions"
            value="all_permissions"
            label="All Permissions"
            checked={data.appPermissions.all}
          />
        </div>
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="appPermissions.check_ins"
            value="check_ins"
            label="Check Ins"
            checked={data.appPermissions.check_ins}
          />
        </div>
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="appPermissions.emergency"
            value="emergency"
            label="Emergency"
            checked={data.appPermissions.emergency}
          />
        </div>
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="appPermissions.country_risk"
            value="country_risk"
            label="Country Risk"
            checked={data.appPermissions.country_risk}
          />
        </div>
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="appPermissions.silent_alarm"
            value="silent_alarm"
            label="Silent Alarm"
            checked={data.appPermissions.silent_alarm}
          />
        </div>
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="appPermissions.active_tracking"
            value="active_tracking"
            label="Active Tracking"
            checked={data.appPermissions.active_tracking}
          />
        </div>
        <div className="mt-1">
          <Checkbox
            onClick={(e) => onChange(e, data.id!)}
            name="appPermissions.trips"
            value="trips"
            label="Trips"
            checked={data.appPermissions.trips}
          />
        </div>
      </div>
      <div className="border-r-1 border-r-light-grey w-full px-4">
        <div></div>
      </div>
      <div className="border-r-1 border-r-light-grey w-full px-4">
        <textarea
          name="description"
          onChange={(e) => onChange(e.target, data.id!)}
          className="w-full h-full"
          placeholder="Type description of the role here"
          value={data.description}
        />
      </div>
      <div className="w-full px-4 flex items-center justify-center">
        <div>
          <button className="mr-5" onClick={() => onSave(data.id!)}>
            <img className="w-8 h-8" src={Save} alt="settings icon" />
          </button>
          <button className="max-w-xs" onClick={() => onDelete(data.id!)}>
            <img className="w-8 h-8" src={Bin} alt="bin icon" />
          </button>
        </div>
      </div>
    </div>
  );
}
