import React from "react";
import WhiteLogo from "../WhiteLogo";
import { Address, Contact, CopyRights, Awards } from "./Components";

function Footer() {
  return (
    <footer className="bg-gradient-to-r bg-white from-[#2D166F_50%] to-[#007584] grid grid-cols-[auto_auto_auto_1fr] gap-4 p-4 text-sm h-400">
      <div className="flex flex-col items-center justify-center px-3">
        <div className="">
          <WhiteLogo />
        </div>
        <CopyRights />
      </div>
      <div className="">
        <Address />
      </div>
      <div className="">
        <Contact />
      </div>
    </footer>
  );
}

export default Footer;
