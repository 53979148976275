import {
  BlueButton,
  ButtonDownload,
} from "../../../../../components/ui/Buttons";
import DropDown from "../../../../../components/form/DropDown";
import Suitcase from "../../../../../assets/icons/suitcase-grey.svg";
import InputText from "../../../../../components/form/InputText";
import IconSearch from "../../../../../assets/icons/icon-search.svg";
import Checkbox from "../../../../../components/form/Checkbox";
import React, { useContext, useState } from "react";
import { ReportsContext } from "../../../ReportsDataProvider";
import DateIcon from "../../../../../assets/icons/date.svg";
import IconMessage from "../../../../../assets/icons/icon-message.svg";
import ReactDatePicker from "react-datepicker";

type HistorySearch = {
  clients: "";
  users: "";
};

export default function Search() {
  const { clients, users } = useContext(ReportsContext);

  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [settingsSearch, setSettingsSearch] = useState<HistorySearch>({
    clients: "",
    users: "",
  });

  const handleSettingsSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSettingsSearch((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClientSelect = (id: string) => {
    if (selectedClients.includes(id)) {
      setSelectedClients(selectedClients.filter((client) => client !== id));
    } else {
      setSelectedClients([...selectedClients, id]);
    }
  };

  return (
    <div className="flex w-full items-center">
      <div className="grid grid-cols-3 gap-2 w-full">
        <DropDown title="Clients" dropDownIcon={Suitcase}>
          <div>
            <div className="mb-2">
              <InputText
                placeholder="Search"
                name="clients"
                value={settingsSearch.clients}
                onChange={handleSettingsSearchChange}
              />
              <button className="absolute top-0 right-3 translate-y-1/2">
                <img src={IconSearch} alt="" />
              </button>
            </div>
            <div className="overflow-hidden h-[185px]">
              <div className="h-full overflow-y-scroll scrollbar-hide px-2">
                {clients &&
                  clients.map((client) => {
                    if (
                      client.name
                        .toLowerCase()
                        .includes(settingsSearch.clients.toLowerCase())
                    ) {
                      return (
                        <div className="mt-2">
                          <Checkbox
                            key={client.id}
                            name="client"
                            checked={selectedClients.includes(
                              client.id ? client.id : ""
                            )}
                            value={client.id ? client.id : ""}
                            label={client.name}
                            onClick={() => {
                              handleClientSelect(client.id ? client.id : "");
                            }}
                          />
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        </DropDown>
        <DropDown title="Dates" dropDownIcon={DateIcon} transparentBox={true}>
          <ReactDatePicker
            inline
            isClearable
            selected={new Date()}
            onChange={() => {}}
            startDate={new Date()}
            endDate={new Date()}
            selectsRange
          />
        </DropDown>
      </div>
      <div className="ml-2 flex">
        <div className="mr-2 w-[130px]">
          <BlueButton text="Search" onClick={() => {}} />
        </div>
        <ButtonDownload blue={true} onClick={() => {}} />
      </div>
    </div>
  );
}
