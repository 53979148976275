import React from "react";
import { SecurityAssessmentsMain } from "../../../features/risk/pages/security-assessments";

function SecurityAssessments() {
  return (
    <div>
      <SecurityAssessmentsMain />
    </div>
  );
}

export default SecurityAssessments;
