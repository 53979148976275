import React, { useContext } from "react";
import {
  AdviceGuidesMain,
  AdviceGuidesSidebar,
} from "../../../features/risk/pages/advice-guides";
import { AdviceGuidesContext } from "../../../features/risk/pages/advice-guides/context/advice-guides";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
function AdviceGuides() {
  const adviceGuidesContext = useContext(AdviceGuidesContext)!;
  const { all_guides_status } = adviceGuidesContext;

  return (
    <div className="flex h-full gap-6">
      {all_guides_status !== "success" ? (
        <div className="h-full w-full flex justify-center items-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <AdviceGuidesMain />
          <AdviceGuidesSidebar />
        </>
      )}
    </div>
  );
}

export default AdviceGuides;
