import React, { useContext } from "react";
import {
  Main,
  ProfileSidebar,
} from "../../features/user-management/pages/user-profile";
import { UserProfileContext } from "../../features/user-management/pages/user-profile/context/user-profile";
import FormDataContextProvider from "../../features/user-management/pages/user-profile/context/form";
function UserProfile() {
  const userProfile = useContext(UserProfileContext)!;

  return userProfile.status !== "success" || userProfile.isFetching ? null : (
    <div className="flex h-full pt-36 pb-16 px-7">
      <FormDataContextProvider>
        <ProfileSidebar />
        <Main />
      </FormDataContextProvider>
    </div>
  );
}

export default UserProfile;
