import React, { ChangeEvent, Ref, useEffect, useState } from "react";

type Input = {
  placeholder: string;
  onChange?: (value: string) => void;
  name: string;
  inputRef?: Ref<HTMLInputElement>;
  required: boolean;
  error: boolean;
  defaultValue?: string;
};

function TextInput({
  placeholder,
  name,
  inputRef,
  required,
  onChange,
  error,
  defaultValue,
}: Input) {
  const [inputValue, setInputValue] = useState(defaultValue);
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <div className="relative">
      <div className="relative">
        {required && (
          <span className="absolute -left-3 top-2/4 -translate-y-2/4 text-red">
            *
          </span>
        )}
        <input
          type="text"
          name={name}
          value={inputValue}
          onChange={handleInputChange}
          className="h-10 w-full rounded-md shadow-md px-4 outline-none"
          placeholder={placeholder}
          ref={inputRef}
        />
      </div>
      {/* Validation Error Message */}
      {error && inputValue === "" ? (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
          <p className="ml-1 text-red">Required</p>
        </div>
      ) : null}
    </div>
  );
}

export default TextInput;
