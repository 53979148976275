import React, { useContext, useRef, useState } from "react";
import { BlueButton } from "../../../../../../components/ui/Buttons";
import EditIcon from "../../../../../../assets/icons/icon-edit-white.svg";
import api from "../../../../../../api/axiosConfig";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../../../../../components/portal/Modal";
import DoneIcon from "@mui/icons-material/Done";
import Error from "@mui/icons-material/Error";
import ClearIcon from "@mui/icons-material/Clear";
// @ts-ignore
import sampleUsersCSV from "../../../../../../assets/Import_Users.csv";
import { emailTemplate } from "../../constants/email-template";
import Checkbox from "../../../../../../components/form/Checkbox";
import { UserContext } from "../../../../../../context/user";
import useAccessibleRolesForUser from "../../../../hooks/useAccessibleRolesForUser";
import { RoleTypes } from "../../../../../../types/roles-types";
import AddUserForm from "../../../../components/add-user";
import TextTemplate from "../../../../../../components/ui/Content/TextTemplate";
import {
  ImportedUsersData,
  ImportReport,
} from "../../../../../../types/user-data-types";
import { AxiosResponse } from "axios";

type SkippedUsers = {
  email: string;
  error: string;
};

function AddUsers() {
  const { clientID } = useParams();
  const userContext = useContext(UserContext);

  const [invitationEmailSelected, setInvitationEmailSelected] =
    useState<boolean>(false);
  const [emailTemplateModal, setEmailTemplateModal] = useState<boolean>(false);
  const [numberOfUsersAdded, setNumberOfUsersAdded] = useState<number>(0);
  const [createSingleUserModal, setCreateSingleUserModal] =
    useState<boolean>(false);
  const [successfulImportModal, setSuccessfulImportModal] =
    useState<boolean>(false);
  const [skippedImportedUsers, setSkippedImportedUsers] = useState<
    SkippedUsers[]
  >([]);

  const [emailTemplateText, setEmailTemplateText] =
    useState<string>(emailTemplate);
  const navigate = useNavigate();
  const usersCSVInputRef = useRef<HTMLInputElement>(null);

  const handleUploadUsersFile = () => {
    const file = usersCSVInputRef.current!.files![0];
    const formData = new FormData();
    formData.append("file", file);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    if (invitationEmailSelected) {
      formData.append("needsInviting", "true");
      formData.append("emailBody", emailTemplateText as string);

      api
        .post(`/users-bulk-create?clientID=${clientID}`, formData, config)
        .then((res: ImportedUsersData | any) => {
          setNumberOfUsersAdded(res.data.successCount);
          res.data.importReport?.forEach((report: ImportReport) => {
            if (report.Error) {
              setSkippedImportedUsers((prev) => [
                ...prev,
                {
                  email: report.Email,
                  error: report.Error,
                },
              ]);
            }
          });
          setEmailTemplateModal(false);
          setSuccessfulImportModal(true);
        })
        .catch((err) => console.error(err));
    } else {
      formData.append("needsInviting", "false");
      formData.append("emailBody", "");

      api
        .post(`/users-bulk-create?clientID=${clientID}`, formData, config)
        .then((res: ImportedUsersData | any) => {
          setNumberOfUsersAdded(res.data.successCount);
          res.data.importReport?.forEach((report: ImportReport) => {
            if (report.Error) {
              setSkippedImportedUsers((prev) => [
                ...prev,
                {
                  email: report.Email,
                  error: report.Error,
                },
              ]);
            }
          });
          setEmailTemplateModal(false);
          setSuccessfulImportModal(true);
        })
        .catch((err) => console.error(err));
    }
  };

  const handleSaveEditor = (text: string) => {
    setEmailTemplateText(text);
  };

  const dateOfBirthRef = useRef<HTMLInputElement>(null);
  const downloadImportUsersSampleRef = useRef<HTMLAnchorElement>(null);

  const accessibleRolesForUser = useAccessibleRolesForUser(
    userContext?.role.name!
  );
  return (
    <>
      <form>
        <div className="grid grid-cols-3 gap-20 max-w-7xl mx-auto">
          <input
            type="file"
            className="hidden"
            ref={usersCSVInputRef}
            onChange={() => setEmailTemplateModal(true)}
          />
          <a
            href={sampleUsersCSV}
            download={true}
            className="hidden"
            ref={downloadImportUsersSampleRef}
          ></a>
          <div className="flex flex-col w-full gap-8">
            <BlueButton
              text="Import Users"
              onClick={() => usersCSVInputRef.current?.click()}
            />
            <BlueButton
              text="Download Sample Import Users CSV"
              onClick={() => downloadImportUsersSampleRef.current?.click()}
            />
          </div>

          {/* {importState ? <p>{importState}</p> : <p>No file chosen.</p>} */}
          <BlueButton
            text="Create Single User"
            onClick={() => setCreateSingleUserModal(true)}
          />
        </div>

        <div className="max-w-md mx-auto my-24 flex flex-col gap-8">
          <BlueButton
            text="Save"
            onClick={() => navigate("/user-management")}
          />
          <div className="flex gap-4 justify-center">
            <div className="h-2 w-2 rounded-full bg-light-light-grey"></div>
            <div className="h-2 w-2 rounded-full bg-light-light-grey"></div>
            <div className="h-2 w-2 rounded-full bg-light-grey"></div>
          </div>
        </div>
      </form>

      <Modal open={emailTemplateModal} setOpen={setEmailTemplateModal}>
        <div className="bg-white p-8 rounded-md flex flex-col gap-8 w-fit">
          <h4 className="text-lg font-semibold mx-auto">Invitation Email</h4>
          <p>
            This will be sent to all users when they are first added to the
            Aurora platform. Note that the below is the default invitation
            email. Please click the text box to edit.
          </p>
          <div className="flex gap-4">
            <Checkbox
              name={"invitation-email"}
              checked={invitationEmailSelected}
              value={"invitation-email"}
              label={"Add invitation email"}
              onClick={() => setInvitationEmailSelected((prev) => !prev)}
            />
          </div>

          {invitationEmailSelected && (
            <TextTemplate
              initialContent={emailTemplateText}
              onSave={handleSaveEditor}
              height="300px"
            />
          )}

          <div className="w-1/4 mx-auto">
            <BlueButton text="Confirm" onClick={handleUploadUsersFile} />
          </div>
        </div>
      </Modal>

      <Modal open={successfulImportModal} setOpen={setSuccessfulImportModal}>
        <div className="bg-white p-8 rounded-md flex flex-col gap-8 relative max-w-[600px] w-full min-w-[350px]">
          <button
            className="absolute right-4 top-4"
            onClick={() => setSuccessfulImportModal(false)}
          >
            <ClearIcon />
          </button>
          {numberOfUsersAdded >= 1 && (
            <div className="h-12 w-12 rounded-full bg-green mx-auto flex items-center justify-center">
              <DoneIcon className="text-white" />
            </div>
          )}
          {numberOfUsersAdded === 0 && (
            <div className="h-12 w-12 rounded-full bg-red mx-auto flex items-center justify-center">
              <Error className="text-white" />
            </div>
          )}
          {numberOfUsersAdded >= 1 && (
            <h3 className="text-xl font-semibold text-center">{`Successfully created ${numberOfUsersAdded} ${
              numberOfUsersAdded === 1 ? "user" : "users"
            }`}</h3>
          )}
          {skippedImportedUsers.length > 0 && (
            <div>
              <div className="text-dark-red">The below users were skipped:</div>
              <ol className="max-h-[200px] overflow-y-scroll list-decimal pl-5">
                {skippedImportedUsers.map((user) => (
                  <li className="mt-1">
                    <div className="font-semibold">{user.email}</div>
                    <div>{user.error}</div>
                  </li>
                ))}
              </ol>
            </div>
          )}
        </div>
      </Modal>

      <Modal open={createSingleUserModal} setOpen={setCreateSingleUserModal}>
        <AddUserForm
          onCreated={() => {
            setCreateSingleUserModal(false);
          }}
        />
      </Modal>
    </>
  );
}

export default AddUsers;
