import axios, {InternalAxiosRequestConfig} from "axios";

const interceptor = (config: InternalAxiosRequestConfig) => {
  config.headers.set("Aurora-Time-Zone", localStorage.getItem("auroraTimeZone") || "UTC");
  return config;
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(interceptor)

export const apiV2 = axios.create({
  baseURL: process.env.REACT_APP_API_V2_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

apiV2.interceptors.request.use(interceptor)

export default api;
