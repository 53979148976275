import React, { useContext } from "react";
import Main from "../../../features/user-management/pages/user-management-home";
import { ClientsContext } from "../../../context/clients";
import LoadingPage from "./LoadingPage";
import { PaginatedClientsContext } from "../../../features/user-management/context/clients";
import { UserContext } from "../../../context/user";
import { Link } from "react-router-dom";
function Home() {
  const clientsContext = useContext(PaginatedClientsContext);
  const userLoginContext = useContext(UserContext);

  return clientsContext?.allClients.status !== "success" ? (
    <LoadingPage all_clients_status={clientsContext!.allClients.status} />
  ) : userLoginContext!.role.name === "NGS Super Admin" ? (
    <div className="flex h-full pt-36 pb-16 px-7">
      <Main />
    </div>
  ) : (
    <div className="absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 text-lg font-semibold flex flex-col gap-8">
      This page is restricted to NGS Super Admins only.
      <Link
        to={`/user-management/client-users/${
          userLoginContext!.metadata.clientID
        }`}
        className="bg-blue px-12 py-4 w-fit mx-auto text-md font-normal text-white"
      >
        View Users
      </Link>
    </div>
  );
}

export default Home;
