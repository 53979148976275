import Checkbox from "../../../../components/form/Checkbox";
import React from "react";

export default function RolesListHeader() {
  const headerTitles = [
    "Name",
    "Aurora Module Access",
    "Aurora Permissions",
    "App Permissions",
    "Client Name",
    "Notes",
    "Actions",
  ];

  return (
    <div className="flex py-3 px-5 rounded-2xl bg-desaturated-grey mt-5 w-fit">
      <div className="grid grid-cols-[minmax(165px,_1fr)_minmax(415px,_1fr)_minmax(220px,_1fr)_minmax(340px,_1fr)_minmax(175px,_1fr)_minmax(290px,_1fr)_minmax(142px,_1fr)] justify-items-center w-full">
        {headerTitles.map((title, index) => (
          <div
            className="text-center font-semibold w-full border-r-1 border-r-light-grey last:border-none"
            key={index}
          >
            {title}
          </div>
        ))}
      </div>
    </div>
  );
}
