import React, { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import CountryProfileSidebar from "./layout/sidebar";
import Header from "./layout/header";
import { CountryProfileContext } from "../../context/country-profile";
import "./index.css";
function CountryProfileSection() {
  const [isMap, setIsMap] = useState<boolean>(false);
  const countryProfileContext = useContext(CountryProfileContext);
  console.log(countryProfileContext);
  return countryProfileContext?.status !== "success" ? (
    <div className="">Loading...</div>
  ) : (
    <div className="flex gap-6 h-full">
      <main className="w-full h-full bg-white flex flex-col gap-4 pb-8 relative country-profile-main">
        <Header />
        <div className="w-full h-[60vh] relative overflow-y-auto px-8">
          <Outlet />
        </div>
      </main>
      <CountryProfileSidebar isMap={isMap} setIsMap={setIsMap} />
    </div>
  );
}

export default CountryProfileSection;
