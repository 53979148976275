import React from "react";
import IndividualTrip from "../../../features/trips/pages/individualTrip";
import TripsContextProvider from "../../../features/trips/context/trips";

export default function IndividualTripPage() {
  return (
    <TripsContextProvider>
      <IndividualTrip />
    </TripsContextProvider>
  );
}
