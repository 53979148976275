import React, { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Roles } from "../../../types/userManagement";

import { PlusBlueButton } from "../../../components/ui/Buttons";
import { ContentWrapperWithHeader } from "../../../components/ui/Content";
import RolesListHeader from "./components/RolesListHeader";
import RoleEntry from "./components/RoleEntry";
import {
  addRole,
  deleteRole,
  getRoles,
  updateRole,
} from "../../../api/userManagementAPIs";

export default function RolesList() {
  const queryClient = useQueryClient();
  const rolesData = useQuery<Roles[]>("roles", getRoles);
  const mutationAddRole = useMutation(addRole, {
    onSuccess: () => {
      queryClient.invalidateQueries("roles");
    },
  });
  const mutationUpdateRole = useMutation(
    (data: { id: string; role: Roles }) => updateRole(data.id, data.role),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("roles");
      },
    }
  );
  const mutationDeleteRole = useMutation(deleteRole, {
    onSuccess: () => {
      queryClient.invalidateQueries("roles");
    },
  });

  const [roles, setRoles] = React.useState<Roles[]>([]);
  const [newRole, setNewRole] = React.useState<Roles>({
    appPermissions: {
      all: false,
      emergency: false,
      silent_alarm: false,
      active_tracking: false,
      trips: false,
      check_ins: false,
      country_risk: false,
    },
    permissions: {
      all: false,
      operational_map: false,
      world_search: false,
      travel_tracking: false,
      settings: false,
      trips: false,
      risk: false,
      reports: false,
      user_management: false,
      mass_warning_and_alerting: false,
      sites: false,
      e_learning: false,
    },
    auroraPermissions: {
      clients: false,
      departments: false,
      users: false,
      all: false,
    },
    description: "",
    name: "",
    clientID: "",
    clientName: "",
  });

  useEffect(() => {
    if (rolesData.isSuccess && rolesData.data.length > 0) {
      setRoles(rolesData.data);
    }
  }, [rolesData.data]);

  const handleFieldChange = (
    e: HTMLInputElement | HTMLTextAreaElement,
    id: string
  ) => {
    const index = roles.findIndex((role) => role.id === id);
    if (e.type === "checkbox") {
      const group = e.name.split(".")[0];
      const name = e.name.split(".")[1];

      setRoles((prevState) => {
        return [
          ...prevState.slice(0, index),
          {
            ...prevState[index],
            [group]: {
              // @ts-ignore
              ...prevState[index][group],
              // @ts-ignore
              [name]: e.target.checked,
            },
          },
          ...prevState.slice(index + 1),
        ];
      });
    } else {
      setRoles((prevState) => {
        return [
          ...prevState.slice(0, index),
          {
            ...prevState[index],
            [e.name]: e.value,
          },
          ...prevState.slice(index + 1),
        ];
      });
    }
  };

  const handleRoleSave = async (id: string) => {
    const role = roles.find((role) => role.id === id);
    if (role) {
      mutationUpdateRole.mutate({ id, role });
    }
  };

  const handleDeleteRole = (id: string) => {
    mutationDeleteRole.mutate(id);
  };

  const toggleAddNewRole = () => {
    mutationAddRole.mutate(newRole);
  };

  return (
    <div className="flex h-screen pt-36 pb-16 px-7">
      <ContentWrapperWithHeader
        title={<div>ROLES</div>}
        button={<PlusBlueButton onClick={toggleAddNewRole} />}
        content={
          <div className="w-full overflow-scroll">
            <div className="w-full ">
              <RolesListHeader />
              <div className="h-[calc(100vh_-_400px)]">
                {rolesData.isLoading ||
                mutationAddRole.isLoading ||
                mutationUpdateRole.isLoading ||
                mutationDeleteRole.isLoading ? (
                  <div className="w-full h-full flex justify-center items-center">
                    Loading...
                  </div>
                ) : (
                  roles.length > 0 &&
                  roles.map((role) => (
                    <RoleEntry
                      key={role.id}
                      data={role}
                      onChange={(e, id) => handleFieldChange(e, id)}
                      onSave={(id) => handleRoleSave(id)}
                      onDelete={(id) => handleDeleteRole(id)}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}
