import React from "react";
import Sidebar from "../../../../../../components/ui/Sidebar";

const keys = [
  {
    title: "Negligible",
    color: "#68AF6D",
    description: "Good safety/ regulatory record",
  },
  {
    title: "Low",
    color: "F9E10A",
    description: "Above average safety/ regulatory record",
  },
  {
    title: "Medium",
    color: "#E6920A",
    description: "Average safety/ regulatory record",
  },
  {
    title: "High",
    color: "#E5680C",
    description: "Below average/ poor safety/ regulatory record",
  },
];

function AirlineRiskMatrixSidebar() {
  return (
    <div className="max-w-xs text-sm">
      <Sidebar title="Key">
        {keys.map((key, i) => {
          return (
            <div className="" key={i}>
              <h4
                className={"uppercase text-sm font-semibold"}
                style={{ color: key.color }}
              >
                {key.title}
              </h4>
              <p>{key.description}</p>
            </div>
          );
        })}
        <p>
          These scores are calculated using a number of factors, including but
          not limited to, number of flights per year, number and type accidents
          and incidents, fleet statistics, financial factors and country
          regulation information.
        </p>
      </Sidebar>
    </div>
  );
}

export default AirlineRiskMatrixSidebar;
