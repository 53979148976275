/**
 * @description restrict app pages based on the user aurora modules
 * @event if the current page doesnt exist in allowed pages then redirect the user to the first allowed page
 * @ignore if the current page is profile or settings
 */
import {useContext, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {UserContext} from "../context/user";

export default function useRoleBasedAppRestriction() {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const allowedPages = userContext?.role.auroraModules;
  const formatAllowedPages = allowedPages?.map((page) => {
    return page.replaceAll("_", "-");
  });
  const currentPage = useLocation().pathname.split("/")[1];

  const redirectToDefault = () => {
    navigate(`/${formatAllowedPages![0]}`);
  };

  return useEffect(() => {
    // if the current page is not user profile or is not included in allowed pages for the user, then redirect the user to the first allowed page for the the user.
    if (
        formatAllowedPages?.includes(currentPage) ||
        formatAllowedPages?.includes("all") ||
        currentPage === "profile" ||
        currentPage === "settings" ||
        currentPage === "user-profile" ||
        currentPage == "world-search"
    ) {
      return;
    } else {
      redirectToDefault();
    }
  }, [currentPage]);
}
