import React, { useEffect, useRef, useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import LoadingSpinner from "../../ui/LoadingSpinner";
type Options = {
  label: string;
  value: string;
};
type SelectType = {
  options: Options[];
  name: string;
  title: string;
  icon?: string;
  onSelect?: (value: string) => void;
  defaultSelected: Options | null;
  loading?: boolean;
  errorMessage?: string;
  disabled?: boolean;
};

function Select({
  options,
  name,
  title,
  icon,
  onSelect,
  defaultSelected = null,
  loading,
  errorMessage,
  disabled = false,
}: SelectType) {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = useState<Options[]>(options);
  const [search, setSearch] = useState<string>("");
  const [selected, setSelected] = useState<Options | null>(defaultSelected);
  const selectDivRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (search === "") {
      setFilteredOptions(options);
      return;
    }
    const newOptions = options.filter((option) =>
      option.label.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredOptions(newOptions);
    return;
  }, [search, options]);

  const toggleDropdown = () => setDropdownOpen((prev) => !prev);

  useOnClickOutside(selectDivRef, () => setDropdownOpen(false));

  useEffect(() => {
    searchInputRef.current?.focus();
  }, [dropdownOpen]);

  useEffect(() => {
    if (!defaultSelected) return;
    setSelected(defaultSelected);
  }, [defaultSelected]);
  return (
    <div className="h-fit w-full relative" ref={selectDivRef}>
      {selected ? (
        <select
          name={name}
          id=""
          className="absolute -z-10 opacity-0 pointer-events-none"
          value={selected?.value}
          onChange={() => {}}
          disabled={disabled}
        >
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : null}

      <button
        className={`h-10 w-full shadow-md rounded-md flex items-center justify-between px-4 ${
          disabled ? "cursor-not-allowed" : "cursor-pointer"
        }`}
        type="button"
        onClick={toggleDropdown}
        disabled={disabled}
      >
        <div className="flex gap-4">
          {icon ? <img src={icon} alt="" /> : null}
          <span
            className="w-full overflow-hidden whitespace-nowrap"
            style={{ textOverflow: "ellipsis" }}
          >
            {selected ? selected.label : title}
          </span>
        </div>
        {dropdownOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </button>

      <div
        className={`bg-white absolute top-12 left-0 right-0 z-10 rounded-md shadow-md ${
          dropdownOpen ? "inline" : "hidden"
        }`}
      >
        <div className="w-full my-4 flex flex-col">
          <input
            type="text"
            className="h-8 w-4/5 rounded-sm shadow-sm mx-auto px-4"
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            ref={searchInputRef}
          />
          {loading ? (
            <div className="w-8 h-8 mx-auto">
              <LoadingSpinner />
            </div>
          ) : null}
        </div>
        <ul className="max-h-32 h-full overflow-y-auto my-4 px-4">
          {errorMessage ? errorMessage : null}
          {filteredOptions?.map((option) => {
            return (
              <li
                key={option.value}
                onClick={() => {
                  setSelected(option);
                  setDropdownOpen(false);
                  if (onSelect) {
                    onSelect(option.value);
                  }
                }}
                className={`cursor-pointer hover:bg-dark-blue hover:bg-opacity-50 p-1 hover:text-white ${
                  defaultSelected?.value === option.value
                    ? "bg-dark-blue bg-opacity-80 text-white"
                    : "bg-white"
                }`}
              >
                {option.label}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default Select;
