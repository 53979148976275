import React, {Dispatch, FormEvent, SetStateAction, useEffect, useRef, useState,} from "react";
import {BlueButton} from "../../../../../../components/ui/Buttons";
import CustomisationSection from "../../components/customisation/section";
import Checkbox from "../../../../../../components/form/Checkbox";
import {useNavigate, useParams} from "react-router-dom";
import useGetClient from "../../../../../../api/clients/useGetClient";
import {ClientDataTypes} from "../../../../../../types/client-data-types";
import api from "../../../../../../api/axiosConfig";

function ViewClient({
                      setFormStage,
                    }: {
  setFormStage?: Dispatch<SetStateAction<"client" | "departments" | "users">>;
}) {
  const {clientID} = useParams();
  const client = useGetClient(clientID!);
  const clientData: ClientDataTypes = client.data?.data!;
  const [NGSAlerts, setNGSAlerts] = useState<boolean>(false);
  const [AirlineRisk, setAirlineRisk] = useState<boolean>(false);

  const navigate = useNavigate();

  const clientNameRef = useRef<HTMLInputElement>(null);
  const emergencyPhoneNumberRef = useRef<HTMLInputElement>(null);
  const emergencyEmailAddressRef = useRef<HTMLInputElement>(null);
  const insuranceNumberRef = useRef<HTMLInputElement>(null);
  const insuranceLinkRef = useRef<HTMLInputElement>(null);
  const sendAlertsToNGSRef = useRef<HTMLInputElement>(null);
  const airlineMatrixRef = useRef<HTMLInputElement>(null);
  const invitationEmailRef = useRef<HTMLTextAreaElement>(null);
  const [incidentVicinityAlerts, setIncidentVicinityAlerts] = useState<boolean>(false);

  useEffect(() => {
    setNGSAlerts(clientData?.sendAlertsToNGS);
    setAirlineRisk(clientData?.modules?.includes("airlineRiskMatrix"));
    setIncidentVicinityAlerts(clientData?.modules?.includes("incident_vicinity_alerts"))
  }, [clientData]);

  const handleEditClient = (e: FormEvent) => {
    e.preventDefault();
    if (!clientNameRef.current!.value) {
      console.error("client name required");
    }

    const checkClientNameChange = () => {
      if (clientNameRef.current?.value !== clientData.name) return true;
      return false;
    };

    const checkEmergencyAlertPhoneNumberChange = () => {
      if (
          emergencyPhoneNumberRef.current?.value !==
          clientData.clientAlarmConfig.emergencyPhoneNumber
      )
        return true;
      return false;
    };

    const checkEmergencyAlertEmailAddressChange = () => {
      if (
          emergencyEmailAddressRef.current?.value !==
          clientData.alertEmailAddresses
      )
        return true;
      return false;
    };

    console.log(sendAlertsToNGSRef.current!.checked);

    const formData = {
      name: checkClientNameChange()
          ? clientNameRef.current!.value
          : clientData.name,
      type: "direct",
      alertEmailAddresses: checkEmergencyAlertEmailAddressChange()
          ? emergencyEmailAddressRef.current!.value
          : clientData.alertEmailAddresses,
      sendAlertsToNGS: sendAlertsToNGSRef.current!.checked,
      airlineRiskMatrix: AirlineRisk,
      incidentVicinityAlerts: incidentVicinityAlerts,
      clientAlarmConfig: {
        emergencyPhoneNumber: checkEmergencyAlertPhoneNumberChange()
            ? emergencyPhoneNumberRef.current!.value
            : clientData.clientAlarmConfig.emergencyPhoneNumber,
      },
      insuranceNumber: insuranceNumberRef.current!.value,
      insuranceLink: insuranceLinkRef.current!.value,
    };

    api
        .put(`clients/${clientID}`, formData)
        .then((res) => {
          console.log("updated the following client data to database", res.data);
          navigate(`/user-management/client/${clientID}/edit-departments`);
        })
        .catch((err) => {
          console.error(err);
        });
  };

  if (client.status !== "success" || client.isFetching) return null;
  return (
      <form onSubmit={(e: FormEvent) => handleEditClient(e)}>
        <input
            type="text"
            defaultValue={clientData.name}
            className="h-10 max-w-lg w-full shadow-md rounded-md px-4 my-8"
            required
            ref={clientNameRef}
        />
        <div className="">
          <div className="w-full flex h-20 relative items-center justify-center">
            <div className="bg-white px-10 relative z-10">
              <p className="">Customisation</p>
            </div>
            <hr className="absolute w-full top-2/4 -translate-y-2/4"/>
          </div>
          <div className="flex flex-col gap-20">
            <CustomisationSection
                title="Custom App Plus Phone Numbers"
                description={`These are triggered when alerts on the App Plus are activated If
              left blank, the emergency alerts will come through to our
              operations team on ${(
                    <br/>
                )} +44 207 183 8910. If you would like to
              have a custom hotline number, please contact our operations team.`}
            >
              <div className="flex flex-col gap-8 justify-end">
                <div className="flex flex-col">
                  <label htmlFor="client_level" className="opacity-80">
                    Client Level
                  </label>
                  <input
                      defaultValue={
                        clientData.clientAlarmConfig?.emergencyPhoneNumber
                            ? clientData.clientAlarmConfig?.emergencyPhoneNumber
                            : ""
                      }
                      type="text"
                      placeholder="Emergency Alert Phone Number"
                      name={"client_level"}
                      className="h-10 max-w-lg w-full shadow-md rounded-md px-4 my-4"
                      required
                      ref={emergencyPhoneNumberRef}
                  />
                </div>
              </div>
            </CustomisationSection>

            <CustomisationSection
                title="Custom Alert Email Address"
                description="These are sent as a failsafe if the Aurora platform fails for any reason.
If left blank, the alerts are sent to t2ops@northcottglobalsolutions.com"
            >
              <div className="flex flex-col gap-4">
                <div className="flex flex-col">
                  <label htmlFor="client_level" className="opacity-80">
                    Emergency
                  </label>
                  <input
                      type="text"
                      placeholder="Emergency Alert Email Address"
                      name={"emergency-email-address"}
                      className="h-10 max-w-lg w-full shadow-md rounded-md px-4 my-4"
                      required
                      defaultValue={clientData.alertEmailAddresses}
                      ref={emergencyEmailAddressRef}
                  />
                </div>

                <Checkbox
                    name="ngs-alerts"
                    checked={NGSAlerts}
                    value={"ngs-alerts"}
                    label={"Send alerts to NGS"}
                    onClick={() => setNGSAlerts((prev) => !prev)}
                    inputRef={sendAlertsToNGSRef}
                />
              </div>
            </CustomisationSection>

            <CustomisationSection
                title="Airline Risk Matrix"
                description=""
            >
              <div className="flex flex-col gap-4">
                <Checkbox
                    name="airline-matrix"
                    checked={AirlineRisk}
                    value={"airline-matrix"}
                    label={"Can view Airline Risk Matrix"}
                    onClick={() => setAirlineRisk((prev) => !prev)}
                    inputRef={sendAlertsToNGSRef}
                />
              </div>
            </CustomisationSection>

            <CustomisationSection
                title="Incident Vicinity Alerts"
                description=""
            >
              <div className="flex flex-col gap-4">
                <Checkbox
                    name="airline-matrix"
                    checked={incidentVicinityAlerts}
                    value={"incident-vicinity-alerts"}
                    label={"Enable incident vicinity alerts"}
                    onClick={() => setIncidentVicinityAlerts((prev) => !prev)}
                />
              </div>
            </CustomisationSection>

            <CustomisationSection
                title="Insurance Information"
                description=""
            >
              <div className="flex flex-col gap-4">
                <div className="flex flex-col">
                  <label htmlFor="client_level" className="opacity-80">
                    Phone Number
                  </label>
                  <input
                      type="text"
                      placeholder="Phone Number in case of claim"
                      name={"emergency-email-address"}
                      className="h-10 max-w-lg w-full shadow-md rounded-md px-4 my-4"
                      required
                      defaultValue={clientData.insuranceNumber}
                      ref={insuranceNumberRef}
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="client_level" className="opacity-80">
                    Email/ link
                  </label>
                  <input
                      type="text"
                      placeholder="Email address or Site link"
                      name={"emergency-email-address"}
                      className="h-10 max-w-lg w-full shadow-md rounded-md px-4 my-4"
                      required
                      defaultValue={clientData.insuranceLink}
                      ref={insuranceLinkRef}
                  />
                </div>
              </div>
            </CustomisationSection>
            
          </div>
        </div>
        <div className="max-w-md mx-auto my-24 flex flex-col gap-8">
          <BlueButton
              text="Next"
              type="submit"
              onClick={(e: FormEvent) => handleEditClient(e)}
          />
          <div className="flex gap-4 justify-center">
            <div className="h-2 w-2 rounded-full bg-light-grey"></div>
            <div className="h-2 w-2 rounded-full bg-light-light-grey"></div>
            <div className="h-2 w-2 rounded-full bg-light-light-grey"></div>
          </div>
        </div>
      </form>
  );
}

export default ViewClient;
