import React, { ReactNode, SetStateAction, createContext, useState } from "react";
import { useParams } from "react-router-dom";
import usePaginatedGetClientUsers from "../../../../api/clients/usePaginatedGetClientUsers";
import { UseQueryResult } from "react-query";

type ClientUsersContextType = {
  allUsers: UseQueryResult<any, unknown>;
  page: number;
  setPage: React.Dispatch<SetStateAction<number>>;
  searchUserByName: string;
  setSearchUserByName: React.Dispatch<SetStateAction<string>>;
};

export const ClientUsersContext = createContext<ClientUsersContextType | null>(
  null
);

export default function ClientUsersContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [searchUserByName, setSearchUserByName] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  const { clientID } = useParams();

  const allUsers = usePaginatedGetClientUsers(
    page,
    searchUserByName,
    clientID!
  );

  const value = {
    allUsers: allUsers,
    page: page,
    setPage: setPage,
    searchUserByName: searchUserByName,
    setSearchUserByName: setSearchUserByName,
  };

  return (
    <ClientUsersContext.Provider value={value}>
      {children}
    </ClientUsersContext.Provider>
  );
}
