import React, { FormEvent, useEffect, useRef, useState } from "react";
import { BlueButton } from "../../../../components/ui/Buttons";
import DrawZone from "./DrawZone";
import Timing from "./Timing";
import ZoneType from "./ZoneType";
import { useGeofences, useZones } from "../../services";
import convertDateToUTC from "../../../../util/convertDateToUTC";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../../auth/loading";
import { useQueryClient } from "react-query";
import { Add, Close, Minimize, Remove } from "@mui/icons-material";
import ZoneOwnership from "../add-zone/ZoneOwnership";
import People from "./People";
function EditZoneForm({
  className,
  map,
  drawingManager,
}: {
  className?: string;
  map: google.maps.Map | undefined;
  drawingManager: google.maps.drawing.DrawingManager | undefined;
}) {
  const [userIDs, setUserIDs] = useState<string[]>([]);
  const [departmentIDs, setDepartmentIDs] = useState<string[]>([]);
  const [applyToAll, setApplyToAll] = useState<boolean>(false);

  const [currentMapZoom, setCurrentMapZoom] = useState<number>(2);
  const [isTimeError, setIsTimeError] = useState<boolean>(false);
  const [isZoneNameError, setIsZoneNameError] = useState<boolean>(false);
  const [updatedPolygon, setUpdatedPolygon] = useState<
    { lng: number; lat: number }[]
  >([]);
  const queryClient = useQueryClient();
  const { type } = useParams();
  console.log(type);
  const navigate = useNavigate();
  const polygonRef = useRef<google.maps.Polygon>();
  const circleRef = useRef<google.maps.Circle>();
  const formRef = useRef<HTMLFormElement>(null);
  const zonesFnc = useZones;
  const geofenceFnc = useGeofences;
  const updateQuery =
    type === "safety-zone"
      ? zonesFnc().updateMutation
      : geofenceFnc().updateMutation;
  const fetchQuery =
    type === "safety-zone" ? zonesFnc().zoneQuery : geofenceFnc().geofenceQuery;
  const { data, isLoading } = fetchQuery;
  useEffect(() => {
    if (!map || !data) return;
    console.log(data);
    // if (data.view.coordinates && data.view.properties.zoom) {
    //   map.setCenter(data.view.coordinates);
    //   map.setZoom(data.view.properties.zoom);
    // }

    const shape = data.location.type;
    const coords =
      shape === "Point"
        ? (data.location.coordinates as [number, number])
        : (data.location.coordinates[0][0] as [number, number]);
    map.setCenter({ lng: coords[0], lat: coords[1] });
    map.setZoom(currentMapZoom);
  }, [map, data, type, currentMapZoom]);

  const [zoneType, setZoneType] = useState<{
    geofences: boolean;
    safetyZones: boolean;
  }>({ geofences: false, safetyZones: false });

  useEffect(() => {
    if (!map || !data) return;
    console.log(data);
    if (data.location.type === "Polygon") {
      const polygonPath = data.location.coordinates;
      const path = polygonPath[0].map((coords: [number, number]) => ({
        lng: coords[0],
        lat: coords[1],
      }));

      const polygon = new google.maps.Polygon({
        paths: path,
        fillColor: "#E20000",
        fillOpacity: 0.35,
        strokeWeight: 2,
        strokeColor: "#E20000",
        strokeOpacity: 0.8,
        clickable: false,
        editable: true,
      });
      polygon.setMap(map);
      polygonRef.current = polygon;
    } else {
      const point = data.location.coordinates;
      const radius = data.location.properties.radius;
      const circle: google.maps.Circle = new google.maps.Circle({
        center: { lng: point[0], lat: point[1] },
        radius,
        editable: true,
        fillColor: "#E20000",
        fillOpacity: 0.35,
        strokeWeight: 2,
        strokeColor: "#E20000",
        strokeOpacity: 0.8,
        clickable: false,
      });
      circle.setMap(map);
      circleRef.current = circle;
    }
  }, [data, map, type]);

  useEffect(() => {
    if (isLoading) return;
    if (type === "safety-zone") {
      setZoneType((prev) => ({ ...prev, safetyZones: true }));
    } else {
      setZoneType((prev) => ({ ...prev, geofences: true }));
    }
  }, [isLoading, type]);

  const editPolygon = () => {
    if (!polygonRef.current) return;
    const newPath = polygonRef.current
      .getPath()
      .getArray()
      .map((latLng) => ({
        lng: latLng.lng(),
        lat: latLng.lat(),
      }));
    polygonRef.current.setPath(newPath);
    setUpdatedPolygon(newPath);
  };

  useEffect(() => {
    if (!polygonRef.current) return;
    setUpdatedPolygon(
      polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => ({
          lng: latLng.lng(),
          lat: latLng.lat(),
        }))
    );
  }, [polygonRef.current]);

  useEffect(() => {
    if (!polygonRef.current) return;
    google.maps.event.addListener(
      polygonRef.current.getPath(),
      "set_at",
      editPolygon
    );
    google.maps.event.addListener(
      polygonRef.current.getPath(),
      "insert_at",
      editPolygon
    );
    google.maps.event.addListener(
      polygonRef.current.getPath(),
      "remove_at",
      editPolygon
    );
  }, [polygonRef.current, updatedPolygon]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!formRef.current) return;
    const formData = new FormData(formRef.current);
    const zoneName = formData.get("name") as string;
    const startAtDate = formData.get("startDate") as string;
    const startAtTime = formData.get("startTime") as string;
    const startsAt = convertDateToUTC(`${startAtDate}${startAtTime}`);
    const endsAtDate = formData.get("endDate") as string;
    const endsAtTime = formData.get("endTime") as string;
    const expiresAt = convertDateToUTC(`${endsAtDate}${endsAtTime}`);
    const allTheTime = formData.get("all-the-time") as string;
    if (!zoneName) {
      setIsZoneNameError(true);
      console.error("Zone name is required.");
      return;
    }
    if (!startAtDate && allTheTime !== "true") {
      console.error("Start date is required");
      return;
    }

    if (!startAtTime && allTheTime !== "true") {
      console.error("Start time is required");
      return;
    }

    if (!endsAtDate && allTheTime !== "true") {
      console.error("Expires at date is required");
      return;
    }

    if (!endsAtTime && allTheTime !== "true") {
      console.error("Expires at time is required");
      return;
    }
    const newData = {
      id: data.id,
      name: zoneName,
      startsAt: allTheTime === "true" ? null : startsAt,
      expiresAt: allTheTime === "true" ? null : expiresAt,
      alwaysActive: allTheTime === "true" ? true : false,
      clientID: clientID,
      departmentID: departmentID,
      appliesToAll: applyToAll,
      appliesTo: {
        departmentIDs: departmentIDs,
        userIDs: userIDs,
      },
      location: {},
    };

    if (polygonRef.current) {
      const polygonPaths = updatedPolygon.map((path) => [path.lng, path.lat]);
      polygonPaths.push(polygonPaths[0]);
      const locationData = {
        type: "Polygon",
        coordinates: [polygonPaths],
      };
      newData.location = locationData;
    } else {
      const lat = circleRef.current?.getCenter()?.lat();
      const lng = circleRef.current?.getCenter()?.lng();
      const radius = circleRef.current?.getRadius();
      const locationData = {
        type: "Point",
        coordinates: [lng, lat],
        properties: {
          radius,
        },
      };

      newData.location = locationData;
    }

    if (
      (!newData.alwaysActive && newData.startsAt === "Invalid date") ||
      newData.expiresAt === "Invalid date"
    ) {
      setIsTimeError(true);
      return;
    }
    updateQuery.mutate(newData, {
      onSuccess: () => {
        type === "safety-zone"
          ? queryClient.invalidateQueries(["zone", data.id])
          : queryClient.invalidateQueries(["geofence", data.id]);
        navigate(-1);
      },
    });
  };

  const [clientID, setClientID] = useState<string | null>(null);
  const [departmentID, setDepartmentID] = useState<string | null>(null);

  const onOwnershipChange = (
    clientID: string | null,
    departmentID: string | null
  ) => {
    setClientID(clientID);
    setDepartmentID(departmentID);
  };

  const onPeopleChange = (
    userIDs: string[],
    departmentIDs: string[],
    applyToAll: boolean
  ) => {
    setUserIDs(userIDs);
    setDepartmentIDs(departmentIDs);
    setApplyToAll(applyToAll);
  };

  const [ownershipLoaded, setOwnershipLoaded] = useState<boolean>(false);
  return isLoading ? (
    <Loading />
  ) : (
    <form
      ref={formRef}
      className={`bg-white text-grey px-8 pb-8 rounded-lg overflow-y-auto ${className}`}
      style={{ width: "30em" }}
    >
      <header className="text-center relative">
        <h4 className="my-4 font-semibold text-base">Edit Zone</h4>
        <button
          className="absolute right-0 top-0"
          type="button"
          onClick={() => navigate(-1)}
        >
          <Close />
        </button>
      </header>

      <hr />

      <DrawZone
        map={map}
        drawingManager={drawingManager}
        polygonRef={polygonRef}
        circleRef={circleRef}
        data={data}
        isZoneNameError={isZoneNameError}
      />

      <hr />
      <div className="flex gap-4 items-center my-4 justify-center">
        <button
          className={"h-10 w-10 rounded-md shadow-md flex items-center justify-center "}
          type="button"
          onClick={() => {
            setCurrentMapZoom((prev) => (prev === 22 ? 22 : prev + 2));
          }}
        >
          <Add />
        </button>
        <span>Map Zoom</span>
        <button
          className={"h-10 w-10 rounded-md shadow-md flex items-center justify-center "}
          type="button"
          onClick={() =>
            setCurrentMapZoom((prev) => (prev === 2 ? 2 : prev - 2))
          }
        >
          <Remove />
        </button>
      </div>
      <hr />
      <ZoneOwnership
        clientID={data.clientID}
        departmentID={data.departmentID}
        onChange={onOwnershipChange}
        onLoaded={() => setOwnershipLoaded(true)}
      />
      <hr />
      <ZoneType
        locked={true}
        zoneType={zoneType}
        setZoneType={setZoneType}
        data={data}
      />
      <hr />

      {ownershipLoaded && (
        <People
          initialUserIDs={data.appliesTo.userIDs}
          initialDepartmentIDs={data.appliesTo.departmentIDs}
          clientID={clientID}
          departmentID={departmentID}
          onChange={onPeopleChange}
          data={data}
        />
      )}
      <hr />
      <Timing data={data} />
      <BlueButton text="Submit" onClick={handleSubmit} />
      {isTimeError && (
        <span className="text-red font-semibold">Failed: Invalid time</span>
      )}
    </form>
  );
}

export default EditZoneForm;
