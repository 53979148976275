import React, { useContext, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { BlueButton, WhiteButton } from "../../../../components/ui/Buttons";
import { UserContext } from "../../../../context/user";
import api from "../../../../api/axiosConfig";
import { downloadCSVFile } from "../../../../util/downloadCSVFile";
import Modal from "../../../../components/portal/Modal";
import useGetAllClients from "../../../../api/clients/useGetAllClients";
import Toast from "../../../../components/portal/Toast";

function ExportFacilities() {
  const user = useContext(UserContext);

  const [clientPopup, setClientPopup] = useState<boolean>(false);
  const [success, setSuccess] = useState<{
    code: number;
    message: string;
  } | null>(null);
  const [toast, setToast] = useState<boolean>(false);
  const clients = useGetAllClients();
  const clientRef = useRef<HTMLSelectElement>(null);
  const pathname = useLocation().pathname.split("/");
  const auroraAccessLevel = user?.role.auroraAccessLevel;
  const auroraAccessLevelNotAllHandleExport = () => {
    if (auroraAccessLevel === "all") {
      setClientPopup(true);
    } else {
      downloadCSVFile(
        `${process.env.REACT_APP_API_URL}facilities-export-csv`,
        "facilities.csv"
      );
    }
  };

  const handleAllExport = () => {
    if (auroraAccessLevel === "all") {
      const clientID = clientRef.current!.value;
      downloadCSVFile(
        `${process.env.REACT_APP_API_URL}facilities-export-csv?clientID=${clientID}`,
        "facilities.csv",
        (response) => {
          setClientPopup(false);
          setSuccess({
            code: response.status,
            message: "Downloaded facilities CSV file.",
          });
          setToast(true);
        },
        (error) => {
          setClientPopup(false);
          setSuccess({ code: error.status, message: error.statusText });
          setToast(true);
        }
      );
    }
  };

  return (
    <div className="w-full">
      {!pathname.includes("facilities") ? (
        <WhiteButton
          text="Export Facilities"
          onClick={auroraAccessLevelNotAllHandleExport}
        />
      ) : (
        <BlueButton
          text="Export Facilities"
          onClick={auroraAccessLevelNotAllHandleExport}
        />
      )}
      <Modal open={clientPopup} setOpen={setClientPopup}>
        <form
          action=""
          className="p-8 rounded-md bg-white shadow-md flex flex-col gap-4"
        >
          <span className="text-lg mx-auto">Select a Client</span>
          <select
            name=""
            id=""
            className="h-10 w-full rounded-md shadow-md px-4 outline-none"
            ref={clientRef}
            defaultValue={"client"}
          >
            <option value="client">Client</option>
            {clients.all_clients_data.map((client) => {
              return (
                <option value={client.id} key={client.id}>
                  {client.name}
                </option>
              );
            })}
          </select>
          <div className="max-w-xs mx-auto w-full">
            <BlueButton
              text="Export Facilities"
              onClick={() => {
                if (clientRef.current?.value === "client") {
                  return;
                }
                handleAllExport();
              }}
            />
          </div>
        </form>
      </Modal>

      <Toast open={toast} setOpen={setToast} setSuccess={setSuccess}>
        {success?.code !== 200 ? (
          <span className="text-lg font-semibold">Error: {success?.code}</span>
        ) : null}

        {success?.code === 200 ? <span>Facilities exported.</span> : null}

        {success?.code !== 200 ? (
          <p className="my-4">{success?.message}</p>
        ) : null}
      </Toast>
    </div>
  );
}

export default ExportFacilities;
