import api from "../../../../../api/axiosConfig";
import { useQuery } from "react-query";
const fetchEmailPreferences = () =>
  api.get("/email-preferences").then((res) => res.data);

export default function useEmailPreferences() {
  const {
    data: emailPreferences,
    isLoading,
    isError,
    error,
  } = useQuery("email-preferences", fetchEmailPreferences);

  return { emailPreferences, isLoading, isError, error };
}
