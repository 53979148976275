import React from "react";
import ReactCountryFlag from "react-country-flag";

export const FlagIcon = ({ country_code }: { country_code: string }) => {
  if (country_code === "") return null;
  return (
    <div className="h-full w-full flex items-center justify-center bg-opacity-0">
      <ReactCountryFlag
        countryCode={country_code}
        svg
        className="rounded-full object-cover"
        style={{ height: "100%", width: "100%" }}
      />
    </div>
  );
};
