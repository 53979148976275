import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../../context/user";
import ClientSelect from "../../components/form-v2/searchable-select/ClientSelect";
import DepartmentSelect from "../../components/form-v2/searchable-select/DepartmentSelect";
import UserSelect from "../../components/form-v2/searchable-select/UserSelect";
import CountrySelect from "../../components/form-v2/searchable-select/CountrySelect";
import BasicText from "../../components/form-v2/basic-text";
import {BlueButton, BlueButtonCentered} from "../../components/ui/Buttons";
import searchIcon from "../../assets/icons/icon-search-light.svg";
import {Client, Department} from "../../types/userManagement";
import {UserDataTypes} from "../../types/user-data-types";
import {CountryType} from "../../constants/countries";
import moment from "moment-timezone";
import {apiGET, apiGETDownload} from "../../apiv2/request";
import {Itinerary} from "../TripsV2/types";
import {dateToString, dateToStringTZ} from "../../util/helpers";
import {useNavigate, useSearchParams} from "react-router-dom";
import Pagination from "../../components/ui/Content/Pagination";
import {Action, ActionSelect} from "../../components/ui/ActionSelect";
import {CountryRiskProvider, useCountryRisk} from "../../context/countryrisk";
import {startCase} from "lodash";
import WorldSearchTypeSelect, {WorldSearchType} from "../../components/form-v2/searchable-select/WorldSearchTypeSelect";
import {RiskLevelSelect} from "../../components/form-v2/searchable-select/RiskLevelSelect";
import {useTimeZoneSelectContext} from "../../providers/TimeZoneSelectProvider";


function WorldSearch() {


  const [client, setClient] = useState<Client | undefined>(undefined)
  const [department, setDepartment] = useState<Department | undefined>(undefined)
  const [users, setUsers] = useState<UserDataTypes[] | undefined>(undefined)
  const [countries, setCountries] = useState<CountryType[] | undefined>(undefined)
  const [minRiskLevel, setMinRiskLevel] = useState<number | undefined>()

  const [query, setQuery] = useState<string>("")

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const [records, setRecords] = useState<Result | undefined>()
  const [page, setPage] = useState(1)
  const [rpp, setRPP] = useState(10)

  const [queryParams] = useSearchParams()
  const tripID = queryParams.get("tripID")
  const userID = queryParams.get("userID")

  const [startDate, setStartDate] = useState<string>(queryParams.get("startDate") || moment().format("YYYY-MM-DD"))
  const [endDate, setEndDate] = useState<string>(queryParams.get("endDate") || moment().add({month: 1}).format("YYYY-MM-DD"))


  const [downloading, setDownloading] = useState(false)

  const [prevParams, setPrevParams] = useState<Map<string, string> | undefined>()

  const [types, setTypes] = useState<WorldSearchType[]>([
    {label: "Trip - Travel", value: "trip:travel"},
    {label: "Trip - Accommodation", value: "trip:accommodation"},
    {label: "App - Check In", value: "app:check_in"},
    {label: "App - Emergency Alarm", value: "app:emergency_alarm"},
    {label: "App - Silent Alarm", value: "app:silent_alarm"},
  ])

  const {role} = useContext(UserContext)!;


  useEffect(() => {
    load()
  }, [page]);

  const getParams = (): Map<string, string> => {
    const params = new Map<string, string>()
    if (!tripID) {
      params.set("page", String(page))
      params.set("limit", String(rpp))

      if (client) {
        params.set("clientID", client.id as string)
        params.set("clientID", client.id as string)
      }

      if (department) {
        params.set("departmentID", department.id)
      }

      if (userID) {
        params.set("userID", userID)
      }

      if (users) {
        params.set("userIDs", users.map((u) => u.id).join(","))
      }

      if (countries) {
        params.set("countryISOs", countries.map(c => c.code).join(","))
      }

      if (startDate && endDate) {
        params.set("startDate", startDate)
        params.set("endDate", endDate)
      }

      if (types && types.length > 0) {

        const setTypes = new Set(types.map(t => t.value))
        if (setTypes.has("trip:travel")) {
          setTypes.delete("trip:travel")
          setTypes.add("travel:flight")
          setTypes.add("travel:taxi")
          setTypes.add("travel:ferry")
          setTypes.add("travel:train")
          setTypes.add("travel:rental")
          setTypes.add("travel:private_car")
          setTypes.add("travel:bus")
          setTypes.add("travel:other")
          setTypes.add("travel:undefined")
        }

        if (setTypes.has("trip:accommodation")) {
          setTypes.delete("trip:accommodation")
          setTypes.add("accommodation:hotel")
          setTypes.add("accommodation:private_property")
          setTypes.add("accommodation:home")
          setTypes.add("accommodation:other")
          setTypes.add("accommodation:undefined")

        }

        if (setTypes.has("trip:scheduled_check_in")) {
          setTypes.delete("trip:scheduled_check_in")
          setTypes.add("event:scheduled_check_in")
        }

        params.set("subtypes", Array.from(setTypes).join(","))
      }

      if (query) {
        params.set("query", query)
      }

      if (minRiskLevel) {
        params.set("minimumRiskLevel", String(minRiskLevel))
      }

    } else {
      params.set("tripID", tripID)
    }

    return params
  }

  const load = async () => {
    setError(false)
    setLoading(true)

    try {
      const params = getParams()


      setPrevParams(params)


      const results = await apiGET<any>(`${process.env.REACT_APP_API_V2_URL}/world-search`, Object.fromEntries(params), {
        "Aurora-Time-Zone": localStorage.getItem("auroraTimeZone") || "UTC",
      })
      setRecords(results)

    } catch (e) {
      setError(true)
    }
    setLoading(false)

  }

  const download = async () => {

    setDownloading(true)
    try {
      const params = getParams()
      params.set("csv", "")

      await apiGETDownload(
          `${process.env.REACT_APP_API_V2_URL}/world-search`,
          `world-search-${Date.now()}.csv`,
          Object.fromEntries(params),
          {"Aurora-Time-Zone": localStorage.getItem("auroraTimeZone") || "UTC",}
      )
    } catch (e) {
      console.error(e)
    }

    setDownloading(false)

  }


  return (

      <CountryRiskProvider>
        <div className="h-full pt-36 pb-16 px-7">
          {!tripID &&
              <div className="mt-5 shadow-lg px-6 pt-7 pb-8 relative bg-white">
                  <div className="flex items-center justify-between py-4 border-b text-md mb-4">
                      <span className="font-semibold text-lg">Filters</span>
                  </div>
                  <div className={"grid grid-cols-4 gap-4"}>
                    {["all"].includes(role.auroraAccessLevel) &&
                        <ClientSelect clients={client ? [client] : []}
                                      onSelectionChange={(clients) => setClient(clients[0])}/>
                    }
                    {["all", "client"].includes(role.auroraAccessLevel) &&
                        <DepartmentSelect departments={department ? [department] : []} clientID={client?.id}
                                          onSelectionChange={(departments) => setDepartment(departments[0])}/>
                    }
                      <UserSelect users={users ? users : []} clientID={client?.id} departmentID={department?.id}
                                  onSelectionChange={(users) => setUsers(users)} multiple={true} disabled={!!userID}
                                  initialUserIDs={userID ? [userID] : []}/>
                      <CountrySelect countries={countries ? countries : []} onSelectionChange={(c) => setCountries(c)}
                                     multiple={true}/>
                      <BasicText value={startDate} type={"date"} onChange={setStartDate} placeholder={"Start Date"}/>
                      <BasicText value={endDate} type={"date"} onChange={setEndDate} placeholder={"End Date"}/>
                      <BasicText value={query} onChange={setQuery} placeholder={"Search"}/>

                      <WorldSearchTypeSelect worldSearchTypes={types} onSelectionChange={(v) => setTypes(v)}
                                             multiple={true}/>
                      <RiskLevelSelect value={minRiskLevel} setValue={setMinRiskLevel}/>
                      <BlueButtonCentered text={"Filter"} icon={searchIcon} onClick={() => {
                        setPage(1);
                        load()
                      }}/>
                  </div>
              </div>
          }

          <div className="mt-5 shadow-lg px-6 pt-7 pb-20 relative bg-white">
            <div className="flex items-center justify-between py-4 border-b text-md mb-4">
              <span className="font-semibold text-lg">World Search</span>
              <div className={"w-24"}>
                <BlueButton text={"Download"} onClick={download} loading={downloading}/>
              </div>
            </div>
            <div className="mt-2">
              {loading && (
                  <div className="w-full text-center">Loading...</div>
              )}
              {error && (
                  <div className="text-red-500 w-full text-center">Error</div>
              )}
              {!loading && !error &&
                  records?.items.length == 0
                  && (
                      <div className="w-full text-center">No results</div>
                  )}
              {!loading && !error && records?.items.length != 0 &&

                  <>
                      <TableHeader/>
                    {records?.items.map((r, i) => {
                      return (
                          <Record key={i} record={r}/>
                      );
                    })}</>

              }
            </div>
            <div className="absolute bottom-5 right-10">
              <div className="flex text-light-grey">
                {records &&
                    records.items.length !== 0 && (
                        <Pagination
                            currentPage={page}
                            itemsPerPage={rpp}
                            dataLength={records.total}
                            togglePageSelect={(page) => setPage(page)}
                        />
                    )}
              </div>
            </div>
          </div>

        </div>
      </CountryRiskProvider>

  );
}


const FilterBar = () => {
  return <div className="h-full w-1/3 text-light-grey shadowlg bg-white pb-10 max-w-[500px] shadow-md">
    <div className="flex bg-light-grey">
      <div
          className={"py-3 w-full"}
      >
        <div className="text-white text-center w-full">Filter</div>
      </div>
    </div>
  </div>
}

const TableHeader = () => {

  const user = useContext(UserContext);

  let cols = 9
  if (user?.role.auroraAccessLevel == "client") {
    cols = 8
  }
  if (user?.role.auroraAccessLevel == "department") {
    cols = 7
  }


  return (
      <div className="grid gap-x-4">
        <div className={`rounded-t-lg bg-desaturated-grey py-3 grid grid-cols-${cols} w-full items-center h-full`}>
          {["all"].includes(user?.role.auroraAccessLevel || "") &&
              <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
                  Client
              </div>
          }

          {["all", "client"].includes(user?.role.auroraAccessLevel || "") &&
              <div className="font-bold border-r-1 text-center h-full flex items-center justify-center">
                  Department
              </div>
          }

          <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
            User
          </div>


          <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
            Date/Time
          </div>
          <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
            Country
          </div>
          <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
            Location
          </div>
          <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
            Risk Level
          </div>
          <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
            Type
          </div>
          <div className="font-bold text-center relative h-full flex items-center justify-center  s">
            Actions
          </div>


        </div>
      </div>
  );
}

type RecordParams = {
  record: Item
}

type ItineraryItemProps = {
  item: Itinerary
}

const RiskLevelIcon: React.FC<{ countryISO?: string, level?: number, large?: boolean }> = ({
                                                                                             countryISO,
                                                                                             level,
                                                                                             large
                                                                                           }) => {

  const {getRisk} = useCountryRisk()
  const risk = level || getRisk(countryISO || "")

  if (!risk) {
    return null
  }

  if (large) {
    return <span
        className={`w-8 h-8 rounded-2xl bg-risk-${risk} bg-risk-1 text-white items-center inline-flex justify-center content-center`}>
    <span>{risk}</span>
  </span>
  }


  return <span
      className={`w-5 h-5 text-xs rounded-2xl bg-risk-${risk} text-white items-center inline-flex justify-center content-center`}>
    <span>{risk}</span>
  </span>

}

export const ItineraryItem: React.FC<ItineraryItemProps> = ({item}) => {

  return (
      <div>
        <div className={"grid grid-cols-[9em,20em,9em,20em] px-8 mt-4"}>
          <div className={"col-span-4 font-bold mb-2"}>
            Details
          </div>
          <div className={"font-semibold capitalize"}>
            Type
          </div>

          <div className={"capitalize"}>
            {item.type}
          </div>

          <div className={"font-semibold capitalize"}>
            Subtype
          </div>

          <div className={"capitalize"}>
            {item.subType}
          </div>


        </div>

        <div className={"grid grid-cols-[9em,20em,9em,20em] px-8 mt-4"}>
          <div className={"font-semibold capitalize"}>
            {item.type == "accommodation" ? "Arrival Time" : "Departure Time"}
          </div>

          <div className={"capitalize"}>
            {moment.tz(item.startTime, item.locations[0].timezone).format("DD/MM/YYYY HH:mm")} ({item.locations[0].timezone})
          </div>

          <div className={"font-semibold capitalize"}>
            {item.type == "accommodation" ? "Departure Time" : "Arrival Time"}
          </div>

          <div className={"capitalize"}>
            {moment.tz(item.endTime, item.locations[item.locations.length - 1].timezone).format("DD/MM/YYYY HH:mm")} ({item.locations[item.locations.length - 1].timezone})
          </div>

        </div>

        <div className={"grid px-8 grid-cols-[9em,20em,9em,20em,9em,20em]"}>


          {Object.keys(item.details).map((k) => <>
            <div key={`${k}-1`} className={"font-semibold capitalize"}>
              {startCase(k)}
            </div>
            <div key={`${k}-2`} className={""}>
              {item.details[k] || "–"}
            </div>
          </>)}
        </div>

        {item.locations.map((l, i) => (<>
          <div key={i} className={"grid px-8 mt-4 grid-cols-[9em,20em,9em,20em,9em,20em]"}>
            {item.locations.length == 1 && (
                <div className={"col-span-6 font-bold mb-2"}>
                  Location <RiskLevelIcon countryISO={l.countryISO}/>
                </div>
            )}


            {item.locations.length == 2 && (
                <div className={"col-span-6 font-bold mb-2"}>
                  {i == 0 ? "Origin Location" : "Destination Location"} <RiskLevelIcon countryISO={l.countryISO}/>
                </div>
            )}
            <div className={"font-semibold capitalize"}>
              Address
            </div>

            <div className={""}>
              {l.addressLines.join(", ")}
            </div>

            <div className={"font-semibold capitalize"}>
              Town
            </div>

            <div className={""}>
              {l.town}
            </div>

            <div className={"font-semibold capitalize"}>
              County
            </div>

            <div className={""}>
              {l.county}
            </div>

            <div className={"font-semibold capitalize"}>
              Region
            </div>

            <div className={""}>
              {l.region}
            </div>
            <div className={"font-semibold capitalize"}>
              Country
            </div>

            <div className={""}>
              {l.countryName}
            </div>

            <div className={"font-semibold capitalize"}>
              Postal Code
            </div>

            <div className={""}>
              {l.postalCode}
            </div>
          </div>

        </>))}

        <hr className={"mt-4"}/>


      </div>
  )
}

export type ItineraryItemType = {
  type: string;
  subType: string;
  locations: Array<{
    countryISO: string;
    countryName: string;
    region: string;
    county: string;
    town: string;
    postalCode: string;
    addressLines: Array<string>;
    timezone: string;
    point: { type: string; coordinates: Array<number> };
    formatted: string
  }>;
  startTime: string;
  endTime: string;
  details: Record<string, string>
}

const Record: React.FC<RecordParams> = ({record}) => {

  const [showTrip, setShowTrip] = useState(false)
  const user = useContext(UserContext);


  const navigate = useNavigate()

  const [tripPage, setTripPage] = useState(1)

  let cols = 9
  if (user?.role.auroraAccessLevel == "client") {
    cols = 8
  }
  if (user?.role.auroraAccessLevel == "department") {
    cols = 7
  }

  const tzContext = useTimeZoneSelectContext()


  return (
      <>

        <div className={`py-3 border-b-1 w-full grid grid-cols-${cols} items-center h-full`}>
          {["all"].includes(user?.role.auroraAccessLevel || "") && <div className="text-center">
            {record.clientName || "-"}
          </div>}
          {["all", "client"].includes(user?.role.auroraAccessLevel || "") && <div className="text-center">
            {record.departmentName || "-"}
          </div>}
          <div className="text-center">
            {record.userName || "-"}
          </div>

          {record.startTime == record.endTime && <div className="text-center">
            {dateToStringTZ(record.endTime,tzContext.timeZone, true)}
          </div>}


          {record.startTime != record.endTime && <div className="text-center">
            {dateToStringTZ(record.startTime, tzContext.timeZone, true)}
              <br/>
            {dateToStringTZ(record.endTime, tzContext.timeZone, true)}
          </div>}

          <div className={"text-center"}>
            {record.location.countryName}
          </div>
          <div className={"text-center"}>
            {record.location.town}
          </div>

          <div className={"text-center"}>
            <RiskLevelIcon level={record.currentCountryRisk} large={true}/>
          </div>


          <div className={"text-center"}>
            <div className={"capitalize"}>{record.subtype.split(":")[0]}</div>
            <div className={"capitalize text-sm"}>{record.subtype.split(":")[1].split("_").join(" ")}</div>

          </div>

          <div className={"px-6"}>
            <ActionSelect label={"Select Action"}>
              {record.tripID && record.tripID != "000000000000000000000000" &&
                  <Action label={showTrip ? "Hide Trip" : "Show Trip"} onSelect={() => setShowTrip(!showTrip)}/>}
              {record.tripID && record.tripID != "000000000000000000000000" &&
                  <Action label={"Edit Trip"} onSelect={() => window.open(`/trips/${record.tripID}/edit`, "_blank")}/>}
            </ActionSelect>
            {/* {record.trip &&*/}
            {/*    <BlueButton text={showTrip ? "Hide Trip" : "Show Trip"} onClick={() => setShowTrip(!showTrip)}/>}*/}
          </div>


        </div>


        {showTrip && <div className={"relative"}>
          {record.trip.itinerary[tripPage - 1] && <ItineraryItem item={record.trip.itinerary[tripPage - 1]}/>}

            <Pagination currentPage={tripPage} itemsPerPage={1} dataLength={record.trip.itinerary.length}
                        togglePageSelect={setTripPage}/>
        </div>}
      </>
  )
      ;
}


export default WorldSearch;


type Result = {
  items: Item[]
  page: number
  total: number
}

type Item = {
  id: string
  type: string
  subtype: string
  location: {
    countryISO: string
    countryName: string
    region: string
    county: string
    town: string
    postalCode: string
    addressLines: Array<string>
    timezone: string
    point: {
      type: string
      coordinates: Array<number>
    }
    formatted: string
  }
  startTime: string
  endTime: string
  startCountryRisk: number
  endCountryRisk: number
  currentCountryRisk: number
  userID: string
  tripID: string
  userName: string
  departmentName: string
  departmentID: string
  clientName: string
  clientID: string
  trip: {
    id: string
    userID: string
    startTime: string
    endTime: string
    settings: {
      userEditable: boolean
      allowThirdPartyUpdates: boolean
    }
    source: {
      provider: string
      type: string
      id: string
      format: string
      rawData: string
    }
    checkInSettings: {
      types: Array<string>
      applyTo: Array<any>
      times: Array<string>
      days: Array<string>
      gracePeriod: number
      preventDuring: Array<any>
    }
    itinerary: ItineraryItemType[]
    status: {
      valid: boolean
      checkInsGenerated: boolean
      errors: Array<any>
    }
    deleted: boolean
    lastModifiedAt: string
  }
}