import React, { useEffect, useState } from "react";
import IncidentEntry from "../../components/IncidentEntry";
import { IncidentDataTypes } from "../../../../../../types/incident-data-types";
import useAllIncidents from "./useAllIncidents";
import IncidentFilterForm from "../../components/Filtertemp";
import DownloadIcon from "../../../../../../../src/assets/icons/download.svg";
import LoadingSpinner from "../../../../../../components/ui/LoadingSpinner";

function IncidentLogMain() {
  const [page, setPage] = useState<number>(1);
  const [filters, setFilters] = useState({
    severity: "",
    countryISO: "",
    typeIDs: "",
    startTime: "",
    endTime: new Date().toISOString(),
    coordinates: "",
    radius: "",
  });

  const [downloadingCSV, setDownloadingCSV] = useState(false);

  const { incidents, total, status } = useAllIncidents(filters, page);

  useEffect(() => {
    console.log("Total incidents:", total);
  }, [total]);

  const isDefaultFilters = () => {
    const currentTime = new Date().toISOString();
    return (
      !filters.severity &&
      !filters.countryISO &&
      !filters.typeIDs &&
      !filters.startTime &&
      !filters.coordinates &&
      !filters.radius    
      );
  };

  useEffect(() => {
    console.log("Inside useEffect:", incidents);
  }, [incidents]);

  const handleApplyFilter = (appliedFilters: any) => {
    setFilters(appliedFilters);
  };

  const handleNextPage = () => {
    if (!isLastPage) {
      setPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  const handleDownload = () => {
    if (downloadingCSV) {
      return;
    }
    setDownloadingCSV(true);
    const params = new URLSearchParams();

    params.set("export", "csv");

    if (filters.severity) {
      params.set("severity", filters.severity);
    }

    if (filters.countryISO) {
      params.set("countryISO", filters.countryISO);
    }

    if (filters.typeIDs) {
      params.set("typeIDs", filters.typeIDs);
    }

    if (filters.startTime) {
      params.set("startTime", filters.startTime);
    }

    if (filters.endTime) {
      params.set("endTime", filters.endTime);
    }

    if (filters.coordinates) {
      params.set("coordinates", filters.coordinates);
    }

    if (filters.radius) {
      params.set("radius", filters.radius);
    }

    fetch(`${process.env.REACT_APP_API_URL}incidents?${params.toString()}`, {
      method: "GET",
      headers: {
        "Aurora-Time-Zone": localStorage.getItem("auroraTimeZone") || "UTC",
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const downloadLink = document.createElement("a");
        const objectUrl = URL.createObjectURL(blob);

        downloadLink.href = objectUrl;
        downloadLink.download = "incidents.csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();

        URL.revokeObjectURL(objectUrl);
        document.body.removeChild(downloadLink);
        setDownloadingCSV(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setDownloadingCSV(false);
      });
  };

  const itemsPerPage = 10;
  const isLastPage = incidents && incidents.length < itemsPerPage;
  const defaultFiltersSet = isDefaultFilters();

  return (
    <div className="shadow-lg bg-white w-full h-full">
      <div className="h-[55px] w-full bg-blue flex justify-between items-center relative">
        <div className="flex items-center ml-4">
          <IncidentFilterForm
            onApplyFilter={handleApplyFilter}
            setPage={setPage}
          />

          <button
            className={`p-2 rounded-md ${
              defaultFiltersSet
                ? "cursor-not-allowed"
                : ""}`}              
            onClick={handleDownload}
            disabled={defaultFiltersSet}
            >
            {downloadingCSV ? (
              <LoadingSpinner width={20} height={20} color="#ffffff" />
            ) : (
              <img src={DownloadIcon} className="pointer-events-none"></img>
            )}
          </button>
        </div>
        <div className="text-white font-semibold ml-14 flex-grow text-center">
          Results
        </div>
        <div className="flex items-center mr-4">
          <button
            onClick={handlePrevPage}
            className="px-4 py-2 bg-blue text-white rounded-md"
          >
            <span className="text-sm">&#8592; Previous Page</span>
          </button>
          <span className=" text-white font-bold">
            {page}{" "}
            <span className="font-semibold" style={{ fontSize: "0.75em" }}>
              of
            </span>{" "}
            <span className="font-semibold" style={{ fontSize: "0.9em" }}>
              {Math.ceil(total / 10)}
            </span>
          </span>
          <button
            onClick={handleNextPage}
            className={`px-4 py-2 bg-blue text-white rounded-md ${
              isLastPage ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isLastPage}
          >
            <span className="text-sm">Next Page &#8594;</span>
          </button>
        </div>
      </div>
      <div className="px-5 py-7 pb-10 w-full h-[calc(100%-_60px)] overflow-hidden relative">
        <div className="w-full h-full overflow-x-scroll scrollbar-hide">
          <div className="flex border-b-1 pb-3 px-2 w-fit 1.5xl:w-full">
            <div className="text-center min-w-[150px] 1.5xl:w-full">Date</div>
            <div className="text-center min-w-[150px] 1.5xl:w-full">Type</div>
            <div className="text-center min-w-[150px] 1.5xl:w-full">
              Severity
            </div>
            <div className="text-center min-w-[150px] 1.5xl:w-full">
              Summary
            </div>
            <div className="text-center min-w-[150px] 1.5xl:w-full">
              Country
            </div>
            <div className="text-center min-w-[150px] 1.5xl:w-full">Source</div>
          </div>
          <div className="mt-1 px-5 text-sm">
            {status === "loading" && (
              <div className="w-full text-center">Loading...</div>
            )}
            {status === "error" && (
              <div className="text-red-500 w-full text-center">Error</div>
            )}
            {status === "success" && incidents && !incidents.length && (
              <div className="w-full text-center">No results</div>
            )}
            {status === "success" &&
              incidents &&
              incidents !== null &&
              incidents.map((incident: IncidentDataTypes) => {
                return <IncidentEntry key={incident.id} data={incident} />;
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncidentLogMain;
