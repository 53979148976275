import React, { useContext } from "react";
import { CountryProfileContext } from "../../context/country-profile";

function CountryProfileOverviewSection() {
  const countryProfileContext = useContext(CountryProfileContext);

  if (!countryProfileContext) return null;

  return !countryProfileContext ||
    !countryProfileContext.data?.data.overview ? (
    <div className="">No data found</div>
  ) : (
    <div
      dangerouslySetInnerHTML={{
        __html: countryProfileContext.data?.data.overview,
      }}
    ></div>
  );
}

export default CountryProfileOverviewSection;
