import React, { useEffect } from "react";
import WhiteLogo from "../../components/ui/WhiteLogo";
import DefaultPersonIcon from "../../assets/icons/icon-default-person.svg";
import { useOidc } from "@axa-fr/react-oidc";
import { useNavigate } from "react-router-dom";
import WhiteLogoImage from "../../assets/icons/ngs-white-blue.svg";

function Login() {
  const navigate = useNavigate();
  const appURL = process.env.REACT_APP_WEB_APP;

  const { login, logout, renewTokens, isAuthenticated } = useOidc();
  useEffect(() => {


    if (window.location.hostname === "safety.northcottglobalsolutions.com" && !isAuthenticated) {
      // Redirect to a different page
      login("/operational-map");
      return
    }


    // trigger every time isAuthenticated state is changed
    if (!isAuthenticated) return;
    handleLogin();
  }, [isAuthenticated]);

  const handleLogin = () => {
    // if user is not authenticated then redirect them to the auth login apage
    // if user is authenticated then redirect them to operational map
    if (!isAuthenticated) {
      login("/operational-map");
    } else {
      navigate("/operational-map");
    }
  };

  return (
    <div className="relative w-screen h-screen bg-gradient-to-bl from-[#2D166F_50%] to-[#007584] flex flex-col justify-center items-center">
      <div className="">
        <img src={WhiteLogoImage} alt="logo of the company" className="h-48" />
      </div>
      <form
        action=""
        className="flex flex-col gap-4 items-center justify-center"
        onSubmit={handleLogin}
      >
        <img src={DefaultPersonIcon} alt="" className="mb-4 h-32" />
        {/* <TextInput placeholder={'Password'} onChange={e => setPassword(e.target.value)}/> */}

        <button
          type="submit"
          className="uppercase text-[#2D166F] h-14 bg-[#6BE8FA] focus:bg-[#67D1E0] hover:bg-[#66C4D1] disabled:bg-[#75C6D1] z-10 w-full mt-4 rounded-full drop-shadow-md"
          onClick={handleLogin}
        >
          Login
        </button>
      </form>
    </div>
  );
}

export default Login;
