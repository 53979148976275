export const downloadCSVFile = async (
  url: string,
  fileName: string,
  successfullCallback?: (response: any) => void,
  errorCallback?: (error: any) => void
) => {
  await fetch(url, {
    headers: {
      "Content-Type": "application/text/csv",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(
          `Failed to fetch CSV file: ${response.status} ${response.statusText}`
        );
      }
      if (successfullCallback) {
        successfullCallback(response);
      }
      return response.text();
    })
    .then((csvData) => {
      const blob = new Blob([csvData], { type: "text/csv" });
      const downloadLink = document.createElement("a");
      const objectURL = URL.createObjectURL(blob);
      downloadLink.target = "_blank";
      downloadLink.href = objectURL;
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);

      downloadLink.click();

      document.body.removeChild(downloadLink);

      URL.revokeObjectURL(objectURL);
    })
    .catch((error) => {
      console.error("Failed to download CSV file:", error);
      if (errorCallback) {
        errorCallback(error);
      }
    });
};
