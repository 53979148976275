import React, {RefObject, useContext, useEffect, useState} from "react";
import InputText from "../../../../../../components/form/InputText";
import FormSection from "../form-section";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { ClientDataTypes } from "../../../../../../types/client-data-types";
import useGetAllClientDepartments from "../../../../../../api/client-departments/useGetAllClientDepartments";
import useGetAllClientFacilities from "../../../../../../api/client-facilities/useGetAllClientFacilities";
import { DepartmentDataTypes } from "../../../../../../types/department-data-types";
import { UserProfileContext } from "../../context/user-profile";
import EmployeeID from "./EmployeeID";
import JobTitle from "./JobTitle";
import ClientName from "./ClientName";
import Department from "./Department";
import {FacilitySelectSingle} from "../../../../../../components/form-v2/searchable-select/FacilitySelect";
import {FacilityDataTypes} from "../../../../../../types/facilitiy-data-types";
import {FormDataContext} from "../../context/form";
function EmploymentDetails() {
  const { data } = useContext(UserProfileContext)!;
  const userData: UserDataTypes = data?.data;
  const clientDepartments = useGetAllClientDepartments(userData.clientID);
  const departments: DepartmentDataTypes[] = clientDepartments.data;

  const [homeFacility, setHomeFacility] = useState<FacilityDataTypes>()
  const [stationedFacility, setStationedFacility] = useState<FacilityDataTypes>()

  const formDataContext = useContext(FormDataContext)!;
  const { stationedFacilityRef, homeFacilityRef } = formDataContext;

  useEffect(() => {
    console.log(homeFacility)
  }, [homeFacility])

  return (
    <FormSection title={"Employment Details"}>
      <div className="grid grid-cols-4 gap-8">
        <EmployeeID/>
        <JobTitle/>
        <ClientName/>

        {clientDepartments.status !== "success" ? null : (
            <Department
                departments={departments}
                defaultDepartmentID={userData.departmentID}
            />
        )}

        <div className="">
          <label className="opacity-80">
            Home Facility
          </label>
          <input type="hidden" value={homeFacility ? homeFacility.id : ""} ref={homeFacilityRef}/>
          <FacilitySelectSingle clientID={userData.clientID} initialFacilityID={userData?.companyDetails?.homeFacility || undefined} facility={homeFacility} onChange={(f) => setHomeFacility(f)}/>
        </div>
        <div className="">
          <label className="opacity-80">
            Stationed Facility
          </label>
          <input type="hidden" value={stationedFacility ? stationedFacility.id : ""} ref={stationedFacilityRef}/>

          <FacilitySelectSingle clientID={userData.clientID}
                                initialFacilityID={userData?.companyDetails?.stationedFacility || undefined}
                                facility={stationedFacility} onChange={(f) => setStationedFacility(f)}/>
        </div>
      </div>
    </FormSection>
  );
}

export default EmploymentDetails;
