import React, { SetStateAction, useContext, useState } from "react";
import { FacilityDataTypes } from "../../../../../types/facilitiy-data-types";
import PencilIcon from "../../../../../assets/icons/pencil.svg";
import BinIcon from "../../../../../assets/icons/icon-bin.svg";
import { useMutation, useQueryClient } from "react-query";
import Modal from "../../../../../components/portal/Modal";

import DeleteFacility from "../modals/delete-facility";
import Guardian from "./Guardian";
import EditFacility from "../../../components/edit-facility";
import { FormsContext } from "../../../context/forms-context";
import api from "../../../../../api/axiosConfig";
import { useNavigate } from "react-router-dom";
import DeleteSiteBasedAlerts from "../../../components/delete-site-based-alerts";
type RowPropsType = {
  facility: FacilityDataTypes;
  setEditSiteBasedAlertsFacilityID: React.Dispatch<
    SetStateAction<string | null>
  >;
  setEditSiteBasedAlertsModal: React.Dispatch<SetStateAction<boolean>>;
};

function Row({
  facility,
  setEditSiteBasedAlertsFacilityID,
  setEditSiteBasedAlertsModal,
}: RowPropsType) {
  const navigate = useNavigate();
  const { id } = facility;
  const [deleteSBA, setDeleteSBA] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [editFacility, setEditFacility] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const handleDeleteFacility = () => {
    api.delete(`facilities/${facility.id}`);
    queryClient.invalidateQueries("all-facilities");
    setDeleteModal(false);
    setDeleted(true)
  };

  const [deleted, setDeleted] = useState<boolean>(false)

  const guardians = facility.guardianNames;

  return (
    <> {!deleted &&
    <div className="grid grid-cols-[12%_12%_10%_25%_10%_10%_10%_12%] bg-desaturated-grey rounded-lg items-center py-2">
      <span className="border-r flex justify-center items-center">
        {facility.name}
      </span>
      <span className="border-r flex justify-center items-center">
        {facility.clientName}
      </span>
      <span className="border-r flex justify-center items-center">
        {facility.departmentName || "N/A"}
      </span>
      <span className="border-r text-ellipsis flex items-center justify-center text-center w-full px-4">
        {facility.address.addressLine}
      </span>
      <div className="border-r  flex flex-col justify-center text-center">
        <span>{facility.position.coordinates[0].toFixed(5)}</span>
        <span>{facility.position.coordinates[1].toFixed(5)}</span>
      </div>
      {/* // Actions */}
      <div className="border-r flex items-center justify-center gap-6 ">
        <button
          className="w-4 h-4 flex items-center justify-center"
          onClick={() => setEditFacility(true)}
        >
          <img src={PencilIcon} alt="" />
        </button>
        <button
          className="w-4 h-4 flex items-center justify-center"
          onClick={() => setDeleteModal(true)}
        >
          <img src={BinIcon} alt="" />
        </button>
      </div>
      {/* // Site based alerts */}
      <div className="border-r flex items-center justify-center gap-6 ">
        <button
          className="w-4 h-4 flex items-center justify-center"
          onClick={() => {
            setEditSiteBasedAlertsFacilityID(id);
            setEditSiteBasedAlertsModal(true);
          }}
        >
          <img src={PencilIcon} alt="" />
        </button>
        <button className="w-4 h-4 flex items-center justify-center">
          <img src={BinIcon} alt="" onClick={() => setDeleteSBA(true)} />
        </button>
      </div>
      <div className="h-20 overflow-y-auto flex flex-col text-left  w-full px-8">
        {guardians?.map((guardian, i) => (
          <Guardian key={i} guardian={guardian} />
        ))}
      </div>
      <Modal open={deleteModal} setOpen={setDeleteModal}>
        <DeleteFacility
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          facility={facility}
          handleDelete={handleDeleteFacility}
        />
      </Modal>
      <Modal open={editFacility} setOpen={setEditFacility}>
        <EditFacility
          editFacility={editFacility}
          setEditFacility={setEditFacility}
          facility={facility}
        />
      </Modal>
      <Modal open={deleteSBA} setOpen={setDeleteSBA}>
        <DeleteSiteBasedAlerts
          facilitiyID={facility.id}
          setDeleteSBA={setDeleteSBA}
        />
      </Modal>
    </div>}
    </>
  );
}

export default Row;
