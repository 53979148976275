import FilterIcon from "../../../../assets/icons/filter-icon.svg";
export const tableHeader = [
  { label: "Name", icon: FilterIcon, id: "name" },
  { label: "Client", icon: FilterIcon, id: "client" },
  { label: "Description", id: "description" },
  { label: "Created By", id: "created-by" },
  { label: "Created At", id: "created-at" },
  { label: "View and Edit Overlay", id: "view-edit" },
  { label: "Delete Overlay", id: "delete" },
];
