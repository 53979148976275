import React, { useContext } from "react";
import InputText from "../../../../../../components/form/InputText";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { FormDataContext } from "../../context/form";
function JobTitle() {
  const { data } = useContext(UserProfileContext)!;
  const userData: UserDataTypes = data?.data;
  const formDataContext = useContext(FormDataContext)!;
  const { jobTitleRef } = formDataContext;
  return (
    <div className="">
      <label htmlFor="job_title" className="opacity-80">
        Job Title
      </label>
      <InputText
        name={"job_title"}
        placeholder="Enter Job Title"
        onChange={() => {}}
        defaultValue={
          userData.companyDetails?.jobTitle
            ? userData.companyDetails?.jobTitle
            : ""
        }
        inputRef={jobTitleRef}
      />
    </div>
  );
}

export default JobTitle;
