import React, { RefObject } from "react";
import DoneIcon from "@mui/icons-material/Done";
type CheckboxTypes = {
  name: string;
  checked: boolean;
  value: string;
  label: string;
  onClick: (target: HTMLInputElement) => void;
  inputRef?: RefObject<HTMLInputElement>;
  disabled?: boolean;
};
function Checkbox({
  checked,
  value,
  label,
  onClick,
  name,
  inputRef,
  disabled = false,
}: CheckboxTypes) {
  return (
    <div className="flex items-center gap-4 text-sm">
      <div className="relative w-4 h-4 flex items-center justify-center">
        <input
          checked={checked}
          value={value}
          type="checkbox"
          name={name}
          className={`h-full w-full z-10 relative opacity-0 ${
            disabled ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          onChange={(event) => onClick(event.target)}
          ref={inputRef}
          disabled={disabled}
        />
        <button
          type="button"
          className="w-5 h-5 absolute border top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 flex items-center justify-center"
        >
          {checked ? <DoneIcon style={{ fontSize: "14px" }} /> : null}
        </button>
      </div>

      <span className="text-ellipsis overflow-hidden whitespace-nowrap">
        {label}
      </span>
    </div>
  );
}

export default Checkbox;
