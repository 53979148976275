import React, { SetStateAction, useEffect, useState } from "react";
import useGetSiteBasedAlertsPerFacility from "../../../../api/site-based-alerts/useGetSiteBasedAlertsPerFacility";
import { SiteBasedAlertDataTypes } from "../../../../types/site-based-alerts-data-type";
import DeleteConfirmation from "../delete-confirmation";
import Modal from "../../../../components/portal/Modal";
import BinIcon from "../../../../assets/icons/icon-bin.svg";
import api from "../../../../api/axiosConfig";
import { useQueryClient } from "react-query";
function DeleteSiteBasedAlerts({
  facilitiyID,
  setDeleteSBA,
}: {
  facilitiyID: string;
  setDeleteSBA: React.Dispatch<SetStateAction<boolean>>;
}) {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteSbaID, setDeleteSbaID] = useState<string>("");
  const sba = useGetSiteBasedAlertsPerFacility(facilitiyID);
  const items = sba.data?.data.items;
  const queryClient = useQueryClient();
  const handleDelete = () => {
    api.delete(`sba/${facilitiyID}/${deleteSbaID}`).then((res) => {
      queryClient.invalidateQueries(["facility-sba", facilitiyID]);
      setDeleteModal(false);
      setDeleteSbaID("");
      setDeleteSBA(false);
    });
  };

  useEffect(() => {
    if (!deleteModal) {
      setDeleteSbaID("");
    }
  }, [deleteModal]);

  return (
    <div
      className={`p-8 bg-white  rounded-md ${
        deleteModal ? "hidden" : "flex flex-col"
      }`}
    >
      <header className="text-center mb-8 text-lg font-semibold border-b pb-4">
        <h4 className="">Site Based Alerts</h4>
      </header>
      <ul className="flex flex-col gap-4">
        {items?.map((item: SiteBasedAlertDataTypes) => (
          <li
            key={item.id}
            className="bg-light-light-grey flex flex-row items-center justify-between gap-4 p-4 rounded-md min-w-[360px]"
          >
            <span className="text-lg">{item.name}</span>
            <button
              className="w-4 h-4 flex items-center justify-center"
              onClick={() => {
                setDeleteModal(true);
                setDeleteSbaID(item.id);
              }}
            >
              <img src={BinIcon} alt="" />
            </button>
          </li>
        ))}
      </ul>
      <Modal open={deleteModal} setOpen={setDeleteModal}>
        <DeleteConfirmation
          handleDelete={handleDelete}
          text={"Confirm deleting site based alert?"}
          cancelDelete={setDeleteModal}
        />
      </Modal>
    </div>
  );
}

export default DeleteSiteBasedAlerts;
