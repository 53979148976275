import React, { useEffect, useRef, useState } from "react";
import { generateMap } from "../../../../../../util/map";
import EditCustomOverlay from "../../../../components/edit-custom-overlay";
import { useCustomOverlays } from "../../../../services";
type MapPropTypes = {
  center?: google.maps.LatLngLiteral;
  zoom: number;
};

function Map({ center, zoom = 3 }: MapPropTypes) {
  const [map, setMap] = useState<google.maps.Map>();
  const [drawingManager, setDrawingManager] =
    useState<google.maps.drawing.DrawingManager>();

  const ref = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const { data: overlayData, isLoading: overlayLoading } =
    useCustomOverlays().overlayQuery;
  useEffect(() => {
    const center = [0, 0];
    const mapCenter = { lng: center[0], lat: center[1] };
    const generatedMap = generateMap(ref, mapCenter, zoom);
    setMap(generatedMap);
  }, []);

  useEffect(() => {
    if (!map) return;

    const generatedDrawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.MARKER,
          google.maps.drawing.OverlayType.POLYGON,
          google.maps.drawing.OverlayType.POLYLINE,
          google.maps.drawing.OverlayType.CIRCLE,
        ],
      },

      circleOptions: {
        fillColor: "#E20000",
        fillOpacity: 0.35,
        strokeWeight: 2,
        strokeColor: "#E20000",
        strokeOpacity: 0.8,
        clickable: false,
        editable: true,
      },
      polygonOptions: {
        fillColor: "#000000",
        fillOpacity: 0.35,
        strokeWeight: 2,
        strokeColor: "#000000",
        strokeOpacity: 0.8,
        clickable: false,
        editable: true,
      },
      polylineOptions: {
        strokeWeight: 2,
        strokeColor: "#000000",
        strokeOpacity: 0.8,
        clickable: false,
        editable: true,
      },
    });

    setDrawingManager(generatedDrawingManager);
  }, [map]);

  useEffect(() => {
    if (!map || !drawingManager) return;
    drawingManager?.setMap(map);
  }, [map, drawingManager]);

  return (
    <div className="flex gap-4 relative h-full overflow-y-auto pb-4">
      {overlayLoading
        ? null
        : overlayData && (
            <EditCustomOverlay
              className="rounded-md shadow-md"
              formRef={formRef}
              map={map}
              drawingManager={drawingManager}
              overlayData={overlayData}
            />
          )}

      <div className="relative flex-1 w-full" ref={ref}></div>
    </div>
  );
}

export default Map;
