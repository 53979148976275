import React, {RefObject, useEffect, useState} from "react";
import FormSection from "../form-section";
import InputText from "../../../../../../components/form/InputText";
import Insurancer from "./Insurancer";
import PolicyNumber from "./PolicyNumber";
import PolicyExpiryDate from "./PolicyExpiryDate";
import {UserEmergencyContact, UserInsurancePolicy} from "../../../../../../types/user-data-types";
import {BlueButton, OrangeButton} from "../../../../../../components/ui/Buttons";
import moment from "moment";

type InsuranceDetailsProps = {
    insurancePolicies: UserInsurancePolicy[];
    addInsurancePolicy: () => void;
    removeInsurancePolicy: (index: number) => void;
    setInsurancePolicies: (insurancePolicies: UserInsurancePolicy[]) => void;
}

const InsuranceDetails: React.FC<InsuranceDetailsProps> = ({insurancePolicies, addInsurancePolicy, removeInsurancePolicy, setInsurancePolicies}) => {
    const onChange = (item: UserInsurancePolicy, index: number) => {
        const newItems = [...insurancePolicies]
        newItems[index] = item
        setInsurancePolicies(newItems)
    }

    return (
        <FormSection title="Insurance Details">
            {insurancePolicies.map((p, i) => (
                <div key={p.id} className={"mb-8"}>
                    <Fields item={p} index={i} onChange={onChange} remove={removeInsurancePolicy}/>
                </div>
            ))}
            <div className="grid grid-cols-4 gap-8">
                <BlueButton text={"Add Policy"} onClick={addInsurancePolicy}/>
            </div>
        </FormSection>
    )
}

type FieldsProps<T> = {
    item: T;
    index: number;
    onChange: (item: T, index: number) => void;
    remove: (index: number) => void;
}


const Fields: React.FC<FieldsProps<UserInsurancePolicy>> = ({item, index, onChange, remove}) => {
    const [insurer, setInsurer] = useState(item.insurer)
    const [policyNumber, setPolicyNumber] = useState(item.policyNumber)
    const [expiresAt, setExpiresAt] = useState(item.expiresAt)

    useEffect(() => {
        onChange({
            insurer,
            policyNumber,
            expiresAt
        }, index)
    }, [insurer, policyNumber, expiresAt])

    return (
        <div className="grid grid-cols-4 gap-8">
            <Field label={"Insurer"} placeholder={"Enter Insurer Name"} value={insurer} onChange={setInsurer}/>
            <Field label={"Policy Number"} placeholder={"Enter Policy Number"} value={policyNumber} onChange={setPolicyNumber}/>
            <Field date label={"Policy Expiry Date"} placeholder={"Policy Expiry Date"} value={moment(expiresAt).format("yyyy-MM-DD")} onChange={setExpiresAt}/>


            <div className="">
                <label>&nbsp;</label>
                <OrangeButton text={"Remove Policy"} onClick={() => {
                    remove(index)
                }}/>
            </div>
        </div>
    )
}

type FieldProps = {
    label: string;
    placeholder: string;
    value: string;
    onChange: (v: string) => void;
    date?: boolean
}

const Field:React.FC<FieldProps> = ({
                                        label, placeholder, value, onChange, date
                                    }) => {
    return (<div>
        <label className="opacity-80">
            {label}
        </label>
        <InputText
            type={date ? "date" : "text"}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
                onChange(e.target.value)
            }}
        />
    </div>)
}


export default InsuranceDetails;
