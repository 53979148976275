import React, { SetStateAction, useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { CountryProfileContext } from "../../../../context/country-profile";
import useGetRiskKey from "../../../../hooks/useGetRiskKey";
import Label from "../../../../../../../../components/form/Label";
type CountryProfileSidebarTypes = {
  isMap: boolean;
  setIsMap: React.Dispatch<SetStateAction<boolean>>;
};


const navLinks = [
  { label: "overview", path: "overview" },
  { label: "political", path: "political" },
  { label: "operational", path: "operational" },
  { label: "personal security", path: "personal-security" },
  { label: "terrorism", path: "terrorism" },
  { label: "travel", path: "travel" },
  { label: "medical", path: "medical" },
  { label: "cultural considerations", path: "cultural-considerations" },
  { label: "regional information", path: "regional-information" },
  { label: "natural hazards", path: "natural-hazards" },
  { label: "security advice", path: "security-advice" },
];
type SidebarTypes = {
  isMap: boolean;
  setIsMap: React.Dispatch<SetStateAction<boolean>>;
};

function CountryProfileSidebar({
  isMap,
  setIsMap,
}: CountryProfileSidebarTypes) {
  const countryProfileContext = useContext(CountryProfileContext);
  const { nav_page, id } = useParams();
  const location = useLocation();
  const pathname = location.pathname;
  const pathArr = pathname.split("/");
  const section = pathArr.at(pathArr.length - 1);

  const key = useGetRiskKey(countryProfileContext!.data?.data.averageRiskLevel);
  if (!countryProfileContext) return null;
  const { color } = key![0];

  return (
    <div className="w-2/4 max-w-lg">
      {/* <div className="px-8 bg-white py-2 shadow-lg">
        <Label
          text="Basic Country Information"
          extraClass="justify-center my-4"
        />
        <hr className="" />
        <div className="flex justify-between my-4">
          <Label text="Time" assignText="10:21:24" extraClass="flex flex-col" />
          <Label
            text="Currency"
            assignText="Afghan Afghani"
            extraClass="flex flex-col"
          />
          <Label
            text="Dialing Code"
            assignText="+93"
            extraClass="flex flex-col"
          />
        </div>
      </div> */}
      <nav className="flex flex-col w-full my-4 p-4 bg-white gap-2 shadow-lg">
        {navLinks.map((navLink) => {
          return (
            <Link
              to={navLink.path}
              key={navLink.path}
              className={`capitalize h-10 w-full flex items-center justify-center rounded-md ${
                navLink.path === section ? "text-white" : "bg-white shadow-md"
              }`}
              onClick={() => setIsMap(false)}
              style={{
                backgroundColor: navLink.path === section ? color : "#ffffff",
              }}
            >
              {navLink.label}
            </Link>
          );
        })}
      </nav>
    </div>
  );
}

export default CountryProfileSidebar;
