import React, { SetStateAction } from "react";
import LocationSVG from "../../../../assets/icons/location-pin-svg.svg";
import PolygonIcon from "../../../../assets/icons/draw-on-map-lines.svg";
import PanToolIcon from "@mui/icons-material/PanTool";
type DrawingMode = "marker" | "polygon" | "polyline" | "circle" | "text" | null;

type DrawBtn = {
  drawingMode: DrawingMode;
  disableDrawing: boolean;
  drawingManager: google.maps.drawing.DrawingManager | undefined;
  setDrawingMode: React.Dispatch<SetStateAction<DrawingMode>>;
};

export const DrawCircleBtn = ({
  drawingMode,
  disableDrawing,
  drawingManager,
  setDrawingMode,
}: DrawBtn) => {
  return (
    <button
      className={`h-10 w-10 rounded-md shadow-md  flex items-center justify-center ${
        drawingMode === "circle" ? "border-2 border-blue" : ""
      } ${disableDrawing ? "cursor-not-allowed" : "cursor-pointer"}`}
      type="button"
      onClick={() => {
        drawingManager?.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
        setDrawingMode("circle");
      }}
      disabled={disableDrawing}
    >
      <div className="h-4 w-4 rounded-full border-2"></div>
    </button>
  );
};

export const DrawPolygonBtn = ({
  drawingMode,
  disableDrawing,
  drawingManager,
  setDrawingMode,
}: DrawBtn) => {
  return (
    <button
      className={`h-10 w-10 rounded-md shadow-md flex items-center justify-center ${
        drawingMode === "polygon" ? "border-2 border-blue" : ""
      } ${disableDrawing ? "cursor-not-allowed" : "cursor-pointer"}`}
      type="button"
      onClick={() => {
        drawingManager?.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
        setDrawingMode("polygon");
      }}
      disabled={disableDrawing}
    >
      <img src={PolygonIcon} alt="" />
    </button>
  );
};
export const DrawPolylineBtn = ({
  drawingMode,
  disableDrawing,
  drawingManager,
  setDrawingMode,
}: DrawBtn) => {
  return (
    <button
      className={`h-10 w-10 rounded-md shadow-md flex items-center justify-center ${
        drawingMode === "polyline" ? "border-2 border-blue" : ""
      } ${disableDrawing ? "cursor-not-allowed" : "cursor-pointer"}`}
      type="button"
      onClick={() => {
        drawingManager?.setDrawingMode(
          google.maps.drawing.OverlayType.POLYLINE
        );
        setDrawingMode("polyline");
      }}
      disabled={disableDrawing}
    >
      <div className="w-3/5 h-[2px] bg-grey rotate-45"></div>
    </button>
  );
};
export const PanBtn = ({
  drawingMode,
  disableDrawing,
  drawingManager,
  setDrawingMode,
}: DrawBtn) => {
  return (
    <button
      className={`h-10 w-10 rounded-md shadow-md flex items-center justify-center ${
        drawingMode === null ? "border-2 border-blue" : ""
      }`}
      type="button"
      onClick={() => {
        drawingManager?.setDrawingMode(null);
        setDrawingMode(null);
      }}
      disabled={disableDrawing}
    >
      <PanToolIcon />
    </button>
  );
};

export const DrawTextBtn = ({
  drawingMode,
  disableDrawing,
  drawingManager,
  setDrawingMode,
}: DrawBtn) => {
  return (
    <button
      className={`h-10 w-10 rounded-md shadow-md flex items-center justify-center ${
        drawingMode === "text" ? "border-2 border-blue" : ""
      } ${disableDrawing ? "cursor-not-allowed" : "cursor-pointer"}`}
      type="button"
      onClick={() => {
        setDrawingMode("text");
        drawingManager?.setDrawingMode(google.maps.drawing.OverlayType.MARKER);
      }}
      disabled={disableDrawing}
    >
      <span>T</span>
    </button>
  );
};

export const DrawMarkerBtn = ({
  drawingMode,
  disableDrawing,
  drawingManager,
  setDrawingMode,
}: DrawBtn) => {
  return (
    <button
      className={`h-10 w-10 rounded-md shadow-md flex items-center justify-center ${
        drawingMode === "marker" ? "border-2 border-blue" : ""
      } ${disableDrawing ? "cursor-not-allowed" : "cursor-pointer"}`}
      type="button"
      onClick={() => {
        setDrawingMode("marker");
        drawingManager?.setDrawingMode(google.maps.drawing.OverlayType.MARKER);
      }}
      disabled={disableDrawing}
    >
      <img src={LocationSVG} alt="" />
    </button>
  );
};
