import React, { useContext } from "react";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { FormDataContext } from "../../context/form";
function LastName() {
  const { data } = useContext(UserProfileContext)!;
  const userData: UserDataTypes = data?.data;

  const formDataContext = useContext(FormDataContext)!;
  const { lastNameRef } = formDataContext;
  return (
    <div className="">
      <label htmlFor="last_name" className="opacity-80">
        Last Name
      </label>
      <input
        required
        ref={lastNameRef}
        name={"last-name"}
        placeholder="Enter Last Name"
        defaultValue={userData.profile?.lastName}
        className="h-10 w-full shadow-md rounded-md px-4"
      />
    </div>
  );
}

export default LastName;
