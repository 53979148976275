import React, { ReactElement, useContext } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Map from "./Map";
import LoadingSpinner from "../../../../../../components/ui/LoadingSpinner";
import { useWeeklyIncidents } from "../../../../services";

function MapApp() {
  const googleMapsApiKey: string = process.env.REACT_APP_GOOGLE_MAP_API_KEY!;
  const weeklyIncidents = useWeeklyIncidents();
  const {
    data: incidents,
    hasNextPage: incidentsHasNextPage,
    isLoading: incidentsLoading,
  } = weeklyIncidents;
  const Error = () => <h1>Failed to load map.</h1>;

  const render = (status: Status): ReactElement => {
    if (status === Status.FAILURE) return <Error />;
    if (incidentsHasNextPage) {
      return (
        <div className="h-full w-full flex justify-center items-center">
          <LoadingSpinner />
        </div>
      );
    }
    return (
      <div className="h-full w-full flex justify-center items-center">
        <LoadingSpinner />
      </div>
    );
  };
  return (
    <div className="h-full w-full">
      <Wrapper
        apiKey={googleMapsApiKey}
        render={render}
        libraries={["drawing", "places"]}
      >
        {incidentsHasNextPage ? (
          <div className="h-full w-full flex justify-center items-center">
            <LoadingSpinner />
          </div>
        ) : (
          <Map zoom={2} incidents={incidents} />
        )}
      </Wrapper>
    </div>
  );
}

export default MapApp;
