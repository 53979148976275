import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import AddIcon from "../../../../../../assets/icons/add-icon.svg";
import Row from "../../components/row";

import useDebounce from "../../../../../../hooks/useDebounce";
import { ClientDataTypes } from "../../../../../../types/client-data-types";
import { BlueButton } from "../../../../../../components/ui/Buttons";
import ClientsListHeader from "../../components/client-list-header";
import { PaginatedClientsContext } from "../../../../context/clients";
import PaginatedComponent from "../../../../../../components/ui/PaginatedComponent";
function Main() {
  const clientsContext = useContext(PaginatedClientsContext)!;
  const {
    allClients,
    setPage,
    page,
    searchClientByName,
    setSearchClientByName,
  } = clientsContext!;

  const [clientsList, setClientsList] = useState<ClientDataTypes[] | null>();

  const [searchParams, setSearchParams] = useSearchParams()
  const [value, setValue] = useDebounce(500, searchParams.get("search"));

  const [newClientReqFullfilled, setNewClientReqFullfilled] =
    useState<boolean>();


  useEffect(() => {
    setSearchClientByName(value);
    const items = clientsContext.allClients.data?.data.items;
    setClientsList(items);
  }, [allClients.isFetching, value]);

 
  useEffect(() => {
    if(value) setSearchParams({page: `${page}`, search: value})
    else setSearchParams({page: `${page}`})
  }, [value, page])

  return (
    <div className="bg-white w-full shadow-lg p-16 flex flex-col gap-8 h-fit">
      <div className="flex justify-between items-center border-b pb-8">
        <p className="text-lg font-semibold">Clients</p>

        <div className="flex items-center gap-14">
          <input
            type="text"
            className="h-10 w-52 rounded-md shadow-md px-4 outline-none"
            placeholder="Search for a Client"
            defaultValue={value}
            onChange={(e) => {
              setPage(1)
              setValue(e.target.value)
            }}
          />
          <Link to="add-new-client" className="w-[130px]">
            <BlueButton text="Add Client" icon={AddIcon} />
          </Link>
        </div>
      </div>

      <ClientsListHeader />

      <PaginatedComponent page={page} setPage={setPage} query={allClients}>
        <div className="h-fit">
          {clientsList?.map((client: ClientDataTypes) => (
            <Row key={client.id} client={client} />
          ))}
        </div>
      </PaginatedComponent>
    </div>
  );
}

export default Main;
