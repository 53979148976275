import React from "react";
import { Outlet } from "react-router-dom";
import FormsContextProvider from "../../features/sites/context/forms-context";
function SitesOutlet() {
  return (
    <FormsContextProvider>
      <div className="h-full w-full flex flex-1 mt-28 relative">
        <Outlet />
      </div>
    </FormsContextProvider>
  );
}

export default SitesOutlet;
