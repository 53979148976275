import React, {useEffect, useRef, useState} from "react";
import {useOperationalMapContext} from "../../providers/OperationalMapProvider";
import FilterIcon from "../../assets/icons/filter-icon.svg";
import PersonIcon from "../../assets/icons/icon-person-dark.svg";
import {useMap, useMapsLibrary} from "@vis.gl/react-google-maps";

export const MapToolbar: React.FC = () => {
  const ctx = useOperationalMapContext();
  const placesLib = useMapsLibrary("places");

  const searchRef = useRef<HTMLInputElement>(null);
  const setMapTypeID = (id: string) => {
    return () => {
      ctx.setMapTypeID(id)
    }
  }

  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox|null>(null);

  useEffect(() => {
    if(!placesLib) return;
    if(!searchRef.current) return;

    const sb = new google.maps.places.SearchBox(searchRef.current);
    setSearchBox(sb)



  }, [placesLib, searchRef.current])

  useEffect(() => {
    if(!searchBox) return;
    if(!ctx.bounds) return;

    searchBox.setBounds(ctx.bounds);
  }, [ctx.bounds])


  useEffect(() => {
    if(!searchBox) return;

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if(!places) return;
      if(places.length === 0) return;

      const firstPlace = places[0];

      if (firstPlace.geometry && firstPlace.geometry.location) {
        ctx.setCenter({
          lat: firstPlace.geometry.location.lat(),
          lng: firstPlace.geometry.location.lng(),
        });
        ctx.setZoom(15); // Adjust zoom level as needed
      }

      // if (places.length === 0) {
      //   return;
      // }
    })

  }, [searchBox])


  return <>
    <div className="absolute top-2 left-4 right-16 flex gap-4 z-[90]">
      <Button image={FilterIcon} onClick={() => ctx.setShowFilters(true)}/>
      <Button image={PersonIcon} onClick={() => ctx.setShowTrackingMenu(true)}/>
      <div className={"flex-1"}/>
      <input
        ref={searchRef}
        className={"bg-white h-12 flex items-center justify-center pointer-events-auto rounded-md shadow-lg px-4"}
        type={"text"} placeholder={"Search location..."}/>

    </div>
    <div className="absolute bottom-6 left-4 right-16 flex gap-4 z-[90] justify-center">
      <div className={"grid grid-cols-4 bg-white h-12 gap-8 rounded-md shadow-lg px-4"}>
        <button className={!ctx.mapTypeID || ctx.mapTypeID == "roadmap" ? "font-bold" : ""} onClick={setMapTypeID("roadmap")}>Map</button>
        <button className={ctx.mapTypeID == "terrain" ? "font-bold" : ""} onClick={setMapTypeID("terrain")}>Terrain</button>
        <button className={ctx.mapTypeID == "hybrid" ? "font-bold" : ""} onClick={setMapTypeID("hybrid")}>Hybrid</button>
        <button className={ctx.mapTypeID == "satellite" ? "font-bold" : ""} onClick={setMapTypeID("satellite")}>Satellite</button>
      </div>
    </div>

  </>
}

type ButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
  image: string;
}

const Button: React.FC<ButtonProps> = (props) => {

  const onClick = () => {
    if (props.disabled || !props.onClick) {
      return
    }
    props.onClick();
  }

  return (
    <button
      className={`bg-white  h-12 w-12 flex items-center justify-center pointer-events-auto rounded-md shadow-lg ${props.disabled ? "opacity-70" : ""}`}
      onClick={onClick}
    >
      <img src={props.image} alt="" className="pointer-events-none"/>
    </button>
  );
};