import React from "react";
import Row from "../../components/Row";
import { tableHeader } from "../../tableHeader";
import LoadingSpinner from "../../../../../../components/ui/LoadingSpinner";
import { OverlaysDataTypes } from "../../../../types";
import { useCustomOverlays } from "../../../../services";
import { useNavigate } from "react-router-dom";
import Table from "../../components/Table";
import { BlueButton } from "../../../../../../components/ui/Buttons";

function Main() {
  const { data: overlays, isLoading } = useCustomOverlays().overlaysQuery;
  const navigate = useNavigate();
  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="h-full w-full relative">
      <header className="flex justify-between mx-12 pb-4 ">
        <p className="flex gap-6 flex-1">
          <span
            className="opacity-50 cursor-pointer"
            onClick={() => navigate("/sites")}
          >
            Sites
          </span>
          <span className="opacity-50">{">"}</span>
          <span>Custom Overlays</span>
        </p>
        <div className="w-56">
          <BlueButton
            text="+ Add New Custom Overlay"
            onClick={() => navigate("/sites/add-custom-overlay")}
          />
        </div>
      </header>

      <div className="px-12 py-6 bg-white">
        <Table tableHeader={tableHeader} />
      </div>
    </div>
  );
}

export default Main;
