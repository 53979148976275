import React, { useContext } from "react";
import InputText from "../../../../../../components/form/InputText";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { FormDataContext } from "../../context/form";
function Allergies() {
  const { data } = useContext(UserProfileContext)!;
  const userData: UserDataTypes = data?.data;
  const formDataContext = useContext(FormDataContext)!;
  const { allergiesRef } = formDataContext;
  return (
    <div className="">
      <label htmlFor="allergies" className="opacity-80">
        Allergies
      </label>
      <InputText
        name={"allergies"}
        placeholder="Enter Allergies"
        onChange={() => {}}
        defaultValue={
          userData.medicalInformation?.allergies
            ? userData.medicalInformation?.allergies
            : ""
        }
        inputRef={allergiesRef}
      />
    </div>
  );
}

export default Allergies;
