import React, { useContext } from "react";
import InputText from "../../../../../../components/form/InputText";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { FormDataContext } from "../../context/form";
import {CountrySelectV2} from "../../../../../../components/form/CountriesSelect";

type PassportCountryProps = {
    index: number
    value: string
    onChange: (value: string) => void
}
const PassportCountry: React.FC<PassportCountryProps> = ({ index, value, onChange }) => {
  const { data } = useContext(UserProfileContext)!;
  const userData: UserDataTypes = data?.data;
  const formDataContext = useContext(FormDataContext)!;
  const { passportNumberRef } = formDataContext;
  return (
    <div className="">
      <label className="opacity-80">
        Passport Country
      </label>
      <CountrySelectV2 value={value} onChange={onChange}/>
    </div>
  );
}

export default PassportCountry;
