import { RefObject, useEffect, useState } from "react";
import useUserActivity from "../../../services/useUserActivity";
import { UserActivityDataTypes } from "../../../types/user-activity-data-types";

const emergencyTypes = [
  "alerts",
  "geofences",
  "check_in_missed",
  "safety_zone",
  "message",
  "silent_alarm",
  "emergency_alarm",
];

export default function useAlarm(audioRef: RefObject<HTMLAudioElement>) {
  const [alarm, setAlarm] = useState<boolean>(false);
  const [playing, setPlaying] = useState<boolean>(false);
  const { data, activeArr } = useUserActivity();

  /**
   *
   * @param userActivity NEW USER ACTIVITY
   * if there is a new emergency then it sets the alarm to true
   */
  const checkAlarm = (userActivity: UserActivityDataTypes[]) => {
    const emergency = userActivity?.filter((activity: UserActivityDataTypes) =>
      emergencyTypes.includes(activity.type)
    );
    if (emergency.length > 0) {
      setAlarm(true);
    }
  };

  useEffect(() => {
    const active = data?.active;
    if (!active) return;
    const emergency = active?.filter((activity: UserActivityDataTypes) =>
      emergencyTypes.includes(activity.type)
    );
    if (emergency.length === 0) {
      setAlarm(false);
      return;
    }
    if (active) {
      checkAlarm(active);
    }
  }, [activeArr, data]);

  useEffect(() => {
    const activeUserActivity = data?.active;
    if (activeUserActivity) {
      checkAlarm(activeUserActivity);
    }
  }, []);

  const play = () => {
    setPlaying(true);
    audioRef.current?.play();
  };

  const pause = () => {
    setPlaying(false);
    audioRef.current?.pause();
  };

  useEffect(() => {
    if (alarm) {
      play();
    } else {
      pause();
    }
  }, [alarm]);

  return { alarm, setAlarm };
}
