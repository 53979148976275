import {
  Clients,
  Departments,
  Roles,
  UserActivityLogs,
  Users,
} from "../types/userManagement";
import { objectToQueryString } from "../util/helpers";
import api from "./axiosConfig";

// Clients
export const getClients = async (
  search: string,
  types: string[],
  page = 1
): Promise<Clients> => {
  const response = await api.get(
    `/clients?search=${search}&types=${[...types]}&ipp=15&page=${page}`
  );
  return response.data;
};

export const getClient = async (id: string): Promise<Clients> => {
  const response = await api.get(`/clients?id=${id}`);
  return response.data.clients[0];
};

export const addClient = async (client: Clients): Promise<void> => {
  return await api.post(`/clients?${objectToQueryString(client)}`, {});
};

// Users
export const getUsers = async (): Promise<Users[]> => {
  const response = await api.get("/users");
  return response.data.users;
};

export const getUser = async (id: string): Promise<Users> => {
  const response = await api.get(`/users?id=${id}`);
  return response.data.users[0];
};

export const addUsers = async (user: Users): Promise<void> => {
  return await api.post(`/users?${objectToQueryString(user)}`, {});
};

// Departments
export const getDepartments = async (): Promise<Departments[]> => {
  const response = await api.get("/departments");
  return response.data.clients;
};

export const getDepartment = async (id: string): Promise<Departments> => {
  const response = await api.get(`/departments?id=${id}`);
  return response.data.clients[0];
};

export const addDepartment = async (department: Departments): Promise<void> => {
  return await api.post(`/departments?${objectToQueryString(department)}`, {});
};

// Roles
export const getRoles = async (): Promise<Roles[]> => {
  const response = await api.get("/roles");
  return response.data.roles;
};

export const addRole = async (role: Roles): Promise<void> => {
  return await api.post("/roles", {
    role,
  });
};

export const updateRole = async (id: string, role: Roles): Promise<void> => {
  return await api.put(`/roles/${id}`, { role });
};

export const deleteRole = async (id: string): Promise<void> => {
  return await api.delete(`/roles/${id}`);
};
