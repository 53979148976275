import { useQuery } from "react-query";
import api from "../axiosConfig";

export default function usePaginatedGetClientUsers(
  page: number,
  searchByName: string,
  clientID: string
) {
  const fetchClientUsers = async (page: number, searchByName: string) => {
    if (searchByName === "") {
      return await api
        .get(`/users?page=${page}`, { params: { clientID: clientID } })
        .then((res) => res)
        .catch((err) => console.error(err));
    } else {
      return await api
        .get(`/users?page=${page}`, {
          params: { search: searchByName, clientID: clientID },
        })
        .then((res) => res)
        .catch((err) => console.error(err));
    }
  };

  return useQuery({
    queryKey: ["client-users", page, searchByName],
    queryFn: () => fetchClientUsers(page, searchByName),
    keepPreviousData: true,
  });
}
