import React from "react";
import { MainAddCustomOverlay } from "../../../features/sites/pages/add-custom-overlay";

function AddCustomOverlay() {
  return (
    <div className="w-full h-full">
      <MainAddCustomOverlay />
    </div>
  );
}

export default AddCustomOverlay;
