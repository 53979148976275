import React from "react";
import { ButtonDownload } from "../../../components/ui/Buttons";

export default function AnnualSummaryTable({
  showDownloadButton = true,
  showMonthes = true,
}: {
  showDownloadButton?: boolean;
  showMonthes?: boolean;
}) {
  const monthes = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // create an array of string and fill it with random twelve numbers
  const randomNumbers = Array.from({ length: 12 }, () =>
    Math.floor(Math.random() * 1000)
  );
  return (
    <div>
      {showMonthes && (
        <div className="flex pl-[100px]">
          <div className="rounded-2xl bg-desaturated-grey py-3 grid grid-cols-12 w-full items-center h-full">
            {monthes.map((month, index) => {
              return (
                <div
                  key={index}
                  className="font-bold border-r-1 text-center h-full flex items-center justify-center last:border-0"
                >
                  {month}
                </div>
              );
            })}
          </div>
          <div className="ml-2 rounded-2xl py-3 px-10 bg-grey text-white">
            Total
          </div>
        </div>
      )}
      <div className="flex flex-col items-center">
        {showDownloadButton && (
          <div className="flex items-center mt-3">
            <span className="mr-4 font-bold">Mass Warning</span>
            <ButtonDownload onClick={() => {}} blue />
          </div>
        )}
        <div className="w-full mt-3 flex">
          {/* title column*/}
          <div className="rounded-2xl bg-desaturated-grey px-2 flex flex-col items-center">
            <div className="border-b-1 text-center h-full flex items-center justify-center py-2 px-4 w-full">
              Sent
            </div>
            <div className="border-b-1 text-center h-full flex items-center justify-center py-2 px-4 w-full">
              App
            </div>
            <div className="border-b-1 text-center h-full flex items-center justify-center py-2 px-4 w-full">
              SMS
            </div>
            <div className="border-b-1 text-center h-full flex items-center justify-center py-2 px-4 w-full">
              Emails
            </div>
            <div className="text-center h-full flex items-center justify-center py-2 px-4 w-full">
              Calls
            </div>
          </div>
          <div className="flex flex-col w-full">
            <div className="grid grid-cols-12 w-full">
              {randomNumbers.map((number, index) => {
                return (
                  <div
                    key={index}
                    className="text-center h-full flex items-center justify-center last:border-0 w-full py-2"
                  >
                    {number}
                  </div>
                );
              })}
            </div>
            <div className="grid grid-cols-12 w-full">
              {randomNumbers.map((number, index) => {
                return (
                  <div
                    key={index}
                    className="text-center h-full flex items-center justify-center last:border-0 w-full py-2"
                  >
                    {number}
                  </div>
                );
              })}
            </div>
            <div className="grid grid-cols-12 w-full">
              {randomNumbers.map((number, index) => {
                return (
                  <div
                    key={index}
                    className="text-center h-full flex items-center justify-center last:border-0 w-full py-2"
                  >
                    {number}
                  </div>
                );
              })}
            </div>
            <div className="grid grid-cols-12 w-full">
              {randomNumbers.map((number, index) => {
                return (
                  <div
                    key={index}
                    className="text-center h-full flex items-center justify-center last:border-0 w-full py-2"
                  >
                    {number}
                  </div>
                );
              })}
            </div>
            <div className="grid grid-cols-12 w-full">
              {randomNumbers.map((number, index) => {
                return (
                  <div
                    key={index}
                    className="text-center h-full flex items-center justify-center last:border-0 w-full py-2"
                  >
                    {number}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col bg-grey rounded-2xl text-white h-fit ml-2">
            <div className="w-full flex items-center justify-center px-10 py-2">
              276
            </div>
            <div className="w-full flex items-center justify-center px-10 py-2">
              276
            </div>
            <div className="w-full flex items-center justify-center px-10 py-2">
              276
            </div>
            <div className="w-full flex items-center justify-center px-10 py-2">
              276
            </div>
            <div className="w-full flex items-center justify-center px-10 py-2">
              276
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
