import React from "react";
import SavedCountries from "../../components/SavedCountries";
import Key from "../../components/Key";
function CountryProfilesSidebar() {
  return (
    <div className="flex max-w-md w-full flex-col gap-6">
      <SavedCountries />
      <Key />
    </div>
  );
}

export default CountryProfilesSidebar;
