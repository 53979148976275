import React, { useContext, useState } from "react";
import FilterIcon from "../../../../../assets/icons/filter-icon.svg";
import FilterIconBlue from "../../../../../assets/icons/filter-icon-blue.svg";
import ClientsDropdown from "./ClientsDropdown";
import AllUsersDropdown from "./AllUsersDropdown";
import TripDatesDropdown from "./TripDatesDropdown";
import CountriesDropdown from "./CountriesDropdown";
import { UserContext } from "../../../../../context/user";
import ClientUsersDropdown from "./ClientUsersDropdown";
import { TripsContext } from "../../../context/trips";

type TripHeadingProps = {
}
const TripHeading: React.FC<TripHeadingProps> = () =>{
  const userContext = useContext(UserContext);
  const { tripsFilters } = useContext(TripsContext);

  const [filterBoxToShow, setFilterBoxToShow] = useState<string>("");
  const [clientSelectedOption, setClientSelectedOption] =
    useState<string>("All Clients");
  const [usersSelectedOptions, setUsersSelectedOptions] = useState<string[]>(
    []
  );

  const toggleFilterBox = (filter: string) => {
    if (filterBoxToShow === filter) {
      setFilterBoxToShow("");
    } else {
      setFilterBoxToShow(filter);
    }
  };

  const handleClientOptionChange = (client: string) => {
    setClientSelectedOption(client);
    setUsersSelectedOptions([]);
  };

  let cols = 7
  if(userContext?.role.auroraAccessLevel == "client"){
    cols = cols - 1
  }

  if(userContext?.role.auroraAccessLevel == "department"){
    cols = cols - 2
  }

  if(userContext?.role.auroraAccessLevel == "user"){
    cols = cols - 3
  }

  return (
      <div className={`rounded-t-lg bg-desaturated-grey py-3 grid grid-cols-${cols} w-full items-center h-full`}>
        {["all"].includes(userContext?.role.auroraAccessLevel || "") && <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
          Client
        </div>}
        {["all", "client"].includes(userContext?.role.auroraAccessLevel || "") && <div className="font-bold border-r-1 text-center h-full flex items-center justify-center">
          Department
        </div>}
        {["all", "client", "department"].includes(userContext?.role.auroraAccessLevel || "") && <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
          Traveller
        </div>}
        <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
          Start Date
        </div>

        <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
          End Date
        </div>
        <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
          Countries
        </div>


        <div className="font-bold text-center px-6 h-full flex items-center justify-center">Actions</div>

      </div>
  );
}

export default TripHeading