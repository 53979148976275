import { useEffect, useState } from "react";
import { IncidentDataTypes } from "../../../../../../types/incident-data-types";
import api from "../../../../../../api/axiosConfig";
import { useQuery } from "react-query";

export default function useAllIncidents(params: {} = {}, page: number) {
  const [allIncidents, setAllIncidents] = useState<IncidentDataTypes[]>([]);
  const fetchIncidents = async (page = 1) => {
    const response = await api.get(`/incidents?page=${page}`, { params });
    return response.data;
  };

  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    isPreviousData,
    status,
  } = useQuery(["all-incidents-paginated", page, params], () => fetchIncidents(page), {
    keepPreviousData: true,
  });

  useEffect(() => {
    console.log(data);
  }, [data]);

  return {
    incidents: data ? data.items : null,
    total: data ? data.total : 0,
    isLoading,
    isError,
    error,
    data,
    isFetching,
    isPreviousData,
    status,
  };
}
