import {PolygonLocation} from "../sites/types";

function toRadians(degrees: number): number {
  return degrees * (Math.PI / 180);
}

type Coords = [number, number];

export function calculateBearing(coord1: Coords, coord2: Coords) {
  const [lon1, lat1] = coord1;
  const [lon2, lat2] = coord2;

  // Convert latitudes and longitudes from degrees to radians
  const lat1Rad = toRadians(lat1);
  const lat2Rad = toRadians(lat2);
  const lon1Rad = toRadians(lon1);
  const lon2Rad = toRadians(lon2);

  // Calculate the differences in longitude
  const deltaLon = lon2Rad - lon1Rad;

  // Calculate the bearing using the formula
  const y = Math.sin(deltaLon) * Math.cos(lat2Rad);
  const x = Math.cos(lat1Rad) * Math.sin(lat2Rad) -
    Math.sin(lat1Rad) * Math.cos(lat2Rad) * Math.cos(deltaLon);

  let bearing = Math.atan2(y, x);

  // Convert bearing from radians to degrees
  bearing = bearing * (180 / Math.PI);

  // Normalize the bearing to be between 0 and 360 degrees
  bearing = (bearing + 360) % 360;

  return bearing;
}

export function getPolygonCenter(loc: PolygonLocation) {
  const coordinates = loc.coordinates[0]; // Access the polygon's coordinates (first ring)
  let totalLat = 0;
  let totalLng = 0;
  const numPoints = coordinates.length;

  coordinates.forEach(([lng, lat]) => {
    totalLat += lat;
    totalLng += lng;
  });

  const centerLat = totalLat / numPoints;
  const centerLng = totalLng / numPoints;

  return {
    lat: centerLat,
    lng: centerLng
  };
}

export function getCoordinatesCenter(coordinates: [number, number][]): {lat: number, lng: number} {
  let totalLat = 0;
  let totalLng = 0;
  const numPoints = coordinates.length;

  coordinates.forEach(([lng, lat]) => {
    totalLat += lat;
    totalLng += lng;
  });

  const centerLat = totalLat / numPoints;
  const centerLng = totalLng / numPoints;

  return {
    lat: centerLat,
    lng: centerLng
  };
}


interface GeoPoint {
  lat: number;
  lng: number;
}

const haversineDistance = (p1: GeoPoint, p2: GeoPoint): number => {
  const R = 6371000; // Radius of the Earth in meters
  const toRad = (value: number) => (value * Math.PI) / 180;

  const dLat = toRad(p2.lat - p1.lat);
  const dLon = toRad(p2.lng - p1.lng);
  const lat1 = toRad(p1.lat);
  const lat2 = toRad(p2.lat);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};

export type Cluster<T> = {
  items: T[];        // Items in the cluster
  center: GeoPoint;  // Center point (centroid) of the cluster
}

export type ClusterResult<T> = {
  clusters: Cluster<T>[];  // Clusters with their center points
  noise: T[];              // Items classified as noise
}

type GetPointFunc<T> = (item: T) => GeoPoint

/**
 * Calculates the centroid of a list of geographical points (latitude, longitude).
 * @param points - Array of GeoPoint objects.
 * @returns The GeoPoint representing the centroid.
 */
const calculateCentroid = (points: GeoPoint[]): GeoPoint => {
  const total = points.length;

  if (total === 0) {
    throw new Error("Cannot calculate centroid of an empty set of points.");
  }

  const sum = points.reduce(
    (acc, point) => {
      acc.lat += point.lat;
      acc.lng += point.lng;
      return acc;
    },
    { lat: 0, lng: 0 }
  );

  return {
    lat: sum.lat / total,
    lng: sum.lng / total
  };
};
export const dbscanGeo = <T>(
  items: T[],
  epsilon: number,
  minPoints: number,
  getPoint: GetPointFunc<T>
): ClusterResult<T> => {
  const clusters: Cluster<T>[] = [];
  const noise: T[] = [];
  const visited = new Set<number>();
  const clusterAssignment = new Map<number, number>();

  const regionQuery = (pointIdx: number): number[] => {
    const neighbors: number[] = [];
    const point = getPoint(items[pointIdx]);
    for (let i = 0; i < items.length; i++) {
      const neighborPoint = getPoint(items[i]);
      if (haversineDistance(point, neighborPoint) <= epsilon) {
        neighbors.push(i);
      }
    }
    return neighbors;
  };

  const expandCluster = (pointIdx: number, neighbors: number[], clusterIdx: number) => {
    const clusterItems: T[] = [];
    const clusterPoints: GeoPoint[] = [];

    const addPointToCluster = (idx: number) => {
      const item = items[idx];
      clusterItems.push(item);
      clusterPoints.push(getPoint(item));
    };

    addPointToCluster(pointIdx);
    clusterAssignment.set(pointIdx, clusterIdx);

    let i = 0;
    while (i < neighbors.length) {
      const neighborIdx = neighbors[i];
      if (!visited.has(neighborIdx)) {
        visited.add(neighborIdx);
        const neighborNeighbors = regionQuery(neighborIdx);
        if (neighborNeighbors.length >= minPoints) {
          neighbors = neighbors.concat(neighborNeighbors);
        }
      }
      if (!clusterAssignment.has(neighborIdx)) {
        addPointToCluster(neighborIdx);
        clusterAssignment.set(neighborIdx, clusterIdx);
      }
      i++;
    }

    const center = calculateCentroid(clusterPoints);
    clusters.push({ items: clusterItems, center });
  };

  // Iterate over each item and attempt to form clusters
  for (let i = 0; i < items.length; i++) {
    if (visited.has(i)) {
      continue;
    }
    visited.add(i);
    const neighbors = regionQuery(i);
    if (neighbors.length < minPoints) {
      noise.push(items[i]);
    } else {
      expandCluster(i, neighbors, clusters.length);
    }
  }

  return { clusters, noise };
};

export const zoomLevelToEpsilon: Record<number, number> = {
  1: 800000,  // Whole world
  2: 400000,
  3: 200000,
  4: 100000,
  5: 50000,   // Continent level
  6: 25000,
  7: 12000,    // Large country/region level
  8: 6000,
  9: 3000,
  10: 1500,    // State/province level
  11: 800,
  12: 400,    // City level
  13: 200,     // District level
  14: 100,     // Neighborhood level
  15: 0,     // Block level
  16: 0,      // Street level
  17: 0,      // Detailed street level
  18: 0,      // Very detailed street/building level
  19: 0,       // Building level
  20: 0        // Very close, almost within a single building
};

export function getEpsilon(zoom : number) {
  if(zoomLevelToEpsilon[zoom]){
    return  zoomLevelToEpsilon[zoom]
  }
  return 0
}