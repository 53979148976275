import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormsContext } from "../../../../context/forms-context";
import { BlueButton } from "../../../../../../components/ui/Buttons";
import Table from "../../components/Table";
import { tableHeader } from "../../tableHeader";
import Modal from "../../../../../../components/portal/Modal";
import AddFacility from "../../../../components/add-facility";
import { Done } from "@mui/icons-material";
import AddSiteBasedAlert from "../../../../components/add-site-based-alerts";
import ImportFacilitiesBtn from "../../../../components/buttons/import-facilities-btn";
import DownloadImportFacilitiesSample from "../../../../components/buttons/download-import-facilities-sample-file";
import ExportFacilities from "../../../../components/buttons/export-facilities";
import useFacilities from "../../../../../../services/useFacilities";
import { useQueryClient } from "react-query";

function Main() {
  const navigate = useNavigate();
  const {
    addIndividualFacility,
    setAddIndividualFacility,
    siteBasedAlertModal,
    setSiteBasedAlertModal,
  } = useContext(FormsContext)!;
  const [successfullyAddedSBA, setSuccessfullyAddedSBA] =
    useState<boolean>(false);
  const [successfullyAddedFacility, setSuccessfullyAddedFacility] =
    useState<boolean>(false);

  const queryClient = useQueryClient();

  useEffect(() => {
    setSuccessfullyAddedFacility(false)
    queryClient.invalidateQueries("facilities");
  }, [successfullyAddedFacility])



  const { facilities: data, isLoading } = useFacilities("facilities", {
    rpp: 200,
  });


  return (
    <div className="h-full w-full relative">
      <header className="flex justify-between mx-12 pb-4 ">
        <p className="flex gap-6 flex-1">
          <span
            className="opacity-50 cursor-pointer"
            onClick={() => navigate("/sites")}
          >
            Sites
          </span>
          <span className="opacity-50">{">"}</span>
          <span>Facilities</span>
        </p>
        <div className="flex flex-row gap-4">
          <div className="w-48">
            <ImportFacilitiesBtn />
          </div>
          <div className="w-48 flex items-center justify-center">
            <DownloadImportFacilitiesSample />
          </div>
          <div className="w-48 flex items-center justify-center">
            <ExportFacilities />
          </div>
          <div className="w-48">
            <BlueButton
              text="+ Add New Facility"
              onClick={() => setAddIndividualFacility(true)}
            />
          </div>
        </div>
      </header>

      <div className="px-12 py-6 bg-white">
        <Table tableHeader={tableHeader} data={data} isLoading={isLoading}/>
      </div>

      <Modal open={addIndividualFacility} setOpen={setAddIndividualFacility}>
        <AddFacility
          addIndividualFacility={addIndividualFacility}
          setAddIndividualFacility={setAddIndividualFacility}
          setSiteBasedAlertModal={setSiteBasedAlertModal}
          siteBasedAlertModal={siteBasedAlertModal}
          setSuccessfullyAddedFacility={setSuccessfullyAddedFacility}
        />
      </Modal>
      <Modal open={successfullyAddedSBA}>
        <div className="bg-white p-8 flex items-center gap-8 text-xl rounded-md shadow-md">
          <div className="p-4 bg-blue w-fit rounded-full">
            <Done className="text-white" />
          </div>
          <span>Successfull added site based alert.</span>
        </div>
      </Modal>
      <Modal open={successfullyAddedFacility}>
        <div className="bg-white p-8 flex items-center gap-8 text-xl rounded-md shadow-md">
          <div className="p-4 bg-blue w-fit rounded-full">
            <Done className="text-white" />
          </div>
          <span>Successfull added facility.</span>
        </div>
      </Modal>
      <Modal open={siteBasedAlertModal} setOpen={setSiteBasedAlertModal}>
        <AddSiteBasedAlert
          setSiteBasedAlertModal={setSiteBasedAlertModal}
          setSuccessfullyAddedSBA={setSuccessfullyAddedSBA}
        />
      </Modal>
    </div>
  );
}

export default Main;
