import React, { useContext } from "react";
import SummarySearchBox from "./SummarySearchBox";
import SummaryReport from "./SummaryReport";
import { ReportsSummaryContext } from "../context/ReportsSummaryProvider";
import { Oval } from "react-loader-spinner";
import { ButtonBorderTransparent } from "../../../../../components/ui/Buttons";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../context/user";

const summaryReportData = [
  {
    title: "Users",
    value: "16501",
  },
  {
    title: "Checkin",
    value: "118",
  },
  {
    title: "Tracking",
    value: "24",
  },
  {
    title: "Emergency",
    value: "3",
  },
];

export default function SummaryPage() {
  const { firstSearch, csvLoading } = useContext(ReportsSummaryContext);
  const userContext = useContext(UserContext);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/messages");
  };

  return (
    <div className="bg-white w-full shadow-lg h-full py-10 pt-7 px-10 overflow-hidden relative">
      <div className="border-b-1 flex">
        <div className="py-3 px-10 flex justify-between items-center">
          Reports
        </div>
        <div className="">
        {userContext && userContext.role.auroraAccessLevel.includes("all") && (
          <ButtonBorderTransparent text="Message Log" onClick={handleClick} />
        )}
        </div>
      </div>
      {csvLoading && (
        <div className="absolute z-50 w-[calc(100%_-_82px)] h-[calc(100%_-_82px)] flex justify-center items-center backdrop-blur-sm">
          <Oval
            height={60}
            width={60}
            color="#003B6E"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#BCE0FD"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
      <div className="py-5 w-full  flex flex-col items-center h-full overflow-y-auto">
        <SummarySearchBox />
        {firstSearch && (
          <div className="w-full">
            <div className="">
              <div className="pb-10 mt-10 flex justify-center">
                <SummaryReport title="App" data={summaryReportData} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
