import React, { ChangeEvent, RefObject, useState } from "react";
import DropDown from "../../../../../components/form/DropDown";
import InputText from "../../../../../components/form/InputText";
import { countries } from "../../../../../constants/countries";
import GlobeIcon from "../../../../../assets/icons/globe.svg";
import CityIcon from "../../../../../assets/icons/city.svg";
import SendIcon from "../../../../../assets/icons/send-icon.svg";
import { BlueButton } from "../../../../../components/ui/Buttons";
import Select from "../../../../../components/form/Select";
import Checkbox from "../../../../../components/form/Checkbox";

function Form() {
  const [isRemoteSecurityAssessment, setIsRemoteSecurityAssessment] =
    useState<boolean>(false);
  const [isOnSiteSecurityAssessment, setIsOnSiteSecurityAssessment] =
    useState<boolean>(false);

  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [type, setType] = useState<string>("");

  const countryNames: string[] = [];
  countries.map((country) => countryNames.push(country.name));

  const onCountrySelected = (
    target: RefObject<HTMLInputElement>,
    index?: number
  ) => {
    setSelectedCountry(target.current?.value!);
  };

  const onCitySelected = (
    target: RefObject<HTMLInputElement>,
    index?: number
  ) => {
    setSelectedCity(target.current?.value!);
  };

  return (
    <div className="mx-auto w-3/4 border-4 border-blue p-8 rounded-2xl flex flex-col gap-6 my-20">
      <Checkbox
        label={"Remote Security Assessment"}
        name={"security-assessment"}
        value={"remote-security-assessment"}
        checked={isRemoteSecurityAssessment}
        onClick={() => setIsRemoteSecurityAssessment((prev) => !prev)}
      />
      <Checkbox
        label={"On-site Security Assessment"}
        name={"security-assessment"}
        value={"on-site-security-assessment"}
        checked={isOnSiteSecurityAssessment}
        onClick={() => setIsOnSiteSecurityAssessment((prev) => !prev)}
      />

      <InputText
        placeholder="Type eg. Hotel/Airport"
        name="type"
        onChange={(event) => setType(event.target.value)}
        required
      />

      <div className="flex gap-4">
        <Select
          options={countryNames}
          title="Country"
          icon={GlobeIcon}
          onSelect={onCountrySelected}
        />
        <Select
          options={countryNames}
          title="City"
          icon={CityIcon}
          onSelect={onCitySelected}
        />
      </div>
      <textarea
        placeholder="Address"
        className="outline-none resize-none bg-white shadow-md w-2/3 min-h-[160px] h-full p-4 rounded-md"
      />
      <textarea
        placeholder="Please type your message here including your travel dates (if known)"
        className="outline-none resize-none bg-white shadow-md w-full min-h-[160px] h-full p-4 rounded-md"
      />
      <div className="w-56 mx-auto mt-10">
        <BlueButton onClick={() => {}} text="Send" icon={SendIcon} />
      </div>
    </div>
  );
}

export default Form;
