import React, {useContext, useEffect, useState} from "react";
import BasicText, {BasicNumber} from "../../components/form-v2/basic-text";
import ClientSelect from "../../components/form-v2/searchable-select/ClientSelect";
import {BlueButton, RedButton} from "../../components/ui/Buttons";
import {apiGET, apiPOST} from "../../apiv2/request";
import {UserContext} from "../../context/user";
import {Client} from "../../types/userManagement";
import {v4} from "uuid";


type Config = {
  id: string
  min: number
  max: number
  times: string[]
  arrival: boolean
  departure: boolean
  timeGracePeriod: number
  arrivalDepartureGracePeriod: number
}

type BackendConfig = {
  id: string
  clientID: string
  items: Array<{
    minRisk: number
    maxRisk: number
    times: Array<string>
    timeGracePeriod: number
    onArrival: boolean
    arrivalGracePeriod: number
    onDeparture: boolean
    departureGracePeriod: number
  }>
}


const minGracePeriod = 15
const maxGracePeriod = 240

const enforceGracePeriodRange = (p: number) => {
  if (p < minGracePeriod) return minGracePeriod
  if (p > maxGracePeriod) return maxGracePeriod
  return p
}

export const CheckInConfiguration: React.FC = () => {
  const user = useContext(UserContext);
  const [client, setClient] = useState<undefined | Client>()


  const [configs, setConfigs] = useState<Config[]>([])

  const addItem = () => {

    const newItem = {
      id: v4(),
      min: configs[configs.length - 1].max + 1,
      max: configs[configs.length - 1].max + 1,
      times: [...configs[configs.length - 1].times],
      arrival: configs[configs.length - 1].arrival,
      departure: configs[configs.length - 1].departure,
      timeGracePeriod: configs[configs.length - 1].timeGracePeriod,
      arrivalDepartureGracePeriod: configs[configs.length - 1].arrivalDepartureGracePeriod,
    }


    setConfigs([...configs, newItem])
  }

  const onRowChange = (config: Config, index: number) => {


    const configWithGracePeriodRangeEnforced = {
      ...config,
      timeGracePeriod: enforceGracePeriodRange(config.timeGracePeriod),
      arrivalDepartureGracePeriod: enforceGracePeriodRange(config.arrivalDepartureGracePeriod)
    }


    const newConfigs = [...configs]
    newConfigs[index] = configWithGracePeriodRangeEnforced
    console.log(newConfigs)
    setConfigs(newConfigs)
  }

  const onRowRemove = (index: number) => {
    const newConfigs = [...configs]
    newConfigs.splice(index, 1)
    setConfigs(newConfigs)
  }

  const load = async () => {

    setConfigs([])

    const url = user?.role?.auroraAccessLevel == "all" ? `${process.env.REACT_APP_API_V2_URL}/auto-check-in-config?clientID=${client?.id}` : `${process.env.REACT_APP_API_V2_URL}/auto-check-in-config`

    try {
      const res = await apiGET<BackendConfig>(url)

      setConfigs(res.items.map((i) => ({
        id: v4(),
        min: i.minRisk,
        max: i.maxRisk,
        times: i.times,
        arrival: i.onArrival,
        departure: i.onDeparture,
        timeGracePeriod: i.timeGracePeriod,
        arrivalDepartureGracePeriod: i.arrivalGracePeriod,
      })))
    } catch (e) {
      setConfigs([{
        id: v4(),
        min: 1,
        max: 9,
        times: [],
        arrival: false,
        departure: false,
        timeGracePeriod: 60,
        arrivalDepartureGracePeriod: 60,
      }])

    }
  }

  useEffect(() => {
    if (user?.role?.auroraAccessLevel != "all") {
      load()
    }

  }, []);

  useEffect(() => {
    if (user?.role?.auroraAccessLevel == "all") {
      load()
    }

  }, [client]);

  const saveConfig = async () => {

    if (user?.role?.auroraAccessLevel == "all" && !client) {
      return
    }

    const items = configs.map((c) => ({
      minRisk: c.min,
      maxRisk: c.max,
      times: c.times,
      onArrival: c.arrival,
      onDeparture: c.departure,
      timeGracePeriod: c.timeGracePeriod,
      arrivalGracePeriod: c.arrivalDepartureGracePeriod,
      departureGracePeriod: c.arrivalDepartureGracePeriod,
    }))

    const req = {
      items
    }

    const url = user?.role?.auroraAccessLevel == "all" ? `${process.env.REACT_APP_API_V2_URL}/auto-check-in-config?clientID=${client?.id}` : `${process.env.REACT_APP_API_V2_URL}/auto-check-in-config`

    try {
      await apiPOST(url, req)
    } catch (e) {
      console.error(e)

    }
  }


  return <div className="h-full pt-36 pb-16 px-7">
    <div className="mt-5 shadow-lg px-6 pt-7 pb-8 relative bg-white">
      <div className="flex items-center justify-between py-4 border-b text-md mb-4">
        <div className="font-semibold text-lg flex-grow">Automatic Check In Configuration</div>
        <div className={" w-96"}>
          {user?.role.auroraAccessLevel == "all" &&
              <ClientSelect clients={client ? [client] : []} onSelectionChange={(c) => setClient(c[0])}/>}
        </div>
      </div>

      {user?.role.auroraAccessLevel == "all" && !client && <div>Please select a client</div>}

      {((user?.role.auroraAccessLevel == "all" && client) || user?.role.auroraAccessLevel == "client") && configs.length != 0 && <>

          <Heading/>

        {configs.map((c, i) => <div key={i}>
          <Row config={c} index={i} prev={configs[i - 1]} next={configs[i + 1]}
               onChange={onRowChange} onRemove={onRowRemove}/>
          <hr className={"opacity-25 my-4"}/>
        </div>)}
        {configs[configs.length - 1] && configs[configs.length - 1].max != 9 &&
            <div className={"grid grid-cols-8 mt-4"}>
                <div className={"px-4 col-end-9"}>
                    <BlueButton text={"Add"} onClick={addItem}/>
                </div>

            </div>}

        {configs[configs.length - 1].max == 9 && <div className={"grid grid-cols-8 mt-4"}>
            <div className={"px-4 col-end-9"}>
                <BlueButton text={"Save Config"} onClick={saveConfig}/>
            </div>
        </div>
        }
      </>}
    </div>
  </div>
}

type RowProps = {
  config: Config
  prev?: Config
  next?: Config
  index: number
  onChange: (config: Config, index: number) => void
  onRemove: (index: number) => void
}

const Row: React.FC<RowProps> = (props) => {

  const [max, setMax] = useState(props.config.max)
  const [times, setTimes] = useState<string[]>(props.config.times)
  const [arrival, setArrival] = useState(props.config.arrival)
  const [departure, setDeparture] = useState(props.config.departure)
  const [timeGracePeriod, setTimeGracePeriod] = useState(props.config.timeGracePeriod)
  const [arrivalDepartureGracePeriod, setArrivalDepartureGracePeriod] = useState(props.config.arrivalDepartureGracePeriod)

  const min = props.prev ? props.prev.max + 1 : 1


  useEffect(() => {
    props.onChange({
      id: props.config.id,
      min,
      max,
      times,
      arrival,
      departure,
      timeGracePeriod,
      arrivalDepartureGracePeriod
    }, props.index)
  }, [max, times, arrival, departure, timeGracePeriod, arrivalDepartureGracePeriod]);


  const addTime = () => {
    if (times.length == 0 || times[times.length - 1] != "") {
      setTimes([...times, ""])

    }
  }

  const removeTime = () => {
    const newTimes = [...times]
    newTimes.splice(newTimes.length - 1)
    setTimes(newTimes)
  }

  const updateTime = (value: string, index: number) => {
    const newTimes = [...times]
    newTimes[index] = value
    setTimes(newTimes)
  }

  return <div className={"py-3 grid grid-cols-8 w-full h-full items-start"}>

    <div className={"px-4"}>
      <BasicNumber type={"number"} value={min} min={1} max={9} disabled={true}/>
    </div>

    <div className={"px-4"}>
      <BasicNumber type={"number"} value={props.config.max} min={min} max={9} onChange={setMax}
                   disabled={!!props.next || min == 9}/>
    </div>


    <div className={"px-4 text-center"}>
      <div className={"flex flex-col gap-4"}>
        {times.map((t, i) => <BasicText value={t} key={i} type={"time"} center={true}
                                        onChange={(t) => updateTime(t, i)}/>)}
        {times.length != 0 && <RedButton text={"Remove Time"} onClick={removeTime}/>}
        <div className={(times.length == 0 || times[times.length - 1] != "") ? "" : "opacity-50"}><BlueButton
            text={"Add Time"} onClick={addTime}/></div>
      </div>
    </div>

    <div className={"px-4"}>
      {times.length != 0 &&
          <BasicNumber type={"number"} value={timeGracePeriod} min={15} max={240} onChange={setTimeGracePeriod}
          />}
    </div>

    <div className={"px-4 text-center"}>
      <input className={"scale-[1.5]"} type={"checkbox"} checked={arrival}
             onChange={(e) => setArrival(e.target.checked)}/>


    </div>
    <div className={"px-4 text-center"}>
      <input className={"scale-[1.5]"} type={"checkbox"} checked={departure}
             onChange={(e) => setDeparture(e.target.checked)}/>

    </div>

    <div className={"px-4"}>
      {(arrival || departure) &&
          <BasicNumber type={"number"} value={arrivalDepartureGracePeriod} min={15} max={240}
                       onChange={setArrivalDepartureGracePeriod}
          />}
    </div>
    <div className={"px-4"}>
      {!props.next && props.index != 0 && <RedButton text={"Remove"} onClick={() => props.onRemove(props.index)}/>}
    </div>


  </div>
}


const Heading: React.FC = () => {
  return <div className={"rounded-t-lg bg-desaturated-grey py-3 grid grid-cols-8 w-full items-center h-full"}>

    <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
      Minimum Risk
    </div>
    <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
      Maximum Risk
    </div>
    <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
      Times
    </div>
    <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
      Times Grace Period
    </div>
    <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
      On Arrival
    </div>
    <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
      On Departure
    </div>
    <div className="font-bold border-r-1 text-center relative h-full flex items-center justify-center">
      Arrival/Departure<br/>Grace Period
    </div>

    <div className="font-bold text-center px-6 h-full flex items-center justify-center">Actions</div>

  </div>
}