import { useOidc } from "@axa-fr/react-oidc"
import React, { useEffect } from "react"

function Logout(){

    const {logout} = useOidc()

    const handleLogout = async() => {
        await logout()
    }

    useEffect(() => {
        handleLogout()
    }, [])


    return <div>Logging out</div>
}

export default Logout