import React, { useContext } from "react";
import { CountryProfileContext } from "../../context/country-profile";
import useGetSectionData from "../../hooks/useGetSectionData";

function CountryProfileRegionalInfoSection() {
    const countryProfileContext = useContext(CountryProfileContext);
    const category = useGetSectionData("regional-information");
    if (!countryProfileContext) return null;

    return !countryProfileContext || category!.length < 1 ? (
        <div className="">No data found</div>
    ) : (
        <div dangerouslySetInnerHTML={{ __html: category![0].text }}></div>
    );
    }

export default CountryProfileRegionalInfoSection;
