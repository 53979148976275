import { useQuery } from "react-query";
import api from "../axiosConfig";
import queryString from "query-string";

interface Location {
  lat: number;
  lng: number;
  countryiso: string;
}

interface Metadata {
  location: Location;
}

interface Message {
  _id: string;
  userID: string;
  source: string;
  sentAt: string;
  message: string;
  target: string;
  userName: string;
  metadata: Metadata;
}

interface Pagination {
  total: number;
  page: number;
  hasMore: boolean;
}

interface MessagesResponse {
  messages: Message[];
  pagination: Pagination;
}

const useGetMessages = (params: {} = {}, page: number, sortField: string, sortOrder: string) => {
  return useQuery<MessagesResponse, Error>(
    ["messages", page, sortField, sortOrder, queryString.stringify(params)], // Include all relevant parameters in key
    async () => {
      const response = await api.get(`/reports/messages?page=${page}&sortField=${sortField}&sortOrder=${sortOrder}&${queryString.stringify(params)}`);
      if (response.status !== 200) {
        throw new Error("Failed to fetch messages");
      }
      return response.data;
    }, 
    {
      keepPreviousData: true, 
    }
  );
};

export default useGetMessages;
