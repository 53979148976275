import React, { useContext, useEffect, useRef, useState } from "react";
import { BlueButton, WhiteButton } from "../../../../components/ui/Buttons";
import { UserContext } from "../../../../context/user";
import Modal from "../../../../components/portal/Modal";
import useGetAllClients from "../../../../api/clients/useGetAllClients";
import { importFacilitiesCSV } from "../../utils";
import Toast from "../../../../components/portal/Toast";
import { useLocation } from "react-router-dom";
function ImportFacilitiesBtn() {
  const pathname = useLocation().pathname.split("/");

  const [clientPopup, setClientPopup] = useState<boolean>(false);
  const [success, setSuccess] = useState<{
    code: number;
    message: string;
  } | null>(null);
  const [toast, setToast] = useState<boolean>(false);
  const user = useContext(UserContext);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const clients = useGetAllClients();
  const clientRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (!success) return;
    setToast(true);
  }, [success]);

  const handleOnChange = () =>
    importFacilitiesCSV(
      fileInputRef,
      clientRef,
      user,
      setClientPopup,
      setSuccess
    );

  return (
    <div>
      <input
        type="file"
        onChange={handleOnChange}
        ref={fileInputRef}
        className="absolute -z-40 opacity-0"
        name="facilities-csv-file"
      />
      {!pathname.includes("facilities") ? (
        <WhiteButton
          type="button"
          text="Import Facilities"
          onClick={() => {
            if (user?.role.auroraAccessLevel === "all") {
              setClientPopup(true);
            } else {
              fileInputRef.current?.click();
            }
          }}
        />
      ) : (
        <BlueButton
          type="button"
          text="Import Facilities"
          onClick={() => {
            if (user?.role.auroraAccessLevel === "all") {
              setClientPopup(true);
            } else {
              fileInputRef.current?.click();
            }
          }}
        />
      )}

      <Modal open={clientPopup} setOpen={setClientPopup}>
        <form
          action=""
          className="p-8 rounded-md bg-white shadow-md flex flex-col gap-4"
        >
          <span className="text-lg mx-auto">Select a Client</span>
          <select
            name=""
            id=""
            className="h-10 w-full rounded-md shadow-md px-4 outline-none"
            ref={clientRef}
            defaultValue={"client"}
          >
            <option value="client">Client</option>
            {clients.all_clients_data.map((client) => {
              return (
                <option value={client.id} key={client.id}>
                  {client.name}
                </option>
              );
            })}
          </select>
          <div className="max-w-xs mx-auto w-full">
            <BlueButton
              text="Import Facilities"
              onClick={() => {
                if (clientRef.current?.value === "client") {
                  // show error
                  return;
                }
                fileInputRef.current?.click();
              }}
            />
          </div>
        </form>
      </Modal>

      <Toast open={toast} setOpen={setToast} setSuccess={setSuccess}>
        {success?.code !== 200 ? (
          <span className="text-lg font-semibold">Error: {success?.code}</span>
        ) : null}

        {success?.code === 200 ? <span>Facilities imported.</span> : null}

        {success?.code !== 200 ? (
          <p className="my-4">{success?.message}</p>
        ) : null}
      </Toast>
    </div>
  );
}

export default ImportFacilitiesBtn;
