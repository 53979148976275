import { ReactNode, RefObject } from "react";
import { FacilityDataTypes } from "../types/facilitiy-data-types";
import { ZonesDataTypes } from "../types/zones-data-types";

export function generateMap(
  ref: RefObject<HTMLDivElement>,
  center: { lat: number; lng: number },
  zoom?: number
) {
  const map = new window.google.maps.Map(ref.current!, {
    center: center,
    zoom: zoom || 15,
    mapTypeId: "roadmap",
    zoomControl: true,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: true,
    minZoom: 3,
  });
  let infoWindow = new google.maps.InfoWindow({
  });

  map.addListener("rightclick", (mapsMouseEvent: any) => {
    infoWindow.close();

    const lat = mapsMouseEvent.latLng.lat();
    const lng = mapsMouseEvent.latLng.lng();
    
    infoWindow = new google.maps.InfoWindow({
      position: mapsMouseEvent.latLng,
    });
    infoWindow.setContent(`${lat.toFixed(5)}, ${lng.toFixed(5)}`);

    infoWindow.open(map);
  });

  map.addListener("click", (mapsMouseEvent: any) => {
    infoWindow.close();
  });

  const mapTypeControlDiv = document.createElement("div");
  mapTypeControlDiv.innerHTML = "<div class=\"maptype-control shadow rounded mb-4\">" + 
                                "<button class=\"maptype-control-roadmap bg-white px-3 pl-4 py-2 rounded-l text-base text-gray-700 hover:bg-gray-50 \">Map</button>" +
                                "<button class=\"maptype-control-terrain bg-white px-3 py-2 text-base text-gray-700 hover:bg-gray-50 \">Terrain</button>" +
                                "<button class=\"maptype-control-hybrid bg-white px-3 py-2 text-base text-gray-700 hover:bg-gray-50 \">Hybrid</button>" +
                                "<button class=\"maptype-control-satellite bg-white px-3 pr-4 py-2 rounded-r text-base text-gray-700 hover:bg-gray-50\">Satellite</button>" +
                                "</div>";

  map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(mapTypeControlDiv);

  const mapButton = mapTypeControlDiv.querySelector(".maptype-control-roadmap");
  const terrainButton = mapTypeControlDiv.querySelector(".maptype-control-terrain");
  const hybridButton = mapTypeControlDiv.querySelector(".maptype-control-hybrid");
  const satelliteButton = mapTypeControlDiv.querySelector(".maptype-control-satellite");
  
  const setActiveButton = (activeButton: any) => {
    [mapButton, terrainButton, hybridButton, satelliteButton].forEach(btn => {
      if (btn) btn.classList.remove("font-semibold"); 
    });
    if (activeButton) activeButton.classList.add("font-semibold");
  };
  
  if (mapButton) {
    mapButton.addEventListener("click", () => {
      map.setMapTypeId("roadmap");
      setActiveButton(mapButton);
    });
  }
  if (terrainButton) {
    terrainButton.addEventListener("click", () => {
      map.setMapTypeId("terrain");
      setActiveButton(terrainButton);
    });
  }
  if (hybridButton) {
    hybridButton.addEventListener("click", () => {
      map.setMapTypeId("hybrid");
      setActiveButton(hybridButton);
    });
  }
  
  if (satelliteButton) {
    satelliteButton.addEventListener("click", () => {
      map.setMapTypeId("satellite");
      setActiveButton(satelliteButton);
    });
  }
  
  if (mapButton) setActiveButton(mapButton);
  

  return map;  

}



export function generateMapMarker(
  lng: number,
  lat: number,
  clickable: boolean
) {
  return new google.maps.Marker({
    position: {
      lat: lat,
      lng: lng,
    },
    clickable: clickable,
  });
}

export function generateFacilitiesMarkers(
  facilities: FacilityDataTypes[],
  markerClickable: boolean
) {
  const markers = facilities.map((facility: FacilityDataTypes) => {
    const lat = facility.position.coordinates[0];
    const lng = facility.position.coordinates[1];
    const marker = generateMapMarker(lat, lng, markerClickable);
    return marker;
  });

  return markers;
}

export function generateZonesMarkers(
  zones: ZonesDataTypes[],
  markerClickable: boolean
) {
  const markers = zones.map((zone) => {
    const lat = zone.location.coordinates[0];
    const lng = zone.location.coordinates[1];

    const marker = generateMapMarker(lat, lng, markerClickable);

    return marker;
  });

  return markers;
}

export function generateMapDrawingTools() {
  return new google.maps.drawing.DrawingManager({
    drawingControlOptions: {
      position: google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [
        google.maps.drawing.OverlayType.MARKER,
        google.maps.drawing.OverlayType.CIRCLE,
        google.maps.drawing.OverlayType.POLYGON,
        google.maps.drawing.OverlayType.POLYLINE,
        google.maps.drawing.OverlayType.RECTANGLE,
      ],
    },
  });
}

export function generateTextInputInfoWindow() {
  const contentString =
    "<div id=\"content\" style=\"height: 56px;\">" +
    "<input type=\"text\" style=\"height: 100%; width: 100%;text-align: center; font-size: 32px\" id=\"drawing-content-input\"/>" +
    "</div>";
  const infowindow = new google.maps.InfoWindow({
    content: contentString,
  });
  return infowindow;
}

export function generateMapSearchBox(
  input: HTMLInputElement,
  map: google.maps.Map | undefined
) {
  if (!map) return;
  const searchBox = new google.maps.places.SearchBox(input);
  // Bias the SearchBox results towards current map's viewport.
  map.addListener("bounds_changed", () => {
    searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
  });

  searchBox.addListener("places_changed", () => {
    const places = searchBox.getPlaces();

    if (places!.length == 0) {
      return;
    }

    // For each place, get the icon, name and location.
    const bounds = new google.maps.LatLngBounds();

    places!.forEach((place) => {
      if (!place.geometry || !place.geometry.location) {
        return;
      }

      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    map.fitBounds(bounds);
  });
}

export function generateCircleRadius(
  map: google.maps.Map,
  severity: string,
  lat: number,
  lng: number
) {
  return new google.maps.Circle({
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    map,
    center: { lat: lat, lng: lng },
    radius:
      severity === "unknown" || severity === "insignificant"
        ? 100
        : severity === "limited"
        ? 300
        : severity === "moderate"
        ? 600
        : severity === "major"
        ? 1000
        : severity === "extreme"
        ? 2500
        : null,
  });
}
