import React, { useContext } from "react";
import InputText from "../../../../../../components/form/InputText";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { FormDataContext } from "../../context/form";
function BloodType() {
  const { data } = useContext(UserProfileContext)!;
  const userData: UserDataTypes = data?.data;
  const formDataContext = useContext(FormDataContext)!;
  const { bloodTypeRef } = formDataContext;
  return (
    <div className="">
      <label htmlFor="blood_type" className="opacity-80">
        Blood Type
      </label>
      <InputText
        name={"blood_type"}
        placeholder="Enter Allergies"
        onChange={() => {}}
        defaultValue={
          userData.medicalInformation?.bloodGroup
            ? userData.medicalInformation?.bloodGroup
            : ""
        }
        inputRef={bloodTypeRef}
      />
    </div>
  );
}

export default BloodType;
