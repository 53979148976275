import React, { ReactNode, createContext } from "react";
import useGetCountryProfiles from "../../../../../../hooks/api/useGetCountryProfiles";
import { useParams } from "react-router-dom";
import useGetCountryProfile from "../../../../../../api/country-profiles/useGetCountryProfile";
import { CountryProfileDataTypes } from "../../../../../../types/country-profile-data-types";
import { AxiosResponse } from "axios";

type CountryProfileContext = {
  data: AxiosResponse<any, any> | null;
  status: "idle" | "loading" | "success" | "error";
  error: unknown;
};
export const CountryProfileContext =
  createContext<CountryProfileContext | null>(null);

export default function CountryProfileContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const countryProfile = useGetCountryProfile();
  return (
    <CountryProfileContext.Provider value={countryProfile}>
      {children}
    </CountryProfileContext.Provider>
  );
}
