import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import LoadingPage from "./LoadingPage";
import {
  Main,
  PhotoUploadSidebar,
} from "../../../features/user-management/pages/client-details";
import { ClientContext } from "../../../features/user-management/context/client";

function Client() {
  const clientContext = useContext(ClientContext)!;

  return clientContext.status !== "success" || clientContext.isFetching ? (
    <LoadingPage clientStatus={clientContext.status} />
  ) : (
    <div className="mt-40 grid grid-cols-[auto_1fr] pr-20">
      <div className="max-w-md">
        <PhotoUploadSidebar />
      </div>
      <Main />
    </div>
  );
}

export default Client;
