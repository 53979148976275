import { useQuery } from "react-query";
import api from "../axiosConfig";
import { ClientDataTypes } from "../../types/client-data-types";

export default function useGetAllClients() {
  const fetchClients = async (page = 1) => {
    return api
      .get(`/clients?page=${page}`, { params: { rpp: 200 } })
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  const clientsQuery = useQuery("clients", () => fetchClients(1));

  const clientsQueryData = clientsQuery.data?.data.items;

  const fetchAllClients = async () => {
    const totalClients = clientsQuery.data?.data.total;
    const clientsPerPage = clientsQuery.data?.data.items.length;
    const totalPagesCalc = Math.ceil(totalClients / clientsPerPage);
    const pageFetches = Array.from({ length: totalPagesCalc }, (v, i) =>
      fetchClients(i)
    );

    return await Promise.all(pageFetches);
  };

  const { data, status, error } = useQuery({
    queryKey: "all-clients",
    queryFn: () => fetchAllClients(),
    enabled: !!clientsQueryData,
  });

  const extractClientsFromEachPage = () => {
    if (status !== "success") return [];

    const arr: ClientDataTypes[] = [];
    data.map((page) => {
      const items = page?.data?.items;
      items?.forEach((item: ClientDataTypes) => {
        arr.push(item);
      });
    });

    return arr;
  };
  const extractedData = extractClientsFromEachPage();

  return {
    all_clients_data: extractedData,
    all_clients_status: status,
    all_clients_error: error,
  };
}
