import { Done } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import CustomCheckbox from "../../../../../../components/form/CustomCheckbox";

interface IncidentType {
  id: string;
  name: string;
  severity: string;
}

interface Category {
  id: string;
  name: string;
  types: IncidentType[];
}

interface CategorySelectorProps {
  categories: Category[];
  initialCategoryIds: string[];
  onTypeIdsChange: (typeIds: string[], categoryIds: string[]) => void;
}

const CategorySelector: React.FC<CategorySelectorProps> = ({ categories, initialCategoryIds, onTypeIdsChange }) => {
  const [selectedCategories, setSelectedCategories] = useState<Record<string, boolean>>(
    initialCategoryIds.reduce((acc, id) => ({ ...acc, [id]: true }), {})
  );



  const computeIds = () => {
    if (!categories) return [[], []];
    const allSelectedTypeIds: string[] = [];
    const selectedCategoryIds: string[] = [];
    categories.forEach(category => {
      if (selectedCategories[category.id]) {
        allSelectedTypeIds.push(...category.types.map(type => type.id));
        selectedCategoryIds.push(category.id);
      }
    });
    return [allSelectedTypeIds, selectedCategoryIds];
  };
  useEffect(() => {
    const [typeIds, categoryIds] = computeIds();
    console.log("compute", computeIds())
    onTypeIdsChange(typeIds, categoryIds);
    console.log("triggered")
  }, [selectedCategories]);

  const handleCheckboxChange = (category: Category) => {
    console.log(`Checkbox clicked: ${category.id}`);
    setSelectedCategories(prevState => ({
      ...prevState,
      [category.id]: !prevState[category.id]
    }));
  };

  console.log(initialCategoryIds, selectedCategories)

  if (!categories || categories.length === 0) {
    return <div>No categories available.</div>;

    
  }
  
  
  return (
    <div>
      {categories.map((category) => {
        const categoryId = category.id.toString().trim();
        const isChecked = initialCategoryIds.some((id) => {
          const result = id === categoryId;
          return result;
        });

        return (
          <div key={category.id} className="relative flex items-center gap-2">
            <CustomCheckbox 
              name={"category"}
              value={category.id}
              checked={!!selectedCategories[category.id]}
              handleFakeCheckbox={() =>
                handleCheckboxChange(category) }
            />
            <span className="ml-2">
              {category.name}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default CategorySelector;
