import React, { useContext } from "react";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { FormDataContext } from "../../context/form";
import { languages } from "../../../../../../constants/languages";
function MainLanguage() {
  const { data } = useContext(UserProfileContext)!;
  const formDataContext = useContext(FormDataContext)!;
  const { mainLanguageRef } = formDataContext;
  const userData: UserDataTypes = data?.data;
  const userLanguages = userData.languages;
  const mainLanguge = userLanguages?.find((lang) => lang.primary);

  return (
    <div className="">
      <label htmlFor="main_language" className="opacity-80">
        Main Language
      </label>
      <select
        ref={mainLanguageRef}
        name="main-language"
        id="main-language"
        className="h-10 w-full rounded-md shadow-md px-4"
        defaultValue={mainLanguge !== undefined ? mainLanguge.code : "en"}
      >
        <option value="select-main-language" disabled>
          Select Main Language
        </option>
        {languages.map((language) => (
          <option value={language.code} key={language.code}>
            {language.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default MainLanguage;
