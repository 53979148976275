import { BlueButton } from "../../../../../../../components/ui/Buttons";
import React, { useRef, useState } from "react";
import api from "../../../../../../../api/axiosConfig";
import validatePhoneForE164 from "../../../../../../../util/validatePhoneForE164";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import AddIcon from "../../../../../../../assets/icons/add-icon.svg";

export default function NewDepartmentForm() {
  const queryClient = useQueryClient();
  const { clientID } = useParams();
  const nameRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const [phoneNumberErr, setPhoneNumberErr] = useState<boolean>(false);

  const handleAddDepartment = () => {
    const formData =
      phoneNumberRef.current?.value !== ""
        ? {
            name: nameRef.current!.value,
            phoneNumber: phoneNumberRef.current?.value,
            clientID: clientID,
            alertEmailAddress:
              emailRef.current?.value !== "" ? emailRef.current?.value : null,
            sendAlertToClient: emailRef.current?.value ? true : false,
          }
        : {
            name: nameRef.current!.value,
            clientID: clientID,
            alertEmailAddress:
              emailRef.current?.value !== "" ? emailRef.current?.value : null,
            sendAlertToClient: emailRef.current?.value ? true : false,
          };

    api
      .post("departments", formData)
      .then((res) => {
        console.log(
          "added the following department data to database",
          res.data
        );
        queryClient.invalidateQueries(["departments", clientID]);
        queryClient.invalidateQueries(["client-departments", clientID]);
      })
      .then(() => {
        setPhoneNumberErr(false);
        nameRef.current!.value = "";
        phoneNumberRef.current!.value = "";
        emailRef.current!.value = "";
      })
      .catch((err) => {
        console.error(err);

        if (!validatePhoneForE164(phoneNumberRef.current?.value!)) {
          setPhoneNumberErr(true);
        }
      });
  };

  return (
    <div className="">
      <div className="flex justify-between items-center gap-24 border rounded-lg p-6">
        <div className="flex flex-col gap-4 w-full">
          <input
            type="text"
            className="shadow-md h-10 rounded-md w-full px-4"
            placeholder="Enter Department"
            required
            ref={nameRef}
          />
          <div className="w-full">
            <input
              type="text"
              className="shadow-md h-10 rounded-md w-full px-4"
              placeholder="Enter Phone Number"
              required
              ref={phoneNumberRef}
            />

            {phoneNumberErr && (
              <span className="text-red inline-block mt-2">
                Please check the format of phone number (e.g +4401010101010)
              </span>
            )}
          </div>
          <input
            type="email"
            className="shadow-md h-10 rounded-md w-full px-4"
            placeholder="Enter Email"
            ref={emailRef}
          />
        </div>
      </div>
      <div className="w-52 mx-auto my-12">
        <BlueButton
          text="Add Department"
          icon={AddIcon}
          onClick={handleAddDepartment}
        />
      </div>
    </div>
  );
}
