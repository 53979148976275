import React, { useContext } from "react";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { FormDataContext } from "../../context/form";
import moment from "moment";
function DateOfBirth() {
  const { data } = useContext(UserProfileContext)!;
  const formDataContext = useContext(FormDataContext)!;
  const { dateOfBirthRef } = formDataContext;
  const userData: UserDataTypes = data?.data;
  const dateOfBirth = moment(userData.profile.dateOfBirth).format("yyyy-MM-DD");
  return (
    <div className="">
      <label htmlFor="date_of_birth" className="opacity-80">
        Date of Birth (DD/MM/YYYY)
      </label>
      <input
        type="date"
        className="h-10 w-full rounded-md shadow-md px-4"
        required
        defaultValue={dateOfBirth}
        name={"date_of_birth"}
        ref={dateOfBirthRef}
        onChange={() => console.log(dateOfBirthRef.current?.value)}
      />
    </div>
  );
}

export default DateOfBirth;
