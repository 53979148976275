import React from "react";
import {CheckInType} from "../../../types/check-ins-data-types";

import Bin from "../../../../../assets/icons/icon-bin.svg";
import EditIcon from "../../../../../assets/icons/icon-edit.svg";
import {countries} from "../../../../../constants/countries";
import moment from "moment-timezone";

export default function CheckInEntry({
                                       data,
                                       openPopUp,
                                       onDelete,
                                     }: {
  data: CheckInType;
  openPopUp: (tripID: string, checkInID: string) => void;
  onDelete: (tripID: string, checkInID: string) => void;
}) {
  const gracePeriodInMinutes = (checkIn: CheckInType): number => {
    const start = new Date(checkIn.checkInAt!);
    const end = new Date(checkIn.checkInBy!);
    const differenceInMilliseconds = end.getTime() - start.getTime();
    return differenceInMilliseconds / (1000 * 60);
  };


  return (
      <div
          className={`rounded-2xl bg-desaturated-grey py-3 grid grid-cols-7 w-full items-center mt-4 ${data.userEditable ? "" : "opacity-50"}`}>
        <div className=" border-r text-center relative px-4 py-4 h-full flex items-center justify-center">
          {moment.tz(data.checkInAt, data.location.timezone).format("DD/MM/YYYY HH:mm")}
        </div>
        <div className=" border-r text-center relative px-4 py-4 h-full flex items-center justify-center ">
          {moment.tz(data.checkInAt, moment.tz.guess()).format("DD/MM/YYYY HH:mm")}
        </div>
        <div className=" border-r text-center relative px-4 py-4 h-full flex items-center justify-center ">
          {data.location !== null
              ? data.location.countryISO !== "UNKNOWN" ||
              data.location.countryISO !== null
                  ? countries.find(
                      (country) =>
                          country.code.toLowerCase() ===
                          data.location.countryISO.toLowerCase()
                  )?.name
                  : "-"
              : "-"}
        </div>
        <div className=" border-r text-center relative px-4 py-4 h-full flex items-center justify-center ">
          {data.userName}
        </div>
        <div className=" border-r text-center relative px-4 py-4 h-full flex items-center justify-center ">
          {gracePeriodInMinutes(data)}
        </div>

        {!data.userEditable &&
            <p className={"text-center relative px-4 py-4 h-full flex items-center justify-center col-span-2"}>
                Cannot edit automatically generated check ins
            </p>}
        {data.userEditable &&
            <>
                <div className=" border-r text-center relative px-4 py-4 h-full flex items-center justify-center">
                    <button onClick={() => openPopUp(data.tripID, data.id)}>
                        <img src={EditIcon} alt="Edit"/>
                    </button>
                </div>
                <div className=" text-center relative px-4 py-4 h-full flex items-center justify-center">
                    <button
                        className="h-[15px] w-[15px]"
                        onClick={() => onDelete(data.tripID, data.id)}
                    >
                        <img src={Bin} alt="Delete"/>
                    </button>
                </div>
            </>}
      </div>
  );
}
