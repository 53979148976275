import { Coordinates, FeatureType } from "../types";
import importFacilitiesCSV from "./importFacilitiesCSV";

import { v4 as uuidv4 } from "uuid";

const handleCircleComplete = (
  circle: google.maps.Circle,
  onConfirm: (data: FeatureType) => void
) => {
  const center = [
    circle.getCenter()!.lng(),
    circle.getCenter()!.lat(),
  ] as Coordinates;
  const radius = circle.getRadius();
  const fillColor = circle.get("fillColor");
  const lineWidth = circle.get("strokeWeight");
  const lineColor = circle.get("strokeColor");
  const fillOpacity = circle.get("fillOpacity");
  const lineOpacity = circle.get("strokeOpacity");
  const input: HTMLInputElement = document.getElementById(
    "input-drawing-name"
  ) as HTMLInputElement;
  const confirmBtn = document.getElementById(
    "input-drawing-confirm-btn"
  ) as HTMLButtonElement;
  input.parentElement!.style.display = "flex";
  input.value = "";
  input.focus();
  const handleConfirm = () => {
    if (input.value === "") {
      console.log(input.parentElement!.children);
      if (input.parentElement!.children.length > 3) {
        return console.error("Name is invalid.");
      }
      const errorSpan = document.createElement("span");
      errorSpan.id = "feature-name-error";
      errorSpan.innerText = "*";
      errorSpan.className =
        "font-semibold text-red text-md absolute left-0 top-0";
      input.parentElement!.appendChild(errorSpan);
      return console.error("Name is invalid.");
    } else {
      const errorSpan = document.getElementById("feature-name-error");
      errorSpan?.remove();
    }
    const data: FeatureType = {
      uuidv4: uuidv4(),
      drawing: circle,
      drawingType: "circle",
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: center,
      },
      properties: {
        name: input.value,
        radius,
        style: {
          lineWidth,
          lineColor,
          lineOpacity,
          fillOpacity,
          fillColor,
        },
        description: "",
      },
    };
    onConfirm(data);
    input.parentElement!.style.display = "none";
    confirmBtn.removeEventListener("click", handleConfirm);
  };
  confirmBtn.addEventListener("click", handleConfirm);
};

const handleMarkerComplete = (
  marker: google.maps.Marker,
  onConfirm: (data: FeatureType) => void
) => {
  const center = [
    marker.getPosition()!.lng(),
    marker.getPosition()!.lat(),
  ] as Coordinates;
  const fillColor = marker.get("fillColor");
  const lineWidth = marker.get("strokeWeight");
  const lineColor = marker.get("strokeColor");
  const fillOpacity = marker.get("fillOpacity");
  const lineOpacity = marker.get("strokeOpacity");
  const input: HTMLInputElement = document.getElementById(
    "input-drawing-name"
  ) as HTMLInputElement;
  const confirmBtn = document.getElementById(
    "input-drawing-confirm-btn"
  ) as HTMLButtonElement;
  input.parentElement!.style.display = "flex";
  input.value = "";
  input.focus();
  const handleConfirm = () => {
    if (input.value === "") {
      console.log(input.parentElement!.children);
      if (input.parentElement!.children.length > 3) {
        return console.error("Name is invalid.");
      }
      const errorSpan = document.createElement("span");
      errorSpan.id = "feature-name-error";
      errorSpan.innerText = "*";
      errorSpan.className =
        "font-semibold text-red text-md absolute left-0 top-0";
      input.parentElement!.appendChild(errorSpan);
      return console.error("Name is invalid.");
    } else {
      const errorSpan = document.getElementById("feature-name-error");
      errorSpan?.remove();
    }
    const data: FeatureType = {
      uuidv4: uuidv4(),
      drawing: marker,
      drawingType: "marker",
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: center,
      },
      properties: {
        name: input.value,
        style: {
          lineWidth,
          lineColor,
          lineOpacity,
          fillOpacity,
          fillColor,
        },
        description: "",
      },
    };
    confirmBtn.removeEventListener("click", handleConfirm);
    onConfirm(data);
    input.parentElement!.style.display = "none";
  };
  confirmBtn.addEventListener("click", handleConfirm);
};

const handlePolylineComplete = (
  polyline: google.maps.Polyline,
  onConfirm: (data: FeatureType) => void
) => {
  const polylinePath = polyline.getPath().getArray();
  const paths = polylinePath.map((path) => {
    const lng = path.lng();
    const lat = path.lat();
    return [lng, lat] as Coordinates;
  });
  const fillColor = polyline.get("fillColor");
  const lineWidth = polyline.get("strokeWeight");
  const lineColor = polyline.get("strokeColor");
  const fillOpacity = polyline.get("fillOpacity");
  const lineOpacity = polyline.get("strokeOpacity");
  const input: HTMLInputElement = document.getElementById(
    "input-drawing-name"
  ) as HTMLInputElement;
  const confirmBtn = document.getElementById(
    "input-drawing-confirm-btn"
  ) as HTMLButtonElement;
  input.parentElement!.style.display = "flex";
  input.value = "";
  input.focus();
  const handleConfirm = () => {
    if (input.value === "") {
      console.log(input.parentElement!.children);
      if (input.parentElement!.children.length > 3) {
        return console.error("Name is invalid.");
      }
      const errorSpan = document.createElement("span");
      errorSpan.id = "feature-name-error";
      errorSpan.innerText = "*";
      errorSpan.className =
        "font-semibold text-red text-md absolute left-0 top-0";
      input.parentElement!.appendChild(errorSpan);
      return console.error("Name is invalid.");
    } else {
      const errorSpan = document.getElementById("feature-name-error");
      errorSpan?.remove();
    }
    const data: FeatureType = {
      uuidv4: uuidv4(),
      drawing: polyline,
      drawingType: "polyline",
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: paths,
      },
      properties: {
        name: input.value,
        style: {
          lineWidth,
          lineColor,
          lineOpacity,
          fillOpacity,
          fillColor,
        },
        description: "",
      },
    };
    onConfirm(data);
    input.parentElement!.style.display = "none";
    confirmBtn.removeEventListener("click", handleConfirm);
  };
  confirmBtn.addEventListener("click", handleConfirm);
};

const handlePolygonComplete = (
  polygon: google.maps.Polygon,
  onConfirm: (data: FeatureType) => void
) => {
  const polygonPaths = polygon.getPath().getArray();
  const paths = polygonPaths.map((path) => {
    const lng = path.lng();
    const lat = path.lat();
    return [lng, lat] as Coordinates;
  });
  const fillColor = polygon.get("fillColor");
  const lineWidth = polygon.get("strokeWeight");
  const lineColor = polygon.get("strokeColor");
  const fillOpacity = polygon.get("fillOpacity");
  const lineOpacity = polygon.get("strokeOpacity");
  console.log({
    polygon,
  });
  const input: HTMLInputElement = document.getElementById(
    "input-drawing-name"
  ) as HTMLInputElement;
  const confirmBtn = document.getElementById(
    "input-drawing-confirm-btn"
  ) as HTMLButtonElement;
  input.parentElement!.style.display = "flex";
  input.value = "";
  input.focus();
  const handleConfirm = () => {
    if (input.value === "") {
      console.log(input.parentElement!.children);
      if (input.parentElement!.children.length > 3) {
        return console.error("Name is invalid.");
      }
      const errorSpan = document.createElement("span");
      errorSpan.id = "feature-name-error";
      errorSpan.innerText = "*";
      errorSpan.className =
        "font-semibold text-red text-md absolute left-0 top-0";
      input.parentElement!.appendChild(errorSpan);
      return console.error("Name is invalid.");
    } else {
      const errorSpan = document.getElementById("feature-name-error");
      errorSpan?.remove();
    }
    const data: FeatureType = {
      uuidv4: uuidv4(),
      drawing: polygon,
      drawingType: "polygon",
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: paths,
      },
      properties: {
        name: input.value,
        style: {
          lineWidth,
          lineColor,
          lineOpacity,
          fillOpacity,
          fillColor,
        },
        description: "",
      },
    };
    onConfirm(data);
    input.parentElement!.style.display = "none";
    confirmBtn.removeEventListener("click", handleConfirm);
  };
  confirmBtn.addEventListener("click", handleConfirm);
};

const handleTextComplete = (
  marker: google.maps.Marker,
  onConfirm: (data: FeatureType) => void
) => {
  const center = [
    marker.getPosition()!.lng(),
    marker.getPosition()!.lat(),
  ] as Coordinates;

  const input: HTMLInputElement = document.getElementById(
    "input-drawing-name"
  ) as HTMLInputElement;
  const confirmBtn = document.getElementById(
    "input-drawing-confirm-btn"
  ) as HTMLButtonElement;

  input.parentElement!.style.display = "flex";
  input.value = "";
  input.focus();
  const handleConfirm = () => {
    if (input.value === "") {
      console.log(input.parentElement!.children);
      if (input.parentElement!.children.length > 3) {
        return console.error("Name is invalid.");
      }
      const errorSpan = document.createElement("span");
      errorSpan.id = "feature-name-error";
      errorSpan.innerText = "*";
      errorSpan.className =
        "font-semibold text-red text-md absolute left-0 top-0";
      input.parentElement!.appendChild(errorSpan);
      return console.error("Name is invalid.");
    } else {
      const errorSpan = document.getElementById("feature-name-error");
      errorSpan?.remove();
    }
    marker.setLabel({
      fontSize: "24px",
      text: input.value,
    });
    const label = marker.getLabel() as google.maps.MarkerLabel;
    const data: FeatureType = {
      uuidv4: uuidv4(),
      drawing: marker,
      drawingType: "text",
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: center,
      },
      properties: {
        name: input.value,
        label: label.text,
        style: {
          fontSize: parseInt(label.fontSize!),
          fontColor: label.color,
        },
        description: "",
      },
    };

    confirmBtn.removeEventListener("click", handleConfirm);
    input.parentElement!.style.display = "none";
    onConfirm(data);
  };
  confirmBtn.addEventListener("click", handleConfirm);
};

export {
  importFacilitiesCSV,
  handleCircleComplete,
  handleMarkerComplete,
  handlePolygonComplete,
  handlePolylineComplete,
  handleTextComplete,
};
