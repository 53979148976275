import React, {useMemo} from "react";
import SearchableSelect, {SearchableSelectOption} from "../../components/form-v2/searchable-select";
import {useOperationalMapContext} from "../../providers/OperationalMapProvider";
import {Overlay} from "../../types/overlay";

export const OverlaySelect: React.FC = () => {

  const ctx = useOperationalMapContext()

  const onChange = (items: SearchableSelectOption<Overlay>[]) => {
    ctx.setSelectedOverlays(items.map(i => i.value))
  }

  const options = useMemo<SearchableSelectOption<Overlay>[]>(() => {
    if(ctx.overlayQuery.isLoading){
      return []
    }

    if(!ctx.overlayQuery.data){
      return []
    }

    return ctx.overlayQuery.data.pages.flatMap((p) => p.items)
      .filter(o => ctx.client ? o.clientID == ctx.client.id : true)
      .map((o) => ({
        search:  `${o.name} ${o.description}`,
        label: o.name,
        value: o,
        key: o.id,
    }))

  }, [ctx.overlayQuery.data])

  const selected = useMemo<SearchableSelectOption<Overlay>[]>(() => {
    return ctx.selectedOverlays.map((o) => ({
      search:  `${o.name} ${o.description}`,
      label: o.name,
      value: o,
      key: o.id,
    }))

  }, [ctx.selectedOverlays])

  return <div>
    <SearchableSelect
      localSearch={true}
      search={true}
      loading={ctx.overlayQuery.isLoading}
      multiple={true}
      onChange={onChange}
      options={options}
      selected={selected}
    />


  </div>

}