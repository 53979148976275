import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../../../components/portal/Modal";
import DeleteConfirmation from "../../../components/delete-confirmation";
import { useCustomOverlays, useGeofences, useZones } from "../../../services";
import { OverlaysDataTypes } from "../../../types";
import Row from "./Row";
import Checkbox from "../../../../../components/form/Checkbox";
import { useSearchParams } from "react-router-dom";
import useGetAllClients from "../../../../../api/clients/useGetAllClients";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";

type TablePropsType = {
  tableHeader: { label: string; icon?: string; id: string }[];
};

function Table({ tableHeader }: TablePropsType) {
  const { data: overlays } = useCustomOverlays().overlaysQuery;
  const [searchParams, setSearchParams] = useSearchParams();
  /**
   * Fetch
   */
  const { all_clients_data } = useGetAllClients();

  /**
   * Filter
   */
  const [filterByNameToggle, setFilterByNameToggle] = useState<boolean>(false);
  const [filterByClientToggle, setFilterByClientToggle] =
    useState<boolean>(false);

  useEffect(() => {
    if (!filterByNameToggle) return;
    nameInputRef.current?.focus();
  }, [filterByNameToggle]);

  /**
   * Set Data
   */

  const clientOptions: { label: string; value: string }[] =
    all_clients_data?.map((client) => ({
      label: client.name,
      value: client.id,
    }));
  const [filteredOverlays, setFilteredOverlays] =
    useState<OverlaysDataTypes[]>(overlays);

  useEffect(() => {
    const searchByName = searchParams.get("name");
    const searchByClientID = searchParams.getAll("clientID");

    const filteredOverlay = overlays
      .filter((overlay: OverlaysDataTypes) =>
        searchByName
          ? overlay.name.toLowerCase().includes(searchByName?.toLowerCase())
          : overlay
      )
      .filter((overlay: OverlaysDataTypes) =>
        searchByClientID.length < 1
          ? overlay
          : searchByClientID.includes(overlay.clientID)
      );

    setFilteredOverlays(filteredOverlay);
    return;
  }, [searchParams, overlays]);
  /**
   * Elements
   */
  const nameInputRef = useRef<HTMLInputElement>(null);
  const clientsSelectRef = useRef<HTMLDivElement>(null);

  /**
   * Utl fncs
   */
  useOnClickOutside(nameInputRef, () => setFilterByNameToggle(false));
  useOnClickOutside(clientsSelectRef, () => setFilterByClientToggle(false));

  const handleFilterByName = () => {
    setFilterByNameToggle(true);
  };

  const handleFilterByClient = () => {
    setFilterByClientToggle(true);
  };

  return (
    <div className="text-center flex flex-col gap-6">
      <div className="grid grid-cols-7 bg-desaturated-grey rounded-lg items-center h-20 font-semibold">
        {tableHeader.map((rowItem, i) => {
          return (
            <div
              className={` relative ${
                i < tableHeader.length - 1 ? "border-r" : null
              } relative`}
              key={rowItem.id}
            >
              <span>{rowItem.label}</span>
              {rowItem.icon && (
                <button
                  type="button"
                  className="absolute top-2/4 -translate-y-2/4 right-4"
                  onClick={
                    rowItem.id === "name"
                      ? handleFilterByName
                      : handleFilterByClient
                  }
                >
                  <img src={rowItem.icon} alt="" />
                </button>
              )}
              {rowItem.id === "name" ? (
                <input
                  type="text"
                  className={`absolute top-10 z-10 left-0 rounded-md h-10 w-full shadow-md px-4 ${
                    filterByNameToggle ? "inline" : "hidden"
                  }`}
                  placeholder="Seach"
                  onChange={(e) => {
                    const clients = searchParams.getAll("clientID");
                    const defaultParams = {
                      name: e.target.value,
                      clientID: clients,
                    };
                    setSearchParams(defaultParams);
                  }}
                  ref={nameInputRef}
                />
              ) : null}

              {rowItem.id === "client" ? (
                <div
                  className={`h-96 overflow-y-auto bg-white absolute top-10 z-10 left-0 rounded-md w-full shadow-md px-4 flex flex-col items-start justify-start gap-4 py-4 overflow-x-hidden ${
                    filterByClientToggle ? "flex" : "hidden"
                  }`}
                  ref={clientsSelectRef}
                >
                  {clientOptions.map((client) => {
                    return (
                      <Checkbox
                        key={client.value}
                        name="client"
                        value={client.value}
                        label={client.label}
                        onClick={() => {
                          const searchName = searchParams.get("name") as string;
                          const paramClients = searchParams.getAll("clientID");
                          const newParamClients = paramClients.filter(
                            (c) => c !== client.value
                          );
                          if (!paramClients.includes(client.value)) {
                            newParamClients.push(client.value);
                          }

                          const defaultParams = {
                            name: searchName,
                            clientID: newParamClients,
                          };
                          setSearchParams(defaultParams);
                        }}
                        checked={
                          searchParams.getAll("clientID").includes(client.value)
                            ? true
                            : false
                        }
                      />
                    );
                  })}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>

      <div className="flex flex-col gap-6 overflow-y-auto max-h-[60vh]">
        {filteredOverlays.map((overlay) => {
          return <Row key={overlay.id} overlay={overlay} />;
        })}
      </div>
    </div>
  );
}

export default Table;
