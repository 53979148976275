import { useQuery } from "react-query";
import api from "../../api/axiosConfig";
export default function useGetRoles() {
  const fetchRoles = async () => {
    return await api
      .get("/roles")
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  const { data, error, status } = useQuery("roles", fetchRoles);

  return { data: data?.data, error, status };
}
