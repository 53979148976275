import React from "react";
import { ButtonDownload } from "../../../../../components/ui/Buttons";
import AnnualSummaryTable from "../../../components/AnnualSummaryTable";

export default function AnnualReport() {
  const monthes = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // create an array of string and fill it with random twelve numbers
  const randomNumbers = Array.from({ length: 12 }, () =>
    Math.floor(Math.random() * 1000)
  );

  return (
    <div className="py-10 px-5">
      <AnnualSummaryTable />
      <AnnualSummaryTable showMonthes={false} />
      <AnnualSummaryTable showMonthes={false} />
      <AnnualSummaryTable showMonthes={false} />
    </div>
  );
}
