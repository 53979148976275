import React, { useContext } from "react";
import InputText from "../../../../../../components/form/InputText";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { FormDataContext } from "../../context/form";
function Medication() {
  const { data } = useContext(UserProfileContext)!;
  const userData: UserDataTypes = data?.data;
  const formDataContext = useContext(FormDataContext)!;
  const { medicationRef } = formDataContext;
  return (
    <div className="">
      <label htmlFor="medication" className="opacity-80">
        Medication
      </label>
      <InputText
        name={"medication"}
        placeholder="Enter Medication"
        onChange={() => {}}
        defaultValue={
          userData.medicalInformation?.medications
            ? userData.medicalInformation?.medications
            : ""
        }
        inputRef={medicationRef}
      />
    </div>
  );
}

export default Medication;
