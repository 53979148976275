import api from "../axiosConfig";
import { useQuery } from "react-query";
export default function useSiteBasedAlerts(facilityID: string, sbaID: string) {
  const fetchData = async () => {
    return await api
      .get(`sba/${facilityID}/${sbaID}`)
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  return useQuery(["sba", sbaID], fetchData, {
    keepPreviousData: false,
  });
}
