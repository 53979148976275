import React, {ReactNode} from "react";
import {ButtonCross} from "../Buttons";
import LoadingSpinner from "../LoadingSpinner";

type ActionModal = {
  children: ReactNode;
  icon?: string;
  buttonLabel?: string;
  closeButton: (e: React.SyntheticEvent) => void;
  mainButton: () => void;
  showButton?: boolean;
  smallButton?: boolean;
  iconAutoWidth?: boolean;
  userEmail?: string;
  color: "green" | "red" | "yellow" | "blue";
  infoFields?: { label: string; value: string }[];
  loading?: boolean;
};

export const ModalLayout = ({
                              children,
                              close,
                            }: {
  children: ReactNode;
  close: (e: React.SyntheticEvent) => void;
}) => {
  return (
      <div
          className="fixed top-0 left-0 w-full h-full bg-grey/80 flex justify-center items-center z-[100] text-center"
          onClick={close}
      >
        {children}
      </div>
  );
};

export const ContentModal = ({
                               children,
                               closeButton,
                             }: {
  children: ReactNode;
  closeButton: (e: React.SyntheticEvent) => void;
}) => {
  return (
      <div className="bg-white rounded-4xl border-6 border-blue relative border-4 border-blue z-[200]">
        <div className="absolute top-3 right-3">
          <ButtonCross smallButton={false} onClick={closeButton}/>
        </div>
        {children}
      </div>
  );
};

export const ActionModalColored = ({
                                     children,
                                     icon,
                                     color,
                                     closeButton,
                                     mainButton,
                                     buttonLabel,
                                     loading = false,
                                     iconAutoWidth = false,
                                     showButton = true,
                                     smallButton = false,
                                     infoFields = [],  // default to an empty array if no infoFields prop is provided

                                   }: ActionModal) => {
  let colorScheme = {
    border: "",
    buttonOuterBorder: "",
    buttonBorder: "",
    buttonColor: "",
    textColor: "",
  };

  switch (color) {
    case "red":
      colorScheme = {
        border: "border-light-red",
        buttonOuterBorder: "bg-light-red",
        buttonBorder: "bg-red/30",
        buttonColor: "bg-red",
        textColor: "text-dark-red",
      };
      break;
    case "green":
      colorScheme = {
        border: "border-desaturated-green",
        buttonOuterBorder: "bg-desaturated-green",
        buttonBorder: "bg-green/30",
        buttonColor: "bg-green",
        textColor: "text-dark-green",
      };
      break;
    case "yellow":
      colorScheme = {
        border: "border-light-orange",
        buttonOuterBorder: "bg-light-orange",
        buttonBorder: "bg-dark-orange/30",
        buttonColor: "bg-dark-orange",
        textColor: "text-dark-orange",
      };
      break;
    case "blue":
      colorScheme = {
        border: "border-light-blue",
        buttonOuterBorder: "bg-light-blue",
        buttonBorder: "bg-blue/30",
        buttonColor: "bg-blue",
        textColor: "text-grey",
      };
      break;
    default:
      break;
  }

  return (
      <div
          className={`relative border-[10px] ${colorScheme.border} rounded-4xl w-[340px] h-[300px] bg-white p-5`}
      >
        <div
            className={`flex flex-col items-center justify-center w-full h-full text-center ${colorScheme.textColor}`}
        >
          {children}
        </div>
        <div className="absolute top-3 right-3">
          <ButtonCross smallButton={false} onClick={closeButton}/>
        </div>
        {showButton && (
            <div
                className={`absolute bottom-0 left-[50%] transform -translate-x-1/2 translate-y-1/2 ${
                    smallButton ? "w-[120px] h-[120px]" : "w-[170px] h-[170px]"
                }  ${colorScheme.buttonOuterBorder} rounded-full p-2`}
            >
              <div
                  className={`rounded-full ${colorScheme.buttonBorder} w-full h-full p-2`}
              >
                <button
                    className={`rounded-full w-full h-full flex justify-center items-center ${colorScheme.buttonColor}`}
                    onClick={mainButton}
                >
                  <div
                      className={`${iconAutoWidth ? "auto" : "w-[43px]"} ${
                          !icon && buttonLabel ? "w-[75px]" : ""
                      }`}
                  >
                    {loading ? <LoadingSpinner color={"white"}/> : <>

                      {icon ? (
                          <img src={icon} alt="" className="pointer-events-none"/>
                      ) : (
                          <span className="text-white">{buttonLabel}</span>
                      )}
                    </>}
                  </div>
                </button>
              </div>
            </div>
        )}
      </div>
  );
};


export const AdditionalInfoModel = ({
                                      children,
                                      icon,
                                      color,
                                      closeButton,
                                      mainButton,
                                      buttonLabel,
                                      iconAutoWidth = false,
                                      showButton = true,
                                      smallButton = false,
                                      infoFields = [],  // default to an empty array if no infoFields prop is provided

                                    }: ActionModal) => {
  let colorScheme = {
    border: "",
    buttonOuterBorder: "",
    buttonBorder: "",
    buttonColor: "",
    textColor: "",
  };

  switch (color) {
    case "red":
      colorScheme = {
        border: "border-light-red",
        buttonOuterBorder: "bg-light-red",
        buttonBorder: "bg-red/30",
        buttonColor: "bg-red",
        textColor: "text-dark-red",
      };
      break;
    case "green":
      colorScheme = {
        border: "border-desaturated-green",
        buttonOuterBorder: "bg-desaturated-green",
        buttonBorder: "bg-green/30",
        buttonColor: "bg-green",
        textColor: "text-dark-green",
      };
      break;
    case "yellow":
      colorScheme = {
        border: "border-light-orange",
        buttonOuterBorder: "bg-light-orange",
        buttonBorder: "bg-dark-orange/30",
        buttonColor: "bg-dark-orange",
        textColor: "text-dark-orange",
      };
      break;
    case "blue":
      colorScheme = {
        border: "border-light-blue",
        buttonOuterBorder: "bg-light-blue",
        buttonBorder: "bg-blue/30",
        buttonColor: "bg-blue",
        textColor: "text-grey",
      };
      break;
    default:
      break;
  }

  return (
      <div
          className={`relative border-[10px] ${colorScheme.border} rounded-4xl w-[570px] h-[450px] bg-white p-5`}
      >
        <div
            className={`flex flex-col  justify-center  w-full h-full text-center ${colorScheme.textColor}`}
        >
          {children}
        </div>
        <div className="absolute top-3 right-3">
          <ButtonCross smallButton={false} onClick={closeButton}/>
        </div>
        {showButton && (
            <div
                className={`absolute bottom-0 left-[50%] transform -translate-x-1/2 translate-y-1/2 ${
                    smallButton ? "w-[120px] h-[120px]" : "w-[170px] h-[170px]"
                }  ${colorScheme.buttonOuterBorder} rounded-full p-2`}
            >
              <div
                  className={`rounded-full ${colorScheme.buttonBorder} w-full h-full p-2`}
              >
                <button
                    className={`rounded-full w-full h-full flex justify-center items-center ${colorScheme.buttonColor}`}
                    onClick={mainButton}
                >
                  <div
                      className={`${iconAutoWidth ? "auto" : "w-[43px]"} ${
                          !icon && buttonLabel ? "w-[75px]" : ""
                      }`}
                  >
                    {icon ? (
                        <img src={icon} alt="" className="pointer-events-none"/>
                    ) : (
                        <span className="text-white">{buttonLabel}</span>
                    )}
                  </div>
                </button>
              </div>
            </div>
        )}
      </div>
  );
};



