import React, {
  FormEvent,
  RefObject,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { BlueButton } from "../../../../components/ui/Buttons";
import { FeatureType } from "../../types";
import { useCustomOverlays } from "../../services";
import { Check, Close } from "@mui/icons-material";
import Modal from "../../../../components/portal/Modal";
import { useNavigate } from "react-router-dom";
import { generateMapSearchBox } from "../../../../util/map";
import featuresReducer from "../../reducers/featuresReducer";
import ClientSelect from "../../../../components/form-v2/client-select";
import FeaturesPanel from "../features-panel";
import DrawingTool from "../drawing-tool";
import EditFeature from "../edit-feature";

type AddCustomOverlayPropsType = {
  className?: string;
  formRef: RefObject<HTMLFormElement>;
  map: google.maps.Map | undefined;
  drawingManager: google.maps.drawing.DrawingManager | undefined;
};

function AddCustomOverlay({
  className,
  formRef,
  map,
  drawingManager,
}: AddCustomOverlayPropsType) {
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const [disableDrawing, setDisableDrawing] = useState<boolean>(false);
  const navigate = useNavigate();
  const [featuresState, featuresDispatch] = useReducer(featuresReducer, []);
  const [toggleFeatures, setToggleFeatures] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const { createMutation: createOverlay } = useCustomOverlays();
  const [drawingMode, setDrawingMode] = useState<
    "marker" | "polygon" | "polyline" | "circle" | "text" | null
  >(null);
  const inputNameRef = useRef<HTMLInputElement>(null);
  const inputConfirmBtnRef = useRef<HTMLButtonElement>(null);

  const handleSearchLocation = (map: google.maps.Map) => {
    const input = document.getElementById("pac-input") as HTMLInputElement;
    generateMapSearchBox(input, map);
  };

  const addFeature = (data: FeatureType) => {
    console.log("triggered add feature");
    featuresDispatch({ type: "ADD_FEATURE", payload: data });
    setDisableDrawing(false);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const formData = new FormData(formRef.current!);
    const overlayName = formData.get("name");
    const overlayDescription = formData.get("description");
    const clientID = formData.get("client") as string;
    if (!overlayName) {
      console.error("Name is required");
      return;
    }

    if (!clientID) {
      console.error("Client not selected.");
      return;
    }

    if (featuresState.length < 1) {
      console.error("No features were added");
      return;
    }
    const features = featuresState.map((ft) => ({
      type: ft.type,
      properties: ft.properties,
      geometry: ft.geometry,
    }));
    const newData = {
      name: overlayName,
      description: overlayDescription,
      clientID,
      data: {
        type: "FeatureCollection",
        features,
      },
    };
    createOverlay.mutateAsync(newData);
  };

  useEffect(() => {
    if (featuresState.length < 1) {
      setToggleFeatures(false);
    }
  }, [featuresState]);

  const clickButtonOnEnter = (event: KeyboardEvent) => {
    // Check if the pressed key is Enter (key code 13)
    if (event.keyCode === 13) {
      // Trigger a click on the button with the id "yourButtonId"
      const confirmBtn = document.getElementById("input-drawing-confirm-btn");
      const confirmLabelTextBtn = document.getElementById(
        "input-label-confirm-btn"
      ) as HTMLButtonElement;
      if (confirmBtn) {
        confirmBtn.click();
      }
      if (confirmLabelTextBtn) {
        confirmLabelTextBtn.click();
      }
    }
  };

  const onEscPress = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      setDrawingMode(null);
      drawingManager?.setDrawingMode(null);
    }
  };

  useEffect(() => {
    document.addEventListener("keypress", clickButtonOnEnter);
    document.addEventListener("keydown", onEscPress);
    return () => {
      document.removeEventListener("keypress", clickButtonOnEnter);
      document.removeEventListener("keydown", onEscPress);
    };
  }, [drawingManager]);

  return (
    <>
      <form
        className={`bg-white text-grey px-8 pb-8 rounded-lg ${className}`}
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <header className="text-center relative">
          <h4 className="my-4 font-semibold text-base">Add Custom Overlay</h4>
          <button
            className="absolute right-0 top-0"
            onClick={() => navigate("/sites/custom-overlay")}
            type="button"
          >
            <Close />
          </button>
        </header>
        <hr />
        <div className="flex flex-col gap-4 py-4">
          <input
            type="text"
            name="name"
            placeholder="name"
            className="h-10 w-full rounded-md shadow-md px-4"
          />
          <textarea
            name="description"
            id="description"
            cols={30}
            rows={5}
            className="bg-desaturated-light-grey w-full p-4 rounded-lg outline-none shadow-md"
            placeholder="Add Description"
          ></textarea>
          <ClientSelect />
        </div>
        <hr />
        <div className="w-full flex items-center flex-col my-6">
          <h4 className="font-semibold text-center">Drawing Tools</h4>
          <div className="flex flex-col gap-8 items-center my-4">
            <div className="">
              <input
                type="text"
                id="pac-input"
                placeholder="Search Location"
                className="h-10 w-full max-w-[240px] mr-7 text-base px-4 shadow-md"
                onFocus={() => handleSearchLocation(map!)}
              />
            </div>
            <DrawingTool
              disableDrawing={disableDrawing}
              setDrawingMode={setDrawingMode}
              drawingMode={drawingMode}
              drawingManager={drawingManager}
              addFeature={addFeature}
              setDisableDrawing={setDisableDrawing}
              setToggleFeatures={setToggleFeatures}
              map={map}
              setDisableSave={setDisableSave}
            />
            <div className="rounded-md bg-white hidden overflow-hidden shadow-md relative">
              <input
                type="text"
                placeholder="Enter feature name"
                className=" px-4 py-2"
                ref={inputNameRef}
                id="input-drawing-name"
                onFocus={() => setDisableSave(true)}
                maxLength={150}
                onChange={(e) => {
                  const limitReachedI = document.getElementById(
                    "input-drawing-name-char-limit"
                  );
                  if (!limitReachedI) return;
                  if (e.target.value.length < 150) {
                    limitReachedI.style.display = "none";
                    return;
                  } else {
                    limitReachedI.style.display = "inline";
                  }
                }}
              />

              <button
                className="h-10 w-10 rounded-md bg-white"
                ref={inputConfirmBtnRef}
                id="input-drawing-confirm-btn"
                type="button"
              >
                <Check />
              </button>
            </div>
            <span
              id="input-drawing-name-char-limit"
              className="text-sm text-red"
              style={{ display: "none" }}
            >
              Maximum characters reached.
            </span>
          </div>
        </div>
        <hr />
        <div className="pt-12 pb-4">
          <BlueButton
            text="Save"
            type="button"
            onClick={handleSubmit}
            disabled={disableSave}
          />
        </div>
      </form>

      <FeaturesPanel
        setToggleFeatures={setToggleFeatures}
        toggleFeatures={toggleFeatures}
        features={featuresState}
        deleteFeature={(payload: FeatureType) =>
          featuresDispatch({ type: "DELETE_FEATURE", payload })
        }
        setEditModal={setEditModal}
        map={map}
      />

      <Modal open={editModal} setOpen={setEditModal}>
        <EditFeature
          setEditModal={setEditModal}
          onSubmit={(data: FeatureType) => {
            featuresDispatch({ type: "EDIT_FEATURE", payload: data });
          }}
          features={featuresState}
        />
      </Modal>
    </>
  );
}

export default AddCustomOverlay;
