import { RefObject, SetStateAction } from "react";

type Proptypes = {};

export default function importFacilitiesCSV(
  fileInputRef: RefObject<HTMLInputElement>,
  clientRef: RefObject<HTMLSelectElement>,
  user: any,
  setClientPopup: React.Dispatch<SetStateAction<boolean>>,
  setSuccess: React.Dispatch<
    SetStateAction<{ code: number; message: string } | null>
  >
) {
  const formData = new FormData();
  const fileInput = fileInputRef.current;

  const handleSuccessMessage = (res: Response) => {
    res.json().then((body) => {
      console.log({body})
      setSuccess({ code: res.status * 1, message: body.message });

    });
  };

  if (!fileInput) {
    return console.error("File input element not found");
  }

  if (!fileInput.files || fileInput.files.length === 0) {
    return console.error("No file selected");
  }
  const csvFile = fileInput.files[0];
  console.log(csvFile); // logs the attached file
  formData.append("file", csvFile);
  if (user?.role.auroraAccessLevel === "all") {
    const clientID = clientRef.current?.value;
    const file = formData.get("file");
    console.log(file);
    fetch(
      `${process.env.REACT_APP_API_URL}facilities-bulk-create?clientID=${clientID}`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => {
        console.log(res);
        fileInputRef.current!.value = "";
        handleSuccessMessage(res);
        setClientPopup(false);
      })
      .catch((err) => {
        handleSuccessMessage(err);
        fileInputRef.current!.value = "";
      });
  } else {
    fetch(`${process.env.REACT_APP_API_URL}facilities-bulk-create`, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        console.log(res);
        fileInputRef.current!.value = "";
      })
      .catch((err) => {
        handleSuccessMessage(err);
        fileInputRef.current!.value = "";
      });
  }
}
