import React from "react";
import { UsersType } from "../../../../../context/country-profiles";
import { CountryProfileDataTypes } from "../../../../../types/country-profile-data-types";

function AverageRiskLevel({
  color,
  data,
}: {
  color: string;
  data: CountryProfileDataTypes;
}) {
  return (
    <div
      className={`h-8 w-8 shadow-md rounded-full ${
        color !== "#00000080" ? "text-white" : null
      } flex justify-center items-center text-sm`}
      style={{ backgroundColor: color !== "#00000080" ? color : "#ffffff" }}
    >
      {data.averageRiskLevel ? data.averageRiskLevel : "N/A"}
    </div>
  );
}

export default AverageRiskLevel;
