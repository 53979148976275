import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Checkbox from "../../../../../../components/form/Checkbox";
import { WhiteButton } from "../../../../../../components/ui/Buttons";

function CustomOverlaySidebarFilter() {
  const [searchParams] = useSearchParams();
  const [isCustomOverlay, setIsCustomOverlay] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const siteTypes = searchParams.getAll("siteTypes");

    if (siteTypes.includes("custom-overlay")) {
      setIsCustomOverlay(true);
    } else {
      setIsCustomOverlay(false);
    }
  }, [searchParams]);

  return (
    <div className="font-semibold">
      <Checkbox
        value="custom-overlay"
        label="Custom Overlay"
        onClick={() => setIsCustomOverlay((prev) => !prev)}
        name="siteTypes"
        checked={isCustomOverlay}
      />
      <div className="font-normal flex flex-col gap-2 my-2">
        <WhiteButton
          text="View / Edit"
          onClick={() => navigate("/sites/custom-overlay")}
        />
        <WhiteButton
          text="Add Custom Overlay"
          onClick={() => {
            navigate("/sites/add-custom-overlay");
          }}
        />
      </div>
    </div>
  );
}

export default CustomOverlaySidebarFilter;
