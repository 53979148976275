import React from "react";
import { useNavigate } from "react-router-dom";
import AverageRiskLevel from "./AverageRiskLevel";
import { keys } from "../../../../../constants/keys";
import { FlagIcon } from "../../../../../components/ui/FlagIcon";
import { Bookmark, BookmarkBorder } from "@mui/icons-material";
import { DownloadButtonGreyOutline } from "../../../../../components/ui/Buttons";
import { CountryProfileDataTypes } from "../../../../../types/country-profile-data-types";
import { downloadFile } from "../../../../../util/downloadFile";
import useSavedCountries from "../services/useSavedCountries";
function CountryProfileListItem({ data }: { data: CountryProfileDataTypes }) {
  const navigate = useNavigate();
  const key = keys.filter((key) =>
    data.averageRiskLevel && key.level === data.averageRiskLevel ? key : null
  );

  const color = key[0]?.color || "#00000080";

  const handleProfileDownload = () => {
    console.log(`/files/country-profile/${data.countryISO}`);

    downloadFile(
      `${process.env.REACT_APP_API_URL}files/country-profile/${data.countryISO}`,
      `${data.country}.pdf`
    );
  };
  const savedCountries: CountryProfileDataTypes[] =
    useSavedCountries().savedCountriesQuery.data;
  const { saveCountryMutation } = useSavedCountries();
  const handleSave = async () => {
    const savedCountryIDs = savedCountries.map((country) => country._id);
    if (savedCountryIDs.includes(data._id)) {
      return await saveCountryMutation.mutateAsync(
        savedCountryIDs.filter((id) => id !== data._id)
      );
    }
    return await saveCountryMutation.mutateAsync([
      ...savedCountryIDs,
      data._id,
    ]);
  };

  return (
    <li
      className={"px-4 py-2 relative border-[3px] bg-desaturated-light-grey rounded-2xl flex items-center gap-2"}
      style={{ borderColor: color }}
    >
      <div className="bg-white p-[2px] h-7 w-7 rounded-full shadow-[0px_4px_10px_-3px_#00000070]">
        <FlagIcon country_code={data.countryISO} />
      </div>
      <span
        className="flex-1 cursor-pointer"
        onClick={() => navigate(`${data.countryISO}/overview`)}
      >
        {data.country}
      </span>

      <div className="flex items-center justify-center gap-4">
        <AverageRiskLevel color={color} data={data} />

        <div className="flex items-center gap-4 z-10">
          <DownloadButtonGreyOutline onClick={handleProfileDownload} />
          <button className="h-8 w-8" onClick={handleSave}>
            {savedCountries
              ?.map((country) => country._id)
              .includes(data._id) ? (
              <Bookmark className="text-yellow" />
            ) : (
              <BookmarkBorder />
            )}
          </button>
        </div>
      </div>
    </li>
  );
}

export default CountryProfileListItem;
