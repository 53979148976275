import React, {
  MouseEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import ArrowDownIcon from "../../assets/icons/arrow-down.svg";

type DropDownTypes = {
  options?: string[];
  title: string;
  dropDownIcon?: string;
  biggerInput?: boolean;
  children?: ReactNode;
  onSelect?: (target: HTMLElement) => void;
  isMultiple?: boolean;
  className?: string;
  transparentBox?: boolean;
  disabled?: boolean;
  selected?: boolean;
  selectedValue?: string;
};

function DropDown({
  title,
  dropDownIcon,
  biggerInput = false,
  children,
  transparentBox = false,
  className,
  onSelect,
  isMultiple,
  disabled = false,
  selected = false,
  selectedValue,
}: DropDownTypes) {
  const borderColor = selected ? "border-grey" : "border-none"; // conditionally setting the border color
  const headerClass = `flex w-full gap-4 items-center cursor-pointer justify-center border ${borderColor} ${
    biggerInput
      ? "px-8 py-7 h-fit rounded-3xl shadow-lg"
      : "rounded-md h-12 px-4 shadow-md"
  } ${
    disabled ? "bg-light-grey bg-opacity-30 cursor-not-allowed" : "bg-white"
  }`;
  const listClass = `list-type-none 
  h-fit 
  max-h-52 
  z-20 
  overflow-y-scroll 
  absolute 
  top-[110%] 
  border-grey
  w-full 
  bg-white 
  shadow-md 
  rounded-lg 
  p-2
  flex-col 
  gap-2`;

  const transparentBoxClass = "list-type-none h-fit w-full bg-transparent z-20 absolute top-[120%] flex justify-center items-center";

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // @ts-ignore
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // @ts-ignore
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`relative ${className ? className : ""} w-full`}
      ref={wrapperRef}
    >
      <div
        className={headerClass}
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        <img src={dropDownIcon} alt="" className="" />
        <span className={`flex-1 ${selected ? "text-grey" : ""}`}>
          {selectedValue ? selectedValue : title}
        </span>
        <img src={ArrowDownIcon} alt="" className="" />
      </div>

      {isOpen && (
        <div className={transparentBox ? transparentBoxClass : listClass}>
          {children}
        </div>
      )}
    </div>
  );
}

export default DropDown;
