import { useQuery } from "react-query";
import api from "../axiosConfig";
export default function useGetSiteBasedAlertsPerFacility(
  facilityID: string | null
) {
  const fetchSBAs = async () => {
    return await api
      .get(`sba/${facilityID}`)
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  return useQuery(["facility-sba", facilityID], fetchSBAs);
}
