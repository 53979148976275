import React, { useContext } from "react";
import NationalitySelect from "../../../../../../components/form/NationalitySelect";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { FormDataContext } from "../../context/form";
function Nationality() {
  const { data } = useContext(UserProfileContext)!;
  const formDataContext = useContext(FormDataContext)!;
  const { nationalityRef } = formDataContext;
  const userData: UserDataTypes = data?.data;
  const nationality = userData.profile?.nationality;

  return (
    <div className="">
      <label htmlFor="nationality" className="opacity-80">
        Nationality
      </label>
      <NationalitySelect
        defaultValue={nationality?.toLowerCase()}
        nationalityRef={nationalityRef}
      />
    </div>
  );
}

export default Nationality;
