import React, {useEffect, useState} from "react";
import api from "../../../../api/axiosConfig"; // Make sure this points to your api file
import LoadingSpinner from "../../../../components/ui/LoadingSpinner";
import {useNavigate} from "react-router-dom";

function AirlineRiskUpload(): JSX.Element {
  const [file, setFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);

  const user = localStorage.getItem("user");
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/settings");
      return
    }
    const userData = JSON.parse(user);

    if (userData?.role?.auroraAccessLevel !== "all") {
      navigate("/settings");
      return
    }
  }, [user]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFile(files[0]); // Update the file state to the first file selected
      setFileSelected(true);
    } else {
      setFile(null); // Clear the file state if no file is selected
      setFileSelected(false);
    }
  };

  const handleUpload = async () => {
    if (!file) { // Ensure there is a file to upload
      alert("Please select a file first!");
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file); // Append the selected file

    try {
      const response = await api.post("/upload-airline-risk", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setUploadStatus("Upload successful!");
      console.log(response.data); // Handle response according to your needs
    } catch (error) {
      setUploadStatus("Upload failed!");
      console.error("Error uploading file:", error);
    } finally {
      setIsLoading(false); // Stop loading regardless of the result
    }
  };

  return (
      <div className="flex flex-col items-center justify-center p-10 space-y-4 bg-gray-100 rounded-lg shadow-md">
        <input
            type="file"
            onChange={handleFileChange}
            accept=".csv"
            className="file:mr-4 file:py-2 file:px-4
                   file:rounded-full file:border-0
                   file:text-sm file:font-semibold
                   file:bg-violet-50 file:text-violet-700
                   hover:file:bg-violet-100"
        />
        <button
            onClick={handleUpload}
            disabled={!fileSelected}
            className="px-4 py-2 bg-blue text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Upload
        </button>
        {uploadStatus && (
            <div
                className={`px-4 py-2 rounded text-sm ${uploadStatus === "Upload successful!" ? "bg-light-green text-dark-green" : "bg-low-red text-dark-red"}`}
            >
              {uploadStatus}
            </div>
        )}
        {isLoading && <LoadingSpinner/>}
      </div>
  );
}

export default AirlineRiskUpload;
