import { useQuery } from "react-query";
import api from "../axiosConfig";
import { DepartmentDataTypes } from "../../types/department-data-types";

export default function useGetAllClientDepartments(id: string) {
  const fetchDepartments = async (page: number) => {
    return await api
      .get(`/departments?clientID=${id}&page=${page}`, { params: { rpp: 200 } })
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  const departmentsQuery = useQuery(["departments", id], () =>
    fetchDepartments(1)
  );

  const departmentsQueryData = departmentsQuery.data?.data.items;

  const fetchAllDepartments = async () => {
    const totalDepartments = departmentsQuery.data?.data.total;
    const departmentsPerPage = departmentsQuery.data?.data.items.length;
    const totalPagesCalc = Math.ceil(totalDepartments / departmentsPerPage);
    const pageFetches = Array.from({ length: totalPagesCalc }, (v, i) =>
      fetchDepartments(i)
    );

    return await Promise.all(pageFetches);
  };

  const { data, status, error, refetch } = useQuery({
    queryKey: ["client-departments", id],
    queryFn: () => fetchAllDepartments(),
    enabled: !!departmentsQueryData,
  });

  const extractDepartmentsFromEachPage = () => {
    if (status !== "success") return [];

    const arr: DepartmentDataTypes[] = [];
    data.map((page) => {
      const items = page!.data.items;
      items?.forEach((item: DepartmentDataTypes) => {
        arr.push(item);
      });
    });

    return arr;
  };
  const extractedData = extractDepartmentsFromEachPage();
  return {
    data: extractedData,
    status,
    error,
    refetch,
  };
}
