import { Close } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

function ExternalIDInputWrapper({
  item,
  index,
  handleChange,
  handleRemove,
}: {
  item: { type: string; value: string };
  index: number;
  handleChange: (data: { type: string; value: string }, index: number) => void;
  handleRemove: (value: string, index: number) => void;
}) {
  const [data, setData] = useState<{ type: string; value: string }>(item);

  useEffect(() => {
    handleChange(data, index);
  }, [data]);

  useEffect(() => {
    setData(item);
  }, [item]);

  return (
    <div className="grid grid-cols-4 gap-8 relative">
      <div className="flex flex-col">
        <label htmlFor="ice-cream-choice">Type</label>
        <input
          type="text"
          list="type-name"
          id="type"
          name="external-id-type"
          className="h-10 w-full shadow-md rounded-md px-4"
          value={data.type}
          onChange={(e) =>
            setData((prev) => {
              const oldVal = { ...prev };
              oldVal.type = e.target.value;
              return oldVal;
            })
          }
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="">Value</label>

        <input
          type="text"
          name="external-id-value"
          className="h-10 w-full shadow-md rounded-md px-4"
          value={data.value}
          onChange={(e) =>
            setData((prev) => {
              const oldVal = { ...prev };
              oldVal.value = e.target.value;
              return oldVal;
            })
          }
        />
      </div>

      <button
        className="h-10 w-10 my-auto"
        type="button"
        onClick={() => handleRemove(data.value, index)}
      >
        <Close />
      </button>

      <datalist id="type-name">
        <option value="voyage_manager"></option>
        <option value="salesforce"></option>
      </datalist>
    </div>
  );
}

export default ExternalIDInputWrapper;
