import {UserActivityDataTypes} from "./user-activity-data-types"

export const userActivityLabels: Record<string, string> = {
  "check_in_missed": "Check In Overdue",
  "incident_violation": "Incident Vicinity Alert"
}

export const userActivityDisplayLabel = (userData: UserActivityDataTypes): string => {
  if (userActivityLabels[userData.type]) return userActivityLabels[userData.type]

  return userData.type.replaceAll("_", " ")
}