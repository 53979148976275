import React from "react";
import { useQuery } from "react-query";
import { CountryProfileDataTypes } from "../../types/country-profile-data-types";
import api from "../axiosConfig";

export default function useGetAllCountryProfiles() {
  const fetchCountryProfiles = async (page: number) => {
    return await api
      .get(`country-profile?page=${page}`, { params: { rpp: 200 } })
      .then((res) => res)
      .catch((err) => console.error(err));
  };
  const countryProfilesQuery = useQuery("country-profiles", () =>
    fetchCountryProfiles(1)
  );
  const countryProfilesQueryData = countryProfilesQuery.data?.data.items;

  const fetchAllCountryProfiles = async () => {
    const totalCountryProfiles = countryProfilesQuery.data?.data.total;
    const countryProfilesPerPage = countryProfilesQuery.data?.data.items.length;
    const totalPagesCalc = Math.ceil(
      totalCountryProfiles / countryProfilesPerPage
    );

    const pageFetches = Array.from({ length: totalPagesCalc }, (v, i) => {
      return fetchCountryProfiles(i + 1);
    });
    return await Promise.all(pageFetches);
  };

  const { data, status, error } = useQuery({
    queryKey: "all-country-profiles",
    queryFn: () => fetchAllCountryProfiles(),
    enabled: !!countryProfilesQueryData,
  });

  const extractCountryProfilesFromEachPage = () => {
    if (status !== "success") return null;
    const arr: CountryProfileDataTypes[] = [];
    data.map((page) => {
      const items = page!.data.items;
      items?.forEach((item: CountryProfileDataTypes) => {
        arr.push(item);
      });
    });
    return arr;
  };
  const extractedData = extractCountryProfilesFromEachPage();
  return {
    all_country_profiles_data: extractedData,
    all_country_profiles_status: status,
    all_country_profiles_error: error,
  };
}
