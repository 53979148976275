/**
 * @description checks if the accessed link is restricted for the user. If its a restricted link then navigates the user to the first allowed link for the user. If it is an allowed link, then the page is rendered.
 */
import React from "react";
import { OidcSecure } from "@axa-fr/react-oidc";
import { useClientBasedAppRestriction } from "../restrictions";

type ProtectedRouteTypes = {
  children: React.ReactNode;
};

function ProtectedRouteClient({ children }: ProtectedRouteTypes) {
  useClientBasedAppRestriction();
  return <OidcSecure>{children}</OidcSecure>;
}

export default ProtectedRouteClient;
