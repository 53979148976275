import moment from "moment";
import React from "react";



interface Location {
  lat: number;
  lng: number;
  countryiso: string;
}

interface Metadata {
  location: Location;
}


interface MessageEntryProps {
  message: {
    _id: string;
    userID: string;
    source: string;
    sentAt: string;
    message: string;
    target: string;
    userName: string;
    metadata: Metadata;
  };
}

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };


const MessageEntry: React.FC<MessageEntryProps> = ({ message }) => {
  const coordinates = message.metadata && (message.metadata.location.lat !== 0 || message.metadata.location.lng !== 0)
    ? `${message.metadata.location.lat.toFixed(2)}, ${message.metadata.location.lng.toFixed(2)}`
    : "";



  return (
    <tr className="border-b border-gray-200 text-center">
      <td className="px-4 py-2">{message.userName}</td>
      <td className="px-4 py-2">
        {capitalizeFirstLetter(message.target === "user" ? "ops" : "user")}
      </td>
      <td className="px-4 py-2">{moment(message.sentAt).local().format("MMM DD, YYYY hh:mm A")}</td>
      <td className="px-4 py-2">{message.metadata.location.countryiso}</td>
      <td className="px-4 py-2">{coordinates}</td>
      <td className="px-4 py-2">{message.message}</td>
    </tr>
  );
};

export default MessageEntry;
