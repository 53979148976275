import React, { Dispatch, FormEvent, SetStateAction, useRef } from "react";
import { BlueButton } from "../../../../../../components/ui/Buttons";
import Checkbox from "../../../../../../components/form/Checkbox";
import api from "../../../../../../api/axiosConfig";
import { useNavigate } from "react-router-dom";

function AddClient({
  setFormStage,
}: {
  setFormStage?: Dispatch<SetStateAction<"client" | "departments" | "users">>;
}) {
  const navigate = useNavigate();
  const clientNameRef = useRef<HTMLInputElement>(null);
  const emergencyPhoneNumberRef = useRef<HTMLInputElement>(null);
  const emergencyEmailAddressRef = useRef<HTMLInputElement>(null);
  const sendAlertsToNGSRef = useRef<HTMLInputElement>(null);
  const handleAddClient = (e: FormEvent) => {
    e.preventDefault();
    if (!clientNameRef.current!.value) {
      console.error("client name required");
    }
    const formData = {
      name: clientNameRef.current!.value,
      type: "direct",
      alertEmailAddresses: emergencyEmailAddressRef.current!.value,
      sendAlertsToNGS: sendAlertsToNGSRef.current!.checked,
      clientAlarmConfig: {
        emergencyPhoneNumber: emergencyPhoneNumberRef.current!.value,
      },
    };

    api
      .post("clients", formData)
      .then((res) => {
        console.log("added the following client data to database", res.data);
        navigate(`add-departments/${res.data.id}`);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <form onSubmit={(e: FormEvent) => handleAddClient(e)}>
      <input
        type="text"
        placeholder="Client Name"
        className="h-10 max-w-lg w-full shadow-md rounded-md px-4 my-8"
        required
        ref={clientNameRef}
      />
      <div className="">
        <div className="w-full flex h-20 relative items-center justify-center">
          <div className="bg-white px-10 relative z-10">
            <p className="">Customisation</p>
          </div>
          <hr className="absolute w-full top-2/4 -translate-y-2/4" />
        </div>
        <div className="flex flex-col gap-20">
          <div className="grid grid-cols-[60%_40%] gap-10">
            <div className="flex flex-col gap-4">
              <h6 className="font-semibold">Custom App Plus Phone Numbers</h6>
              <p className="w-3/4">{`These are triggered when alerts on the App Plus are activated If
              left blank, the emergency alerts will come through to our
              operations team on ${(
                <br />
              )} +44 207 183 8910. If you would like to
              have a custom hotline number, please contact our operations team.`}</p>
            </div>

            <div className="">
              {" "}
              <div className="flex flex-col gap-8 justify-end">
                <div className="flex flex-col">
                  <label htmlFor="client_level" className="opacity-80">
                    Client Level
                  </label>
                  <input
                    type="text"
                    placeholder="Emergency Alert Phone Number"
                    name={"client_level"}
                    className="h-10 max-w-lg w-full shadow-md rounded-md px-4 my-4"
                    required
                    ref={emergencyPhoneNumberRef}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-[60%_40%] gap-10">
            <div className="flex flex-col gap-4">
              <h6 className="font-semibold">Custom Alert Email Address</h6>
              <p className="w-3/4">
                These are sent as a failsafe if the Aurora platform fails for
                any reason. If left blank, the alerts are sent to
                t2ops@northcottglobalsolutions.com
              </p>
            </div>

            <div className="">
              {" "}
              <div className="flex flex-col gap-4">
                <div className="flex flex-col">
                  <label htmlFor="client_level" className="opacity-80">
                    Emergency
                  </label>
                  <input
                    type="text"
                    placeholder="Emergency Alert Email Address"
                    name={"emergency-email-address"}
                    className="h-10 max-w-lg w-full shadow-md rounded-md px-4 my-4"
                    required
                    ref={emergencyEmailAddressRef}
                  />
                </div>

                <Checkbox
                  name="ngs-alerts"
                  checked={false}
                  value={"ngs-alerts"}
                  label={"Send alerts to NGS"}
                  onClick={() => {}}
                  inputRef={sendAlertsToNGSRef}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-md mx-auto my-24 flex flex-col gap-8">
        <BlueButton
          text="Next"
          type="submit"
          onClick={(e: FormEvent) => handleAddClient(e)}
        />
        <div className="flex gap-4 justify-center">
          <div className="h-2 w-2 rounded-full bg-light-grey"></div>
          <div className="h-2 w-2 rounded-full bg-light-light-grey"></div>
          <div className="h-2 w-2 rounded-full bg-light-light-grey"></div>
        </div>
      </div>
    </form>
  );
}

export default AddClient;
