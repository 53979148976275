import React from "react";
import FilterIcon from "../../../../assets/icons/filter-icon.svg";
import FilterIconBlue from "../../../../assets/icons/filter-icon-blue.svg";
export const tableHeader = [
  {
    label: "Name",
    id: "name",
    icon: FilterIcon,
    iconConfirmed: FilterIconBlue,
  },
  {
    label: "Client",
    id: "client",
    icon: FilterIcon,
    iconConfirmed: FilterIconBlue,
  },
  {
    label: "Department",
    id: "department",
  },
  {
    label: "Address",
    id: "address",
  },
  {
    label: "Coordinates",
    id: "coordinates",
  },
  {
    label: "Actions",
    id: "actions",
  },
  {
    label: "Site-based Alerts",
    id: "site-based-alerts",
  },
  {
    label: "Guardians",
    id: "guardians",
  },
];
