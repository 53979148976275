import React from "react";
import ArrowDownIcon from "../../../assets/icons/arrow-down.svg";
import FilterIcon from "../../../assets/icons/filter-icon.svg";
import MessageIcon from "../../../assets/icons/message-icon-dark.svg";
import IconCross from "../../../assets/icons/icon-cross.svg";
import IconPlus from "../../../assets/icons/add-icon.svg";
import DownloadIconBlue from "../../../assets/icons/download-blue.svg";
import DownloadIconWhite from "../../../assets/icons/download-white.svg";
import MuteIcon from "../../../assets/icons/mute.svg";
import {Bookmark, BookmarkBorder} from "@mui/icons-material";
import DownloadGreyIcon from "../../../assets/icons/download-grey.svg";
import LoadingSpinner from "../LoadingSpinner";

type WhiteButtonTypes = {
  text: string;
  icon?: string;
  onClick: () => void;
  type?: "button" | "submit" | "reset" | undefined;
};

type DropDownButtonTypes = {
  open: boolean;
  onClick: () => void;
};

type RoundedToggleButtonProps = {
  id: string;
  name: string;
  checked: boolean;
  label: string;
  onChange: (event: HTMLInputElement) => void;
};

export const WhiteButton = ({
                              text,
                              icon,
                              onClick,
                              type = "button",
                            }: WhiteButtonTypes) => {
  return (
      <button
          type={type}
          onClick={onClick}
          className="shadow-lg px-5 w-full h-10 rounded-lg flex items-center justify-center gap-2 text-sm bg-white hover:bg-desaturated-light-grey hover:bg-opacity-50 transition-colors"
      >
        {icon ? <img src={icon} alt=""/> : null}
        <div className="flex-1 pointer-events-none">{text}</div>
      </button>
  );
};

export const BlueButton = ({
                             text,
                             icon,
                             onClick,
                             type = "button",
                             name = "",
                             disabled = false,
                             loading = false,
                           }: {
  text: string;
  icon?: string;
  name?: string;
  onClick?: (e?: any) => void;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  loading?: boolean;
}) => {

  function handleOnClick(e?: any) {
    if (loading) {
      return
    }
    if (disabled) {
      return
    }

    if (onClick) (
        onClick(e)
    )
  }

  return (
      <button
          name={name}
          type={type ? type : "button"}
          className={`w-full min-w-fit h-10 text-white text-sm rounded-md shadow-lg flex items-center gap-6 justify-center text-center hover:bg-opacity-80 transition-opacity ${
              disabled ? "cursor-not-allowed bg-light-grey" : "bg-blue"
          } ${(icon && !loading) ? "px-4" : " px-[10%]"}`}
          onClick={handleOnClick}
          disabled={disabled}
      >
        {!disabled && !loading && icon && <img src={icon} alt=""/>} {/* Conditional rendering of the icon */}
        {!loading ? <p className="flex-1">{text}</p> :
            <LoadingSpinner width={16} height={16} color={"white"}/>}
      </button>
  );
};

export const BlueButtonCentered = ({
                                     text,
                                     icon,
                                     onClick,
                                     type = "button",
                                     name = "",
                                     disabled = false,
                                   }: {
  text: string;
  icon?: string;
  name?: string;
  onClick?: (e?: any) => void;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
}) => {
  return (
      <button
          name={name}
          type={type ? type : "button"}
          className={`relative w-full min-w-fit h-10 text-white text-sm rounded-md shadow-lg flex items-center gap-6 justify-center text-center hover:bg-opacity-80 transition-opacity ${
              disabled ? "cursor-not-allowed bg-light-grey" : "bg-blue"
          } ${icon ? "px-4" : " px-[10%]"}`}
          onClick={onClick}
          disabled={disabled}
      >
        {!disabled && icon &&
            <div className={"absolute left-4 top-0 bottom-0 flex flex-col items-center justify-center"}><img
                className={""} src={icon} alt=""/></div>}
        <p className="flex-1">{text}</p>
      </button>
  );
};

export const OrangeButton = ({
                               text,
                               icon,
                               onClick,
                             }: {
  text: string;
  icon?: string;
  onClick?: () => void;
}) => {
  return (
      <button
          type="button"
          className="w-full h-10 bg-dark-orange text-white rounded-md shadow-lg flex items-center gap-6 justify-center px-3 text-sm"
          onClick={onClick}
      >
        {icon && <img src={icon} alt=""/>}
        {text}
      </button>
  );
};

export const GreenButton = ({
                              text,
                              icon,
                              onClick,
                            }: {
  text: string;
  icon?: string;
  onClick?: () => void;
}) => {
  return (
      <button
          type="button"
          className="w-full h-10 bg-dark-green text-white rounded-md shadow-lg flex items-center gap-6 justify-center px-3 text-sm"
          onClick={onClick}
      >
        {icon && <img src={icon} alt=""/>}
        {text}
      </button>
  );
};


export const RedButton = ({
                            text,
                            icon,
                            onClick,
                          }: {
  text: string;
  icon?: string;
  onClick?: () => void;
}) => {
  return (
      <button
          type="button"
          className="w-full h-10 bg-red text-white rounded-md shadow-lg flex items-center gap-6 justify-center px-3 text-sm"
          onClick={onClick}
      >
        {icon && <img src={icon} alt=""/>}
        {text}
      </button>
  );
};

export const GreyButton = ({
                             text,
                             icon,
                             customClass,
                             customClassText,
                             onClick,
                           }: {
  text: string;
  icon?: string;
  customClass?: string;
  customClassText?: string;
  onClick?: () => void;
}) => {
  return (
      <button
          type="button"
          className={`bg-grey text-white text-center w-full h-10 rounded-md px-4 gap-4 flex items-center justify-center shadow-md ${customClass}`}
          onClick={onClick}
      >
        {icon && <img src={icon} alt=""/>}
        <span className={customClassText}>{text}</span>
      </button>
  );
};

export const RoundedToggleButton = ({
                                      id,
                                      name,
                                      label,
                                      checked,
                                      onChange,
                                    }: RoundedToggleButtonProps) => {
  return (
      <div className="flex items-center">
        <input
            type="checkbox"
            id={id}
            name={name}
            checked={checked}
            onChange={(event) => onChange(event.target)}
            className="hidden"
        />
        <label
            htmlFor={id}
            className={`${
                checked ? "bg-blue border-blue" : "bg-white border-grey"
            } w-12 h-6 block rounded-full cursor-pointer transition-colors duration-200 border-2`}
        >
        <span
            className={`${
                checked
                    ? "translate-x-7 bg-blue border-white"
                    : "translate-x-1 border-grey"
            } block w-3 h-3 border-2 bg-white rounded-full transform transition-transform duration-200 translate-y-1`}
        ></span>
        </label>
        <div className="ml-3 text-light-grey font-light">{label}</div>
      </div>
  );
};

export const DropDownButton = ({open, onClick}: DropDownButtonTypes) => {
  return (
      <button
          className={`h-8 w-8 flex items-center justify-center absolute bottom-0 right-2 ${
              open ? "rotate-180" : null
          }`}
          onClick={onClick}
      >
        <img src={ArrowDownIcon} alt="" className="pointer-events-none"/>
      </button>
  );
};

export const FilterButton = ({onClick, disabled}: { onClick: () => void, disabled?: boolean }) => {
  return (
      <button
          className={`bg-white  h-12 w-12 flex items-center justify-center pointer-events-auto rounded-md shadow-lg ${disabled ? "opacity-70" : ""}`}
          onClick={onClick}
      >
        <img src={FilterIcon} alt="" className="pointer-events-none"/>
      </button>
  );
};

export const MessageButton = ({onClick}: { onClick: () => void }) => {
  return (
      <button
          className="bg-white  h-12 w-12 flex items-center justify-center  rounded-md shadow-lg"
          onClick={onClick}
      >
        <img src={MessageIcon} alt="" className="pointer-events-none"/>
      </button>
  );
};

export const ButtonCross = ({
                              onClick,
                              smallButton = false,
                            }: {
  onClick?: (e: React.SyntheticEvent) => void;
  smallButton?: boolean;
}) => {
  return (
      <button
          className={`bg-dark-grey rounded-full ${
              smallButton ? "p-1" : "p-3"
          } cursor-pointer`}
          onClick={onClick}
      >
        <img src={IconCross} alt="" className="pointer-events-none"/>
      </button>
  );
};

export const PlusBlueButton = ({onClick}: { onClick?: () => void }) => {
  return (
      <button className="bg-blue rounded-md p-3 cursor-pointer" onClick={onClick}>
        <img src={IconPlus} alt="" className="pointer-events-none"/>
      </button>
  );
};

export const ButtonBorderTransparent = ({
                                          text,
                                          icon,
                                          onClick,
                                          type,
                                          disabled = false
                                        }: {
  text: string;
  icon?: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
}) => {
  return (
      <button
          type={type ? type : "button"}
          onClick={onClick}
          disabled={disabled}
          className={`bg-transparent border-2 ${disabled ? "border-light-grey text-light-grey cursor-not-allowed" : "border-blue text-blue"} rounded-md p-3 cursor-pointer w-full h-10 text-sm shadow-lg flex items-center gap-6 justify-center px-3`}
      >
        {text}
        {!disabled && icon && <img src={icon} alt=""/>}
      </button>
  );
};

export const ButtonDownload = ({
                                 onClick,
                                 blue = false,
                                 loading = false,
                               }: {
  onClick?: () => void;
  blue?: boolean;
  loading?: boolean;
}) => {
  return (
      <button
          className={`rounded-md p-2.5 shadow-md h-10 w-10 flex items-center justify-center ${
              !blue ? "bg-white" : "bg-blue"
          }`}
          onClick={() => {
            if (!loading && onClick) {
              onClick();
            }
          }}
      >
        {loading ? (
            <LoadingSpinner width={20} height={20}/>
        ) : (
            <img
                src={!blue ? DownloadIconBlue : DownloadIconWhite}
                alt=""
                className="w-full h-full"
            />
        )}
      </button>
  );
};

export const DownloadButtonWhiteOutline = ({
  onClick,
}: {
onClick?: () => void;
}) => {
return (
          <button
          type="button"
          onClick={onClick}
          className="h-8 w-8 flex items-center justify-center rounded-md hover:shadow-md"
        >  
        <img
            src={DownloadGreyIcon}
            alt=""
            style={{filter: "invert(50%) brightness(1000%)"}}
        />
      </button>
  );
};

export const DownloadButtonGreyOutline = ({
                                            onClick,
                                          }: {
  onClick?: () => void;
}) => {
  return (
      <button
          type="button"
          onClick={onClick}
          className="h-8 w-8 flex items-center justify-center rounded-md hover:shadow-md"
      >
        <img src={DownloadGreyIcon} alt=""/>
      </button>
  );
};

export const BookmarkButtonWhiteOutline = ({
                                             bookmark,
                                             onClick,
                                           }: {
  bookmark?: boolean;
  onClick?: () => void;
}) => {
  return (
      <button
          className="h-8 w-8 flex items-center justify-center rounded-md hover:shadow-md"
          onClick={onClick}
      >
        {bookmark ? <Bookmark style={{fill: "#F9E10A"}}/> : <BookmarkBorder/>}
      </button>
  );
};

export const MuteButton = ({onClick}: { onClick: () => void }) => {
  return (
      <button onClick={onClick}>
        <img src={MuteIcon} alt=""/>
      </button>
  );
};
