import {getTimezonesForCountry, Timezone} from "countries-and-timezones";

export type TimezoneOverride = {
    name: string;
}


const overrides: Record<string, string[]> = {
    "XK": ["Europe/Belgrade"]
}

export const getTimezonesForCountryWithOverride = (id: string): TimezoneOverride[] => {
    const timezones: TimezoneOverride[] = (getTimezonesForCountry(id) ?? []).map(t => ({name: t.name}))

    if( overrides[id.toUpperCase()]) {
        const extras = overrides[id.toUpperCase()].map((name) => {
            return {
                name,
            }
        })
        timezones.push(...extras)
    }

    return timezones
}