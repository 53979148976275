import React, { useContext } from "react";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { FormDataContext } from "../../context/form";
function FirstName() {
  const { data } = useContext(UserProfileContext)!;
  const userData: UserDataTypes = data?.data;
  const formDataContext = useContext(FormDataContext)!;
  const { firstNameRef } = formDataContext;

  return (
    <div className="">
      <label htmlFor="first_name" className="opacity-80">
        First Name
      </label>
      <input
        required
        ref={firstNameRef}
        name={"first_name"}
        placeholder="Enter First Name"
        defaultValue={userData.profile?.firstName}
        className="h-10 w-full shadow-md rounded-md px-4"
      />
    </div>
  );
}

export default FirstName;
