import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormsContext } from "../../../../context/forms-context";
import { WhiteButton } from "../../../../../../components/ui/Buttons";
import AddMultipleFacilities from "../Form/AddMultipleFacilities";
import Modal from "../../../../../../components/portal/Modal";
import AddFacility from "../../../../components/add-facility";

import { Done } from "@mui/icons-material";
import AddSiteBasedAlert from "../../../../components/add-site-based-alerts";
import ImportFacilitiesBtn from "../../../../components/buttons/import-facilities-btn";
import DownloadImportFacilitiesSample from "../../../../components/buttons/download-import-facilities-sample-file";
import ExportFacilities from "../../../../components/buttons/export-facilities";
import Checkbox from "../../../../../../components/form/Checkbox";
function FacilitiesSidebarFilter() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFacilities, setIsFacilities] = useState<boolean>(true);
  const navigate = useNavigate();
  const [successfullyAddedFacility, setSuccessfullyAddedFacility] =
    useState<boolean>(false);
  const [successfullyAddedSBA, setSuccessfullyAddedSBA] =
    useState<boolean>(false);
  const {
    addIndividualFacility,
    addMultipleFacilities,
    siteBasedAlertModal,
    setAddCustomOverlay,
    setAddIndividualFacility,
    setAddMultipleFacilities,
    setAddZone,
    setSiteBasedAlertModal,
  } = useContext(FormsContext)!;

  useEffect(() => {
    const siteTypes = searchParams.getAll("siteTypes") as string[];
    if (siteTypes.includes("facilities")) {
      setIsFacilities(true);
    } else {
      setIsFacilities(false);
    }
  }, [searchParams]);
  return (
    <>
      <div className="font-semibold">
        <Checkbox
          value="facilities"
          label="Facilities"
          onClick={() => setIsFacilities((prev) => !prev)}
          name="siteTypes"
          checked={isFacilities}
        />
        <div className="font-normal flex flex-col gap-2 my-2">
          <WhiteButton
            text="View / Edit"
            onClick={() => navigate("/sites/facilities")}
          />
          <WhiteButton
            text="Add Individual Facility"
            onClick={() => {
              setAddIndividualFacility(true);
              setAddCustomOverlay(false);
              setAddMultipleFacilities(false);
              setAddZone(false);
            }}
          />
          <ImportFacilitiesBtn />
          <DownloadImportFacilitiesSample />
          <ExportFacilities />
        </div>
      </div>
      {addMultipleFacilities ? (
        <AddMultipleFacilities
          className="absolute top-10 left-[28vw] w-full z-10"
          setIsMultipleFacilitiesModal={setAddMultipleFacilities}
        />
      ) : null}

      <Modal open={addIndividualFacility} setOpen={setAddIndividualFacility}>
        <AddFacility
          setAddIndividualFacility={setAddIndividualFacility}
          setSiteBasedAlertModal={setSiteBasedAlertModal}
          addIndividualFacility={addIndividualFacility}
          siteBasedAlertModal={siteBasedAlertModal}
          setSuccessfullyAddedFacility={setSuccessfullyAddedFacility}
        />
      </Modal>
      <Modal open={successfullyAddedFacility}>
        <div className="bg-white p-8 flex items-center gap-8 text-xl rounded-md shadow-md">
          <div className="p-4 bg-blue w-fit rounded-full">
            <Done className="text-white" />
          </div>
          <span>Successfull added facility.</span>
        </div>
      </Modal>
      <Modal open={successfullyAddedSBA}>
        <div className="bg-white p-8 flex items-center gap-8 text-xl rounded-md shadow-md">
          <div className="p-4 bg-blue w-fit rounded-full">
            <Done className="text-white" />
          </div>
          <span>Successfull added site based alert.</span>
        </div>
      </Modal>

      <Modal open={siteBasedAlertModal} setOpen={setSiteBasedAlertModal}>
        <AddSiteBasedAlert
          setSiteBasedAlertModal={setSiteBasedAlertModal}
          setSuccessfullyAddedSBA={setSuccessfullyAddedSBA}
        />
      </Modal>
    </>
  );
}

export default FacilitiesSidebarFilter;
