import React, { useEffect, useRef, useState } from "react";
import { generateMap, generateMapSearchBox } from "../../../../../../util/map";
import YellowLocationPin from "../../../../../../assets/icons/pin-yellow.png";
import EditZoneForm from "../../../../components/edit-zone";
function Map() {
  const [map, setMap] = useState<google.maps.Map>();
  const mapRef = useRef<HTMLDivElement>(null);
  const [drawingManager, setDrawingManager] =
    useState<google.maps.drawing.DrawingManager>();
  useEffect(() => {
    const zoom = 3;
    const center = [0, 0];
    const mapCenter = { lng: center[0], lat: center[1] };
    const generatedMap = generateMap(mapRef, mapCenter, zoom);
    setMap(generatedMap);
  }, []);

  useEffect(() => {
    const input = document.getElementById("pac-input") as HTMLInputElement;
    generateMapSearchBox(input, map);
  }, [map]);

  useEffect(() => {
    if (!map) return;

    const generatedDrawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.MARKER,
          google.maps.drawing.OverlayType.CIRCLE,
          google.maps.drawing.OverlayType.POLYGON,
        ],
      },
      markerOptions: {
        icon: {
          url: YellowLocationPin,
          scaledSize: new google.maps.Size(40, 40),
        },
      },
      circleOptions: {
        fillColor: "#E20000",
        fillOpacity: 0.35,
        strokeWeight: 2,
        strokeColor: "#E20000",
        strokeOpacity: 0.8,
        clickable: false,
        editable: true,
      },
      polygonOptions: {
        fillColor: "#E20000",
        fillOpacity: 0.35,
        strokeWeight: 2,
        strokeColor: "#E20000",
        strokeOpacity: 0.8,
        clickable: false,
        editable: true,
      },
    });

    setDrawingManager(generatedDrawingManager);
  }, [map]);

  useEffect(() => {
    if (!map || !drawingManager) return;
    drawingManager?.setMap(map);
  }, [map, drawingManager]);

  return (
    <div className="flex gap-4 relative h-full w-full overflow-y-auto">
      <EditZoneForm map={map} drawingManager={drawingManager} />
      <div className=" w-full relative flex-1" ref={mapRef}></div>
    </div>
  );
}

export default Map;
