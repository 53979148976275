import { useQuery } from "react-query";
import api from "../api/axiosConfig";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UserActivityDataTypes } from "../types/user-activity-data-types";
const emergencyTypes = [
  "alerts",
  "geofences",
  "check_in_missed",
  "safety_zone",
  "message",
  "silent_alarm",
  "emergency_alarm",
  "incident_violation",
  "geofence_violation",
  "safety_zone_violation"
];
export default function useUserActivity() {
  const [active, setActive] = useState<UserActivityDataTypes[]>([]);
  const [resolved, setResolved] = useState<UserActivityDataTypes[]>([]);
  const [activeArr, setActiveArr] = useState<UserActivityDataTypes[]>([]);

  const [emergency, setEmergency] = useState<UserActivityDataTypes[]>([]);
  const [nonEmergency, setNonEmergency] = useState<UserActivityDataTypes[]>([]);

  const [token] = useState<string>(() => {
    const rand = () => {
      return Math.random().toString(36).substr(2); // remove `0.`
    };

    return rand() + rand();
  });

  const fetchUserActivity = async () => {
    return await api
      .get("ops/user-activity-feed", {
        params: { token: token },
      })
      .then((res) => res.data)
      .catch((err) => console.error(err));
  };

  const { data, isFetching, isError, error, status } = useQuery(
    "user-activity",
    fetchUserActivity,
    {
      refetchInterval:
        parseInt(process.env.REACT_APP_USER_ACTIVITY_REFRESH_RATE!) || 5000,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (!data) return;
    setActive(data.active);

    const a = data.resolved ? data.resolved : []
    const b = resolved ? resolved : []

    const all = [...a,...b]

    const map: Record<string,UserActivityDataTypes>  = {}

    all.forEach((i) => {
      map[i.id] = i;
    })
    
    setResolved(Object.values(map));

  }, [data]);

  useEffect(() => {
    if (!data) return;

    const sortByTimestamp = (a: any, b: any) =>
      new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
    const existing = [...activeArr];
    const existingAndNew = [...existing, ...(active || [])];

    if (resolved) {
      setActiveArr(existingAndNew.sort(sortByTimestamp));
      return;
    }
    const toRemove: UserActivityDataTypes[] = resolved || [];

    const resolvedRemoved = existingAndNew.filter((a) => {
      const matches = toRemove.filter((b: any) => {
        return b.id == a.id;
      });

      return matches.length === 0;
    });

    setActiveArr(resolvedRemoved.sort(sortByTimestamp));
  }, [active]);

  const filterEmergencyActivities = () => {
    const items = activeArr.filter((activity: UserActivityDataTypes) =>
      emergencyTypes.includes(activity.type)
    );
    setEmergency(items);
  };

  const filterNonEmergencyActivities = () => {
    const items = activeArr.filter(
      (activity: UserActivityDataTypes) =>
        !emergencyTypes.includes(activity.type)
    );
    setNonEmergency(items);
  };

  useEffect(() => {
    filterEmergencyActivities();
    filterNonEmergencyActivities();
  }, [activeArr]);

  return {
    data,
    emergencyUserActivity: emergency,
    nonEmergencyUserActivity: nonEmergency,
    activeArr,
    isFetching,
    isError,
    status,
    error,
    resolved
  };
}
