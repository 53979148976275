import {
  ActionModalColored,
  ModalLayout,
} from "../../../../../components/ui/Modals";
import Bin from "../../../../../assets/icons/icon-bin-white.svg";
import React from "react";

export default function DeletePopUp({
  closeDeleteModal,
  deleteEntry,
  popUpText,
}: {
  closeDeleteModal: (e: React.SyntheticEvent) => void;
  deleteEntry: () => void;
  popUpText: string;
}) {
  return (
    <ModalLayout close={closeDeleteModal}>
      <ActionModalColored
        icon={Bin}
        color="red"
        mainButton={deleteEntry}
        closeButton={closeDeleteModal}
        showButton={true}
      >
        <div className="font-bold">Are You Sure?</div>
        <div className="pb-5">
          {popUpText}
          <div>Please press</div> the button below to confirm.
        </div>
      </ActionModalColored>
    </ModalLayout>
  );
}
