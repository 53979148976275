import useGetRoles from "../../../api/roles/useGetRoles";
import { RoleTypes } from "../../../types/roles-types";

export default function useAccessibleRolesForUser(roleName: string) {
  const roles = useGetRoles();

  const userRolesRestrictions = () => {
    if (roleName === "NGS Super Admin") {
      return [
        "NGS Super Admin",
        "Client Global Admin",
        "Department Admin",
        "Traveller",
        "Insurance User",
      ];
    } else if (roleName === "Client Global Admin") {
      return [
        "Client Global Admin",
        "Department Admin",
        "Traveller",
        "Insurance User",
      ];
    } else if (roleName === "Department Admin") {
      return ["Department Admin", "Traveller", "Insurance User"];
    } else if (roleName === "Traveller") {
      return ["Traveller", "Insurance User"];
    } else return ["Insurance User"];
  };

  const filteredUserRolesBasedOnRestrictions = () => {
    return roles?.data?.filter((role: RoleTypes) => {
      const userRoles = userRolesRestrictions();
      if (!userRoles.includes(role.name)) return;
      return role;
    });
  };

  return filteredUserRolesBasedOnRestrictions();
}
