import { useOidcAccessToken } from "@axa-fr/react-oidc";
import React, { createContext, ReactNode, useEffect } from "react";

export type AccessTokenPayloadType = {
  iss: string;
  sub: string;
  profile: {
    firstName: string;
    middleNames: string;
    lastName: string;
    dateOfBirth: string;
    gender: string;
    genderAssignedAtBirth: null;
    countryOfResidence: string;
    nationality: string;
    ethnicity: null;
    metadata: null;
  };
  email: string;
  role: {
    id: string;
    name: string;
    description: string;
    auroraModules: string[];
    auroraAccessLevel: "all" | "client" | "department" | "user";
    appPermissions: string[];
    clientID: null;
    clientName: null;
  };
  metadata: {
    clientName: string;
    departmentName: string;
    clientID: string;
    departmentID: string;
  };
  modules: string[];
  iat: number;
  nbf: number;
  exp: number;
};

export const UserContext = createContext<AccessTokenPayloadType | null>(null);

export default function UserContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { accessTokenPayload } = useOidcAccessToken();

  const data = accessTokenPayload;

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(data));
  }, []);
  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
}
