import React, { ReactNode, SetStateAction, useEffect, useRef } from "react";
import ReactDom from "react-dom";

type ModalPropsType = {
  children: ReactNode;
  open: boolean;
  className?: string;
  setOpen?: React.Dispatch<SetStateAction<boolean>>;
};

export default function Modal({
  children,
  open,
  className,
  setOpen,
}: ModalPropsType) {
  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const body = document.body;
    body.style.overflow = open ? "hidden" : "auto";
  }, [open]);
  // useOnClickOutside(modalRef, () => (setOpen ? setOpen!(false) : null));
  if (!open) return null;

  return ReactDom.createPortal(
    <div
      onClick={() => setOpen && setOpen(false)}
      className={`${
        open ? "fixed" : null
      } top-0 left-0 h-full w-full flex items-center justify-center bg-black bg-opacity-50 ${className}`}
      style={{ zIndex: 100 }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`relative ${className}`}
        ref={modalRef}
      >
        {children}
      </div>
    </div>,
    document.getElementById("portal")!
  );
}
