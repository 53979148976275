import React, { useContext } from "react";
import Sidebar from "../../../../../components/ui/Sidebar";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { RiskModalContext } from "../context/risk-modal";
const keys = [
  {
    slug: "high-negligible",
    name: "Negligible",
    color: "#0CA817",
    level: 1,
  },
  {
    slug: "med-negligible",
    name: "Negligible",
    color: "#73B146",
    level: 2,
  },
  {
    slug: "high-low",
    name: "Low",
    color: "#B4BC00",
    level: 3,
  },
  {
    slug: "med-low",
    name: "Low",
    color: "#D8B700",
    level: 4,
  },
  {
    slug: "high-medium",
    name: "Medium",
    color: "#E6920A",
    level: 5,
  },
  {
    slug: "med-medium",
    name: "Medium",
    color: "#E5680C",
    level: 6,
  },
  {
    slug: "high-high",
    name: "High",
    color: "#E23603",
    level: 7,
  },
  {
    slug: "med-high",
    name: "High",
    color: "#BA1F1F",
    level: 8,
  },
  {
    slug: "high-extreme",
    name: "Extreme",
    color: "#000000",
    level: 9,
  },
];

function Key() {
  const { setRiskModal } = useContext(RiskModalContext)!;
  const KeyListItem = ({
    color,
    level,
    name,
  }: {
    color: string;
    level: number;
    name: string;
  }) => {
    return (
      <li className="flex items-center  gap-4">
        <div
          className="h-10 shadow-[0px_4px_10px_-3px_#00000070] w-10 rounded-full flex items-center justify-center text-white font-semibold"
          style={{ backgroundColor: color }}
        >
          {level}
        </div>
        <span>{name}</span>
      </li>
    );
  };
  return (
    <Sidebar title="Key">
      <ul className="grid grid-cols-2 grid-rows-5 grid-flow-col gap-2">
        {keys.map((item) => (
          <KeyListItem
            key={item.slug}
            name={item.name}
            color={item.color}
            level={item.level}
          />
        ))}
        <li className="flex items-center  gap-4">
          <button
            className="bg-blue h-10 shadow-[0px_4px_10px_-3px_#00000070] w-10 rounded-md flex items-center justify-center text-white font-semibold"
            onClick={() => setRiskModal(true)}
          >
            <VpnKeyIcon className="text-white rotate-45" />
          </button>
          <span>Risk Level Meaning</span>
        </li>
      </ul>
    </Sidebar>
  );
}

export default Key;
