import React, { SetStateAction } from "react";
import BinIcon from "../../../../assets/icons/icon-bin-white.svg";
import { RedCircle } from "../../../../components/ui/Circles";
function DeleteConfirmation({
  text,
  handleDelete,
  cancelDelete,
}: {
  text: string;
  handleDelete: () => void;
  cancelDelete: React.Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <div className="bg-white p-4 relative w-64 rounded-lg text-center py-8 border-red border-4 border-opacity-20">
      <h4 className="text-dark-red font-semibold">Are you sure?</h4>
      <p className="mb-12 text-dark-red">{text}</p>
      <button
        className="w-6 h-6 rounded-full flex items-center justify-center bg-light-grey absolute top-4 right-4 text-white"
        onClick={() => cancelDelete(false)}
      >
        x
      </button>
      <button
        className="absolute bottom-4 left-1/2 translate-x-8"
        onClick={handleDelete}
        type="button"
      >
        <RedCircle icon={BinIcon} />
      </button>
    </div>
  );
}

export default DeleteConfirmation;
