import React from "react";
import TripForm from "./form";
import {useParams} from "react-router-dom";
import {TripFormProvider} from "./context";

const TripEdit: React.FC = () => {

  const {id: tripID} = useParams();


  return <TripFormProvider
      id={tripID}
      mode={"edit"}
  >
    <TripForm/>
  </TripFormProvider>
}

export default TripEdit;