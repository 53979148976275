import React from "react";
import FirstAidIcon from "../../../../../../assets/icons/first-aid-icon.svg";
import AlertIcon from "../../../../../../assets/icons/alert-icon.svg";
import EarthquakeIcon from "../../../../../../assets/icons/earthquake.svg";
import FlightIcon from "../../../../../../assets/icons/flight.svg";
import useGetSavedAdviceGuides from "../../../../../../api/advice-guides/useGetSavedAdviceGuides";
import useGetAllAdviceGuides from "../../../../../../api/advice-guides/useGetAllAdviceGuides";
import AdviceGuidesSidebarCard from "./AdviceGuidesSidebarCard";

function AdviceGuidesSidebar() {
  const allAdviceGuides = useGetAllAdviceGuides();
  const savedAdviceGuides = useGetSavedAdviceGuides();

  const filteredAdviceGuides = allAdviceGuides.all_guides_data.filter(
    (guide) =>
      savedAdviceGuides.data?.data &&
      savedAdviceGuides.data?.data.includes(guide.id)
  );

  return (
    <div className="bg-white w-full shadow-md flex flex-col gap-6 pt-5 max-h-96 h-fit p-6 max-w-md">
      <div className="border-b-1 h-12 flex items-center justify-center ">
        <p className="text-md text-center  block pb-2 font-semibold">
          Saved Guides
        </p>
      </div>
      {filteredAdviceGuides.length < 1 && (
        <p className="text-center">Bookmark guides to save.</p>
      )}
      <ul className="px-6 flex flex-col gap-4 overflow-y-auto">
        {filteredAdviceGuides?.map((guide, i) => {
          const guideAzurePath = guide.azurePath;
          const guideAzureType = guideAzurePath.split("/")[1].toLowerCase();
          const icon =
            guideAzureType === "travel"
              ? FlightIcon
              : guideAzureType === "hazard"
              ? EarthquakeIcon
              : guideAzureType === "medical"
              ? FirstAidIcon
              : AlertIcon;
          return (
            <AdviceGuidesSidebarCard
              guide={guide}
              key={guide.id}
              icon={icon}
              name={guide.name}
              guideAzureType={guideAzureType}
            />
          );
        })}
      </ul>
    </div>
  );
}

export default AdviceGuidesSidebar;
