import { useEffect, useState } from "react";
import { FacilityDataTypes } from "../types/facilitiy-data-types";
import api from "../api/axiosConfig";
import { useInfiniteQuery } from "react-query";

export default function useFacilities(querykey: string, params: {} = {}) {
  const [allFacilities, setAllFacilities] = useState<FacilityDataTypes[]>([]);
  const fetchIncidents = async (page: number) => {
    const response = await api.get(`/facilities?page=${page}`, { params });
    return response.data;
  };

  const { data, fetchNextPage, hasNextPage, isFetching, isLoading, status } =
    useInfiniteQuery(
      querykey,
      ({ pageParam = 1 }) => fetchIncidents(pageParam),
      {
        getNextPageParam: (lastPage, allPages) => {
          if (lastPage.hasMore) {
            return allPages.length + 1;
          }
          return undefined;
        },
      }
    );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
      return;
    }
  }, [data, hasNextPage, fetchNextPage]);

  useEffect(() => {
    if (hasNextPage) return;
    const flatItems = data ? data.pages?.flatMap((page) => page.items) : [];
    setAllFacilities(flatItems);
  }, [data, hasNextPage]);

  return {
    facilities: allFacilities,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    status,
  };
}
