import React, { useContext, useEffect, useState } from "react";

import useGetAllClients from "../../../../api/clients/useGetAllClients";
import useDepartments from "../../../../services/useDepartments";
import { Select } from "../../../../components/form-v2";
import SuitcaseIcon from "../../../../assets/icons/suitcase.svg";
import DepartmentIcon from "../../../../assets/icons/department-icon.svg";
import { UserContext } from "../../../../context/user";
import SearchableSelect from "../../../../components/form-v2/searchable-select";
import ClientSelect from "../../../../components/form-v2/searchable-select/ClientSelect";
import { Client, Department } from "../../../../types/userManagement";
import { PaginatedResponse } from "../../../../types/PaginatedResponse";
import api from "../../../../api/axiosConfig";
import DepartmentSelect from "../../../../components/form-v2/searchable-select/DepartmentSelect";

type SelectOption = { label: string; value: string };

function ZoneOwnership({
  onChange = (clientID: string | null, departmentID: string | null) => {},
  clientID = "",
  departmentID = "",
  onLoaded = () => {}
}) {
  const [_clientID, setClientID] = useState<string | null>(clientID);
  const [_departmentID, setDepartmentID] = useState<null | string>(
    departmentID
  );
  const [client, setClient] = useState<Client | null>(null);
  const [department, setDepartment] = useState<Department | null>(null);

  useEffect(() => {
    onChange(_clientID, _departmentID);
  }, [_clientID, _departmentID]);

  const { role } = useContext(UserContext)!;

  const fetchInitialClient = async () => {
    if (!clientID) {
      return;
    }
    try {
      const res = await api.get<Client>(`/clients/${clientID}`);
      setClient(res.data);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchInitialDepartment = async () => {
    if (!departmentID) {
      return;
    }
    try {
      const res = await api.get<Department>(`/departments/${departmentID}`);
      setDepartment(res.data);
    } catch (e) {
      console.error(e);
    }
  };


  useEffect(() => {
    (async () => {
      await fetchInitialClient();
      await fetchInitialDepartment();
      onLoaded()
    })()
   
  }, []);

  const onClientChange = (clients: Client[]) => {
    setClient(clients.length > 0 ? clients[0] : null);
  };

  const onDepartmentChange = (departments: Department[]) => {
    setDepartment(departments.length > 0 ? departments[0] : null);
  };

  useEffect(() => {
    onChange(client?.id || null, department?.id || null)
  }, [client, department])


  return (
    <div className="flex flex-col gap-2 py-4">
      <span className="font-bold text-sm text-center">
        <span className="text-red">* </span>Step 2 : Ownerhip
      </span>

      {role.auroraAccessLevel == "all" && (
        <ClientSelect
          multiple={false}
          clients={client ? [client] : []}
          onSelectionChange={onClientChange}
        />
      )}

      {(role.auroraAccessLevel == "all" ||  role.auroraAccessLevel == "client") && 

      (client ||  role.auroraAccessLevel == "client") && <DepartmentSelect multiple={false} departments={department ? [department] : []} onSelectionChange={onDepartmentChange} clientID={client ? client.id : null}/>}
    </div>
  );
}

export default ZoneOwnership;
