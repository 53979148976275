import React from "react";
import Label from "../../../../components/form/Label";

type TimeStampsTypes = {
  text: string;
  whoseTimeZone: string;
  date: string;
  time: string;
  timeDifference?: string;
  last?: boolean;
};

function TimeStamps({
  text,
  whoseTimeZone,
  date,
  time,
  timeDifference,
  last,
}: TimeStampsTypes) {
  return (
    <div className="py-4 border-b">
      <Label text={text} />
      <span className="text-sm opacity-50">{whoseTimeZone}</span>
      <div className="flex gap-4">
        <span>{date}</span>
        <span>{time}</span>
        <span>{timeDifference}</span>
      </div>
    </div>
  );
}

export default TimeStamps;
