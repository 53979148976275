import React, {useEffect, useState} from "react"
import SearchableSelect, {SearchableSelectOption} from "."
import AirplaneIcon from "../../../assets/icons/airplane.svg";

type WorldSearchTypeSelectParams = {

  multiple?: boolean;
  onSelectionChange?: (types: WorldSearchType[]) => void
  worldSearchTypes: WorldSearchType[],
}

export type WorldSearchType = {
  label: string,
  value: string,
}

function WorldSearchTypeSelect({
                                 multiple,
                                 onSelectionChange,
                                 worldSearchTypes,
                               }: WorldSearchTypeSelectParams) {

  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<SearchableSelectOption<WorldSearchType>[]>([])
  const [searchStr, setSearchStr] = useState("")

  const setupOptions = async () => {
    setLoading(true)
    try {


      const opts = [
        {label: "Trip - Travel", value: "trip:travel"},
        {label: "Trip - Accommodation", value: "trip:accommodation"},
        {label: "Trip - Scheduled Check In", value: "trip:scheduled_check_in"},
        {label: "App - Check In", value: "app:check_in"},
        {label: "App - Emergency Alarm", value: "app:emergency_alarm"},
        {label: "App - Silent Alarm", value: "app:silent_alarm"},
        {label: "App - Location", value: "app:location"},
      ].map((o) => ({
        ...o,
        value: o,
        key: o.value,
      }));


      setOptions(opts.filter(o => o.label.toLowerCase().includes(searchStr.toLowerCase())))


    } catch (e) {
      console.error(e)
    }

    setLoading(false)
  }

  useEffect(() => {
    setupOptions()
  }, [searchStr])


  const onChange = (selected: SearchableSelectOption<WorldSearchType>[]) => {
    if (onSelectionChange) {
      onSelectionChange(selected.map(s => s.value))
    }
  }

  const onSearch = (value: string) => {
    setSearchStr(value)
  }

  return (<SearchableSelect
      icon={AirplaneIcon}
      label={multiple ? "Type" : "Types"}
      selected={worldSearchTypes.map(t => ({
        label: t.label,
        value: t,
        key: t.value,
      }))}
      search={true}
      multiple={!!multiple}
      options={options}
      localSearch={false}
      onChange={onChange}
      onSearch={onSearch}
      loading={loading}
  />)
}

export default WorldSearchTypeSelect