export default function typeBasedTileColor(type: string) {
  switch (type) {
    case "tracking_enabled":
      return {
        color: "blue",
        borderColor: "blue",
        circleColor: "blue",
      };

      break;
    case "tracking_disabled":
      return {
        color: "blue",
        borderColor: "blue",
        circleColor: "blue",
      };

      break;
    case "message":
      return {
        color: "green",
        borderColor: "green",
        circleColor: "white",
      };

      break;
    case "safety_zone":
      return {
        color: "dark-orange",
        borderColor: "dark-orange",
        circleColor: "white",
      };

      break;
    case "geofences":
      return {
        color: "dark-orange",
        borderColor: "dark-orange",
        circleColor: "white",
      };

      break;
    case "safety_zone_violation_resolved":
    case "geofence_violation_resolved":
    case "incident_violation_resolved":
    case "check_in":
      return {
        color: "green",
        borderColor: "green",
        circleColor: "green",
      };

      break;
    case "alerts":
    case "emergency_tracking_enabled":
    case "emergency_tracking_disabled":
      return {
        color: "dark-orange",
        borderColor: "dark-orange",
        circleColor: "dark-orange",
      };

      break;
    case "silent_alarm":
      return {
        color: "red",
        borderColor: "red",
        circleColor: "white",
      };

      break;
    case "geofence_violation":
    case "incident_violation":
    case "safety_zone_violation":
    case "emergency_alarm":
    case "check_in_missed":
      return {
        color: "red",
        borderColor: "red",
        circleColor: "red",
      };

    default:
      return {
        color: "gray",
        borderColor: "gray",
        circleColor: "white",
      };
  }
}
