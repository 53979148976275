import React from "react";
import { Outlet } from "react-router-dom";
import SidebarLinks from "../../components/ui/SidebarLinks";
import { ContentWrapperWithHeader } from "../../components/ui/Content";
import Breadcrumbs from "../../components/ui/Content/Breadcrumbs";
import useRestrictedSettings from "../../restrictions/useRestrictedSettings";
function Settings() {
  const links = useRestrictedSettings();
  return (
    <div className="pt-36 pb-16 flex h-screen">
      <div className="max-w-md w-full">
        <SidebarLinks title="Settings" navLinks={links} />
      </div>
      <main className="w-full h-full pr-8">
        <ContentWrapperWithHeader
          title={<Breadcrumbs />}
          content={<Outlet />}
        />
      </main>
    </div>
  );
}

export default Settings;
