import React from "react";
import AllTrips from "../../../features/trips/pages/allTrips";
import TripsContextProvider from "../../../features/trips/context/trips";
import {CountryRiskProvider} from "../../../context/countryrisk";

export default function AllTripsPage() {
  return (
    <TripsContextProvider>
      <CountryRiskProvider>
        <AllTrips />
      </CountryRiskProvider>
    </TripsContextProvider>
  );
}
