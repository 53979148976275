import React, { RefObject, useContext } from "react";
import FormSection from "../form-section";
import InputText from "../../../../../../components/form/InputText";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import {UserContext} from "../../../../../../context/user";

function LastActiveDetails() {

  const userContext = useContext(UserContext);
  const { data } = useContext(UserProfileContext)!;
  const userData: UserDataTypes = data?.data;
  return (
    <FormSection title={"Last Active Details"}>
      <div className="grid grid-cols-4 gap-8">
        <div className="">
          <label htmlFor="last-login-at" className="opacity-80">
            Last Login
          </label>
          <input
            type="text"
            value={
              userData.status?.lastLoginAt ? userData.status?.lastLoginAt : ""
            }
            disabled
            readOnly
            className="h-10 w-full rounded-md shadow-md px-4"
          />
          {userContext?.role.auroraAccessLevel == "all" &&
            <>
                <br/>
                  <label className="opacity-80">
                      Last App Token Refresh
                  </label>
                  <input
                      type="text"
                      disabled
                      value={
                        userData.pushNotificationTokenReceivedAt
                          ? userData.pushNotificationTokenReceivedAt
                          : ""
                      }
                      readOnly
                      className="h-10 w-full rounded-md shadow-md px-4"
                  />
            </>
          }
        </div>
        <div className="">
          <label htmlFor="last-activity" className="opacity-80">
            Last Activity
          </label>
          <input
            type="text"
            disabled
            value={
              userData.status?.lastActivityAt
                ? userData.status?.lastActivityAt
                : ""
            }
            readOnly
            className="h-10 w-full rounded-md shadow-md px-4"
          />
        </div>



        <div className="">
          <label htmlFor="aurora" className="opacity-80">
            Last Known Position
          </label>
          <div className="flex flex-col gap-4 my-4">
            <div className="">
              <span>Longitude</span>
              <input
                disabled
                type="text"
                value={
                  userData.lastKnownLocation?.position?.coordinates[0]
                    ? userData.lastKnownLocation?.position?.coordinates[0]
                    : ""
                }
                readOnly
                className="h-10 w-full rounded-md shadow-md px-4"
              />
            </div>
            <div className="">
              <span>Latitude</span>
              <input
                disabled
                type="text"
                value={
                  userData.lastKnownLocation?.position?.coordinates[1]
                    ? userData.lastKnownLocation?.position?.coordinates[1]
                    : ""
                }
                readOnly
                className="h-10 w-full rounded-md shadow-md px-4"
              />
            </div>
          </div>
        </div>
      </div>
    </FormSection>
  );
}

export default LastActiveDetails;
