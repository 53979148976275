import React, { useContext } from "react";
import InputText from "../../../../../../components/form/InputText";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { FormDataContext } from "../../context/form";

type PassportNumberProps = {
    index: number
    value: string
    onChange: (value: string) => void
}
const PassportNumber: React.FC<PassportNumberProps> = ({ index, value, onChange }) => {
  const { data } = useContext(UserProfileContext)!;
  const userData: UserDataTypes = data?.data;
  const formDataContext = useContext(FormDataContext)!;
  const { passportNumberRef } = formDataContext;
  return (
    <div className="">
      <label htmlFor="passport_number" className="opacity-80">
        Passport Number
      </label>
      <InputText
        name={`passport_number[${index}]`}
        placeholder="Enter Passport Number"
        value={value}
        onChange={(e) => {onChange(e.target.value)}}
        inputRef={passportNumberRef}
      />
    </div>
  );
}

export default PassportNumber;
