import React, { ChangeEvent, useEffect, useState } from "react";

function TextAreaEdit({
  maxCharCount,
  value,
  onChange
}: {
  maxCharCount?: number,
  value: string,
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
}) {

    const [charCount, setCharCount] = useState<number>(value.length);


  const checkWordCount = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const textValue = e.target.value;
    setCharCount(textValue.length); 


    if (maxCharCount) {
      const words = textValue.match(/\b[-?(\w+)?]+\b/gi);
      if (words && words.length > maxCharCount) {

        return;
      }
    }

    onChange(e);
  };

  useEffect(() => {
    setCharCount(value.length);
  }, [value]);

  return (
    <div>
      <textarea
        value={value}
        className="border outline-none py-2 px-4 shadow-md border-none rounded-lg w-full overflow-hidden resize-none"
        maxLength={maxCharCount} 
        onChange={checkWordCount}
        placeholder="Add notes"
      />
      {maxCharCount !== undefined && (
        <span className="flex justify-end text-xs font-semibold">
          {charCount} / {maxCharCount}
        </span>
      )}
    </div>
  );
}

export default TextAreaEdit;
