import React, { FormEvent, useRef, useState } from "react";
import { BlueButton } from "../../../../../../components/ui/Buttons";
import api from "../../../../../../api/axiosConfig";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "../../../../../../assets/icons/add-icon.svg";
import BinIcon from "../../../../../../assets/icons/icon-bin.svg";
import useGetAllClientDepartments from "../../../../../../api/client-departments/useGetAllClientDepartments";
import { useQueryClient } from "react-query";
import { DepartmentDataTypes } from "../../../../../../types/department-data-types";
import DepartmentEntry from "../../../add-client/form/add-departments/components/DepartmentEntry";
import NewDepartmentForm from "../../../add-client/form/add-departments/components/NewDepartmentForm";

type DepartmentValues = {
  name: string;
  phoneNumber?: string;
  alertConfig: {
    emailAddress?: string;
    sendToClient?: boolean;
  };
};

function ViewDepartments() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { clientID } = useParams();
  const clientDepartments = useGetAllClientDepartments(clientID!);
  const nameRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);

  const handleChangeDepartment = async (
    id: string,
    changedDepartment: DepartmentValues
  ) => {
    const departmentToChange = clientDepartments.data.find(
      (department) => department.id === id
    );

    const changedDepartmentData: DepartmentDataTypes = {
      id: id,
      clientID: departmentToChange?.clientID!,
      createdAt: departmentToChange?.createdAt!,
      updatedAt: departmentToChange?.updatedAt!,
      isDefault: departmentToChange?.isDefault!,
      metadata: departmentToChange?.metadata!,
      name: changedDepartment.name,
      phoneNumber: changedDepartment.phoneNumber!,
      alertConfig: {
        emergencyPhoneNumber:
          departmentToChange?.alertConfig.emergencyPhoneNumber!,
        emailAddress: changedDepartment.alertConfig.emailAddress!,
        sendToClient: changedDepartment.alertConfig.sendToClient!,
        sendToNGS: departmentToChange?.alertConfig.sendToNGS!,
      },
    };

    await api
      .put(`departments/${id}`, changedDepartmentData)
      .then((res) => {
        queryClient.invalidateQueries("departments");
        queryClient.invalidateQueries("client-departments");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeleteDepartment = (id: string) => {
    api
      .delete(`departments/${id}`)
      .then(() => {
        queryClient.invalidateQueries("departments");
        queryClient.invalidateQueries("client-departments");
      })
      .catch((err) => console.error(err));
  };

  return (
    <form>
      <div className=" mt-12">
        <div className="grid grid-cols-2 gap-x-52 gap-y-8">
          {clientDepartments.data?.map((department) => {
            return (
              <DepartmentEntry
                department={department}
                handleDeleteDepartment={(id: string) =>
                  handleDeleteDepartment(id)
                }
                changeDepartment={(
                  id: string,
                  changedDepartment: DepartmentValues
                ) => handleChangeDepartment(id, changedDepartment)}
              />
            );
          })}
          <NewDepartmentForm />
        </div>
      </div>
      <div className="max-w-md mx-auto my-24 flex flex-col gap-8">
        <BlueButton
          text="Next"
          onClick={() =>
            navigate(`/user-management/client/${clientID}/edit-users`)
          }
        />
        <div className="flex gap-4 justify-center">
          <div className="h-2 w-2 rounded-full bg-light-light-grey"></div>
          <div className="h-2 w-2 rounded-full bg-light-grey"></div>
          <div className="h-2 w-2 rounded-full bg-light-light-grey"></div>
        </div>
      </div>
    </form>
  );
}

export default ViewDepartments;
