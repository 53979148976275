import React, { RefObject, useContext, useState } from "react";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import FormSection from "../form-section";
import { FormDataContext } from "../../context/form";
import { UserProfileContext } from "../../context/user-profile";

function Account() {
  const { data } = useContext(UserProfileContext)!;
  const userData: UserDataTypes = data?.data;
  const formDataContext = useContext(FormDataContext);
  const { primaryPhoneNumberRef, secondaryPhoneNumberRef, passwordRef } =
    formDataContext!;
  const [passwordYourself, setSetPasswordYourself] = useState(false);

  const primaryPhoneNumber = userData.phoneNumbers?.find(
    (number) => number.primary
  );

  const secondaryPhoneNumber = userData.phoneNumbers?.find(
    (number) => !number.primary
  );

  return (
    <FormSection title="Account">
      <div className="grid grid-cols-4 grid-rows-2 gap-8">
        <div className="row-start-1 col-start-1">
          <label htmlFor="email" className="opacity-80">
            Email
          </label>
          <input
            type="email"
            name="email"
            placeholder="Enter Email"
            value={userData.email.toLowerCase()}
            className="h-10 w-full shadow-md rounded-md px-4"
            disabled
            readOnly
          />
        </div>
        <div className="row-start-2 col-start-1">
          <label htmlFor="primary_phone_number" className="opacity-80">
            Primary Phone Number
          </label>
          <input
            required
            name={"primary_phone_number"}
            placeholder="Add Phone Number"
            defaultValue={
              primaryPhoneNumber !== undefined ? primaryPhoneNumber.number : ""
            }
            ref={primaryPhoneNumberRef}
            className="h-10 w-full shadow-md rounded-md px-4"
          />
        </div>
        <div className="row-start-2 col-start-2">
          <label htmlFor="secondary_phone_number" className="opacity-80">
            Secondary Phone Number
          </label>
          <input
            name={"secondary_phone_number"}
            placeholder="Add Phone Number"
            defaultValue={
              secondaryPhoneNumber !== undefined
                ? secondaryPhoneNumber.number
                : ""
            }
            ref={secondaryPhoneNumberRef}
            className="h-10 w-full shadow-md rounded-md px-4"
          />
        </div>
      </div>
    </FormSection>
  );
}

export default Account;
