import React from "react";
import TripForm from "./form";
import {TripFormProvider} from "./context";

const TripCreate: React.FC = () => {
  return <TripFormProvider mode={"create"}>
    <TripForm/>
  </TripFormProvider>
}

export default TripCreate;