import React from "react";
import Search from "../../../../components/form/Search";
import Header from "./Header";
import useDebounce from "../../../../hooks/useDebounce";
import UserStatusCardContainer from "./UserStatusCardContainer";

function Sidebar({
  sidebarType,
}: {
  sidebarType: "non-emergency" | "emergency";
}) {
  const [filterName, setFilterName] = useDebounce(500, "");

  return (
    <div className="overflow-y-hidden pt-8 border-red">
      <div className="flex flex-col gap-8 mx-10 mb-8">
        <Header
          text={sidebarType === "non-emergency" ? "non-emergency" : "emergency"}
          sidebarType={sidebarType}
        />
        <Search
          placeholder="Search for an individual"
          onSearch={(value: string) => setFilterName(value)}
        />
      </div>
      <UserStatusCardContainer
        sidebarType={sidebarType}
        filterValue={filterName}
      />
    </div>
  );
}

export default Sidebar;
