import { useQuery } from "react-query";
import api from "../axiosConfig";

export default function useGetIncidentTypes() {
  const fetchIncidentTypes = async () => {
    return await api
      .get("/incident-types")
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  return useQuery("incident-types", fetchIncidentTypes);
}
