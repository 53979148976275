// @ts-nocheck
// @ts-ignore
import { useContext } from "react";
import { CountryProfileContext } from "../context/country-profile";

export default function useGetSectionData(section: string) {
  const countryProfileContext = useContext(CountryProfileContext);
  if (!countryProfileContext) return null;
  return countryProfileContext.data?.data.analysis.filter(
    (item) => item.slug.toLowerCase() === section.toLowerCase()
  );
}
