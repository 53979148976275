import React, { useContext, useEffect, useRef, useState } from "react";
import {
  BlueButton,
  ButtonBorderTransparent,
} from "../../../../../../components/ui/Buttons";
import AddIcon from "../../../../../../assets/icons/add-icon.svg";
import TableHeader from "../../components/TableHeader";
import { ClientUsersContext } from "../../../../context/client-users";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import Row from "../../components/row";
import PaginatedComponent from "../../../../../../components/ui/PaginatedComponent";
import Modal from "../../../../../../components/portal/Modal";
import AddUser from "../../../../components/add-user";
import useDebouncer from "../../../../../../hooks/debouncer";
import Download from "../../../../../../assets/icons/download-blue.svg";
import api from "../../../../../../api/axiosConfig";
import { downloadCSV } from "../../../../../../util/helpers";
import { useParams } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import Checkbox from "../../../../../../components/form/Checkbox";
import { emailTemplate } from "../../../add-client/constants/email-template";

function Main() {
  const [addUserModal, setAddUserModal] = useState<boolean>(false);
  const [emailTemplateModal, setEmailTemplateModal] = useState<boolean>(false);
  const [invitationEmailSelected, setInvitationEmailSelected] =
    useState<boolean>(false);
  const invitationEmailRef = useRef<HTMLTextAreaElement>(null);

  const { clientID } = useParams();
  const { page, setPage, searchUserByName, setSearchUserByName, allUsers } =
    useContext(ClientUsersContext)!;

  const [csvLoading, setCsvLoading] = useState<boolean>(false);
  const [value, setValue] = useState(searchUserByName);
  const debounced = useDebouncer(value, 500);

  const inputRef = useRef<HTMLInputElement>(null);

  const [clientUsersData, setClientUsersData] = useState<UserDataTypes[]>();

  useEffect(() => {
    setSearchUserByName(debounced);
    const items = allUsers.data?.data.items;
    setClientUsersData(items);
  }, [debounced]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const getCSV = () => {
    setCsvLoading(true);
    api
      .get("/users-csv", {
        params: {
          clientID: clientID,
        },
      })
      .then((res) => {
        setCsvLoading(false);
        downloadCSV(res.data, "users_summary_.csv");
      })
      .catch((err) => {
        setCsvLoading(false);
        console.error(err);
      });
  };

  const openInvitationEmailModal = () => {
    setEmailTemplateModal(true);
  };

  const saveInvitationEmail = () => {
    setEmailTemplateModal(false);
    // const email = invitationEmailRef.current?.value;
    // if (email) {
    //   api
    //     .post(`/email-template`, {
    //       clientID: clientID,
    //       emailTemplate: email,
    //     })
    //     .then((res) => {})
    //     .catch((err) => {
    //       console.error(err);
    //     });
    // }
  };

  return (
    <div className="bg-white w-full shadow-lg h-full p-16 flex flex-col gap-8 relative">
      <div className="flex justify-between items-center border-b pb-8">
        <p className="font-semibold">
          Total Users: {`${allUsers.data?.data.total}`}
        </p>

        <div className="flex items-center justify-center gap-8">
          <div className="mr-3 w-[205px] flex justify-center">
            {csvLoading && (
              <Oval
                height={30}
                width={30}
                color="#003B6E"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#BCE0FD"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            )}
            {!csvLoading && (
              <ButtonBorderTransparent
                text="Download Users CSV"
                onClick={() => getCSV()}
                icon={Download}
              />
            )}
          </div>
          <input
            ref={inputRef}
            type="text"
            className="h-10 px-4 rounded-md shadow-md"
            placeholder="Search for a User"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <div className="w-32">
            <BlueButton
              text="Add User"
              icon={AddIcon}
              type="button"
              onClick={() => setAddUserModal(true)}
            />
          </div>
        </div>
      </div>
      <TableHeader />

      <div className="flex-1">
        <PaginatedComponent page={page} setPage={setPage} query={allUsers}>
          <div className="">
            {clientUsersData?.map((user: UserDataTypes) => {
              return <Row user={user} key={user.id} />;
            })}
          </div>
        </PaginatedComponent>
      </div>

      <Modal open={addUserModal} setOpen={setAddUserModal}>
        <AddUser
          onCreated={() => {
            setAddUserModal(false);
          }}
        />
      </Modal>

      <Modal open={emailTemplateModal} setOpen={setEmailTemplateModal}>
        <div className="bg-white p-8 rounded-md flex flex-col gap-8 w-fit">
          <h4 className="text-lg font-semibold mx-auto">Invitation Email</h4>
          <p>
            This will be sent to all users when they are first added to the
            Aurora platform. Note that the below is the default invitation
            email. Please click the text box to edit.
          </p>
          <div className="flex gap-4">
            <Checkbox
              name={"invitation-email"}
              checked={invitationEmailSelected}
              value={"invitation-email"}
              label={"Add invitation email"}
              onClick={() => setInvitationEmailSelected((prev) => !prev)}
            />
          </div>

          {invitationEmailSelected && (
            <textarea
              name=""
              id=""
              cols={120}
              rows={20}
              className="border outline-none overflow-y-auto py-2 px-4 bg-desaturated-grey border-none rounded-lg w-full overflow-hidden resize-none"
              defaultValue={emailTemplate}
              ref={invitationEmailRef}
            />
          )}

          <div className="w-1/4 mx-auto">
            <BlueButton text="Confirm" onClick={saveInvitationEmail} />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Main;
