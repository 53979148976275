import { useQuery } from "react-query";
import api from "../axiosConfig";

export default function useGetClient(id: string) {
  const fetchClient = async () => {
    return await api
      .get(`/clients/${id}`)
      .then((res) => res.data)
      .catch((err) => console.error(err));
  };

  return useQuery("client-world-search", fetchClient);
}
