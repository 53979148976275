import React, {RefObject, useEffect, useState} from "react";
import FormSection from "../form-section";
import {UserEmergencyContact, UserPassport} from "../../../../../../types/user-data-types";

import {BlueButton, OrangeButton} from "../../../../../../components/ui/Buttons";
import InputText from "../../../../../../components/form/InputText";

type EmergencyContactProps = {
   emergencyContacts: UserEmergencyContact[];
    addEmergencyContact: () => void;
    removeEmergencyContact: (index: number) => void;
    setEmergencyContacts: (emergencyContacts: UserEmergencyContact[]) => void;
}
const EmergencyContact:React.FC<EmergencyContactProps> = ({emergencyContacts, addEmergencyContact, removeEmergencyContact, setEmergencyContacts}) => {
    const onChange = (emergencyContact: UserEmergencyContact, index: number) => {
        const newEmergencyContacts = [...emergencyContacts]
        newEmergencyContacts[index] = emergencyContact
        setEmergencyContacts(newEmergencyContacts)
    }

    return (
        <FormSection title="EmergencyContact Details">
            {emergencyContacts.map((c, i) => (
                <div key={c.id} className={"mb-8"}>
                    <Fields item={c} index={i} onChange={onChange} remove={removeEmergencyContact}/>
                </div>
            ))}
            <div className="grid grid-cols-4 gap-8">
                <BlueButton text={"Add Contact"} onClick={addEmergencyContact}/>
            </div>
        </FormSection>
    )
}

export default EmergencyContact;

type FieldsProps<T> = {
    item: T;
    index: number;
    onChange: (item: T, index: number) => void;
    remove: (index: number) => void;
}


const Fields: React.FC<FieldsProps<UserEmergencyContact>> = ({item, index, onChange, remove}) => {
    const [firstName, setFirstName] = useState(item.firstName)
    const [lastName, setLastName] = useState(item.lastName)
    const [relationship, setRelationship] = useState(item.relationship)
    const [phoneNumber, setPhoneNumber] = useState(item.phoneNumber)
    const [email, setEmail] = useState(item.email)


    useEffect(() => {
        onChange({
            firstName,
            lastName,
            relationship,
            phoneNumber,
            email,
        }, index)
    }, [firstName, lastName, relationship, phoneNumber, email])

    return (
        <div className="grid grid-cols-4 gap-8">
            <Field label={"First Name"} placeholder={""} value={firstName} onChange={setFirstName}/>
            <Field label={"Last Name"} placeholder={""} value={lastName} onChange={setLastName}/>
            <Field label={"Relationship"} placeholder={""} value={relationship} onChange={setRelationship}/>
            <Field label={"Phone Number"} placeholder={""} value={phoneNumber} onChange={setPhoneNumber}/>
            <Field label={"Email Address"} placeholder={""} value={email} onChange={setEmail}/>

            <div className="">
                <label>&nbsp;</label>
                <OrangeButton text={"Remove Contact"} onClick={() => {
                    remove(index)
                }}/>
            </div>
        </div>
    )
}


type FieldProps = {
    label: string;
    placeholder: string;
    value: string;
    onChange: (v: string) => void;
}

const Field:React.FC<FieldProps> = ({
    label, placeholder, value, onChange
                                    }) => {
    return (<div>
        <label className="opacity-80">
            {label}
        </label>
        <InputText
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
                onChange(e.target.value)
            }}
        />
    </div>)
}
