import React from "react";

export default function IndividualTripHeader() {
  return (
    <div className="rounded-t-xl bg-desaturated-grey py-2 grid grid-cols-7 w-full items-center">
      <div className="font-bold border-r text-center relative px-4 py-1 h-full flex items-center justify-center">
        <div>
          Check In Time<br/><span className={"text-sm font-normal"}>Local Time</span>
        </div>
      </div>
      <div className="font-bold border-r text-center relative px-4 py-2 h-full flex items-center justify-center">
        <div>

        Check In Time<br/><span className={"text-sm font-normal"}>Operator Time</span>
        </div>

      </div>
      <div className="font-bold border-r text-center relative px-4 py-2 h-full flex items-center justify-center ">
        Location
      </div>
      <div className="font-bold border-r text-center relative px-4 py-2 h-full flex items-center justify-center ">
        Traveller
      </div>
      <div className="font-bold border-r text-center relative px-4 py-2 h-full flex items-center justify-center">
        <div>

          Grace Period<br/><span className={"text-sm font-normal"}>Minutes</span>
        </div>

      </div>
      <div className="font-bold border-r text-center relative px-4 py-2 h-full flex items-center justify-center ">
        Edit Check In
      </div>
      <div className="font-bold text-center relative">Delete Check In</div>
    </div>
  );
}
