import React from "react";
type LabelTypes = {
  text: string;
  assignText?: string;
  extraClass?: string;
  htmlFor?: string;
};

function Label({ text, assignText, extraClass, htmlFor }: LabelTypes) {
  return (
    <div className={`text-sm text-grey flex gap-1 ${extraClass}`}>
      <label htmlFor={htmlFor} className="font-semibold">
        {text}
      </label>
      <label htmlFor="" className="">
        {assignText}
      </label>
    </div>
  );
}

export default Label;
