import React, { useEffect, useState } from "react";
import Checkbox from "../../../../../components/form/Checkbox";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";
import useGetAllClients from "../../../../../api/clients/useGetAllClients";

function SearchByClient({
  setFilterByClientToggle,
  filterByClientToggle,
  setSelectedClientIDs,
  selectedClientIDs,
}: {
  setFilterByClientToggle: React.Dispatch<React.SetStateAction<boolean>>;
  filterByClientToggle: boolean;
  setSelectedClientIDs: React.Dispatch<
    React.SetStateAction<{ value: string; label: string }[]>
  >;
  selectedClientIDs: { value: string; label: string }[];
}) {
  const [searchClientName, setSearchClientName] = useState<string>("");

  const [selectedClients, setSelectedClients] = React.useState<
    { label: string; value: string }[]
  >([]);
  const allClients = useGetAllClients()
    .all_clients_data.map((client) => ({
      value: client.id,
      label: client.name,
    }))
    .filter((client) => {
      if (searchClientName === "") {
        return client;
      } else {
        return client.label
          .toLowerCase()
          .includes(searchClientName.toLowerCase());
      }
    });
  const clientsSelectRef = React.useRef<HTMLDivElement>(null);

  useOnClickOutside(clientsSelectRef, () => setFilterByClientToggle(false));

  const handleSelectClient = (client: { label: string; value: string }) => {
    const clientExists = selectedClientIDs.find(
      (item) => item.value === client.value
    );

    if (clientExists) {
      const filterClients: { label: string; value: string }[] =
        selectedClientIDs.filter((item) => item.value !== client.value);
      setSelectedClientIDs(filterClients);
    } else {
      setSelectedClientIDs((prev) => [...prev, client]);
    }
  };

  return (
    <div
      className={`h-96 overflow-y-auto bg-white absolute top-10 z-10 left-0 rounded-md w-full shadow-md px-4 flex flex-col items-start justify-start gap-4 py-4 overflow-x-hidden ${
        filterByClientToggle ? "flex" : "hidden"
      }`}
      ref={clientsSelectRef}
    >
      <input
        type="text"
        placeholder="Search client"
        className="shadow-md w-full rounded-md px-4 py-2 mx-auto"
        onChange={(e) => setSearchClientName(e.target.value)}
      />
      {allClients.map((client) => {
        const clientExists = selectedClientIDs.find(
          (item) => item.value === client.value
        );

        return (
          <Checkbox
            key={client.value}
            name="client"
            value={client.value}
            label={client.label}
            onClick={() => handleSelectClient(client)}
            checked={clientExists ? true : false}
          />
        );
      })}
    </div>
  );
}

export default SearchByClient;
