import { useEffect, useState } from "react";
import { Coordinates, FeatureType } from "../../../types";
type Action = {
  type: "ADD_FEATURE" | "EDIT_FEATURE" | "DELETE_FEATURE" | "SET_FEATURES";
  payload: FeatureType | FeatureType[];
};
export default function useEditPolyline(
  features: FeatureType[],
  map: google.maps.Map | undefined,
  featuresDispatch: React.Dispatch<Action>
) {
  const [updatedPolyline, setUpdatedPolyline] = useState<FeatureType>();
  const polylines = features?.filter(
    (feature) => feature.drawingType === "polyline"
  );

  polylines?.forEach((feature) => {
    const polyline = feature.drawing as google.maps.Polyline;

    const handleNewPath = (newPolyline: google.maps.Polyline) => {
      const newPath = newPolyline
        .getPath()
        .getArray()
        .map((latLng) => ({
          lng: latLng.lng(),
          lat: latLng.lat(),
        }));
      console.log(newPath);
      polyline.setPath(newPath);
      const updatedData: FeatureType = {
        ...feature,
        drawing: polyline,
        geometry: {
          type: "LineString",
          coordinates: newPath.map((path) => [path.lng, path.lat]),
        },
      };
      setUpdatedPolyline(updatedData);
    };

    google.maps.event.addListener(polyline.getPath(), "set_at", () =>
      handleNewPath(polyline)
    );
    google.maps.event.addListener(polyline.getPath(), "insert_at", () =>
      handleNewPath(polyline)
    );
    google.maps.event.addListener(polyline.getPath(), "remove_at", () =>
      handleNewPath(polyline)
    );
  });

  useEffect(() => {
    if (!updatedPolyline) return;
    featuresDispatch({
      type: "EDIT_FEATURE",
      payload: updatedPolyline as FeatureType,
    });
  }, [updatedPolyline]);
}
