import React, { useContext, useEffect, useState } from "react";
import Select from "../select";
import SuitcaseIcon from "../../../assets/icons/suitcase.svg";
import { ClientDataTypes } from "../../../types/client-data-types";
import useGetAllClients from "../../../api/clients/useGetAllClients";
import { AccessTokenPayloadType } from "../../../context/user";

type Option = {
  label: string;
  value: string;
};

function ClientSelect({ onSelect }: { onSelect?: (value: string) => void }) {
  const [defaultClient, setDefaultClient] = useState<Option | null>(null);
  const { all_clients_data: clients } = useGetAllClients();
  const clientOptions: { value: string; label: string }[] = clients.map(
    (client: ClientDataTypes) => ({
      label: client.name,
      value: client.id,
    })
  );
  const user: AccessTokenPayloadType = JSON.parse(
    localStorage.getItem("user") as string
  );
  const defaultClientForUser = clients.find(
    (client) => client.id === user.metadata.clientID
  );
  useEffect(() => {
    if (!defaultClientForUser) return;
    if (defaultClientForUser) {
      const defaultClientOption = {
        label: defaultClientForUser?.name,
        value: defaultClientForUser?.id,
      };
      setDefaultClient(defaultClientOption);
      if (onSelect) {
        onSelect(defaultClientOption.value);
      }
    }
  }, [defaultClientForUser]);

  return (
    <Select
      name="client"
      options={clientOptions}
      title="Clients"
      icon={SuitcaseIcon}
      defaultSelected={defaultClient}
      onSelect={onSelect}
    />
  );
}

export default ClientSelect;
