import React, { useState } from "react";
import useClient from "../../../../../hooks/api/useClient";
import PencilIcon from "../../../../../assets/icons/pencil.svg";
import BinIcon from "../../../../../assets/icons/icon-bin.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../../components/portal/Modal";
import DeleteConfirmation from "../../../components/delete-confirmation";
import useGetUser from "../../../../../api/user/useGetUser";
function Row({
  data,
  handleDelete,
  handleEdit,
}: {
  data: {
    clientID: string;
    createdBy: string;
    startsAt: string;
    expiresAt: string;
    id: string;
    name: string;
    departmentName: null|string;
    clientName: null|string
    type: "geofence" | "zone";
  };
  handleDelete: () => void;
  handleEdit: () => void;
}) {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const startsAtDate = moment(data.startsAt).format("DD/MM/yyyy");
  const startsAtTime = moment(data.startsAt).format("HH:MM");
  const endsAtDate = moment(data.expiresAt).format("DD/MM/yyyy");
  const endsAtTime = moment(data.expiresAt).format("HH:MM");

  return false ? null : (
    <>
      <div
        className="grid grid-cols-7 bg-desaturated-grey rounded-lg items-center h-16"
        key={data.id}
      >
        <span className="border-r">{data.name !== "" ? data.name : "N/A"}</span>
        <span className="border-r">{data.clientName}</span>
        <span className="border-r">{data.departmentName}</span>
        <span className="border-r capitalize">
          {data.type === "geofence" ? "Geofence" : "Safety Zone"}
        </span>
        <div className="flex items-center gap-2 justify-center">
          {startsAtDate === "Invalid date" ||
          endsAtDate === "Invalid date" ||
          startsAtTime === "Invalid date" ||
          endsAtTime === "Invalid date" ? (
            <span>N/A</span>
          ) : (
            <>
              <div className="flex items-center gap-2 justify-center">
                <div className="flex flex-col">
                  <span>{startsAtDate}</span>
                  <span>{startsAtTime}</span>
                </div>
                -
                <div className="flex flex-col">
                  <span>{endsAtDate}</span>
                  <span>{endsAtTime}</span>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="border-r border-l">
          <button className="mx-auto w-4 h-4" onClick={() => handleEdit()}>
            <img src={PencilIcon} alt="" />
          </button>
        </div>
        <div className="">
          <button
            className="mx-auto w-4 h-4"
            onClick={() => setDeleteModal(true)}
          >
            <img src={BinIcon} alt="" />
          </button>
        </div>
      </div>
      <Modal setOpen={setDeleteModal} open={deleteModal}>
        <DeleteConfirmation
          text={`You are about to delete ${data.name}. Please press 
          the button below to confirm.`}
          handleDelete={() => {
            handleDelete();
            setDeleteModal(false);
          }}
          cancelDelete={() => setDeleteModal(false)}
        />
      </Modal>
    </>
  );
}

export default Row;
