import React, { SetStateAction, useContext, useRef, useState } from "react";
import FormModal from "../../../../components/form/FormModal";
import { UserActivityDataTypes } from "../../../../types/user-activity-data-types";
import { BlueButton } from "../../../../components/ui/Buttons";
import api from "../../../../api/axiosConfig";
import Modal from "../../../../components/portal/Modal";
import DoneIcon from "@mui/icons-material/Done";
import { UserContext } from "../../../../context/user";
function Message({
  userData,
  setSendNotificationPopup,
}: {
  userData: UserActivityDataTypes;
  setSendNotificationPopup: React.Dispatch<SetStateAction<boolean>>;
}) {
  const messageRef = useRef<HTMLTextAreaElement>(null);
  const handleMessage = () => {
    if (!messageRef.current!.value) return;
    const messageText = messageRef.current!.value;
    api
      .post(`ops/send-message/${userData.userID}`, { message: messageText })
      .then(() => {
        setSendNotificationPopup(false);
      })
      .catch((err) => console.error(err));
  };
  
  const userContext = useContext(UserContext);

  if (userContext?.sub == userData.userID) {
    return (
      <FormModal title="Send Message">
        <div className="flex flex-col gap-2 my-6">
          <div className="my-4">
            Message cannot be sent to self
          </div>
        </div>
      </FormModal>

    )
  }



  return (
    <FormModal title="Send Message">
      <div className="flex flex-col gap-2 my-6">
        <div className="my-4">
          <textarea
            className="bg-light-light-grey outline-none p-4"
            rows={5}
            cols={20}
            placeholder={`Message for ${userData.metadata.userName}`}
            ref={messageRef}
          />
        </div>
      </div>
      <BlueButton onClick={() => handleMessage()} text={"Send"} />
    </FormModal>
  );
}

export default Message;
