import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../../../../../api/axiosConfig";

export default function useSavedCountries() {
  const queryClient = useQueryClient();
  const fetchSavedCountriesProfile = () => {
    return api
      .get("saved/country-profiles")
      .then((res) => res.data)
      .catch((err) => console.error(err));
  };

  const savedCountriesQuery = useQuery(
    "saved-countries",
    fetchSavedCountriesProfile
  );

  const saveCountryProfile = async (countryIDs: string[]) => {
    return api
      .post("/saved/country-profiles", { countryIDs: countryIDs })
      .then((res) => res.data)
      .catch((err) => console.error(err));
  };

  const saveCountryMutation = useMutation(saveCountryProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries("saved-countries");
    },
  });

  return { savedCountriesQuery, saveCountryMutation };
}
