import React, { ChangeEvent, RefObject } from "react";

type InputTextPropsType = {
  placeholder?: string;
  biggerInput?: boolean;
  value?: string | number;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  missingError?: string;
  icon?: string;
  inputRef?: RefObject<HTMLInputElement>;
  defaultValue?: string;
  type?: string;
};

function InputText({
  placeholder,
  biggerInput = false,
  value,
  name,
  onChange,
  required = false,
  missingError,
  icon,
  inputRef,
  defaultValue,
  type = "text",
}: InputTextPropsType) {
  return (
    <div className="flex items-center gap-2 w-full relative">
      {required ? (
        <span className="text-red absolute top-2/4 -translate-y-2/4 -left-3">
          *
        </span>
      ) : null}
      <div className="w-full relative">
        {icon && (
          <img
            src={icon}
            alt=""
            className="absolute left-4 top-2/4 -translate-y-2/4"
          />
        )}
        <span>{missingError}</span>

        <input
          defaultValue={defaultValue}
          ref={inputRef}
          name={name}
          onChange={onChange}
          value={value}
          type={type}
          required={required}
          placeholder={placeholder}
          className={`outline-none w-full ${icon && "pl-14"} ${
            biggerInput
              ? "px-8 py-7 h-fit rounded-3xl shadow-lg"
              : "max-w-sm h-10 px-4 rounded-md shadow-md"
          }`}
        />
      </div>
    </div>
  );
}

export default InputText;
