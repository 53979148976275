import React from "react";
import { BlueButton, WhiteButton } from "../../../../../components/ui/Buttons";
import DownloadWhite from "../../../../../assets/icons/download-white.svg";

export default function MassWarningIndividual() {
  return (
    <div className="overflow-hidden h-[calc(100vh_-_300px)]">
      <div className="overflow-y-scroll h-full">
        <div className="py-10 border-b flex w-full justify-between ">
          <div className="flex flex-col">
            <span className="font-bold">Client Incident, Seoul</span>
            <span className="text-[#707070] my-2">2910-06139449-1</span>
            <span className="text-light-grey">29/10/22</span>
            <span className="text-light-grey">23:20:35</span>
            <div className="mt-2 w-[250px]">
              <BlueButton
                text="Download Complete Report"
                onClick={() => {}}
                icon={DownloadWhite}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex">
              <div className="flex">
                <div className="w-[200px] rounded-l-lg bg-grey text-white py-2 text-center">
                  Total Responded
                </div>
                <div className="w-[100px] rounded-r-lg bg-desaturated-grey text-grey py-2 text-center">
                  159
                </div>
              </div>
              <div className="ml-5">
                <BlueButton
                  text="Download"
                  onClick={() => {}}
                  icon={DownloadWhite}
                />
              </div>
            </div>
            <div className="flex mt-4">
              <div className="flex">
                <div className="w-[200px] rounded-l-lg bg-grey text-white py-2 text-center">
                  Total Unresponded
                </div>
                <div className="w-[100px] rounded-r-lg bg-desaturated-grey text-grey py-2 text-center">
                  137
                </div>
              </div>
              <div className="ml-5">
                <BlueButton
                  text="Download"
                  onClick={() => {}}
                  icon={DownloadWhite}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="py-10 border-b">
          <div>1st Alert</div>
          <div className="mt-2 w-[250px]">
            <BlueButton
              text="Download Report"
              onClick={() => {}}
              icon={DownloadWhite}
            />
          </div>
          <div className="mt-3">
            <div className="flex items-center ">
              <div className="grid grid-cols-7 w-full text-center">
                <div></div>
                <div className="py-2 bg-desaturated-grey rounded-l-md">
                  Total
                </div>
                <div className="py-2 bg-desaturated-grey">Failed</div>
                <div className="py-2 bg-desaturated-grey">Sent</div>
                <div className="py-2 bg-desaturated-grey rounded-r-md">
                  Responded
                </div>
                <div></div>
                <div></div>
              </div>
              <div className="ml-4 w-[160px]">
                <WhiteButton onClick={() => {}} text="View Message" />
              </div>
            </div>
            <div className="flex mt-3">
              <div className="grid grid-cols-7 text-center w-full">
                <div className="text-left">App</div>
                <div>297</div>
                <div>297</div>
                <div>297</div>
                <div>297</div>
              </div>
              <div className="w-[164px]"></div>
            </div>
            <div className="flex items-center ">
              <div className="grid grid-cols-7 w-full text-center">
                <div></div>
                <div className="py-2 bg-desaturated-grey rounded-l-md">
                  Total
                </div>
                <div className="py-2 bg-desaturated-grey">Failed</div>
                <div className="py-2 bg-desaturated-grey">Sent</div>
                <div className="py-2 bg-desaturated-grey rounded-r-md">
                  Responded
                </div>
                <div></div>
                <div></div>
              </div>
              <div className="ml-4 w-[160px]">
                <WhiteButton onClick={() => {}} text="View Message" />
              </div>
            </div>
            <div className="flex mt-3">
              <div className="grid grid-cols-7 text-center w-full">
                <div className="text-left">SMS</div>
                <div>297</div>
                <div>297</div>
                <div>297</div>
                <div>297</div>
              </div>
              <div className="w-[164px]"></div>
            </div>
            <div className="flex items-center ">
              <div className="grid grid-cols-7 w-full text-center">
                <div></div>
                <div className="py-2 bg-desaturated-grey rounded-l-md">
                  Total
                </div>
                <div className="py-2 bg-desaturated-grey">Failed</div>
                <div className="py-2 bg-desaturated-grey">Sent</div>
                <div className="py-2 bg-desaturated-grey rounded-r-md">
                  Responded
                </div>
                <div></div>
                <div></div>
              </div>
              <div className="ml-4 w-[160px]">
                <WhiteButton onClick={() => {}} text="View Message" />
              </div>
            </div>
            <div className="flex mt-3">
              <div className="grid grid-cols-7 text-center w-full">
                <div className="text-left">Email</div>
                <div>297</div>
                <div>297</div>
                <div>297</div>
                <div>297</div>
              </div>
              <div className="w-[164px]"></div>
            </div>
            <div className="flex items-center ">
              <div className="grid grid-cols-7 w-full text-center">
                <div></div>
                <div className="py-2 bg-desaturated-grey rounded-l-md">
                  Total
                </div>
                <div className="py-2 bg-desaturated-grey">Failed</div>
                <div className="py-2 bg-desaturated-grey">Sent</div>
                <div className="py-2 bg-desaturated-grey rounded-r-md">
                  Responded
                </div>
                <div></div>
                <div></div>
              </div>
              <div className="ml-4 w-[160px]">
                <WhiteButton onClick={() => {}} text="View Audio" />
              </div>
            </div>
            <div className="flex mt-3">
              <div className="grid grid-cols-7 text-center w-full">
                <div className="text-left">Calls</div>
                <div>297</div>
                <div>297</div>
                <div>297</div>
                <div>297</div>
                <div>0</div>
                <div>0</div>
              </div>
              <div className="w-[164px]"></div>
            </div>
          </div>
        </div>
        <div className="py-10">
          <div>2nd Alert</div>
          <div className="mt-2 w-[250px]">
            <BlueButton
              text="Download Report"
              onClick={() => {}}
              icon={DownloadWhite}
            />
          </div>
          <div className="mt-3">
            <div className="flex items-center ">
              <div className="grid grid-cols-7 w-full text-center">
                <div></div>
                <div className="py-2 bg-desaturated-grey rounded-l-md">
                  Total
                </div>
                <div className="py-2 bg-desaturated-grey">Failed</div>
                <div className="py-2 bg-desaturated-grey">Sent</div>
                <div className="py-2 bg-desaturated-grey rounded-r-md">
                  Responded
                </div>
                <div></div>
                <div></div>
              </div>
              <div className="ml-4 w-[160px]">
                <WhiteButton onClick={() => {}} text="View Message" />
              </div>
            </div>
            <div className="flex mt-3">
              <div className="grid grid-cols-7 text-center w-full">
                <div className="text-left">App</div>
                <div>297</div>
                <div>297</div>
                <div>297</div>
                <div>297</div>
              </div>
              <div className="w-[164px]"></div>
            </div>
            <div className="flex items-center ">
              <div className="grid grid-cols-7 w-full text-center">
                <div></div>
                <div className="py-2 bg-desaturated-grey rounded-l-md">
                  Total
                </div>
                <div className="py-2 bg-desaturated-grey">Failed</div>
                <div className="py-2 bg-desaturated-grey">Sent</div>
                <div className="py-2 bg-desaturated-grey rounded-r-md">
                  Responded
                </div>
                <div></div>
                <div></div>
              </div>
              <div className="ml-4 w-[160px]">
                <WhiteButton onClick={() => {}} text="View Message" />
              </div>
            </div>
            <div className="flex mt-3">
              <div className="grid grid-cols-7 text-center w-full">
                <div className="text-left">SMS</div>
                <div>297</div>
                <div>297</div>
                <div>297</div>
                <div>297</div>
              </div>
              <div className="w-[164px]"></div>
            </div>
            <div className="flex items-center ">
              <div className="grid grid-cols-7 w-full text-center">
                <div></div>
                <div className="py-2 bg-desaturated-grey rounded-l-md">
                  Total
                </div>
                <div className="py-2 bg-desaturated-grey">Failed</div>
                <div className="py-2 bg-desaturated-grey">Sent</div>
                <div className="py-2 bg-desaturated-grey rounded-r-md">
                  Responded
                </div>
                <div></div>
                <div></div>
              </div>
              <div className="ml-4 w-[160px]">
                <WhiteButton onClick={() => {}} text="View Message" />
              </div>
            </div>
            <div className="flex mt-3">
              <div className="grid grid-cols-7 text-center w-full">
                <div className="text-left">Email</div>
                <div>297</div>
                <div>297</div>
                <div>297</div>
                <div>297</div>
              </div>
              <div className="w-[164px]"></div>
            </div>
            <div className="flex items-center ">
              <div className="grid grid-cols-7 w-full text-center">
                <div></div>
                <div className="py-2 bg-desaturated-grey rounded-l-md">
                  Total
                </div>
                <div className="py-2 bg-desaturated-grey">Failed</div>
                <div className="py-2 bg-desaturated-grey">Sent</div>
                <div className="py-2 bg-desaturated-grey">Responded</div>
                <div className="py-2 bg-desaturated-grey">Played</div>
                <div className="py-2 bg-desaturated-grey rounded-r-md">
                  Acknowledged
                </div>
              </div>
              <div className="ml-4 w-[160px]">
                <WhiteButton onClick={() => {}} text="View Audio" />
              </div>
            </div>
            <div className="flex mt-3">
              <div className="grid grid-cols-7 text-center w-full">
                <div className="text-left">Calls</div>
                <div>297</div>
                <div>297</div>
                <div>297</div>
                <div>297</div>
                <div>0</div>
                <div>0</div>
              </div>
              <div className="w-[164px]"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
