import React, {useContext, useEffect, useState} from "react";
import { UserProfileContext } from "../../context/user-profile";
import useGetClient from "../../../../../../api/client/useGetClient";
import useGetAllClientDepartments from "../../../../../../api/client-departments/useGetAllClientDepartments";
import { BlueButton } from "../../../../../../components/ui/Buttons";
import {
  UserDataTypes,
  UserEmergencyContact,
  UserInsurancePolicy,
  UserPassport
} from "../../../../../../types/user-data-types";
import Account from "../../components/account";
import RequiredInformation from "../../components/required-information";
import EmploymentDetails from "../../components/employment-details";
import EmergencyContact from "../../components/emergency-contact";
import PassportDetails from "../../components/passport-details";
import MedicalInformation from "../../components/medical-information";
import InsuranceDetails from "../../components/insurance-details";
import LastActiveDetails from "../../components/last-active-details";
import { FormDataContext } from "../../context/form";
import ExternalIDs from "../../components/external-ids";
import { useNavigate } from "react-router-dom";


function Main() {
  const formDataContext = useContext(FormDataContext);
  const { handleEdit } = formDataContext!;
  const userProfile = useContext(UserProfileContext)!;
  const userProfileData: UserDataTypes = userProfile.data?.data;
  const client = useGetClient(userProfileData.clientID)!;
  const department = useGetAllClientDepartments(userProfileData.departmentID!);
  const navigate = useNavigate();

  const [passports, setPassports] = useState<UserPassport[]>([])
  const [emergencyContacts, setEmergencyContacts] = useState<UserEmergencyContact[]>([])
  const [insurancePolicies, setInsurancePolicies] = useState<UserInsurancePolicy[]>([])


  useEffect(()=> {
    if(userProfileData.passports) {
      setPassports(userProfileData.passports.map(p => {
        return {...p, id: crypto.randomUUID()}
      }))
    }

    if(userProfileData.emergencyContacts){
      setEmergencyContacts(userProfileData.emergencyContacts.map(c => {
        return {...c, id: crypto.randomUUID()}
      }))
    }

    if(userProfileData.insurancePolicies){
      setInsurancePolicies(userProfileData.insurancePolicies.map(p => {
        return {...p, id: crypto.randomUUID()}
      }))
    }

  },[])



  const addPassport = () => {
    setPassports([...passports,  {id: crypto.randomUUID(), expiresAt:null} as UserPassport])
  }

  const removePassport = (index: number) => {
    const newPassports = [...passports]
    newPassports.splice(index, 1)
    setPassports(newPassports)
  }

  const addEmergencyContact = () => {
    setEmergencyContacts([...emergencyContacts, {id: crypto.randomUUID()} as UserEmergencyContact])
  }

  const removeEmergencyContact = (index: number) => {
    const newContacts = [...emergencyContacts]
    newContacts.splice(index, 1)
    setEmergencyContacts(newContacts)
  }

  const addInsurancePolicy= () => {
    setInsurancePolicies([...insurancePolicies,  {id: crypto.randomUUID(), expiresAt:null} as UserInsurancePolicy])
  }

  const removeInsurancePolicy = (index: number) => {
    const newPolicies = [...insurancePolicies]
    newPolicies.splice(index, 1)
    setInsurancePolicies(newPolicies)
  }


  const handleEditAndRedirect = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); 
    handleEdit(e, passports, emergencyContacts, insurancePolicies);
    const clientID = userProfileData.clientID;

    if (clientID) {
      navigate(`/user-management/client-users/${clientID}`);
    } else {
      console.error("Client ID is not available");
    }
  };

  return client.status !== "success" &&
    department?.status !== "success" ? null : (
    <div className="bg-white w-full shadow-lg h-full py-10 pt-4 px-10">
      <div className="flex items-center justify-between py-4 border-b text-md">
        <span className="font-semibold text-lg">User Profile</span>
      </div>

      <form className=" max-h-[70vh] overflow-y-auto pr-8 my-8">
        <Account />
        <RequiredInformation />
        <EmploymentDetails />
        <EmergencyContact emergencyContacts={emergencyContacts} addEmergencyContact={addEmergencyContact} removeEmergencyContact={removeEmergencyContact} setEmergencyContacts={setEmergencyContacts}/>
        <PassportDetails passports={passports} addPassport={addPassport} removePassport={removePassport} setPassports={setPassports}/>
        <MedicalInformation />
        <InsuranceDetails insurancePolicies={insurancePolicies} addInsurancePolicy={addInsurancePolicy} removeInsurancePolicy={removeInsurancePolicy} setInsurancePolicies={setInsurancePolicies}/>
        <ExternalIDs />
        <LastActiveDetails />

        {userProfileData.metadata &&
        <div className="mt-2">
          <span className="py-8 font-semibold inline-block">Additional Information</span>


          <div className="grid grid-cols-4 gap-8">
            {userProfileData.metadata.map(m =>
            <div className="">
              <label htmlFor="first_name" className="opacity-80">
                {m.label}
              </label>
              <input
                disabled
                defaultValue={m.value}
                className="h-10 w-full shadow-md rounded-md px-4"
              />

            </div>)}
          </div>
        </div>
        }
        <div className="max-w-xs mx-auto my-12 gap-4 flex flex-col">
          <BlueButton
            type="submit"
            text="Update Profile"
            onClick={handleEditAndRedirect}
          />
        </div>
      </form>
    </div>
  );
}
export default Main;
