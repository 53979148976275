import React from "react";
import { Link } from "react-router-dom";

function MainLink({
  title,
  color,
  icon,
  to,
  active,
}: {
  title: string;
  color: string;
  to: string;
  icon: string;
  active: boolean;
}) {
  return (
    <Link
      to={to}
      draggable={false}
      className={`text-sm font-semibold transition-colors  ${`border-[${color}] hover:border-2`} ${
        active ? "text-white" : null
      } shadow-lg w-full flex items-center justify-start gap-2 px-2 2xl:px-6 2xl:justify-center h-12 rounded-full`}
      style={active ? { backgroundColor: `${color}` } : undefined}
    >
      <img
        src={icon}
        alt=""
        className={`max-h-full max-w-[25%] ${
          active ? "invert-0 brightness-[100]" : ""
        }`}
        style={{ margin: "2px" }}
      />
      <span className="overflow-hidden text-ellipsis whitespace-nowrap">
        {title}
      </span>
    </Link>
  );
}

export default MainLink;
