import React from "react";
import FormSection from "../form-section";

import FirstName from "./FirstName";
import MiddleName from "./MiddleName";
import LastName from "./LastName";
import DateOfBirth from "./DateOfBirth";
import GenderAssignedAtBirth from "./GenderAssignedAtBirth";
import Gender from "./Gender";
import Nationality from "./Nationality";
import CountryOfResidence from "./CountryOfResidence";
import MainLanguage from "./MainLanguage";
import MultipleLanguage from "./MultipleLanguage";

function RequiredInformation() {
  return (
    <FormSection title="Required Information">
      <div className="grid grid-cols-4 gap-8">
        <FirstName />
        <MiddleName />
        <LastName />
        <DateOfBirth />
        <GenderAssignedAtBirth />
        <Gender />
        <Nationality />
        <CountryOfResidence />
        <MainLanguage />
        <MultipleLanguage />
      </div>
    </FormSection>
  );
}

export default RequiredInformation;
