import React, {
  FormEvent,
  RefObject,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { BlueButton } from "../../../../components/ui/Buttons";
import useGetAllClients from "../../../../api/clients/useGetAllClients";
import LocationSVG from "../../../../assets/icons/location-pin-svg.svg";
import PolygonIcon from "../../../../assets/icons/draw-on-map-lines.svg";
import PanToolIcon from "@mui/icons-material/PanTool";
import { FeatureType, OverlaysDataTypes } from "../../types";
import { useCustomOverlays } from "../../services";
import { Check, Close } from "@mui/icons-material";
import Modal from "../../../../components/portal/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import { generateMapSearchBox } from "../../../../util/map";
import { Select } from "../../../../components/form-v2";
import SuitcaseIcon from "../../../../assets/icons/suitcase.svg";
import featuresReducer from "../../reducers/featuresReducer";
import {
  drawCircle,
  drawMarker,
  drawPolygon,
  drawPolyline,
  drawText,
} from "./utils";
import { useQueryClient } from "react-query";
import useEditPolygon from "./hooks/useEditPolygon";
import useEditPolyline from "./hooks/useEditPolyline";
import TextInput from "../../../../components/form-v2/input";
import useClient from "../../../../hooks/api/useClient";
import FeaturesPanel from "../features-panel";
import DrawingTool from "../drawing-tool";
import EditFeature from "../edit-feature";

type AddCustomOverlayPropsType = {
  className?: string;
  formRef: RefObject<HTMLFormElement>;
  map: google.maps.Map | undefined;
  drawingManager: google.maps.drawing.DrawingManager | undefined;
  overlayData: OverlaysDataTypes;
};

function EditCustomOverlay({
  className,
  formRef,
  map,
  drawingManager,
  overlayData,
}: AddCustomOverlayPropsType) {
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const pathname = useLocation().pathname;
  const [disableDrawing, setDisableDrawing] = useState<boolean>(false);
  const [isNameError, setIsNameError] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { updateMutation: updateOverlay, overlayQuery } = useCustomOverlays();
  const [features, setFeatures] = useState<FeatureType[]>([]);
  const [featuresState, featuresDispatch] = useReducer(featuresReducer, []);
  const navigate = useNavigate();
  const [toggleFeatures, setToggleFeatures] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [drawingMode, setDrawingMode] = useState<
    "marker" | "polygon" | "polyline" | "circle" | "text" | null
  >(null);
  const inputNameRef = useRef<HTMLInputElement>(null);
  const inputConfirmBtnRef = useRef<HTMLButtonElement>(null);

  const { data: client, isLoading: clientLoading } = useClient(
    overlayData.clientID
  );
  const { all_clients_data } = useGetAllClients();

  const clientOptions: { label: string; value: string }[] =
    all_clients_data.map((client) => ({
      label: client.name,
      value: client.id,
    }));

  const handleSearchLocation = (map: google.maps.Map) => {
    const input = document.getElementById("pac-input") as HTMLInputElement;
    generateMapSearchBox(input, map);
  };
  const addFeature = (data: FeatureType) => {
    featuresDispatch({ type: "ADD_FEATURE", payload: data });
    setDisableDrawing(false);
  };

  useEffect(() => {
    if (!map) return;
    if (!overlayQuery.data) return;
    if (overlayQuery.data.data.features.length < 1) return;
    const overlayFeatures: FeatureType[] = overlayQuery.data.data.features.map(
      (f) => {
        const drawingType =
          f.geometry.type === "Point" && f.properties.radius
            ? "circle"
            : f.geometry.type === "Point" && !f.properties.label
            ? "marker"
            : f.geometry.type === "Point" && f.properties.label
            ? "text"
            : f.geometry.type === "Polygon"
            ? "polygon"
            : "polyline";

        const drawing =
          drawingType === "circle"
            ? drawCircle(f, map)
            : drawingType === "marker"
            ? drawMarker(f, map)
            : drawingType === "text"
            ? drawText(f, map)
            : drawingType === "polygon"
            ? drawPolygon(f, map)
            : drawingType === "polyline"
            ? drawPolyline(f, map)
            : null;

        const ft: FeatureType = {
          type: f.type,
          uuidv4: uuidv4(),
          geometry: f.geometry,
          properties: f.properties,
          drawing,
          drawingType,
        } as FeatureType;

        drawing?.setMap(map);
        return ft;
      }
    );
    setFeatures(overlayFeatures);
  }, [overlayQuery.data, map, pathname]);

  useEffect(() => {
    if (features.length < 1) return;
    featuresDispatch({ type: "SET_FEATURES", payload: features });
  }, [features, pathname]);

  useEditPolygon(featuresState, map, featuresDispatch);
  useEditPolyline(featuresState, map, featuresDispatch);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const formData = new FormData(formRef.current!);
    const overlayName = formData.get("name");
    const overlayDescription = formData.get("description");

    if (!overlayName) {
      console.error("Name is required");
      setIsNameError(true);
      return;
    }

    const features = featuresState.map((ft) => ({
      type: ft.type,
      properties: ft.properties,
      geometry: ft.geometry,
    }));

    if (features.length < 1) {
      console.error("No overlays drawn.");
      return;
    }
    updateOverlay.mutateAsync(
      {
        name: overlayName,
        description: overlayDescription,
        data: {
          type: "FeatureCollection",
          features,
        },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            "custom-overlays",
            overlayQuery.data?.id,
          ]);
          await queryClient.invalidateQueries("custom-overlay");
          navigate("/sites");
        },
      }
    );
  };

  useEffect(() => {
    if (featuresState.length < 1) {
      setToggleFeatures(false);
    }
  }, [featuresState]);

  const clickButtonOnEnter = (event: KeyboardEvent) => {
    // Check if the pressed key is Enter (key code 13)
    if (event.keyCode === 13) {
      // Trigger a click on the button with the id "yourButtonId"
      const confirmBtn = document.getElementById("input-drawing-confirm-btn");
      const confirmLabelTextBtn = document.getElementById(
        "input-label-confirm-btn"
      ) as HTMLButtonElement;
      if (confirmBtn) {
        confirmBtn.click();
      }
      if (confirmLabelTextBtn) {
        confirmLabelTextBtn.click();
      }
    }
  };

  const onEscPress = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      setDrawingMode(null);
      drawingManager?.setDrawingMode(null);
    }
  };

  useEffect(() => {
    document.addEventListener("keypress", clickButtonOnEnter);
    document.addEventListener("keydown", onEscPress);
    return () => {
      document.removeEventListener("keypress", clickButtonOnEnter);
      document.removeEventListener("keydown", onEscPress);
    };
  }, [drawingManager]);

  return clientLoading ? null : (
    <>
      <form
        className={`bg-white text-grey px-8 pb-8 rounded-lg ${className}`}
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <header className="text-center relative">
          <h4 className="my-4 font-semibold text-base">Edit Custom Overlay</h4>
          <button
            className="absolute right-0 top-0"
            onClick={() => {
              navigate("/sites/custom-overlay");
            }}
            type="button"
          >
            <Close />
          </button>
        </header>
        <hr />
        <div className="flex flex-col gap-4 py-4">
          <TextInput
            name="name"
            placeholder="name"
            defaultValue={overlayQuery.data?.name}
            required={true}
            error={isNameError}
          />
          <input />
          <textarea
            name="description"
            id="description"
            cols={30}
            rows={5}
            className="bg-desaturated-light-grey w-full p-4 rounded-lg outline-none shadow-md"
            placeholder="Add Description"
            defaultValue={overlayQuery.data?.description}
          ></textarea>
          <Select
            name="client"
            options={clientOptions}
            defaultSelected={{ label: client!.name, value: client!.id }}
            title="Client"
            icon={SuitcaseIcon}
          />
        </div>
        <hr />
        <div className="w-full flex items-center flex-col my-6">
          <h4 className="font-semibold text-center">Drawing Tools</h4>
          <div className="flex flex-col gap-8 items-center my-4">
            <div className="">
              <input
                type="text"
                id="pac-input"
                placeholder="Search Location"
                className="h-10 w-full max-w-[240px] mr-7 text-base px-4 shadow-md"
                onFocus={() => handleSearchLocation(map!)}
              />
            </div>
            <DrawingTool
              disableDrawing={disableDrawing}
              setDrawingMode={setDrawingMode}
              drawingMode={drawingMode}
              drawingManager={drawingManager}
              addFeature={addFeature}
              setDisableDrawing={setDisableDrawing}
              setToggleFeatures={setToggleFeatures}
              map={map}
              setDisableSave={setDisableSave}
            />
            <div className="rounded-md bg-white hidden overflow-hidden shadow-md relative">
              <input
                type="text"
                placeholder="Enter feature name"
                className=" px-4 py-2"
                ref={inputNameRef}
                id="input-drawing-name"
                onFocus={() => setDisableSave(true)}
                maxLength={150}
                onChange={(e) => {
                  const limitReachedI = document.getElementById(
                    "input-drawing-name-char-limit"
                  );
                  if (!limitReachedI) return;
                  if (e.target.value.length < 150) {
                    limitReachedI.style.display = "none";
                    return;
                  } else {
                    limitReachedI.style.display = "inline";
                  }
                }}
              />
              <button
                className="h-10 w-10 rounded-md bg-white"
                ref={inputConfirmBtnRef}
                id="input-drawing-confirm-btn"
                type="button"
              >
                <Check />
              </button>
            </div>
            <span
              id="input-drawing-name-char-limit"
              className="text-sm text-red"
              style={{ display: "none" }}
            >
              Maximum characters reached.
            </span>
          </div>
        </div>
        <hr />
        <div className="pt-12 pb-4">
          <BlueButton
            text="Save"
            type="button"
            onClick={handleSubmit}
            disabled={disableSave}
          />
        </div>
      </form>

      {/** FEATURES PANEL */}
      <FeaturesPanel
        setToggleFeatures={setToggleFeatures}
        toggleFeatures={toggleFeatures}
        features={featuresState}
        deleteFeature={(payload: FeatureType) =>
          featuresDispatch({ type: "DELETE_FEATURE", payload })
        }
        setEditModal={setEditModal}
        map={map}
      />
      <Modal open={editModal} setOpen={setEditModal}>
        <EditFeature
          setEditModal={setEditModal}
          onSubmit={(data: FeatureType) => {
            featuresDispatch({ type: "EDIT_FEATURE", payload: data });
          }}
          features={featuresState}
        />
      </Modal>
    </>
  );
}

export default EditCustomOverlay;
