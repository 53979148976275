import React, { SetStateAction, useRef, useState } from "react";
import FormModal from "../../../../../../../components/form/FormModal";
import {
  BlueButton,
  WhiteButton,
} from "../../../../../../../components/ui/Buttons";
import UploadIcon from "../../../../../../../assets/icons/upload-white.svg";
import DownloadIcon from "../../../../../../../assets/icons/download-blue.svg";
import Checkbox from "../../../../../../../components/form/Checkbox";
function AddMultipleFacilities({
  setIsMultipleFacilitiesModal,
  className,
}: {
  setIsMultipleFacilitiesModal: React.Dispatch<SetStateAction<boolean>>;
  className: string;
}) {
  const [defaultRadii, setDefaultRadii] = useState<boolean>(false);
  const defaultRadiiRef = useRef<HTMLInputElement>(null);
  const [customRadii, setCustomRadii] = useState<boolean>(false);
  const customRadiiRef = useRef<HTMLInputElement>(null);

  const handleSubmit = () => {};
  return (
    <FormModal
      title="Add Multiple Facility"
      onSubmit={handleSubmit}
      className={className}
    >
      <div className="absolute top-4 right-8">
        <button
          onClick={() => setIsMultipleFacilitiesModal(false)}
          type="button"
          className=" rounded-full w-6 text-white flex items-center justify-center h-6 bg-light-grey"
        >
          x
        </button>
      </div>

      <div className="flex flex-col gap-4 my-8">
        <div className="flex items-center gap-6">
          <button className="bg-blue h-8 w-8 rounded-md flex items-center justify-center">
            <img src={UploadIcon} alt="" />
          </button>
          <span>Upload a csv</span>
        </div>
        <div className="flex items-center gap-6">
          <button className="bg-white shadow-lg h-8 w-8 rounded-md flex items-center justify-center">
            <img src={DownloadIcon} alt="" />
          </button>
          <span>Download Template csv</span>
        </div>
      </div>
      <hr />
      <div className="my-8">
        <Checkbox
          inputRef={defaultRadiiRef}
          label={"Set default radii around the site"}
          name="default-radii"
          checked={defaultRadii}
          onClick={() => setDefaultRadii((prev) => !prev)}
          value={"default-radii"}
        />
        <Checkbox
          inputRef={customRadiiRef}
          label={"Set custom radii around the site"}
          name="custom-radii"
          checked={customRadii}
          onClick={() => setCustomRadii((prev) => !prev)}
          value={"custom-radii"}
        />
      </div>

      <div className="flex flex-col gap-4">
        <WhiteButton text="No Site-based Alert" onClick={() => {}} />
        <BlueButton text="Add" onClick={() => {}} />
      </div>
    </FormModal>
  );
}

export default AddMultipleFacilities;
