import React from "react";
import { Outlet } from "react-router-dom";

function Main() {
  return (
    <main className="w-full flex-1 pr-8">
      <Outlet />
    </main>
  );
}

export default Main;
