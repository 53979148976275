import React, { useContext } from "react";
import { AdviceGuidesContext } from "../../../features/risk/pages/advice-guides/context/advice-guides";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";

import { IncidentLogMain } from "../../../features/risk/pages/incident-log";

function IncidentLog() {
  const adviceGuidesContext = useContext(AdviceGuidesContext)!;
  const { all_guides_status } = adviceGuidesContext;

  return (
    <div className="flex h-full">
      {all_guides_status !== "success" ? (
        <div className="h-full w-full flex justify-center items-center">
          <LoadingSpinner />
        </div>
      ) : (
        <IncidentLogMain />
      )}
    </div>
  );
}

export default IncidentLog;
