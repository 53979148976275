import React, { useEffect } from "react";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";

function SearchByName({
  filterByNameToggle,
  setFilterByNameToggle,
  setSearchName,
}: {
  filterByNameToggle: boolean;
  setFilterByNameToggle: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchName: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [name, setName] = React.useState<string>("");
  const nameInputRef = React.useRef<HTMLInputElement>(null);
  useOnClickOutside(nameInputRef, () => setFilterByNameToggle(false));

  useEffect(() => {
    setSearchName(name);
  }, [name]);

  return (
    <input
      type="text"
      className={`absolute top-10 z-10 left-0 rounded-md h-10 w-full shadow-md px-4 ${
        filterByNameToggle ? "inline" : "hidden"
      }`}
      ref={nameInputRef}
      placeholder="Seach"
      onChange={(e) => setName(e.target.value)}
    />
  );
}

export default SearchByName;
