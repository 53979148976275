import React, { ReactElement, useEffect } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Map from "./Map";
function MapApp() {
  const googleMapsApiKey: string = process.env.REACT_APP_GOOGLE_MAP_API_KEY!;

  const Spinner = () => <h1>Insert spinning wheel for loading</h1>;
  const Error = () => <h1>Insert error</h1>;

  const render = (status: Status): ReactElement => {
    if (status === Status.FAILURE) return <Error />;
    return <Spinner />;
  };

  return (
    <Wrapper
      apiKey={googleMapsApiKey}
      render={render}
      libraries={["drawing", "places"]}
    >
      <Map zoom={1} center={{ lat: 0, lng: 0 }} />
    </Wrapper>
  );
}

export default MapApp;
