import React, {createContext, ReactNode, SetStateAction, useContext, useEffect, useState,} from "react";
import {UserContext} from "../../../../context/user";
import {Client} from "../../../../types/userManagement";

const data = {
  clientID: "",
  userTypes: ["app", "trip", "facility", "satellite"],
  siteTypes: [],
  trackingUserID: null,
  incidents: true,
  searchOverlayID: null,
  worldSearchTypes: []
};

type FitlerType = {
  clientID: string;
  userTypes: string[];
  siteTypes: string[];
  trackingUserID: string | null;
  incidents: boolean;
  searchOverlayID: string | null;
  worldSearchTypes: string[];
};

type FilterMapContextType = {
  filters: FitlerType;
  setFilters: React.Dispatch<SetStateAction<FitlerType>>;
  client: Client | null;
  setClient: React.Dispatch<SetStateAction<Client | null>>
};

export const FilterMapContext = createContext<FilterMapContextType>({
  filters: data,
  setFilters: () => {
  },
  client: null,
  setClient: () => {
  },
});

export default function FilterMapContextProvider({
                                                   children,
                                                 }: {
  children: ReactNode;
}) {

  const [client, setClient] = useState<Client | null>(null)

  const {role, metadata} = useContext(UserContext)!;

  const [filters, setFilters] = useState<FitlerType>({
    ...data,
    clientID: role.auroraAccessLevel === "all" ? "all" : metadata.clientID,
  });
  const storedItem = localStorage.getItem("operational-map-filter-config");
  useEffect(() => {
    if (storedItem) {
      const parsedItem: FitlerType = JSON.parse(storedItem);
      parsedItem.clientID = ""
      setFilters(parsedItem);
    }
  }, []);

  useEffect(() => {
    if (!filters.trackingUserID) {
      localStorage.setItem("operational-map-filter-config", JSON.stringify(filters));
    }
  }, [filters]);

  const value = {filters, setFilters, client, setClient};

  return (
      <FilterMapContext.Provider value={value}>
        {children}
      </FilterMapContext.Provider>
  );
}
