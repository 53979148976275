import React, { ReactNode, SetStateAction, createContext, useState } from "react";
import { IncidentDataTypes } from "../../types/incident-data-types";

type IncidentDetailsModalContextType = {
  incidentDetails: IncidentDataTypes | null;
  setIncidentDetails: React.Dispatch<SetStateAction<IncidentDataTypes | null>>;
  incidentsModal: boolean;
  setIncidentsModal: React.Dispatch<SetStateAction<boolean>>;
};

export const IncidentDetailsModalContext =
  createContext<IncidentDetailsModalContextType | null>(null);

export default function IncidentDetailsModalProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [incidentDetails, setIncidentDetails] =
    useState<IncidentDataTypes | null>(null);
  const [incidentsModal, setIncidentsModal] = useState<boolean>(false);

  const value = {
    incidentDetails: incidentDetails,
    setIncidentDetails: setIncidentDetails,
    incidentsModal: incidentsModal,
    setIncidentsModal: setIncidentsModal,
  };

  return (
    <IncidentDetailsModalContext.Provider value={value}>
      {children}
    </IncidentDetailsModalContext.Provider>
  );
}
