import api from "../axiosConfig";
import { useQuery } from "react-query";
export default function useGetFacility(id: string) {
  console.log(id);
  const fetchFacility = async () => {
    return await api
      .get(`facilities/${id}`)
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  return useQuery("facility", fetchFacility);
}
