import React from "react";
import useSavedCountries from "../services/useSavedCountries";
import { CountryProfileDataTypes } from "../../../../../types/country-profile-data-types";
import CountryProfileListItem from "./CountryProfileListItem";

function SavedCountries() {
  const { data } = useSavedCountries().savedCountriesQuery;
  return (
    <div className="px-8 w-full">
      <div className="bg-white w-full shadow-md pb-12 px-6 h-full flex flex-col gap-6 overflow-y-auto pt-5">
        <div className="border-b-1 h-12 w-[3/5] flex items-center justify-center ">
          <p className="text-md text-center  block pb-2 font-semibold">
            Saved Countries
          </p>
        </div>
        <div className="flex flex-col gap-6 max-h-44 overflow-y-auto">
          {data?.length < 1 ? (
            <div className="">Bookmark a country to add to the list.</div>
          ) : (
            data?.map((country: CountryProfileDataTypes) => {
              return <CountryProfileListItem data={country} />;
            })
          )}
        </div>
      </div>
    </div>
  );
}

export default SavedCountries;
