import React, { useContext } from "react";
import InputText from "../../../../../../components/form/InputText";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { FormDataContext } from "../../context/form";
function EmployeeID() {
  const { data } = useContext(UserProfileContext)!;
  const userData: UserDataTypes = data?.data;
  const formDataContext = useContext(FormDataContext)!;
  const { employeeIDRef } = formDataContext;
  return (
    <div className="">
      <label htmlFor="employee_id" className="opacity-80">
        Employee ID
      </label>
      <InputText
        name={"employee_id"}
        placeholder="Enter Employee ID"
        defaultValue={
          userData.companyDetails?.employeeID
            ? userData.companyDetails?.employeeID
            : ""
        }
        inputRef={employeeIDRef}
      />
    </div>
  );
}

export default EmployeeID;
