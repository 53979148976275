import React, { useCallback, useMemo } from "react";
import { useDebounce } from "use-debounce";
import { useOperationalMapContext } from "../../providers/OperationalMapProvider";
import { dbscanGeo, zoomLevelToEpsilon } from "./math";
import { useQuery } from "react-query";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { TripMarker } from "./tripmarker";

const MemoizedTripMarker = React.memo(TripMarker);
const MemoizedAdvancedMarker = React.memo(AdvancedMarker);

export const TripsClustered: React.FC = () => {
  const ctx = useOperationalMapContext();
  const [debouncedZoom] = useDebounce(ctx.zoom, 300); // Debounce delay

  const getItemsClustered = useCallback(() => {
    if (!debouncedZoom || !ctx.tripQuery.data) return null;

    return dbscanGeo(
      ctx.tripQuery.data,
      zoomLevelToEpsilon[debouncedZoom],
      1,
      (item) => ({
        lat: item.location.point.coordinates[1],
        lng: item.location.point.coordinates[0],
      })
    );
  }, [debouncedZoom, ctx.tripQuery.data]);

  const queryKey = ["trips-clustered", debouncedZoom, ctx.client, ctx.selectedTripTypes, ctx.tripQuery.data];
  const itemsQuery = useQuery(queryKey, getItemsClustered, {
    staleTime: 10000,
    cacheTime: 300000,
  });

  if (itemsQuery.isLoading) return null;
  if (itemsQuery.isError) return null;
  if (!itemsQuery.data || itemsQuery.data.clusters.length === 0) return null;

  return (
    <>
      {itemsQuery.data.clusters.map((c) => {
        if (c.items.length > 1 && ctx.zoom < 12) {
          return (
            <MemoizedAdvancedMarker
              key={`trip_${c.center.lat}_${c.center.lng}`}
              position={{ lat: c.center.lat, lng: c.center.lng }}
            >
              <div className="w-[24px] h-[24px] flex rounded-full justify-center items-center bg-blue text-white font-semibold shadow">
                {c.items.length}
              </div>
            </MemoizedAdvancedMarker>
          );
        }

        return <>
          {c.items.map((t) =>     <MemoizedTripMarker
              key={t.id}
              record={t}
              showInfoWindow={ctx.visibleInfoWindow === t.id}
            />)}
        </>
      })}
    </>
  );
};