import { useEffect, useRef, useState } from "react";
import ZonesDataTypes, {
  PointLocation,
  PolygonLocation,
} from "../../sites/types";
import api from "../../../api/axiosConfig";
import moment from "moment";

const useZonesMarker = (
  map: google.maps.Map | undefined,
  zones: ZonesDataTypes[],
  siteTypes: string[],
  clientID?: string
) => {
  const [isSafetyZones, setIsSafetyZones] = useState<boolean>(false);
  const polygonRef = useRef<google.maps.Polygon[]>([]);
  const circlesRef = useRef<google.maps.Circle[]>([]);
  const filteredZones = zones?.filter((zone) =>
    !clientID || clientID === "all" ? zone : zone?.clientID === clientID
  );

  useEffect(() => {
    setIsSafetyZones(siteTypes.includes("safety-zone"));
  }, [siteTypes]);
  const fetchClient = async (id: string) => {
    return await api
      .get(`/clients/${id}`)
      .then((res) => res.data)
      .catch((err) => console.error(err));
  };
  /**
   * Render zones
   */

  useEffect(() => {
    if (!map || !siteTypes.includes("safety-zone")) return;
    const infowindow = new google.maps.InfoWindow();
    const polygons: google.maps.Polygon[] = [];
    const circles: google.maps.Circle[] = [];
    filteredZones.forEach((zone) => {
      const safetyZoneStringDiv = async () => {
        const client = await fetchClient(zone.clientID);
        const convertedStartTime = moment(zone.startsAt).format(
          "YYYY-MM-DD HH:mm:ss Z"
        );
        const convertedExpiresTime = moment(zone.expiresAt).format(
          "YYYY-MM-DD HH:mm:ss Z"
        );
        return `<div style="font-size: 14px;  padding: 16px">
          <div>
            <p>
              <span style="font-weight: 500;">Zone Name:</span> 
              ${zone.name}
            </p>
            <p>
              <span style="font-weight: 500;">Client:</span> 
              ${client.name}
            </p>
            ${
              zone.alwaysActive
                ? "<span style=\"font-weight: 500;\">Permanent Zone</span>"
                : `          <p>
              <span style="font-weight: 500;">Zone Starts At:</span> 
              ${convertedStartTime}
            </p>
            <p>
              <span style="font-weight: 500;">Zone Expires At:</span> 
              ${convertedExpiresTime}
            </p>`
            }
        
          </div>
        </div>`;
      };

      if (zone.location.type === "Point") {
        const location = zone.location as PointLocation;
        const coordinates = location.coordinates;
        const lng = coordinates[0] as number;
        const lat = coordinates[1] as number;
        const position = { lng, lat };
        const circle = new google.maps.Circle({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          map,
          center: position,
          radius: location.properties ? location.properties?.radius : null,
        });
        circle.setMap(map);

        circle.addListener("click", async (e: google.maps.MapMouseEvent) => {
          infowindow.setContent(await safetyZoneStringDiv());
          infowindow.setPosition({
            lng: e.latLng!.lng(),
            lat: e.latLng!.lat(),
          });
          infowindow.open(map);
        });
        return circles.push(circle);
      } else {
        const location = zone.location as PolygonLocation;
        const coordinates = location.coordinates.flatMap((coords) => coords);
        const path = coordinates.map((coords) => ({
          lng: coords[0],
          lat: coords[1],
        }));
        const polygon = new google.maps.Polygon({
          strokeWeight: 2,
          paths: path,
          fillOpacity: 0.35,
          fillColor: "#FF0000",
          strokeColor: "#FF0000",
        });

        polygon.setMap(map);

        polygon.addListener("click", async (e: google.maps.MapMouseEvent) => {
          infowindow.setContent(await safetyZoneStringDiv());
          infowindow.setPosition({
            lat: e.latLng!.lat(),
            lng: e.latLng!.lng(),
          });
          infowindow.open(map);
        });

        return polygons.push(polygon);
      }
    });

    circlesRef.current.forEach((c) => c.setMap(null));
    polygonRef.current.forEach((p) => p.setMap(null));
    circlesRef.current = circles;
    polygonRef.current = polygons;
    circlesRef.current.forEach((c) => c.setMap(map));
    polygonRef.current.forEach((p) => p.setMap(map));
  }, [filteredZones, map, siteTypes]);

  /**
   * Show hide markers
   */

  useEffect(() => {
    if (!map) return;
    if (isSafetyZones) {
      polygonRef.current.forEach((p) => p.setMap(map));
      circlesRef.current.forEach((c) => c.setMap(map));
      return;
    }
    polygonRef.current.forEach((p) => p.setMap(null));
    circlesRef.current.forEach((c) => c.setMap(null));
  }, [isSafetyZones, map]);

  useEffect(() => {
    if (!map) return;
    if (siteTypes.includes("safety-zone")) {
      polygonRef.current.forEach((p) => p.setMap(map));
      circlesRef.current.forEach((c) => c.setMap(map));
    } else {
      polygonRef.current.forEach((p) => p.setMap(null));
      circlesRef.current.forEach((c) => c.setMap(null));
    }
  }, [siteTypes, map]);
};

export default useZonesMarker;
