import React, { SetStateAction } from "react";

import WhiteBinIcon from "../../../../assets/icons/icon-bin-white.svg";
import { Clear } from "@mui/icons-material";
import { RedCircle } from "../../../../components/ui/Circles";
import { UserActivityDataTypes } from "../../../../types/user-activity-data-types";
function ResolveConfirmation({
  deleteModal,
  setDeleteModal,
  handleDelete,
  userActivity,
}: {
  deleteModal: boolean;
  setDeleteModal: React.Dispatch<SetStateAction<boolean>>;
  handleDelete: () => void;
  userActivity: UserActivityDataTypes;
}) {
  return (
    <div className=" bg-light-red p-4 relative w-fit h-fit rounded-lg max-w-md text-center">
      <div className=" flex flex-col gap-4 justify-center items-center w-full h-full bg-white p-4 pt-10 pb-20 rounded-lg">
        <button
          className="h-8 w-8 rounded-full absolute right-8 top-8"
          onClick={() => setDeleteModal(false)}
          type="button"
        >
          <Clear />
        </button>
        <h3 className="font-bold text-lg">Are you sure?</h3>
        <p>
          You are about to resolve activity for{" "}
          <span className="font-semibold">
            {userActivity.metadata.userName}
          </span>
          . Please press the button below to confirm.
        </p>

        <button
          className="absolute w-fit h-fit bottom-8 left-2/4 translate-x-2/4 pl-4"
          onClick={handleDelete}
          type="submit"
        >
          <RedCircle icon={WhiteBinIcon} />
        </button>
      </div>
    </div>
  );
}

export default ResolveConfirmation;
