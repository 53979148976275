import {useNavigate} from "react-router-dom";
import React, {useContext, useState} from "react";

import {dateToString} from "../../../../../util/helpers";
import {TripWithDetails} from "../../../../../pages/TripsV2/types";
import {UserContext} from "../../../../../context/user";
import {Action, ActionSelect} from "../../../../../components/ui/ActionSelect";
import Pagination from "../../../../../components/ui/Content/Pagination";
import {ItineraryItem} from "../../../../../pages/WorldSearch";
import {useTimeZoneSelectContext} from "../../../../../providers/TimeZoneSelectProvider";
import moment from "moment";

export default function TripEntry({
                                    data, openEditTripModal, opedDeleteTripModal,
                                  }: {
  data: TripWithDetails;
  openEditTripModal: (isNew: boolean, id: string) => void;
  opedDeleteTripModal: (id: string) => void;
}) {

  const [showAllCountries, setShowAllCountries] = useState(false)
  const [showTrip, setShowTrip] = useState(false)

  const [tripPage, setTripPage] = useState(1)

  const {timeZone} = useTimeZoneSelectContext()


  const userContext = useContext(UserContext);

  let cols = 7
  if (userContext?.role.auroraAccessLevel == "client") {
    cols = cols - 1
  }

  if (userContext?.role.auroraAccessLevel == "department") {
    cols = cols - 2
  }

  if (userContext?.role.auroraAccessLevel == "user") {
    cols = cols - 3
  }

  const countries = new Set<string>()

  data.itinerary.forEach((it) => {
    it.locations.forEach(l => {
      countries.add(l.countryName)
    })
  })

  const navigate = useNavigate()

  const countryLimit = 2


  return (<>
    <div className={`py-3 border-b-1 w-full grid grid-cols-${cols} items-center h-full`}>
      {["all"].includes(userContext?.role.auroraAccessLevel || "") && <div className="text-center  px-6">
        {data.clientName || "-"}
      </div>}
      {["all", "client"].includes(userContext?.role.auroraAccessLevel || "") && <div className="text-center px-6">
        {data.departmentName || "-"}
      </div>}
      {["all", "client", "department"].includes(userContext?.role.auroraAccessLevel || "") &&
          <div className="text-center  px-6 truncate overflow-ellipsis">
            {data.userName || "-"}
          </div>}
      <div className="text-center">
        {moment.tz(data.startTime, timeZone).format("DD/MM/YYYY")}
      </div>
      <div className="text-center">
        {moment.tz(data.endTime, timeZone).format("DD/MM/YYYY")}
      </div>
      <div className="text-center">
        {[...Array.from(countries)].map((c, i) => {
          if (!showAllCountries && i >= countryLimit) {
            return null
          }

          return <div key={i}>{c}</div>

        })}

        {countries.size > countryLimit && <button onClick={() => {
          setShowAllCountries(!showAllCountries)
        }} className={"text-sm text-blue"}>
          {showAllCountries ? "Hide" : `Show ${countries.size - countryLimit} more`}
        </button>}


      </div>

      <div className={"px-6"}>
        <ActionSelect label={"Select Action"}>
          <Action label={showTrip ? "Hide Trip" : "Show Trip"} onSelect={() => setShowTrip(!showTrip)}/>
          <Action label={"Edit Trip"} onSelect={() => openEditTripModal(false, data.id)}/>
          <Action label={"Delete Trip"} onSelect={() => opedDeleteTripModal(data.id)}/>
          <Action label={"Edit Check Ins"} onSelect={() => window.open(`/trips/${data.id}`, "_blank")}/>
        </ActionSelect>
      </div>

    </div>
    {showTrip && <div className={"relative"}>
      {data.itinerary[tripPage - 1] && <ItineraryItem item={data.itinerary[tripPage - 1]}/>}

        <Pagination currentPage={tripPage} itemsPerPage={1} dataLength={data.itinerary.length}
                    togglePageSelect={setTripPage}/>
    </div>}
  </>);
}
