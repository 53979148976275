import React, { ReactNode, SetStateAction, createContext, useState } from "react";
import { ClientDataTypes } from "../../../../types/client-data-types";
import usePaginatedGetAllClients from "../../../../api/clients/usePaginatedGetAllClients";
import { AxiosResponse } from "axios";
import { useSearchParams } from "react-router-dom";
type ClientsQueryType = {
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  data: AxiosResponse<any, any> | undefined;
  isFetching: boolean;
  isPreviousData: boolean;
  status: "idle" | "error" | "loading" | "success";
};

type ClientsContextType = {
  allClients: ClientsQueryType;
  page: number;
  setPage: React.Dispatch<SetStateAction<number>>;
  searchClientByName: string;
  setSearchClientByName: React.Dispatch<SetStateAction<string>>;
};

export const PaginatedClientsContext = createContext<ClientsContextType | null>(
  null
);

export default function PaginatedClientsContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [searchClientByName, setSearchClientByName] = useState<string>("");

  const [searchParams] = useSearchParams()


  const [page, setPage] = useState<number>(parseInt(searchParams.get("page") || "1"));
  const allClients: ClientsQueryType = usePaginatedGetAllClients(
    page,
    searchClientByName
  );

  const value = {
    allClients: allClients,
    page: page,
    setPage: setPage,
    searchClientByName: searchClientByName,
    setSearchClientByName: setSearchClientByName,
  };

  return (
    <PaginatedClientsContext.Provider value={value}>
      {children}
    </PaginatedClientsContext.Provider>
  );
}
