import { Status, Wrapper } from "@googlemaps/react-wrapper";
import React, { ReactElement } from "react";
import LoadingSpinner from "../../../../../../components/ui/LoadingSpinner";
import Map from "./Map";

function CustomOverlayMap() {
  const googleMapsApiKey: string = process.env.REACT_APP_GOOGLE_MAP_API_KEY!;

  const LoadingScreen = () => {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  };

  const Error = () => <h1>Failed to load map.</h1>;

  const render = (status: Status): ReactElement => {
    if (status === Status.FAILURE) return <Error />;
    return <LoadingScreen />;
  };
  return (
    <Wrapper
      apiKey={googleMapsApiKey}
      render={render}
      libraries={["drawing", "places"]}
    >
      <Map />
    </Wrapper>
  );
}

export default CustomOverlayMap;
