import { useQuery } from "react-query";
import api from "../axiosConfig";
export default function usePaginatedGetAllClients(
  page: number,
  searchByName: string
) {
  const fetchClients = async (page: number, searchByName: string) => {
    if (searchByName === "") {
      return await api
        .get(`/clients?page=${page}`)
        .then((res) => res)
        .catch((err) => console.error(err));
    } else {
      console.log(searchByName);
      return await api
        .get(`/clients?page=${page}`, { params: { search: searchByName } })
        .then((res) => res)
        .catch((err) => console.error(err));
    }
  };

  const fetchQuery = useQuery({
    queryKey: ["clients", page, searchByName],
    queryFn: () => fetchClients(page, searchByName),
    keepPreviousData: true,
  });

  return {
    isLoading: fetchQuery.isLoading,
    isError: fetchQuery.isError,
    error: fetchQuery.error,
    data: fetchQuery.data || undefined,
    isFetching: fetchQuery.isFetching,
    isPreviousData: fetchQuery.isPreviousData,
    status: fetchQuery.status,
  };
}
