import React from "react";
import { Link, useLocation } from "react-router-dom";

type MainLinkTypes = {
  path: string;
  slug: string;
  title: string;
};

function MainLink({ path, slug, title }: MainLinkTypes) {
  // location and pathSlug will be used to add active state styles on nav link
  const location = useLocation();
  const pathSlug = location.pathname.split("/")[2];

  return (
    <Link
      to={path}
      className={`w-full h-10 flex justify-center items-center rounded-md ${
        pathSlug === slug
          ? "font-semibold shadow-md bg-blue text-white"
          : "bg-white shadow-md"
      }`}
    >
      {title}
    </Link>
  );
}

export default MainLink;
