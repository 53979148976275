import React from "react";
import LoadingSpinner from "../../components/ui/LoadingSpinner";

function Error() {
  return (
    <div className="relative w-screen h-screen bg-gradient-to-bl from-[#2D166F_50%] to-[#007584] flex flex-col justify-center items-center">
      <span className="text-2xl font-semibold text-white mb-8">
        Authentication Error
      </span>
    </div>
  );
}

export default Error;
