export const emailTemplate = `
You are receiving this email because you have been invited to join NGS Aurora.
<br/>
Please use the blue 'Activate Account' button below to setup your password.
<br/>
You can download the Aurora App using the buttons below.
<br/>
Once downloaded, please treat the NGS Aurora App as LIVE.
<br/>
If you want to test any of the emergency functions, please notify NGS Ops beforehand otherwise they will treat any activations as a LIVE emergency.
<br/>
Please see link below to our tutorial video for the NGS Aurora App.
<br/>
<a href="https://youtu.be/AcdNB6ra1iM">https://youtu.be/AcdNB6ra1iM</a>
<br/>
Let us know if you have any questions. 
`
