import React, { useContext, useState } from "react";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { FormDataContext } from "../../context/form";

import MultipleLanguageSelect from "../../../../../../components/form/MultipleLanguageSelect";

function MultipleLanguage() {
  const [multiSelectLang, setMultiSelectLang] = useState<boolean>(false);
  const { data } = useContext(UserProfileContext)!;
  const formDataContext = useContext(FormDataContext)!;
  const { multiLanguageRef } = formDataContext;
  const userData: UserDataTypes = data?.data;
  const additionalLanguages = userData.languages?.filter(
    (lang) => !lang.primary
  );

  const additionalLanguagesCodes = additionalLanguages?.map((lang) =>
    lang.code.toLowerCase()
  );

  return (
    <div className="relative h-fit  w-full">
      <MultipleLanguageSelect
        selectRef={multiLanguageRef}
        selectedOptions={additionalLanguagesCodes}
        label
      />
    </div>
  );
}

export default MultipleLanguage;
