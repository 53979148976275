import React from "react";

function RedCircle({ title, icon }: { title?: string; icon?: string }) {
  return (
    <div className="w-28 h-28 rounded-full bg-[#fbc5c5] p-2 absolute -top-8 -right-8 z-10">
      <div className="w-full h-full rounded-full bg-red bg-opacity-20 p-2">
        <div className="w-full h-full rounded-full bg-red flex items-center justify-center">
          {title && (
            <span className="font-semibold text-white text-xs whitespace-pre-wrap text-center">
              {title}
            </span>
          )}
          {icon && <img src={icon} alt="" className="w-6" />}
        </div>
      </div>
    </div>
  );
}

export default RedCircle;
