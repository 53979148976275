import React, { useContext } from "react";
import InputText from "../../../../../../components/form/InputText";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { FormDataContext } from "../../context/form";
function MedicalConditions() {
  const { data } = useContext(UserProfileContext)!;
  const userData: UserDataTypes = data?.data;
  const formDataContext = useContext(FormDataContext)!;
  const { medicalConditionsRef } = formDataContext;
  return (
    <div className="">
      <label htmlFor="passport_expiry" className="opacity-80">
        Medical Conditions
      </label>
      <InputText
        name={"medical_conditions"}
        placeholder="Enter Medical Conditions"
        onChange={() => {}}
        defaultValue={
          userData.medicalInformation?.conditions
            ? userData.medicalInformation?.conditions
            : ""
        }
        inputRef={medicalConditionsRef}
      />
    </div>
  );
}

export default MedicalConditions;
