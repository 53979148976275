import React from "react";
import SettingsBox from "../components/SettingsBox";
import InputText from "../../../../../components/form/InputText";
import IncludeInputsBox from "../components/IncludeInputsBox";
import {
  BlueButton,
  ButtonBorderTransparent,
} from "../../../../../components/ui/Buttons";

export default function CreateNewReport() {
  return (
    <div className="pt-5 relative h-full">
      <div className="mb-4">Settings</div>
      <div className="pb-8 border-b border-light-grey w-full">
        <SettingsBox />
      </div>
      <div className="mt-8">
        <div className="mb-4">Report Detail</div>
        <div className="max-w-[350px]">
          <InputText
            placeholder="Report Name"
            name="report-name"
            onChange={() => {}}
          />
        </div>
        <div className="mt-7">
          <div className="text-sm font-bold">Include</div>
          <IncludeInputsBox />
        </div>
      </div>
      <div className="absolute bottom-10 right-2 w-full flex justify-end flex-col items-end">
        <div className="w-1/3 max-w-[350px]">
          <BlueButton text="Save" onClick={() => {}} />
        </div>
        <div className="mt-2 w-1/3 max-w-[350px]">
          <ButtonBorderTransparent text="Generate Report" onClick={() => {}} />
        </div>
      </div>
    </div>
  );
}
