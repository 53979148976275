import React from "react";
import { useParams } from "react-router-dom";
import {
  Main,
  PhotoUploadSidebar,
} from "../../../features/user-management/pages/add-client";
function Client() {
  return (
    <div className="mt-40 grid grid-cols-[0.25fr_1fr] pr-20">
      <PhotoUploadSidebar />
      <Main />
    </div>
  );
}

export default Client;
