import React, {
  ChangeEvent,
  MouseEvent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import ArrowDownIcon from "../../assets/icons/arrow-down.svg";
import useOnClickOutside from "../../hooks/useOnClickOutside";
type Options = {
  name?: string;
  code?: string;
  id?: string;
};

type SelectPropTypes = {
  options: string[] | Options[];
  title: string;
  icon?: string;
  name?: string;
  required?: boolean;
  onSelect: (target: RefObject<HTMLInputElement>) => void;
  biggerInput?: boolean;
  multiple?: boolean;
  value?: string;
  showSearch?: boolean;
  disabled?: boolean;
};

function Select({
  options,
  title,
  icon,
  name,
  onSelect,
  required,
  biggerInput,
  multiple,
  value,
  showSearch = true,
  disabled = false,
}: SelectPropTypes) {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string[]>([]);
  const [filterValue, setFilterValue] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");

  const borderColor = selectedOption.length > 0 ? "border-grey" : "border-none"; // conditionally setting the border color
  const headerClass = `flex w-full gap-4 items-center cursor-pointer justify-start border ${borderColor} bg-white ${
    disabled ? "bg-light-grey bg-opacity-30 cursor-not-allowed" : "bg-white" // Add this line
  }  ${
    biggerInput
      ? "px-8 py-7 h-fit rounded-3xl shadow-lg"
      : "rounded-md h-10 px-4 shadow-md"
  }`;
  const listClass = `
      h-fit 
      max-h-52 
      z-20 
      overflow-y-scroll 
      scrollbar-hide
      absolute 
      top-[110%] 
      border-grey
      w-full 
      bg-white 
      shadow-md 
      rounded-lg 
      py-4
      flex-col 
      gap-2 `;

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (value) {
      if (!value.includes(",")) {
        options.forEach((option) => {
          if (typeof option === "object") {
            if (option.name?.toLowerCase().includes(value!.toLowerCase())) {
              setSelectedOption([value]);
            }
          } else {
            if (option.toLowerCase().includes(value?.toLowerCase())) {
              setSelectedOption([value]);
            }
          }
        });
      } else {
        setSelectedOption(value.split(","));
      }
    } else {
      setSelectedOption([])
    }
  }, [value]);

  const handleListClick = (target: HTMLElement) => {
    const selectedValue = target.innerText;
    let selectedOptionObject: any;

    setIsActive(false);
    setSelectedOption([selectedValue]);

    // Find the option object that was selected
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const optionName = typeof option === "object" ? option.name : option;
      if (optionName === selectedValue) {
        selectedOptionObject = option;
        break;
      }
    }

    setTimeout(() => {
      // Use the ref to attach the selected object to the input element
      if (inputRef.current) {
        if (typeof selectedOptionObject === "string") {
          inputRef.current.value = selectedOptionObject;
        } else {
          inputRef.current.value = selectedOptionObject.name; // set input value to the selected option's code
        }
      }
      // Send the ref with attached selected object to the parent component
      onSelect(inputRef);
    }, 0);
  };

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
  };

  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isActive && searchInputRef.current) {
      searchInputRef.current!.focus();
    }
  }, [isActive]);

  useOnClickOutside(ref, () => setIsActive(false));
  return (
    <div className="relative w-full flex  items-center gap-2">
      {required ? (
        <span className="text-red self-start absolute top-2/4 -translate-y-2/4 -left-3">
          *
        </span>
      ) : null}
      <div
        className={headerClass}
        onClick={() => {
          if (!disabled) {
            setIsActive(true);
          }
        }}
      >
        {icon && <img src={icon} alt="" className="" />}
        <input
          ref={inputRef}
          value={
            selectedOption.length === 0 ? value : selectedOption.toString()
          }
          disabled
          name={name}
          placeholder={title}
          className={`flex-1 w-full pointer-events-none ${
            disabled ? "bg-transparent cursor-not-allowed" : "bg-white" // Add this line
          } ${!selectedOption ? "text-light-grey" : "text-grey"}`}
          required={required}
        />
        <img src={ArrowDownIcon} alt="" className="" />
      </div>
      {isActive && !disabled && (
        <div className={listClass} ref={ref}>
          {showSearch && (
            <div className="px-6">
              <input
                type="text"
                value={filterValue}
                onChange={onFilterChange}
                placeholder="Search"
                className="max-w-sm h-8 px-4 rounded-md shadow-md mb-3 w-full"
                ref={searchInputRef}
              />
            </div>
          )}
          <ul className="list-type-none">
            {options &&
              options.map((option) => {
                const listElement = (
                  <li
                    key={typeof option === "string" ? option : option.name}
                    className={`px-6 text-sm text-left mt-1 first:mt-0 hover:bg-blue hover:text-white cursor-pointer ${
                      typeof option === "string" &&
                      selectedOption.includes(option)
                        ? "bg-blue text-white"
                        : ""
                    } ${
                      typeof option === "object" &&
                      selectedOption.includes(option.name!)
                        ? "bg-blue text-white"
                        : ""
                    }`}
                    onClick={(event: MouseEvent<HTMLElement>) =>
                      handleListClick(event.target as HTMLElement)
                    }
                  >
                    {typeof option === "string" ? option : option.name}
                  </li>
                );

                if (typeof option === "string") {
                  if (
                    option.toLowerCase().includes(filterValue.toLowerCase())
                  ) {
                    return listElement;
                  }
                } else {
                  if (
                    option
                      .name!.toLowerCase()
                      .includes(filterValue.toLowerCase())
                  ) {
                    return listElement;
                  }
                }
              })}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Select;
