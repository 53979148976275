import React, { useEffect, useState } from "react"
import SearchableSelect, { SearchableSelectOption } from "."
import api from "../../../api/axiosConfig"
import { PaginatedResponse } from "../../../types/PaginatedResponse"
import { Client } from "../../../types/userManagement"
import SuitcaseIcon from "../../../assets/icons/suitcase.svg";

type ClientSelectParams = {
  clients: Client[]
  multiple?: boolean;
  onSelectionChange?: (clients: Client[]) => void;
  disabled?: boolean;
}

function ClientSelect({
  multiple,
  onSelectionChange,
  clients,
  disabled
}: ClientSelectParams){

  const [loading, setLoading] = useState(false)
  const [options, setOptions]  = useState<SearchableSelectOption<Client>[]>([])
  const [searchStr, setSearchStr] = useState("")

  const fetchClients = async () => {
    setLoading(true)
    try{
      const res = await api.get<PaginatedResponse<Client>>("/clients", { params: { search: searchStr, rpp: 20 } })
      setOptions(res.data.items.map((c) => ({
        label: c.name,
        value: c,
        key: c.id!
      })))

    } catch(e){
      console.error(e)
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchClients()
  }, [searchStr])


  const onChange = (selected: SearchableSelectOption<Client>[]) => {
    if(onSelectionChange){
      onSelectionChange(selected.map(s => s.value))
    }
  }

  const onSearch = (value: string) => {
    setSearchStr(value);
  }

  return (<SearchableSelect
    icon={SuitcaseIcon}
    label={multiple ? "Clients" : "Client"}
    selected={clients.map(c => ({
      label: c.name,
      value: c,
      key: c.id!
    }))}
    search={true}
    multiple={multiple ? true : false}
    options={options}
    localSearch={false}
    onChange={onChange}
    onSearch={onSearch}
    loading={loading}
    disabled={disabled}
  />)
}

export default ClientSelect

type ClientSelectSingleProps = {
  client?: Client,
  onChange?: (selected: Client|undefined) => void,
  disabled?: boolean,
}

export const ClientSelectSingle: React.FC<ClientSelectSingleProps> = (props) =>
{

  const onChange = (clients: Client[]) => {
    if (!props.onChange) return;

    if(clients.length == 0){
      props.onChange(undefined)
      return
    }

    props.onChange(clients[0])
  }

  return <ClientSelect disabled={props.disabled} multiple={false} onSelectionChange={onChange} clients={props.client ? [props.client] : []}/>
}