import React, { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { kebabCaseToGeneralCase } from "../../util/helpers";

import SidebarLinks from "../../components/ui/SidebarLinks";
import { ContentWrapperWithHeader } from "../../components/ui/Content";

import navLinks from "./nav-links.json";
import customReportsLinks from "./custom-reports-links.json";
import Breadcrumbs from "../../components/ui/Content/Breadcrumbs";
import SummaryPage from "./pages/Summary/components/SummaryPage";
import ReportsSummaryProvider from "./pages/Summary/context/ReportsSummaryProvider";

function Reports() {
  return (
    <ReportsSummaryProvider>
      <div className="pt-36 pb-16 flex h-screen">
        {/* <div className="max-w-md w-full">*/}
        {/*  <SidebarLinks*/}
        {/*    title="Reports"*/}
        {/*    navLinks={navLinks}*/}
        {/*    innerLinks={customReportsLinks}*/}
        {/*    innerLinksTitle="Custom Reports"*/}
        {/*  />*/}
        {/* </div>*/}
        <main className="w-full h-full px-8">
          {/* <ContentWrapperWithHeader*/}
          {/*  title={<Breadcrumbs />}*/}
          {/*  content={<Outlet />}*/}
          {/* />*/}
          <SummaryPage />
        </main>
      </div>
    </ReportsSummaryProvider>
  );
}

export default Reports;
