import { BlueButton } from "../../../../../components/ui/Buttons";
import DownloadIcon from "../../../../../assets/icons/download.svg";
import AnnualSummaryTable from "../../../components/AnnualSummaryTable";
import HistoryOfNotifications from "../components/HistoryOfNotifications";
import MassWarningSingleEntry from "../components/MassWarningSingleEntry";
import Envelop from "../../../../../assets/icons/envelop.svg";
import CircleCheck from "../../../../../assets/icons/circle-check.svg";
import Phone from "../../../../../assets/icons/phone.svg";
import MessageChat from "../../../../../assets/icons/message-chat.svg";
import React, { useEffect, useState } from "react";
import Pagination from "../../../../../components/ui/Content/Pagination";

export default function MassWarningBase() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(12);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const numberOfItems = 30;

  const entryItems: any = [];

  [...Array(numberOfItems)].forEach((_, i) => {
    entryItems.push(<MassWarningSingleEntry key={i} />);
  });

  return (
    <div className="py-10 relative h-full">
      <div className="overflow-hidden h-[calc(100vh_-_350px)]">
        <div className="overflow-y-scroll h-full scrollbar-hide">
          {currentPage === 1 && (
            <>
              <div className="flex w-full justify-between items-center">
                <div>
                  <span className="font-bold mr-1">Summary:</span>Year-to-date
                </div>
                <div className="w-[150px]">
                  <BlueButton
                    text="Download"
                    icon={DownloadIcon}
                    onClick={() => {}}
                  />
                </div>
              </div>
              <div className="mt-10 pb-10 border-b">
                <AnnualSummaryTable showDownloadButton={false} />
              </div>
            </>
          )}
          <div className="mt-10">
            <div className="mb-6">History of Notifications</div>
            <HistoryOfNotifications />
          </div>
          <div className="mt-6">
            <div className="font-bold">Results</div>
            <div className="flex mt-5 pl-5 pb-10 relative">
              <div className="w-full">
                <div className="bg-desaturated-grey rounded-2xl grid grid-cols-[48px_1fr_0.8fr_0.8fr_0.8fr_0.6fr] w-full py-1 justify-items-center items-center text-center">
                  <div></div>
                  <div>Name</div>
                  <div>Date/Time</div>
                  <div>ID</div>
                  <div>Total Number of Alerts</div>
                  <div>Methods</div>
                </div>
                <div className="overflow-hidden h-[calc(70vh_-_200px)]">
                  <div className="overflow-y-scroll h-full scrollbar-hide">
                    {entryItems.slice(indexOfFirstItem, indexOfLastItem)}
                  </div>
                </div>
              </div>
              <div className="ml-2 bg-desaturated-grey rounded-2xl flex flex-col py-5 w-[155px] h-fit px-3 opacity-0 hover:opacity-100 transition-opacity">
                <span className="font-bold text-center">Key</span>
                <div className="flex items-center">
                  <img src={CircleCheck} alt="" className="w-[16px] h-[16px]" />
                  <div className="ml-3">App Checkin</div>
                </div>
                <div className="flex items-center">
                  <img src={Phone} alt="" className="w-[16px] h-[16px]" />
                  <div className="ml-3">Call</div>
                </div>
                <div className="flex items-center">
                  <img src={Envelop} alt="" className="w-[16px] h-[16px]" />
                  <div className="ml-3">Email</div>
                </div>
                <div className="flex items-center">
                  <img src={MessageChat} alt="" className="w-[16px] h-[16px]" />
                  <div className="ml-3">SMS</div>
                </div>
              </div>
              <div className="absolute -bottom-4 right-10">
                <div className="flex text-light-grey">
                  {entryItems!.length > 0 && (
                    <Pagination
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                      dataLength={entryItems ? entryItems.length : 0}
                      togglePageSelect={(page) => setCurrentPage(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
