import React, { ReactNode, createContext } from "react";
import useGetAllAdviceGuides from "../../../../../../api/advice-guides/useGetAllAdviceGuides";
import { AdviceGuidesDataTypes } from "../../../../../../types/advice-guides-type";

type AdviceGuidesContextType = {
  all_guides_data: AdviceGuidesDataTypes[] | any;
  all_guides_status: "idle" | "loading" | "success" | "error";
  all_guides_error: unknown;
};
export const AdviceGuidesContext =
  createContext<AdviceGuidesContextType | null>(null);

export default function AdviceGuidesContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const adviceGuides = useGetAllAdviceGuides();
  return (
    <AdviceGuidesContext.Provider value={adviceGuides}>
      {children}
    </AdviceGuidesContext.Provider>
  );
}
