import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/user";

export default function useClientBasedAppRestriction() {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const hasAirlineRiskMatrix = userContext?.modules?.includes("airlineRiskMatrix");

  useEffect(() => {
    if (!hasAirlineRiskMatrix) {
      navigate("/");
    }
  }, [hasAirlineRiskMatrix, navigate]);
}
