import {useQuery} from "react-query";
import {CheckInType} from "../../features/trips/types/check-ins-data-types";
import {apiGET} from "../../apiv2/request";

type CheckInsData = {
  data: CheckInType[];
  status: "idle" | "loading" | "success" | "error";
  error: unknown;
  refetch: () => void;
};

export default function useGetCheckInsSheet(): CheckInsData {
  const fetchCheckInSheet = async (): Promise<CheckInType | any> => {
    return await apiGET(`${process.env.REACT_APP_API_V2_URL}/ops/check-in-sheet`).then((res) => res)
        .catch((err) => console.error(err));
  };

  const {data, status, error, refetch} = useQuery(
      "check-ins-sheet",
      fetchCheckInSheet
  );

  return {data, status, error, refetch};
}
