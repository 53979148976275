export const checkInTypes = [
  {value: "auto", label: "Automatic"},
  {value: "none", label: "None"},
  {value: "set_times", label: "Set Times"},
  {value: "arrival_departure", label: "Arrival & Departure"},
]

export const travelTypes = [
  {value: "travel", label: "Travel"},
  {value: "accommodation", label: "Accommodation"},
]

export const travelSubtypesTravel = [
  {value: "flight", label: "Flight"},
  {value: "taxi", label: "Taxi"},
  {value: "ferry", label: "Ferry"},
  {value: "train", label: "Train"},
  {value: "rental", label: "Rental"},
  {value: "private_car", label: "Private Car"},
  {value: "bus", label: "Bus"},
  {value: "other", label: "Other"},
  // {value: "undefined", label: "Undefined"},
]

export const travelSubtypesAccommodation = [
  {value: "hotel", label: "Hotel"},
  {value: "private_property", label: "Private Property"},
  {value: "home", label: "Home"},
  {value: "other", label: "Other"},
  // {value: "undefined", label: "Undefined"},
]

