import React, { useEffect, useState } from "react";
import Checkbox from "../../../../components/form/Checkbox";
import DateTimeInput from "../../../../components/form/DateTimeInput";

function Timing() {
  const [isAllTime, setIsAllTime] = useState<boolean>(false);
  const [isSpecificTimeDate, setIsSpecificTimeDate] = useState<boolean>(false);

  useEffect(() => {
    if (isSpecificTimeDate) {
      setIsAllTime(false);
    }
  }, [isSpecificTimeDate]);
  useEffect(() => {
    if (isAllTime) {
      setIsSpecificTimeDate(false);
    }
  }, [isAllTime]);

  return (
    <div className="flex flex-col gap-4 py-4">
      <span className="font-bold text-sm text-center">
        <span className="text-red">*</span> Step 5 : Timing
      </span>
      <div className="mx-auto">
        <Checkbox
          checked={isAllTime}
          name="all-the-time"
          label={"All the time"}
          value={isAllTime ? "true" : "false"}
          onClick={(target) => setIsAllTime(target.checked)}
        />

        <Checkbox
          checked={isSpecificTimeDate}
          name="timing"
          label="Specific time and dates"
          value="specific-time-and-dates"
          onClick={(target) => setIsSpecificTimeDate(target.checked)}
        />
      </div>
      <div className={`${isAllTime ? "hidden" : ""}`}>
        <div className="flex gap-4 items-center px-8">
          <div className="shadow-md rounded-md h-12 px-4 ">
            <DateTimeInput
              type="time"
              defaultValue="00:00"
              name="startTime"
              required={true}
              disabled={!isSpecificTimeDate}
            />
          </div>
          <div className="shadow-md rounded-md h-12 px-4 ">
            <DateTimeInput
              type="date"
              name={"startDate"}
              required={true}
              disabled={!isSpecificTimeDate}
            />
          </div>
        </div>

        <div className="text-center my-2">until</div>

        <div className="flex gap-4 justify-center px-8">
          <div className="shadow-md rounded-md h-12 px-4">
            <DateTimeInput
              type="time"
              defaultValue="00:00"
              name="endTime"
              required={true}
              disabled={!isSpecificTimeDate}
            />
          </div>
          <div className="shadow-md rounded-md h-12 px-4 ">
            <DateTimeInput
              type="date"
              name={"endDate"}
              required={true}
              disabled={!isSpecificTimeDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timing;
