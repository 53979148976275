import React, { useContext, useEffect, useState } from "react";
import Checkbox from "../../../../components/form/Checkbox";

import { UserContext } from "../../../../context/user";
import { Department } from "../../../../types/userManagement";
import { UserDataTypes } from "../../../../types/user-data-types";
import UserSelect from "../../../../components/form-v2/searchable-select/UserSelect";
import DepartmentSelect from "../../../../components/form-v2/searchable-select/DepartmentSelect";
import api from "../../../../api/axiosConfig";
import { PaginatedResponse } from "../../../../types/PaginatedResponse";
type SelectOption = { label: string; value: string };

function People({
  clientID,
  departmentID,
  initialUserIDs,
  initialDepartmentIDs,
  onChange,
}: {
  clientID: string | null;
  departmentID: string | null;
  initialUserIDs?: string[];
  initialDepartmentIDs?: string[];
  onChange: (
    userIDs: string[],
    departmentIDs: string[],
    applyToAll: boolean
  ) => void;
}) {
  const [applyToAll, setApplyToAll] = useState<boolean>(false);

  const [users, setUsers] = useState<UserDataTypes[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);

  const fetchInitialUsers = async () => {
    if (!initialUserIDs || initialUserIDs.length == 0) {
      return;
    }
    try {
      const res = await api.get<PaginatedResponse<UserDataTypes>>("/users", {
        params: { ids: initialUserIDs.join(",") },
      });
      console.log({ res });
      setUsers(res.data.items);
      console.log("USERS", res.data);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchInitialDepartments = async () => {
    if (!initialDepartmentIDs || initialDepartmentIDs.length == 0) {
      return;
    }
    try {
      const res = await api.get<PaginatedResponse<Department>>("/departments", {
        params: { ids: initialDepartmentIDs.join(",") },
      });
      setDepartments(res.data.items);
      console.log("DEPARTMENTS", res.data.items);
    } catch (e) {
      console.error(e);
    }
  };
  const [initialised, setInitialised] = useState(false);

  useEffect(() => {
    (async () => {
      await fetchInitialUsers();
      await fetchInitialDepartments();

      setInitialised(true);
    })();
  }, []);

  const onDepartmentsChange = (departments: Department[]) => {
    setDepartments(departments);
    console.log(departments);
  };

  const onUsersChange = (users: UserDataTypes[]) => {
    console.log("USER CHANGE", users);
    setUsers(users);
  };

  useEffect(() => {
    if (!initialised) {
      return;
    }

    onChange(
      users.map((u) => u.id),
      departments.map((d) => d.id),
      applyToAll
    );
  }, [users, departments, applyToAll]);

  return (
    <div className="flex flex-col gap-2 py-4">
      <span className="font-bold text-sm text-center">
        <span className="text-red">* </span>Step 4 : People
      </span>
      <div className="mx-auto">
        <Checkbox
          label="Everyone"
          name="everyone"
          value="everyone"
          checked={applyToAll}
          onClick={() => setApplyToAll(!applyToAll)}
        />
      </div>

      {!applyToAll && (
        <>
          {!departmentID && (
            <DepartmentSelect
              departments={departments}
              multiple={true}
              clientID={clientID}
              onSelectionChange={onDepartmentsChange}
            />
          )}

          <UserSelect
            users={users}
            multiple={true}
            clientID={clientID}
            departmentID={departmentID}
            onSelectionChange={onUsersChange}
          />
        </>
      )}
    </div>
  );
}

export default People;
