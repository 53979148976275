import React from "react";
import SearchableSelect, {SearchableSelectOption} from "./index";
import {countries} from "../../../constants/countries";


type CountrySelectSingleProps = {
  value: string | undefined;
  setValue: (value: string | undefined) => void;
  label: string;
}

export const CountrySelectSingle: React.FC<CountrySelectSingleProps> = (props) => {

  const componentOptions = countries.map((o) => {
    return {
      value: o.code,
      key: o.code,
      label: o.name,
      search: o.name,
    }
  })


  const selected: SearchableSelectOption<string> | undefined = componentOptions.find(o => o.value === props.value)

  function handleChange(values: SearchableSelectOption<string>[]) {

    if (values.length == 0) {
      props.setValue(undefined)
      return
    }

    props.setValue(values[0].value)
  }

  return <div>
    <label>{props.label}</label>
    <SearchableSelect
        multiple={false}
        options={componentOptions}
        selected={selected ? [selected] : []}
        search={true}
        localSearch={true}
        onChange={handleChange}/>
  </div>
}