import React, { useEffect, useState } from "react"
import SearchableSelect, { SearchableSelectOption } from "."
import api from "../../../api/axiosConfig"
import { PaginatedResponse } from "../../../types/PaginatedResponse"
import { Department } from "../../../types/userManagement"

import PeopleIcon from "../../../assets/icons/icon-person.svg";
import DepartmentIcon from "../../../assets/icons/department-icon.svg";
type DepartmentSelectParams = {
  departments: Department[]
  clientID?: string | null;
  multiple?: boolean;
  onSelectionChange?: (departments: Department[]) => void;
  disabled?: boolean;
}

function DepartmentSelect({
  clientID,
  multiple,
  onSelectionChange,
  departments,
  disabled,
}: DepartmentSelectParams){

  const [loading, setLoading] = useState(false)
  const [options, setOptions]  = useState<SearchableSelectOption<Department>[]>([])
  const [searchStr, setSearchStr] = useState("")

  const fetchDepartments = async () => {
    setLoading(true)
    try{
      const res = await api.get<PaginatedResponse<Department>>("/departments", { params: { clientID, search: searchStr, rpp: 200 } })
      setOptions(res.data.items.map((d) => ({
        label: d.name,
        value: d,
        key: d.id
      })))

    } catch(e){
      console.error(e)
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchDepartments()
  }, [clientID, searchStr])

  useEffect(() => {
    onChange([])
  }, [clientID])

  const onChange = (selected: SearchableSelectOption<Department>[]) => {
    if(onSelectionChange){
      onSelectionChange(selected.map(s => s.value))
    }
  }

  const onSearch = (value: string) => {
    setSearchStr(value);
  }

  return (<SearchableSelect
    icon={DepartmentIcon}
    label={multiple ? "Departments" : "Department"}
    selected={departments.map(d => ({
      label: d.name,
      value: d,
      key: d.id
    }))}
    search={true}
    multiple={multiple ? true : false}
    options={options}
    localSearch={false}
    onChange={onChange}
    onSearch={onSearch}
    loading={loading}
    disabled={disabled}
  />)
}

export default DepartmentSelect