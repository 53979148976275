import { useEffect, useState } from "react";
import api from "../../api/axiosConfig";
import { useInfiniteQuery } from "react-query";

export default function useInfiniteApi<T = any>(
  endpoint: string,
  params: {},
  queryKey: string | string[]
) {
  const [items, setItems] = useState<T[]>([]);

  const fetchData = async (page: number) => {

    
    return await api
      .get(`${endpoint}?page=${page}`, { params })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => console.error(err));
  };

  const {
    data,
    isLoading,
    isError,
    error,
    status,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(queryKey, ({ pageParam = 1 }) => fetchData(pageParam), {
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.hasMore) {
        return allPages.length + 1;
      }
      return undefined;
    },
  });

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [data, hasNextPage, fetchNextPage]);
  useEffect(() => {
    if (hasNextPage) return;
    const flatItems = data ? data.pages.flatMap((page) => page.items) : [];
    setItems(flatItems as []);
  }, [data, hasNextPage]);

  return {
    data: items,
    isLoading,
    isError,
    error,
    status,
  };
}
