import React, { RefObject, useEffect, useRef, useState } from "react";
import Select from "../../../../components/form/Select";
import { countries } from "../../../../constants/countries";
import { BlueButton } from "../../../../components/ui/Buttons";
import Download from "../../../../assets/icons/download-button-white.svg";
import api from "../../../../api/axiosConfig";
import TextTemplate from "../../../../components/ui/Content/TextTemplate";
import { AxiosResponse } from "axios";
import { CountryProfileDataTypes } from "../../../../types/country-profile-data-types";
import { useNavigate } from "react-router-dom";

const riskLevels = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

export default function CustomProfiles() {
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  useEffect(() => {
    if (!user) return;
    const userData = JSON.parse(user);

    if (userData?.role?.name !== "NGS Super Admin") {
      navigate("/settings");
    }
  }, [user]);

  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [countryProfile, setCountryProfile] =
    useState<CountryProfileDataTypes | null>(null);
  const buttonUploadPDF = useRef<HTMLInputElement>(null);
  const [filePDF, setFilePDF] = useState<any>();

  const fetchCountryProfile = (countryISO: string) => {
    api
      .get(`country-profile/${countryISO}`)
      .then((res: AxiosResponse<CountryProfileDataTypes>) => {
        setCountryProfile(res.data);
      })
      .catch((err) => console.error(err));
  };

  const handleCountryChange = (value: RefObject<HTMLInputElement>) => {
    const countryISO = countries.find(
      (country) => country.name === value.current?.value
    )?.code;
    setSelectedCountry(countryISO!);

    fetchCountryProfile(countryISO!);
  };

  const handleSaveCountryProfile = () => {
    api
      .put(`country-profile/${selectedCountry}`, countryProfile)
      .then((res) => console.log("Country profile updated"))
      .catch((err) => console.error(err));

    if (filePDF) {
      saveUploadedImage();
    }
  };

  const saveUploadedImage = () => {
    const formData = new FormData();
    formData.append("file", filePDF);

    api
      .post(
        `/files/country-profile/${selectedCountry}`,
        {
          file: filePDF,
        },
        {
          headers: { "content-type": "multipart/form-data" },
        }
      )
      .then((res) => {
        console.log("country profile PDF uploaded");
        setFilePDF(null);
        fetchCountryProfile(selectedCountry);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCountryRiskLevelChange = (value: string) => {
    setCountryProfile((prevState) => {
      return {
        ...prevState!,
        averageRiskLevel: parseInt(value),
      };
    });
  };

  const handleTextSave = (text: string, index: number) => {
    const newAnalysis = countryProfile?.analysis.map((analysis, i) => {
      if (i === index) {
        return {
          ...analysis,
          text: text,
        };
      } else {
        return analysis;
      }
    });
    setCountryProfile((prevState) => {
      return {
        ...prevState!,
        analysis: newAnalysis!,
      };
    });
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      console.error("No file selected");
      return;
    }

    setFilePDF(event.target.files[0]);
  };

  const handleChooseFile = () => {
    buttonUploadPDF.current?.click();
  };

  return (
    <div className="overflow-hidden">
      <div className="h-[calc(100vh_-_315px)] overflow-y-auto pr-2">
        <div className="mt-6">
          <div>Select Country</div>
          <div className="w-[200px]">
            <Select
              onSelect={(value) => handleCountryChange(value)}
              options={countries}
              title="Country"
            />
          </div>
        </div>
        {selectedCountry && (
          <>
            <div className="mt-8">
              <div className="flex w-full justify-between items-end">
                <div>
                  <div>Risk Level</div>
                  <div className="w-[100px]">
                    <Select
                      onSelect={(value) =>
                        handleCountryRiskLevelChange(value.current?.value!)
                      }
                      options={riskLevels}
                      title="Level"
                      value={countryProfile?.averageRiskLevel.toString()}
                      showSearch={false}
                    />
                  </div>
                </div>
                <div className="w-[222px]">
                  <input
                    type="file"
                    id="file-upload"
                    name="country-profile-pdf"
                    className="hidden"
                    onChange={handleFileUpload}
                    ref={buttonUploadPDF}
                  />
                  <BlueButton
                    text="Upload PDF Profile"
                    onClick={handleChooseFile}
                    icon={Download}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8">
              <div className="mb-2">Overview</div>
              <TextTemplate
                initialContent={countryProfile?.overview!}
                onSave={(text) =>
                  setCountryProfile((prevState) => {
                    return {
                      ...prevState!,
                      overview: text,
                    };
                  })
                }
              />
            </div>
            {countryProfile?.analysis.map((analysis, index) => (
              <div className="mt-8" key={analysis.slug}>
                <div className="mb-2">{analysis.category}</div>
                <TextTemplate
                  initialContent={analysis.text}
                  onSave={(text) => handleTextSave(text, index)}
                />
              </div>
            ))}
            <div className="w-[100px] mt-6 mx-auto ">
              <BlueButton text="Save" onClick={handleSaveCountryProfile} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
