import React, { useContext } from "react";
import InputText from "../../../../../../components/form/InputText";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { FormDataContext } from "../../context/form";
import moment from "moment";

type PassportExpiryProps = {
    index: number
    value: string|null
    onChange: (value: string) => void
}
const PassportExpiry: React.FC<PassportExpiryProps> = ({index, value, onChange}) => {
  const { data } = useContext(UserProfileContext)!;
  const userData: UserDataTypes = data?.data;
  const formDataContext = useContext(FormDataContext)!;
  const { passportExpiryDateRef } = formDataContext;

  const passportExpiry = moment(value).format("yyyy-MM-DD");
  const onDateChange = (e : React.ChangeEvent<HTMLInputElement>) => {
      if(e.target.value){
          onChange(moment(e.target.value, "yyyy-MM-DD").toISOString())
          return
      }

      onChange("")
  }

  return (
    <div className="">
      <label htmlFor={`passport_expiry[${index}]`} className="opacity-80">
        Passport Expiry
      </label>
      <input
        type="date"
        className="h-10 w-full rounded-mnd shadow-md px-4"
        name={`passport_expiry[${index}]`}
        placeholder="Enter Passport Expiry"
        value={passportExpiry}
        onChange={onDateChange}
        ref={passportExpiryDateRef}
      />
    </div>
  );
}

export default PassportExpiry;
