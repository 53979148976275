import React, {memo, useCallback, useEffect, useState} from "react";
import {useOperationalMapContext} from "../../providers/OperationalMapProvider";
import {AdvancedMarker, InfoWindow, useAdvancedMarkerRef} from "@vis.gl/react-google-maps";
import {FacilityDataTypes} from "../../types/facilitiy-data-types";
import {UserLocationHistory} from "../operational-map/components/Map/services/useLocationHistory";
import {calculateBearing} from "./math";
import {Polyline} from "./polyline";
import {fetchTimeZone} from "./timezone";
import moment from "moment";
import {useTimeZoneSelectContext} from "../../providers/TimeZoneSelectProvider";

type TrackingMarkerProps = {
  infoKey: string;
  showInfoWindow: boolean;
  current: UserLocationHistory;
  next: UserLocationHistory | null;
}

export const TrackingMarker: React.FC<TrackingMarkerProps> = memo((props) => {
    const ctx = useOperationalMapContext();
    const tzCTX = useTimeZoneSelectContext()
    const [markerRef, marker] = useAdvancedMarkerRef();

    const openInfo = useCallback(() => {
      ctx.setVisibleInfoWindow(props.infoKey);
    }, [ctx, props.infoKey]);


    const rotation = props.next ? calculateBearing(props.current.coordinates, props.next.coordinates) : 0

    const [userTimeZone, setUserTimeZone] = useState<string|null>(null);

    const getTimeZone = async() => {
      try {
        setUserTimeZone(await fetchTimeZone(props.current.coordinates[1], props.current.coordinates[0]));
      } catch(e){
        console.error(e);
      }
    }

    useEffect(() => {
      getTimeZone()
    },[props.current.coordinates])

  const battery = (() => {
    if(!props.current.battery) return "N/A"
    if(props.current.pointCount === 1) return props.current.battery[0]
    return `${props.current.battery[0]} - ${props.current.battery[props.current.battery.length - 1]}`
  })()

  const avgSpeed = (() => {
    if (!props.current.speed){
      return "N/A"
    }

    return props.current.speed.reduce((acc, curr) => acc + curr, 0) / props.current.speed.length
  })()



    return (
      <>
        {props.next &&
            <Polyline
              coordinates={[props.current.coordinates, props.next.coordinates]}
              strokeWeight={6}
              strokeColor={"#8b71e8"}
           />
        }
        <AdvancedMarker position={{ lat: props.current.coordinates[1], lng: props.current.coordinates[0] }} onClick={openInfo} ref={markerRef}>
          <img
            src={props.next ? "/assets/mapicons/tracking.png" : "/assets/mapicons/tracking_latest.png"}
            width={24}
            height={24}
            alt={"Facility Icon"}
            style={{
              transform: `translate(0%, 50%) rotate(${rotation}deg)`,
            }}

          />
        </AdvancedMarker>
        {props.showInfoWindow && (
          <InfoWindow anchor={marker}>
            <h3 className={"text-center text-lg font-semibold"}>Tracking Details</h3>
            <hr/>
            <div className={"mt-2 grid grid-cols-[1fr,3fr] gap-x-4"}>
              <div className={"text-base font-semibold"}>Name</div>
              <div className={"text-base capitalize"}>{props.current.name}</div>
              <div className={"text-base font-semibold"}>Client</div>
              <div className={"text-base capitalize"}>{props.current.clientName}</div>
              <div className={"text-base font-semibold"}>Times Updated</div>
              <div className={"text-base capitalize"}>{props.current.pointCount}</div>
              {userTimeZone && <>
                  <div className={"text-base font-semibold"}>Time (Location)</div>
                  <div className={"text-base capitalize"}>
                    {moment.tz(props.current.startTime, userTimeZone).format("DD/MM/YYYY HH:mm:ss Z z")}
                  </div>
              </>}
              <div className={"text-base font-semibold"}>Time (Operator)</div>
              <div className={"text-base capitalize"}>
                {moment.tz(props.current.startTime, tzCTX.timeZone).format("DD/MM/YYYY HH:mm:ss Z z")}
              </div>
              <div className={"text-base font-semibold"}>Battery Level</div>
              <div className={"text-base capitalize"}>{battery}%</div>
              <div className={"text-base font-semibold"}>Speed</div>
              <div className={"text-base capitalize"}>{avgSpeed}</div>
              <div className={"text-base font-semibold"}>Bearing</div>
              <div className={"text-base capitalize"}>{props.current.bearing}</div>

            </div>
          </InfoWindow>
        )}
      </>
    )
      ;
  })
;

TrackingMarker.displayName = "Tracking"