import React, { SetStateAction, useEffect, useState } from "react";
import { generateMapSearchBox } from "../../../../util/map";
import PanToolIcon from "@mui/icons-material/PanTool";
import Search from "../../../../components/form/Search";
import PolygonIcon from "../../../../assets/icons/draw-on-map-lines.svg";
import ZonesDataTypes, { GeofencesDataTypes } from "../../types";
import TextInput from "../../../../components/form-v2/input";

type DrawingZoneType = {
  map: google.maps.Map | undefined;
  drawingManager: google.maps.drawing.DrawingManager | undefined;
  polygonRef: React.MutableRefObject<google.maps.Polygon | undefined>;
  circleRef: React.MutableRefObject<google.maps.Circle | undefined>;
  data: ZonesDataTypes | GeofencesDataTypes;
  isZoneNameError: boolean;
};

function DrawZone({
  map,
  drawingManager,
  polygonRef,
  circleRef,
  data,
  isZoneNameError,
}: DrawingZoneType) {
  const [disableDrawing, setDisableDrawing] = useState<boolean>(true);
  const [drawingMode, setDrawingMode] =
    useState<google.maps.drawing.OverlayType | null>(null);

  const handleSearchLocation = (map: google.maps.Map) => {
    const input = document.getElementById("pac-input") as HTMLInputElement;
    generateMapSearchBox(input, map);
  };

  useEffect(() => {
    if (!drawingManager) return;
    const mode = drawingManager.getDrawingMode();
    setDrawingMode(mode);
  }, [drawingManager]);

  useEffect(() => {
    if (!drawingManager) {
      setDrawingMode(null);
      return;
    }
    setDrawingMode(drawingManager?.getDrawingMode());
  }, [drawingManager]);

  useEffect(() => {
    if (!drawingManager || !map) return;
    const handleCompleteCircle = (circle: google.maps.Circle) => {
      circleRef.current = circle;
    };

    const handleCompletePolygon = (polygon: google.maps.Polygon) => {
      polygonRef.current = polygon;
    };

    drawingManager.addListener("circlecomplete", handleCompleteCircle);
    drawingManager.addListener("polygoncomplete", handleCompletePolygon);
  }, [drawingManager, map, circleRef, polygonRef]);

  const handlePolygon = () => {
    drawingManager?.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    circleRef.current?.setMap(null);
    circleRef.current = undefined;
  };

  const handleCircle = () => {
    drawingManager?.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
    setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
    polygonRef.current?.setMap(null);
    polygonRef.current = undefined;
  };

  const handleMove = () => {
    drawingManager?.setDrawingMode(null);
    setDrawingMode(null);
  };

  return (
    <div className="w-full flex items-center flex-col gap-4 my-6">
      <span className="font-bold text-sm text-center">Step 1 : Draw Zone</span>
      <div className="flex flex-col gap-4 w-full">
        <TextInput
          placeholder="Name"
          name="name"
          required={false}
          defaultValue={data.name}
          error={isZoneNameError}
        />
        <Search
          placeholder="Search Location"
          id="pac-input"
          onFocus={() => handleSearchLocation(map!)}
        />
      </div>
      <h4 className="font-semibold text-center">Drawing Tools</h4>
      <div className="flex flex-col gap-8 items-center">
        <div className="flex gap-4">
          <button
            className={`h-10 w-10 rounded-md shadow-md flex items-center justify-center ${
              drawingMode === "polygon" ? "border-2 border-blue" : ""
            }`}
            type="button"
            onClick={handlePolygon}
            disabled={true}
          >
            <img src={PolygonIcon} alt="" />
          </button>
          <button
            className={`h-10 w-10 rounded-md shadow-md flex items-center justify-center ${
              drawingMode === "circle" ? "border-2 border-blue" : ""
            }`}
            type="button"
            onClick={handleCircle}
            disabled={true}
          >
            <div className="h-1/2 w-1/2 bg-opacity-0 border-2 rounded-full"></div>
          </button>
          <button
            className={`h-10 w-10 rounded-md shadow-md flex items-center justify-center ${
              drawingMode === null ? "border-2 border-blue" : ""
            }`}
            type="button"
            onClick={handleMove}
          >
            <PanToolIcon />
          </button>
        </div>
      </div>
    </div>
  );
}

export default DrawZone;
