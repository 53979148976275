import React, { ReactNode, SetStateAction } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AxiosResponse } from "axios";
type QueryType = {
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  data: AxiosResponse<any, any> | undefined;
  isFetching: boolean;
  isPreviousData: boolean;
  status: "idle" | "error" | "loading" | "success";
};

type PaginatedComponentType = {
  page: number;
  setPage: React.Dispatch<SetStateAction<number>>;
  children: ReactNode;
  query: QueryType;
};

function PaginatedComponent({
  page,
  setPage,
  children,
  query,
}: PaginatedComponentType) {
  return (
    <div className="flex flex-col justify-between">
      {children}
      <div className="flex items-center justify-end gap-4 mt-8">
        <button
          onClick={() => setPage((old) => Math.max(old - 1, 1))}
          disabled={page === 1}
          className={`w-10 h-10 flex items-center justify-center ${
            page === 1 ? "opacity-0" : "opacity-100"
          }`}
        >
          <ArrowBackIosIcon />
        </button>

        <span className="h-10 w-10 flex items-center justify-center">
          {page}
        </span>

        <button
          className={`w-10 h-10 flex items-center justify-center ${
            query.isPreviousData || !query.data?.data.hasMore
              ? "opacity-0"
              : "opacity-100"
          }`}
          onClick={() => {
            if (!query.isPreviousData && query.data?.data.hasMore) {
              setPage((old) => old + 1);
            }
          }}
          disabled={query.isPreviousData || !query.data?.data.hasMore}
        >
          <ArrowForwardIosIcon />
        </button>
      </div>
    </div>
  );
}

export default PaginatedComponent;
