import React, {
  Ref,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { BlueButton } from "../../../../components/ui/Buttons";
import { Close } from "@mui/icons-material";
import { UserContext } from "../../../../context/user";
import { ClientDataTypes } from "../../../../types/client-data-types";
import api from "../../../../api/axiosConfig";
import Checkbox from "../../../../components/form/Checkbox";
// import Guardians from "./guardians";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
// import path from "path";
import { FacilityDataTypes } from "../../../../types/facilitiy-data-types";
import UserSelect from "../../../../components/form-v2/searchable-select/UserSelect";
import { UserDataTypes } from "../../../../types/user-data-types";
import ClientSelect from "../../../../components/form-v2/searchable-select/ClientSelect";
import { Client, Department } from "../../../../types/userManagement";
import { PaginatedResponse } from "../../../../types/PaginatedResponse";
import DepartmentSelect from "../../../../components/form-v2/searchable-select/DepartmentSelect";
function EditFacility({
  editFacility,
  setEditFacility,
  facility,
}: {
  setEditFacility: React.Dispatch<SetStateAction<boolean>>;
  editFacility: boolean;
  facility: FacilityDataTypes;
}) {

  const userContext = useContext(UserContext);
  const userRole = userContext?.role;

  const [notifyNGSCheck, setNotifyNGSCheck] = useState<boolean>(
    facility.notifyNGS
  );

  const fetchInitialUsers = async () => {
    console.log(facility)
    if (!facility.guardians || facility.guardians.length == 0) {
      return;
    }
    try {
      const res = await api.get<PaginatedResponse<UserDataTypes>>("/users", {
        params: { ids: facility.guardians.join(",") },
      });
      console.log({ res });
      setGuardians(res.data.items);
      console.log(res.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchInitialUsers();
  }, [facility])
 

  const [guardians, setGuardians] = useState<UserDataTypes[]>([])

  const guardianChange = (users: UserDataTypes[]) => {
    setGuardians(users)

  }

  const [client, setClient] = useState<Client|null>({
    id: facility.clientID,
    name: facility.clientName,
  } as Client)


  const [department, setDepartment] = useState<Department|null>(facility.departmentID ? {
    id: facility.departmentID,
    name: facility.departmentName,
  } as Department : null)

  useEffect(() => {
    if(facility.departmentID){
      setDepartment({
        id: facility.departmentID,
        name: facility.departmentName,
      } as Department)
    }
  }, [facility])


  useEffect(() => {
    setGuardians([])
  }, [client, department])

  console.log({facility, department})
  

  const siteNameRef = useRef<HTMLInputElement>(null);
  const firstLineOfAddress = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const countryRef = useRef<HTMLInputElement>(null);
  const postalZipCodeRef = useRef<HTMLInputElement>(null);
  const longitudeRef = useRef<HTMLInputElement>(null);
  const latitudeRef = useRef<HTMLInputElement>(null);
  const notifyNGSRef = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();
  const handleEditFacility = () => {
    const siteName = siteNameRef.current!.value;

   
    const longitude = longitudeRef.current!.value;
    const latitude = latitudeRef.current!.value;
    const addressLine = firstLineOfAddress.current!.value;
    const city = cityRef.current!.value;
    const country = countryRef.current!.value;
    const postcode = postalZipCodeRef.current?.value;

    if(!client){
      return
    }

    api
      .put(`facilities/${facility.id}`, {
        clientID: client.id,
        departmentID: department?.id,
        name: siteName,
        position: {
          type: "Point",
          coordinates: [parseFloat(longitude), parseFloat(latitude)],
        },
        address: {
          addressLine: addressLine,
          city: city,
          country: country,
          postcode: postcode,
        },
        notifyNGS: notifyNGSRef.current!.checked,
        guardians: guardians.map(g => g.id),
      })
      .then((res) => {
        console.log(res.data);
        queryClient.invalidateQueries("facilities");
        queryClient.invalidateQueries("all-facilities");
        setEditFacility(false);
      });
  };
  return (
    <>
      <div className="bg-white rounded-md shadow-md relative px-10 pb-10 flex flex-col gap-8">
        <button
          className="h-10 w-10 absolute top-0 right-0"
          onClick={() => setEditFacility(false)}
          type="button"
        >
          <Close />
        </button>

        <header className="h-20 border-b px-8 flex items-center justify-center">
          <h3>Edit Facility</h3>
        </header>

        <div className="">
          <div className="">
            <h6 className="font-semibold text-sm">Site Details</h6>
            <div className="my-8 grid-cols-2 grid gap-12">
              <div className="flex items-center gap-4">
                <span className="font-bold text-red">*</span>
                <input
                  type="text"
                  className="h-10 w-full rounded-md shadow-md px-4"
                  placeholder="Name of Site"
                  ref={siteNameRef}
                  required
                  name="site-name"
                  defaultValue={facility.name}
                />
              </div>
              <div className="flex items-center gap-4">
                <span className="font-bold text-red">*</span>
                  <ClientSelect disabled={true} multiple={false} clients={client ? [client] : []} onSelectionChange={(clients) => {
                    if(clients.length > 0){
                      setClient(clients[0])
                    } else {
                      setClient(null)
                    }
                  }}/>
              </div>
              <div></div>
              <div className="flex items-center gap-4">
                <span className="font-bold text-red opacity-0">*</span>
                <DepartmentSelect  disabled={userRole?.auroraAccessLevel == "department"} multiple={false} departments={department ? [department] : []} onSelectionChange={(departments) => {
                    if(departments.length > 0){
                      setDepartment(departments[0])
                    } else {
                      setDepartment(null)
                    }
                  }}/>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-8">
            <div className="relative">
              <span className="font-bold text-red absolute -left-4">*</span>
              <h6 className="font-semibold text-sm">Site Location</h6>
            </div>

            <div className="grid grid-cols-[1fr_auto_1fr] gap-8">
              <div className="flex flex-col gap-4">
                <input
                  type="text"
                  className="h-10 w-full rounded-md shadow-md px-4"
                  placeholder="First Line of Address"
                  ref={firstLineOfAddress}
                  required
                  name={"first-line-of-address"}
                  defaultValue={facility.address.addressLine}
                />
                <input
                  type="text"
                  required
                  className="h-10 w-full rounded-md shadow-md px-4"
                  placeholder="City"
                  ref={cityRef}
                  name={"city"}
                  defaultValue={facility.address.city}
                />
                <input
                  type="text"
                  required
                  className="h-10 w-full rounded-md shadow-md px-4"
                  placeholder="Country"
                  ref={countryRef}
                  name={"country"}
                  defaultValue={facility.address.country}
                />
                <input
                  type="text"
                  className="h-10 w-full rounded-md shadow-md px-4"
                  placeholder="Postal / Zip Code"
                  ref={postalZipCodeRef}
                  name={"zip-postal-code"}
                  defaultValue={facility.address.postcode}
                />
              </div>
              <div className="h-full ">
                <div className="relative h-full flex w-full items-center justify-center">
                  <div className="absolute h-3/4 w-[1px] bg-light-grey "></div>
                  <span className="bg-white px-4 py-2 z-10 relative">and</span>
                </div>
              </div>
              <div className="flex flex-col gap-4 items-start justify-center">
                <div className="w-full">
                  <label htmlFor="longitude" className="opacity-50">
                    Longitude
                  </label>
                  <input
                    type="text"
                    required
                    className="h-10 w-full rounded-md shadow-md px-4"
                    placeholder="Longitude"
                    ref={longitudeRef}
                    name={"longitude"}
                    defaultValue={facility.position.coordinates[0]}
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="latitude" className="opacity-50">
                    Latitude
                  </label>
                  <input
                    type="text"
                    required
                    className="h-10 w-full rounded-md shadow-md px-4"
                    placeholder="Latitude"
                    ref={latitudeRef}
                    name={"latitude"}
                    defaultValue={facility.position.coordinates[1]}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-8">
              <div className="relative">
                <span className="font-bold text-red absolute -left-4">*</span>
                <h6 className="font-semibold text-sm">Guardians</h6>
              </div>
              <div className="grid grid-cols-2">
                {client && <UserSelect users={guardians} clientID={client.id} multiple={true} onSelectionChange={guardianChange}/>}
                
        

                <div className="flex items-center justify-start pl-20">
                  <Checkbox
                    checked={notifyNGSCheck}
                    value={"notify-ngs"}
                    label={"Notify NGS"}
                    onClick={() =>
                      setNotifyNGSCheck(notifyNGSRef.current!.checked)
                    }
                    name={"notify-ngs"}
                    inputRef={notifyNGSRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-8 w-1/3 mx-auto">
          <BlueButton text="Submit" onClick={handleEditFacility} />
        </div>
      </div>
    </>
  );
}

export default EditFacility;
