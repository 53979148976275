import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";

import {BlueButton, BlueButtonCentered} from "../../../../components/ui/Buttons";
import TripEntry from "./components/TripEntry";
import Pagination from "../../../../components/ui/Content/Pagination";

import AddIcon from "../../../../assets/icons/add-icon.svg";
import searchIcon from "../../../../assets/icons/icon-search-light.svg";
import TripsHeading from "./components/TripsHeading";
import TripsError from "./components/TripsError";
import DeletePopUp from "../individualTrip/components/DeletePopUp";
import Toast from "../../../../components/portal/Toast";
import {TripWithDetails} from "../../../../pages/TripsV2/types";
import {apiDELETE, apiGET} from "../../../../apiv2/request";
import ClientSelect from "../../../../components/form-v2/searchable-select/ClientSelect";
import {Client, Department} from "../../../../types/userManagement";
import UserSelect from "../../../../components/form-v2/searchable-select/UserSelect";
import {UserDataTypes} from "../../../../types/user-data-types";
import DepartmentSelect from "../../../../components/form-v2/searchable-select/DepartmentSelect";
import CountrySelect from "../../../../components/form-v2/searchable-select/CountrySelect";
import {CountryType} from "../../../../constants/countries";
import BasicText from "../../../../components/form-v2/basic-text";
import moment from "moment";
import {UserContext} from "../../../../context/user";

type Result = {
  items: TripWithDetails[]
  page: number
  total: number
}


export default function AllTrips() {
  const [newTripError, setNewTripError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [query, setQuery] = useSearchParams()
  // const {
  //   tripsData,
  //   tripsStatus,
  //   tripsPage,
  //   setTripsPage,
  // } = useContext(TripsContext);

  // const [currentFilteredTrips, setCurrentFilteredTrips] = useState<TripsType[]>(
  //   []
  // );

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [tripIdToDelete, setTripIdToDelete] = useState<string>("");

  // get the index of the last item on the current page
  // const indexOfLastItem = tripsPage! * 10;
  // // get the index of the first item on the current page
  // const indexOfFirstItem = indexOfLastItem - 10;
  // get the current page of items

  const navigate = useNavigate()

  // useEffect(() => {
  //   if (tripsData?.items !== null) {
  //     setCurrentFilteredTrips(
  //       tripsData
  //         ? tripsData.items.slice(indexOfFirstItem, indexOfLastItem)
  //         : []
  //     );
  //   }
  // }, [tripsData, indexOfLastItem, indexOfFirstItem]);

  const openDeleteModal = (tripId: string) => {
    if (tripId) {
      setShowDeleteModal(true);
      setTripIdToDelete(tripId);
    }
  };

  const deleteEntry = async () => {

    try {
      const results = await apiDELETE<{
        message: string
      }>(`${process.env.REACT_APP_API_V2_URL}/trips/${tripIdToDelete}`)

      console.log({results})
    } catch (e) {
      console.error(e)
    }
    setShowDeleteModal(false)
    load()


  };

  const closeDeleteModal = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (e.currentTarget === e.target) {
      setShowDeleteModal(false);
    }
  };

  const openEditTrip = (id: string) => {
    window.open(`/trips/${id}/edit`, "_blank")

  };

  const openNewTrip = () => {
    navigate("/trips/create")
  };

  const [trips, setTrips] = useState<TripWithDetails[]>([])
  const [count, setCount] = useState(0)

  const load = async () => {
    setError(false)
    setLoading(true)
    try {
      const params: Record<string, string> = {}

      params["page"] = String(page)
      params["limit"] = String(rpp)

      if (client) {
        params["clientID"] = client.id as string
      }

      if (department) {
        params["departmentID"] = department.id
      }

      if (users && users.length != 0) {
        params["userIDs"] = users.map(u => u.id).join(",")
      }

      if (countries && countries.length != 0) {
        params["countryISOs"] = countries.map(c => c.code).join(",")
      }

      if (startDate && endDate) {
        params["startDate"] = startDate
        params["endDate"] = endDate
      }

      const id = query.get("id")
      if (id) {
        params["id"] = id
      }

      if (search != "") {
        params["query"] = search
      }


      const results = await apiGET<Result>(`${process.env.REACT_APP_API_V2_URL}/trips`, params, {
        "Aurora-Time-Zone": localStorage.getItem("auroraTimeZone") || "UTC",
      })
      setTrips(results.items)
      setCount(results.total)

    } catch (e) {
      setError(true)
    }
    setLoading(false)

  }

  const [client, setClient] = useState<Client | undefined>(undefined)
  const [department, setDepartment] = useState<Department | undefined>(undefined)
  const [users, setUsers] = useState<UserDataTypes[]>([])
  const [countries, setCountries] = useState<CountryType[]>([])
  const [startDate, setStartDate] = useState<string>(moment().format("YYYY-MM-DD"))
  const [endDate, setEndDate] = useState<string>(moment().add({month: 1}).format("YYYY-MM-DD"))
  const [page, setPage] = useState(1)
  const [rpp, setRPP] = useState(10)
  const [search, setSearch] = useState("")

  const {role} = useContext(UserContext)!;

  useEffect(() => {
    load()
  }, []);

  useEffect(() => {
    load()
  }, [page]);


  return (<div className="h-full pt-36 pb-16 px-7">
    {!query.get("id") && <div className="flex w-full justify-end items-center">
        <div className="flex gap-4">

          {["all"].includes(role.auroraAccessLevel) &&
              <Link className="w-[220px]" to={"/trips/check-in-configuration"}>
                  <BlueButton text="Check In Configuration"/>
              </Link>}

          {!["user"].includes(role.auroraAccessLevel) &&
              <Link to="upcoming-check-ins" className="w-[220px]">
                  <BlueButton text="View Upcoming Check Ins" onClick={() => {
                  }}/>
              </Link>}
            <div className="w-[160px]">
                <BlueButton
                    text="Add New Trip"
                    onClick={() => openNewTrip()}
                    icon={AddIcon}
                />
            </div>
        </div>
    </div>}
    {!query.get("id") && <div className="mt-5 shadow-lg px-6 pt-7 pb-8 relative bg-white">
        <div className="flex items-center justify-between py-4 border-b text-md mb-4">
            <span className="font-semibold text-lg">Filters</span>
        </div>
        <div className={"grid grid-cols-4 gap-4"}>
          {["all"].includes(role.auroraAccessLevel) &&
              <ClientSelect clients={client ? [client] : []} onSelectionChange={(clients) => setClient(clients[0])}/>}
          {["all", "client"].includes(role.auroraAccessLevel) &&
              <DepartmentSelect departments={department ? [department] : []} clientID={client?.id}
                                onSelectionChange={(departments) => setDepartment(departments[0])}/>}
          {["all", "client", "department"].includes(role.auroraAccessLevel) &&
              <UserSelect users={users} clientID={client?.id} departmentID={department?.id}
                          onSelectionChange={(users) => setUsers(users)} multiple={true}/>}
            <CountrySelect countries={countries} onSelectionChange={setCountries} multiple={true}/>
            <BasicText value={startDate} type={"date"} onChange={setStartDate} placeholder={"Start Date"}/>
            <BasicText value={endDate} type={"date"} onChange={setEndDate} placeholder={"End Date"}/>
            <BasicText value={search} type={"text"} placeholder={"Search"} onChange={setSearch}/>

            <div className={"col-end-5"}>
                <BlueButtonCentered text={"Filter"} icon={searchIcon} onClick={load}/>
            </div>

        </div>
    </div>}
    <div className="mt-5 shadow-lg px-6 pt-7 pb-20 relative bg-white">
      <div className="flex items-center justify-between py-4 border-b text-md mb-4">
        <span className="font-semibold text-lg">Trips</span>
      </div>
      <div className="mt-2">
        {loading && (<div className="w-full text-center">Loading...</div>)}
        {error && (<div className="text-red-500 w-full text-center">Error</div>)}
        {!loading && !error && trips.length == 0 && (<div className="w-full text-center">No results</div>)}
        {!loading && !error && trips.length != 0 &&

            <>
                <TripsHeading/>
              {trips.map((trip) => {
                return (<TripEntry
                    key={trip.id}
                    data={trip}
                    openEditTripModal={(isNew, id) => openEditTrip(id)}
                    opedDeleteTripModal={(id) => openDeleteModal(id)}
                />);
              })}</>

        }
      </div>
      {!query.get("id") && <div className="absolute bottom-5 right-10">
          <div className="flex text-light-grey">
            {trips && trips.length !== 0 && (<Pagination
                currentPage={page}
                itemsPerPage={rpp}
                dataLength={count}
                togglePageSelect={(page) => setPage(page)}
            />)}
          </div>
      </div>}
    </div>

    {showDeleteModal && (<DeletePopUp
        popUpText="You are about to delete a Trip."
        closeDeleteModal={closeDeleteModal}
        deleteEntry={deleteEntry}
    />)}

    <Toast open={newTripError} setOpen={setNewTripError}>
      <TripsError errorMessage={errorMessage}/>
    </Toast>
  </div>);
}
