import React, { useState } from "react";
import useAirlineRisks from "../../hooks/useAllAirlineRisks"; // Update the import path as necessary
import {SearchTextInput} from "../../../../../../components/form/Search"; // Update the import path as necessary
import FilterIcon from "../../../../../../assets/icons/filter-icon.svg"; // Update the import path as necessary
import DownloadIcon from "../../../../../../../src/assets/icons/download-grey.svg";
import LoadingSpinner from "../../../../../../components/ui/LoadingSpinner";
import moment from "moment";
import TooltipPortal from "./ToolTip";


interface AirlineRisk {
  airlineID: string;
  airlineName: string;
  region: string;
  headquartered: string;
  euBan: string;
  iosa: string;
  flightsPerYear: string;
  fleet: string;
  riskRating: string;
}

const headerData = [
  { title: "Airline Id" },
  { title: "Airline Name" },
  { title: "Region"},
  { title: "Headquartered" },
  { title: "Eu Ban" },
  { title: "IOSA" },
  { title: "Flights Per Year" },
  { title: "Fleet" },
  { title: "Risk Rating"},
];


function AirlineRiskMatrixMain() {
  const { data: airlineRisks, loading, error } = useAirlineRisks() as {
      data: AirlineRisk[] | undefined;
    loading: boolean;
    error: any;
  };

  const [query, setQuery] = useState("");
  const [downloadCSV, setDownloadCSV] = useState(false);

  const handleDownload = () => {
    if (downloadCSV) {
      return;
    }
    setDownloadCSV(true);
    const params = new URLSearchParams();

    params.set("export", "csv");

    fetch(`${process.env.REACT_APP_API_URL}airline-risk-data?${params.toString()}`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const downloadLink = document.createElement("a");
        const objectUrl = URL.createObjectURL(blob);

        downloadLink.href = objectUrl;
        const now = moment();
        downloadLink.download = `NGS_AirlineRisk_${now.format("MMMM_YYYY")}.csv`;
        document.body.appendChild(downloadLink);
        downloadLink.click();

        URL.revokeObjectURL(objectUrl);
        document.body.removeChild(downloadLink);
        setDownloadCSV(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setDownloadCSV(false);
      });
  };




  return (
      <div className="flex flex-col gap-6 bg-white p-4 h-[80vh] flex-1 text-sm">
      <div className="flex justify-between pb-4">
        <div className="w-5/12 pl-4">
          <SearchTextInput
            placeholder="Search for an airline"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <button
          className="bg-gray-300 pr-4 rounded-md"
          onClick={handleDownload}
        >
          {downloadCSV ? (
            <LoadingSpinner width={20} height={20} color="#000000" />
          ) : (
            <img src={DownloadIcon} className="pointer-events-none"></img>
          )}
        </button>
      </div>

      <div className="flex-1 overflow-y-scroll">
        <table className="table-auto border-separate border-spacing-y-2 text-center w-full">
          <TableHead />
          <TableBody data={airlineRisks} loading={loading} error={error} query={query} />
        </table>
      </div>
    </div>
  );
}

type TableBodyProps = {
  data?: AirlineRisk[]
  loading: boolean
  error: any
  query: string
}

const TableBody: React.FC<TableBodyProps> = ({
    data,
    loading,
    error,
    query,

}) => {

  if(!data){
      return null
  }

  const lcQuery = query.toLowerCase();

  return (
      <tbody>
      {data.map((risk, i) => (
         <Row key={risk.airlineID + risk.airlineName} data={risk} index={i} query={lcQuery}/>
      ))}
      </tbody>
  );
};

type RowProps = {
    data: AirlineRisk
    index: number

    query: string
};

const getRiskRatingClass = (rating: string) => {
  switch (rating.toUpperCase()) {
      case "NEGLIGIBLE":
          return "text-[#68AF6D] font-medium";
      case "LOW":
          return "text-[#F9E10A] font-medium";
      case "MEDIUM":
          return "text-[#E6920A] font-medium";
      case "HIGH":
          return "text-[#E5680C] font-medium";
      default:
          return "";
  }
};

const Row: React.FC<RowProps> = ({data, index, query}) => {

    if (!(data.airlineName.toLowerCase().includes(query) ||
        data.airlineID.toLowerCase().includes(query) ||
        data.headquartered.toLowerCase().includes(query)
    )){
        return null
    }


    return (
      <tr className={"[&:not(:last-child)>td]:border-b"}>
          {Object.keys(data).map((key, j) => {
              const value = data[key as keyof AirlineRisk];
              const isRiskRating = key === "riskRating";
              const riskClass = isRiskRating ? getRiskRatingClass(value.toString()) : "";

              return (
                  <td key={j} className={"capitalize [&:not(:last-child)>div]:border-r"}>
                      <div className={`my-2 ${riskClass}`}>
                          {value?.toString() ?? "-"}
                      </div>
                  </td>
              );
          })}
      </tr>
  );
};

const TableHead = () => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setTooltipPosition({
      top: rect.top,
      left: rect.left + rect.width / 2,
    });
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  return (
    <thead className="sticky top-0 bg-white z-50">
      <tr>
        {headerData.map(({ title }, i) => (
          <th
            key={i}
            className="sticky top-0 bg-white py-2 border-b-2 [&:not(:last-child)>div]:border-r group"
            onMouseEnter={title === "IOSA" ? handleMouseEnter : undefined}
            onMouseLeave={title === "IOSA" ? handleMouseLeave : undefined}
          >
            <div className="relative flex items-center p-2 justify-center gap-2">
              <span>{title}</span>
            </div>
          </th>
        ))}
      </tr>
      <TooltipPortal
        message="The IATA Operational Safety Audit evaluates an airline’s operational management."
        visible={tooltipVisible}
        position={tooltipPosition}
      />
    </thead>
  );
};


export default AirlineRiskMatrixMain;
