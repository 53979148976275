import React from "react";
import MainLink from "./MainLink";
import Sidebar from "../Sidebar";
export type NavLinks = {
  slug: string;
  label: string;
  path: string;
  rolesAllowed: string[];
};

export default function SidebarLinks({
  title,
  navLinks,
  innerLinks,
  innerLinksTitle,
}: {
  title: string;
  navLinks: NavLinks[] | undefined;
  innerLinks?: NavLinks[];
  innerLinksTitle?: string;
}) {
  return (
    <Sidebar title={title} fitContent={false}>
      <nav className="flex flex-col gap-4 px-12 text-center">
        {navLinks?.map((navLink) => {
          return (
            <MainLink
              key={navLink.slug}
              path={navLink.path}
              title={navLink.label}
              slug={navLink.slug}
              innerLinks={navLink.label === innerLinksTitle ? innerLinks : []}
            />
          );
        })}
      </nav>
    </Sidebar>
  );
}
