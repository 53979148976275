import api from "../axiosConfig";
import { useQuery } from "react-query";
import { CheckInType } from "../../features/trips/types/check-ins-data-types";
import {apiGET} from "../../apiv2/request";

type CheckInsData = {
  data: CheckInType[];
  status: "idle" | "loading" | "success" | "error";
  error: unknown;
};

export default function useGetTripsCheckIns(tripID: string): CheckInsData {


  const fetchTripCheckIns = async (): Promise<CheckInType | any> => {

    const results = await apiGET<any[]>(`${process.env.REACT_APP_API_V2_URL}/trips/${tripID}/scheduled-check-ins`)

    return results
  };

  const { data, status, error } = useQuery(
    "trips-check-ins",
    fetchTripCheckIns
  );

  return { data, status, error };
}
