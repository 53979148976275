import { Coordinates, FeatureType } from "../../../types";
import { v4 as uuidv4 } from "uuid";
const drawCircle = (f: FeatureType, map: google.maps.Map | undefined) => {
  const lng = f.geometry.coordinates[0] as number;
  const lat = f.geometry.coordinates[1] as number;
  const position = { lng, lat };
  const circle = new google.maps.Circle({
    map,
    radius: f.properties.radius,
    center: position,
    fillColor: f.properties.style.fillColor,
    editable: true,
    strokeColor: f.properties.style.lineColor,
    strokeWeight: f.properties.style.lineWidth,
    fillOpacity: 0.35,
    strokeOpacity: 0.8,
  });
  let isSettingRadius = false;
  let isCenterChanged = false;
  circle.addListener("radius_changed", () => {
    if (!isSettingRadius) {
      isSettingRadius = true;
      const newRadius = circle.getRadius();
      if (newRadius !== f.properties.radius) {
        // Only set the radius if it's actually changing
        f.properties.radius = newRadius;
        circle.setRadius(newRadius);
      }
      isSettingRadius = false;
    }
  });
  circle.addListener("center_changed", () => {
    if (!isCenterChanged) {
      isCenterChanged = true;
      const newCenter = circle.getCenter();
      if (!newCenter) return;
      const newPosition = { lng: newCenter.lng(), lat: newCenter.lat() };
      if (newPosition !== position) {
        // Only set the radius if it's actually changing
        f.geometry.coordinates = [newPosition.lng, newPosition.lat];
        circle.setCenter(newPosition);
      }
      isCenterChanged = false;
    }
  });

  return circle;
};

const drawMarker = (f: FeatureType, map: google.maps.Map | undefined) => {
  const position = {
    lng: f.geometry.coordinates[0] as number,
    lat: f.geometry.coordinates[1] as number,
  };
  const marker = new google.maps.Marker({
    map,
    position,
    draggable: true,
    label: {
      text: f.properties.icon || "\ue55e",
      fontFamily: "Material Icons",
      color: "#ffffff",
      fontSize: "18px",
    },
  });
  let isPositionChanged = false;
  marker.addListener("dragend", () => {
    if (!isPositionChanged) {
      isPositionChanged = true;
      const newPosition = {
        lng: marker.getPosition()!.lng(),
        lat: marker.getPosition()!.lat(),
      };
      if (newPosition !== position) {
        // Only set the radius if it's actually changing
        f.geometry.coordinates = [newPosition.lng!, newPosition.lat!];
        marker.setPosition(newPosition);
      }
      isPositionChanged = false;
    }
  });
  return marker;
};

const drawText = (f: FeatureType, map: google.maps.Map | undefined) => {
  if (!map) return;
  const position = {
    lng: f.geometry.coordinates[0] as number,
    lat: f.geometry.coordinates[1] as number,
  };
  const marker = new google.maps.Marker({
    map,
    position,
    draggable: true,
    icon: {
      url: "",
      size: new google.maps.Size(20, 20),
    },
    label: {
      text: f.properties.label!,
      color: f.properties.style.fontColor,
      fontSize: `${f.properties.style.fontSize}px`,
    },
  });
  let isPositionChanged = false;
  marker.addListener("dragend", () => {
    if (!isPositionChanged) {
      isPositionChanged = true;
      const newPosition = {
        lng: marker.getPosition()!.lng(),
        lat: marker.getPosition()!.lat(),
      };
      if (newPosition !== position) {
        // Only set the radius if it's actually changing
        f.geometry.coordinates = [newPosition.lng!, newPosition.lat!];
        marker.setPosition(newPosition);
      }
      isPositionChanged = false;
    }
  });

  return marker;
};

const drawPolygon = (f: FeatureType, map: google.maps.Map | undefined) => {
  if (!map) return;
  const coordinates = f.geometry.coordinates as Coordinates[];
  const path = coordinates.map((coords) => ({
    lng: coords[0],
    lat: coords[1],
  }));
  const polygon = new google.maps.Polygon({
    paths: path,
    strokeWeight: f.properties.style.lineWidth,
    fillColor: f.properties.style.fillColor,
    strokeColor: f.properties.style.lineColor,
    fillOpacity: 0.35,
    strokeOpacity: 0.8,
    editable: true,
    map,
  });
  return polygon;
};

const drawPolyline = (f: FeatureType, map: google.maps.Map | undefined) => {
  const coordinates = f.geometry.coordinates as Coordinates[];
  const path = coordinates.map((coords) => ({
    lng: coords[0],
    lat: coords[1],
  }));
  const polyline = new google.maps.Polyline({
    path,
    strokeWeight: f.properties.style.lineWidth,
    strokeColor: f.properties.style.lineColor,
    strokeOpacity:
      f.properties.style.lineOpacity === 0 ? 1 : f.properties.style.lineOpacity,
    editable: true,
  });
  return polyline;
};

const handleCircleComplete = (
  circle: google.maps.Circle,
  onConfirm: (data: FeatureType) => void
) => {
  const center = [
    circle.getCenter()!.lng(),
    circle.getCenter()!.lat(),
  ] as Coordinates;
  const radius = circle.getRadius();
  const fillColor = circle.get("fillColor");
  const lineWidth = circle.get("strokeWeight");
  const lineColor = circle.get("strokeColor");
  const fillOpacity = circle.get("fillOpacity");
  const lineOpacity = circle.get("strokeOpacity");
  const input: HTMLInputElement = document.getElementById(
    "input-drawing-name"
  ) as HTMLInputElement;
  const confirmBtn = document.getElementById(
    "input-drawing-confirm-btn"
  ) as HTMLButtonElement;
  input.parentElement!.style.display = "flex";
  input.value = "";
  input.focus();
  const handleConfirm = () => {
    if (input.value === "") return console.error("Name is invalid.");
    const data: FeatureType = {
      uuidv4: uuidv4(),
      drawing: circle,
      drawingType: "circle",
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: center,
      },
      properties: {
        name: input.value,
        radius,
        style: {
          lineWidth,
          lineColor,
          lineOpacity,
          fillOpacity,
          fillColor,
        },
        description: "",
      },
    };
    onConfirm(data);
    input.parentElement!.style.display = "none";
    confirmBtn.removeEventListener("click", handleConfirm);
  };
  confirmBtn.addEventListener("click", handleConfirm);
};

const handleMarkerComplete = (
  marker: google.maps.Marker,
  onConfirm: (data: FeatureType) => void
) => {
  const center = [
    marker.getPosition()!.lng(),
    marker.getPosition()!.lat(),
  ] as Coordinates;
  const fillColor = marker.get("fillColor");
  const lineWidth = marker.get("strokeWeight");
  const lineColor = marker.get("strokeColor");
  const fillOpacity = marker.get("fillOpacity");
  const lineOpacity = marker.get("strokeOpacity");
  const input: HTMLInputElement = document.getElementById(
    "input-drawing-name"
  ) as HTMLInputElement;
  const confirmBtn = document.getElementById(
    "input-drawing-confirm-btn"
  ) as HTMLButtonElement;
  input.parentElement!.style.display = "flex";
  input.value = "";
  input.focus();
  const handleConfirm = () => {
    if (input.value === "") return console.error("Name is invalid.");
    const data: FeatureType = {
      uuidv4: uuidv4(),
      drawing: marker,
      drawingType: "marker",
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: center,
      },
      properties: {
        name: input.value,
        style: {
          lineWidth,
          lineColor,
          lineOpacity,
          fillOpacity,
          fillColor,
        },
        description: "",
      },
    };
    onConfirm(data);
    input.parentElement!.style.display = "none";
    confirmBtn.removeEventListener("click", handleConfirm);
  };
  confirmBtn.addEventListener("click", handleConfirm);
};

const handlePolylineComplete = (
  polyline: google.maps.Polyline,
  onConfirm: (data: FeatureType) => void
) => {
  const polylinePath = polyline.getPath().getArray();
  const paths = polylinePath.map((path) => {
    const lng = path.lng();
    const lat = path.lat();
    return [lng, lat] as Coordinates;
  });
  const fillColor = polyline.get("fillColor");
  const lineWidth = polyline.get("strokeWeight");
  const lineColor = polyline.get("strokeColor");
  const fillOpacity = polyline.get("fillOpacity");
  const lineOpacity = polyline.get("strokeOpacity");
  const input: HTMLInputElement = document.getElementById(
    "input-drawing-name"
  ) as HTMLInputElement;
  const confirmBtn = document.getElementById(
    "input-drawing-confirm-btn"
  ) as HTMLButtonElement;
  input.parentElement!.style.display = "flex";
  input.value = "";
  input.focus();
  const handleConfirm = () => {
    if (input.value === "") return console.error("Name is invalid.");
    const data: FeatureType = {
      uuidv4: uuidv4(),
      drawing: polyline,
      drawingType: "polyline",
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: paths,
      },
      properties: {
        name: input.value,
        style: {
          lineWidth,
          lineColor,
          lineOpacity,
          fillOpacity,
          fillColor,
        },
        description: "",
      },
    };
    onConfirm(data);
    input.parentElement!.style.display = "none";
    confirmBtn.removeEventListener("click", handleConfirm);
  };
  confirmBtn.addEventListener("click", handleConfirm);
};

const handlePolygonComplete = (
  polygon: google.maps.Polygon,
  onConfirm: (data: FeatureType) => void
) => {
  const polygonPaths = polygon.getPath().getArray();
  const paths = polygonPaths.map((path) => {
    const lng = path.lng();
    const lat = path.lat();
    return [lng, lat] as Coordinates;
  });
  const fillColor = polygon.get("fillColor");
  const lineWidth = polygon.get("strokeWeight");
  const lineColor = polygon.get("strokeColor");
  const fillOpacity = polygon.get("fillOpacity");
  const lineOpacity = polygon.get("strokeOpacity");
  const input: HTMLInputElement = document.getElementById(
    "input-drawing-name"
  ) as HTMLInputElement;
  const confirmBtn = document.getElementById(
    "input-drawing-confirm-btn"
  ) as HTMLButtonElement;
  input.parentElement!.style.display = "flex";
  input.value = "";
  input.focus();
  const handleConfirm = () => {
    if (input.value === "") return console.error("Name is invalid.");
    const data: FeatureType = {
      uuidv4: uuidv4(),
      drawing: polygon,
      drawingType: "polygon",
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: paths,
      },
      properties: {
        name: input.value,
        style: {
          lineWidth,
          lineColor,
          lineOpacity,
          fillOpacity,
          fillColor,
        },
        description: "",
      },
    };
    onConfirm(data);
    input.parentElement!.style.display = "none";
    confirmBtn.removeEventListener("click", handleConfirm);
  };
  confirmBtn.addEventListener("click", handleConfirm);
};
export {
  handleCircleComplete,
  handleMarkerComplete,
  handlePolygonComplete,
  handlePolylineComplete,
  drawCircle,
  drawMarker,
  drawPolygon,
  drawPolyline,
  drawText,
};
