import React from "react";
import ReactDOM from "react-dom";

interface TooltipPortalProps {
  message: string;
  visible: boolean;
  position: { top: number; left: number };
}

const TooltipPortal: React.FC<TooltipPortalProps> = ({ message, visible, position }) => {
  if (!visible) return null;

  return ReactDOM.createPortal(
    <div
      style={{
        position: "fixed",
        top: position.top,
        left: position.left,
        transform: "translate(-50%, -100%)",
      }}
      className="w-auto px-3 py-1 text-xs text-grey font-medium rounded-md z-50"
    >
      {message}
    </div>,
    document.body
  );
};

export default TooltipPortal;
