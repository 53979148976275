import React from "react";
import {
  AirlineRiskMatrixMain,
  AirlineRiskMatrixSidebar,
} from "../../../features/risk/pages/airline-risk-matrix";

function AirlineRiskMatrix() {
  return (
    <div className="flex">
      <AirlineRiskMatrixMain />
      <AirlineRiskMatrixSidebar />
    </div>
  );
}

export default AirlineRiskMatrix;
