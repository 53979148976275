import React, { FormEvent, SetStateAction, useEffect, useRef, useState } from "react";
import useOnClickOutside from "../../../../../../hooks/useOnClickOutside";
import CustomCheckbox from "../../../../../../components/form/CustomCheckbox";
import { BlueButton } from "../../../../../../components/ui/Buttons";
import { useSearchParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import moment from "moment";
import useGetIncidentTypes from "../../../../../../api/incident-types/useGetIncidentTypes";
import IncidentTypesDataTypes from "../../../../../../types/incident-types-data-types";
import CheckboxFilter from "../../../incident-log/components/checkbox-filter";
import CategorySelector from "./Categoryselect";

type IncidentTypeFilter = {
  unknown: boolean;
  insignificant: boolean;
  limited: boolean;
  moderate: boolean;
  major: boolean;
  extreme: boolean;
};

function IncidentTypeFilter({
  setFilter,
  filter,
}: {
  setFilter: React.Dispatch<SetStateAction<boolean>>;
  filter: boolean;
}) {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const filterByType = searchParams.getAll("type");
  const [type, setType] = useState<IncidentTypeFilter>({
    unknown: filterByType.includes("unknown"),
    insignificant: filterByType.includes("insignificant"),
    limited: filterByType.includes("limited"),
    moderate: filterByType.includes("moderate"),
    major: filterByType.includes("major"),
    extreme: filterByType.includes("extreme"),
  });
  const formRef = useRef<HTMLFormElement>(null);

  useOnClickOutside(formRef, () => setFilter(false));

  const incidentTypes = useGetIncidentTypes();
  const [isFiltersCleared, setIsFiltersCleared] = useState(false);

  const [selectedTimeframe, setSelectedTimeframe] = useState<string>("week");

  const calculateStartTime = (timeframe: string) => {
    switch (timeframe) {
      case "12 hours":
        return moment().utc().subtract(12, "hours").toISOString();
      case "24 hours":
        return moment().utc().subtract(1, "days").toISOString();
      case "week":
        return moment().utc().subtract(7, "days").toISOString();
      default:
        return moment().utc().toISOString();
    }
  };

  const [queryTypeIDs, setQueryTypeIDs] = useState<string[]>([]);
  const [categoriesIDs, setCategoriesIDs] = useState<string[]>([]);

    const categoryIds = searchParams.getAll("categories");
    console.log(categoryIds)

  const handleTypeIdsChange = (typeIds: string[], categoryIds: string[]) => {
    setQueryTypeIDs(typeIds); 
    setCategoriesIDs(categoryIds); 
    console.log("category and types", categoryIds, typeIds)

  };


  const handleSubmit = (e: FormEvent) => {
    console.log("test")
    e.preventDefault();



    const selectedTypes: string[] = Object.entries(type)
      .filter(([_, value]) => value)
      .map(([key]) => key);


      const startTime = calculateStartTime(selectedTimeframe);


      const queryParams: { [key: string]: string | string[] } = {
      type: selectedTypes,
      startTime,
      subtypes: queryTypeIDs,
      categories: categoriesIDs, 
    };

    setSearchParams(queryParams);

    setFilter(false);
  };

  console.log(incidentTypes.data?.data)

  return (
    <form
      className={`absolute top-16 left-16 z-10 bg-white rounded-md pointer-events-auto p-4 w-fit flex-col gap-2 ${
        filter ? "flex" : "hidden"
      }`}
      ref={formRef}
    >
      <div className="w-full border-b mb-2 pb-4 pointer-events-auto">
        <h4 className="w-full inline-flex font-semibold text-center">
          Incident Type Filter
        </h4>
      </div>
      <div className="overflow-y-auto h-[500px]">
      <h4 className="w-full inline-flex font-semibold text-center">Timeframe</h4>
      <h4 className="w-full inline-flex text-sm text-center">(Default is 1 week)</h4>
      <div className="flex flex-wrap gap-4">
        {["12 hours", "24 hours", "week"].map((timeframe) => (
          <div key={timeframe} className="flex items-center">
            <CustomCheckbox
              name="timeframe"
              value={timeframe}
              checked={selectedTimeframe === timeframe}
              handleFakeCheckbox={() => setSelectedTimeframe(timeframe)}
            />
            <div className="ml-2">
              <label htmlFor="timeframe">
                {timeframe.charAt(0).toUpperCase() + timeframe.slice(1)}
              </label>
            </div>
          </div>
        ))}
      </div>
      <h5 className="w-full inline-flex font-semibold text-center">Severity</h5>
      <div className="flex items-center gap-4 pointer-events-auto">
        <CustomCheckbox
          name={"type"}
          value={"unknown"}
          checked={type.unknown}
          handleFakeCheckbox={() =>
            setType((prev) => ({ ...prev, unknown: !prev.unknown }))
          }
        />
        <label htmlFor="type">Unknown</label>
      </div>
      <div className="flex items-center gap-4">
        <CustomCheckbox
          name={"type"}
          value={"insignificant"}
          checked={type.insignificant}
          handleFakeCheckbox={() =>
            setType((prev) => ({
              ...prev,
              insignificant: !prev.insignificant,
            }))
          }
        />
        <label htmlFor="type">Insignificant</label>
      </div>
      <div className="flex items-center gap-4">
        <CustomCheckbox
          name={"type"}
          value={"limited"}
          checked={type.limited}
          handleFakeCheckbox={() =>
            setType((prev) => ({ ...prev, limited: !prev.limited }))
          }
        />
        <label htmlFor="type">Limited</label>
      </div>
      <div className="flex items-center gap-4">
        <CustomCheckbox
          name={"type"}
          value={"moderate"}
          checked={type.moderate}
          handleFakeCheckbox={() =>
            setType((prev) => ({ ...prev, moderate: !prev.moderate }))
          }
        />
        <label htmlFor="type">Moderate</label>
      </div>
      <div className="flex items-center gap-4">
        <CustomCheckbox
          name={"type"}
          value={"major"}
          checked={type.major}
          handleFakeCheckbox={() =>
            setType((prev) => ({ ...prev, major: !prev.major }))
          }
        />
        <label htmlFor="type">Major</label>
      </div>
      <div className="flex items-center gap-4">
        <CustomCheckbox
          name={"type"}
          value={"extreme"}
          checked={type.extreme}
          handleFakeCheckbox={() =>
            setType((prev) => ({ ...prev, extreme: !prev.extreme }))
          }
        />
        <label htmlFor="type">Extreme</label>
      </div>
      <h5 className="flex items-center font-semibold gap-4 4">Category</h5>
       <CategorySelector categories={incidentTypes.data?.data} initialCategoryIds={categoryIds} onTypeIdsChange={handleTypeIdsChange}/>
      </div>
      <div className="mx-auto w-3/4 ">
        <BlueButton text="Submit" type="button" onClick={handleSubmit} />
      </div>
    </form>
  );
}

export default IncidentTypeFilter;
