import React from "react";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";

type DateTimeInputTypes = {
  type: string;
  defaultValue?: string;
  disabled?: boolean;
  name?: string;
  required?: boolean;
};

function DateTimeInput({
                         type,
                         defaultValue,
                         disabled,
                         name,
                         required = false,
                       }: DateTimeInputTypes) {
  return (
      <input
          type={type}
          className="outline-none h-full w-full text-center"
          defaultValue={defaultValue}
          disabled={disabled}
          name={name}
          required={required}
      />
  );
}

export default DateTimeInput;
