import React, { useContext } from "react";
import GuideListItem from "../../components/GuideListItem";
import { AdviceGuidesContext } from "../../context/advice-guides";
import { AdviceGuidesDataTypes } from "../../../../../../types/advice-guides-type";
import { useParams } from "react-router-dom";

function AdviceGuidesEmergencySection() {
  const { all_guides_data } = useContext(AdviceGuidesContext)!;

  const filteredData = all_guides_data.filter(
    (guide: AdviceGuidesDataTypes) => {
      const guideAzurePathArr = guide.azurePath.split("/");
      if (guideAzurePathArr.includes("emergency")) {
        return guide;
      }
    }
  );

  const { guide_name } = useParams();
  console.log(guide_name);
  return (
    <ul className="px-16 my-12 flex flex-col gap-6 overflow-y-auto h-[70vh]">
      {filteredData.map((guide: AdviceGuidesDataTypes) => {
        return <GuideListItem guide={guide} key={guide.id} />;
      })}
    </ul>
  );
}

export default AdviceGuidesEmergencySection;
