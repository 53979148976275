import React, { useContext, useEffect, useRef, useState } from "react";
import Search from "../../../../../../components/form/Search";
import { CountryProfilesContext } from "../../context/country-profiles";
import CountryProfileListItem from "../../components/CountryProfileListItem";
import { CountryProfileDataTypes } from "../../../../../../types/country-profile-data-types";
import Modal from "../../../../../../components/portal/Modal";
import RiskRatingsCountries from "../../../../components/risk-ratings/RiskRatingsCountries";
import { RiskModalContext } from "../../context/risk-modal";
import _ from "lodash";

function Main() {
  const { riskModal, setRiskModal } = useContext(RiskModalContext)!;
  const countryProfiles = useContext(CountryProfilesContext)!;
  const [countrySearch, setCountrySearch] = useState<string>("");

  const [countryProfilesData, setCountryProfilesData] = useState<
    CountryProfileDataTypes[]
  >(countryProfiles.all_country_profiles_data!);

  useEffect(() => {
    if (countrySearch !== "") {
      const normalizedSearchString = _.deburr(countrySearch).toLowerCase();

      const filteredData = countryProfiles.all_country_profiles_data!.filter(
        (country) =>
          _.deburr(country.country)
            .toLowerCase()
            .includes(normalizedSearchString)
      );

      setCountryProfilesData(filteredData);
    } else {
      setCountryProfilesData(countryProfiles.all_country_profiles_data!);
    }
  }, [countrySearch]);

  return (
    <main
      className="bg-white px-12 shadow-lg flex-1 h-fit w-full"
      id="country_profiles_main"
    >
      <div className="flex justify-end">
        <div className="w-full pt-4 max-w-lg">
          <Search placeholder="Country" onSearch={setCountrySearch} />
        </div>
      </div>

      <div className="h-fit w-full">
        <ul className="flex flex-col gap-4 my-8  h-[60vh] overflow-y-auto pr-10">
          {countryProfilesData.map((country, i) => {
            return <CountryProfileListItem key={i} data={country} />;
          })}
        </ul>
      </div>
      <Modal open={riskModal} setOpen={setRiskModal}>
      <RiskRatingsCountries setOpen={setRiskModal} />      
      </Modal>
    </main>
  );
}

export default Main;
