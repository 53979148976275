import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

// Auth
import { Auth, Login, Logout, ProtectedRoute } from "./auth";

// Pages ––– Operational Map
import OperationalMap from "./pages/OperationalMap/index";

// Pages ––– World Search
import WorldSearch from "./pages/WorldSearch";

// Pages ––– Risk
import {
  Home as RiskHome,
  GlobalRiskMap,
  AdviceGuides,
  AirlineRiskMatrix,
  CountryProfiles,
  SecurityAssessments,
  CountryProfile,
  AllCountryProfiles,
} from "./pages/risk";

// Pages ––– User Management
import {
  Home as UserManagementHome,
  ClientUsers,
  RolesList,
  Client,
  AddNewClient,
} from "./pages/user-management";

import UserProfile from "./pages/UserProfile";
// Pages --- Trips
import IndividualTripPage from "./pages/Trips/IndividualTrip";
import AllTripsPage from "./pages/Trips/AllTripsPage";
import UpcomingCheckInsPage from "./pages/Trips/UpcomingCheckIns";

// Pages ––- Sites
import {
  SitesAddCustomOverlayPage,
  SitesAddZone,
  SitesCustomOverlayPage,
  SitesEditCustomOverlay,
  SitesEditZone,
  SitesFacilitiesPage,
  SitesHomePage,
  SitesOutlet,
  SitesZonePage,
} from "./pages/Sites";

// Pages --- Settings
import Settings from "./pages/Settings";
import EmergencyResponse from "./pages/Settings/pages/EmergencyResponse";
import ViewPlans from "./pages/Settings/pages/EmergencyResponse/ViewPlans";
import MassWarning from "./pages/Settings/pages/MassWarning";
import CustomEmails from "./pages/Settings/pages/CustomEmails";
import InvitationEmail from "./pages/Settings/pages/CustomEmails/InvitationEmail";
import PreTripEmail from "./pages/Settings/pages/CustomEmails/PreTripEmail";
import NewTripEmail from "./pages/Settings/pages/CustomEmails/NewTripEmail";
import RealTimeTravellersAffected from "./pages/Settings/pages/CustomEmails/RealTimeTravellersAffected";
import ComplianceReportEmail from "./pages/Settings/pages/CustomEmails/ComplianceReportEmail";
import Facilities from "./pages/Settings/pages/Facilities";

import { OidcConfiguration, OidcProvider } from "@axa-fr/react-oidc";
import CustomEmailsHome from "./pages/Settings/pages/CustomEmails/CustomEmailsHome";
import SettingsMassWarningHome from "./pages/Settings/pages/MassWarning/pages/Home";
import AllGroups from "./pages/Settings/pages/MassWarning/pages/Groups";
import SeeAllEmailTemplates from "./pages/Settings/pages/MassWarning/pages/SeeAllEmailTemplates";
import SeeAllAudioFiles from "./pages/Settings/pages/MassWarning/pages/SeeAllAudioFiles";
import Dashboard from "./pages/Settings/pages/MassWarning/pages/Groups/Dashboard";
import EditGroup from "./pages/Settings/pages/MassWarning/pages/Groups/EditGroup";

// Pages --- Reports
import Reports from "./pages/Reports";
import SummaryHome from "./pages/Reports/pages/Summary/pages/SummaryHome";
import Summary from "./pages/Reports/pages/Summary";
import CreateNewReport from "./pages/Reports/pages/CustomReports/pages/CreateNewReport";
import CustomReports from "./pages/Reports/pages/CustomReports";
import AnnualReport from "./pages/Reports/pages/CustomReports/pages/AnnualReport";
import Cases from "./pages/Reports/pages/Cases";
import ReportsDataProvider from "./pages/Reports/ReportsDataProvider";
import MonthlyReport from "./pages/Reports/pages/CustomReports/pages/MonthlyReport";
import MassWarningIndex from "./pages/Reports/pages/MassWarning";
import MassWarningBase from "./pages/Reports/pages/MassWarning/pages/MassWarningBase";
import MassWarningIndividual from "./pages/Reports/pages/MassWarning/pages/MassWarningIndividual";
import ClientsContextProvider from "./context/clients";
import ClientUsersContextProvider from "./features/user-management/context/client-users";
import UserProfileContextProvider from "./features/user-management/pages/user-profile/context/user-profile";
import CountryProfilesContextProvider from "./features/risk/pages/country-profiles/context/country-profiles";
import CountryProfileSection from "./features/risk/pages/country-profiles/sections/country-profille";
import CountryProfileOverviewSection from "./features/risk/pages/country-profiles/sections/overview";
import CountryProfilePoliticalSection from "./features/risk/pages/country-profiles/sections/political";
import CountryProfileOperationalSection from "./features/risk/pages/country-profiles/sections/operational";
import CountryProfileCrimeSection from "./features/risk/pages/country-profiles/sections/crime";
import CountryProfileTerrorismSection from "./features/risk/pages/country-profiles/sections/terrorism";
import CountryProfileTravelSection from "./features/risk/pages/country-profiles/sections/travel";
import CountryProfileMedicalSection from "./features/risk/pages/country-profiles/sections/medical";
import CountryProfileRegionalInfoSection from "./features/risk/pages/country-profiles/sections/regional-information";
import CountryProfileCulturalConsiderationSection from "./features/risk/pages/country-profiles/sections/cultural-consideration";
import CountryProfileNaturalHazardsSection from "./features/risk/pages/country-profiles/sections/natural-hazards";
import CountryProfileSecurityAdviceSection from "./features/risk/pages/country-profiles/sections/security-advice";
import CountryProfileMapSection from "./features/risk/pages/country-profiles/sections/map";
import AdviceGuidesTravelSection from "./features/risk/pages/advice-guides/sections/travel";
import AdviceGuidesNaturalHazardsSection from "./features/risk/pages/advice-guides/sections/natural-hazards";
import AdviceGuidesMedicalSection from "./features/risk/pages/advice-guides/sections/medical";
import AdviceGuidesEmergencySection from "./features/risk/pages/advice-guides/sections/emergency";
// import { ErrorBoundary } from "react-error-boundary";
import PaginatedClientsContextProvider from "./features/user-management/context/clients";
import ClientContextProvider from "./features/user-management/context/client";
import AdviceGuidesContextProvider from "./features/risk/pages/advice-guides/context/advice-guides";
import AddClient from "./features/user-management/pages/add-client/form/add-client";
import AddDepartments from "./features/user-management/pages/add-client/form/add-departments";
import AddUsers from "./features/user-management/pages/add-client/form/add-users";
import ViewClient from "./features/user-management/pages/client-details/form/view-client";
import ViewDepartments from "./features/user-management/pages/client-details/form/view-departments";
import ViewUsers from "./features/user-management/pages/client-details/form/view-users";
import Authenticating from "./auth/authenticating";
import Loading from "./auth/loading";
import Error from "./auth/error";
import CustomProfiles from "./pages/Settings/pages/CustomProfiles";
import CustomEmailsRecipients from "./pages/Settings/components/custom-emails-recipients";
import IncidentLog from "./pages/RiskV2/IncidentLog";
import MessageLog from "./pages/Reports/messageProvider";
import AirlineRiskUpload from "./pages/Settings/components/airline-risk-matrix";
import ProtectedRouteClient from "./util/ProtectedRouteClient";
import TripCreate from "./pages/TripsV2/create";
import TripEdit from "./pages/TripsV2/edit";
import {CheckInConfiguration} from "./pages/CheckInConfiguration";
import SetBannerAlert from "./pages/Settings/components/banner-alert";
import {AppCompliance} from "./pages/Settings/components/app-compliance";


const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const configuration: OidcConfiguration = {
  client_id: process.env.REACT_APP_OAUTH2_CLIENT_ID || "",
  redirect_uri: window.location.origin + "/authentication/callback",
  silent_redirect_uri:
    window.location.origin + "/authentication/silent-callback",
  scope: "openid profile email offline_access", // offline_access scope allow your client to retrieve the refresh_token
  service_worker_relative_url: "/OidcServiceWorker.js",
  authority: process.env.REACT_APP_OAUTH2_AUTHORITY || "",
  service_worker_only: false,
};

root.render(
  <React.StrictMode>
    <OidcProvider
      configuration={configuration}
      authenticatingComponent={Loading}
      loadingComponent={Authenticating}
      authenticatingErrorComponent={Error}
    >
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route path="" element={<Auth />}>
              <Route path="" element={<Login />} />
            </Route>
            <Route
              path="logout"
              element={
                <ProtectedRoute>
                  <Logout />
                </ProtectedRoute>
              }
            />

            <Route path="/" element={<App />}>
              <Route
                path="user-management"
                element={
                  <ProtectedRoute>
                    <PaginatedClientsContextProvider>
                      <UserManagementHome />
                    </PaginatedClientsContextProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="user-management/add-new-client"
                element={
                  <ProtectedRoute>
                    <AddNewClient />
                  </ProtectedRoute>
                }
              >
                <Route path="" element={<AddClient />} />
                <Route
                  path="add-departments/:clientID"
                  element={<AddDepartments />}
                />
                <Route path="add-users/:clientID" element={<AddUsers />} />
              </Route>
              <Route
                path="user-management/client/:clientID"
                element={
                  <ProtectedRoute>
                    <ClientContextProvider>
                      <Client />
                    </ClientContextProvider>
                  </ProtectedRoute>
                }
              >
                <Route path="edit-client" element={<ViewClient />} />
                <Route path="edit-departments" element={<ViewDepartments />} />
                <Route path="edit-users" element={<AddUsers />} />
              </Route>
              <Route
                path="user-management/client-users/:clientID"
                element={
                  <ProtectedRoute>
                    <ClientUsersContextProvider>
                      <ClientUsers />
                    </ClientUsersContextProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="user-management/roles-list"
                element={
                  <ProtectedRoute>
                    <RolesList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="user-profile/:userID"
                element={
                  <ProtectedRoute>
                    <UserProfileContextProvider>
                      <UserProfile />
                    </UserProfileContextProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="profile"
                element={
                  <ProtectedRoute>
                    <UserProfileContextProvider>
                      <UserProfile />
                    </UserProfileContextProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="operational-map"
                element={
                  <ProtectedRoute>
                    <ClientsContextProvider>
                      <OperationalMap />
                    </ClientsContextProvider>
                  </ProtectedRoute>
                }
              />
              {
                // temp
              }
              <Route
                path="world-search"
                element={
                  <ProtectedRoute>
                    <WorldSearch />
                  </ProtectedRoute>
                }
              />
              <Route
                path="risk"
                element={
                  <ProtectedRoute>
                    <RiskHome />
                  </ProtectedRoute>
                }
              >
                <Route path="global-incident-map" element={<GlobalRiskMap />} />

                <Route
                  path="country-profiles"
                  element={
                    <CountryProfilesContextProvider>
                      <CountryProfiles />
                    </CountryProfilesContextProvider>
                  }
                >
                  <Route path="" element={<AllCountryProfiles />} />

                  <Route path=":country_iso" element={<CountryProfile />}>
                    <Route path="" element={<CountryProfileSection />}>
                      <Route
                        path="overview"
                        element={<CountryProfileOverviewSection />}
                      />
                      <Route
                        path="political"
                        element={<CountryProfilePoliticalSection />}
                      />
                      <Route
                        path="operational"
                        element={<CountryProfileOperationalSection />}
                      />
                      <Route
                        path="personal-security"
                        element={<CountryProfileCrimeSection />}
                      />
                      <Route
                        path="terrorism"
                        element={<CountryProfileTerrorismSection />}
                      />
                      <Route
                        path="travel"
                        element={<CountryProfileTravelSection />}
                      />
                      <Route
                        path="medical"
                        element={<CountryProfileMedicalSection />}
                      />
                      <Route
                        path="regional-information"
                        element={<CountryProfileRegionalInfoSection />}
                      />
                      <Route
                        path="cultural-considerations"
                        element={<CountryProfileCulturalConsiderationSection />}
                      />
                      <Route
                        path="natural-hazards"
                        element={<CountryProfileNaturalHazardsSection />}
                      />
                      <Route
                        path="security-advice"
                        element={<CountryProfileSecurityAdviceSection />}
                      />
                      <Route
                        path="map"
                        element={<CountryProfileMapSection />}
                      />
                    </Route>
                  </Route>
                </Route>

                <Route
                  path="advice-guides"
                  element={
                    <AdviceGuidesContextProvider>
                      <AdviceGuides />
                    </AdviceGuidesContextProvider>
                  }
                >
                  <Route
                    path="travel"
                    element={<AdviceGuidesTravelSection />}
                  />
                  <Route
                    path="natural-hazards"
                    element={<AdviceGuidesNaturalHazardsSection />}
                  />
                  <Route
                    path="medical"
                    element={<AdviceGuidesMedicalSection />}
                  />
                  <Route
                    path="emergency"
                    element={<AdviceGuidesEmergencySection />}
                  />
                </Route>

                <Route
                  path="incident-log"
                  element={
                    <AdviceGuidesContextProvider>
                      <IncidentLog />
                    </AdviceGuidesContextProvider>
                  }
                />

                <Route
                  path="airline-risk-matrix"
                  element={
                  <ProtectedRouteClient>
                  <AirlineRiskMatrix />
                  </ProtectedRouteClient> }
                />

                <Route
                  path="security-assessments"
                  element={<SecurityAssessments />}
                />
              </Route>
              <Route
                path="settings"
                element={
                  <ProtectedRoute>
                    <Settings />
                  </ProtectedRoute>
                }
              >
                <Route path="country-profiles" element={<CustomProfiles />} />
                <Route
                  path="custom-emails-recipients"
                  element={<CustomEmailsRecipients />}
                />
                <Route 
                  path="airline-risk-matrix"
                  element={<AirlineRiskUpload/>}
                 />
                <Route 
                  path="banner-alert"
                  element={<SetBannerAlert/>}
                 />
                <Route
                  path="app-compliance"
                  element={<AppCompliance/>}
                />
                {/* <Route*/}
                {/*  path="emergency-response"*/}
                {/*  element={*/}
                {/*    <UsersDataProvider>*/}
                {/*      <EmergencyResponse />*/}
                {/*    </UsersDataProvider>*/}
                {/*  }*/}
                {/* >*/}
                {/*  <Route*/}
                {/*    path="view-plans"*/}
                {/*    element={*/}
                {/*      <UsersDataProvider>*/}
                {/*        <ViewPlans />*/}
                {/*      </UsersDataProvider>*/}
                {/*    }*/}
                {/*  />*/}
                {/* </Route>*/}
                {/* <Route*/}
                {/*  path="mass-warning"*/}
                {/*  element={*/}
                {/*    <UsersDataProvider>*/}
                {/*      <MassWarning />*/}
                {/*    </UsersDataProvider>*/}
                {/*  }*/}
                {/* >*/}
                {/*  <Route*/}
                {/*    path=""*/}
                {/*    element={*/}
                {/*      <UsersDataProvider>*/}
                {/*        <SettingsMassWarningHome />*/}
                {/*      </UsersDataProvider>*/}
                {/*    }*/}
                {/*  />*/}
                {/*  <Route*/}
                {/*    path="groups"*/}
                {/*    element={*/}
                {/*      <UsersDataProvider>*/}
                {/*        <AllGroups />*/}
                {/*      </UsersDataProvider>*/}
                {/*    }*/}
                {/*  >*/}
                {/*    <Route*/}
                {/*      path=""*/}
                {/*      element={*/}
                {/*        <UsersDataProvider>*/}
                {/*          <Dashboard />*/}
                {/*        </UsersDataProvider>*/}
                {/*      }*/}
                {/*    />*/}
                {/*    <Route*/}
                {/*      path="edit-group"*/}
                {/*      element={*/}
                {/*        <UsersDataProvider>*/}
                {/*          <EditGroup />*/}
                {/*        </UsersDataProvider>*/}
                {/*      }*/}
                {/*    />*/}
                {/*  </Route>*/}
                {/*  <Route*/}
                {/*    path="all-email-templates"*/}
                {/*    element={*/}
                {/*      <UsersDataProvider>*/}
                {/*        <SeeAllEmailTemplates />*/}
                {/*      </UsersDataProvider>*/}
                {/*    }*/}
                {/*  />*/}
                {/*  <Route*/}
                {/*    path="all-audio-files"*/}
                {/*    element={*/}
                {/*      <UsersDataProvider>*/}
                {/*        <SeeAllAudioFiles />*/}
                {/*      </UsersDataProvider>*/}
                {/*    }*/}
                {/*  />*/}
                {/* </Route>*/}
                {/* <Route*/}
                {/*  path="custom-emails"*/}
                {/*  element={*/}
                {/*    <UsersDataProvider>*/}
                {/*      <CustomEmails />*/}
                {/*    </UsersDataProvider>*/}
                {/*  }*/}
                {/* >*/}
                {/*  <Route*/}
                {/*    path="invitation-email"*/}
                {/*    element={*/}
                {/*      <UsersDataProvider>*/}
                {/*        <InvitationEmail />*/}
                {/*      </UsersDataProvider>*/}
                {/*    }*/}
                {/*  />*/}
                {/*  <Route*/}
                {/*    path="pre-trip-email"*/}
                {/*    element={*/}
                {/*      <UsersDataProvider>*/}
                {/*        <PreTripEmail />*/}
                {/*      </UsersDataProvider>*/}
                {/*    }*/}
                {/*  />*/}
                {/*  <Route*/}
                {/*    path="new-trip-email"*/}
                {/*    element={*/}
                {/*      <UsersDataProvider>*/}
                {/*        <NewTripEmail />*/}
                {/*      </UsersDataProvider>*/}
                {/*    }*/}
                {/*  />*/}
                {/*  <Route*/}
                {/*    path="real-time-travellers-affected"*/}
                {/*    element={*/}
                {/*      <UsersDataProvider>*/}
                {/*        <RealTimeTravellersAffected />*/}
                {/*      </UsersDataProvider>*/}
                {/*    }*/}
                {/*  />*/}
                {/*  <Route*/}
                {/*    path="compliance-report-email"*/}
                {/*    element={*/}
                {/*      <UsersDataProvider>*/}
                {/*        <ComplianceReportEmail />*/}
                {/*      </UsersDataProvider>*/}
                {/*    }*/}
                {/*  />*/}
                {/* </Route>*/}
                {/* <Route*/}
                {/*  path="facilities"*/}
                {/*  element={*/}
                {/*    <UsersDataProvider>*/}
                {/*      <Facilities />*/}
                {/*    </UsersDataProvider>*/}
                {/*  }*/}
                {/* />*/}
              </Route>
              <Route
                path="sites"
                element={
                  <ProtectedRoute>
                    <SitesOutlet />
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":client_id?/:facility_id?"
                  element={<SitesHomePage />}
                />
                <Route
                  path="custom-overlay"
                  element={<SitesCustomOverlayPage />}
                />
                <Route
                  path="add-custom-overlay"
                  element={<SitesAddCustomOverlayPage />}
                />
                <Route
                  path="edit-custom-overlay/:id"
                  element={<SitesEditCustomOverlay />}
                />
                <Route path="zones" element={<SitesZonePage />} />
                <Route path="facilities" element={<SitesFacilitiesPage />} />
                <Route path="add-zone" element={<SitesAddZone />} />
                <Route
                  path="edit-zone/:type/:zoneID"
                  element={<SitesEditZone />}
                />
              </Route>
              <Route
                path="trips"
                element={
                  <ProtectedRoute>
                    <AllTripsPage />
                  </ProtectedRoute>
                }
              />

              <Route
                  path="trips/check-in-configuration"
                  element={
                    <ProtectedRoute>
                      <CheckInConfiguration/>

                    </ProtectedRoute>
                  }
              />

              <Route
                path="trips/:id"
                element={
                  <ProtectedRoute>
                    <IndividualTripPage />
                  </ProtectedRoute>
                }
              />


              <Route
                  path="trips/create"
                  element={
                    <ProtectedRoute>
                      <TripCreate />
                    </ProtectedRoute>
                  }
              />

              <Route
                  path="trips/:id/edit"
                  element={
                    <ProtectedRoute>
                      <TripEdit/>
                    </ProtectedRoute>
                  }
              />

              <Route
                path="trips/upcoming-check-ins"
                element={
                  <ProtectedRoute>
                    <UpcomingCheckInsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="reports"
                element={
                  <ProtectedRoute>
                    <ReportsDataProvider>
                      <Reports />
                    </ReportsDataProvider>
                  </ProtectedRoute>
                }
              >
                <Route path="summary" element={<Summary />}>
                  <Route path="" element={<SummaryHome />} />
                </Route>
                <Route path="custom-reports" element={<CustomReports />}>
                  <Route
                    path="create-new-report"
                    element={<CreateNewReport />}
                  />
                  <Route path="annual-report" element={<AnnualReport />} />
                  <Route path="monthly-report" element={<MonthlyReport />} />
                </Route>
                <Route path="mass-warning" element={<MassWarningIndex />}>
                  <Route path="" element={<MassWarningBase />} />
                  <Route path=":id" element={<MassWarningIndividual />} />
                </Route>
                <Route path="cases" element={<Cases />} />
              </Route>
              <Route
                path="messages"
                element={
                  <ProtectedRoute>
                    <ReportsDataProvider>
                      <MessageLog />
                    </ReportsDataProvider>
                  </ProtectedRoute>
                }
              />
            </Route>
          </Routes>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </OidcProvider>
  </React.StrictMode>
);
