import React from "react";
import { Main, SidebarFilter } from "../../../features/sites/pages/home";

function Home() {
  return (
    <div className="w-full h-full gap-4 p-4 grid grid-cols-[auto_1fr]">
      <SidebarFilter />
      <Main />
    </div>
  );
}

export default Home;
