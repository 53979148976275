import React, { ReactNode } from "react";

type SidebarTypes = {
  title: string;
  children?: ReactNode;
  fitContent?: boolean;
};

function Sidebar({ title, children, fitContent = true }: SidebarTypes) {
  return (
    <div className={`px-8 w-full ${fitContent ? "h-fit" : "h-full"}`}>
      <div className="bg-white w-full shadow-md pb-12 px-6 h-full flex flex-col gap-6 overflow-y-auto pt-5">
        <div className="border-b-1 h-12 w-[3/5] flex items-center justify-center ">
          <p className="text-md text-center  block pb-2 font-semibold">
            {title}
          </p>
        </div>

        {children}
      </div>
    </div>
  );
}

export default Sidebar;
