import React from "react";
import {
  Main,
  CountryProfilesSidebar,
} from "../../../../features/risk/pages/country-profiles";
import RiskModalContextProvider from "../../../../features/risk/pages/country-profiles/context/risk-modal";
function AllCountryProfiles() {
  return (
    <div className="flex flex-1">
      <RiskModalContextProvider>
        <Main />
        <CountryProfilesSidebar />
      </RiskModalContextProvider>
    </div>
  );
}

export default AllCountryProfiles;
