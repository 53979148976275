import { getTimezonesForCountry, Timezone } from "countries-and-timezones";
import moment from "moment";
import { useEffect, useState } from "react";
import {getTimezonesForCountryWithOverride, TimezoneOverride} from "./tz";

export const snakeCaseToGeneralCase = (sentence: string): string => {
  return sentence
    .split("_")
    .map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

export const kebabCaseToGeneralCase = (sentence: string): string => {
  return sentence
    .split("-")
    .map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

export const objectToQueryString = (
  obj: object,
  parentKey = ""
): string => {
  const keyValuePairs = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value: any = (obj as any)[key];
      const keyWithParent = parentKey ? `${parentKey}[${key}]` : key;

      if (typeof value === "object") {
        keyValuePairs.push(objectToQueryString(value, keyWithParent));
      } else {
        keyValuePairs.push(
          `${encodeURIComponent(keyWithParent)}=${encodeURIComponent(value)}`
        );
      }
    }
  }

  return keyValuePairs.join("&");
};

export const dateToStringWithDashes = (
  date: Date | string,
  underDash = false
): string => {
  const dateExample = new Date(date);

  // get the components of the date
  const year: number | string = dateExample.getFullYear();
  let month: number | string = dateExample.getMonth() + 1;
  let day: number | string = dateExample.getDate();

  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;

  // form the YYYY-MM-DD string
  if (underDash) return year + "_" + month + "_" + day;
  return year + "-" + month + "-" + day;
};

export const dateToString = (
  date: Date | string,
  showFullDate = true
): string => {
  const d = moment(date);

  if (showFullDate) {
    return d.format("DD/MM/YYYY HH:mm");
  } else {
    return d.format("DD/MM/YYYY");
  }

};
export const dateToStringTZ = (
    date: Date | string,
    timezone: string,
    showFullDate = true
): string => {
  const d = moment(date).tz(timezone);

  if (showFullDate) {
    return d.format("DD/MM/YYYY HH:mm");
  } else {
    return d.format("DD/MM/YYYY");
  }
};

export const dateToHoursAndMinutes = (date: Date | string | null): string => {
  if (!date) return "-";

  return moment(date).format("HH:mm");
};

export const getTimezones = (countryId: string): TimezoneOverride[] => {
  let timeZones: TimezoneOverride[] = [];
  if (countryId) {
    timeZones = getTimezonesForCountryWithOverride(countryId)!;
  }
  return timeZones;
};

export const generateUniqueId = () => {
  const timestamp = new Date().getTime();
  const randomNum = Math.random().toString(36).substring(2, 10);
  return `${timestamp}-${randomNum}`;
};

export const downloadCSV = (
  data: string,
  filename = "download.csv"
): void => {
  const csvContent: string = data;
  const blob: Blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  if ((navigator as any).msSaveBlob) {
    // IE 10+
    (navigator as any).msSaveBlob(blob, filename);
  } else {
    const link: HTMLAnchorElement = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url: string = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const capitalizeFirstLetter = (string: string) => {
  if (string.length === 0) {
    return string;
  }

  const firstLetter = string[0].toUpperCase();
  const capitalizedString = firstLetter + string.slice(1);

  return capitalizedString;
};
