import { useGeofences, useZones } from "../../../services";
import SearchByName from "./SearchByName";
import SearchByClient from "./SearchByClient";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Row from "./Row";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
type TablePropsType = {
  tableHeader: { label: string; icon?: string; id: string }[];
};

function Table({ tableHeader }: TablePropsType) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [mergedData, setMergedData] = useState<any[]>([]);
  const [searchName, setSearchName] = React.useState<string>("");
  const [selectedClientIDs, setSelectedClientIDs] = useState<
    { value: string; label: string }[]
  >([]);
  /**
   * Fetch
   */
  const {
    geofencesQuery: { data: geofences },
    deleteMutation: deleteGeofence,
  } = useGeofences();
  const {
    zonesQuery: { data: zones },
    deleteMutation: deleteZone,
  } = useZones();
  const geofenceArrWithType = geofences.map((geofence) => ({
    ...geofence,
    type: "geofence",
  }));
  const zoneArrWithType = zones.map((zone) => ({ ...zone, type: "zone" }));
  const dataArr = useMemo(() => {
    const arr = [...geofenceArrWithType, ...zoneArrWithType];
    return arr;
  }, [geofenceArrWithType, zoneArrWithType]);

  useEffect(() => {
    if (dataArr) {
      setMergedData(dataArr);
    }
  }, [geofences, zones]);

  /**
   * Filter
   */
  const [filterByNameToggle, setFilterByNameToggle] =
    React.useState<boolean>(false);
  const [filterByClientToggle, setFilterByClientToggle] =
    React.useState<boolean>(false);

  /**
   * Utils
   */

  const handleDeleteGeofence = (id: string) => {
    deleteGeofence.mutate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries("geofences");
      },
    });
  };
  const handleDeleteZone = (id: string) => {
    deleteZone.mutate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries("safety-zones");
      },
    });
  };

  const handleEditGeofence = (id: string) => {
    navigate(`/sites/edit-zone/geofence/${id}`);
  };

  const handleEditZone = (id: string) => {
    navigate(`/sites/edit-zone/safety-zone/${id}`);
  };

  return (
    <div className="text-center flex flex-col gap-6">
      <div className="grid grid-cols-7 bg-desaturated-grey rounded-lg items-center h-20 font-semibold">
        {tableHeader.map((rowItem, i) => {
          return (
            <div
              className={` ${
                i < tableHeader.length - 1 ? "border-r" : null
              } relative`}
              key={i}
            >
              <span>{rowItem.label}</span>
              {rowItem.icon && (
                <button
                  type="button"
                  className="absolute top-2/4 -translate-y-2/4 right-4"
                  onClick={
                    rowItem.id === "name"
                      ? () => setFilterByNameToggle(true)
                      : () => setFilterByClientToggle(true)
                  }
                >
                  <img src={rowItem.icon} alt="" />
                </button>
              )}
              {rowItem.id === "name" ? (
                <SearchByName
                  filterByNameToggle={filterByNameToggle}
                  setFilterByNameToggle={setFilterByNameToggle}
                  setSearchName={setSearchName}
                />
              ) : null}

              {rowItem.id === "client" ? (
                <SearchByClient
                  filterByClientToggle={filterByClientToggle}
                  setFilterByClientToggle={setFilterByClientToggle}
                  setSelectedClientIDs={setSelectedClientIDs}
                  selectedClientIDs={selectedClientIDs}
                />
              ) : null}
            </div>
          );
        })}
      </div>

      <div className="flex flex-col gap-6">
        {mergedData
          .filter((item) => {
            if (searchName === "") return item;
            return item.name.toLowerCase().includes(searchName.toLowerCase());
          })
          .filter((item) => {
            if (selectedClientIDs.length < 1) return item;
            const itemAsOption: { value: string; label: string } = {
              value: item.clientID,
              label: item.name,
            };
            const itemExists = selectedClientIDs.find(
              (selectedClient) => selectedClient.value === itemAsOption.value
            );
            return itemExists && item;
          })
          .map((item) => (
            <Row
              key={item.id}
              data={item}
              handleDelete={
                item.type === "geofence"
                  ? () => handleDeleteGeofence(item.id)
                  : () => handleDeleteZone(item.id)
              }
              handleEdit={
                item.type === "geofence"
                  ? () => handleEditGeofence(item.id)
                  : () => handleEditZone(item.id)
              }
            />
          ))}
      </div>
    </div>
  );
}

export default Table;
