import React, { memo, useCallback, useMemo } from "react";
import { useOperationalMapContext } from "../../providers/OperationalMapProvider";
import { AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from "@vis.gl/react-google-maps";
import { useTimeZoneSelectContext } from "../../providers/TimeZoneSelectProvider";
import { Circle } from "./circle";
import moment from "moment/moment";
import {IncidentDataTypes} from "../../types/incident-data-types";

type IncidentMarkerProps = {
  incident: IncidentDataTypes;
  infoKey: string;
  showInfoWindow: boolean;
}

export const IncidentMarker: React.FC<IncidentMarkerProps> = memo((props) => {
  const ctx = useOperationalMapContext();
  const lat = props.incident.position.coordinates[1];
  const lng = props.incident.position.coordinates[0];
  const [markerRef, marker] = useAdvancedMarkerRef();
  const tzCtx = useTimeZoneSelectContext();

  // Optimize moment formatting
  const formattedDate = useMemo(() => moment.tz(props.incident.startedAt, tzCtx.timeZone).format("DD/MM/YYYY"), [props.incident.startedAt, tzCtx.timeZone]);
  const formattedTime = useMemo(() => moment.tz(props.incident.startedAt, tzCtx.timeZone).format("HH:mm Z z"), [props.incident.startedAt, tzCtx.timeZone]);

  const handleClick = useCallback(() => {
    ctx.setVisibleInfoWindow(props.incident.id);
  }, [ctx, props.incident.id]);

  // Handle out-of-bounds (uncomment if needed)
  // const outOfBounds = useBoundsCheck(lng, lat);
  // if (outOfBounds) return null;

  return (
    <>
    {ctx.showIncidentRadius && <Circle
        onClick={handleClick}
        center={{ lat, lng }}
        strokeColor={"#ff0000"}
        fillColor={"#ff0000"}
        strokeOpacity={0.8}
        fillOpacity={0.35}
        strokeWeight={1}
        radius={2500}
      />}
      <AdvancedMarker
        ref={markerRef}
        position={{ lat, lng }}
        onClick={handleClick}
      >
        <img
          src={"/assets/mapicons/incident.png"}
          width={24}
          height={24}
          alt={"Incident Icon"}
          className="incident-icon"
          style={{
            transform: "translate(0, 50%)",
          }}
        />
      </AdvancedMarker>
      {props.showInfoWindow && (
        <InfoWindow anchor={marker} onClose={() => ctx.setVisibleInfoWindow(null)}>
          <h3 className={"text-center text-lg font-semibold"}>Incident Details</h3>
          <hr />
          <h4 className={"font-semibold text-base mt-2"}>Summary</h4>
          <p className={"text-base"}>{props.incident.summary}</p>
          <h4 className={"font-semibold text-base mt-2"}>Description</h4>
          <p className={"text-base"}>{props.incident.description}</p>
          <div className={"mt-2 grid grid-cols-[1fr,3fr]"}>
            <div className={"text-base font-semibold"}>Severity</div>
            <div className={"text-base capitalize"}>{props.incident.severity}</div>
            <div className={"text-base font-semibold"}>Category</div>
            <div className={"text-base capitalize"}>{props.incident.categoryName}</div>
            <div className={"text-base font-semibold"}>Date/Time</div>
            <div className={"text-base capitalize"}>{formattedDate}</div>
            <div className={"text-base font-semibold"}>Time</div>
            <div className={"text-base capitalize"}>{formattedTime}</div>
            {props.incident.reference && props.incident.reference.length > 0 && (
              <div>
                <a className={"text-base underline font-semibold text-blue"} target="_blank" href={props.incident.reference[0].link} rel="noreferrer">Source</a>
              </div>
            )}
          </div>
        </InfoWindow>
      )}
    </>
  );
});

IncidentMarker.displayName = "IncidentMarker";