import React, { ReactNode, createContext } from "react";

import { ClientDataTypes } from "../../types/client-data-types";
import useGetAllClients from "../../api/clients/useGetAllClients";

type ClientsContextType = {
  all_clients_data: ClientDataTypes[] | any;
  all_clients_status: "idle" | "loading" | "success" | "error";
  all_clients_error: unknown;
};

export const ClientsContext = createContext<ClientsContextType | null>(null);

export default function ClientsContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const allClients = useGetAllClients();

  const value = allClients.all_clients_data?.sort(function (
    a: ClientDataTypes,
    b: ClientDataTypes
  ) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return (
    <ClientsContext.Provider value={allClients}>
      {children}
    </ClientsContext.Provider>
  );
}
