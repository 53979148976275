import { useState, useEffect } from "react";
import { debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";

const values = new Subject();

const useDebounce = (time: number, initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const sub = values.pipe(debounceTime(time)).subscribe(setValue);
    return () => sub.unsubscribe();
  }, [time]);

  const updateValue = (v: any) => values.next(v);

  return [value, updateValue];
};

export default useDebounce;