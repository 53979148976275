import React from "react";

function TripsError({ errorMessage }: { errorMessage: string }) {
  return (
    <div className="flex flex-col text-center">
      <span className="font-semibold text-lg">Unsuccessful</span>
      <span className="capitalize">{errorMessage}</span>
    </div>
  );
}

export default TripsError;
