import EditIcon from "../../../../../../../assets/icons/icon-edit.svg";
import BinIcon from "../../../../../../../assets/icons/icon-bin.svg";
import React, { useState } from "react";
import { DepartmentDataTypes } from "../../../../../../../types/department-data-types";
import { BlueButton } from "../../../../../../../components/ui/Buttons";
import validatePhoneForE164 from "../../../../../../../util/validatePhoneForE164";

type DepartmentValues = {
  name: string;
  phoneNumber?: string;
  alertConfig: {
    emailAddress?: string;
    sendToClient?: boolean;
  };
};

export default function DepartmentEntry({
  department,
  handleDeleteDepartment,
  changeDepartment,
}: {
  department: DepartmentDataTypes;
  handleDeleteDepartment: (id: string) => void;
  changeDepartment: (id: string, changedDepartment: DepartmentValues) => void;
}) {
  const [editingState, setEditingState] = useState<boolean>(false);
  const [phoneNumberErr, setPhoneNumberErr] = useState<boolean>(false);
  const [departmentValues, setDepartmentValues] = useState<DepartmentValues>({
    name: department.name,
    phoneNumber: department.phoneNumber,
    alertConfig: {
      emailAddress: department.alertConfig.emailAddress,
      sendToClient: department.alertConfig.sendToClient,
    },
  });

  const saveDepartment = (id: string) => {
    if (departmentValues.phoneNumber) {
      if (!validatePhoneForE164(departmentValues.phoneNumber)) {
        setPhoneNumberErr(true);
        return;
      } else {
        setPhoneNumberErr(false);
      }
    }
    changeDepartment(id, departmentValues);
    setEditingState(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name !== "email") {
      setDepartmentValues({
        ...departmentValues,
        [name]: value,
      });
    } else {
      setDepartmentValues({
        ...departmentValues,
        alertConfig: {
          emailAddress: value,
          sendToClient: value ? true : false,
        },
      });
    }
  };

  return (
    <div className="flex justify-between items-start gap-10 border border-light-grey border-opacity-30 rounded-lg p-6 h-fit">
      <div className="flex flex-col gap-4 w-full">
        <input
          type="text"
          className="shadow-md h-10 rounded-md w-full px-4"
          name="name"
          required
          placeholder="Enter Department"
          disabled={!editingState}
          defaultValue={departmentValues.name}
          value={departmentValues.name}
          onChange={handleInputChange}
        />
        {/* prettier-ignore*/}
        {editingState || department.phoneNumber ? (
          <div>
            <input
              type="text"
              className="shadow-md h-10 rounded-md w-full px-4"
              name="phoneNumber"
              required
              placeholder="Enter Phone Number"
              disabled={!editingState}
              defaultValue={departmentValues.phoneNumber}
              value={departmentValues.phoneNumber}
              onChange={handleInputChange}
            />
            {phoneNumberErr && (
              <span className="text-red inline-block mt-2">
                Please check the format of phone number (e.g +4401010101010)
              </span>
            )}
          </div>
        ) : null}
        {editingState || department.alertConfig.emailAddress ? (
          <input
            type="email"
            className="shadow-md h-10 rounded-md w-full px-4"
            name="email"
            placeholder="Enter Email"
            disabled={!editingState}
            defaultValue={departmentValues.alertConfig.emailAddress}
            value={departmentValues.alertConfig.emailAddress}
            onChange={handleInputChange}
          />
        ) : null}
      </div>

      <div className="flex items-center gap-2 max-w-[90px]">
        {editingState && (
          <div className="w-[90px]">
            <BlueButton
              onClick={() => saveDepartment(department.id!)}
              text="Save"
            />
          </div>
        )}
        {!editingState && (
          <button
            className="h-10 w-10 rounded-md bg-light-light-grey flex items-center justify-center"
            onClick={() => setEditingState(true)}
          >
            <img src={EditIcon} alt="" />
          </button>
        )}
        {!editingState && (
          <button
            className="h-10 w-10 rounded-md bg-light-light-grey flex items-center justify-center"
            onClick={() => handleDeleteDepartment(department.id!)}
            type="button"
          >
            <img src={BinIcon} alt="" className="h-2/4" />
          </button>
        )}
      </div>
    </div>
  );
}
