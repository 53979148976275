import React, { useRef } from "react";
// @ts-ignore
import downloadFacilitiesCSVSample from "./facilities-and-SBAs-template-import.csv";
import { useLocation } from "react-router-dom";
import { BlueButton, WhiteButton } from "../../../../components/ui/Buttons";
function DownloadImportFacilitiesSample() {
  const pathname = useLocation().pathname.split("/");
  const ref = useRef<HTMLAnchorElement>(null);
  return (
    <>
      <a
        ref={ref}
        href={downloadFacilitiesCSVSample}
        download={true}
        className="absolute opacity-0 -z-10"
      >
        {}
      </a>

      {pathname.includes("facilities") ? (
        <BlueButton
          text="Download Sample File"
          onClick={() => ref.current!.click()}
        />
      ) : (
        <WhiteButton
          text="Download Sample File"
          onClick={() => ref.current!.click()}
        />
      )}
    </>
  );
}

export default DownloadImportFacilitiesSample;
