import React, { RefObject, useState } from "react";
import { languages } from "../../constants/languages";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { KeyboardArrowDown } from "@mui/icons-material";
function MultipleLanguageSelect({
  selectRef,
  selectedOptions,
  label,
}: {
  selectRef: RefObject<HTMLSelectElement>;
  selectedOptions: string[];
  label?: boolean;
}) {
  const [open, setOpen] = useState(false);
  useOnClickOutside(selectRef, () => setOpen(false));
  return (
    <div className="h-fit">
      {label && (
        <label htmlFor="additional_language" className="opacity-80">
          Multiple Language
        </label>
      )}
      <button
        className="h-10 w-full rounded-md shadow-md relative flex justify-between pl-4 items-center"
        onClick={() => setOpen(true)}
        type="button"
      >
        Select Multiple Languages
        <KeyboardArrowDown />
      </button>
      <select
        multiple
        className={`absolute h-52 p-4 shadow-md rounded-md z-10 ${
          label ? "top-20" : "top-12"
        } left-0 right-0 ${open ? "flex" : "hidden"}`}
        name={"multiple-languages-select"}
        ref={selectRef}
      >
        {languages.map((lang) => {
          const isSelected = selectedOptions?.includes(lang.code)
            ? true
            : false;
          return (
            <option value={lang.code} selected={isSelected} key={lang.code}>
              {lang.name}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default MultipleLanguageSelect;
