import React from "react";
import { Main } from "../../../features/risk/pages/global-risk-map";

import IncidentDetailsModalProvider from "../../../context/incident-details-modal";
function GlobalRiskMap() {
  return (
    <div className="w-full relative h-full">
      <IncidentDetailsModalProvider>
        <Main />
      </IncidentDetailsModalProvider>
    </div>
  );
}

export default GlobalRiskMap;
