import {WorldSearchRecord} from "../operational-map/components/Map/hooks/useWorldSearchMarkers";
import React, {useCallback, useMemo} from "react";
import {AdvancedMarker, InfoWindow, Marker, useAdvancedMarkerRef, useMarkerRef} from "@vis.gl/react-google-maps";
import {useOperationalMapContext} from "../../providers/OperationalMapProvider";
import {useTimeZoneSelectContext} from "../../providers/TimeZoneSelectProvider";
import {useNavigate} from "react-router-dom";
import {startCase} from "lodash";
import moment from "moment/moment";
import {BlueButton} from "../../components/ui/Buttons";
import {useBoundsCheck} from "./bounds";


type TripMarkerProps = {
  record: WorldSearchRecord;
  showInfoWindow: boolean;
}

const getIconPNG = (type: string) => {
  switch (type) {
    case "travel:flight":
      return "flight.png"
    case "travel:taxi":
      return "taxi.png"
    case "travel:ferry":
      return "ferry.png"
    case "travel:train":
      return "train.png"
    case "travel:rental":
      return "car_rental.png"
    case "travel:private_car":
      return "car.png"
    case "travel:bus":
      return "bus.png"
    case "travel:other":
      return "travel_unknown.png"
    case "travel:undefined":
      return "travel_unknown.png"

    case "accommodation:hotel":
      return "hotel.png"
    case "accommodation:private_property":
      return "house_rental.png"
    case "accommodation:home":
      return "house.png"
    case "accommodation:other":
      return "accommodation_unknown.png"
    case "accommodation:undefined":
      return "accommodation_unknown.png"
  }

  return "question_mark"
}



export const TripMarker: React.FC<TripMarkerProps> = React.memo((props) => {

  const [markerRef, marker] = useMarkerRef();
  const ctx = useOperationalMapContext();
  const tzCTX = useTimeZoneSelectContext();
  const { record } = props;

  // Memoize the position object to avoid recalculating unless coordinates change
  const position = useMemo(() => {
    const lat = record.location.point.coordinates[1];
    const lng = record.location.point.coordinates[0];
    return { lat, lng };
  }, [record.location.point.coordinates]);

  // Memoize the icon PNG based on the subtype to avoid recalculating unless subtype changes
  const iconPNG = useMemo(() => getIconPNG(record.subtype), [record.subtype]);

  const openInfo = useCallback(() => {
    ctx.setVisibleInfoWindow(record.id);
  }, [ctx, record.id]);

  // const outOfBounds = useBoundsCheck(
  //   position.lng,
  //   position.lat,
  // )
  //
  // if(outOfBounds){
  //   return null;
  // }


  return (
    <>
      <Marker
        ref={markerRef}
        position={position}
        onClick={openInfo}
        icon={{
          url: `/assets/mapicons/${iconPNG}`,
        }}
      />

      {props.showInfoWindow && (
        <InfoWindow anchor={marker} onClose={() => ctx.setVisibleInfoWindow(null)}>
          <h3 className={"text-center text-lg font-semibold"}>Trip Details</h3>
          <hr/>
          <h4 className={"font-semibold text-base mt-2 "}>User Details</h4>
          <div className={"mt-2 grid grid-cols-[1fr,3fr] gap-x-4"}>
            <div className={"text-base font-semibold"}>User</div>
            <div className={"text-base capitalize"}>{record.userName}</div>
            <div className={"text-base font-semibold"}>Email</div>
            <div className={"text-base capitalize"}><a href={`mailto:${record.userEmail}`}>{record.userEmail}</a></div>
            <div className={"text-base font-semibold"}>Phone Number/s</div>
            <div className={"text-base capitalize"}>{record.userPhoneNumbers.map((p, i) => (
              <a key={`${i}_${p.number}`} className={"block"} href={`tel:${p.number}`}>{p.number}</a>))}</div>
          </div>
          <h4 className={"font-semibold text-base mt-2 "}>Source Details</h4>
          <div className={"mt-2 grid grid-cols-[1fr,3fr] gap-x-4"}>
            <div className={"text-base font-semibold"}>Type</div>
            <div className={"text-base capitalize"}>{record.type}</div>
            <div className={"text-base font-semibold"}>Subtype</div>
            <div
              className={"text-base capitalize"}>{startCase(record.subtype.split(":")[0])} &ndash; {startCase(record.subtype.split(":")[1])}</div>
            <div className={"text-base font-semibold"}>Start</div>
            <div className={"text-base capitalize"}>
              {moment.tz(record.startTime, tzCTX.timeZone).format("DD/MM/YYYY HH:mm Z z")}
            </div>
            <div className={"text-base font-semibold"}>End</div>
            <div className={"text-base capitalize"}>
              {moment.tz(record.endTime, tzCTX.timeZone).format("DD/MM/YYYY HH:mm Z z")}
            </div>
            <div className={"text-base font-semibold"}>Country</div>
            <div className={"text-base capitalize"}>{record.location.countryName}</div>

            {record.metadata && Object.keys(record.metadata).map(k => (
              <>
                <div className={"text-base font-semibold"}>{startCase(k)}</div>
                <div className={"text-base capitalize"}>{record.metadata[k] || "–"}</div>
              </>
            ))}

          </div>

          <h4 className={"font-semibold text-base mt-2 "}>Trip Details</h4>
          <div className={"mt-2 grid grid-cols-[1fr,3fr] gap-x-4"}>
            <div className={"text-base font-semibold"}>Dates</div>
            <div className={"text-base capitalize"}>
              {moment.tz(record.trip.startTime, tzCTX.timeZone).format("DD/MM/YYYY")} – {moment.tz(record.trip.endTime, tzCTX.timeZone).format("DD/MM/YYYY")}
            </div>
          </div>


          <div className={"flex flex-col gap-2 mt-2"}>
            <BlueButton text={"Show Trip"} onClick={() => {
              window.open(`/trips?id=${record.tripID}`, "_blank");
            }}/>
            <BlueButton text={"Show World Search"} onClick={() => {
              window.open(`/world-search?tripID=${record.tripID}`)
            }}/>
          </div>

        </InfoWindow>
      )}
    </>
  );
});

TripMarker.displayName = "TripMarker"
