import React from "react";
import FormsContextProvider from "../../../features/sites/context/forms-context";
import { Main } from "../../../features/sites/pages/zones";

function Zones() {
  return (
    <FormsContextProvider>
      <div className="h-full w-full flex flex-1 my-8 relative">
        <Main />
      </div>
    </FormsContextProvider>
  );
}

export default Zones;
