import React, { FormEvent, useContext, useRef, useState } from "react";
import InputText from "../../../../../../components/form/InputText";
import Modules from "../../components/modules";
import Departments from "../../components/departments";
import Users from "../../components/users";
import Customisation from "../../components/customisation";
import { ClientContext } from "../../../../context/client";
import useGetAllClientDepartments from "../../../../../../api/client-departments/useGetAllClientDepartments";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import api from "../../../../../../api/axiosConfig";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
function Main() {
  const location = useLocation();
  const locationPathNameArr = location.pathname.split("/");
  const [formStage, setFormStage] = useState<
    "client" | "departments" | "users"
  >("client");

  const [successReq, setSuccessReq] = useState<boolean>(false);
  const [failedReq, setFailedReq] = useState<boolean>(false);
  const nameRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const usersCSVInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const handleUploadUsersFile = () => {
    const file = usersCSVInputRef.current!.files![0];
    const formData = new FormData();

    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    api.post("/users-bulk-create", formData, config);
  };

  return (
    <div className=" bg-white shadow-md p-10 h-fit mb-10">
      <div className="flex py-4  text-md w-full pr-10 flex-col gap-8 pb-8">
        <div className="flex gap-12">
          <span
            className={`font-semibold text-lg ${
              !locationPathNameArr.includes("edit-departments") &&
              !locationPathNameArr.includes("edit-users")
                ? "opacity-100"
                : "opacity-50"
            }`}
          >
            Edit Client
          </span>
          <NavigateNextIcon />
          <span
            className={`font-semibold text-lg ${
              locationPathNameArr.includes("edit-departments")
                ? "opacity-100"
                : "opacity-50"
            }`}
          >
            Edit Departments
          </span>
          <NavigateNextIcon />
          <span
            className={`font-semibold text-lg ${
              locationPathNameArr.includes("edit-users")
                ? "opacity-100"
                : "opacity-50"
            }`}
          >
            Edit Users
          </span>
        </div>
        <hr className="bg-light-light-grey h-[1px] w-full" />
      </div>

      <div className="">
        <Outlet />
      </div>
    </div>
  );
}

export default Main;
