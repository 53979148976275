import React, { FormEvent, useEffect, useRef, useState } from "react";
import useGetIncidentTypes from "../../../../api/incident-types/useGetIncidentTypes";
import CheckboxWrapper from "./checkbox-wrapper";
import IncidentTypesDataTypes from "../../../../types/incident-types-data-types";
import { BlueButton } from "../../../../components/ui/Buttons";
import { SiteBasedAlertDataTypes } from "../../../../types/site-based-alerts-data-type";
import api from "../../../../api/axiosConfig";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import SearchableSelect, { SearchableSelectOption } from "../../../../components/form-v2/searchable-select";
function EditForm({ sbaData }: { sbaData: SiteBasedAlertDataTypes }) {
  const [data, setData] = useState(sbaData);
  const [name, setName] = useState("");
  const [radiusM, setRadiusM] = useState<string>("");
  const formRef = useRef<HTMLFormElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const alertRadiusRef = useRef<HTMLInputElement>(null);
  const incidentTypes = useGetIncidentTypes();
  const queryClient = useQueryClient();
  useEffect(() => {
    setName(sbaData.name);
  }, [sbaData.name]);

  useEffect(() => {
    setRadiusM(sbaData.radiusM.toString());
  }, [sbaData.radiusM]);
  const navigate = useNavigate();
  const handleEdit = (e: FormEvent) => {
    e.preventDefault();
    const formData = new FormData(formRef.current!);
    const severity = formData.getAll("severity");
    const mainSubTypesID = formData.getAll("sub-type");
    const types = mainSubTypesID;
    const data: {
      name: string;
      facilityID: string;
      clientID: string;
      radiusM: number;
      incidentType: FormDataEntryValue[];
      position: {
        type: string;
        coordinates: [number, number];
      };
      severity: {
        unknown: boolean;
        insignificant: boolean;
        limited: boolean;
        moderate: boolean;
        major: boolean;
        extreme: boolean;
      };
    } = {
      name: nameRef.current!.value,
      facilityID: sbaData.facilityID!,
      clientID: sbaData.clientID!,
      radiusM: parseFloat(alertRadiusRef.current!.value),
      incidentType: types,
      position: {
        type: sbaData.position.type,
        coordinates: [
          sbaData.position.coordinates[0],
          sbaData.position.coordinates[1],
        ],
      },
      severity: {
        unknown: severities.some(item => item.value === "unknown"),
        insignificant: severities.some(item => item.value === "insignificant"),
        limited: severities.some(item => item.value === "limited"),
        moderate: severities.some(item => item.value === "moderate"),
        major: severities.some(item => item.value === "major"),
        extreme: severities.some(item => item.value === "extreme"),
      },
    };

    api
      .put(`sba/${sbaData.facilityID}/${sbaData.id}`, data)
      .then((res) => {
        queryClient.invalidateQueries(["sba", sbaData.id]);
        queryClient.invalidateQueries("facility-sba");
        queryClient.invalidateQueries("sba");

        navigate("/sites/facilities");
      })
      .catch((err) => console.error(err));
  };


  // @ts-ignore
  const [severities, setSeverities] = useState<SearchableSelectOption<string>[]>(Object.keys(sbaData.severity).filter((k) => sbaData.severity[k]).map((k) => {
    return {
      key: k,
      value: k,
      label: k.charAt(0).toUpperCase() + k.slice(1)
    }
  }))

  return (
    <form className="flex gap-8 flex-col " action={"sites"} ref={formRef}>
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <span className="text-red font-semibold">*</span>
          <input
            name={"name"}
            type="text"
            placeholder="Site Name"
            className="h-10 w-full rounded-md shadow-md px-4"
            required
            ref={nameRef}
            value={name}
            onChange={() => setName(nameRef.current!.value)}
          />
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <span className="text-red font-semibold">*</span>
          <input
            name={"alertRadius"}
            type="text"
            placeholder="Alert Radius (m)"
            className="h-10 w-full rounded-md shadow-md px-4"
            required
            ref={alertRadiusRef}
            value={radiusM}
            onChange={() => setRadiusM(alertRadiusRef.current!.value)}
          />
        </div>
      </div>

      <div className="flex flex-col gap-8">
        <div className="flex items-center gap-2">
          <span className="text-red font-semibold">*</span>
          <div className="h-10 w-full px-4 flex items-center opacity-50">
            <p>Incident Types:</p>
          </div>
        </div>
        {incidentTypes.data?.data.map((type: IncidentTypesDataTypes) => (
          <CheckboxWrapper
            incidentType={type}
            key={type.id}
            sbaData={sbaData}
          />
        ))}
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <span className="text-red font-semibold">*</span>
          <div className="h-10 w-full px-4 flex items-center opacity-50">
            <p>Incident Severities:</p>
          </div>
        </div>
        <SearchableSelect
          multiple={true}
          label="Select Severity"
          options={[
            {value: "unknown", label:"Unknown", key:"unknown"},
            {value: "insignificant", label:"Insignificant", key:"insignificant"},
            {value: "limited", label:"Limited", key:"limited"},
            {value: "moderate", label:"Moderate", key:"moderate"},
            {value: "major", label:"Major", key:"major"},
            {value: "extreme", label:"Extreme", key:"extreme"},

          ]}
          search={false}
          localSearch={false}
          selected={severities}
          onChange={(s) => {setSeverities(s)}}
        
        />
      </div>

      <div className="flex mx-auto flex-col gap-4 my-6 w-1/3">
        <BlueButton text="Save" type="submit" onClick={handleEdit} />
      </div>
    </form>
  );
}

export default EditForm;
