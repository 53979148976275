import { useQuery } from "react-query";
import api from "../axiosConfig";
import { AdviceGuidesDataTypes } from "../../types/advice-guides-type";

export default function useGetAllAdviceGuides() {
  const fetchAdviceGuides = async (page: number) => {
    return await api
      .get(`files/advice-guide?page=${page}`)
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  const adviceGuidesQuery = useQuery("advice-guides", () =>
    fetchAdviceGuides(1)
  );

  const adviceGuidesQueryData = adviceGuidesQuery.data?.data.items;

  const fetchAllAdviceGuides = async () => {
    const totalAdviceGuides = adviceGuidesQuery.data?.data.total;
    const guidesPerPage = adviceGuidesQuery.data?.data.items.length;
    const totalPagesCalc = Math.ceil(totalAdviceGuides / guidesPerPage);
    const pageFetches = Array.from({ length: totalPagesCalc }, (v, i) =>
      fetchAdviceGuides(i + 1)
    );

    return await Promise.all(pageFetches);
  };

  const { data, status, error } = useQuery({
    queryKey: "all-advice-guides",
    queryFn: () => fetchAllAdviceGuides(),
    enabled: !!adviceGuidesQueryData,
  });
  const extractGuidesFromEachPage = () => {
    if (status !== "success") return [];

    const arr: AdviceGuidesDataTypes[] = [];
    data.map((page) => {
      const items = page!.data.items;
      items?.forEach((item: AdviceGuidesDataTypes) => {
        arr.push(item);
      });
    });

    return arr;
  };
  const extractedData = extractGuidesFromEachPage();

  return {
    all_guides_data: extractedData,
    all_guides_status: status,
    all_guides_error: error,
  };
}
