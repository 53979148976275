import {ContentModal, ModalLayout} from "../../../../../components/ui/Modals";
import {BlueButton} from "../../../../../components/ui/Buttons";
import {dateToString,} from "../../../../../util/helpers";
import ReactDatePicker from "react-datepicker";
import InputText from "../../../../../components/form/InputText";
import Select from "../../../../../components/form/Select";
import {countries} from "../../../../../constants/countries";
import React, {RefObject, useContext, useEffect, useState} from "react";
import {getTimezonesForCountry, Timezone} from "countries-and-timezones";
import {NewCheckIn, TripsContext} from "../../../context/trips";
import {UserContext} from "../../../../../context/user";
import TextAreaEdit from "./TextArea";
import {getTimezonesForCountryWithOverride, TimezoneOverride} from "../../../../../util/tz";

export default function NewCheckInPopUp({
                                          closeCheckInPopUp,
                                          saveAndClose,
                                          errorMessage,
                                        }: {
  errorMessage: string,
  closeCheckInPopUp: (e: React.SyntheticEvent) => void;
  saveAndClose: (data: NewCheckIn) => void;
}) {
  const {newCheckIn, setNewCheckIn} = useContext(TripsContext);
  const userContext = useContext(UserContext);

  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [destinationTimezones, setDestinationTimezones] = useState<string[]>(
      []
  );
  const [isValid, setIsValid] = useState<boolean>(true);
  const [gracePeriodError, setGracePeriodError] = useState("");

  const validateForm = () => {
    let formIsValid = true;

    if (!newCheckIn?.checkInAt || !newCheckIn?.gracePeriod || !newCheckIn?.location.countryISO) {
      formIsValid = false;
    }

    const gracePeriodValue = parseInt(newCheckIn?.gracePeriod?.toString() || "0");
    if (isNaN(gracePeriodValue) || gracePeriodValue > 60) {
      setGracePeriodError("Grace period must be at most 60 minutes");
      formIsValid = false;
    } else {
      setGracePeriodError("");
    }

    setIsValid(formIsValid);
  };

  useEffect(() => {
    validateForm();
  }, [newCheckIn]);

  const handleCountriesChange = (target: RefObject<HTMLInputElement>) => {
    const {value} = target.current!;

    const countryId = countries.find((country) => country.name === value)?.code;

    setNewCheckIn!((prevState) => ({
      ...prevState,
      location: {
        ...prevState.location,
        countryISO: countryId || "",
      },
    }));

    let timezones: TimezoneOverride[] = [];

    if (countryId) {
      timezones = getTimezonesForCountryWithOverride(countryId)!;
    }

    setDestinationTimezones(timezones!.map((timezone) => timezone.name));
  };

  const handleTimezoneChange = (target: RefObject<HTMLInputElement>) => {
    const {value} = target.current!;
    setNewCheckIn!((prevState) => ({
      ...prevState,
      location: {
        ...prevState.location,
        timezone: value,
      },
    }));
  };

  const handleDateChange = (date: Date) => {
    setNewCheckIn!((prevState) => ({
      ...prevState,
      checkInAt: date,
    }));
  };

  const handleGracePeriodChange = (
      event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {value} = event.target;
    setNewCheckIn!((prevState) => ({
      ...prevState,
      gracePeriod: value,
    }));
  };

  const handleNotesChange = (
      event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const {value} = event.target;
    setNewCheckIn!((prevState) => ({
      ...prevState,
      notes: value,
    }));
  };

  const filterPassedTime = (time: Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
      <ModalLayout close={closeCheckInPopUp}>
        <ContentModal closeButton={closeCheckInPopUp}>
          <div className="px-10 py-[30px] flex flex-col items-start w-fit">
            <div className="font-bold text-center self-center w-[85%] border-b pb-5">
              Add Check-In
            </div>
            <div className="mt-10 flex">
              <div className="flex relative">
                <div className="flex flex-col w-[200px]">
                  <label htmlFor="checkInDate" className="text-sm underline font-medium text-gray-700 mb-2">Check-In
                    Date</label>
                  <div className="flex relative ml-3">
                    <span className="text-red absolute top-2/4 -translate-y-2/4 -left-3">*</span>
                    <div
                        className={`h-10 px-4 rounded-md shadow-md w-[200px] border outline-none w-full flex items-center ${
                            !newCheckIn?.checkInAt
                                ? "text-light-grey border-none "
                                : "text-grey  border-grey"
                        }`}
                        onClick={() => setShowDatePicker(!showDatePicker)}
                    >
                      {!newCheckIn?.checkInAt
                          ? "Date"
                          : dateToString(newCheckIn!.checkInAt!)}
                    </div>
                  </div>
                </div>
                {showDatePicker && (
                    <div className="absolute left-0 -bottom-4 z-40 transform translate-y-full w-[330px]">
                      <ReactDatePicker
                          inline
                          isClearable
                          required
                          selected={newCheckIn?.checkInAt}
                          onChange={(date) => handleDateChange(date!)}
                          showTimeSelect
                          filterTime={filterPassedTime}
                          dateFormat="MMMM d, yyyy h:mm aa"
                          name="arrivalTime"
                      />
                    </div>
                )}
              </div>
              <div className="flex ml-7 w-[190px] flex-col">
                <label htmlFor="gracePeriod" className="text-sm underline font-medium text-gray-700 mb-2">Grace
                  Period</label>
                <InputText
                    required={true}
                    placeholder="Grace Period"
                    name="gracePeriod"
                    value={`${newCheckIn?.gracePeriod}`}
                    onChange={(event) => handleGracePeriodChange(event)}
                />
                {gracePeriodError && (
                    <div className="text-red text-xs mt-2">
                      {gracePeriodError}
                    </div>
                )}
              </div>
            </div>
            <div className="mt-10 flex pl-3">
              <div className="flex w-[190px] flex-col">
                <label htmlFor="countrySelect"
                       className="text-sm underline font-medium text-gray-700 mb-2">Country</label>
                <Select
                    required
                    value={
                      countries.find(
                          (country) =>
                              country.code.toLowerCase() ===
                              newCheckIn?.location.countryISO.toLowerCase()
                      )?.name
                    }
                    options={countries}
                    title="Country"
                    name="destination"
                    onSelect={handleCountriesChange}
                />
              </div>
            </div>
            <div className="mt-10 w-full pl-3 flex-col">
              <label htmlFor="notes" className="text-sm font-medium underline text-gray-700 mb-2">Notes</label>
              <TextAreaEdit
                  maxCharCount={300}
                  value={`${newCheckIn?.notes}`}
                  onChange={(event) => handleNotesChange(event)}
              />
            </div>
            <div className="mt-[60px] w-[200px] self-center">
              <BlueButton
                  text="Save"
                  disabled={!isValid}
                  onClick={() => {
                    if (newCheckIn) {
                      saveAndClose(newCheckIn);
                    }
                  }}
              />
            </div>
            <div className={"w-full mt-4"}><p className={"text-center text-red"}>{errorMessage}</p></div>

          </div>
        </ContentModal>
      </ModalLayout>
  );
}
