import React, { useCallback } from "react";
import { useDebounce } from "use-debounce";
import { useOperationalMapContext } from "../../providers/OperationalMapProvider";
import { dbscanGeo, zoomLevelToEpsilon } from "./math";
import { useQuery } from "react-query";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { UserMarker } from "./usermarker";

const MemoizedUserMarker = React.memo(UserMarker);
const MemoizedAdvancedMarker = React.memo(AdvancedMarker);

export const UsersClustered: React.FC = () => {
  const ctx = useOperationalMapContext();
  const [debouncedZoom] = useDebounce(ctx.zoom, 300);

  const getItemsClustered = useCallback(() => {
    if (!debouncedZoom || !ctx.userQuery.data) return null;

    return dbscanGeo(
      ctx.userQuery.data,
      zoomLevelToEpsilon[debouncedZoom],
      1,
      (item) => ({
        lat: item.lat,
        lng: item.lng,
      })
    );
  }, [debouncedZoom, ctx.userQuery.data]);

  const queryKey = ["users-clustered", debouncedZoom, ctx.client, ctx.selectedUserLocationTypes, ctx.userQuery.data];
  const itemsQuery = useQuery(queryKey, getItemsClustered, {
    staleTime: 10000,
    cacheTime: 300000,
  });

  if (itemsQuery.isLoading) return <div>Loading...</div>;
  if (itemsQuery.isError) return <div>Error loading users</div>;
  if (!itemsQuery.data || itemsQuery.data.clusters.length === 0) return <div>No users available</div>;

  return (
    <>
      {itemsQuery.data.clusters.map((c) => {
        if (c.items.length > 1) {
          return (
            <MemoizedAdvancedMarker
              key={`${c.center.lat}_${c.center.lng}`}
              position={{ lat: c.center.lat, lng: c.center.lng }}
            >
              <div className="w-[24px] h-[24px] flex rounded-full justify-center items-center bg-green text-white font-semibold shadow">
                {c.items.length}
              </div>
            </MemoizedAdvancedMarker>
          );
        }

        const l = c.items[0];

        return (
          <MemoizedUserMarker
            key={l.key}
            infoKey={l.key}
            lat={l.lat}
            lng={l.lng}
            users={l.users}
            multiple={l.multiple}
            showInfoWindow={ctx.visibleInfoWindow === l.key}
          />
        );
      })}
    </>
  );
};