import React from "react";
import Main from "../../../features/sites/pages/facilities/layout/Main";

function Facilities() {
  return (
    <div className="h-full w-full flex flex-1 my-8 relative">
      <Main />
    </div>
  );
}

export default Facilities;
