import React, {useEffect, useRef, useState} from "react";
import {isArray} from "lodash";

type ActionSelectProps = {
  label: string
  children: React.ReactNode
}

export const ActionSelect: React.FC<ActionSelectProps> = ({label, children}) => {


  const [show, setShow] = useState(false)
  const items = isArray(children) ? children : [children]

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShow(false)
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  },);


  const action = (callback: () => void) => {
    callback()
    setShow(false)
  }

  if (!items[0]) {
    return null
  }

  return <div ref={ref} className={"relative overflow-visible"}>
    <button
        type={"button"}
        className={"w-full min-w-fit h-10 text-white text-sm rounded-md shadow-lg flex items-center gap-6 justify-center text-center hover:bg-opacity-80 transition-opacity bg-blue"}
        onClick={() => setShow(!show)}
    >{label}</button>

    {show &&
        <div className={"absolute left-0 right-0 top-12 bg-white rounded-md shadow-2xl p-4 z-50 flex flex-col gap-4"}>
          {items.map((item, index) => <div key={index} className={""}>
            <button onClick={() => {
              action(item.props.onSelect)
            }} className={"text-center w-full"}>{item.props.label}</button>
          </div>)}


        </div>}

  </div>
}

type ActionProps = {
  label: string
  onSelect: () => void
}

export const Action: React.FC<ActionProps> = () => null
