import React, { createContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import useGetReportsSummary from "../../../../../api/reports/useGetReportsSummary";
import { ReportsSummary } from "../../../../../types/reports-data-types";
import useGetReportsSummaryCSV from "../../../../../api/reports/useGetReportsSummaryCSV";

interface ReportsContextInterface {
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  selectedClients: string[];
  setSelectedClients: React.Dispatch<React.SetStateAction<string[]>>;
  summaryData: ReportsSummary;
  summaryStatus: "idle" | "loading" | "success" | "error";
  csvType: "tracking" | "checkin" | "emergency" | "all";
  setCSVType: React.Dispatch<"tracking" | "checkin" | "emergency" | "all">;
  getSummaryInfo: () => void;
  firstSearch: boolean;
  csvLoading: boolean;
  setCSVLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

type SummaryType = "tracking" | "checkin" | "emergency" | "all";

export const ReportsSummaryContext = createContext<ReportsContextInterface>(
  {} as ReportsContextInterface
);

export default function ReportsSummaryProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const [csvType, setCSVType] = useState<SummaryType>("all");
  const [firstSearch, setFirstSearch] = useState<boolean>(false);
  const [enableFetchData, setEnableFetchData] = useState<boolean>(false);
  const [enableFetchCSV, setEnableFetchCSV] = useState<boolean>(false);
  const [csvLoading, setCSVLoading] = useState<boolean>(false);

  const { data, status, refetch } = useGetReportsSummary(
    startDate ? startDate.toISOString() : "",
    endDate ? endDate.toISOString() : "",
    selectedClients,
    enableFetchData
  );

  const getSummaryInfo = () => {
    refetch();
    setEnableFetchData(true);
    setFirstSearch(true);
  };

  return (
    <ReportsSummaryContext.Provider
      value={{
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        selectedClients,
        setSelectedClients,
        summaryData: data,
        summaryStatus: status,
        getSummaryInfo,
        csvType,
        setCSVType,
        firstSearch,
        csvLoading,
        setCSVLoading,
      }}
    >
      {children}
    </ReportsSummaryContext.Provider>
  );
}
