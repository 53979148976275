import React from "react";
import { useNavigate } from "react-router-dom";
import MapApp from "../../components/Map";

function Main() {
  const navigate = useNavigate();
  return (
    <div className="w-full h-full px-4">
      <header className="my-8 mx-8">
        <p className="flex gap-6 flex-1 ">
          <span
            className="opacity-50 cursor-pointer"
            onClick={() => navigate("/sites")}
          >
            Sites
          </span>
          <span className="opacity-50">{">"}</span>
          <span>Add Custom Overlay</span>
        </p>
      </header>
      <MapApp />
    </div>
  );
}

export default Main;
