import React, {createContext, ReactNode, useState} from "react";
import {Legs, TripsType} from "../../types/trips-data-types";
import useGetTrips from "../../../../api/trips/useGetTrips";
import {EditCheckIn} from "../../types/check-ins-data-types";
import {Moment} from "moment";

interface tripLegObj {
  origin: {
    country: string;
    city: string;
    timezone: string;
  };
  destination: {
    country: string;
    city: string;
    timezone: string;
  };
}

interface CheckInAlertsInfo {
  option?: string;
  gracePeriod?: number;
  checkInTime?: Date | null;
  checkInTimeSecond?: Date | null;
  notes?: string;
}

type GeneratedCheckIn = {
  date: Moment;
  time: Moment;
  country: string;
  timezone: string;
  notes?: string;
};

export type NewCheckIn = {
  checkInAt: Date | null;
  gracePeriod: string | number;
  location: {
    countryISO: string;
    timezone: string;
    city: string; // optional
  };
  notes: string;
};

type NewTripData = {
  id?: string;
  clientID: string;
  userID: string;
  legs: Legs[];
  gracePeriod: number;
  notes: string;
  scheduledCheckIns: GeneratedCheckIn[];
};

type TripsContextType = {
  tripsData: { items: TripsType[]; page: number; total: number };
  tripsStatus: "idle" | "loading" | "success" | "error";
  tripsError: unknown;
  tripsPage: number;
  checkInAlertsInfo: CheckInAlertsInfo;
  setCheckInAlertsInfo: React.Dispatch<React.SetStateAction<CheckInAlertsInfo>>;
  selectedTrip: NewTripData | null;
  setSelectedTrip: React.Dispatch<React.SetStateAction<NewTripData | null>>;
  setTripsPage: React.Dispatch<React.SetStateAction<number>>;
  generatedCheckIns: GeneratedCheckIn[];
  setGeneratedCheckIns: React.Dispatch<
      React.SetStateAction<GeneratedCheckIn[]>
  >;
  newTripData: NewTripData;
  setNewTripData: React.Dispatch<React.SetStateAction<NewTripData>>;
  tripsFilters: TripsFilters;
  setTripsFilters: React.Dispatch<React.SetStateAction<TripsFilters>>;
  fetchFilteredTrips: () => void;
  usersDropdownListPage: number;
  setUsersDropdownListPage: React.Dispatch<React.SetStateAction<number>>;
  popUpUsersDropdownListPage: number;
  setPopUpUsersDropdownListPage: React.Dispatch<React.SetStateAction<number>>;
  selectedCheckIn: EditCheckIn | null;
  setSelectedCheckIn: React.Dispatch<React.SetStateAction<EditCheckIn | null>>;
  newCheckIn: NewCheckIn;
  setNewCheckIn: React.Dispatch<React.SetStateAction<NewCheckIn>>;
  isNewTrip: boolean;
  setIsNewTrip: React.Dispatch<React.SetStateAction<boolean>>;
};

type TripsFilters = {
  clientID?: string;
  departmentID?: string[];
  userID?: string[];
  startDate?: string;
  endDate?: string;
  originCountryISO?: string[];
  destinationCountryISO?: string[];
};

export const TripsContext = createContext<Partial<TripsContextType>>({});

export default function TripsContextProvider({
                                               children,
                                             }: {
  children: ReactNode;
}) {
  const [tripsPage, setTripsPage] = useState(1);
  const [selectedCheckIn, setSelectedCheckIn] = useState<EditCheckIn | null>(
      null
  );
  const [newCheckIn, setNewCheckIn] = useState<NewCheckIn>({
    checkInAt: null,
    gracePeriod: "",
    location: {city: "", countryISO: "", timezone: ""},
    notes: "",
  });
  const [checkInAlertsInfo, setCheckInAlertsInfo] = useState<CheckInAlertsInfo>(
      {
        option: "",
        gracePeriod: 0,
        checkInTime: null,
        checkInTimeSecond: null,
        notes: "",
      }
  );
  const [isNewTrip, setIsNewTrip] = useState<boolean>(true);
  const [tripsFilters, setTripsFilters] = useState<TripsFilters>({
    clientID: "",
    departmentID: [],
    userID: [],
    startDate: "",
    endDate: "",
    originCountryISO: [],
    destinationCountryISO: [],
  });
  const [newTripData, setNewTripData] = useState<NewTripData>({
    clientID: "",
    gracePeriod: 0,
    notes: "",
    legs: [
      {
        origin: {
          countryISO: "",
          city: "",
          addressLine: "",
          state: "",
          timezone: "",
        },
        destination: {
          countryISO: "",
          city: "",
          addressLine: "",
          state: "",
          timezone: "",
        },
        travelType: "",
        status: "",
        flightDetails: "",
        departureTime: null,
        arrivalTime: null,
      },
      {
        origin: {
          countryISO: "",
          city: "",
          addressLine: "",
          state: "",
          timezone: "",
        },
        destination: {
          countryISO: "",
          city: "",
          addressLine: "",
          state: "",
          timezone: "",
        },
        travelType: "",
        status: "",
        flightDetails: "",
        departureTime: null,
        arrivalTime: null,
      },
    ],
    scheduledCheckIns: [],
    userID: "",
  });
  const [selectedTrip, setSelectedTrip] = useState<NewTripData | null>(null);
  const [usersDropdownListPage, setUsersDropdownListPage] = useState<number>(1);
  const [popUpUsersDropdownListPage, setPopUpUsersDropdownListPage] =
      useState<number>(1);
  const [generatedCheckIns, setGeneratedCheckIns] = useState<
      GeneratedCheckIn[]
  >([]);

  const {data, status, error, refetch} = useGetTrips(
      tripsPage,
      tripsFilters.clientID !== "All Clients" ? tripsFilters.clientID : "",
      tripsFilters.departmentID,
      tripsFilters.userID,
      tripsFilters.startDate,
      tripsFilters.endDate,
      tripsFilters.originCountryISO,
      tripsFilters.destinationCountryISO
  );

  const fetchFilteredTrips = () => {
    setTripsPage(1);
    refetch();
  };

  return (
      <TripsContext.Provider
          value={{
            tripsData: data,
            tripsStatus: status,
            tripsError: error,
            tripsPage,
            setTripsPage,
            checkInAlertsInfo,
            setCheckInAlertsInfo,
            selectedTrip,
            setSelectedTrip,
            generatedCheckIns,
            setGeneratedCheckIns,
            newTripData,
            setNewTripData,
            tripsFilters,
            setTripsFilters,
            fetchFilteredTrips,
            usersDropdownListPage,
            setUsersDropdownListPage,
            popUpUsersDropdownListPage,
            setPopUpUsersDropdownListPage,
            selectedCheckIn,
            setSelectedCheckIn,
            newCheckIn,
            setNewCheckIn,
            isNewTrip,
            setIsNewTrip,
          }}
      >
        {children}
      </TripsContext.Provider>
  );
}
