import React from "react"


type BasicTextProps = {
  label?: string
  value: string
  onChange?: (value: string) => void
  type?: React.HTMLInputTypeAttribute
  placeholder?: string
  center?: boolean
  disabled?: boolean
}

const BasicText: React.FC<BasicTextProps> = (props) => {


  return <div className={"flex flex-col relative"}>
    {props.label != undefined && <label className={"mb-1"}>{props.label}</label>}
    {props.placeholder && props.type == "date" && (
        <div className={"absolute top-0 bottom-0 left-4 flex items-center opacity-80"}>{props.placeholder}</div>
    )}
    <input
        className={`h-10 w-full shadow-md rounded-md max-h-20 px-4 outline-none bg-white ${props.center ? "text-center" : ""} ${props.type == "date" ? "text-right" : ""}`}
        value={props.value}
        onChange={(e) => props.onChange && props.onChange(e.target.value)}
        type={props.type || "text"}
        placeholder={props.placeholder}
        disabled={props.disabled}
    />

  </div>
}

export default BasicText

type BasicNumberProps = {
  label?: string
  onChange?: (value: number) => void
  type: "number"
  placeholder?: string
  value: number
  min: number
  max: number
  disabled?: boolean
}

export const BasicNumber: React.FC<BasicNumberProps> = (props) => {


  const handleChange = (val: number) => {
    if (props.onChange) {
      props.onChange(val)
    }
  }

  const handleOnInput = (e: any) => {
    if (e.target.value > props.max) {
      e.target.value = props.max
      handleChange(props.max)
    }

    if (e.target.value < props.min) {
      e.target.value = props.min
      handleChange(props.min)
    }
  }

  return <div className={"flex flex-col relative"}>
    {props.label != undefined && <label className={"mb-1"}>{props.label}</label>}
    <input
        min={props.min}
        max={props.max}
        onBlur={handleOnInput}
        className={"h-10 w-full shadow-md rounded-md max-h-20 px-4 outline-none bg-white text-center"}
        value={props.value}
        onChange={(e) => handleChange(Number(e.target.value))}
        type={"number"}
        placeholder={props.placeholder}
        disabled={props.disabled}
    />

  </div>
}