import React from "react";
import { useNavigate } from "react-router-dom";
import { BlueButton } from "../../../../../components/ui/Buttons";
import Table from "../components/Table";
import { tableHeader } from "../tableHeader";
function Main() {
  const navigate = useNavigate();
  return (
    <div className="h-full w-full">
      <header className="flex justify-between mx-12 pb-4">
        <p className="flex gap-6 flex-1">
          <span
            className="opacity-50 cursor-pointer"
            onClick={() => navigate("/sites")}
          >
            Sites
          </span>
          <span className="opacity-50">{">"}</span>
          <span>Zone List</span>
        </p>
        <div className="w-56">
          <BlueButton
            text="+ Add New Zone"
            onClick={() => navigate("/sites/add-zone")}
          />
        </div>
      </header>

      <div className="px-12 py-6 bg-white">
        <Table tableHeader={tableHeader} />
      </div>
    </div>
  );
}

export default Main;
