import React from "react";
import SearchableSelect, {SearchableSelectOption} from "./index";
import Icon from "../../../assets/icons/exclamation-warning.svg";


type RiskLevelSelectProps = {
  value: number | undefined;
  setValue: (value: number | undefined) => void;
}

export const RiskLevelSelect: React.FC<RiskLevelSelectProps> = (props) => {

  const options = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const componentOptions = options.map((o) => {
    return {
      value: o,
      key: String(o),
      label: String(o),
    }
  })

  const selected: SearchableSelectOption<number> | undefined = componentOptions.find(o => o.value === props.value)

  function handleChange(values: SearchableSelectOption<number>[]) {

    if (values.length == 0) {
      props.setValue(undefined)
      return
    }

    props.setValue(values[0].value)
  }

  return <SearchableSelect
      icon={Icon}
      label={"Minimum Risk Level"}
      multiple={false} options={componentOptions} selected={selected ? [selected] : []} search={false}
      localSearch={false}
      onChange={handleChange}/>
}