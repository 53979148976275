import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ClientSelectSingle} from "../../../../components/form-v2/searchable-select/ClientSelect";
import BasicText from "../../../../components/form-v2/basic-text";
import moment from "moment";
import {useTimeZoneSelectContext} from "../../../../providers/TimeZoneSelectProvider";
import {BlueButton} from "../../../../components/ui/Buttons";
import {Client} from "../../../../types/userManagement";
import SearchableSelect, {SearchableSelectOption} from "../../../../components/form-v2/searchable-select";
import {apiV2} from "../../../../api/axiosConfig";
import {PaginatedResponse} from "../../../../types/PaginatedResponse";
import {Trip, TripWithDetails} from "../../../TripsV2/types";


const disableTypeOptions: SearchableSelectOption<string>[] = [
  {
    value: "no_app_install",
    key: "no_app_install",
    label: "No App Install Detected",
    search: "",
  },
  {
    value: "admin_override",
    key: "admin_override",
    label: "Admin Manually Disabled",
    search: "",
  }
]

export const AppCompliance: React.FC = () => {

  const tzCtx = useTimeZoneSelectContext();

  const pageSize = 10

  const [fromDate, setFromDate] = useState(moment().startOf("month").format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().endOf("month").format("YYYY-MM-DD"));
  const [downloading, setDownloading] = useState(false);
  const [generatingReport, setGeneratingReport] = useState(false);
  const [client, setClient] = useState<Client>();
  const [disableTypes, setDisabledTypes] = useState<SearchableSelectOption<string>[]>([]);

  const [current, setCurrent] = useState<number>();
  const [total, setTotal] = useState<number>();


  useEffect(() => {
    const to = moment(toDate, "YYYY-MM-DD")
    const from = moment(fromDate, "YYYY-MM-DD")
    if(from.isAfter(to)){
      setToDate(fromDate)
    }

  }, [toDate, fromDate]);

  const getParams = (page: number) => {
    const req = new Map()

    req.set("page", page)
    req.set("limit", 5);
    req.set("startTime", moment.tz(fromDate, tzCtx.timeZone).toISOString())
    req.set("endTime", moment.tz(toDate, tzCtx.timeZone).toISOString())
    if(client){
      req.set("clientID", client.id)
    }

    req.set("disableGeneration", "true")

    if(disableTypes.length != 0){
      req.set("disableGenerationReasons", disableTypes.map(v => v.value).join(","));
    } else {
      req.set("disableGenerationReasons", disableTypeOptions.map(v => v.value).join(","));
    }

    return Object.fromEntries(req)
  }

  const fetchTrips = async (page = 1): Promise<PaginatedResponse<TripWithDetails>> => {
    const params = getParams(page);
    const res = await apiV2.get<PaginatedResponse<TripWithDetails>>("trips", {params})
    return res.data
  }

  function escapeCSVValue(value: string|number) {
    if (typeof value === "string") {
      // Escape double quotes by doubling them and wrap the value in quotes if it contains commas, quotes, or newlines
      value = value.replace(/"/g, "\"\"");
      if (value.includes(",") || value.includes("\"") || value.includes("\n")) {
        value = `"${value}"`;
      }
    }
    return value;
  }

  const loading = useMemo(() => {
    return downloading || generatingReport
  }, [downloading, generatingReport])

  const downloadReport = async () => {
    setDownloading(true);
    let i = 1

    const results = []

    while(true){
      const res = await fetchTrips(i)
      setTotal(res.total)
      results.push(...res.items)
      setCurrent(results.length)

      if(i * pageSize > res.total){
        break;
      }

      i += 1;
    }

    setDownloading(false);
    setGeneratingReport(true);

    const rows = []

    rows.push([
      "Trip ID",
      "Client Name",
      "Department Name",
      "User Name",
      "Trip Start",
      "Trip End",
      "Reason",
    ].map(escapeCSVValue).join(","))

    results.forEach((r) => {
      rows.push([
        r.id,
        r.clientName,
        r.departmentName,
        r.userName,
        moment.tz(r.startTime, tzCtx.timeZone).format("DD/MM/YYYY HH:mm Z z"),
        moment.tz(r.endTime, tzCtx.timeZone).format("DD/MM/YYYY HH:mm Z z"),
        r.checkInSettings.disableGenerationReason,
      ].map(escapeCSVValue).join(","))
    })

    const csv = rows.join("\n")

    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", "app-compliance-report.csv");
    a.click();
    window.URL.revokeObjectURL(url);

    setGeneratingReport(false);
  }



  return (
    <div className="flex flex-col items-center justify-center p-10 space-y-4">
      <ClientSelectSingle client={client} onChange={setClient} />
      <div className={"flex w-full gap-4"}>
        <div className={"w-1/2"}>
          <BasicText type={"date"} value={fromDate} placeholder={"From"} onChange={setFromDate} />
        </div>
        <div className={"w-1/2"}>
          <BasicText type={"date"} value={toDate} placeholder={"To"} onChange={setToDate}/>
        </div>
      </div>
      <SearchableSelect label={"Reason"} multiple={true} options={disableTypeOptions} selected={disableTypes} search={false} localSearch={false} onChange={setDisabledTypes}/>
      <BlueButton text={"Download Report"} loading={loading} onClick={downloadReport}/>
      {downloading && <div>
        Downloading: {current} / {total}
      </div>}

      {generatingReport && <div>
          Generating report
      </div>}
    </div>
  )
}


