import React, { useState } from "react";

import DropDown from "../../../../../components/form/DropDown";
import Checkbox from "../../../../../components/form/Checkbox";
import { BlueButton } from "../../../../../components/ui/Buttons";

export default function IncludeInputsBox() {
  const [showInputs, setShowInputs] = useState<string[]>([]);

  const handleShowInputs = (name: string) => {
    if (showInputs.includes(name)) {
      setShowInputs(showInputs.filter((input) => input !== name));
    } else {
      setShowInputs([...showInputs, name]);
    }
  };

  return (
    <div className="grid grid-cols-4 gap-2">
      <DropDown title="App Plus">
        <div className="mb-2">
          <Checkbox
            name="userNumbers"
            checked={false}
            value="userNumbers"
            label="User numbers"
            onClick={() => {}}
          />
        </div>
        <div className="mb-2">
          <Checkbox
            name="checking"
            checked={false}
            value="checking"
            label="Checking"
            onClick={() => {}}
          />
        </div>
        <div className="mb-2">
          <Checkbox
            name="tracking"
            checked={false}
            value="tracking"
            label="Tracking"
            onClick={() => {}}
          />
        </div>
        <div className="mb-2">
          <Checkbox
            name="emergencyAlerts"
            checked={false}
            value="emergencyAlerts"
            label="Emergency Alerts"
            onClick={() => {}}
          />
        </div>
        <div className="mb-2">
          <Checkbox
            name="overdueCheckins"
            checked={false}
            value="overdueCheckins"
            label="Overdue Checkins"
            onClick={() => {}}
          />
        </div>
        <div className="mt-5 m-auto w-[90%]">
          <BlueButton text="Save" onClick={() => {}} />
        </div>
      </DropDown>
      <DropDown title="Travel Tracking">
        <div className="mb-2">
          <Checkbox
            name="travellers"
            checked={false}
            value="travellers"
            label="Travellers"
            onClick={() => {}}
          />
        </div>
        <div className="mb-2">
          <Checkbox
            name="trips"
            checked={false}
            value="trips"
            label="Trips"
            onClick={() => {}}
          />
        </div>
        <div className="mb-2">
          <Checkbox
            name="pnrs"
            checked={false}
            value="pnrs"
            label="PNRs"
            onClick={() => {}}
          />
        </div>
        <div className="mb-2">
          <Checkbox
            name="overOrUnderUsage"
            checked={false}
            value="overOrUnderUsage"
            label="Over or Under Usage"
            onClick={() => {}}
          />
        </div>
        <div className="mb-2">
          <Checkbox
            name="exceptions"
            checked={false}
            value="exceptions"
            label="Exceptions"
            onClick={() => {}}
          />
        </div>
        <div className="mt-5 m-auto w-[90%]">
          <BlueButton text="Save" onClick={() => {}} />
        </div>
      </DropDown>
      <DropDown title="Mass Warning">
        <div className="mb-2">
          <Checkbox
            name="massNotificationsSent"
            checked={false}
            value="massNotificationsSent"
            label="Mass Notifications Sent"
            onClick={() => {}}
          />
        </div>
        <div className="mb-2">
          <Checkbox
            name="respondees"
            checked={false}
            value="respondees"
            label="Respondees (%)"
            onClick={() => {}}
          />
        </div>
        <div className="mb-2">
          <Checkbox
            name="app"
            checked={false}
            value="app"
            label="App"
            onClick={() => {}}
          />
        </div>
        <div className="mb-2">
          <Checkbox
            name="sms"
            checked={false}
            value="sms"
            label="SMS"
            onClick={() => {}}
          />
        </div>
        <div className="mb-2">
          <Checkbox
            name="email"
            checked={false}
            value="email"
            label="Email"
            onClick={() => {}}
          />
        </div>
        <div className="mb-2">
          <Checkbox
            name="calls"
            checked={false}
            value="calls"
            label="Calls"
            onClick={() => {}}
          />
        </div>
        <div className="mt-5 m-auto w-[90%]">
          <BlueButton text="Save" onClick={() => {}} />
        </div>
      </DropDown>
      <DropDown title="Cases">
        <div className="mb-2">
          <Checkbox
            name="total"
            checked={false}
            value="total"
            label="Total"
            onClick={() => {}}
          />
        </div>
        <div className="mb-2">
          <Checkbox
            name="open"
            checked={false}
            value="open"
            label="Open"
            onClick={() => {}}
          />
        </div>
        <div className="mb-2">
          <Checkbox
            name="summary"
            checked={false}
            value="summary"
            label="Summary"
            onClick={() => {}}
          />
        </div>

        <div className="mt-5 m-auto w-[90%]">
          <BlueButton text="Save" onClick={() => {}} />
        </div>
      </DropDown>
    </div>
  );
}
