import React, { useContext, useEffect, useRef, useState } from "react";
import { IncidentDetailsModalContext } from "../../../../../../context/incident-details-modal";
import IncidentDetails from "../../../../../../components/IncidentDetails";
import { IncidentDataTypes } from "../../../../../../types/incident-data-types";
import { generateMap, generateMapSearchBox } from "../../../../../../util/map";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import PurplePin from "../../../../../../assets/icons/pin-purple.png";
import OrangePin from "../../../../../../assets/icons/pin-orange.png";
import WhitePin from "../../../../../../assets/icons/pin-white.png";
import RedPin from "../../../../../../assets/icons/pin-red.png";
import GreenPin from "../../../../../../assets/icons/pin-green.png";
import YellowPin from "../../../../../../assets/icons/pin-yellow.png";
import { useSearchParams } from "react-router-dom";

type MapPropTypes = {
  zoom: number;
  center?: [number, number];
  incidents: IncidentDataTypes[];
};

function Map({ zoom, center = [0, 0], incidents }: MapPropTypes) {
  const [map, setMap] = useState<google.maps.Map>();
  const [searchParams] = useSearchParams();
  const filterByType = searchParams.getAll("type");

  const ref = useRef<HTMLDivElement>(null);
  const mapCenter = { lng: center[0], lat: center[1] };
  const input = document.getElementById("pac-input") as HTMLInputElement;
  const {
    incidentDetails,
    incidentsModal,
    setIncidentDetails,
    setIncidentsModal,
  } = useContext(IncidentDetailsModalContext)!;

  const markersRef = useRef<google.maps.Marker[]>([]);
  const markersClusterRef = useRef<MarkerClusterer>();
  useEffect(() => {
    /**
     * This below initialises the map
     */
    const map = generateMap(ref, mapCenter, zoom);
    setMap(map);
    generateMapSearchBox(input, map);
  }, []);
  useEffect(() => {
    if (!map) return;
    const handleMarkerClick = (lng: number, lat: number) => {
      const markerIncident: IncidentDataTypes | undefined = incidents?.find(
        (incident: IncidentDataTypes) =>
          incident.position.coordinates[0] === lng &&
          incident.position.coordinates[1] === lat
            ? incident
            : null
      );

      if (!markerIncident || markerIncident === undefined) {
        return console.error("Failed to get this incident.");
      } else {
        setIncidentDetails(markerIncident);
        setIncidentsModal(true);
      }
    };
    const incidentMarkers = incidents?.map((incident: IncidentDataTypes) => {
      const lng = incident.position.coordinates[0];
      const lat = incident.position.coordinates[1];
      // this below is where a marker will be placed and an icon representing it
      const marker = new google.maps.Marker({
        position: { lat: lat, lng: lng },
        clickable: true,
        icon: {
          url:
            incident.severity === "extreme"
              ? PurplePin
              : incident.severity === "moderate"
              ? RedPin
              : incident.severity === "limited"
              ? YellowPin
              : incident.severity === "insignificant"
              ? GreenPin
              : incident.severity === "major"
              ? OrangePin
              : WhitePin,
          scaledSize: new google.maps.Size(40, 40),
        },
      });
      const markerLat = marker.getPosition()?.lat();
      const markerLng = marker.getPosition()?.lng();
      marker.addListener("click", () => {
        handleMarkerClick(markerLng!, markerLat!);
      });
      return marker;
    });
    markersRef.current.forEach((m) => m.setMap(null));
    markersRef.current = [];
    markersRef.current = incidentMarkers;

    markersClusterRef.current?.setMap(null);
    markersClusterRef.current = undefined;
    const markerCluster = new MarkerClusterer({
      markers: incidentMarkers,
      map,
    });
    markersClusterRef.current = markerCluster;
  }, [map, filterByType, incidents]);

  useEffect(() => {
    if (!map) return;
    markersRef.current.forEach((m) => m.setMap(map));
    markersClusterRef.current?.setMap(map);
  }, [markersRef.current]);

  return (
    <>
      <div ref={ref} className="h-full w-full"></div>
      {incidentDetails && incidentsModal && (
        <div className=" absolute top-4 left-4 h-fit w-3/4 flex flex-col gap-8 overflow-y-auto px-8 bg-white items-center shadow-lg  max-w-xl rounded-lg z-50">
          <IncidentDetails incident={incidentDetails} />
        </div>
      )}
    </>
  );
}

export default Map;
