import React, { useMemo, Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

type Breadcrumbs = {
  name: string;
  url: string;
};

export default function Breadcrumbs() {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumbs[]>([]);

  const urlToBreadcrumb = useMemo((): Breadcrumbs[] => {
    const urlParts = location.pathname.split("/").filter((part) => part !== "");
    let currentUrl = "";

    const settingsPart = urlParts.shift();

    return urlParts.map((part, index) => {
      currentUrl += `/${settingsPart}/${part}`;
      const name = part
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      return {
        name,
        url: currentUrl,
      };
    });
  }, [location.pathname]);

  useEffect(() => {
    setBreadcrumbs(urlToBreadcrumb);
  }, [urlToBreadcrumb]);

  return (
    <nav>
      <ul className="list-unstyled flex">
        {breadcrumbs.map((breadcrumb, index) => (
          <Fragment key={index}>
            <li>
              {index < breadcrumbs.length - 1 ? (
                <Link
                  to={breadcrumb.url}
                  className="text-light-grey hover:text-grey"
                >
                  {breadcrumb.name}
                </Link>
              ) : (
                <span className="text-grey">{breadcrumb.name}</span>
              )}
            </li>
            {index < breadcrumbs.length - 1 && <li className="px-10">{">"}</li>}
          </Fragment>
        ))}
      </ul>
    </nav>
  );
}
