import React, { useContext, useState } from "react";
import UserStatusCardDropDown from "../UserStatusCardDropDown";
import { UserActivityDataTypes } from "../../../../types/user-activity-data-types";

import UserInformation from "./UserInformation";
import typeBasedTileColor from "../../constants/typeBasedTileColor";
import { userActivityDisplayLabel } from "../../../../types/user-activity";
import { FilterMapContext } from "../../context/filter-map";

function UserStatusCard({
  userData,
  sidebarType,
}: {
  userData: UserActivityDataTypes;
  sidebarType: "non-emergency" | "emergency";
}) {
  const [tileColor] = useState(typeBasedTileColor(userData.type));
  const [dropDown, setDropDown] = useState<boolean>(false);

  const [visible, setVisible] = useState(true);

  
  const { filters } = useContext(FilterMapContext);

  if(filters.clientID  && filters.clientID != "all" && filters.clientID != userData.clientID){
    return null;
  }

  return (
    <>
      {visible && (
        <div className="relative w-72 rounded-xl">
          <UserInformation
            setDropDown={setDropDown}
            dropDown={dropDown}
            userData={userData}
          />
          {/* <Circle type={userData.type} /> */}
          <div
            className={`w-28 h-28 rounded-full bg-${
              tileColor!.borderColor
            } bg-opacity-30 p-2 absolute -top-8 -right-8 z-10 `}
          >
            <div
              className={`w-full h-full rounded-full bg-${
                tileColor!.borderColor
              } bg-opacity-40 p-2`}
            >
              <div
                className={`w-full h-full rounded-full bg-${
                  tileColor!.circleColor
                } flex items-center justify-center`}
              >
                {userData.type && (
                  <span
                    className={`font-semibold text-${
                      tileColor?.circleColor === "white" ? "grey" : "white"
                    } text-xs whitespace-pre-wrap text-center capitalize`}
                  >
                    {userActivityDisplayLabel(userData)}
                  </span>
                )}
              </div>
            </div>
          </div>
          <UserStatusCardDropDown
            key={userData.id}
            dropDown={dropDown}
            userData={userData}
            sidebarType={sidebarType}
            setVisible={setVisible}
          />
        </div>
      )}
    </>
  );
}

export default UserStatusCard;
