import moment from "moment";
import api from "../../../api/axiosConfig";
import convertDateToUTC from "../../../util/convertDateToUTC";
import { useSearchParams } from "react-router-dom";
import { useInfiniteQuery } from "react-query";
import { useEffect, useState } from "react";
import { IncidentDataTypes } from "../../../types/incident-data-types";

export function useWeeklyIncidents() {
  const [items, setItems] = useState<IncidentDataTypes[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const sevenDaysAgoDateFromToday = moment().subtract(7, "days").format();
  const sevenDaysAgoDateFromTodayToUTC = convertDateToUTC(
    sevenDaysAgoDateFromToday
  );
  const filterByType = searchParams.getAll("type");
  const filterbyCategories = searchParams.getAll("subtypes");
  const defaultSevenDaysAgo = convertDateToUTC(moment().subtract(7, "days").format());

  useEffect(() => {
    if (!searchParams.get("startTime")) {
      setSearchParams((params) => {
        params.set("startTime", defaultSevenDaysAgo);
        return params;
      });
    }
  }, [defaultSevenDaysAgo, searchParams, setSearchParams]);

  const filterByTime = searchParams.get("startTime") || defaultSevenDaysAgo;
  

  const fetchIncidents = (page: number) =>
    api
      .get(`/incidents?page=${page}`, {
        params: {
          startTime: filterByTime,
          rpp: 200,
          severity: filterByType.join(),
          typeIDs: filterbyCategories.join()
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => console.error(err));

  const {
    data,
    isLoading,
    isError,
    error,
    status,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ["weekly-incidents", filterByType.join(), filterByTime],
    ({ pageParam = 1 }) => fetchIncidents(pageParam),
    {
      getNextPageParam: (lastPage: any, allPages) => {
        if (lastPage.hasMore) {
          return allPages.length + 1;
        }
        return undefined;
      },
    }
  );

  useEffect(() => {
    if (!hasNextPage) return;
    fetchNextPage();
  }, [data, hasNextPage]);

  useEffect(() => {
    if (hasNextPage || !data) return;
    const flatItems = data.pages.flatMap((page) => page.items);
    setItems(flatItems);
  }, [data, hasNextPage]);

  return {
    data: items,
    isLoading,
    isError,
    error,
    status,
    hasNextPage,
  };
}
