type Params = Record<string, string | number>
type Headers = Record<string, string>
export const request = async <ResponseType>(
    method: string,
    url: string,
    params?: Params,
    headers?: Headers,
    body?: object
): Promise<ResponseType> => {

  const queryParams = new URLSearchParams();

  if (params) {
    for (const key in params) {
      if (params[key] !== undefined) {
        queryParams.append(key, String(params[key]));
      }
    }
  }

  const fullUrl = params ? `${url}?${queryParams.toString()}` : url;

  const response = await fetch(fullUrl, {
    method: method,
    headers: headers,
    body: JSON.stringify(body)
  });

  if (!response.ok) {
    throw await response.json();
  }

  return await response.json();
}

export const apiGET = async <ResponseType>(
    url: string,
    params?: Params,
    headers?: Headers,
): Promise<ResponseType> => {
  return request<ResponseType>("GET", url, params, headers)
}

export const apiPOST = async <ResponseType>(
    url: string,
    body: object,
    params?: Params,
    headers?: Headers,
): Promise<ResponseType> => {
  return request<ResponseType>("POST", url, params, headers, body)
}

export const apiPUT = async <ResponseType>(
    url: string,
    body: object,
    params?: Params,
    headers?: Headers,
): Promise<ResponseType> => {
  return request<ResponseType>("PUT", url, params, headers, body)
}

export const apiPATCH = async <ResponseType>(
    url: string,
    body: object,
    params?: Params,
    headers?: Headers,
): Promise<ResponseType> => {
  return request<ResponseType>("PATCH", url, params, headers, body)
}


export const apiDELETE = async <ResponseType>(
    url: string,
    params?: Params,
    headers?: Headers,
): Promise<ResponseType> => {
  return request<ResponseType>("DELETE", url, params, headers)
}

export const apiGETDownload = async (
    url: string,
    filename: string,
    params?: Params,
    headers?: Headers,
): Promise<void> => {

  const queryParams = new URLSearchParams();

  if (params) {
    for (const key in params) {
      if (params[key] !== undefined) {
        queryParams.append(key, String(params[key]));
      }
    }
  }
  const fullUrl = params ? `${url}?${queryParams.toString()}` : url;

  const response = await fetch(fullUrl, {
    method: "GET",
    headers: headers
  });

  if (!response.ok) {
    throw new Error("Failed to download CSV");
  }

  const blob = await response.blob();

  // Create a temporary anchor element to trigger the download
  const downloadURL = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = downloadURL;
  a.download = filename; // You can set the filename here
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);


}