import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
type SeeLatestIncidentsPropTypes = {
  color: string;
};
function SeeLatestIncidents({ color }: SeeLatestIncidentsPropTypes) {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const pathnameArr = pathname.split("/");
  const section = pathnameArr.at(pathnameArr.length - 1);

  const handleClick = () => {
    if (section === "map") {
      navigate(-1);
      return;
    }

    navigate("map");
  };
  return (
    <button
      onClick={handleClick}
      className={`h-12 w-fit px-2 border-2 ${
        section === "map" ? `bg-[${color}] text-white` : "bg-white"
      } rounded-xl text-sm`}
      style={{
        borderColor: color,
        backgroundColor: section === "map" ? color : "#fff",
      }}
    >
      See Latest Incidents
    </button>
  );
}

export default SeeLatestIncidents;
