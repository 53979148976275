import React, { useState } from "react";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { FilterButton } from "../../../../../../components/ui/Buttons";
import Modal from "../../../../../../components/portal/Modal";
import RiskRatings from "../../../../components/risk-ratings";
import IncidentTypeFilter from "./IncidentTypeFilter";

function Header() {
  const [filter, setFilter] = useState<boolean>(false);
  const [keyModal, setKeyModal] = useState<boolean>(false);
  return (
    <header className="flex justify-between items-center p-10 absolute w-full top-0 z-50 pointer-events-none">
      <div className="flex flex-row gap-4 relative flex-1">
        <button
          className="bg-blue h-12 w-12 shadow-[0px_4px_10px_-3px_#00000070] rounded-md flex items-center justify-center text-white font-semibold pointer-events-auto"
          onClick={() => setKeyModal(true)}
        >
          <VpnKeyIcon className="text-white rotate-45" />
        </button>
        <FilterButton onClick={() => setFilter(true)} />

        <IncidentTypeFilter filter={filter} setFilter={setFilter} />
      </div>

      <input
        type="text"
        id="pac-input"
        placeholder="Search Location"
        className="h-10 w-full max-w-[240px] mr-7 text-base px-4 pointer-events-auto"
      />

      <Modal open={keyModal} setOpen={setKeyModal}>
        <RiskRatings setOpen={setKeyModal} />
      </Modal>
    </header>
  );
}

export default Header;
