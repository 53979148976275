import React, { createContext, useState, ReactNode, useEffect, useMemo } from "react";

interface AlertContextType {
  alert: boolean;
  alertHeight: number;
  isExpanded: boolean;
  setAlert: (alert: boolean) => void;
  setAlertHeight: (height: number) => void;
  setIsExpanded: (expanded: boolean) => void;
}

const defaultAlertContext: AlertContextType = {
  alert: false,
  alertHeight: 0,
  isExpanded: false,
  setAlert: () => {},
  setAlertHeight: () => {},
  setIsExpanded: () => {},
};

export const AlertContext = createContext<AlertContextType>(defaultAlertContext);

interface AlertContextProviderProps {
  children: ReactNode;
  marginTop?: number;
}

export const AlertContextProvider: React.FC<AlertContextProviderProps> = ({ children }) => {
  const [alert, setAlert] = useState<boolean>(false);
  const [alertHeight, setAlertHeight] = useState<number>(0);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  useEffect(() => {
    console.log("Alert Height:", alertHeight);
  }, [alertHeight]);

  useEffect(() => {
    console.log("Alert Expanded State:", isExpanded);
  }, [isExpanded]);

  const calculateMarginTop = useMemo(() => {
    if (isExpanded) {
      return `${49 + (alertHeight / 1.3)}px`;
    } else if (alertHeight > 20) {
      return "50px";
    } else {
      return "36px";
    }
  }, [alertHeight, isExpanded]);


  return (
    <AlertContext.Provider value={{ alert, setAlert, alertHeight, setAlertHeight, isExpanded, setIsExpanded }}>
      <style>
        {`
          .alert-transition {
          transition: margin-top 0.3s ease-in-out;
          }
        `}
      </style>
      {alert ? (
        <div className="alert-transition" style={{ marginTop: calculateMarginTop }}>
          test
        </div>
      ) : null}
      {children}
    </AlertContext.Provider>
  );
};
