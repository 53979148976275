import React, { useContext } from "react";
import CountriesSelect from "../../../../../../components/form/CountriesSelect";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { FormDataContext } from "../../context/form";
function CountryOfResidence() {
  const { data } = useContext(UserProfileContext)!;
  const formDataContext = useContext(FormDataContext)!;
  const { countryOfResidenceRef } = formDataContext;
  const userData: UserDataTypes = data?.data;
  const countryOfResidence = userData.profile?.countryOfResidence;

  return (
    <div className="">
      <label htmlFor="residence_country" className="opacity-80">
        Country of Residence
      </label>
      <CountriesSelect
        defaultValue={countryOfResidence}
        countryOfResidenceRef={countryOfResidenceRef}
      />
    </div>
  );
}

export default CountryOfResidence;
