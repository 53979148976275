import React from "react";
import { SitesAddZoneMain } from "../../../features/sites/pages/add-zone";

function AddZone() {
  return (
    <div className="w-full h-full relative">
      <SitesAddZoneMain />
    </div>
  );
}

export default AddZone;
