import React, { SetStateAction, useEffect, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";
import ColorizeIcon from "@mui/icons-material/Colorize";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import { BlueButton } from "../../../../components/ui/Buttons";
import { FeatureType } from "../../types";
import { useNavigate, useSearchParams } from "react-router-dom";
import MarkerIconsOption from "../marker-icons-option";
type EditFeatureType = {
  setEditModal: React.Dispatch<SetStateAction<boolean>>;
  onSubmit: (data: FeatureType) => void;
  features: FeatureType[];
};
function EditFeature({ setEditModal, onSubmit, features }: EditFeatureType) {
  const navigate = useNavigate();
  const [feature, setFeature] = useState<FeatureType>();
  const [icon, setIcon] = useState<string>("");
  const [searchParams] = useSearchParams();
  const [type] = useState(searchParams.get("type"));
  const [id] = useState(searchParams.get("feature"));
  const [toggleColor, setToggleColor] = useState<boolean>(false);
  const [color, setColor] = useState("#000000");
  const [fontSize, setFontSize] = useState<number>();
  const [lineWidth, setLineWidth] = useState<number>();
  const colorPickerRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  useOnClickOutside(colorPickerRef, () => setToggleColor(false));
  useEffect(() => {
    const findFeature = features.find((f) => f.uuidv4 === id);
    setFeature(findFeature);
  }, [id]);

  useEffect(() => {
    if (feature?.drawingType === "text") {
      const defaultColor = feature?.properties.style.fontColor || "#000000";
      setColor(defaultColor);
    } else {
      const defaultColor = feature?.properties.style.lineColor || "#000000";
      setColor(defaultColor);
    }
  }, [feature]);

  useEffect(() => {
    const defaultColor = feature?.properties.style.lineWidth || 2;
    setLineWidth(defaultColor);
  }, [feature, searchParams, features]);
  useEffect(() => {
    if (feature?.drawingType !== "text") return;
    const drawing = feature?.drawing as google.maps.Marker;
    const defaultFontSize = drawing.getLabel() as google.maps.MarkerLabel;
    const fs = parseInt(defaultFontSize.fontSize as string) || 22;
    setFontSize(fs);
  }, [feature, searchParams, features]);

  const handleEdit = () => {
    if (!feature) return;
    console.log(feature.drawingType);
    const formData = new FormData(formRef.current!);
    const name = formData.get("name") as string;
    const description = formData.get("description") as string;
    const handleUpdate = (data: FeatureType) => {
      onSubmit(data);
      navigate(-1);
      setEditModal(false);
    };

    const updateCircle = () => {
      if (feature.drawingType !== "circle") return;
      const style = {
        lineWidth: lineWidth || 2,
        lineColor: color,
        lineOpacity: 0.8,
        fillOpacity: 0.35,
        fillColor: color,
      };
      const properties = {
        radius: feature?.properties.radius,
        style,
        name,
        description,
      };
      const data: FeatureType = {
        ...feature,
        properties,
      };
      handleUpdate(data);
    };

    const updateMarker = () => {
      if (feature.drawingType !== "marker") return;
      const style = {};
      const properties = {
        style,
        name,
        description,
        icon,
      };
      const data: FeatureType = {
        ...feature,
        properties,
      };
      handleUpdate(data);
    };

    const updateLabel = () => {
      if (feature.drawingType !== "text") return;
      const label = name;
      const style = {
        fontSize: fontSize,
        fontColor: color,
      };
      const properties = {
        style,
        label,
        name,
        description,
      };
      const data: FeatureType = {
        ...feature,
        properties,
      };
      handleUpdate(data);
    };

    const updatePolygon = () => {
      if (feature.drawingType !== "polygon") return;
      const style = {
        lineWidth: lineWidth || 2,
        lineColor: color,
        lineOpacity: 0.8,
        fillOpacity: 0.35,
        fillColor: color,
      };
      const properties = {
        style,
        name,
        description,
      };
      const data: FeatureType = {
        ...feature,
        properties,
      };
      handleUpdate(data);
    };

    const updatePolyline = () => {
      if (feature.drawingType !== "polyline") return;
      const style = {
        lineWidth: lineWidth || 2,
        lineColor: color,
        lineOpacity: 0.8,
      };
      const properties = {
        style,
        name,
        description,
      };
      const data: FeatureType = {
        ...feature,
        properties,
      };
      handleUpdate(data);
    };

    return feature.drawingType === "circle"
      ? updateCircle()
      : feature.drawingType === "marker"
      ? updateMarker()
      : feature.drawingType === "text"
      ? updateLabel()
      : feature.drawingType === "polygon"
      ? updatePolygon()
      : feature.drawingType === "polyline"
      ? updatePolyline()
      : null;
  };

  console.log(color);

  return (
    <form ref={formRef} className="bg-white text-grey px-8 pb-8 rounded-lg">
      <header className="text-center pt-2">
        <h4 className="my-4 font-semibold text-base">Edit</h4>
      </header>
      <hr />
      <div className="flex flex-col gap-6 my-6 w-96">
        <div className="">
          <label htmlFor="name" className="text-sm font-semibold">
            Name
          </label>
          <input
            type="text"
            name="name"
            placeholder="Name"
            className="h-10 w-full px-4 shadow-md"
            defaultValue={feature?.properties.name}
            maxLength={150}
            onChange={(e) => {
              const limitReachedI = document.getElementById(
                "input-drawing-edit-name-char-limit"
              );
              if (!limitReachedI) return;
              if (e.target.value.length < 150) {
                limitReachedI.style.display = "none";
                return;
              } else {
                limitReachedI.style.display = "inline";
              }
            }}
          />
          <span
            id="input-drawing-edit-name-char-limit"
            className="text-sm text-red"
            style={{ display: "none" }}
          >
            Maximum characters reached.
          </span>
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="marker-label" className="text-sm font-semibold">
            Description
          </label>
          <textarea
            name="description"
            placeholder="Description"
            className="outline-none h-32 shadow-md px-4"
            defaultValue={feature?.properties.description}
          ></textarea>
        </div>

        {type !== "marker" ? (
          <>
            <label htmlFor="color" className="text-sm font-semibold">
              Color
            </label>
            <div className="flex gap-6">
              <button
                onClick={() => setToggleColor((prev) => !prev)}
                className="h-10 w-10 rounded-md shadow-md"
                type="button"
              >
                <ColorizeIcon />
              </button>
              <div
                className="h-10 w-10 rounded-md shadow-md"
                style={{ backgroundColor: color }}
              ></div>
              {toggleColor ? (
                <div className="" ref={colorPickerRef}>
                  <HexColorPicker
                    color={color}
                    defaultValue={
                      type === "text"
                        ? feature?.properties.style.fontColor
                        : feature?.properties.style.fillColor
                    }
                    onChange={(value) => setColor(value)}
                    style={{ position: "absolute", left: 150 }}
                  />
                </div>
              ) : null}
            </div>
            {type !== "text" ? (
              <div className="flex flex-col items-start gap-4">
                <label htmlFor="line-width" className="text-sm font-semibold">
                  Line Width
                </label>
                <input
                  type="number"
                  name="line-width"
                  placeholder="Size"
                  className="shadow-md h-10 px-4 rounded-md w-24"
                  defaultValue={lineWidth}
                  onChange={(e) => {
                    setLineWidth(parseInt(e.target.value));
                  }}
                />
              </div>
            ) : (
              <div className="flex flex-col items-start gap-4">
                <label htmlFor="font-size" className="text-sm font-semibold">
                  Font Size
                </label>
                <input
                  type="number"
                  name="font-size"
                  placeholder="Size"
                  className="shadow-md h-10 px-4 rounded-md w-24"
                  min={8}
                  defaultValue={fontSize}
                  onChange={(e) => {
                    setFontSize(parseInt(e.target.value));
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <MarkerIconsOption icon={icon} setIcon={setIcon} />
        )}
      </div>
      <BlueButton type="button" onClick={handleEdit} text="Edit" />
    </form>
  );
}

export default EditFeature;
