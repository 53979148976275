import React from "react";
import { FlagIcon } from "../../../../../../../../components/ui/FlagIcon";

function Flag({ country_code }: { country_code: string }) {
  return (
    <div className="p-[2px] w-8 h-8 rounded-full bg-white">
      <FlagIcon country_code={country_code} />
    </div>
  );
}

export default Flag;
