import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import React from "react";
import { FeatureType } from "../../types";
import FeatureItem from "./FeatureItem";

type Props = {
  setToggleFeatures: React.Dispatch<React.SetStateAction<boolean>>;
  toggleFeatures: boolean;
  features: FeatureType[];
  deleteFeature: (payload: FeatureType) => void;
  setEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  map: google.maps.Map | undefined;
};

function FeaturesPanel(props: Props) {
  const [focusOn, setFocusOn] = React.useState<string | null>(null);
  if (!props.map) return null;
  return (
    <div className="bg-white absolute z-10 bottom-10 right-10 mr-6 rounded-md  w-96 flex flex-col shadow-md max-h-96 overflow-y-auto">
      <header
        className="px-4 h-10 flex items-center justify-between cursor-pointer"
        onClick={() => props.setToggleFeatures((prev) => !prev)}
      >
        <span className="text-center font-medium">{`Features (${props.features.length})`}</span>
        <span className="">
          {props.toggleFeatures ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </span>
      </header>
      <div
        className={` overflow-y-auto ${
          props.toggleFeatures ? "h-fit" : "h-0 overflow-hidden"
        }`}
      >
        {props.features.map((feature) => {
          return (
            <FeatureItem
              key={feature.uuidv4}
              feature={feature}
              setEditModal={props.setEditModal}
              handleDelete={() => props.deleteFeature(feature)}
              type={feature.drawingType}
              map={props.map}
              setFocusOn={setFocusOn}
              focusOn={focusOn}
            />
          );
        })}
      </div>
    </div>
  );
}

export default FeaturesPanel;
