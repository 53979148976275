import React, { useContext } from "react";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
function ClientName() {
  const { data } = useContext(UserProfileContext)!;
  const userData: UserDataTypes = data?.data;
  return (
    <div className="">
      <label htmlFor="client_name" className="opacity-80">
        Client Name
      </label>
      <input
        type="text"
        className="h-10 w-full rounded-md shadow-md px-4"
        value={userData.clientName}
        readOnly
      />
    </div>
  );
}

export default ClientName;
