import React from "react";
import { Outlet } from "react-router-dom";
import CountryProfileContextProvider from "../../../../features/risk/pages/country-profiles/context/country-profile";

function CountryProfile() {
  return (
    <div className="">
      <CountryProfileContextProvider>
        <Outlet />
      </CountryProfileContextProvider>
    </div>
  );
}

export default CountryProfile;
