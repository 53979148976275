import React, { useRef, useState } from "react";
import Sidebar from "../../../../../../components/ui/Sidebar";
import UploadImage from "../../../../../../assets/aurora_branding_upload_image.png";

function PhotoUploadSidebar() {
  const [file, setFile] = useState<any>();
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const handleChooseFile = () => {
    fileUploadRef.current?.click();
  };

  const handleFileUpload = () => {
    setFile(fileUploadRef.current!.files![0]);
  };
  return (
    <div className="w-58 mx-12 shadow-md h-fit bg-white p-8">
      <div className="flex flex-col gap-10">
        <img src={UploadImage} alt="" className="rounded-full" />

        <div className="flex gap-4 flex-col">
          <input
            type="file"
            id="file-upload"
            name="profile-picture"
            className="hidden"
            onChange={handleFileUpload}
            ref={fileUploadRef}
          />

          <button
            className="h-10 w-full rounded-full bg-light-light-grey shadow-md text-sm"
            onClick={handleChooseFile}
          >
            Choose File
          </button>
          <span className="text-sm inline-block my-auto mx-auto w-28 text-ellipsis overflow-hidden">
            {" "}
            {file ? file.name : "No file chosen"}
          </span>
        </div>
      </div>
    </div>
  );
}

export default PhotoUploadSidebar;
