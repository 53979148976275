import React, { useContext, useEffect, useRef, useState } from "react";
import { generateMap, generateMapSearchBox } from "../../../../../../util/map";
import useFacilities from "../../../../../../services/useFacilities";

import {
  useCustomOverlays,
  useGeofences,
  useZones,
} from "../../../../services";
import {
  useCustomOverlayMarker,
  useFacilitiesMarker,
  useGeofencesMarker,
  useZonesMarker,
} from "../../../../../map/hooks";
import { useSearchParams } from "react-router-dom";
function Map() {
  const [searchParams] = useSearchParams();
  const siteTypes = searchParams.getAll("siteTypes");
  const [map, setMap] = useState<google.maps.Map>();
  const ref = useRef<HTMLDivElement>(null);
  const { facilities } = useFacilities("facilities", {
    rpp: 200,
  });
  const { data: overlays } = useCustomOverlays().overlaysQuery;
  const { data: zones } = useZones().zonesQuery;
  const { data: geofences } = useGeofences().geofencesQuery;

  useEffect(() => {
    const zoom = 3;
    const center = [0, 0];
    const mapCenter = { lng: center[0], lat: center[1] };
    const generatedMap = generateMap(ref, mapCenter, zoom);
    setMap(generatedMap);
  }, []);
  useFacilitiesMarker(map, facilities, siteTypes);
  useCustomOverlayMarker(map, overlays, siteTypes);
  useZonesMarker(map, zones, siteTypes);
  useGeofencesMarker(map, geofences, siteTypes);
  const input = document.getElementById("pac-input") as HTMLInputElement;
  generateMapSearchBox(input, map);
  return (
    <div className="relative h-full w-full">
      <div
        ref={ref}
        className="h-full w-full flex justify-center items-center"
      ></div>
      <input
        type="text"
        className="absolute h-12 w-56 rounded-md shadow-md mr-7 top-10 right-10 z-20 px-4"
        placeholder="Search location"
        id="pac-input"
      />
    </div>
  );
}

export default Map;
