import React, { useEffect, useState } from "react";
import api from "../../../../api/axiosConfig";
import ReactDatePicker from "react-datepicker";
import DropDown from "../../../../components/form/DropDown";
import EditIcon from "../../../../assets/icons/icon-edit.svg";
import BinIcon from "../../../../assets/icons/icon-bin.svg";
import TextTemplateNotif from "../../../../components/ui/Content/TextTemplateNotif";
import BasicText from "../../../../components/form-v2/basic-text";

export default function SetBannerAlert() {
  const [title, setTitle] = useState(""); 
  const [message, setMessage] = useState(""); 
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [displayMessage, setDisplayMessage] = useState(""); 
  const [listData, setListData] = useState<any[]>([]);
  const [newTitle, setNewTitle] = useState(""); 
  const [newMessage, setNewMessage] = useState(""); 
  const [newStartTime, setNewStartTime] = useState<Date | null>(null);
  const [newEndTime, setNewEndTime] = useState<Date | null>(null);
  const [newPlatformChecked, setNewPlatformChecked] = useState(false);
  const [newAppChecked, setNewAppChecked] = useState(false);
  
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState<string | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;


  const formatDate = (date: Date | null) => {
    return date ? `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}T${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}` : "";
  };

  const fetchData = async () => {
    const data = await getList();
    const sortedData = data.sort((a: { lastProcessed: string | number | Date; }, b: { lastProcessed: string | number | Date; }) => new Date(b.lastProcessed).getTime() - new Date(a.lastProcessed).getTime());
    setListData(sortedData);
  };
  

  const handleSubmit = async (event: any) => {
    event.preventDefault();
  
    if (!newMessage) {
      setDisplayMessage("Message cannot be empty!");
      return;
    }
  
    let environment = "";
    if (newPlatformChecked) environment += "platform";
    if (newAppChecked) environment += (environment ? ", " : "") + "app";
  
    try {
      if (isEditing) {
        await api.put(`${process.env.REACT_APP_API_V2_URL}/update-alert-message/${editId}`, {
          title: newTitle,
          message: newMessage,
          startTime: newStartTime,
          endTime: newEndTime,
          environment,
        });
        setDisplayMessage(`Notification Updated: ${newTitle}`);
      } else {
        await api.post(`${process.env.REACT_APP_API_V2_URL}/set-alert-message`, {
          title: newTitle,
          message: newMessage,
          startTime: newStartTime,
          endTime: newEndTime,
          environment,
        });
        setDisplayMessage(`Notification Added: ${newTitle}`);
      }
  
      await fetchData();
      resetForm(); 
    } catch (error) {
      setDisplayMessage("Failed to send message");
      console.error("Error:", error);
    }
  };
  
  const resetForm = () => {
    setNewTitle("");
    setNewMessage(""); 
    setNewPlatformChecked(false);
    setNewAppChecked(false);
    setIsEditing(false);
    setEditId(null);
  };
  

  const handleEdit = (item: any) => {
    setIsEditing(true);
    setEditId(item.id);

    setNewMessage(item.message);
    setNewStartTime(new Date(item.startTime));
    setNewEndTime(new Date(item.endTime));
    setNewPlatformChecked(item.environment.includes("platform"));
    setNewAppChecked(item.environment.includes("app"));
  };

  const handleDelete = async (item: any) => {
    try {
      await api.post(`${process.env.REACT_APP_API_V2_URL}/remove-alert-message/${item.id}`, {});
      await fetchData();
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const getList = async () => {
    try {
      const response = await api.get(`${process.env.REACT_APP_API_V2_URL}/alert-list`);
      return response.data;
    } catch (error) {
      console.error("Failed to get list:", error);
      return [];
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const totalPages = Math.ceil(listData.length / itemsPerPage);

  const paginatedData = listData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white shadow rounded-lg p-2 mb-8">
        <div className="h-48 overflow-y-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-gray-50 text-left text-gray-700 uppercase text-xs font-medium tracking-wider">
                <th className="py-3 px-4">Title</th>
                <th className="py-3 px-4">Message</th>
                <th className="py-3 px-4">Start Time</th>
                <th className="py-3 px-4">End Time</th>
                <th className="py-3 px-4">Environment</th>
                <th className="py-3 px-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item) => (
                <tr key={item.id} className="border-t text-sm text-gray-900">
                  <td className="py-2 px-4">{item.title}</td>
                  <td className="py-2 px-4">{item.message}</td>
                  <td className="py-2 px-4">{new Date(item.startTime).toLocaleString()}</td>
                  <td className="py-2 px-4">{new Date(item.endTime).toLocaleString()}</td>
                  <td className="py-2 px-4">{item.environment}</td>
                  <td className="py-2 px-4">
                    <div className="flex space-x-4">
                      <button
                        onClick={() => handleEdit(item)}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        <img src={EditIcon} alt="Edit" className="inline w-5 h-5" />
                      </button>
                      <button
                        onClick={() => handleDelete(item)}
                        className="text-red-600 hover:text-red-800"
                      >
                        <img src={BinIcon} alt="Delete" className="inline w-5 h-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalPages > 1 && (
          <div className="flex justify-center mt-4 space-x-2">
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => handlePageChange(i + 1)}
                className={`px-3 py-1 border rounded ${
                  currentPage === i + 1
                    ? "bg-blue text-white"
                    : "bg-gray-200 text-grey"
                }`}
              >
                {i + 1}
              </button>
            ))}
          </div>
        )}
      </div>
      <form
        onSubmit={handleSubmit}
        className="bg-white shadow rounded-lg p-8 space-y-6 h-96 overflow-y-auto"
        >
          <div>
            <label className="block text-sm font-medium text-gray-700">Title</label>
            <input
              type="text"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              placeholder="Enter Title"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Message</label>
            <TextTemplateNotif
              initialContent={newMessage}
              onSave={(html) => setNewMessage(html)}
              height="200px"
              resetEditor={!!displayMessage} 
            />
          </div>

          <div className="flex flex-col gap-2">
          <div>
            <label className="block text-sm font-medium text-gray-700">Start Time</label>
            <BasicText
              type="datetime-local"
              value={formatDate(newStartTime)}
              onChange={(value: string) => setNewStartTime(new Date(value))}
              placeholder="Select Start Time"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">End Time</label>
            <BasicText
              type="datetime-local"
              value={formatDate(newEndTime)}
              onChange={(value: string) => setNewEndTime(new Date(value))}
              placeholder="Select End Time"
            />
          </div>

          <div className="flex space-x-6 mb-6">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                checked={newPlatformChecked}
                onChange={() => setNewPlatformChecked(!newPlatformChecked)}
              />
              <span className="ml-2">Platform</span>
            </label>

            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                checked={newAppChecked}
                onChange={() => setNewAppChecked(!newAppChecked)}
              />
              <span className="ml-2">App</span>
            </label>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className={`inline-flex mt-2justify-center py-2 px-4 border border-transparent shadow-sm text-lg font-medium rounded-md text-white ${
                newMessage ? "bg-blue" : "bg-grey cursor-not-allowed"
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue`}
              disabled={!newMessage}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
