import React, { useCallback } from "react";
import { useDebounce } from "use-debounce";
import { useOperationalMapContext } from "../../providers/OperationalMapProvider";
import { dbscanGeo, zoomLevelToEpsilon } from "./math";
import { useQuery } from "react-query";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { IncidentMarker } from "./incidentmarker";

export const IncidentsClustered: React.FC = () => {
  const ctx = useOperationalMapContext();
  const [debouncedZoom] = useDebounce(ctx.zoom, 300); // Debounce delay

  const getItemsClustered = useCallback(() => {
    if (!debouncedZoom || !ctx.incidentQuery.data) return null;

    // Memoize the result of dbscanGeo
    const allIncidents = ctx.incidentQuery.data.pages.flatMap(p => p.items);
    return dbscanGeo(
      allIncidents,
      zoomLevelToEpsilon[debouncedZoom],
      1,
      (item) => ({
        lat: item.position.coordinates[1],
        lng: item.position.coordinates[0],
      })
    );
  }, [debouncedZoom, ctx.incidentQuery.data]);

  const itemsQuery = useQuery(
    ["incidents-clustered", debouncedZoom, ctx.selectedIncidentLevels, ctx.incidentQuery.data],
    getItemsClustered,
    {
      staleTime: 10000,
      cacheTime: 300000,
    }
  );

  if (itemsQuery.isLoading) return null;
  if (itemsQuery.isError) return null;
  if (!itemsQuery.data || itemsQuery.data.clusters.length === 0) return null;

  return (
    <>
      {itemsQuery.data.clusters.map((c) => {
        if (c.items.length > 1) {
          return (
            <AdvancedMarker
              key={`${c.center.lat}_${c.center.lng}`}
              position={{ lat: c.center.lat, lng: c.center.lng }}
            >
              <div className="w-[24px] h-[24px] flex rounded-full justify-center items-center bg-darker-red text-white font-semibold shadow">
                {c.items.length}
              </div>
            </AdvancedMarker>
          );
        }

        return (
          <IncidentMarker
            key={c.items[0].id}
            incident={c.items[0]}
            infoKey={c.items[0].id}
            showInfoWindow={ctx.visibleInfoWindow === c.items[0].id}
          />
        );
      })}
    </>
  );
};