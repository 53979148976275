import React, { useEffect, useRef, useState } from "react";
import { BlueButton } from "../../../../components/ui/Buttons";
import DrawZone from "./DrawZone";
import People from "./People";
import Timing from "./Timing";
import ZoneType from "./ZoneType";
import { useGeofences, useZones } from "../../services";
import convertDateToUTC from "../../../../util/convertDateToUTC";
import { useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
import ZoneOwnership from "./ZoneOwnership";

function AddZoneForm({
  className,
  map,
  drawingManager,
}: {
  className?: string;
  map: google.maps.Map | undefined;
  drawingManager: google.maps.drawing.DrawingManager | undefined;
}) {
  const navigate = useNavigate();
  const [zoneNameError, setZoneNameError] = useState<boolean>(false);
  const polygonRef = useRef<google.maps.Polygon>(null);
  const circleRef = useRef<google.maps.Circle>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const { createMutation: createZone } = useZones();
  const { createMutation: createGeofence } = useGeofences();
  const [zoneType, setZoneType] = useState<{
    geofences: boolean;
    safetyZones: boolean;
  }>({ geofences: false, safetyZones: false });

  const [clientID, setClientID] = useState<string | null>(null);
  const [departmentID, setDepartmentID] = useState<string | null>(null);

  const onOwnershipChange = (
    clientID: string | null,
    departmentID: string | null
  ) => {
    setClientID(clientID);
    setDepartmentID(departmentID);
  };

  const [userIDs, setUserIDs] = useState<string[]>([]);
  const [departmentIDs, setDepartmentIDs] = useState<string[]>([]);
  const [applyToAll, setApplyToAll] = useState<boolean>(false);

  const onPeopleChange = (
    userIDs: string[],
    departmentIDs: string[],
    applyToAll: boolean
  ) => {
    setUserIDs(userIDs);
    setDepartmentIDs(departmentIDs);
    setApplyToAll(applyToAll);
  };

  const handleSubmit = () => {
    if (!formRef.current) return;
    const formData = new FormData(formRef.current);
    const zoneName = formData.get("name") as string;
    if (!zoneName) {
      setZoneNameError(true);
      console.error("Zone name is required.");
      return;
    }

    const startAtDate = formData.get("startDate") as string;
    const startAtTime = formData.get("startTime") as string;
    const startsAt = convertDateToUTC(`${startAtDate}${startAtTime}`);
    const allTheTime = formData.get("all-the-time") as string;
    const currentMapCentre = map?.getCenter();
    const currentMapZoom = map?.getZoom();
    if (!startAtDate && allTheTime !== "true") {
      console.error("Start date is required");
      return;
    }

    if (!startAtTime && allTheTime !== "true") {
      console.error("Start time is required");
      return;
    }

    const endsAtDate = formData.get("endDate") as string;
    const endsAtTime = formData.get("endTime") as string;
    const expiresAt = convertDateToUTC(`${endsAtDate}${endsAtTime}`);

    if (!endsAtDate && allTheTime !== "true") {
      console.error("Expires at date is required");
      return;
    }

    if (!endsAtTime && allTheTime !== "true") {
      console.error("Expires at time is required");
      return;
    }

    // if (!applyToAll && userIDs.length < 1) {
    //   console.error("Select a user or everyone.");
    //   return;
    // }

    const view = {
      type: "MapCentre",
      coordinates: [currentMapCentre?.lat(), currentMapCentre?.lng()],
      properties: {
        type: "MapZoom",
        zoom: currentMapZoom,
      },
    };
    const data = {
      name: zoneName,
      startsAt: allTheTime === "true" ? null : startsAt,
      expiresAt: allTheTime === "true" ? null : expiresAt,
      alwaysActive: allTheTime === "true" ? true : false,
      clientID,
      departmentID,
      appliesToAll: applyToAll,
      appliesTo: {
        departmentIDs: departmentIDs,
        userIDs: userIDs,
      },
      view,
      location: {},
    };

    if (polygonRef.current) {
      const polygonPaths = polygonRef.current
        ?.getPath()
        .getArray()
        .map((path) => [path.lng(), path.lat()]);
      polygonPaths.push(polygonPaths[0]);
      const locationData = {
        type: "Polygon",
        coordinates: [polygonPaths],
      };
      data.location = locationData;
    } else {
      const lat = circleRef.current?.getCenter()?.lat();
      const lng = circleRef.current?.getCenter()?.lng();
      const radius = circleRef.current?.getRadius();
      const locationData = {
        type: "Point",
        coordinates: [lng, lat],
        properties: {
          radius,
        },
      };

      data.location = locationData;
    }

    if (zoneType.safetyZones) {
      createZone.mutate(data, {
        onSuccess: () => {
          navigate(-1);
        },
        onError: (err) => {
          console.error(err);
        },
      });
    } else {
      createGeofence.mutate(data, {
        onSuccess: () => {
          navigate(-1);
        },
      });
    }
  };

  return (
    <>
      <form
        ref={formRef}
        className={`bg-white text-grey px-8 pb-8 rounded-lg ${className}`}
      >
        <header className="text-center relative">
          <h4 className="my-4 font-semibold text-base">Add Zone</h4>
          <button
            className="absolute right-0 top-0"
            onClick={() => navigate(-1)}
            type="button"
          >
            <Close />
          </button>
        </header>

        <hr />

        <DrawZone
          map={map}
          drawingManager={drawingManager}
          polygonRef={polygonRef}
          circleRef={circleRef}
          zoneNameError={zoneNameError}
        />
        <hr />
        <ZoneOwnership onChange={onOwnershipChange} />
        <hr />
        <ZoneType zoneType={zoneType} setZoneType={setZoneType} />
        <hr />
        <People
          clientID={clientID}
          departmentID={departmentID}
          onChange={onPeopleChange}
        />
        <hr />
        <Timing />
        <BlueButton text="Submit" type="button" onClick={handleSubmit} />
      </form>
    </>
  );
}

export default AddZoneForm;
