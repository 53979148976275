import React, { SetStateAction, useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import { SiteBasedAlertDataTypes } from "../../../../types/site-based-alerts-data-type";
import useGetSiteBasedAlerts from "../../../../api/site-based-alerts/useGetSiteBasedAlerts";
import LoadingSpinner from "../../../../components/ui/LoadingSpinner";
import EditForm from "./EditForm";
import api from "../../../../api/axiosConfig";
import { BlueButton } from "../../../../components/ui/Buttons";
import { Add, Done } from "@mui/icons-material";
import Modal from "../../../../components/portal/Modal";
import AddSiteBasedAlert from "./add-site-based-alerts";
function Form({
  setEditSiteBasedAlertsModal,
  items,
  facilitiyID,
}: {
  setEditSiteBasedAlertsModal: React.Dispatch<SetStateAction<boolean>>;
  items: SiteBasedAlertDataTypes[];
  facilitiyID: string | null;
}) {
  const [addSBA, setAddSBA] = useState<boolean>(false);
  const [successfullyAddedSBA, setSuccessfullyAddedSBA] =
    useState<boolean>(false);
  const [sbaID, setSbaID] = useState<string>(items[0].id);
  const sba = useGetSiteBasedAlerts(facilitiyID!, sbaID!);
  console.log(sba);

  const handleAddSBA = () => {
    setAddSBA(true);
  };
  return sba.status !== "success" || !sba.data ? (
    <LoadingSpinner />
  ) : (
    <div
      className={`p-8 bg-white rounded-md shadow-md h-[60vh] w-[100vw] max-w-7xl overflow-y-auto ${
        addSBA ? "hidden" : "flex flex-col"
      }`}
    >
      <header className="h-10 w-full border-b flex items-center justify-center">
        <h4 className="font-semibold">Edit Site-based Alert</h4>
        <button
          className="absolute top-5 right-5"
          type="button"
          onClick={() => setEditSiteBasedAlertsModal(false)}
        >
          <CloseIcon />
        </button>
      </header>
      <div className="grid grid-cols-[auto_auto] gap-x-8 my-8">
        <ul className="flex flex-col gap-4">
          {items.map((item) => (
            <li
              key={item.id}
              className="bg-light-light-grey p-2 rounded-md cursor-pointer"
              onClick={() => setSbaID(item.id)}
            >
              {item.name}
            </li>
          ))}
          <li className="w-3/4 mx-auto">
            <BlueButton text="Add" type="button" onClick={handleAddSBA} />
          </li>
        </ul>
        <EditForm sbaData={sba.data.data} />
      </div>
      <Modal open={successfullyAddedSBA}>
        <div className="bg-white p-8 flex items-center gap-8 text-xl rounded-md shadow-md">
          <div className="p-4 bg-blue w-fit rounded-full">
            <Done className="text-white" />
          </div>
          <span>Successfull added site based alert.</span>
        </div>
      </Modal>
      <Modal open={addSBA} setOpen={setAddSBA}>
        <AddSiteBasedAlert
          setSiteBasedAlertModal={setAddSBA}
          setSuccessfullyAddedSBA={setSuccessfullyAddedSBA}
          facilityID={facilitiyID!}
        />
      </Modal>
    </div>
  );
}

export default Form;
