import React from "react";
import { useQuery } from "react-query";
import api from "../axiosConfig";

export default function useGetTrips(
  page = 1,
  clientIDs = "",
  departmentIDs: string[] = [],
  userIDs: string[] = [],
  startDate = "",
  endDate = "",
  originCountryISO: string[] = [],
  destinationCountryISO: string[] = []
) {
  const fetchTrips = async () => {
    return await api
      .get(`/trips?page=${page}`, {
        params: {
          page,
          clientIDs,
          departmentIDs: departmentIDs.join(),
          userIDs: userIDs.join(),
          startDate,
          endDate,
          originCountryISO: originCountryISO.join(),
          destinationCountryISO: destinationCountryISO.join(),
        },
      })
      .then((res) => res.data)
      .catch((err) => console.error(err));
  };

  const { data, status, error, refetch } = useQuery(
    ["trips", page],
    fetchTrips
  );

  return {
    data,
    status,
    error,
    refetch,
  };
}
