import { useQuery } from "react-query";
import api from "../../api/axiosConfig";
import { ClientDataTypes } from "../../types/client-data-types";

export default function useClient(id: string) {
  const fetchClient = async () => {
    return await api
      .get(`/clients/${id}`)
      .then((res) => {
        const data: ClientDataTypes = res.data;
        return data;
      })
      .catch((err) => console.error(err));
  };

  return useQuery(["client", id], fetchClient);
}
