export const metadataFields: Record<string, string[]> = {
  "flight": ["flightNumber", "airline"],
  "taxi": ["company", "bookingReference", "registrationNumber"],
  "ferry": ["company", "bookingReference", "boatNumber"],
  "train": ["company", "bookingReference", "trainNumber"],
  "rental": ["company", "bookingReference", "pickUpTime", "dropOffTime"],
  "private_car": ["registrationNumber"],
  "bus": ["company", "bookingReference", "registrationNumber"],
  "hotel": ["company", "bookingReference"],
  "privateProperty": [],
  "home": [],
  "other": ["type", "notes"],
}
export const metadataTypes: Record<string, string> = {
  "pickUpTime": "datetime-local",
  "dropOffTime": "datetime-local",
}
