import { dateToString } from "../../../util/helpers";
import ReactDatePicker from "react-datepicker";
import React from "react";

export default function SingleDateBox({
  startDate,
  onStartDatePicked,
}: {
  startDate: Date;
  onStartDatePicked: (date: Date) => void;
}) {
  const [showDatePicker, setShowDatePicker] = React.useState(false);

  return (
    <div className="relative">
      <div
        className="border border-light-grey rounded-md px-3 py-2 cursor-pointer focus:outline-none text-center w-[100px]"
        onClick={() => setShowDatePicker((prevState) => !prevState)}
      >
        {dateToString(startDate, false)}
      </div>
      {showDatePicker && (
        <div className="absolute left-0 -bottom-4 z-40 transform translate-y-full">
          <ReactDatePicker
            inline
            required
            selected={startDate}
            onChange={(date) => onStartDatePicked(date!)}
          />
        </div>
      )}
    </div>
  );
}
