import { useEffect, useState } from "react";
import { Coordinates, FeatureType } from "../../../types";
type Action = {
  type: "ADD_FEATURE" | "EDIT_FEATURE" | "DELETE_FEATURE" | "SET_FEATURES";
  payload: FeatureType | FeatureType[];
};
export default function useEditPolygon(
  features: FeatureType[],
  map: google.maps.Map | undefined,
  featuresDispatch: React.Dispatch<Action>
) {
  const [updatedPolygon, setUpdatedPolygon] = useState<FeatureType>();
  const polygons = features?.filter(
    (feature) => feature.drawingType === "polygon"
  );

  polygons?.forEach((feature) => {
    const polygon = feature.drawing as google.maps.Polygon;
    const handleNewPath = (newPolygon: google.maps.Polygon) => {
      const newPath = newPolygon
        .getPath()
        .getArray()
        .map((latLng) => ({
          lng: latLng.lng(),
          lat: latLng.lat(),
        }));
      console.log(newPath);
      polygon.setPath(newPath);
      const updatedData: FeatureType = {
        ...feature,
        drawing: polygon,
        geometry: {
          type: "Polygon",
          coordinates: newPath.map((path) => [path.lng, path.lat]),
        },
      };
      setUpdatedPolygon(updatedData);
    };

    google.maps.event.addListener(polygon.getPath(), "set_at", () =>
      handleNewPath(polygon)
    );
    google.maps.event.addListener(polygon.getPath(), "insert_at", () =>
      handleNewPath(polygon)
    );
    google.maps.event.addListener(polygon.getPath(), "remove_at", () =>
      handleNewPath(polygon)
    );
  });

  useEffect(() => {
    if (!updatedPolygon) return;
    featuresDispatch({
      type: "EDIT_FEATURE",
      payload: updatedPolygon as FeatureType,
    });
  }, [updatedPolygon]);
}
