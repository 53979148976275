import React from "react";
import {
  DrawCircleBtn,
  DrawMarkerBtn,
  DrawPolygonBtn,
  DrawPolylineBtn,
  DrawTextBtn,
  PanBtn,
} from "../buttons-v2";
import { FeatureType } from "../../types";
import useMapListeners from "../listeners";
type DrawingMode = "marker" | "polygon" | "polyline" | "circle" | "text" | null;

type Props = {
  drawingMode: DrawingMode;
  disableDrawing: boolean;
  drawingManager: google.maps.drawing.DrawingManager | undefined;
  setDrawingMode: React.Dispatch<React.SetStateAction<DrawingMode>>;
  setDisableDrawing: React.Dispatch<React.SetStateAction<boolean>>;
  addFeature: (data: FeatureType) => void;
  setToggleFeatures: React.Dispatch<React.SetStateAction<boolean>>;
  map: google.maps.Map | undefined;
  setDisableSave: React.Dispatch<React.SetStateAction<boolean>>;
};

function DrawingTool(props: Props) {
  useMapListeners(
    props.drawingMode,
    props.disableDrawing,
    props.drawingManager,
    props.setDrawingMode,
    props.setDisableDrawing,
    props.addFeature,
    props.setToggleFeatures,
    props.map,
    props.setDisableSave
  );
  if (!props.map) return null;
  return (
    <div className="grid grid-cols-4 gap-4">
      <DrawCircleBtn
        disableDrawing={props.disableDrawing}
        setDrawingMode={props.setDrawingMode}
        drawingMode={props.drawingMode}
        drawingManager={props.drawingManager}
      />
      <DrawMarkerBtn
        disableDrawing={props.disableDrawing}
        setDrawingMode={props.setDrawingMode}
        drawingMode={props.drawingMode}
        drawingManager={props.drawingManager}
      />
      <DrawPolygonBtn
        disableDrawing={props.disableDrawing}
        setDrawingMode={props.setDrawingMode}
        drawingMode={props.drawingMode}
        drawingManager={props.drawingManager}
      />
      <DrawPolylineBtn
        disableDrawing={props.disableDrawing}
        setDrawingMode={props.setDrawingMode}
        drawingMode={props.drawingMode}
        drawingManager={props.drawingManager}
      />
      <DrawTextBtn
        disableDrawing={props.disableDrawing}
        setDrawingMode={props.setDrawingMode}
        drawingMode={props.drawingMode}
        drawingManager={props.drawingManager}
      />
      <PanBtn
        disableDrawing={props.disableDrawing}
        setDrawingMode={props.setDrawingMode}
        drawingMode={props.drawingMode}
        drawingManager={props.drawingManager}
      />
    </div>
  );
}

export default DrawingTool;
