import React, { useEffect, useState } from "react";
import Checkbox from "../../../../components/form/Checkbox";
import DateTimeInput from "../../../../components/form/DateTimeInput";
import ZonesDataTypes, { GeofencesDataTypes } from "../../types";
import moment from "moment";

function Timing({ data }: { data: ZonesDataTypes | GeofencesDataTypes }) {
  const [isAllTime, setIsAllTime] = useState<boolean>(data.alwaysActive);
  const [isSpecificTimeDate, setIsSpecificTimeDate] = useState<boolean>(
    !data.alwaysActive
  );

  useEffect(() => {
    if (isSpecificTimeDate) {
      setIsAllTime(false);
    }
  }, [isSpecificTimeDate]);
  useEffect(() => {
    if (isAllTime) {
      setIsSpecificTimeDate(false);
    }
  }, [isAllTime]);
  const startTime = moment(data.startsAt).format("HH:mm");
  const startDate = moment(data.startsAt).format("yyyy-MM-DD");
  const endTime = moment(data.expiresAt).format("HH:mm");
  const endDate = moment(data.expiresAt).format("yyyy-MM-DD");

  return (
    <div className="flex flex-col gap-4 py-4 w-full">
      <span className="font-bold text-sm text-center">Step 5 : Timing</span>
      <div className="mx-auto">
        <Checkbox
          checked={isAllTime}
          name="all-the-time"
          label={"All the time"}
          value={isAllTime ? "true" : "false"}
          onClick={(target) => setIsAllTime(target.checked)}
        />

        <Checkbox
          checked={isSpecificTimeDate}
          name="timing"
          label="Specific time and dates"
          value="specific-time-and-dates"
          onClick={(target) => setIsSpecificTimeDate(target.checked)}
        />
      </div>
      <div className={isAllTime? "hidden": ""}>
        <div className="flex gap-4 items-center px-8">
          <div className="shadow-md rounded-md h-12 px-4 ">
            <DateTimeInput
              type="time"
              defaultValue={startTime}
              name="startTime"
              required={true}
              disabled={!isSpecificTimeDate}
            />
          </div>
          <span>on</span>
          <div className="shadow-md rounded-md h-12 px-4 ">
            <DateTimeInput
              type="date"
              name={"startDate"}
              required={true}
              disabled={!isSpecificTimeDate}
              defaultValue={startDate}
            />
          </div>
        </div>

        <span className="">until</span>

        <div className="flex gap-4 items-center px-8">
          <div className="shadow-md rounded-md h-12 px-4">
            <DateTimeInput
              type="time"
              defaultValue={endTime}
              name="endTime"
              required={true}
              disabled={!isSpecificTimeDate}
            />
          </div>
          <span>on</span>
          <div className="shadow-md rounded-md h-12 px-4 ">
            <DateTimeInput
              type="date"
              name={"endDate"}
              required={true}
              disabled={!isSpecificTimeDate}
              defaultValue={endDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timing;
