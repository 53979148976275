import {ContentModal, ModalLayout} from "../../../../../components/ui/Modals";
import {BlueButton} from "../../../../../components/ui/Buttons";
import {dateToString} from "../../../../../util/helpers";
import ReactDatePicker from "react-datepicker";
import InputText from "../../../../../components/form/InputText";
import Select from "../../../../../components/form/Select";
import {countries} from "../../../../../constants/countries";
import React, {RefObject, useEffect, useRef, useState} from "react";
import {getTimezonesForCountry, Timezone} from "countries-and-timezones";
import {EditCheckIn} from "../../../types/check-ins-data-types";
import moment from "moment"
import TextAreaEdit from "./TextArea";
import {getTimezonesForCountryWithOverride, TimezoneOverride} from "../../../../../util/tz";

export default function EditCheckInPopUp({
                                           closeCheckInPopUp,
                                           checkIn,
                                           saveAndClose,
                                           errorMessage,
                                         }: {
  closeCheckInPopUp: (e: React.SyntheticEvent) => void;
  saveAndClose: (data: EditCheckIn) => void;
  checkIn: EditCheckIn;
  errorMessage: string;
}) {
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [gracePeriodError, setGracePeriodError] = useState("");
  const [destinationTimezones, setDestinationTimezones] = useState<string[]>(
      []
  );
  const [selectedCheckIn, setSelectedCheckIn] = useState<EditCheckIn | null>(
      checkIn
  );

  const gracePeriodRef = useRef<HTMLInputElement>(null);
  const notesRef = useRef<HTMLInputElement>(null);

  const validateForm = () => {
    let formIsValid = true;

    const gracePeriodValue = parseInt(selectedCheckIn?.gracePeriod?.toString() || "0");
    if (isNaN(gracePeriodValue) || gracePeriodValue > 60) {
      setGracePeriodError("Grace period must be at most 60 minutes");
      formIsValid = false;
    } else {
      setGracePeriodError("");
    }

    return formIsValid;
  };

  useEffect(() => {
    setSelectedCheckIn((prevState) => {
      if (!prevState) return prevState
      if (!prevState.checkInAt) return prevState

      const localOffsetMinutes = new Date().getTimezoneOffset();

      const c = (moment().tz(prevState.location.timezone).utcOffset() + localOffsetMinutes)
      const d = moment.tz(prevState.checkInAt, prevState.location.timezone).add({minute: c})


      const sc = {
        ...prevState,
        checkInAt: d.toDate()
      }

      return sc;
    })

    if (gracePeriodRef.current) {
      gracePeriodRef.current.value = `${selectedCheckIn?.gracePeriod}`
    }

    if (notesRef.current) {
      notesRef.current.value = `${selectedCheckIn?.notes}`
    }
  }, [])

  const handleCountriesChange = (target: RefObject<HTMLInputElement>) => {
    const {value} = target.current!;
    const countryId = countries.find((country) => country.name === value)?.code;

    setSelectedCheckIn((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          location: {
            ...prevState.location,
            countryISO: countryId || "",
          },
        };
      }
      return prevState;
    });


    let timezones: TimezoneOverride[] = [];

    if (countryId) {
      timezones = getTimezonesForCountryWithOverride(countryId)!;
    }

    setDestinationTimezones(timezones!.map((timezone) => timezone.name));
  };

  const handleTimezoneChange = (target: RefObject<HTMLInputElement>) => {
    const {value} = target.current!;
    setSelectedCheckIn!((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          location: {
            ...prevState.location,
            timezone: value,
          },
        };
      }
      return prevState;
    });
  };

  const handleDateChange = (date: Date) => {
    console.log(date)
    setSelectedCheckIn!((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          checkInAt: date,
        };
      }
      return prevState;
    });
  };

  const handleNotesChange = (
      event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const {value} = event.target;
    setSelectedCheckIn!((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          notes: value,
        };
      }
      return prevState;
    });
  };


  const handleGracePeriodChange = (
      event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {value} = event.target;

    setSelectedCheckIn((prevState) => {
      return prevState ? {...prevState, gracePeriod: value} : prevState;
    });

    const gracePeriodValue = parseInt(value || "0");
    if (isNaN(gracePeriodValue) || gracePeriodValue > 60) {
      setGracePeriodError("Grace period must be at most 60 minutes");
    } else {
      setGracePeriodError("");
    }
  };

  return (
      <ModalLayout close={closeCheckInPopUp}>
        <ContentModal closeButton={closeCheckInPopUp}>
          <div className="px-10 py-[30px] flex flex-col items-start w-fit">
            <div className="font-bold text-center self-center w-[85%] border-b pb-5">
              Edit Check-In
            </div>
            <div className="mt-10 flex">
              <div className="flex relative">
                <div className="flex flex-col w-[200px]">
                  <label htmlFor="checkInDate" className="text-sm underline font-medium text-gray-700 mb-2">Check-In
                    Date</label>
                  <div className="flex relative ml-3">
                    <span className="text-red absolute top-2/4 -translate-y-2/4 -left-3">*</span>
                    <div
                        className={`h-10 px-4 rounded-md shadow-md w-[200px] border outline-none w-full flex items-center ${
                            !selectedCheckIn?.checkInAt
                                ? "text-light-grey border-none "
                                : "text-grey  border-grey"
                        }`}
                        onClick={() => setShowDatePicker(!showDatePicker)}
                    >
                      {!selectedCheckIn?.checkInAt
                          ? "Date"
                          : dateToString(selectedCheckIn!.checkInAt!)}
                    </div>
                  </div>
                </div>
                {showDatePicker && (
                    <div className="absolute left-0 -bottom-4 z-40 transform translate-y-full w-[330px]">
                      <ReactDatePicker
                          inline
                          required
                          selected={selectedCheckIn?.checkInAt}
                          onChange={(date) => handleDateChange(date!)}
                          showTimeSelect
                          dateFormat="MMMM d, yyyy h:mm aa"
                          name="arrivalTime"
                      />
                    </div>
                )}
              </div>
              <div className="flex ml-7 w-[190px] flex-col">
                <label htmlFor="gracePeriod" className="text-sm underline font-medium text-gray-700 mb-2">Grace
                  Period</label>
                <InputText
                    required={true}
                    placeholder="Grace Period"
                    name="gracePeriod"
                    value={selectedCheckIn?.gracePeriod}
                    onChange={(event) => handleGracePeriodChange(event)}
                />
                {gracePeriodError && (
                    <div className="text-red text-sm mt-2">
                      {gracePeriodError}
                    </div>
                )}
              </div>
            </div>
            <div className="mt-10 flex pl-3">
              <div className="flex w-[190px] flex-col">
                <label htmlFor="countrySelect"
                       className="text-sm underline font-medium text-gray-700 mb-2">Country</label>
                <Select
                    required
                    value={
                      countries.find(
                          (country) =>
                              country.code.toLowerCase() ===
                              selectedCheckIn?.location.countryISO.toLowerCase()
                      )?.name
                    }
                    options={countries}
                    title="Country"
                    name="destination"
                    onSelect={handleCountriesChange}
                />
              </div>
            </div>
            <div className="mt-10 w-full pl-3 flex-col">
              <label htmlFor="notes" className="text-sm font-medium underline text-gray-700 mb-2">Notes</label>
              <TextAreaEdit
                  maxCharCount={300}
                  value={`${selectedCheckIn?.notes}`}
                  onChange={(event) => handleNotesChange(event)}
              />
            </div>
            <div className="mt-[60px] w-[200px] self-center">
              <BlueButton
                  text="Save"
                  onClick={() => {
                    if (!gracePeriodError && selectedCheckIn) {
                      saveAndClose(selectedCheckIn);
                    }
                  }}
              />
            </div>
            <div className={"w-full mt-4"}><p className={"text-center text-red"}>{errorMessage}</p></div>

          </div>
        </ContentModal>
      </ModalLayout>
  );
}
