import {UsersLocationUser} from "../operational-map/components/Map/hooks/useUserLocationMarkers";
import React, {memo} from "react";
import {SingleUserMarker} from "./singleusermarker";
import {MultipleUserMarker} from "./multipleusermarker";
import {useBoundsCheck} from "./bounds";

export type UserMarkerProps = {
  lat: number;
  lng: number;
  users: UsersLocationUser[];
  multiple: boolean;
  showInfoWindow: boolean;
  infoKey: string;
}


export const UserMarker: React.FC<UserMarkerProps> = memo((props) => {

  // const outOfBounds = useBoundsCheck(
  //   props.lng,
  //   props.lat,
  // )
  //
  // if(outOfBounds){
  //   return null;
  // }

  if (props.multiple) {
    return <MultipleUserMarker infoKey={props.infoKey} lat={props.lat} lng={props.lng} users={props.users}
                               multiple={props.multiple} showInfoWindow={props.showInfoWindow}/>
  }
  return <SingleUserMarker infoKey={props.infoKey} lat={props.lat} lng={props.lng} users={props.users}
                           multiple={props.multiple} showInfoWindow={props.showInfoWindow}/>
});

UserMarker.displayName = "UserMarker"