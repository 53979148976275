import React, { useContext } from "react";
import { UserProfileContext } from "../../context/user-profile";
import { UserDataTypes } from "../../../../../../types/user-data-types";
import { FormDataContext } from "../../context/form";
function GenderAssignedAtBirth() {
  const { data } = useContext(UserProfileContext)!;
  const formDataContext = useContext(FormDataContext)!;
  const { genderAssignedAtBirthRef } = formDataContext;
  const userData: UserDataTypes = data?.data;
  const genderAssignedAtBirth = userData.profile?.genderAssignedAtBirth;
  return (
    <div className="">
      <label htmlFor="birth_gender" className="opacity-80">
        Gender Assigned at Birth
      </label>
      <select
        ref={genderAssignedAtBirthRef}
        name="gender-assigned-at-birth"
        id="gender-assigned-at-birth"
        className="h-10 w-full rounded-md shadow-md px-4"
        defaultValue={
          genderAssignedAtBirth
            ? genderAssignedAtBirth
            : "select-gender-assigned-at-birth"
        }
      >
        <option value="select-gender-assigned-at-birth" disabled>
          Select Gender
        </option>
        <option value="male">Male</option>
        <option value="female">Female</option>
        <option value="others">Others</option>
      </select>
    </div>
  );
}

export default GenderAssignedAtBirth;
