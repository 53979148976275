import React, { ReactNode } from "react";

function FormSection({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) {
  return (
    <div className="mt-2">
      <span className="py-8 font-semibold inline-block">{title}</span>
      {children}
    </div>
  );
}

export default FormSection;
