import FilterIcon from "../../../../assets/icons/filter-icon.svg";
export const tableHeader = [
  {
    label: "Name",
    id: "name",
    icon: FilterIcon,
  },
  {
    label: "Client",
    id: "client",
    icon: FilterIcon,
  },
  {
    label: "Department / User",
    id: "department-user",
  },
  {
    label: "Type",
    id: "type",
  },
  {
    label: "Timing",
    id: "timing",
  },
  { label: "View and Edit", id: "view-edit" },
  { label: "Delete", id: "delete" },
];
