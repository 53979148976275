import React, { ReactNode } from "react";

function CustomisationSection({
  title,
  description,
  children,
}: {
  title: string;
  description: string;
  children: ReactNode;
}) {
  return (
    <div className="grid grid-cols-[60%_40%] gap-10">
      <div className="flex flex-col gap-4">
        <h6 className="font-semibold">{title}</h6>
        <p className="w-3/4">{description}</p>
      </div>

      <div className="">{children}</div>
    </div>
  );
}

export default CustomisationSection;
