import React, { FormEvent, useRef } from "react";
import CustomOverlaySidebarFilter from "../../components/CustomOverlaySidebarFilter";
import FacilitiesSidebarFilter from "../../components/FacilitiesSidebarFilter";
import ZonesSidebarFilter from "../../components/ZonesSideFilter";
import { BlueButton } from "../../../../../../components/ui/Buttons";
import { useSearchParams } from "react-router-dom";
function SidebarFilter() {
  const [searchParams, setSearchParams] = useSearchParams();
  const formRef = useRef<HTMLFormElement>(null);
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const formData = new FormData(formRef.current!);
    const siteTypes = formData.getAll("siteTypes") as string[];
    setSearchParams({ siteTypes });
  };

  return (
    <form
      ref={formRef}
      className="w-96 h-full overflow-y-auto bg-white text-grey rounded-lg relative flex flex-col p-8"
    >
      <header className="text-center">
        <h4 className="my-4 font-semibold text-base">Sites</h4>
      </header>
      <hr className="mb-4"></hr>
      <FacilitiesSidebarFilter />
      <ZonesSidebarFilter />
      <CustomOverlaySidebarFilter />

      <div className="mt-12">
        <BlueButton text="Filter" onClick={handleSubmit} />
      </div>
    </form>
  );
}

export default SidebarFilter;
