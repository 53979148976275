import React, {
  FormEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import fs from "fs";
import Sidebar from "../../../../../../components/ui/Sidebar";
import UploadImage from "../../../../../../assets/aurora_branding_upload_image.png";
import { ClientContext } from "../../../../context/client";
import api from "../../../../../../api/axiosConfig";
function PhotoUploadSidebar() {
  const [file, setFile] = useState<any>();
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const clientContext = useContext(ClientContext)!;
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);

  const fetchImage = async () => {
    return await fetch(
      `${process.env.REACT_APP_API_URL}files/platform-logo/${clientContext.data.id}`
    )
      .then(async (response) => {
        const blob = await response.blob();
        setImageBlob(blob);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchImage();
  }, []);

  const handleChooseFile = () => {
    fileUploadRef.current?.click();
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      console.error("No file selected");
      return;
    }

    setFile(event.target.files[0]);
  };

  const saveUploadedImage = () => {
    const formData = new FormData();
    formData.append("file", file);

    api
      .post(`/files/platform-logo/${clientContext.data.id}`, formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((res) => {
        console.log("image uploaded");
        setFile(null);
        fetchImage();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="">
      <Sidebar title={clientContext.data.name}>
        <>
          {imageBlob && (
            <img
              src={URL.createObjectURL(imageBlob)}
              alt="Fetched Image"
              className="rounded-full"
            />
          )}
          {!imageBlob && (
            <img src={UploadImage} alt="" className="w-full rounded-full" />
          )}
        </>
        <div className="flex gap-4 flex-col">
          <input
            type="file"
            id="file-upload"
            name="profile-picture"
            className="hidden"
            onChange={handleFileUpload}
            ref={fileUploadRef}
          />
          <div className="flex">
            <button
              className="h-10 w-1/2 rounded-full bg-white shadow-md text-sm"
              onClick={handleChooseFile}
            >
              Choose File
            </button>
            <button
              className={`h-10 w-1/2 rounded-full shadow-md text-sm text-center ml-2 ${
                !file ? "bg-light-light-grey" : "bg-blue text-white"
              }`}
              disabled={!file}
              onClick={saveUploadedImage}
            >
              Save
            </button>
          </div>
          <span className="text-sm inline-block my-auto mx-auto w-28 text-ellipsis overflow-hidden">
            {" "}
            {file ? file.name : "No file chosen"}
          </span>
        </div>
      </Sidebar>
    </div>
  );
}

export default PhotoUploadSidebar;
