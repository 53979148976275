import React from "react";
export default function CasesEntry() {
  return (
    <div className="grid grid-cols-[0.3fr_0.4fr_1fr_0.4fr_0.3fr_0.5fr] w-full py-3 justify-items-center items-center text-center py-1 border-b">
      <div>NGS09281</div>
      <div>Ben Murrell</div>
      <div>NGS09281- Bain & CO - Gadri Chandra - Suspected Hip Injury</div>
      <div>25/11/2022 13:02</div>
      <div>Closed</div>
      <div>Direct Client Medical</div>
    </div>
  );
}
