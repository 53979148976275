import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { AlertContext } from "../../context/alert";

const apiV2 = axios.create({
  baseURL: process.env.REACT_APP_API_V2_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const CustomAlert: React.FC = () => {
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const [alertId, setAlertId] = useState(true);
  const [isOverflow, setIsOverflow] = useState(false);
  const [height, setHeight] = useState("20px"); 

  const contentRef = useRef<HTMLDivElement>(null); 

  const { alert, setAlert, setAlertHeight, isExpanded, setIsExpanded } = useContext(AlertContext);

  const removeUlTags = (htmlString: string) => {
    return htmlString.replace(/<\/?ul>/g, "");
  };

  useEffect(() => {
    const fetchAlertMessage = async () => {
      try {
        const response = await apiV2.get("/alert-message");
        const sanitizedMessage = removeUlTags(response.data.message);
        setAlertTitle(response.data.title);
        setAlertMessage(sanitizedMessage);
        setAlertId(response.data.id);
        if (response.data.message) {
          setAlert(true);
        }
      } catch (error) {
        console.error("Error fetching alert message:", error);
      }
    };

    fetchAlertMessage();
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      console.log(contentRef.current)
      const calculatedHeight = contentRef.current.scrollHeight;
      setAlertHeight(calculatedHeight);
      if (calculatedHeight > 20) {
        setIsOverflow(true);
      }
    }
  }, [alertMessage, setIsOverflow]);

  useEffect(() => {
    if (contentRef.current) {
      // Create a temporary container
      const tempContainer = document.createElement("div");
      tempContainer.style.position = "absolute";
      tempContainer.style.visibility = "hidden";
      tempContainer.style.width = "100%"; // Match the width of the actual container
      tempContainer.style.fontSize = window.getComputedStyle(contentRef.current).fontSize; // Match font size
      tempContainer.style.lineHeight = window.getComputedStyle(contentRef.current).lineHeight; // Match line height
      tempContainer.innerHTML = contentRef.current.innerHTML; // Copy the content
      
      document.body.appendChild(tempContainer);

      // Calculate the height of the text content
      const calculatedHeight = tempContainer.scrollHeight;

      // Clean up
      document.body.removeChild(tempContainer);

      // Update state
      setAlertHeight(calculatedHeight);
      if (calculatedHeight > 20) {
        setIsOverflow(true);
      } else {
        setIsOverflow(false);
      }
    }
  }, [setAlertHeight]);

  const closeAlert = async () => {
    setIsVisible(false);
    setAlert(false);
    try {
      await apiV2.post("/alert-closed", { id: alertId });
    } catch (error) {
      console.error("Error sending alert close request:", error);
    }
  };

  const toggleExpanded = () => {
    if (contentRef.current) {
      if (isExpanded) {
        setHeight("20px");
      } else {
        setHeight(`${contentRef.current.scrollHeight}px`);
      }
    }
    setIsExpanded(!isExpanded);
  };

  if (!alertMessage || !isVisible) return null;

  return (
    <div
      className="bg-ice-blue border-t-4 border-[#2D166F] rounded-b text-teal-900 px-4 py-3 shadow-md"
      role="alert"
    >
      <div className="flex">
        <div className="py-1">
          <svg
            className="fill-current h-6 w-6 text-teal-500 mr-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
          </svg>
        </div>
        <div className="flex-grow text-black">
          <p className="font-bold">{alertTitle}</p>
          <div
            ref={contentRef}
            className="text-sm font-semibold"
            style={{
              maxHeight: height,
              overflow: "hidden",
              transition: "max-height 0.3s ease-in-out",
            }}
            dangerouslySetInnerHTML={{ __html: alertMessage }}
          />
          {isOverflow && (
            <button
              className="mt-1"
              onClick={toggleExpanded}
            >
              {isExpanded ? "Show Less" : "Show More"}
            </button>
          )}
        </div>
        <div className="py-1">
          <svg
            onClick={closeAlert}
            className="fill-current h-6 w-6 text-teal-500 cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 01-1.697 0L10 12.197l-2.651 2.652a1.2 1.2 0 01-1.697-1.697l2.652-2.651-2.652-2.651a1.2 1.2 0 011.697-1.697L10 8.803l2.651-2.652a1.2 1.2 0 011.697 1.697L11.197 10l2.651 2.651a1.2 1.2 0 010 1.697z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default CustomAlert;
