import React, { useContext } from "react";
import SummaryReport from "../../Summary/components/SummaryReport";
import SummarySearchBox from "../../Summary/components/SummarySearchBox";

const summaryReportData = [
  {
    title: "Users",
    value: "16501",
  },
  {
    title: "Checkin",
    value: "118",
  },
  {
    title: "Tracking",
    value: "24",
  },
  {
    title: "Emergency",
    value: "3",
  },
];

export default function MonthlyReport() {
  return (
    <div className="py-5 grid grid-cols-[40%_1fr] gap-10 w-full">
      <SummarySearchBox />
      <div className="overflow-hidden h-[calc(100vh_-_330px)] mb-3">
        <div className="overflow-y-scroll h-full scrollbar-hide">
          <div className="pb-10 border-b border-light-grey">
            <SummaryReport title="App Plus" data={summaryReportData} />
          </div>
          <div className="pb-10 border-b border-light-grey">
            <SummaryReport title="App Plus" data={summaryReportData} />
          </div>
          <div className="pb-10 border-b border-light-grey">
            <SummaryReport title="App Plus" data={summaryReportData} />
          </div>
          <div>
            <SummaryReport title="App Plus" data={summaryReportData} />
          </div>
        </div>
      </div>
    </div>
  );
}
