import React from "react";
import Information from "../../components/Information";
import Form from "../../form/Form";

function SecurityAssessmentsMain() {
  return (
    <div className="w-full  bg-white rounded-lg px-8 h-[60vh] overflow-y-auto">
      <div className="py-4 border-b">
        <h3 className="text-[18px] font-light mx-8">Security Assessments</h3>
      </div>
      <div className="">
        <Information />
        <Form />
      </div>
    </div>
  );
}

export default SecurityAssessmentsMain;
