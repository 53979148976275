import api from "../axiosConfig";
import { useQuery } from "react-query";

export default function useGetUserPersonalProfile() {
  const fetchUserPersonalProfile = async () => {
    return await api
      .get("/profile")
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  return useQuery(["personal-profile"], fetchUserPersonalProfile, {
    keepPreviousData: true,
  });
}
