import React, { FormEvent, ReactNode, Ref } from "react";

type FormModal = {
  className?: string;
  children: ReactNode;
  title: string;
  modalRef?: Ref<HTMLFormElement>;
  onSubmit?: () => void;
};

function FormModal({
  className,
  children,
  title,
  modalRef,
  onSubmit,
}: FormModal) {
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!onSubmit) return;
    onSubmit();
  };
  return (
    <form
      ref={modalRef}
      className={`bg-white text-grey px-8 pb-8 rounded-lg max-h-[60vh] overflow-y-auto ${className}`}
      onSubmit={(e) => handleSubmit(e)}
    >
      <header className="text-center">
        <h4 className="my-4 font-semibold text-base">{title}</h4>
      </header>

      <hr />
      {children}
    </form>
  );
}

export default FormModal;
