import React, { useContext } from "react";
import navLinks from "../../constants/nav-links.json";
import MainLink from "../../components/main-link";
import { UserContext } from "../../../../context/user";

function NavSidebar() {

  const userContext = useContext(UserContext);

  const hasAirlineRiskMatrix = userContext?.modules?.includes("airlineRiskMatrix");

  return (
    <div className="px-8 w-full max-w-sm">
      <div className="bg-white w-full shadow-md pb-12 px-6 flex flex-col gap-6 overflow-y-auto pt-5 h-fit">
        <div className="border-b-1 h-12 w-[3/5] flex items-center justify-center ">
          <p className="text-md text-center  block pb-2 font-semibold">Risk</p>
        </div>

        <nav className="flex flex-col gap-4 text-center w-full h-fit">
        {navLinks
            .filter((link) => {
              if (link.slug === "security-assessments") {
                return false;
              }

              if (link.slug === "airline-risk-matrix") {
                return hasAirlineRiskMatrix;
              }

              return true;
            })
            .map((navLink) => (
              <MainLink
                key={navLink.slug}
                path={navLink.path}
                title={navLink.label}
                slug={navLink.slug}
              />
            ))}
        </nav>
        <div className="px-12">
          <a
            href="https://www.shetravel.co.uk"
            target="_blank"
            className="bg-white shadow-md w-full h-10 flex  justify-center items-center rounded-md" rel="noreferrer"
          >
            SheTravel
          </a>
        </div>
      </div>
    </div>
  );
}

export default NavSidebar;
