import React, { useState, useEffect, useRef } from "react";
import FilterIcon from "../../../../../assets/icons/filter-icon.svg";
import { countries } from "../../../../../constants/countries";
import ReactDatePicker from "react-datepicker";
import useGetIncidentTypes from "../../../../../api/incident-types/useGetIncidentTypes";
import CheckboxFilter from "./checkbox-filter";
import IncidentTypesDataTypes from "../../../../../types/incident-types-data-types";
import ReactSelect from "react-select";
import { Done } from "@mui/icons-material";
import DropDown from "../../../../../components/form/DropDown";
import DateIcon from "../../../../../assets/icons/date.svg"
import { first } from "rxjs";



function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function IncidentFilterForm({ onApplyFilter, setPage }: { onApplyFilter: (filters: any) => void, setPage: (page: number) => void }) {
    const [selectedSeverities, setSelectedSeverities] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [longitude, setLongitude] = useState("");
    const [latitude, setLatitude] = useState("");
    const [radius, setRadius] = useState("");
    const incidentTypes = useGetIncidentTypes();
    const formRef = useRef<HTMLFormElement>(null)
    


    const countryOptions = countries.map(country => ({
        value: country.code,
        label: country.name
    }));

    const handleCountryChange = (selectedOption: any) => {
        setSelectedCountries([selectedOption.value]);
    };



    type FilterType = {
        severity?: string;
        countryISO?: string;
        typeIDs?: string;
        startTime?: string;
        endTime?: string;
        coordinates?: string;
        radius?: string;
    };

    


    const applyFilters = (subtypes: string[]) => {

        const filters: FilterType = {
            severity: selectedSeverities.join(","),
            countryISO: selectedCountries.join(","),
            typeIDs: subtypes.join(","),
            startTime: firstDate?.toISOString(),
            endTime: finalDate?.toISOString(),
        };
    
        console.log(subtypes)

        if (longitude && latitude) {
            filters.coordinates = `${longitude},${latitude}`;
            if (radius) {
                filters.radius = radius;  
            }
        }
    
        onApplyFilter(filters);
    };
    
    const [isFiltersCleared, setIsFiltersCleared] = useState(false);

    const clearFilters = () => {
        setSelectedSeverities([]);
        setSelectedCountries([]);
        setSelectedCategories([]);
        setLongitude("");
        setLatitude("");
        setRadius("");
        setShowDatePicker1(false);
        setShowDatePicker2(false);
        setFirstDate(null);
        setFinalDate(null);
        formRef.current?.reset();

        setIsFiltersCleared(true);
    };
    
    useEffect(() => {
        if(isFiltersCleared) {
            applyFilters([]);
            setIsFiltersCleared(false);
            setPage(1)  
        }
    }, [isFiltersCleared]);

    const [showDatePicker1, setShowDatePicker1] = useState<boolean>(false);
    const [showDatePicker2, setShowDatePicker2] = useState<boolean>(false);


    const [firstDate, setFirstDate] = useState<Date | null>(null);
    const [finalDate, setFinalDate] = useState<Date | null>(null);
    
    const handleDateChange1 = (date: Date) => {
        setFirstDate(date);
    };
    
    const handleDateChange2 = (date: Date) => {
        setFinalDate(date);
    };


    const dateToString = (date: any) => {
        return date.toLocaleDateString();
    };

    const filterPassedTime = (_time: any) => {
        return true; 
    };

    const [isOpen, setIsOpen] = useState(false);

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); 
        const formData = new FormData(formRef.current!);
        const subtypes = formData.getAll("sub-type") as string[];
        applyFilters(subtypes);
    
        setPage(1);
        setIsOpen(false);

      };

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const getActiveFilters = (): string[] => {
        const filters: string[] = [];
    
        if (selectedSeverities.length > 0) {
            filters.push(`Severities: ${selectedSeverities.map(capitalizeFirstLetter).join(", ")}`);
        }
        if (selectedCountries.length > 0) {
            const countryNames = selectedCountries.map(code => countries.find(country => country.code === code)?.name || "").filter(Boolean);
            filters.push(`Country: ${countryNames.join(", ")}`);
        }
        if (firstDate && finalDate) {
            filters.push(`${dateToString(firstDate)} - ${dateToString(finalDate)}`);
        }
        if (longitude && latitude) {
            filters.push(`Lat,Lon:(${latitude},${longitude})`);
            if (radius) {
                filters.push(`Radius:${radius}m`);
            }
        }
        
        return filters;
    }

    const toggleSeverity = (severity: string) => {
        setSelectedSeverities(prev => {
            if (severity === "all") {
                return prev.length === 5 ? [] : ["insignificant", "limited", "moderate", "major", "extreme"];
            } else {
                return prev.includes(severity) ? prev.filter(s => s !== severity) : [...prev, severity];
            }
        });
    };
    

    return (
        <div>
            <button onClick={togglePopup} className="bg-gray-300 p-2 rounded-md">
                <img src={FilterIcon} alt="" className="pointer-events-none" />
            </button>

            {isOpen && (
                <div className="absolute top-0 left-0 bg-white p-4 rounded-md shadow-lg w-[45rem] mt-2 z-10 border border-gray-300">
                    <button onClick={togglePopup} className="absolute top-2 right-2 text-gray-500">X</button>
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-semibold">Incident Filter</h2>                        
                        <div className="flex text-blue space-x-2 overflow-x-auto" style={{ maxHeight: "30px" }}>
                            {getActiveFilters().map((filter, idx) => (
                                <span key={idx} className="text-xs bg-gray-200 rounded p-1">{filter}</span>
                            ))}
                        </div>
                    </div>

                <form ref={formRef} onSubmit={handleFormSubmit} style={{ height: "700px", overflowY: "auto" }}>
                    <div className="mb-4">
                        <div className="flex w-300px">
                            <div className="mb-4">
                            <DropDown
                                className="w-[300px]"
                                dropDownIcon={DateIcon}
                                title="Dates"
                                transparentBox={true}
                                selectedValue={
                                    firstDate && finalDate
                                    ? `${firstDate.toLocaleDateString()} - ${finalDate.toLocaleDateString()}`
                                    : ""
                                }
                                selected={firstDate && finalDate ? true : false}
                            >
                                <ReactDatePicker
                                    inline
                                    isClearable
                                    selected={firstDate}
                                    onChange={(dates: [Date | null, Date | null]) => {
                                        const [start, end] = dates;

                                        setFirstDate(start);
                                        setFinalDate(end);
                                    }}
                                    startDate={firstDate}
                                    endDate={finalDate}
                                    selectsRange
                                />
                            </DropDown>
                            </div>
                        </div>
                    <div className="mb-4">
                        <label className="block mb-2">Country:</label>
                            <ReactSelect 
                                className=' text-black rounded-md shadow border-none '
                                options={countryOptions}
                                onChange={handleCountryChange}
                                placeholder="Select a country"
                            />
                        </div>
                    </div> 
                    <div className="flex justify-center items-center ">
                        <span>OR</span>
                    </div>
                    <div className="mb-4">
                        <label className="block mb-2">Incident Location:</label>
                        <div className="grid grid-cols-3 gap-2">
                            <input
                                type="text"
                                placeholder="Latitude"
                                className="shadow p-2 rounded-md"
                                value={latitude}
                                onChange={(e) => setLatitude(e.target.value)}
                            />
                            <input
                                type="text"
                                placeholder="Longitude"
                                className="shadow p-2 rounded-md"
                                value={longitude}
                                onChange={(e) => setLongitude(e.target.value)}
                            />
                            <input 
                                type="text" 
                                placeholder="Radius (m)" 
                                className="shadow p-2 rounded-md" 
                                value={radius} 
                                onChange={(e) => setRadius(e.target.value)} 
                            />
                            </div>
                    </div>
                    <legend>Severity:</legend>
                    <div className="flex items-center gap-4 mt-3 font-semibold">
                        <button 
                            className="h-4 w-4 flex items-center justify-center border" 
                            type="button"
                            onClick={() => toggleSeverity("all")}
                        >
                            {selectedSeverities.length === 5 ? <Done style={{ width: "12px" }} /> : null}
                        </button>
                        All
                    </div>

                    <fieldset className="grid grid-cols-3 gap-1">
                        {["insignificant", "limited", "moderate", "major", "extreme"].map(severity => (
                            <div className="flex items-center gap-4 my-1 ml-4" key={severity}>
                                <button 
                                    className="h-4 w-4 flex items-center justify-center border" 
                                    type="button"
                                    onClick={() => toggleSeverity(severity)}
                                >
                                    {selectedSeverities.includes(severity) ? <Done style={{ width: "12px" }} /> : null}
                                </button>
                                {capitalizeFirstLetter(severity)}
                            </div>
                        ))}
                    </fieldset>
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center pt-10">
                            <p>Incident Types:</p>
                        </div>
                        {incidentTypes.data?.data.map((type: IncidentTypesDataTypes) => (
                        <CheckboxFilter incidentType={type} key={type.id} shouldClear={isFiltersCleared} onClearComplete={() => setIsFiltersCleared(false)} />
                        ))}
                    </div>
                    <div className="flex justify-between m-2 space-x-4">         
                        <button type='submit' className="mt-3 bg-blue text-white p-3 px-9 rounded-md">
                            Filter
                        </button>
                        <button type="button" onClick={clearFilters} className="mt-3 bg-light-grey text-white p-3 px-9 rounded-md ">
                            Clear
                        </button>
                    </div>
                </form>
                </div>
            )}
        </div>
    );
}

export default IncidentFilterForm;
